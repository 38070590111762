import { ActionCreatorWithPayload, ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { logger } from '@services/logger/logger.service';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  IntegrationsDashboardType,
  SendingsDashboardType,
  isIntegrationsDashboardType,
} from '@honestica/core-apps-common/types';

import { downloadDocuments, downloadIntegrationRequests } from '@api';
import { DownloadOneAction, OneFailurePayload } from '@store/documents/documents.actions';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';
import { IntegratedIntegrationsActions } from '@store/documents/integrations/integrated.slice';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { SentDocumentsActions } from '@store/documents/outgoing/sent.slice';

import { ArchivedDocumentsActions } from '../../incoming/archived.slice';

const downloadActionToDocumentType = {
  [ArchivedDocumentsActions.downloadOne.type]: SendingsDashboardType.Archive,
  [DraftDocumentsActions.downloadOne.type]: SendingsDashboardType.Draft,
  [InboxDocumentsActions.downloadOne.type]: SendingsDashboardType.Inbox,
  [IntegratedIntegrationsActions.downloadOne.type]: IntegrationsDashboardType.Integrated,
  [SentDocumentsActions.downloadOne.type]: SendingsDashboardType.Sent,
  [WorklistIntegrationsActions.downloadOne.type]: IntegrationsDashboardType.Worklist,
};

const onSuccess: Record<
  SendingsDashboardType | IntegrationsDashboardType,
  ActionCreatorWithoutPayload
> = {
  [SendingsDashboardType.Archive]: ArchivedDocumentsActions.downloadOneSuccess,
  [SendingsDashboardType.Draft]: DraftDocumentsActions.downloadOneSuccess,
  [SendingsDashboardType.Inbox]: InboxDocumentsActions.downloadOneSuccess,
  [SendingsDashboardType.Sent]: SentDocumentsActions.downloadOneSuccess,
  [IntegrationsDashboardType.Integrated]: IntegratedIntegrationsActions.downloadOneSuccess,
  [IntegrationsDashboardType.Worklist]: WorklistIntegrationsActions.downloadOneSuccess,
};

const onFail: Record<
  SendingsDashboardType | IntegrationsDashboardType,
  ActionCreatorWithPayload<OneFailurePayload>
> = {
  [SendingsDashboardType.Archive]: ArchivedDocumentsActions.downloadOneFailure,
  [SendingsDashboardType.Draft]: DraftDocumentsActions.downloadOneFailure,
  [SendingsDashboardType.Inbox]: InboxDocumentsActions.downloadOneFailure,
  [SendingsDashboardType.Sent]: SentDocumentsActions.downloadOneFailure,
  [IntegrationsDashboardType.Integrated]: IntegratedIntegrationsActions.downloadOneFailure,
  [IntegrationsDashboardType.Worklist]: WorklistIntegrationsActions.downloadOneFailure,
};

function* downloadOneDocumentSaga(action: DownloadOneAction) {
  const type = downloadActionToDocumentType[action.type];
  if (!type) return;

  try {
    if (isIntegrationsDashboardType(type)) {
      yield call(downloadIntegrationRequests, [action.payload.id]);
    } else {
      yield call(downloadDocuments, [action.payload.id], type);
    }
    yield put(onSuccess[type]());
  } catch (error) {
    logger.error('LOGIC', `Failed to download document ${action.payload.id}`, error);
    yield put(
      onFail[type]({
        id: action.payload.id,
        error,
      }),
    );
  }
}

export function* downloadDetailSagas() {
  yield all([
    takeLatest(InboxDocumentsActions.downloadOne.type, downloadOneDocumentSaga),
    takeLatest(ArchivedDocumentsActions.downloadOne.type, downloadOneDocumentSaga),
    takeLatest(DraftDocumentsActions.downloadOne.type, downloadOneDocumentSaga),
    takeLatest(SentDocumentsActions.downloadOne.type, downloadOneDocumentSaga),
    takeLatest(WorklistIntegrationsActions.downloadOne.type, downloadOneDocumentSaga),
    takeLatest(IntegratedIntegrationsActions.downloadOne.type, downloadOneDocumentSaga),
  ]);
}
