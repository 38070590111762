export function debounce<T extends (...args: any[]) => any>(fn: T, time: number) {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    const deferred = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      fn(...args);
    };

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(deferred, time);

    return timeout;
  };
}
