import { http } from '@services/http/http.service';
import { logger } from '@services/logger/logger.service';

import { SearchParams } from '@honestica/core-apps-common/types';

const path = '/practitioners';

export async function getPractitioners(searchParams: SearchParams) {
  try {
    return await http.get({ path, searchParams });
  } catch (err) {
    logger.error('API', 'getPractitioners failed', err);
    return undefined;
  }
}

export async function getPractitioner(searchParams: SearchParams) {
  try {
    return await http.get({ path: `${path}/${searchParams.id}` });
  } catch (err) {
    logger.error('API', 'getPractitioner failed', err);
    return undefined;
  }
}
