import { createAction } from '@reduxjs/toolkit';

import { GenericError, MutationErrorName } from './errors.state';

export type AddMutationErrorMessage = typeof addMutationErrorMessage;
export type AddGenericErrorMessage = typeof addGenericErrorMessage;
export type RemoveGenericErrorMessage = typeof removeGenericErrorMessage;
export type RemoveMutationErrorMessage = typeof removeMutationErrorMessage;

const REDUCER_NAME = 'errors';

export const addMutationErrorMessage = createAction<MutationErrorName>(
  `${REDUCER_NAME}/addMutationMessage`,
);

export const addGenericErrorMessage = createAction<GenericError>(
  `${REDUCER_NAME}/addGenericMessage`,
);

export const removeMutationErrorMessage = createAction<MutationErrorName>(
  `${REDUCER_NAME}/removeMutationMessage`,
);

export const removeGenericErrorMessage = createAction(`${REDUCER_NAME}/removeGenericMessage`);
