import { createSlice } from '@reduxjs/toolkit';

import { uploadReducer } from '@store/upload/upload.reducer';

import { INITIAL_INTEGRATIONS_STATE } from './integrations.state';

const REDUCER_NAME = 'integrations';

const slice = createSlice({
  initialState: INITIAL_INTEGRATIONS_STATE,
  name: REDUCER_NAME,
  reducers: {
    ...uploadReducer,
  },
});

export const integrationsReducer = slice.reducer;
export const IntegrationsActions = slice.actions;
