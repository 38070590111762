import { createAction } from '@reduxjs/toolkit';

import { NotificationsState } from './notifications.state';

const REDUCER_NAME = 'notifications';

const GET_NOTIFICATIONS = `${REDUCER_NAME}/get`;

export const getNotifications = createAction(GET_NOTIFICATIONS);
export const getNotificationsSuccess = createAction<NotificationsState>(
  `${GET_NOTIFICATIONS}Success`,
);
export const getNotificationsFailure = createAction<unknown>(`${GET_NOTIFICATIONS}Failure`);

export type GetNotificationsSuccess = typeof getNotificationsSuccess;
