import { Selector, createSelector } from '@reduxjs/toolkit';

import { Patient } from '@honestica/core-apps-common/types';

import { getPatientEntities } from '../entities/entities.selector';
import { Entities } from '../entities/entities.state';
import { State } from '../reducer';

import { PatientDirectoryState } from './patientDirectory.reducer';

export const getPatientDirectory: Selector<State, PatientDirectoryState> = (state) =>
  state.patientDirectory;
export const getJob: Selector<State, PatientDirectoryState['job']> = (state: State) =>
  getPatientDirectory(state).job;
export const isImportJobProcessing: Selector<State, boolean> = ({
  patientDirectory: {
    job: { jobId, status },
  },
}: State) => !!jobId && !['failed', 'completed'].includes(status ?? '');
export const isJobRollback: Selector<State, boolean> = (state: State) =>
  getPatientDirectory(state).job.isRollback;
export const getTotalPatients: Selector<State, number> = (state: State) =>
  getPatientDirectory(state).total;
export const isLoadingPatients: Selector<State, boolean> = (state: State) =>
  getPatientDirectory(state).loading;
export const isLoadingPatientModal: Selector<State, boolean> = (state: State) =>
  getPatientDirectory(state).modalLoading;
export const isInErrorPatientDirectory: Selector<State, boolean> = (state: State) =>
  getPatientDirectory(state).error;
export const isInErrorPatientDrawer: Selector<State, boolean> = (state: State) =>
  getPatientDirectory(state).drawerError;
export const isInErrorPatientModal: Selector<State, boolean> = (state: State) =>
  getPatientDirectory(state).modalError;
export const getPatientList: Selector<State, Patient['id'][]> = (state: State) =>
  getPatientDirectory(state).list;

export const getPatients = createSelector(
  [getPatientList, getPatientEntities],
  (patientIds: Patient['id'][], patientEntities: Entities<Patient>) =>
    patientIds.reduce((patients: Patient[], id: Patient['id']) => {
      const patient = id && patientEntities[id];
      if (patient) {
        patients.push(patient);
      }
      return patients;
    }, []),
);

export const getPatientFromQueryParams = createSelector(
  [getPatientEntities],
  (patientEntities: Entities<Patient>) => {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);

    const patientId = searchParams.get('patientId') as string;
    if (!patientEntities[patientId]) {
      return undefined;
    }
    return patientEntities[patientId];
  },
);
