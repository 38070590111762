import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  createUploadAllDocsSaga,
  createUploadDocumentFromWatcherSaga,
} from '@store/upload/addNewFile.sagas';
import {
  checkAndProcessJobSaga,
  createHandleJobFailureSaga,
  createHandleJobSuccessSaga,
} from '@store/upload/processUploadJob.sagas';

import { IntegrationsActions } from './integrations.slice';

export function* integrationsSagas() {
  yield all([
    takeLatest(
      IntegrationsActions.uploadAllDocsFromWatchedFolder.type,
      createUploadAllDocsSaga('integration'),
    ),
  ]);
  yield all([
    takeEvery(
      IntegrationsActions.uploadFromWatcher.type,
      createUploadDocumentFromWatcherSaga('integration'),
    ),
  ]);
  yield all([
    takeEvery(IntegrationsActions.addUploadJob.type, checkAndProcessJobSaga('integration')),
  ]);
  yield all([
    takeEvery(IntegrationsActions.jobUploadFailure.type, createHandleJobFailureSaga('integration')),
  ]);
  yield all([
    takeEvery(IntegrationsActions.jobUploadSuccess.type, createHandleJobSuccessSaga('integration')),
  ]);
}
