'use client';

import { useCallback, useEffect, useMemo } from 'react';

import { ErrorPage } from '@launcher/components/ErrorPage';
import { BASENAME } from '@launcher/constants';
import { isLoginWebview as isLoginWebviewUtil } from '@launcher/helpers/desktop/loginWebview.helper';
import { useAuthenticateUser } from '@launcher/hooks/useAuthenticateUser.hook';
import { isDesktopApp } from '@launcher/utils';
import { authClient } from '@services/authentication/authentication.service';
import { logger } from '@services/logger/logger.service';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthUser } from '@honestica/core-apps-common/types';
import { Page, Spinner } from '@honestica/ui-kit/src';

import { authenticateUserFailure } from '@store/user/user.actions';
import { getAuthenticationError, getIsAuthenticated } from '@store/user/user.selector';
import { AuthenticationError } from '@store/user/user.state';

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authenticationError = useSelector(getAuthenticationError);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const onAuthenticateUserSuccess = useAuthenticateUser();

  const isLoginWebview = useMemo(() => isLoginWebviewUtil(), []);

  /*
  Get user data from autentication provider token
  */
  const getUserDataFromToken = useCallback(async () => {
    if (isAuthenticated) {
      logger.info('LOGIC', `LOGIN_AUTHENTICATEUSER_AUTHENTICATED `);
      return;
    }

    try {
      logger.info('LOGIC', `LOGIN_AUTHENTICATEUSER `);
      // Get user data from code in query params (added on redirect by login.lifen.fr)
      const {
        user,
        targetUrl,
      }: {
        user: AuthUser | undefined;
        targetUrl: string | undefined;
      } = await authClient.handleRedirectAfterLogin();

      // If in the webview, error messages will be displayed in it (may break button action)
      if (!user) {
        dispatch(authenticateUserFailure(AuthenticationError.Default));
        return;
      }

      // Await is needed to wait for action to finish before redirecting (avoid infinite loop)
      await onAuthenticateUserSuccess(user);

      // User data has been dispatched, no more reason to go further
      if (isLoginWebview) {
        return;
      }

      if (targetUrl) {
        // Redirect user to the correct page if needed (targetUrl provided on login)
        logger.info('LOGIC', `Redirect user to the correct app, ${targetUrl}`);
        navigate(`${BASENAME}${targetUrl}`);
      } else {
        logger.info('LOGIC', `Authentication: no targetUrl`);
      }
    } catch (error) {
      logger.error('LOGIC', 'Failed to authenticate user', error);
      dispatch(authenticateUserFailure(AuthenticationError.Default));
    }
  }, [dispatch, isAuthenticated, isLoginWebview, navigate, onAuthenticateUserSuccess]);

  /*
  Redirect user to login provider
  */
  const redirectToLoginProvider = useCallback(async () => {
    try {
      /* This will redirect the user to login.lifen.fr */
      logger.info('LOGIC', `LOGIN_HANDLE_LOGIN`);

      // it seems that errors are not thrown on desktop if ssl issue (Chrome-error://chromewebdata)
      await authClient.login();
    } catch (error) {
      logger.error('LOGIC', 'Failed to redirect to login', error);
      dispatch(authenticateUserFailure(AuthenticationError.Login));
    }
  }, [dispatch]);

  /*
  Get user object from auth0 token or redirect to auth0 if user is not loggued in
  */
  useEffect(() => {
    // Already authenticated, do nothing
    if (isAuthenticated) {
      return;
    }

    const searchParams = new URLSearchParams(search);
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    if (code || state) {
      // Login has already happened, get user object from auth0 token
      getUserDataFromToken();
    } else if (isLoginWebview || !isDesktopApp()) {
      // Redirect to login provider (auth0)
      redirectToLoginProvider();
    } else {
      logger.info('LOGIC', `LOGIN_NO_ACTION`);
    }
  }, [isAuthenticated, isLoginWebview, search, getUserDataFromToken, redirectToLoginProvider]);

  // In webview, only display spinner or auth error messages
  if (isLoginWebview) {
    if (authenticationError) {
      return <ErrorPage errorKey={authenticationError} />;
    }

    return (
      <>
        <Page.Empty>
          <Spinner />
        </Page.Empty>
      </>
    );
  }

  return <>{authenticationError ? <ErrorPage errorKey={authenticationError} /> : children}</>;
};
