import { Component, ComponentProps, ReactNode } from 'react';

import Logo from '../Logo';

import { SidebarIcon } from './Icon/Sidebar.Icon.component';
import { SidebarMenu } from './Menu/Sidebar.Menu.component';

import styles from './Sidebar.module.less';

interface SidebarProps extends ComponentProps<'aside'> {
  children: ReactNode;
  bottomMenu: ReactNode;
  logoColor?: string;
}

export class Sidebar extends Component<SidebarProps> {
  static Icon = SidebarIcon;

  static Menu = SidebarMenu;

  render() {
    const { bottomMenu, children, logoColor, ...rest } = this.props;
    return (
      <aside className={styles.sidebarWrapper} {...rest}>
        <div className={styles.sidebar}>
          <Logo className={styles.logo} logoColor={logoColor} />
          <div className={styles.menuWrapper}>
            <div>{this.props.children}</div>
            <div>{this.props.bottomMenu}</div>
          </div>
        </div>
      </aside>
    );
  }
}
