import { DesktopSettings, defaultDesktopSettings } from '@launcher/utils';

export const initialState: DesktopState = {
  immutableSettings: [],
  loadingSettings: true,
  settings: defaultDesktopSettings,
  watchingFolders: false,
  desktopFeaturesInitialized: false,
};

export interface DesktopState {
  immutableSettings: (keyof DesktopSettings)[];
  loadingSettings: boolean;
  settings: DesktopSettings;
  watchingFolders: boolean;
  desktopFeaturesInitialized: boolean;
}
