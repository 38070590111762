import { PayloadAction, createAction } from '@reduxjs/toolkit';

import { FlowType } from '@honestica/core-apps-common/types';

import { desktopSlice } from './desktop.reducer';

export type MoveFilePayload = { filePath: string; flowType: FlowType };
export type MoveFileAction = PayloadAction<MoveFilePayload>;

export const DesktopActions = {
  ...desktopSlice.actions,

  moveImportedFileToSuccess: createAction<MoveFilePayload>(
    `${desktopSlice.name}/moveImportedFileToSuccess`,
  ),

  moveNonImportedFileToError: createAction<MoveFilePayload>(
    `${desktopSlice.name}/moveNonImportedFileToError`,
  ),

  showOutboxErrorFolder: createAction<{ fileName?: string; type: FlowType }>(
    `${desktopSlice.name}/showOutboxErrorFolder`,
  ),
  initDesktop: createAction<void>(`${desktopSlice.name}/initDesktop`),
};
