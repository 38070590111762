import { DesktopSettings } from '@launcher/utils';
import { i18n } from '@services/i18n/index';
import { logger } from '@services/logger/logger.service';

export async function addVirtualPrinterForMac(settings: DesktopSettings) {
  try {
    if (window.electron.getPlatform() !== 'darwin') return;

    logger.info('DESKTOP', 'Mac platform detected, checking if virtual printer is installed...');
    // Configure all paths
    const homeDir = window.electron.getHomedir();
    const { watchPath } = settings;

    // eslint-disable-next-line no-useless-escape
    const libraryDirectory = `${homeDir}/Library/PDF\ Services`;
    const printerDirectoryPath = `${libraryDirectory}/${
      settings.printerFolderName || i18n.t('settings.virtualPrinter.folderNameDefault')
    }`;

    const dirPathsToEnsure = [watchPath, libraryDirectory];

    // Create folders if they don't exist
    await Promise.all(dirPathsToEnsure.map((path) => window.electron.ensureDir(path)));

    // Create symlink between app and printer folder
    if (window.electron.createSymlink(watchPath, printerDirectoryPath)) {
      logger.info('DESKTOP', 'Symlink created');
      logger.info('DESKTOP', `${printerDirectoryPath} has been created`);
    }
  } catch (error) {
    logger.error('DESKTOP', 'Cannot verify if virtual printer has been installed', error);
  }
}
