import { all, put, takeEvery } from 'redux-saga/effects';

import { List, Practitioner } from '@honestica/core-apps-common/types';

import { getPractitioners } from '@api';

import {
  FindPractitioners,
  findPractitioners,
  findPractitionersFailure,
  findPractitionersSuccess,
} from './practitionerDirectory.action';

export function* findPractitionersAsync({ payload: searchParams }: ReturnType<FindPractitioners>) {
  try {
    const practitioners: List<Practitioner> = yield getPractitioners(searchParams);
    yield put(findPractitionersSuccess(practitioners));
  } catch (e) {
    yield put({ type: findPractitionersFailure.type });
  }
}

export function* practitionerDirectorySagas() {
  yield all([takeEvery(findPractitioners.type, findPractitionersAsync)]);
}
