import { NonParsedRegistryItem } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';

const LIFEN_VALUE = 'Lifen';

export async function setPdfCreatorProfileTargetDirectory(directory: string) {
  try {
    logger.info('DESKTOP', 'Setting PDFCreator Profile TargetDirectory');

    const profiles = await window.electron.getUserPdfCreatorProfilesRegistryAccessor();

    profiles.forEach((p: NonParsedRegistryItem) => setLifenProfileTargetDirectory(p, directory));
  } catch (err) {
    logger.error('DESKTOP', 'Could not set PDFCreator Profile TargetDirectory', err);
  }
}

const TARGET_DIRECTORY_KEY = 'TargetDirectory';

export async function setLifenProfileTargetDirectory(
  registryItem: NonParsedRegistryItem,
  directory: string,
) {
  const name = await registryItem.get('Name');

  if (LIFEN_VALUE !== name.value) return;

  logger.info('DESKTOP', `Lifen Profile found (PDFCreator)`);

  if (!registryItem.set) return;

  logger.info(
    'DESKTOP',
    `${TARGET_DIRECTORY_KEY} for Lifen Profile is going to be set with value: ${directory} (PDFCreator)`,
  );

  registryItem.set!(TARGET_DIRECTORY_KEY, 'REG_SZ', directory);
}
