import { useMemo, useState } from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import cx from 'classnames';

import Button from '../Button';

import styles from './SimplePagination.module.less';

function noop() {}

export interface SimplePaginationProps {
  hasMoreBefore: boolean;
  hasMoreAfter: boolean;
  isLoading?: boolean;
  hideOnSinglePage?: boolean;
  onChange?: (reversed: boolean) => void;
  page?: number;
  perPage?: number;
  total?: number;
  className?: string;
  'data-testid'?: string;
}

export const SimplePagination = ({
  hasMoreBefore,
  hasMoreAfter,
  isLoading,
  total,
  page = 1,
  perPage = 20,
  hideOnSinglePage = false,
  onChange = noop,
  className = '',
  ...rest
}: SimplePaginationProps) => {
  const [currentPage, setCurrentPage] = useState(page);
  const isPrevDisabled = useMemo(
    () => isLoading || !hasMoreBefore || currentPage === 1,
    [currentPage, hasMoreBefore, isLoading],
  );
  const isNextDisabled = useMemo(() => !hasMoreAfter || isLoading, [hasMoreAfter, isLoading]);

  // When hideOnSinglePage is true and there is only one page, hide the pager
  if (hideOnSinglePage === true && !hasMoreAfter && !hasMoreBefore) {
    return null;
  }

  const hasPrev = () => !!hasMoreBefore && currentPage > 1;
  const hasNext = () => !!hasMoreAfter;

  const handleChange = (reversed = false) => {
    onChange?.(reversed);
  };

  const onPrevious = () => {
    if (hasPrev()) {
      setCurrentPage(currentPage - 1);
      handleChange(true);
    }
  };

  const onNext = () => {
    if (hasNext()) {
      setCurrentPage(currentPage + 1);
      handleChange();
    }
  };

  const totalText = total && (
    <div className={styles.paginationTotal} role="navigation" aria-label="Pagination Navigation">
      {total === 0 ? 0 : (currentPage - 1) * perPage + 1} -{' '}
      {currentPage * perPage > total ? total : currentPage * perPage} sur {total} documents
    </div>
  );

  return (
    <div className={cx(styles.paginationContainer, className)} {...rest}>
      <Button
        icon={<LeftOutlined />}
        onClick={onPrevious}
        className={styles.previous}
        disabled={isPrevDisabled}
        aria-label="prev page"
        aria-disabled={isPrevDisabled}
        tabIndex={0}
      />
      <Button
        icon={<RightOutlined />}
        onClick={onNext}
        className={styles.next}
        disabled={isNextDisabled}
        aria-label="next page"
        aria-disabled={isNextDisabled}
        tabIndex={0}
      />
      {totalText}
    </div>
  );
};
