import { DesktopSettingsRetrieved } from '@launcher/helpers/desktop';
import { DesktopSettings } from '@launcher/utils';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { initialState } from './desktop.state';

export const desktopSlice = createSlice({
  name: 'desktop',
  initialState,
  reducers: {
    closeWatchers: (state) => ({ ...state, watchingFolders: false }),

    setSettings: (state, action: SetDesktopSettingsAction) => ({
      ...state,
      loadingSettings: false,
      settings: action.payload.value,
      immutableSettings: action.payload.immutables,
    }),
    initWatchers: (state, _action: PayloadAction<DesktopSettings>) => state,
    startWatchers: (state) => ({ ...state, watchingFolders: true }),

    updateSettings: (state, action: UpdateDesktopSettingsAction) => ({
      ...state,
      loadingSettings: false,
      settings: {
        ...state.settings,
        ...action.payload,
      },
    }),

    setDesktopIsReady: (state) => ({
      ...state,
      desktopFeaturesInitialized: true,
    }),
  },
});

export const desktopReducer = desktopSlice.reducer;

export type SetDesktopSettingsPayload = DesktopSettingsRetrieved;
export type UpdateDesktopSettingsPayload = Partial<DesktopSettings>;

export type UpdateDesktopSettingsAction = PayloadAction<UpdateDesktopSettingsPayload>;
export type SetDesktopSettingsAction = PayloadAction<SetDesktopSettingsPayload>;
