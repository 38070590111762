import { http } from '@services/http';

import {
  List,
  Organization,
  Practitioner,
  ProfessionalBaseType,
  SearchParams,
} from '@honestica/core-apps-common/types';

export const CONTACTS_PATH = '/contacts';

export function getContacts(
  searchParams: SearchParams,
): Promise<List<Practitioner | Organization>> {
  const noCache = true;
  return http.get({ path: CONTACTS_PATH, searchParams }, noCache);
}

export async function addContact(params: {
  resourceId: string;
  resourceType: ProfessionalBaseType;
}) {
  return await http.post({ path: CONTACTS_PATH, body: params });
}

export async function removeContact(contactId: number): Promise<Practitioner | Organization> {
  return await http.destroy({ path: `${CONTACTS_PATH}/${contactId}` });
}
