export enum ApiErrorsKey {
  BINARY_LOCATION_NOT_FOUND = 'BINARY_LOCATION_NOT_FOUND',
  BODY_INVALID_TYPE = 'BODY_INVALID_TYPE',
  COMMUNICATIONS_BASED_ON_COMMUNICATION_REQUEST_ID_NOT_FOUND = 'COMMUNICATIONS_BASED_ON_COMMUNICATION_REQUEST_ID_NOT_FOUND',
  COMREQ_INVALID_ID = 'COMREQ_INVALID_ID',
  COMREQ_NOT_FOUND = 'COMREQ_NOT_FOUND',
  CONNECTOR_INVALID_ID = 'CONNECTOR_INVALID_ID',
  CONNECTOR_INVALID_TYPE = 'CONNECTOR_INVALID_TYPE',
  CONNECTOR_NOT_FOUND = 'CONNECTOR_NOT_FOUND',
  CONTACT_INVALID_RESOURCE_ID = 'CONTACT_INVALID_RESOURCE_ID',
  CONTACT_INVALID_USER_ID = 'CONTACT_INVALID_USER_ID',
  CONTACT_NOT_FOUND = 'CONTACT_NOT_FOUND',
  CONTACT_PREFERENCE_ADD_INVALID_PARAMS = 'CONTACT_PREFERENCE_ADD_INVALID_PARAMS',
  CONTACT_PREFERENCE_REMOVE_INVALID_PARAMS = 'CONTACT_PREFERENCE_REMOVE_INVALID_PARAMS',
  CONTACT_RESOURCE_NOT_FOUND = 'CONTACT_RESOURCE_NOT_FOUND',
  DEFAULT = 'DEFAULT',
  DEVICE_INVALID_ID = 'DEVICE_INVALID_ID',
  DEVICE_INVALID_NAME = 'DEVICE_INVALID_NAME',
  DEVICE_INVALID_OWNER_NAME = 'DEVICE_INVALID_OWNER_NAME',
  DEVICE_INVALID_OWNER_REFERENCE = 'DEVICE_INVALID_OWNER_REFERENCE',
  DOCREF_CREATION_FAILED = 'DOCREF_CREATION_FAILED',
  DOCREF_INVALID_PARAMS = 'DOCREF_INVALID_PARAMS',
  DOCUMENTS_INVALID_DOCUMENT = 'DOCUMENTS_INVALID_DOCUMENT',
  DOCUMENTS_NO_VERSION_ID = 'DOCUMENTS_NO_VERSION_ID',
  DOCUMENT_ALREADY_CANCELLED = 'DOCUMENT_ALREADY_CANCELLED',
  DOCUMENT_ALREADY_SENT = 'DOCUMENT_ALREADY_SENT',
  DOCUMENT_INVALID_ARGUMENT = 'DOCUMENT_INVALID_ARGUMENT',
  DOCUMENT_INVALID_ID = 'DOCUMENT_INVALID_ID',
  DOCUMENT_MISSING_DMP_SETTINGS = 'DOCUMENT_MISSING_DMP_SETTINGS',
  DOCUMENT_NOT_ARCHIVED = 'DOCUMENT_NOT_ARCHIVED',
  DOCUMENT_NOT_FOUND = 'DOCUMENT_NOT_FOUND',
  DOCUMENT_NOT_INTEGRABLE = 'DOCUMENT_NOT_INTEGRABLE',
  DOCUMENT_NOT_SENDABLE = 'DOCUMENT_NOT_SENDABLE',
  DOCUMENT_NO_RECIPIENTS = 'DOCUMENT_NO_RECIPIENTS',
  DOCUMENT_PRACTITIONER_INVALID = 'DOCUMENT_PRACTITIONER_INVALID',
  DOCUMENT_READ_ONLY = 'DOCUMENT_READ_ONLY',
  DOCUMENT_SENDING_PROHIBITED = 'DOCUMENT_SENDING_PROHIBITED',
  DOCUMENT_TOPIC_NO_INTEGRATION_PATIENT = 'DOCUMENT_TOPIC_NO_INTEGRATION_PATIENT',
  ENCOUNTER_INVALID = 'ENCOUNTER_INVALID',
  ENCOUNTER_INVALID_ID = 'ENCOUNTER_INVALID_ID',
  ENCOUNTER_INVALID_SERVICE_PROVIDER_REFERENCE = 'ENCOUNTER_INVALID_SERVICE_PROVIDER_REFERENCE',
  ENCOUNTER_INVALID_START_DATE = 'ENCOUNTER_INVALID_START_DATE',
  ENCOUNTER_NOT_FOUND = 'ENCOUNTER_NOT_FOUND',
  ENQUEUE_ROLLBACK_PATIENTS_IMPORT_JOB_FAILED = 'ENQUEUE_ROLLBACK_PATIENTS_IMPORT_JOB_FAILED',
  FALLBACK_ORGANIZATION_NO_TELECOMS = 'FALLBACK_ORGANIZATION_NO_TELECOMS',
  FILE_INVALID = 'FILE_INVALID',
  HEALTHCHECK_FAILURE = 'HEALTHCHECK_FAILURE',
  IDENTITIES_FETCH_FAILED = 'IDENTITIES_FETCH_FAILED',
  IDENTITIES_INVALID = 'IDENTITIES_INVALID',
  INTEGRATION_DOCUMENT_INVALID = 'INTEGRATION_DOCUMENT_INVALID',
  INVALID_CONNECTOR_UPDATES = 'INVALID_CONNECTOR_UPDATES',
  INVALID_MACHINE_TOKEN = 'INVALID_MACHINE_TOKEN',
  INVALID_RESSOURCE = 'INVALID_RESSOURCE',
  JOB_INVALID_ID = 'JOB_INVALID_ID',
  JOB_INVALID_QUEUE = 'JOB_INVALID_QUEUE',
  JOB_NOT_FOUND = 'JOB_NOT_FOUND',
  LOGS_INVALID_FORMAT = 'LOGS_INVALID_FORMAT',
  NOT_ENOUGH_DOCUMENTS_TO_GROUP = 'NOT_ENOUGH_DOCUMENTS_TO_GROUP',
  NO_DOCREF_FOUND = 'NO_DOCREF_FOUND',
  NO_DOCUMENT_ATTACHEMENT = 'NO_DOCUMENT_ATTACHEMENT',
  ORGANIZATION_NOT_FOUND = 'ORGANIZATION_NOT_FOUND',
  PATIENTS_IMPORT_JOB_INVALID_CSV = 'PATIENTS_IMPORT_JOB_INVALID_CSV',
  PATIENTS_IMPORT_JOB_INVALID_TEMPLATE = 'PATIENTS_IMPORT_JOB_INVALID_TEMPLATE',
  PATIENTS_IMPORT_JOB_NOT_FOUND = 'PATIENTS_IMPORT_JOB_NOT_FOUND',
  PATIENT_INVALID_ID = 'PATIENT_INVALID_ID',
  PATIENT_INVALID_ID_LIST = 'PATIENT_INVALID_ID_LIST',
  PATIENT_INVALID_RESOURCE = 'PATIENT_INVALID_RESOURCE',
  PATIENT_NOT_FOUND = 'PATIENT_NOT_FOUND',
  PRACTITIONER_NOT_FOUND = 'PRACTITIONER_NOT_FOUND',
  PRACTITIONER_ROLE_INVALID_ID = 'PRACTITIONER_ROLE_INVALID_ID',
  PRACTITIONER_ROLE_NOT_FOUND = 'PRACTITIONER_ROLE_NOT_FOUND',
  PROFESSIONAL_BASE_INVALID_RESOURCE = 'PROFESSIONAL_BASE_INVALID_RESOURCE',
  PROFESSIONAL_BASE_INVALID_RESOURCE_TYPE = 'PROFESSIONAL_BASE_INVALID_RESOURCE_TYPE',
  SECURE_LINK_EXPIRED = 'SECURE_LINK_EXPIRED',
  SECURE_LINK_UNABLE_TO_FIND_PHONE_NUMBER = 'SECURE_LINK_UNABLE_TO_FIND_PHONE_NUMBER',
  SECURE_LINK_UNAUTHORIZED = 'SECURE_LINK_UNAUTHORIZED',
  STRING_LITERAL_INVALID_VALUE = 'STRING_LITERAL_INVALID_VALUE',
  SUPPORT_TICKET_INVALID_FORMAT = 'SUPPORT_TICKET_INVALID_FORMAT',
  TELECOM_INVALID_RESOURCE = 'TELECOM_INVALID_RESOURCE',
  TELECOM_INVALID_TYPE = 'TELECOM_INVALID_TYPE',
  TRYING_TO_SPLIT_SINGLE_DOCUMENT = 'TRYING_TO_SPLIT_SINGLE_DOCUMENT',
  UNKNOWN_SETTING = 'UNKNOWN_SETTING',
  USER_IDENTITY_NOT_FOUND = 'USER_IDENTITY_NOT_FOUND',
  USER_INVALID_API_DOMAIN = 'USER_INVALID_API_DOMAIN',
  USER_INVALID_ID = 'USER_INVALID_ID',
  USER_INVALID_IDENTITIES = 'USER_INVALID_IDENTITIES',
  USER_INVALID_TOKEN = 'USER_INVALID_TOKEN',
  WORKSPACE_ID_INVALID = 'WORKSPACE_ID_INVALID',
}

export enum CustomApiErrorsKey {
  FHIR_ERROR = 'FHIR_ERROR',
  UM_ERROR = 'UM_ERROR',
  ZENDESK_API_ERROR = 'ZENDESK_API_ERROR',
  CONTACT_PREFERENCE_ADD_FAILURE = 'CONTACT_PREFERENCE_ADD_FAILURE',
  CONTACT_PREFERENCE_REMOVE_FAILURE = 'CONTACT_PREFERENCE_REMOVE_FAILURE',
}

export type IApiError = {
  key: ApiErrorsKey;
  message: string;
  statusCode: number;
};

export const API_ERRORS: Record<keyof typeof ApiErrorsKey, IApiError> = {
  [ApiErrorsKey.DEFAULT]: {
    key: ApiErrorsKey.DEFAULT,
    message: 'Internal server error',
    statusCode: 500,
  },
  [ApiErrorsKey.BODY_INVALID_TYPE]: {
    key: ApiErrorsKey.BODY_INVALID_TYPE,
    message: 'Body type is not valid. Object is expected',
    statusCode: 400,
  },

  [ApiErrorsKey.BINARY_LOCATION_NOT_FOUND]: {
    key: ApiErrorsKey.BINARY_LOCATION_NOT_FOUND,
    message: 'Binary location not found',
    statusCode: 500,
  },
  [ApiErrorsKey.COMREQ_INVALID_ID]: {
    key: ApiErrorsKey.COMREQ_INVALID_ID,
    message: 'CommunicationRequest id is not valid. A string is expected',
    statusCode: 422,
  },
  [ApiErrorsKey.CONTACT_INVALID_USER_ID]: {
    key: ApiErrorsKey.CONTACT_INVALID_USER_ID,
    message: 'Invalid contact id',
    statusCode: 422,
  },
  [ApiErrorsKey.CONTACT_NOT_FOUND]: {
    key: ApiErrorsKey.CONTACT_NOT_FOUND,
    message: 'Contact not found',
    statusCode: 404,
  },
  [ApiErrorsKey.NO_DOCUMENT_ATTACHEMENT]: {
    key: ApiErrorsKey.NO_DOCUMENT_ATTACHEMENT,
    message: 'No downloadable file',
    statusCode: 404,
  },
  [ApiErrorsKey.CONTACT_RESOURCE_NOT_FOUND]: {
    key: ApiErrorsKey.CONTACT_RESOURCE_NOT_FOUND,
    message: 'Contact resource not found',
    statusCode: 404,
  },
  [ApiErrorsKey.CONTACT_INVALID_RESOURCE_ID]: {
    key: ApiErrorsKey.CONTACT_INVALID_RESOURCE_ID,
    message: 'Invalid resourceId, a string is expected',
    statusCode: 422,
  },
  [ApiErrorsKey.FILE_INVALID]: {
    key: ApiErrorsKey.FILE_INVALID,
    message: 'Invalid file, a binary is expected',
    statusCode: 422,
  },

  [ApiErrorsKey.HEALTHCHECK_FAILURE]: {
    key: ApiErrorsKey.HEALTHCHECK_FAILURE,
    message: 'The database is not available',
    statusCode: 500,
  },
  [ApiErrorsKey.INVALID_RESSOURCE]: {
    key: ApiErrorsKey.INVALID_RESSOURCE,
    message: 'Provided ressource is not valid',
    statusCode: 422,
  },
  [ApiErrorsKey.CONTACT_PREFERENCE_ADD_INVALID_PARAMS]: {
    key: ApiErrorsKey.CONTACT_PREFERENCE_ADD_INVALID_PARAMS,
    message: 'Missing Parameters',
    statusCode: 422,
  },
  [ApiErrorsKey.CONTACT_PREFERENCE_REMOVE_INVALID_PARAMS]: {
    key: ApiErrorsKey.CONTACT_PREFERENCE_REMOVE_INVALID_PARAMS,
    message: 'Missing Parameters',
    statusCode: 422,
  },
  [ApiErrorsKey.DOCREF_INVALID_PARAMS]: {
    key: ApiErrorsKey.DOCREF_INVALID_PARAMS,
    message: 'Document Reference Invalid  Params',
    statusCode: 422,
  },
  [ApiErrorsKey.DOCREF_CREATION_FAILED]: {
    key: ApiErrorsKey.DOCREF_CREATION_FAILED,
    message: 'Error during DocumentReference creation',
    statusCode: 400,
  },
  [ApiErrorsKey.DOCUMENTS_INVALID_DOCUMENT]: {
    key: ApiErrorsKey.DOCUMENTS_INVALID_DOCUMENT,
    message: 'The provided document is not valid',
    statusCode: 422,
  },
  [ApiErrorsKey.DOCUMENT_ALREADY_CANCELLED]: {
    key: ApiErrorsKey.DOCUMENT_ALREADY_CANCELLED,
    message: 'The document as already been deleted',
    statusCode: 403,
  },
  [ApiErrorsKey.DOCUMENT_ALREADY_SENT]: {
    key: ApiErrorsKey.DOCUMENT_ALREADY_SENT,
    message: 'The document as already been sent',
    statusCode: 400,
  },
  [ApiErrorsKey.DOCUMENT_NOT_SENDABLE]: {
    key: ApiErrorsKey.DOCUMENT_NOT_SENDABLE,
    message: 'The document could not be sent',
    statusCode: 403,
  },
  [ApiErrorsKey.DOCUMENT_INVALID_ID]: {
    key: ApiErrorsKey.DOCUMENT_INVALID_ID,
    message: 'You provided an invalid document ID',
    statusCode: 400,
  },
  [ApiErrorsKey.DOCUMENTS_NO_VERSION_ID]: {
    key: ApiErrorsKey.DOCUMENTS_NO_VERSION_ID,
    message: 'The CommunicationRequest has no versionId',
    statusCode: 422,
  },
  [ApiErrorsKey.DOCUMENT_NOT_FOUND]: {
    key: ApiErrorsKey.DOCUMENT_NOT_FOUND,
    message: 'No document was found under the provided ID',
    statusCode: 404,
  },
  [ApiErrorsKey.DOCUMENT_READ_ONLY]: {
    key: ApiErrorsKey.DOCUMENT_READ_ONLY,
    message: 'The document is read-only and cannot be modified',
    statusCode: 403,
  },
  [ApiErrorsKey.DOCUMENT_NOT_ARCHIVED]: {
    key: ApiErrorsKey.DOCUMENT_NOT_ARCHIVED,
    message: 'The provided document is not archived',
    statusCode: 422,
  },
  [ApiErrorsKey.DOCUMENT_SENDING_PROHIBITED]: {
    key: ApiErrorsKey.DOCUMENT_SENDING_PROHIBITED,
    message: 'User is not allowed to send documents',
    statusCode: 403,
  },
  [ApiErrorsKey.DOCUMENT_PRACTITIONER_INVALID]: {
    key: ApiErrorsKey.DOCUMENT_PRACTITIONER_INVALID,
    message: 'Practitioner does not match with practitioner role.',
    statusCode: 403,
  },
  [ApiErrorsKey.DOCUMENT_TOPIC_NO_INTEGRATION_PATIENT]: {
    key: ApiErrorsKey.DOCUMENT_TOPIC_NO_INTEGRATION_PATIENT,
    message:
      'You can not replace or cancel a sent DMP document without a patient integration match',
    statusCode: 422,
  },
  [ApiErrorsKey.DOCUMENT_MISSING_DMP_SETTINGS]: {
    key: ApiErrorsKey.DOCUMENT_MISSING_DMP_SETTINGS,
    message: 'You provided missing DMP settings',
    statusCode: 400,
  },
  [ApiErrorsKey.CONNECTOR_INVALID_ID]: {
    key: ApiErrorsKey.CONNECTOR_INVALID_ID,
    message: 'You provided an invalid connector ID',
    statusCode: 400,
  },
  [ApiErrorsKey.CONNECTOR_NOT_FOUND]: {
    key: ApiErrorsKey.CONNECTOR_NOT_FOUND,
    message: 'The connector was not found',
    statusCode: 404,
  },
  [ApiErrorsKey.DEVICE_INVALID_ID]: {
    key: ApiErrorsKey.DEVICE_INVALID_ID,
    message: 'Device id is not valid. A string is expected',
    statusCode: 422,
  },
  [ApiErrorsKey.DEVICE_INVALID_OWNER_REFERENCE]: {
    key: ApiErrorsKey.DEVICE_INVALID_OWNER_REFERENCE,
    message: 'Device owner reference is not valid. A string is expected',
    statusCode: 422,
  },
  [ApiErrorsKey.DEVICE_INVALID_NAME]: {
    key: ApiErrorsKey.DEVICE_INVALID_NAME,
    message: 'Device name is not valid. A string is expected',
    statusCode: 422,
  },
  [ApiErrorsKey.ENCOUNTER_INVALID_ID]: {
    key: ApiErrorsKey.ENCOUNTER_INVALID_ID,
    message: 'Encounter id is not valid. A string is expected',
    statusCode: 422,
  },
  [ApiErrorsKey.ENCOUNTER_INVALID_SERVICE_PROVIDER_REFERENCE]: {
    key: ApiErrorsKey.ENCOUNTER_INVALID_SERVICE_PROVIDER_REFERENCE,
    message: 'Encounter serviceProvider reference is not valid. A string is expected',
    statusCode: 422,
  },
  [ApiErrorsKey.ENCOUNTER_INVALID_START_DATE]: {
    key: ApiErrorsKey.ENCOUNTER_INVALID_START_DATE,
    message: 'Encounter start date is not valid. A string is expected',
    statusCode: 422,
  },
  [ApiErrorsKey.ENCOUNTER_NOT_FOUND]: {
    key: ApiErrorsKey.ENCOUNTER_NOT_FOUND,
    message: 'Could not find Encounter',
    statusCode: 404,
  },
  [ApiErrorsKey.ENCOUNTER_INVALID]: {
    key: ApiErrorsKey.ENCOUNTER_INVALID,
    message: 'Encounter is not valid',
    statusCode: 422,
  },
  [ApiErrorsKey.FALLBACK_ORGANIZATION_NO_TELECOMS]: {
    key: ApiErrorsKey.FALLBACK_ORGANIZATION_NO_TELECOMS,
    message: 'Selected fallback organization has no valid telecoms to send to',
    statusCode: 404,
  },
  [ApiErrorsKey.INTEGRATION_DOCUMENT_INVALID]: {
    key: ApiErrorsKey.INTEGRATION_DOCUMENT_INVALID,
    message: 'Provided IntegrationRequest has an invalid format',
    statusCode: 422,
  },
  [ApiErrorsKey.DOCUMENT_NOT_INTEGRABLE]: {
    key: ApiErrorsKey.DOCUMENT_NOT_INTEGRABLE,
    message: 'The document could not be integrated',
    statusCode: 403,
  },
  [ApiErrorsKey.JOB_INVALID_ID]: {
    key: ApiErrorsKey.JOB_INVALID_ID,
    message: 'jobId is not valid',
    statusCode: 422,
  },
  [ApiErrorsKey.JOB_INVALID_QUEUE]: {
    key: ApiErrorsKey.JOB_INVALID_QUEUE,
    message: 'queue is not valid',
    statusCode: 422,
  },
  [ApiErrorsKey.JOB_NOT_FOUND]: {
    key: ApiErrorsKey.JOB_NOT_FOUND,
    message: 'Job not found',
    statusCode: 404,
  },
  [ApiErrorsKey.LOGS_INVALID_FORMAT]: {
    key: ApiErrorsKey.LOGS_INVALID_FORMAT,
    message: 'Invalid logs format',
    statusCode: 422,
  },

  [ApiErrorsKey.NO_DOCREF_FOUND]: {
    key: ApiErrorsKey.NO_DOCREF_FOUND,
    message: 'Could not find Document Reference',
    statusCode: 404,
  },
  [ApiErrorsKey.COMMUNICATIONS_BASED_ON_COMMUNICATION_REQUEST_ID_NOT_FOUND]: {
    key: ApiErrorsKey.COMMUNICATIONS_BASED_ON_COMMUNICATION_REQUEST_ID_NOT_FOUND,
    message: 'Communications based on communication request id not found',
    statusCode: 404,
  },
  [ApiErrorsKey.PATIENT_INVALID_ID]: {
    key: ApiErrorsKey.PATIENT_INVALID_ID,
    message: 'Patient id is not valid',
    statusCode: 422,
  },
  [ApiErrorsKey.PATIENT_INVALID_RESOURCE]: {
    key: ApiErrorsKey.PATIENT_INVALID_RESOURCE,
    message: 'Patient resource is not valid',
    statusCode: 422,
  },
  [ApiErrorsKey.PATIENT_INVALID_ID_LIST]: {
    key: ApiErrorsKey.PATIENT_INVALID_ID_LIST,
    message: 'patient id list is not valid',
    statusCode: 422,
  },
  [ApiErrorsKey.PATIENT_NOT_FOUND]: {
    key: ApiErrorsKey.PATIENT_NOT_FOUND,
    message: 'Patient not found',
    statusCode: 404,
  },
  [ApiErrorsKey.PATIENTS_IMPORT_JOB_NOT_FOUND]: {
    key: ApiErrorsKey.PATIENTS_IMPORT_JOB_NOT_FOUND,
    message: 'No patient job found',
    statusCode: 404,
  },
  [ApiErrorsKey.PATIENTS_IMPORT_JOB_INVALID_CSV]: {
    key: ApiErrorsKey.PATIENTS_IMPORT_JOB_INVALID_CSV,
    message: 'patients import csv is not valid',
    statusCode: 422,
  },
  [ApiErrorsKey.PATIENTS_IMPORT_JOB_INVALID_TEMPLATE]: {
    key: ApiErrorsKey.PATIENTS_IMPORT_JOB_INVALID_TEMPLATE,
    message: 'patients import template is not valid',
    statusCode: 422,
  },
  [ApiErrorsKey.PROFESSIONAL_BASE_INVALID_RESOURCE]: {
    key: ApiErrorsKey.PROFESSIONAL_BASE_INVALID_RESOURCE,
    message: 'ProfessionalBase resource is not valid',
    statusCode: 422,
  },
  [ApiErrorsKey.PROFESSIONAL_BASE_INVALID_RESOURCE_TYPE]: {
    key: ApiErrorsKey.PROFESSIONAL_BASE_INVALID_RESOURCE_TYPE,
    message: 'Invalid professionalBase resource Type',
    statusCode: 422,
  },
  [ApiErrorsKey.PRACTITIONER_NOT_FOUND]: {
    key: ApiErrorsKey.PRACTITIONER_NOT_FOUND,
    message: 'Practitioner not found',
    statusCode: 404,
  },
  [ApiErrorsKey.PRACTITIONER_ROLE_INVALID_ID]: {
    key: ApiErrorsKey.PRACTITIONER_ROLE_INVALID_ID,
    message: 'PractitionerRole id is not valid. A string is expected',
    statusCode: 422,
  },
  [ApiErrorsKey.PRACTITIONER_ROLE_NOT_FOUND]: {
    key: ApiErrorsKey.PRACTITIONER_ROLE_NOT_FOUND,
    message: 'PractitionerRole not found',
    statusCode: 404,
  },
  [ApiErrorsKey.ORGANIZATION_NOT_FOUND]: {
    key: ApiErrorsKey.ORGANIZATION_NOT_FOUND,
    message: 'Organization not found',
    statusCode: 404,
  },
  [ApiErrorsKey.STRING_LITERAL_INVALID_VALUE]: {
    key: ApiErrorsKey.STRING_LITERAL_INVALID_VALUE,
    message: 'invalid string literal value',
    statusCode: 422,
  },
  [ApiErrorsKey.SUPPORT_TICKET_INVALID_FORMAT]: {
    key: ApiErrorsKey.SUPPORT_TICKET_INVALID_FORMAT,
    message: 'invalid ticket format',
    statusCode: 422,
  },
  [ApiErrorsKey.TELECOM_INVALID_TYPE]: {
    key: ApiErrorsKey.TELECOM_INVALID_TYPE,
    message: 'invalid telecom format',
    statusCode: 422,
  },
  [ApiErrorsKey.TELECOM_INVALID_RESOURCE]: {
    key: ApiErrorsKey.TELECOM_INVALID_RESOURCE,
    message: 'invalid telecom',
    statusCode: 422,
  },
  [ApiErrorsKey.USER_INVALID_ID]: {
    key: ApiErrorsKey.USER_INVALID_ID,
    message: 'userId is not valid',
    statusCode: 422,
  },
  [ApiErrorsKey.USER_IDENTITY_NOT_FOUND]: {
    key: ApiErrorsKey.USER_IDENTITY_NOT_FOUND,
    message: 'No identity found',
    statusCode: 404,
  },
  [ApiErrorsKey.USER_INVALID_API_DOMAIN]: {
    key: ApiErrorsKey.USER_INVALID_API_DOMAIN,
    message: 'User API domain is not valid, a string is expected',
    statusCode: 422,
  },
  [ApiErrorsKey.USER_INVALID_TOKEN]: {
    key: ApiErrorsKey.USER_INVALID_TOKEN,
    message: 'User token is not valid, a string is expected',
    statusCode: 422,
  },
  [ApiErrorsKey.INVALID_MACHINE_TOKEN]: {
    key: ApiErrorsKey.INVALID_MACHINE_TOKEN,
    message: 'Machine token is not valid',
    statusCode: 500,
  },
  [ApiErrorsKey.IDENTITIES_FETCH_FAILED]: {
    key: ApiErrorsKey.IDENTITIES_FETCH_FAILED,
    message: 'Alphonse identities endpoint access issue',
    statusCode: 500,
  },
  [ApiErrorsKey.USER_INVALID_IDENTITIES]: {
    key: ApiErrorsKey.USER_INVALID_IDENTITIES,
    message: 'User identities is not valid, an array is expected',
    statusCode: 422,
  },
  [ApiErrorsKey.WORKSPACE_ID_INVALID]: {
    key: ApiErrorsKey.WORKSPACE_ID_INVALID,
    message: 'WorkspaceId is not valid, a string is expected',
    statusCode: 422,
  },
  [ApiErrorsKey.SECURE_LINK_EXPIRED]: {
    key: ApiErrorsKey.SECURE_LINK_EXPIRED,
    message: 'Secure link is valid 14 days',
    statusCode: 410,
  },
  [ApiErrorsKey.SECURE_LINK_UNAUTHORIZED]: {
    key: ApiErrorsKey.SECURE_LINK_UNAUTHORIZED,
    message: 'Invalid OTP code',
    statusCode: 401,
  },
  [ApiErrorsKey.SECURE_LINK_UNABLE_TO_FIND_PHONE_NUMBER]: {
    key: ApiErrorsKey.SECURE_LINK_UNABLE_TO_FIND_PHONE_NUMBER,
    message: 'Unable to find phone number',
    statusCode: 404,
  },
  [ApiErrorsKey.NOT_ENOUGH_DOCUMENTS_TO_GROUP]: {
    key: ApiErrorsKey.NOT_ENOUGH_DOCUMENTS_TO_GROUP,
    message: 'Not enough documents to group (minimum is 2)',
    statusCode: 422,
  },
  [ApiErrorsKey.TRYING_TO_SPLIT_SINGLE_DOCUMENT]: {
    key: ApiErrorsKey.TRYING_TO_SPLIT_SINGLE_DOCUMENT,
    message: 'You are trying to split a document which is not grouped',
    statusCode: 422,
  },
  // TODO : CORE-2858 format these errors
  [ApiErrorsKey.COMREQ_NOT_FOUND]: {
    key: ApiErrorsKey.COMREQ_NOT_FOUND,
    message: 'Internal server error',
    statusCode: 500,
  },
  [ApiErrorsKey.DOCUMENT_INVALID_ARGUMENT]: {
    key: ApiErrorsKey.DOCUMENT_INVALID_ARGUMENT,
    message: 'Internal server error',
    statusCode: 500,
  },
  [ApiErrorsKey.DOCUMENT_NO_RECIPIENTS]: {
    key: ApiErrorsKey.DOCUMENT_NO_RECIPIENTS,
    message: 'Internal server error',
    statusCode: 500,
  },
  [ApiErrorsKey.CONNECTOR_INVALID_TYPE]: {
    key: ApiErrorsKey.CONNECTOR_INVALID_TYPE,
    message: 'Internal server error',
    statusCode: 500,
  },
  [ApiErrorsKey.DEVICE_INVALID_OWNER_NAME]: {
    key: ApiErrorsKey.DEVICE_INVALID_OWNER_NAME,
    message: 'Internal server error',
    statusCode: 500,
  },
  [ApiErrorsKey.ENQUEUE_ROLLBACK_PATIENTS_IMPORT_JOB_FAILED]: {
    key: ApiErrorsKey.ENQUEUE_ROLLBACK_PATIENTS_IMPORT_JOB_FAILED,
    message: 'Internal server error',
    statusCode: 500,
  },
  [ApiErrorsKey.IDENTITIES_INVALID]: {
    key: ApiErrorsKey.IDENTITIES_INVALID,
    message: 'Internal server error',
    statusCode: 500,
  }, // not used anymore
  [ApiErrorsKey.INVALID_CONNECTOR_UPDATES]: {
    key: ApiErrorsKey.INVALID_CONNECTOR_UPDATES,
    message: 'Internal server error',
    statusCode: 500,
  },
  [ApiErrorsKey.UNKNOWN_SETTING]: {
    key: ApiErrorsKey.UNKNOWN_SETTING,
    message: 'Unknown setting',
    statusCode: 500,
  },
};
