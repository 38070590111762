import { Tag as AntTag } from 'antd';
import { TagProps as AntTagProps } from 'antd/lib/tag';
import cx from 'classnames';

import styles from './Tag.module.less';

export interface TagProps extends AntTagProps {
  large?: boolean;
  bordered?: boolean;
}

export const Tag = ({
  children,
  large = false,
  bordered = false,
  className,
  ...otherProps
}: TagProps) => (
  <AntTag
    className={cx({ [styles.large]: large, [styles.noBorder]: !bordered }, className)}
    {...otherProps}
  >
    {children}
  </AntTag>
);
