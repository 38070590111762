import { DesktopSettings } from '@launcher/utils';

import { assignProp } from '@honestica/core-apps-common/utils';

const writables: (keyof DesktopSettings)[] = [
  'autoUpdateEnabled',
  'hideSupport',
  'isImportWatcherActivated',
  'keepFileDuringXDays',
  'myRequestsOnly',
  'pacUrl',
  'printerFolderName',
  'proxyMode',
  'proxyUrl',
  'ssoConnection',
  'stabilityThreshold',
  'usePolling',
  'usePollingInterval',
  'username',
  'watchPath',
];

export function filterSettingsWriteableToFS(settings: DesktopSettings) {
  const res: Partial<DesktopSettings> = {};

  for (const key of writables) {
    if (key in settings) {
      assignProp(res, settings, key);
    }
  }

  return res;
}
