import { useNotifyNetworkOffline } from '@launcher/hooks/useOffline.hook';
import { useTranslation } from 'react-i18next';

import { Alert, Button } from '@honestica/ui-kit/src';

import { useModal } from '@hooks/useModal.hook';
import { GenericError, MutationError } from '@store/errors/errors.state';
import { ModalType } from '@store/modal/modal.type';

import { ErrorMessagesReduxProps, errorMessagesConnector } from './ErrorMessages.connector';

import styles from './ErrorMessages.module.less';

const genericLabel: { [key in GenericError]: string } = {
  network: 'appstore.error.network.label',
  server: 'appstore.error.server.label',
};

function ErrorMessagesComponent(props: ErrorMessagesReduxProps) {
  const { t } = useTranslation();
  const { openModal } = useModal();

  useNotifyNetworkOffline();

  if (props.hide || (props.mutations.length === 0 && !props.generic)) {
    return <></>;
  }

  return (
    <div className={styles.root} data-testid="error-messages">
      {props.mutations.map((error) => (
        <Alert
          key={error}
          type="error"
          closable
          onClose={() => props.remove(error)}
          className={styles.alert}
          message={t(`error.mutation.${error}`)}
          action={
            error !== MutationError.dndForbidden && ( // temporary solution
              <Button
                type="link"
                className={styles.button}
                onClick={() => openModal({ modal: ModalType.helpSupport })}
              >
                {t('appstore.error.server.contact.support.label')}
              </Button>
            )
          }
        />
      ))}

      {props.generic && (
        <Alert
          data-testid="generic-error"
          type="error"
          closable
          onClose={() => props.removeGeneric()}
          className={styles.alert}
          message={t(genericLabel[props.generic])}
          action={
            <Button
              type="link"
              className={styles.button}
              onClick={() => openModal({ modal: ModalType.helpSupport })}
            >
              {t('appstore.error.server.contact.support.label')}
            </Button>
          }
        />
      )}
    </div>
  );
}

export const ErrorMessages = errorMessagesConnector(ErrorMessagesComponent);
