import { replaceAttachmentsInMemory } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { all, getContext, put, select, takeEvery } from 'redux-saga/effects';

import {
  DashboardSearchParams,
  DocumentStatus,
  DocumentVerificationStatus,
  DocumentsList,
  FeatureFlags,
  IntegrationsDashboardType,
  SendingRequestBundle,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import * as API from '@api';
import { ARCHIVE, DRAFT, INBOX, INTEGRATED, SENT, WORKLIST } from '@constants/documents.constants';
import { updateEntitiesAction } from '@store/documents/documents.actions';
import {
  selectDashboardSearchParams,
  selectDetailDocumentId,
} from '@store/documents/documents.selector';
import { ArchivedDocumentsActions } from '@store/documents/incoming/archived.slice';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';
import { IntegratedIntegrationsActions } from '@store/documents/integrations/integrated.slice';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import { selectDraftCurrentIdentityReference } from '@store/documents/outgoing/draft.selector';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { SentDocumentsActions } from '@store/documents/outgoing/sent.slice';
import {
  RealtimeCommunicationRequestUpdated,
  realtimeCommunicationRequestUpdated,
} from '@store/externalEvents/externalEvents.action';
import { selectPathName } from '@store/router/router.selector';
import { getFeatureFlags } from '@store/user/user.selector';
import { getAttachmentsFromPayload } from '@utils/attachments.util';
import {
  findDocumentsInCache,
  getDashboardDocumentsQueryKey,
  invalidateDashboardCache,
  refreshDraftCacheAfterActionOnOneDocument,
  updateDocumentInCache,
} from '@utils/caching.util';
import { professionalToIdentityReference } from '@utils/identities.util';
import { getDashboardTypeFromRoute, isDashboardRoute, isDetailRoute } from '@utils/route.utils';

import { IntegrationsActions, SendingRequestsActions } from '../documents/documents.state';

const Actions: Record<
  SendingsDashboardType | IntegrationsDashboardType,
  SendingRequestsActions | IntegrationsActions
> = {
  [INBOX]: InboxDocumentsActions,
  [ARCHIVE]: ArchivedDocumentsActions,
  [DRAFT]: DraftDocumentsActions,
  [SENT]: SentDocumentsActions,
  [WORKLIST]: WorklistIntegrationsActions, // TODO: Clean up?
  [INTEGRATED]: IntegratedIntegrationsActions, // TODO: Clean up?
};

function* refreshDocumentInCache(bundle: SendingRequestBundle) {
  const queryClient: QueryClient = yield getContext('queryClient');
  const currentIdentity: string | undefined = yield select((state) =>
    selectDraftCurrentIdentityReference(state),
  );
  const documentIdentity = professionalToIdentityReference(bundle.document.sender);
  const documentExistsInCache = findDocumentsInCache({
    dashboardType: SendingsDashboardType.Draft,
    documentId: bundle.document.id,
    identity: currentIdentity,
    queryClient,
  });

  // Document is present in cache of current view, update it to latest fetched version
  if (documentExistsInCache && documentExistsInCache.length > 0) {
    yield updateDocumentInCache({
      updatedDocument: bundle.document,
      queryClient,
    });

    // Document status changed to "Suspended", increment ready to send counter
    if (
      documentExistsInCache[0].status !== DocumentStatus.Suspended &&
      bundle.document.status === DocumentStatus.Suspended &&
      bundle.document.verificationStatus === DocumentVerificationStatus.ReadyToSend
    ) {
      yield put(
        DraftDocumentsActions.incrementReadyToSendCounter({
          identity: documentIdentity,
          count: 1,
        }),
      );
    }
  }
}

function receiveSendingRequestUpdated() {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return function* saga({ payload }: ReturnType<RealtimeCommunicationRequestUpdated>) {
    const queryClient: QueryClient = yield getContext('queryClient');
    try {
      const documentId = payload.resource.id;

      logger.info('LOGIC', `[realtime] refreshing document id: ${documentId}`);

      if (!documentId) return;

      const pathname: string = yield select(selectPathName);
      const currentDashboardType = getDashboardTypeFromRoute(pathname) as SendingsDashboardType;
      const currentIdentity: string | undefined = yield select((state) =>
        selectDraftCurrentIdentityReference(state),
      );
      const featureFlags: FeatureFlags = yield select(getFeatureFlags);

      if (
        !currentDashboardType ||
        (currentDashboardType !== SendingsDashboardType.Draft &&
          currentDashboardType !== SendingsDashboardType.Sent)
      ) {
        return;
      }

      const selectedId: string = yield select(selectDetailDocumentId, currentDashboardType);
      const searchParams: DashboardSearchParams = yield select((state) =>
        selectDashboardSearchParams(state, currentDashboardType),
      );

      const isCurrentDetailPage = isDetailRoute(pathname) && documentId === selectedId;
      const queryKey = getDashboardDocumentsQueryKey({
        dashboardType: currentDashboardType,
        identity: currentIdentity,
        search: { searchParams, featureFlags },
      });
      const currentQueriesCache = queryClient.getQueriesData<DocumentsList>(queryKey);
      const currentDocInCache = currentQueriesCache.find((query) =>
        query[1]?.entities?.documents?.find((doc) => doc.id === documentId),
      );
      const isDocumentInDashboard = !!currentDocInCache;
      const isCurrentDashboard = isDashboardRoute(pathname) && isDocumentInDashboard;

      // document is not present on current dashboard page or current detail view
      if (!isCurrentDetailPage && !isCurrentDashboard) {
        if (currentDashboardType === SendingsDashboardType.Draft) {
          // Document is not in current cache. Invalidate all other ones except current one.
          invalidateDashboardCache({
            dashboardType: SendingsDashboardType.Draft,
            type: 'inactive', // Invalidate only inactive cache (ie. not the current one)
            refetchType: 'none', // Do not refetch immediatly
            queryClient,
          });

          if (currentIdentity) {
            invalidateDashboardCache({
              dashboardType: SendingsDashboardType.Draft,
              identity: currentIdentity,
              type: 'inactive',
              refetchType: 'none',
              queryClient,
            });
          }

          yield put(DraftDocumentsActions.fetchDraftCounters);
          yield put(DraftDocumentsActions.fetchTotalReadyToSend);
        } else if (currentDashboardType === SendingsDashboardType.Sent) {
          invalidateDashboardCache({
            dashboardType: SendingsDashboardType.Sent,
            search: { searchParams, featureFlags },
            refetchType: 'all', // Refresh in background,
            queryClient,
          });

          // Document is potentially replaced. Need to refresh detail view.
          if (selectedId) {
            const bundle: SendingRequestBundle = yield API.fetchDocument(
              selectedId,
              SendingsDashboardType.Sent,
            );

            yield put(Actions[currentDashboardType].setDetailViewDocumentDto(bundle.document));
          }
        }

        return;
      }

      // Document is present on current dashboard page or current detail view, fetch latest version
      const bundle: SendingRequestBundle = yield API.fetchDocument(
        documentId,
        SendingsDashboardType.Draft,
      );

      // If document is cancelled, it's probably a splited document so we refresh the entire list
      // Otherwise, we refresh only the given document
      if (bundle.document.status === DocumentStatus.Cancelled) {
        refreshDraftCacheAfterActionOnOneDocument({
          documentId: bundle.document.id,
          currentIdentity,
          search: { searchParams, featureFlags },
          queryClient,
        });
        yield put(DraftDocumentsActions.fetchDraftCounters);
        yield put(DraftDocumentsActions.fetchTotalReadyToSend);
        yield put(DraftDocumentsActions.flushLoadingDocumentIds());
      } else {
        const fileStorageIds = replaceAttachmentsInMemory(
          getAttachmentsFromPayload(bundle),
          currentDashboardType,
        );

        yield refreshDocumentInCache(bundle);
        yield put(updateEntitiesAction(bundle.entities));

        // refresh sending request dto in detail view store
        if (documentId === selectedId) {
          yield put(Actions[currentDashboardType].setDetailViewDocumentDto(bundle.document));
        }

        yield put(
          Actions[currentDashboardType].fetchManyAttachmentsSuccess({
            id: bundle.document.id,
            fileStorageIds,
          }),
        );
      }
    } catch (err) {
      logger.error('LOGIC', '[realtime] Failed to refresh draft document', err);
    }
  };
}

export function* sendingRequestRealTimeSagas() {
  yield all([takeEvery(realtimeCommunicationRequestUpdated.type, receiveSendingRequestUpdated())]);
}
