import { Selector } from '@reduxjs/toolkit';

import {
  Encounter,
  SendingRequest,
  SendingRequestDto,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import {
  selectDetailDocument,
  selectDetailDocumentDto,
  selectDetailDocumentMeta,
  selectDetailViewState,
} from '@store/documents/documents.selector';
import {
  INITIAL_INBOX_DOCUMENT_METADATA,
  InboxDetailViewState,
  InboxDocumentMetadata,
} from '@store/documents/incoming/inbox.state';

import { State } from '../../reducer';

/*
 * DOCUMENT DETAIL VIEW GENERICS
 */

export const selectInboxDetailViewState: Selector<State, InboxDetailViewState> = (state) =>
  selectDetailViewState(state, SendingsDashboardType.Inbox) as InboxDetailViewState;

export const selectInboxDetailDocument: Selector<State, SendingRequest | undefined> = (state) =>
  selectDetailDocument(state, SendingsDashboardType.Inbox);

export const selectInboxDetailDocumentDto: Selector<State, SendingRequestDto | undefined> = (
  state,
) => selectDetailDocumentDto(state, SendingsDashboardType.Inbox);

export const selectInboxDetailDocumentMeta: Selector<State, InboxDocumentMetadata> = (state) =>
  (selectDetailDocumentMeta(state, SendingsDashboardType.Inbox) as InboxDocumentMetadata) ||
  INITIAL_INBOX_DOCUMENT_METADATA;

/*
 * INBOX DETAIL
 */

export const selectEncounter: Selector<State, Encounter | undefined> = (state) => {
  const integration = selectInboxDetailDocumentDto(state)?.integration;

  return integration?.encounter;
};

export const selectInboxDetailDocumentHasPatient: Selector<State, boolean> = (state) =>
  !!selectInboxDetailDocument(state)?.patient?.patient;

// TODO: should be improved
export const selectAreAllInboxDetailActionsDisabled: Selector<State, boolean> = (state) => {
  const document = selectInboxDetailDocument(state);
  const isDocumentFetching = selectInboxDetailViewState(state).documentRequest.isLoading;
  const isForwarding = selectInboxDetailDocumentMeta(state).forwardRequest.isLoading;

  return !document || isDocumentFetching || isForwarding;
};

export const selectInboxDetailDocumentId: Selector<State, string | undefined> = (state) =>
  selectInboxDetailDocumentDto(state)?.id;
