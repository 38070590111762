import { logger } from '@services/logger/logger.service';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import { Organization } from '@honestica/core-apps-common/types';

import { getOrganizations } from '@api';

import { getOrganizationEntities } from '../entities/entities.selector';
import { Entities } from '../entities/entities.state';

import {
  FindOrganization,
  findOrganization,
  findOrganizationFailure,
  findOrganizationSuccess,
} from './organizationDetail.action';

export function* findOrganizationsAsync({ payload }: ReturnType<FindOrganization>) {
  const organizations: Entities<Organization> = yield select(getOrganizationEntities);
  const id: string = payload;

  try {
    if (!id) {
      throw new Error('Id params is mandatory to fetch organization');
    }

    if (organizations[id]) {
      yield put(findOrganizationSuccess(organizations[id]));
      return;
    }

    const organizationList: { list: Organization[] } = yield getOrganizations({
      ids: [id],
    });

    const organization = organizationList?.list?.[0];
    if (!organization) {
      throw new Error(`Organization ${id} not found`);
    }

    yield put(findOrganizationSuccess(organization));
  } catch (error) {
    logger.error('HTTP', 'Failed to find organizations', error);
    yield put(findOrganizationFailure(error));
  }
}

export function* organizationDetailSagas() {
  yield all([takeEvery(findOrganization.type, findOrganizationsAsync)]);
}
