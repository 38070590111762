import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { NavigateFunction } from 'react-router-dom';
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import { DashboardSearchParams, SendingsDashboardType } from '@honestica/core-apps-common/types';

import { integrateIntegrationRequests } from '@api';
import { INTEGRATIONS_PATH, WORKLIST } from '@constants/documents.constants';
import { BatchAction, OneAction } from '@store/documents/documents.actions';
import {
  selectDashboardSearchParams,
  selectManyDocumentsFileStorageIds,
} from '@store/documents/documents.selector';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import { selectPathName } from '@store/router/router.selector';
import { invalidateDashboardCache } from '@utils/caching.util';

function* integrateOneIntegrationRequestSaga({ payload: { id } }: OneAction) {
  const pathname: string = yield select(selectPathName);
  const navigate: NavigateFunction = yield getContext('customNavigate');
  const queryClient: QueryClient = yield getContext('queryClient');

  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, [id], WORKLIST);
  try {
    yield call(integrateIntegrationRequests, [id]);
    yield put(WorklistIntegrationsActions.integrateOneSuccess({ id, fileStorageIds }));
    logger.info('LOGIC', `Document n°${id} was successfully integrated`);

    // Document integrated could be received in lifen sending inbox. We need to invalidate cache.
    invalidateDashboardCache({
      dashboardType: SendingsDashboardType.Inbox,
      refetchType: 'active',
      exact: false,
      queryClient,
    });

    const identityId = /integrations\/([0-9]+)\/?/i.exec(pathname)?.[1];

    navigate(`${INTEGRATIONS_PATH}/${identityId}/${WORKLIST}`);
  } catch (error) {
    logger.error('LOGIC', 'Failed to integrate document', error);
    yield put(
      WorklistIntegrationsActions.integrateOneFailure({
        id,
        error,
      }),
    );
  }
}

function* integrateManyIntegrationRequestsSaga({ payload: { ids } }: BatchAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, ids, WORKLIST);
  try {
    const searchParams: DashboardSearchParams = yield select((state) =>
      selectDashboardSearchParams(state, WORKLIST),
    );
    yield call(integrateIntegrationRequests, ids);
    yield put(WorklistIntegrationsActions.integrateManySuccess({ ids, fileStorageIds }));

    // Document integrated could be received in lifen sending inbox. We need to invalidate cache.
    invalidateDashboardCache({
      dashboardType: SendingsDashboardType.Inbox,
      refetchType: 'active',
      exact: false,
      queryClient,
    });

    yield put(
      WorklistIntegrationsActions.fetchMany({
        search: searchParams,
        clearSearch: false,
      }),
    );
  } catch (error) {
    logger.error('LOGIC', 'Failed to integrate IntegrationRequests', error);
    yield put(
      WorklistIntegrationsActions.integrateManyFailure({
        ids,
        error,
      }),
    );
  }
}

export function* integrateIntegrationRequestsSagas() {
  yield all([
    takeLatest(WorklistIntegrationsActions.integrateOne.type, integrateOneIntegrationRequestSaga),
    takeLatest(
      WorklistIntegrationsActions.integrateMany.type,
      integrateManyIntegrationRequestsSaga,
    ),
  ]);
}
