import { App } from '@launcher/App';
import { AppProviders } from '@launcher/AppProviders';
import { monitoring } from '@services/monitoring';
import ReactDOM from 'react-dom';

import './index.less';

(window.document.getElementById('SECTION_1') as HTMLElement).style.display = 'none';

monitoring.init();

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById('root'),
);
