import { useCallback, useEffect, useState } from 'react';

import { AppError } from '@launcher/constants';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Logo, Page, Typography } from '@honestica/ui-kit/src';

import { logout } from '@store/user/user.actions';
import { AuthenticationError } from '@store/user/user.state';

import styles from './ErrorPage.module.less';

interface ErrorPageProps {
  error?: Error;
  errorKey?: AuthenticationError | AppError | null;
}

export const ErrorPage = (props: ErrorPageProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [displayLogout, setdisplayLogout] = useState(false);

  const handleLogout = () => dispatch(logout());

  useEffect(() => {
    if (
      props.errorKey === AuthenticationError.ExpiredSession ||
      props.errorKey === AuthenticationError.MisconfiguredAccount ||
      props.errorKey === AuthenticationError.Login ||
      props.errorKey === AuthenticationError.Default
    ) {
      setdisplayLogout(true);
    }
  }, [props.errorKey]);

  const getErrorMessage = useCallback((error: AuthenticationError | AppError) => {
    const errorMessagesMapping: Record<AuthenticationError | AppError, string> = {
      [AuthenticationError.ExpiredSession]: 'error.authError.expiredSession',
      [AuthenticationError.MisconfiguredAccount]: 'error.authError.misconfiguredAccount',
      [AuthenticationError.Default]: 'error.authError.default',
      [AuthenticationError.Login]: 'error.authError.login',
      [AppError.InvalidEnvVariables]: 'error.invalidEnvVariables',
      [AppError.LoadingDesktopSettingsFailure]: 'error.loadingDesktopSettingsFailure',
      [AppError.Maintenance]: 'error.maintenance',
    };

    return errorMessagesMapping[error] ?? errorMessagesMapping[AuthenticationError.Default];
  }, []);

  return (
    <Page.Empty>
      <div className={styles.frame}>
        <Logo withName size={40} />
        <Typography.Text strong className="text-centered">
          {props.errorKey
            ? t(getErrorMessage(props.errorKey))
            : `${t('error.boundary.apologies')} : ${props.error?.message}`}
        </Typography.Text>
        {displayLogout ? (
          <Button type="primary" onClick={handleLogout}>
            {t('error.authError.logout')}
          </Button>
        ) : (
          <Button type="primary" onClick={() => window.location.reload()}>
            {t('refresh')}
          </Button>
        )}
      </div>
    </Page.Empty>
  );
};
