import { AGE_OF_MAJORITY } from '../constants';
import { Patient, PatientBase } from '../types/entities/patient.types';

import { diffInYears } from '.';

export const getPatientEmail = (patient: PatientBase): string | undefined =>
  patient.telecoms.email[0]?.value;

export const getPatientEns = (patient: PatientBase): string | undefined =>
  patient.telecoms.ens[0]?.value;

export const getPatientCellphone = (patient: PatientBase): string | undefined =>
  patient.telecoms.phone[0]?.value;

export const getPatientLandlinePhone = (patient: PatientBase): string | undefined =>
  patient.telecoms.landlinePhone[0]?.value;

export function arePatientEqual(patient1: PatientBase, patient2: PatientBase): boolean {
  const p1Name = parsePatientName(patient1);
  const p2Name = parsePatientName(patient2);

  return (
    p1Name?.firstName === p2Name?.firstName &&
    p1Name?.lastName === p2Name?.lastName &&
    patient1.birthdate === patient2.birthdate &&
    getPatientEmail(patient1) === getPatientEmail(patient2) &&
    JSON.stringify(patient1.address[0]?.lines) === JSON.stringify(patient2.address[0]?.lines) &&
    patient1.address[0]?.city === patient2.address[0]?.city &&
    patient1.address[0]?.postalCode === patient2.address[0]?.postalCode &&
    getPatientCellphone(patient1) === getPatientCellphone(patient2) &&
    getPatientLandlinePhone(patient1) === getPatientLandlinePhone(patient2) &&
    patient1.generalPractitioner?.id === patient2.generalPractitioner?.id
  );
}

export function isPatientMinor(patient: Patient): boolean {
  if (!patient.birthdate) return false;
  return diffInYears(patient.birthdate) < AGE_OF_MAJORITY;
}

export function arePatientsConsideredSame(p1: PatientBase, p2: PatientBase) {
  const p1Name = parsePatientName(p1);
  const p2Name = parsePatientName(p2);

  if (p1Name?.firstName !== p2Name?.firstName) return false;
  if (p1Name?.lastName !== p2Name?.lastName) return false;
  return (p1.birthdate || '') === (p2.birthdate || '');
}

export function areAllPatientsConsideredSame(patients: PatientBase[]) {
  if (patients.length < 2) return true;

  const patient = patients[0];

  for (const p of patients) {
    if (!arePatientsConsideredSame(patient, p)) return false;
  }

  return true;
}

export function parsePatientName(patient: Partial<PatientBase>) {
  if (!!patient.usualFirstname && !!patient.usualLastname)
    return {
      firstName: patient.usualFirstname,
      lastName: patient.usualLastname,
    };

  // Still gives priority to usual name
  if (!!patient.birthFirstname && !!patient.birthLastname)
    return {
      firstName: patient.usualFirstname || patient.birthFirstname,
      lastName: patient.usualLastname || patient.birthLastname,
    };

  return null;
}
