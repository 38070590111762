import { Organization } from './organization';
import { Practitioner } from './practitioner';
import { PractitionerRole } from './practitionerRole';

export type DocumentTypeInfo = {
  code: string;
  label: string;
  trigram: string;
  authorized: boolean;
};

export interface ProfessionalSettings {
  lifenFeatureSetIntegrations?: {
    mssInboxActiveOption?: boolean;
    enableDocumentTitleOption?: boolean;
    automaticIntegrationOption?: boolean;
    medicalExamTypeOption?: boolean;
    personalizedPmsiLayoutOption?: boolean;
    enableRequiredFieldsOption?: boolean;
    documentTypesSetting: DocumentTypeInfo[];
    documentTypeDefaultSetting: DocumentTypeInfo;
  };
}

/* eslint-disable @typescript-eslint/no-shadow */
export enum ProfessionalBaseType {
  Organization = 'Organization',
  Practitioner = 'Practitioner',
  PractitionerRole = 'PractitionerRole',
}
/* eslint-enable @typescript-eslint/no-shadow */
export interface ProfessionalDto {
  id: string;
  type: ProfessionalBaseType;
  practRolePractitionerReference?: string;
}
export interface ProfessionalBase extends ProfessionalDto {
  type: ProfessionalBaseType;
  id: string;
  name: string;
  qualifications?: string;
  status?: string;
  hasPostalContactPreference?: boolean;
  prefix?: string;
  featureSets?: ProfessionalSettings;
}

export type Professional = Organization | Practitioner | PractitionerRole;
