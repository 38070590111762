import { ConnectedProps, connect } from 'react-redux';

import {
  selectAreInfoMessagesHidden,
  selectInfoMessagesState,
} from '@store/info-messages/infoMessages.selector';
import { InfoMessageActions } from '@store/info-messages/infoMessages.slice';
import { State } from '@store/reducer';

export const infoMessagesConnector = connect(
  (state: State) => ({
    messages: selectInfoMessagesState(state),
    hide: selectAreInfoMessagesHidden(),
  }),
  {
    remove: InfoMessageActions.removeOne,
    cancel: InfoMessageActions.cancelOne,
  },
);

export type InfoMessagesReduxProps = ConnectedProps<typeof infoMessagesConnector>;
