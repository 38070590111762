export enum DocumentTypeSystem {
  Loinc = 'http://loinc.org',
}

export type DocumentType = LoincCodeType;

export const LoincCodes = [
  '15508-5',
  '11526-1',
  '11502-2',
  'CERT_DECL',
  '11488-4',
  '67851-6',
  '11493-4',
  '34874-8',
  'OTHER',
  '57826-0',
  '18748-4',
  '57133-1',
  '11490-0',
  '57828-6',
  '34794-8',
  '56446-8',
] as const;
export type LoincCodeType = (typeof LoincCodes)[number];

export const LoincCodeShortNames: Record<LoincCodeType, string> = {
  '15508-5': 'ACC',
  '11526-1': 'ACP',
  '11502-2': 'BIO',
  CERT_DECL: 'CERT',
  '11488-4': 'CRC',
  '67851-6': 'CRE',
  '11493-4': 'CRH',
  '34874-8': 'CRO',
  OTHER: 'DOC',
  '57826-0': 'FACT',
  '18748-4': 'IMG',
  '57133-1': 'LDA',
  '11490-0': 'LDL',
  '57828-6': 'PM',
  '34794-8': 'RCP',
  '56446-8': 'RDV',
};
