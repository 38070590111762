import { createSlice } from '@reduxjs/toolkit';

import { generateDownloadActions } from '../common/reducers/downloadDocuments.reducers';
import { updateDocumentAttachments } from '../common/reducers/fetchAttachments.reducers';
import {
  fetchDocumentsSuccess,
  fetchManyFailure,
  fetchManyIntegration,
  fetchManySuccess,
  fetchOne,
  fetchOneFailure,
  fetchOneSuccess,
  setDetailViewDocument,
  setDetailViewDocumentDto,
} from '../common/reducers/fetchDocuments.reducers';
import { updateOne, updateOneFailure } from '../common/reducers/updateMeta.reducers';
import { updateSearchParams } from '../common/reducers/updateSearchParams.reducers';
import {
  DetailViewDocumentDtoAction,
  FetchDocumentAction,
  FetchDocumentSuccessAction,
  FetchDocumentsAndNavigateAction,
  GetDocumentAction,
  GetDocumentSuccessAction,
  OneAction,
  OneFailureAction,
} from '../documents.actions';

import {
  INITIAL_INTEGRATED_INTEGRATIONS_STATE,
  INITIAL_INTEGRATED_INTEGRATION_DOCUMENT_METADATA,
  IntegratedIntegrationRequestMetadataKey,
} from './integrated.state';

const slice = createSlice({
  name: 'integratedIntegrations',
  initialState: INITIAL_INTEGRATED_INTEGRATIONS_STATE,
  reducers: {
    /*
     * DASHBOARD
     */

    /*
     * View
     */

    fetchMany: fetchManyIntegration,
    fetchManySuccess: (state, _) =>
      fetchManySuccess(state, _, INITIAL_INTEGRATED_INTEGRATION_DOCUMENT_METADATA),
    fetchManyFailure,
    fetchDocumentsSuccess: (state, _) =>
      fetchDocumentsSuccess(state, _, INITIAL_INTEGRATED_INTEGRATION_DOCUMENT_METADATA),

    updateSearchParams,

    /*
     * DETAIL
     */

    /*
     * View
     */

    getOne: (state, _action: GetDocumentAction) => state,
    setDetailViewDocument: (state, action: GetDocumentSuccessAction) =>
      setDetailViewDocument(state, action, INITIAL_INTEGRATED_INTEGRATIONS_STATE.detailView),
    setDetailViewDocumentDto: (state, action: DetailViewDocumentDtoAction) =>
      setDetailViewDocumentDto(
        state,
        action,
        INITIAL_INTEGRATED_INTEGRATIONS_STATE.detailView,
        INITIAL_INTEGRATED_INTEGRATION_DOCUMENT_METADATA,
      ),

    fetchOne,
    fetchOneWithIntegrationData: (state, action: FetchDocumentAction) =>
      updateOne(state, action, IntegratedIntegrationRequestMetadataKey.fetchAttachmentsRequest), // to update detail integration data + attachments of document that have integration data (use the same saga but a different reducer)
    fetchOneSuccess: (state, action: FetchDocumentSuccessAction) =>
      fetchOneSuccess(state, action, INITIAL_INTEGRATED_INTEGRATION_DOCUMENT_METADATA), // updates entities
    fetchOneFailure,

    fetchManyAttachments: (state, action: OneAction) =>
      updateOne(state, action, IntegratedIntegrationRequestMetadataKey.fetchAttachmentsRequest),
    fetchManyAttachmentsSuccess: updateDocumentAttachments,
    fetchManyAttachmentsFailure: (state, action: OneFailureAction) =>
      updateOneFailure(
        state,
        action,
        IntegratedIntegrationRequestMetadataKey.fetchAttachmentsRequest,
      ),

    fetchManyAndNavigate: (state, _action: FetchDocumentsAndNavigateAction) => state,
  },
});

export const integrationsIntegratedReducer = slice.reducer;

export const IntegratedIntegrationsActions = {
  ...slice.actions,
  ...generateDownloadActions(slice.name),
};
