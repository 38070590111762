import { ERROR_DIRECTORY } from '@launcher/constants';
import { getSettingsFromFS } from '@launcher/helpers/desktop';
import { DesktopSettings } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';
import { all, takeLatest } from 'redux-saga/effects';

import { DesktopActions } from '../desktop.action';

function* showOutboxErrorFolderSaga(
  action: ReturnType<typeof DesktopActions.showOutboxErrorFolder>,
) {
  // Cannot read settings from store because it is not on the same window
  const settings: Partial<DesktopSettings> = yield getSettingsFromFS();

  const { watchPath } = settings;

  if (!watchPath) {
    logger.error(
      'DESKTOP',
      `Cannot show error folder without ${action.payload} watchPath in settings`,
    );
    return;
  }

  try {
    logger.info('DESKTOP', 'Open outbox error folder');
    const folderPath = window.electron.joinPaths(
      watchPath,
      ERROR_DIRECTORY,
      action.payload.fileName || '',
    );
    window.electron.showItemInFolder(folderPath);
  } catch (err) {
    logger.error('DESKTOP', 'Cannot not show outbox error folder', err);
  }
}

export function* showOutboxErrorFolderSagas() {
  yield all([takeLatest(DesktopActions.showOutboxErrorFolder.type, showOutboxErrorFolderSaga)]);
}
