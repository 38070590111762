import { DesktopSettings, bufferToFile, convertFileToDocumentJob } from '@launcher/utils';
import { tracking } from '@services/analytics';
import { logger } from '@services/logger/logger.service';
import { put, select } from 'redux-saga/effects';

import { FlowType, Professional } from '@honestica/core-apps-common/types';

import { selectDesktopSettings } from '@store/desktop/desktop.selector';
import { selectDraftCurrentIdentity } from '@store/documents/outgoing/draft.selector';
import { notificationAddPending } from '@store/notificationWindow/notificationWindow.action';
import { UploadDocumentsActions } from '@store/uploadDocuments/uploadDocuments.slice';
import {
  getIsLifenSendingActivated,
  selectSelectedOrDefaultIdentity,
} from '@store/user/user.selector';

export function createUploadAllDocsSaga(flowType: FlowType) {
  return function* uploadAllDocsSaga() {
    const isAppActivated: boolean = yield select(getIsLifenSendingActivated);

    if (!isAppActivated) {
      return;
    }

    logger.info('DESKTOP', `Checking existing files in ${flowType} folder.`);

    const settings: DesktopSettings = yield select(selectDesktopSettings);

    const filePaths = window.electron.getAllFilePathsFromDir(settings.watchPath);
    const cleanFilePaths = filePaths.filter(
      (path) =>
        window.electron.statFile(path).isFile() &&
        window.electron.getBaseName(path) !== '.DS_Store',
    );

    if (cleanFilePaths.length < 1) return;

    logger.info(
      'DESKTOP',
      `Processing ${cleanFilePaths.length} existing files in ${flowType} folder.`,
    );

    if (cleanFilePaths.length > 1) {
      tracking.trackBatchFileUploadStart({
        documentsCount: cleanFilePaths.length,
        uploadSource: 'opening',
      });
    }

    for (const path of cleanFilePaths) {
      try {
        const fileName = window.electron.getBaseName(path);

        logger.info('DESKTOP', `New file at ${flowType} watchPath`);

        const file = bufferToFile(window.electron.readFileInWatchPath(fileName), fileName);
        const sender: Professional = yield select((state) =>
          selectSelectedOrDefaultIdentity(state, { flowType }),
        );

        yield put(notificationAddPending({ count: 1, flowType }));

        yield put(
          UploadDocumentsActions.addUploadJob(
            convertFileToDocumentJob({
              sender,
              fileName: file.name,
              filePath: path,
              file,
              uploadSource: 'watcher',
              flowType,
            }),
          ),
        );
      } catch (err) {
        logger.error(
          'DESKTOP',
          `Failed to process existing file in ${flowType} folder.`,
          err,
          false,
        );
      }
    }
  };
}

export function createUploadDocumentFromWatcherSaga(flowType: FlowType) {
  return function* uploadDocumentFromWatcherSaga(
    data: ReturnType<typeof UploadDocumentsActions.uploadFromWatcher>,
  ) {
    try {
      const fileName = window.electron.getBaseName(data.payload);

      const file = bufferToFile(window.electron.readFileInWatchPath(fileName), fileName);

      const sender: Professional = yield select((state) =>
        selectSelectedOrDefaultIdentity(state, { flowType }),
      );

      yield put(
        UploadDocumentsActions.addUploadJob(
          convertFileToDocumentJob({
            sender,
            fileName: file.name,
            filePath: data.payload,
            file,
            uploadSource: 'watcher',
            flowType,
          }),
        ),
      );
    } catch (err) {
      logger.error('LOGIC', 'Failed to upload document from watcher', err, false);
    }
  };
}

export function createUploadDocumentFromButtonSaga(flowType: FlowType) {
  return function* uploadDocumentFromButtonSaga(
    data: ReturnType<typeof UploadDocumentsActions.uploadFromButton>,
  ) {
    yield put(notificationAddPending({ count: 1, flowType }));
    let sender: Professional;

    const currentIdentity: Professional = yield select((state) =>
      selectDraftCurrentIdentity(state),
    );

    if (flowType === 'sending' && currentIdentity) {
      // identity centric
      sender = currentIdentity;
    } else {
      sender = yield select((state) => selectSelectedOrDefaultIdentity(state, { flowType }));
    }

    yield put(
      UploadDocumentsActions.addUploadJob(
        convertFileToDocumentJob({
          sender,
          fileName: data.payload.name,
          file: data.payload,
          uploadSource: 'button',
          flowType,
        }),
      ),
    );
  };
}

export function createUploadDocumentFromDnDSaga(flowType: FlowType) {
  return function* uploadDocumentsFromDnDSaga(
    data: ReturnType<typeof UploadDocumentsActions.uploadFromDnD>,
  ) {
    yield put(notificationAddPending({ count: data.payload.length, flowType }));

    let sender: Professional;
    const currentIdentity: Professional = yield select((state) =>
      selectDraftCurrentIdentity(state),
    );

    if (flowType === 'sending' && currentIdentity) {
      sender = currentIdentity;
    } else {
      sender = yield select((state) => selectSelectedOrDefaultIdentity(state, { flowType }));
    }

    for (const file of data.payload) {
      yield put(
        UploadDocumentsActions.addUploadJob(
          convertFileToDocumentJob({
            sender,
            fileName: file.name,
            file,
            uploadSource: 'drop',
            flowType,
          }),
        ),
      );
    }
  };
}
