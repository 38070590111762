/**
 * Generated from https://ant.design/docs/spec/colors.
 * Shades go from lighter [0] to darker [9], and [5] is the base color.
 */

export type ColorName =
  | 'white'
  | 'black'
  | 'red'
  | 'volcano'
  | 'orange'
  | 'gold'
  | 'yellow'
  | 'lime'
  | 'green'
  | 'cyan'
  | 'blue'
  | 'geekblue'
  | 'purple'
  | 'magenta'
  | 'brand'
  | 'primary'
  | 'grey';

const colors: { [key in ColorName]: string[] } = {
  white: ['#ffffff'], // shorthand
  black: ['#000000'], // shorthand
  red: [
    '#fff1f0', // light
    '#ffccc7',
    '#ffa39e',
    '#ff7875',
    '#ff4d4f',
    '#f5222d', // base
    '#cf1322',
    '#a8071a',
    '#820014',
    '#5c0011', // dark
  ],
  volcano: [
    '#fff2e8', // light
    '#ffd8bf',
    '#ffbb96',
    '#ff9c6e',
    '#ff7a45',
    '#fa541c', // base
    '#d4380d',
    '#ad2102',
    '#871400',
    '#610b00', // dark
  ],
  orange: [
    '#fff7e6', // light
    '#ffe7ba',
    '#ffd591',
    '#ffc069',
    '#ffa940',
    '#fa8c16', // base
    '#d46b08',
    '#ad4e00',
    '#873800',
    '#612500', // dark
  ],
  gold: [
    '#fffbe6', // light
    '#fff1b8',
    '#ffe58f',
    '#ffd666',
    '#ffc53d',
    '#faad14', // base
    '#d48806',
    '#ad6800',
    '#874d00',
    '#613400', // dark
  ],
  yellow: [
    '#feffe6', // light
    '#ffffb8',
    '#fffb8f',
    '#fff566',
    '#ffec3d',
    '#fadb14', // base
    '#d4b106',
    '#ad8b00',
    '#876800',
    '#614700', // dark
  ],
  lime: [
    '#fcffe6', // light
    '#f4ffb8',
    '#eaff8f',
    '#d3f261',
    '#bae637',
    '#a0d911', // base
    '#7cb305',
    '#5b8c00',
    '#3f6600',
    '#254000', // dark
  ],
  green: [
    '#f6ffed', // light
    '#d9f7be',
    '#b7eb8f',
    '#95de64',
    '#73d13d',
    '#52c41a', // base
    '#389e0d',
    '#237804',
    '#135200',
    '#092b00', // dark
  ],
  cyan: [
    '#e6fffb', // light
    '#b5f5ec',
    '#87e8de',
    '#5cdbd3',
    '#36cfc9',
    '#13c2c2', // base
    '#08979c',
    '#006d75',
    '#00474f',
    '#002329', // dark
  ],
  blue: [
    '#e8fafe', // light
    '#bae7ff',
    '#97ccd3',
    '#69c0ff',
    '#40a9ff',
    '#1890ff', // base
    '#096dd9',
    '#0050b3',
    '#003a8c',
    '#002766', // dark
  ],
  geekblue: [
    '#f0f5ff', // light
    '#d6e4ff',
    '#adc6ff',
    '#85a5ff',
    '#597ef7',
    '#2f54eb', // base
    '#1d39c4',
    '#10239e',
    '#061178',
    '#030852', // dark
  ],
  purple: [
    '#f9f0ff', // light
    '#efdbff',
    '#d3adf7',
    '#b37feb',
    '#9254de',
    '#722ed1', // base
    '#531dab',
    '#391085',
    '#22075e',
    '#120338', // dark
  ],
  magenta: [
    '#fff0f6', // light
    '#ffd6e7',
    '#ffadd2',
    '#ff85c0',
    '#f759ab',
    '#eb2f96', // base
    '#c41d7f',
    '#9e1068',
    '#780650',
    '#520339', // dark
  ],
  brand: [
    '#fff5f0', // light
    '#ffeee6',
    '#ffd1bd',
    '#ffb094',
    '#ff8e6b',
    '#ff6742', // base
    '#d94a2e',
    '#b3311d',
    '#8c1c0f',
    '#66100a', // dark
  ],
  primary: [
    '#dde3eb', // light
    '#d1d7de',
    '#b2bfd1',
    '#879fc4',
    '#6180b8',
    '#08459b', // base
    '#2a4485',
    '#192b5e',
    '#0c1638',
    '#040612', // dark
  ],
  grey: [
    '#ffffff', // white
    '#fafafa', // light
    '#f0F2f5',
    '#f0f0f0',
    '#d9d9d9',
    '#bfbfbf',
    '#8c8c8c',
    '#595959',
    '#434343',
    '#262626',
    '#1f1f1f',
    '#141414',
    '#000000', // black
  ],
};

export const SenderColors = [
  colors.red[1],
  colors.volcano[1],
  colors.blue[1],
  colors.cyan[1],
  colors.purple[1],
  colors.orange[1],
];

export default colors;
