import { ConnectedProps, connect } from 'react-redux';

import { selectDesktopSettings } from '@store/desktop/desktop.selector';
import { State } from '@store/reducer';
import { logout } from '@store/user/user.actions';
import { getIsLifenSendingActivated, getUser } from '@store/user/user.selector';

export type HelpReduxProps = ConnectedProps<typeof helpConnector>;

const mapStateToProps = (state: State) => ({
  hideSupport: selectDesktopSettings(state).hideSupport,
  lifenSendingActivated: getIsLifenSendingActivated(state),
  user: getUser(state),
});

export const helpConnector = connect(mapStateToProps, { logout });
