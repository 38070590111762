import { convertDtoToDocumentByType } from '@lifen-sending/utils';
import { Selector, createSelector } from '@reduxjs/toolkit';

import {
  IntegrationsDashboardType,
  SendingRequest,
  isIntegrationsDashboardType,
} from '@honestica/core-apps-common/types';
import { isDefined } from '@honestica/core-apps-common/utils';

import {
  ARCHIVE,
  DRAFT,
  ERRORS,
  INBOX,
  INTEGRATED,
  SENT,
  WORKLIST,
} from '@constants/documents.constants';
import { getAttachmentEntities, getEntities } from '@store/entities/entities.selector';
import { isXhrStatus } from '@store/types';
import { selectUserSettings } from '@store/user/user.selector';
import { getPageCountFromAttachments } from '@utils/attachments.util';

import { State } from '../reducer';

import {
  DocumentDashboardType,
  DocumentsStateFromType,
  SendingRequestDtoFromType,
} from './documents.state';
import { isDraftDocument } from './outgoing/draft.state';

/*
 * ROOT SELECTORS
 */

/**
 * Get a documents state
 */
export function selectDocumentsState<T extends DocumentDashboardType>(state: State, docType: T) {
  const subState = {
    [INBOX]: state.documentInbox,
    [ARCHIVE]: state.documentArchived,
    [DRAFT]: state.documentDraft,
    [SENT]: state.documentSent,
    [ERRORS]: state.documentErrored,
    [WORKLIST]: state.integrationRequestWorklist,
    [INTEGRATED]: state.integrationRequestIntegrated,
  };

  return subState[docType] as DocumentsStateFromType<T>;
}

/**
 * Get the detail view state
 */
export const selectDetailViewState = createSelector(
  selectDocumentsState,
  (docType) => docType.detailView,
);

/*
 * DASHBOARDS
 */

/**
 * Get many documents with their metadata for the dashboard view
 */
export function selectDashboardIds<T extends DocumentDashboardType>(
  documentsState: State,
  docType: T,
) {
  return selectDocumentsState(documentsState, docType).dashboardView.ids;
}

/**
 * Get many documents with their metadata for the dashboard view
 */
export function selectDashboardDocuments<T extends DocumentDashboardType>(
  state: State,
  docType: T,
) {
  const documentState = selectDocumentsState(state, docType);
  const entities = getEntities(state);

  return (
    documentState.dashboardView.ids
      // Hide documents that are being sent
      .filter((id) => {
        const entity = documentState.entities[id];
        if (!isDraftDocument(entity)) return true;
        return !entity.meta?.sendDocument.isLoading;
      })
      .map(
        (id: string | number) =>
          documentState.entities[id].resource &&
          convertDtoToDocumentByType<T>({
            document: documentState.entities[id].resource,
            docType,
            entities,
          }),
      )
      .filter(isDefined)
  );
}

export const selectIsIntegratingDocuments = createSelector(selectDocumentsState, (documentsState) =>
  documentsState.dashboardView.ids.reduce((isLoading, id) => {
    const hasOneStatusLoadingIntegrate = Object.entries(documentsState.entities[id].meta).find(
      ([meta, value]) => isXhrStatus(value) && value.isLoading && meta === 'integrateDocument',
    );
    return hasOneStatusLoadingIntegrate ? true : isLoading;
  }, false),
);

/**
 * Get the total number of documents for the specified dashboard
 */
export const selectDashboardTotalDocuments = createSelector(
  selectDocumentsState,
  (documentsState) => documentsState.dashboardView.total,
);

/**
 * Get many {@link IDocumentDto} or {@link IntegrationDocumentDto} for the dashboard view
 */
export function selectDashboardDocumentsDto<T extends DocumentDashboardType>(
  state: State,
  docType: T,
) {
  const subState = selectDocumentsState(state, docType);
  return subState.dashboardView.ids.map(
    (id) => subState.entities[id].resource,
  ) as SendingRequestDtoFromType<T>[];
}

/**
 * Get search parameters for the dashboard view
 */
export const selectDashboardSearchParams = createSelector(
  selectDocumentsState,
  (documentsState) => documentsState?.dashboardView.searchParams,
);

export const selectDetailDocumentId: Selector<State, string | undefined> = (
  state,
  docType: DocumentDashboardType | IntegrationsDashboardType,
) => selectDocumentsState(state, docType).detailView.selectedId;

/**
 * Does the Lifen Sending dashboard has {@link DashboardSearchParams} parameters
 */
export const selectDashboardHasSearchParams = (state: State, docType: DocumentDashboardType) => {
  const params = selectDashboardSearchParams(state, docType);
  if (!params) return false;
  return Boolean(
    params.selectedMediumFilter ||
      params.selectedStatusFilter ||
      params.selectedSenderFilter ||
      params.hideOtherRequesters ||
      params.defaultDateRange ||
      params.dateRange ||
      params.recipient ||
      params.searchPatient ||
      params.source,
  );
};

/**
 * Does the Lifen Integration dashboard has {@link DashboardSearchParams} parameters
 */
export const selectIntegrationDashboardHasSearchParams = (
  state: State,
  docType: IntegrationsDashboardType,
) => {
  const params = selectDashboardSearchParams(state, docType);
  return Boolean(
    params.selectedStatusFilter ||
      params.selectedSenderFilter ||
      params.defaultDateRange ||
      params.dateRange,
  );
};

export const selectReadyToSendAutomaticSending = (state: State) =>
  selectUserSettings(state).readyToSendAutomaticSending;

export const selectDashboardIsInError = createSelector(
  selectDocumentsState,
  (documentsState) => documentsState.dashboardView.documentsRequest.isInError,
);

export const selectDashboardIsLoading = createSelector(
  selectDocumentsState,
  (documentsState) => documentsState.dashboardView.documentsRequest.isLoading,
);

export function selectLoadingDocumentIds<T extends DocumentDashboardType>(
  documentsState: State,
  docType: T,
  cacheEnabled = false,
) {
  const subState = selectDocumentsState(documentsState, docType);
  return cacheEnabled
    ? subState.dashboardView.loadingDocumentIds.reduce<Record<string, boolean>>(
        (ids, id) => ({ ...ids, [id]: true }),
        {},
      )
    : subState?.dashboardView.ids.reduce<Record<string, boolean>>((ids, id) => {
        const hasOneStatusLoading = Object.values(subState.entities[id].meta).find(
          (m) => isXhrStatus(m) && m.isLoading,
        );
        return { ...ids, [id]: hasOneStatusLoading };
      }, {});
}

/**
 * Check if at least one XHR meta status of the currently displayed documents is set as `isError: true`
 * @deprecated TODO: CORE-3255 all document status must be managed in a granular way. This selector is a temporarily fix.
 */
export const selectOneXhrStatusIsInError = createSelector(
  selectDocumentsState,
  (documentsState) =>
    documentsState.dashboardView.documentsRequest.isInError ||
    documentsState.dashboardView.ids.reduce((isInError, id) => {
      const hasOneStatusInError = Object.values(documentsState.entities[id].meta).find(
        (m) => isXhrStatus(m) && m.isInError,
      );
      return hasOneStatusInError ? true : isInError;
    }, false),
);

/*
 * DETAIL VIEW
 */

/**
 * Get a document entity with its metadata
 */
export function selectDocumentEntity<T extends DocumentDashboardType>(
  documentsState: State,
  id: string,
  docType: T,
) {
  const documentState = selectDocumentsState(documentsState, docType);
  return documentState.entities[id];
}

/**
 * Get a document with its metadata
 */
export function selectDocument<T extends DocumentDashboardType>(
  documentsState: State,
  id: string,
  docType: T,
) {
  const subState = selectDocumentsState(documentsState, docType);
  const entities = getEntities(documentsState);

  if (!subState.entities[id]) {
    return undefined;
  }

  return convertDtoToDocumentByType<T>({
    docType,
    document: subState.entities[id].resource,
    entities,
  });
}

/**
 * Get the document with its metadata for the detail view
 */
export function selectDetailDocument<T extends DocumentDashboardType>(
  documentsState: State,
  docType: T,
) {
  const subState = selectDocumentsState(documentsState, docType);
  const id = subState.detailView.selectedId;

  if (!isIntegrationsDashboardType(docType)) {
    if (!subState.detailView.documentDto) {
      return undefined;
    }

    const entities = getEntities(documentsState);
    return convertDtoToDocumentByType<T>({
      docType,
      document: subState.detailView.documentDto,
      entities,
    });
  }

  if (!id || !subState.entities[id]) {
    return undefined;
  }

  return selectDocument(documentsState, id, docType);
}

/**
 * Get the {@link SendingRequestDto} or {@link IntegrationRequestDto} for the detail view
 */
export function selectDetailDocumentDto<T extends DocumentDashboardType>(state: State, docType: T) {
  const subState = selectDocumentsState(state, docType);
  const id = subState.detailView.selectedId;

  if (!isIntegrationsDashboardType(docType)) {
    return subState.detailView.documentDto as SendingRequestDtoFromType<T>;
  }

  if (!id || !subState.entities[id]) {
    return undefined;
  }
  return subState.entities[id].resource as SendingRequestDtoFromType<T>;
}

/**
 * Get the document meta for the detail view
 */
export const selectDetailDocumentMeta = createSelector(selectDocumentsState, (state) => {
  const id = state.detailView.selectedId;
  if (!id || !state.entities[id]) return undefined;
  return state.entities[id].meta;
});

/**
 * Get the fileStorageIds of many documents
 */
export function selectManyDocumentsFileStorageIds<T extends DocumentDashboardType>(
  state: State,
  ids: string[],
  docType: T,
) {
  const subState = selectDocumentsState(state, docType);
  return Object.keys(subState.entities)
    .filter((id) => ids.includes(id))
    .flatMap((id) => {
      const entity = selectDocumentEntity(state, id, docType);
      if (!entity || !('fileStorageIds' in entity.meta)) return undefined;
      return entity.meta.fileStorageIds;
    });
}

/**
 * Get the current fileStorageIds of the document opened in the detail view
 */
export const selectDetailDocumentFileStorageIds = createSelector(
  selectDetailDocumentMeta,
  (meta) => {
    if (!meta || !('fileStorageIds' in meta)) return [];
    return meta.fileStorageIds ?? [];
  },
);

/**
 * Has the current opened document in the detail view has attachments
 */
export const selectDetailDocumentHasAttachments = createSelector(
  selectDetailDocumentFileStorageIds,
  (fileStorageIds) => fileStorageIds.length > 0,
);

/**
 * Has the current opened document in the detail view has attachments or message
 */
export function selectDetailDocumentHasAttachmentsOrMessage<T extends DocumentDashboardType>(
  documentsState: State,
  docType: T,
) {
  const document = selectDetailDocument(documentsState, docType);
  const message = !isIntegrationsDashboardType(docType)
    ? (document as SendingRequest)?.message
    : undefined;
  const hasMessage = message?.html !== undefined || message?.plain !== undefined;
  return selectDetailDocumentHasAttachments(documentsState, docType) || hasMessage;
}

export const selectDetailDocumentAttachmentsAreLoading = createSelector(
  selectDetailDocumentMeta,
  (meta) => !!(meta && 'fetchAttachmentsRequest' in meta && meta.fetchAttachmentsRequest.isLoading),
);

/**
 * Get many documents
 */
export function selectManyDocuments<T extends DocumentDashboardType>(
  state: State,
  ids: string[],
  docType: T,
) {
  const subState = selectDocumentsState(state, docType);
  return Object.keys(subState.entities)
    .filter((id) => ids.includes(id))
    .flatMap((id) => {
      const entity = selectDocumentEntity(state, id, docType);
      if (!entity) return undefined;
      return entity;
    });
}

/**
 * Get document page count
 */
export const selectDocumentPageCount = (state: State, id: string) => {
  const attachments = getAttachmentEntities(state) ?? {};
  if (id in attachments) {
    return getPageCountFromAttachments(attachments[id]);
  }

  return null;
};
