import { Action } from '@reduxjs/toolkit';
import { all, getContext, takeLatest } from 'redux-saga/effects';

import {
  DocumentPatientDto,
  IntegrationRequestDto,
  PatientMatchStatus,
  SendingRequestDto,
} from '@honestica/core-apps-common/types';
import { hasHospitalBasePatientIntegration } from '@honestica/core-apps-common/utils';

import { UpdatePatientAction } from '@store/documents/documents.actions';

import { getCurrentDocument } from './utils/tracking.utils';

function* getPatient() {
  const docDto: SendingRequestDto | IntegrationRequestDto = yield getCurrentDocument();
  return docDto ? docDto.patient : undefined;
}

function* getMatchStatus() {
  const docDto: SendingRequestDto | IntegrationRequestDto = yield getCurrentDocument();
  if (docDto && hasHospitalBasePatientIntegration(docDto.integration)) {
    return docDto.patient?.ehrPatientId ? PatientMatchStatus.Found : PatientMatchStatus.NotFound;
  }
  return undefined;
}

function* trackPatientCreated(): any {
  const trackingService = yield getContext('trackingService');
  trackingService.documentPatientCreated({ patientId: undefined });
}

function* trackPatientUpdated({ payload: { patient } }: UpdatePatientAction): any {
  const trackingService = yield getContext('trackingService');
  const matchStatus: PatientMatchStatus | undefined = yield getMatchStatus();
  trackingService.patientUpdated({ patientId: patient.id, matchStatus });
}

function* trackPatientAdded({ payload: { patient } }: UpdatePatientAction): any {
  const trackingService = yield getContext('trackingService');
  trackingService.documentPatientAdded({ patientId: patient.id });
}

function* trackPatientMatchRelaunched(): any {
  const trackingService = yield getContext('trackingService');
  const matchStatus: PatientMatchStatus | undefined = yield getMatchStatus();
  trackingService.refreshPatientMatch({ matchStatus });
}

function* trackPatientRemoved(): any {
  const trackingService = yield getContext('trackingService');
  const patient: DocumentPatientDto | undefined = yield getPatient();
  const matchStatus: PatientMatchStatus | undefined = yield getMatchStatus();
  if (patient) {
    trackingService.documentPatientRemoved({ patientId: patient.id, matchStatus });
  }
}

function* trackPatientSearched(): any {
  const trackingService = yield getContext('trackingService');
  trackingService.patientSearched();
}

export function* trackingPatientSagas() {
  yield all([
    takeLatest((action: Action) => /addDocumentPatient$/.test(action.type), trackPatientAdded),
  ]);

  yield all([
    takeLatest((action: Action) => /createDocumentPatient$/.test(action.type), trackPatientCreated),
  ]);

  yield all([
    takeLatest(
      (action: Action) => /refreshPatientMatch$/.test(action.type),
      trackPatientMatchRelaunched,
    ),
  ]);

  yield all([
    takeLatest((action: Action) => /removeDocumentPatient$/.test(action.type), trackPatientRemoved),
  ]);

  yield all([
    takeLatest((action: Action) => /searchPatients$/.test(action.type), trackPatientSearched),
  ]);

  yield all([
    takeLatest((action: Action) => /updateDocumentPatient$/.test(action.type), trackPatientUpdated),
  ]);
}
