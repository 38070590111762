import { tracking } from '@services/analytics';
import { takeEvery } from 'redux-saga/effects';

import { cancelMessageToAction } from '../helpers/cancelMessageToActions.helper';
import { CancelInfoMessageAction, InfoMessageActions } from '../infoMessages.slice';

function* saga(action: CancelInfoMessageAction) {
  const callback = cancelMessageToAction[action.payload.type];

  tracking.trackCancelInfoMessage({
    payloadType: action.payload.type,
    id: action.payload.id,
  });

  if (callback) yield callback(action.payload.id);
}

export function* handleCancelMessageSagas() {
  yield takeEvery(InfoMessageActions.cancelOne.type, saga);
}
