import { logger } from '@services/logger/logger.service';
import { NavigateFunction } from 'react-router-dom';
import { all, getContext, takeEvery } from 'redux-saga/effects';

import { OpenDrawerFromStore, closeDrawerFromStore, openDrawerFromStore } from './drawer.action';

export function* onOpenDrawer({ payload }: ReturnType<OpenDrawerFromStore>) {
  const navigate: NavigateFunction = yield getContext('navigate');
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);

  logger.info('UI', 'Open Drawer', { ...payload });

  searchParams.set('drawer', payload.drawer);

  if (payload.practitionerId) {
    searchParams.set('practitionerId', payload.practitionerId);
  }

  if (payload.patientId) {
    searchParams.set('patientId', payload.patientId);
  }

  if (payload.organizationId) {
    searchParams.set('organizationId', payload.organizationId);
  }

  if (payload.addPatientRecipientFlowParams) {
    searchParams.set(
      'isAddressSelected',
      payload.addPatientRecipientFlowParams.isAddressSelected.toString(),
    );
    searchParams.set(
      'isTelecomSelected',
      payload.addPatientRecipientFlowParams.isTelecomSelected.toString(),
    );
    searchParams.set(
      'isEnsSelected',
      payload.addPatientRecipientFlowParams.isEnsSelected.toString(),
    );
  }

  if (payload.recipientReference) {
    searchParams.set('recipientReference', payload.recipientReference);
  }
  navigate(`?${searchParams.toString()}`, { replace: true });
}

export function* onCloseDrawer() {
  const navigate: NavigateFunction = yield getContext('navigate');

  const { search } = window.location;
  const searchParams = new URLSearchParams(search);

  logger.info('UI', 'Close Drawer', { drawer: searchParams.get('drawer') });

  navigate(`?`, { replace: true });
}

export function* drawerSagas() {
  yield all([
    takeEvery(closeDrawerFromStore.type, onCloseDrawer),
    takeEvery(openDrawerFromStore.type, onOpenDrawer),
  ]);
}
