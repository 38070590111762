import { http } from '@services/http/http.service';

import {
  CreatePatient,
  Patient,
  PatientsImportJobData,
  SearchParams,
} from '@honestica/core-apps-common/types';

export const PATIENTS_PATH = '/patients';

// CRUD Patient

export async function getPatient(patientId: Patient['id']) {
  return await http.get({ path: `${PATIENTS_PATH}/${patientId}` });
}

export async function getPatients(searchParams: SearchParams) {
  return await http.get({ path: PATIENTS_PATH, searchParams });
}

export async function postPatient(patient: CreatePatient) {
  return await http.post({ path: PATIENTS_PATH, body: { patient } });
}

export async function putPatient(patient: Patient) {
  return await http.put({ path: PATIENTS_PATH, body: { patient } });
}

export async function destroyPatients(patientIds: string[]) {
  return await http.destroyWithBody({ path: PATIENTS_PATH, body: { patientIds } });
}

// jobs

export async function getUserLatestPatientJobState() {
  return await http.get({ path: `${PATIENTS_PATH}/job/user` });
}

export async function postPatientsImportJob(data: PatientsImportJobData) {
  return await http.post({ path: `${PATIENTS_PATH}/job/import`, body: { ...data } });
}

export async function putPatientsImportJob(jobId: string) {
  return await http.put({ path: `${PATIENTS_PATH}/job/import/${jobId}`, body: {} });
}
