import { createReducer } from '@reduxjs/toolkit';

import {
  addGenericErrorMessage,
  addMutationErrorMessage,
  removeGenericErrorMessage,
  removeMutationErrorMessage,
} from './errors.action';
import { ERRORS_INITIAL_STATE } from './errors.state';

export const errorsReducer = createReducer(ERRORS_INITIAL_STATE, (builder) => {
  builder.addCase(addGenericErrorMessage, (state, action) => ({
    ...state,
    generic: action.payload,
  }));

  builder.addCase(addMutationErrorMessage, (state, action) => {
    if (!state.mutations.includes(action.payload)) {
      return { ...state, mutations: [...state.mutations, action.payload] };
    }

    return state;
  });

  builder.addCase(removeGenericErrorMessage, (state) => ({
    ...state,
    generic: null,
  }));

  builder.addCase(removeMutationErrorMessage, (state, action) => ({
    ...state,
    mutations: state.mutations.filter((e) => e !== action.payload),
  }));
});
