import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { NavigateFunction } from 'react-router-dom';
import { getContext, put, select, takeLatest } from 'redux-saga/effects';

import {
  DocumentStatus,
  DocumentVerificationStatus,
  Professional,
  SendingRequestBundle,
  SendingRequestDto,
  SendingsDashboardType,
  UserSettings,
} from '@honestica/core-apps-common/types';

import { forwardDocument } from '@api';
import { DRAFT_DETAIL_VIEW_PATH, SENT } from '@constants/documents.constants';
import { OneAction } from '@store/documents/documents.actions';
import {
  selectDetailDocumentDto,
  selectManyDocumentsFileStorageIds,
} from '@store/documents/documents.selector';
import { SentDocumentsActions } from '@store/documents/outgoing/sent.slice';
import { removeFromSelection } from '@store/selection/selection.action';
import { getSelectedIdentity, selectUserSettings } from '@store/user/user.selector';
import { refreshDraftDocumentsInCache } from '@utils/caching.util';
import { professionalToIdentityReference } from '@utils/identities.util';

import { DraftDocumentsActions } from '../draft.slice';

function* forwardOneDocumentSaga({ payload: { id } }: OneAction) {
  const navigate: NavigateFunction = yield getContext('customNavigate');
  const queryClient: QueryClient = yield getContext('queryClient');
  const userSettings: UserSettings = yield select(selectUserSettings);
  const selectedIdentity: Professional | undefined = yield select(
    getSelectedIdentity,
    'lifenSending',
  );
  const selectedIdentityReference = selectedIdentity
    ? selectedIdentity.practRolePractitionerReference ??
      `${selectedIdentity.type}/${selectedIdentity.id}`
    : undefined; // should always be defined when a forward happens
  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, [id], SENT);

  try {
    const res: { document: SendingRequestBundle['document'] } = yield forwardDocument(
      id,
      SendingsDashboardType.Sent,
      userSettings,
      selectedIdentityReference,
    );
    yield put(
      SentDocumentsActions.forwardOneSuccess({
        id /* should update the initial doc meta */,
        fileStorageIds,
      }),
    );
    const selectedDocumentDto: SendingRequestDto = yield select(selectDetailDocumentDto, SENT);
    const documentIdentity = professionalToIdentityReference(selectedDocumentDto.sender);

    refreshDraftDocumentsInCache({ identity: documentIdentity, queryClient });
    refreshDraftDocumentsInCache({ identity: 'all', queryClient });

    yield put(
      DraftDocumentsActions.incrementDraftCounter({
        identity: documentIdentity,
        count: 1,
      }),
    );

    if (
      selectedDocumentDto.status === DocumentStatus.Suspended &&
      selectedDocumentDto.verificationStatus === DocumentVerificationStatus.ReadyToSend
    ) {
      yield put(
        DraftDocumentsActions.incrementReadyToSendCounter({
          identity: documentIdentity,
          count: 1,
        }),
      );
    }

    yield put(removeFromSelection({ dashboard: SendingsDashboardType.Sent, id }));
    navigate(`${DRAFT_DETAIL_VIEW_PATH}/${res.document.id}`);
  } catch (error) {
    logger.error('LOGIC', 'Failed to Forward Sent Documents', error);
    yield put(
      SentDocumentsActions.forwardOneFailure({
        id,
        error,
      }),
    );
  }
}

export function* forwardSentDocumentsSagas() {
  yield takeLatest(SentDocumentsActions.forwardOne.type, forwardOneDocumentSaga);
}
