import { useMemo } from 'react';

import { getFilesToBeCleaned } from '@launcher/helpers/desktop';
import { useTranslation } from 'react-i18next';

import { Typography } from '@honestica/ui-kit/src';

import {
  CleanOutboxConfirmationModalReduxProps,
  cleanOutboxConfirmationModalConnector,
} from './CleanOutboxConfirmationModal.connector';

function CleanOutboxConfirmationModalComponent(props: CleanOutboxConfirmationModalReduxProps) {
  const { t } = useTranslation();

  const count = useMemo(
    () => getFilesToBeCleaned([props.settings.watchPath], 0).length,
    [props.settings],
  );

  return (
    <Typography.Text>
      {count > 0
        ? t('desktopSettings.cleanOutbox.modal.fileNumberWarning', { count })
        : t('desktopSettings.cleanOutbox.modal.fileNumberWarning_zero')}
    </Typography.Text>
  );
}

export const CleanOutboxConfirmationModal = cleanOutboxConfirmationModalConnector(
  CleanOutboxConfirmationModalComponent,
);
