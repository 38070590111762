import { createAction } from '@reduxjs/toolkit';

import { ProfessionalBaseType, Telecom } from '@honestica/core-apps-common/types';

export type RemoveContactPreferenceSuccess = typeof removeContactPreferenceSuccess;
export type AddContactPreferenceSuccess = typeof addContactPreferenceSuccess;

const REDUCER_NAME = 'contactPreference';

const ADD_CONTACT_PREFERENCE = `${REDUCER_NAME}/addPreference`;

export const addContactPreference = createAction<{
  data: Telecom | 'postal';
  resourceId: string;
  resourceType: ProfessionalBaseType;
}>(ADD_CONTACT_PREFERENCE);

export const addContactPreferenceSuccess = createAction<{
  data: Telecom | 'postal';
  resourceId: string;
  resourceType: ProfessionalBaseType;
}>(`${ADD_CONTACT_PREFERENCE}Success`);

export const addContactPreferenceFailure = createAction<{
  data: Telecom | 'postal';
  resourceId: string;
  resourceType: ProfessionalBaseType;
}>(`${ADD_CONTACT_PREFERENCE}Failure`);
export type AddContactPreference = typeof addContactPreference;

const REMOVE_CONTACT_PREFERENCE = `${REDUCER_NAME}/removePreference`;

export const removeContactPreference = createAction<{
  data: Telecom | 'postal';
  resourceId: string;
  resourceType: ProfessionalBaseType;
}>(REMOVE_CONTACT_PREFERENCE);

export const removeContactPreferenceSuccess = createAction<{
  data: Telecom | 'postal';
  resourceId: string;
  resourceType: ProfessionalBaseType;
}>(`${REMOVE_CONTACT_PREFERENCE}Success`);

export const removeContactPreferenceFailure = createAction<{
  data: Telecom | 'postal';
  resourceId: string;
  resourceType: ProfessionalBaseType;
}>(`${REMOVE_CONTACT_PREFERENCE}Failure`);

export type RemoveContactPreference = typeof removeContactPreference;
