import { createAction } from '@reduxjs/toolkit';

import { Organization } from '@honestica/core-apps-common/types';

const REDUCER_NAME = 'organizationDetail';

const FIND_ORGANIZATION = `${REDUCER_NAME}/findOne`;

export const findOrganization = createAction<string>(FIND_ORGANIZATION);
export const findOrganizationSuccess = createAction<Organization>(`${FIND_ORGANIZATION}Success`);
export const findOrganizationFailure = createAction<unknown>(`${FIND_ORGANIZATION}Failure`);

export type FindOrganization = typeof findOrganization;
