import { all } from 'redux-saga/effects';

import { notificationWindowSagas } from '@store/notificationWindow/notificationWindow.sagas';

import { actionDeferredSagas } from './actionDeferred/sagas';
import { contactDirectorySagas } from './contactDirectory/contactDirectory.sagas';
import { contactPreferencesSagas } from './contactPreferences/contactPreferences.sagas';
import { desktopSagas } from './desktop/sagas';
import { documentsSagas } from './documents/documents.sagas';
import { drawerSagas } from './drawer/drawer.sagas';
import { errorsSagas } from './errors/errors.sagas';
import { featureFlagsSagas } from './featureFlags/featureFlags.sagas';
import { infoMessagesSagas } from './info-messages/sagas';
import { integrationsSagas } from './integrations/integrations.sagas';
import { loggerSagas } from './logger/logger.sagas';
import { modalSagas } from './modal/modal.sagas';
import { notificationsSagas } from './notifications/notifications.sagas';
import { organizationDetailSagas } from './organizationDetail/organizationDetail.sagas';
import { organizationDirectorySagas } from './organizationDirectory/organizationDirectory.sagas';
import { patientDirectorySagas } from './patientDirectory/patientDirectory.sagas';
import { practitionerDetailSagas } from './practitionerDetail/practitionerDetail.sagas';
import { practitionerDirectorySagas } from './practitionerDirectory/practitionerDirectory.sagas';
import { sendingRequestRealTimeSagas } from './realtime/SendingRequestRealtime.saga';
import { integrationRequestRealtimeSagas } from './realtime/integrationRequestRealtime.saga';
import { sendingRealTimeSagas } from './realtime/sendingRealtime.saga';
import { searchDirectoriesSagas } from './searchDirectories/searchDirectories.sagas';
import { trackingSagas } from './tracking/sagas';
import { uploadDocumentJobsSagas } from './uploadDocuments/sagas';
import { userSagas } from './user/user.sagas';

export function* rootSaga() {
  yield all([
    ...actionDeferredSagas.map((saga) => saga()),
    ...desktopSagas.map((saga) => saga()),
    ...documentsSagas.map((saga) => saga()),
    ...infoMessagesSagas.map((saga) => saga()),
    ...uploadDocumentJobsSagas.map((saga) => saga()),
    ...trackingSagas.map((saga) => saga()),
    contactDirectorySagas(),
    contactPreferencesSagas(),
    drawerSagas(),
    errorsSagas(),
    featureFlagsSagas(),
    integrationsSagas(),
    loggerSagas(),
    modalSagas(),
    notificationWindowSagas(),
    notificationsSagas(),
    organizationDetailSagas(),
    organizationDirectorySagas(),
    patientDirectorySagas(),
    practitionerDetailSagas(),
    practitionerDirectorySagas(),
    sendingRealTimeSagas(),
    sendingRequestRealTimeSagas(),
    integrationRequestRealtimeSagas(),
    searchDirectoriesSagas(),
    userSagas(),
  ]);
}
