import { Environment, TenantKeys } from '@launcher/constants';
import { getNumber, getString } from '@launcher/utils/typeGuard.utils';
import { logger } from '@services/logger/logger.service';

const getName = (): Environment | undefined => {
  const validValues: string[] = ['octopus', 'dev', 'staging', 'review', 'post-prod', 'production'];
  const environmentVariable = getString('ENV_NAME');

  if ((environmentVariable && !validValues.includes(environmentVariable)) || !environmentVariable) {
    console.error(
      `[EnvironmentVariable] environment name must be a valid environment string. Should be ${validValues.toString()}`,
    );
    return undefined;
  }

  const { origin } = window.location;

  if (
    isE2e() &&
    (origin.includes('localhost') || origin.includes('staging') || origin.includes('review'))
  ) {
    return 'octopus';
  }

  return environmentVariable as Environment;
};

const isE2eEnv = (): boolean =>
  getName() === 'octopus' && !localStorage.getItem('LIFEN_APPS_USE_PRIVATE_URLS');

const getTenant = (): TenantKeys | undefined => {
  const validValues: string[] = ['default', 'beta'];
  const tenant = getString('ENV_TENANT');

  if ((tenant && !validValues.includes(tenant)) || !tenant) {
    console.error(
      `[EnvironmentVariable] tenant must be a valid tenant string. Should be ${validValues.toString()}`,
    );
    return undefined;
  }

  return tenant as TenantKeys;
};

const getAuth0Audience = (): string | undefined => getString('AUTH0_AUDIENCE');

const getAuth0Domain = (): string | undefined => getString('AUTH0_DOMAIN');

const getAuthIssuer = (): string | undefined => getString('AUTH_ISSUER');

// Until we can rely on a special environment for E2e tests
// We have to force some env vars to run on CircelCi
const getAuth0ClientId = (): string | undefined =>
  isE2eEnv() ? 'XO8eRtbHonDf4ZbZOUDnOSHFGuZ6n3ZK' : getString('AUTH0_CLIENTID');

const getBaseUrlUm = (): string | undefined =>
  isE2eEnv() ? 'https://admin.internal.staging.lifen.fr/settings' : getString('BASE_URL_UM');

const getBaseUrlSending = (): string | undefined => getString('BASE_URL_SENDING');

const getBaseUrlPatient = (): string | undefined => getString('BASE_URL_PATIENT');

const getBaseUrlStable = (): string | undefined => getString('BASE_URL_STABLE');

const getBaseUrlBackend = (): string | undefined => {
  if (isE2eEnv()) return 'https://api-core-apps.internal.staging.lifen.fr';

  if (process.env.REACT_APP_BACKEND_URL) return process.env.REACT_APP_BACKEND_URL; // dev local

  return getString('BASE_URL_BACKEND');
};

const getBaseUrlProxy = (): string | undefined =>
  isE2eEnv() ? 'api.internal.staging.lifen.fr' : getString('BASE_URL_PROXY');

const getBrowserHistoryName = (): string | undefined => getString('BROWSER_HISTORY_BASENAME');

const getUrlLoginCallback = (): string | undefined => {
  if (getName() === 'review' || (origin.includes('review') && getName() === 'octopus')) {
    return `${window.location.origin}/apps/`;
  }

  return getString('URL_LOGIN_CALLBACK');
};

const getDashboardDateRangeDays = (): number | undefined => getNumber('DASHBOARDS_DATE_RANGE_DAYS');

const getLdToken = (): string | undefined => getString('LD_TOKEN');

const getLDRelayUrl = (): string | undefined =>
  isE2eEnv() ? 'https://api.public.staging.lifen.fr/launchdarkly' : getString('LD_RELAY_URL');

const getPusherAppKey = (): string | undefined => getString('PUSHER_APP_KEY_BACKEND');

const getPusherWsPath = (): string | undefined => getString('PUSHER_WS_PATH');

const getSegmentKey = (): string | undefined => getString('SEGMENT_KEY');

// SENTRY_DSN is injected in the build process to event capture config errors
// https://app.circleci.com/settings/project/github/honestica/lifen-core-apps/environment-variables
const getSentryDsn = (): string | undefined => process.env.REACT_APP_SENTRY_DSN;

const isE2e = () => window.Cypress;

const isTest = () => false;

const isReady = () => {
  const hasAllRequiredEnvVariables =
    !!getName() &&
    !!getTenant() &&
    !!getAuth0Audience() &&
    !!getAuth0ClientId() &&
    !!getAuth0Domain() &&
    !!getAuthIssuer() &&
    !!getPusherAppKey() &&
    !!getLdToken() &&
    !!getSegmentKey() &&
    !!getUrlLoginCallback();

  if (!hasAllRequiredEnvVariables) {
    logger.error('LOGIC', 'missing required env variable');
    return false;
  }

  return true;
};

export const EnvManager = {
  getAuth0Audience,
  getAuth0ClientId,
  getAuth0Domain,
  getAuthIssuer,
  getBaseUrlBackend,
  getBaseUrlPatient,
  getBaseUrlProxy,
  getBaseUrlSending,
  getBaseUrlStable,
  getBaseUrlUm,
  getBrowserHistoryName,
  getDashboardDateRangeDays,
  getLdToken,
  getLDRelayUrl,
  getName,
  getPusherAppKey,
  getPusherWsPath,
  getSegmentKey,
  getSentryDsn,
  getString,
  getTenant,
  getUrlLoginCallback,
  isE2e,
  isReady,
  isTest,
};
