import { ConnectedProps, connect } from 'react-redux';

import { selectDesktopSettings } from '@store/desktop/desktop.selector';
import { State } from '@store/reducer';
import { logout } from '@store/user/user.actions';
import { getUser } from '@store/user/user.selector';

export type AboutModalReduxProps = ConnectedProps<typeof aboutModalConnector>;

const mapStateToProps = (state: State) => ({
  settings: selectDesktopSettings(state),
  user: getUser(state),
});

export const aboutModalConnector = connect(mapStateToProps, { logout });
