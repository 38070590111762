import { ComponentPropsWithRef, ReactElement, ReactNode, cloneElement } from 'react';

import StarTwoTone from '@ant-design/icons/StarTwoTone';
import { Typography } from 'antd';
import cx from 'classnames';

import { Size } from '../../@types/props';
import colors from '../../theme/colors';
import Avatar from '../Avatar';
import Space from '../Space';

import styles from './Contact.module.less';

export interface ContactDescriptionProps {
  qualifications?: string;
  address?: string;
  bornOn?: string;
  ins?: string;
  nir?: string;
  ipp?: string;
  insQualification?: ReactNode;
}

const { Text } = Typography;

interface ContactProps extends ComponentPropsWithRef<'div'> {
  title: string;
  subtitle?: ReactElement | string;
  description?: ContactDescriptionProps;
  initials: string;
  hasStar?: boolean;
  onStar?: () => void;
  isStarred?: boolean;
  size?: Size;
  showAvatar?: boolean;
  avatarColor?: string;
  customAvatarSize?: number;
}

export const Contact = ({
  title,
  subtitle,
  description,
  initials,
  hasStar = false,
  onStar,
  isStarred,
  showAvatar = true,
  avatarColor,
  size = 'small',
  className,
  customAvatarSize,
  ...otherProps
}: ContactProps) => {
  const Subtitle = ({ inline }: { inline?: boolean }) => {
    if (!subtitle) {
      return <></>;
    }
    return typeof subtitle === 'string' ? (
      <Text className={cx(styles.subtitle, inline && styles.inline)}>{subtitle}</Text>
    ) : (
      cloneElement(subtitle as ReactElement, {
        className: cx((subtitle as ReactElement).props.className, styles.subtitle),
      })
    );
  };
  const Description = () => {
    if (!description || Object.keys(description).length === 0) {
      return <></>;
    }
    return (
      <Space direction="vertical" size={2} data-testid="contact-description">
        {Object.entries(description).map(([key, value]) => (
          <Text className={styles.description} key={key} data-testid={`contact-description-${key}`}>
            {value}
          </Text>
        ))}
      </Space>
    );
  };

  const Star = () => (
    <StarTwoTone
      className={styles.star}
      twoToneColor={isStarred ? colors.gold[5] : colors.grey[5]}
      onClick={onStar}
    />
  );

  if (size === 'large') {
    return (
      <Space align="start" size="large" {...otherProps} className={className}>
        {showAvatar && (
          <Avatar size={56} className={styles.avatar} background={avatarColor}>
            {initials}
          </Avatar>
        )}
        <Space size={4} direction="vertical" className={styles.textContainer}>
          <Space>
            <Text className={styles.title}>{title}</Text>
            {hasStar && <Star />}
          </Space>
          <Subtitle />
          <Description />
        </Space>
      </Space>
    );
  }
  if (size === 'medium') {
    return (
      <Space
        align="center"
        size="middle"
        {...otherProps}
        className={cx('contactMediumSize', className)}
      >
        {showAvatar && (
          <Avatar color={colors.white[0]} background={colors.grey[6]} size="large">
            {initials}
          </Avatar>
        )}
        <Space size={0} direction="vertical">
          <Space>
            <Text className={styles.title}>{title}</Text>
            <Subtitle />
            {hasStar && <Star />}
          </Space>
          <Description />
        </Space>
      </Space>
    );
  }
  return (
    <Space {...otherProps} className={className}>
      <div className={styles.container}>
        {showAvatar && (
          <Avatar size={customAvatarSize ?? undefined} background={avatarColor}>
            {initials}
          </Avatar>
        )}
      </div>
      <Text>{title}</Text>
      <Subtitle inline />
    </Space>
  );
};
