import { WATCHERS_POLLING_INTERVAL_DEFAULT } from '@launcher/constants';

import {
  DesktopConnectionSettings,
  DesktopSettings,
  ImportWatcherLocalSettings,
  OtherDesktopSettings,
  ProxyMode,
  WatcherLocalSettings,
} from './defaultSettings.types';

const KEEP_FILE_DURING_X_DAYS_DEFAULT = 30;

const defaultConnectionSettings: DesktopConnectionSettings = {
  pacUrl: '',
  proxyMode: ProxyMode.System,
  proxyUrl: '',
  username: '',
  ssoConnection: '',
};

const defaultImportWatcherLocalSettings: ImportWatcherLocalSettings = {
  isImportWatcherActivated: false,
  printerFolderName: undefined,
};

const defaultOtherDesktopSettings: OtherDesktopSettings = {
  autoUpdateEnabled: true,
  hideSupport: false,
  myRequestsOnly: false,
};

const defaultWatcherLocalSettings: WatcherLocalSettings = {
  stabilityThreshold: 800,
  usePolling: false,
  usePollingInterval: WATCHERS_POLLING_INTERVAL_DEFAULT,
  watchPath: '',
  keepFileDuringXDays: KEEP_FILE_DURING_X_DAYS_DEFAULT,
};

export const defaultDesktopSettings: DesktopSettings = {
  ...defaultConnectionSettings,
  ...defaultImportWatcherLocalSettings,
  ...defaultOtherDesktopSettings,
  ...defaultWatcherLocalSettings,
};
