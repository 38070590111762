import { LoadingOutlined } from '@ant-design/icons';
import { Button as AntButton } from 'antd';
import { ButtonProps as AntButtonProps } from 'antd/lib/button/button';
import cx from 'classnames';

import styles from './Button.module.less';

export interface ButtonProps extends AntButtonProps {
  transparent?: boolean;
  noBorder?: boolean;
  overlayLoading?: boolean;
  danger?: boolean;
}

const Spinner = () => (
  <span className={`ant-btn-loading-icon ${styles.loadingIcon}`}>
    <LoadingOutlined spin />
  </span>
);

export const Button = ({
  children,
  danger,
  transparent,
  noBorder,
  overlayLoading,
  loading,
  className,
  ...rest
}: ButtonProps) => (
  <AntButton
    className={`${className} align-items-center ${cx({
      [styles.transparent]: transparent,
      [styles.noBorder]: noBorder,
      [styles.danger]: danger,
      [styles.loadingOverlay]: overlayLoading && loading,
    })}`}
    loading={overlayLoading ? false : loading}
    {...rest}
  >
    {overlayLoading && loading && <Spinner />}
    {children}
  </AntButton>
);
