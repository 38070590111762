import { createReducer } from '@reduxjs/toolkit';

import {
  INITIAL_STATE,
  NotificationState,
} from '@store/notificationWindow/notificationWindow.state';

import {
  NotificationAddError,
  NotificationAddPending,
  NotificationAddSuccess,
  notificationAddError,
  notificationAddPending,
  notificationAddSuccess,
  resetNotification,
} from './notificationWindow.action';

const onAddSuccess = (
  state: NotificationState,
  action: ReturnType<NotificationAddSuccess>,
): NotificationState => ({
  ...state,
  pending: state.pending === 0 ? 0 : state.pending - 1,
  success: [...state.success, action.payload],
});

const onAddError = (
  state: NotificationState,
  action: ReturnType<NotificationAddError>,
): NotificationState => ({
  ...state,
  pending: state.pending === 0 ? 0 : state.pending - 1,
  errors: [...state.errors, action.payload],
});

const onResetNotification = (): NotificationState => INITIAL_STATE;

const onAddToPending = (
  state: NotificationState,
  action: ReturnType<NotificationAddPending>,
): NotificationState => ({
  ...state,
  pending: state.pending + action.payload.count,
});

export const notificationReducer = createReducer(INITIAL_STATE, {
  [notificationAddSuccess.type]: onAddSuccess,
  [notificationAddError.type]: onAddError,
  [resetNotification.type]: onResetNotification,
  [notificationAddPending.type]: onAddToPending,
});
