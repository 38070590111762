import { REFRESH_PATIENT_MATCH_TIMEOUT_MS } from '@launcher/constants';
import { logger } from '@services/logger/logger.service';
import { all, delay, put, race, select, take, takeLatest } from 'redux-saga/effects';

import {
  IntegrationRequestBundle,
  IntegrationRequestDto,
  List,
  Patient,
} from '@honestica/core-apps-common/types';
import { isIntegration } from '@honestica/core-apps-common/validators';

import {
  addIntegrationRequestPatient,
  createIntegrationRequestPatient,
  getPatients,
  refreshIntegrationRequestPatientMatch,
  removeIntegrationRequestPatient,
  updateIntegrationRequestPatient,
} from '@api';
import {
  CreatePatientAction,
  OneAction,
  SearchPatientsAction,
  UpdatePatientAction,
} from '@store/documents/documents.actions';
import {
  selectIntegrationRequestDto,
  selectWorklistCurrentPatient,
} from '@store/documents/integrations/worklist.selector';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import { hasConnectorWithPreprocessing } from '@utils/integrations.util';

function* addDocumentPatientAsync({ payload: { patient } }: UpdatePatientAction) {
  const docDto: IntegrationRequestDto = yield select(selectIntegrationRequestDto);
  try {
    const bundle: IntegrationRequestBundle = yield addIntegrationRequestPatient(
      docDto.id,
      {
        id: patient.id,
        telecoms: [],
        addresses: [],
      },
      hasConnectorWithPreprocessing(docDto.integration),
    );
    yield put(WorklistIntegrationsActions.addDocumentPatientSuccess({ bundle }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to add document patient', error);
    yield put(
      WorklistIntegrationsActions.addDocumentPatientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

function* createDocumentPatientAsync({ payload: { patient } }: CreatePatientAction) {
  const docDto: IntegrationRequestDto = yield select(selectIntegrationRequestDto);
  try {
    const bundle: IntegrationRequestBundle = yield createIntegrationRequestPatient(
      docDto.id,
      patient,
      isIntegration(docDto.integration),
    );
    yield put(WorklistIntegrationsActions.addDocumentPatientSuccess({ bundle }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to create document patient', error);
    yield put(
      WorklistIntegrationsActions.addDocumentPatientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

function* refreshPatientMatchAsync({ payload: { id } }: OneAction) {
  try {
    yield refreshIntegrationRequestPatientMatch(id);
    /*
      When patient match is done, `lf-bundle-cr-predictions` sends a push event
      that triggers the "fetchOneSuccess" action. We wait for 30 seconds,
      and go into timeout mode if the action is not dispatched within this delay.
    */
    const { success, timeout } = yield race({
      success: take(WorklistIntegrationsActions.fetchOneSuccess.type),
      timeout: delay(REFRESH_PATIENT_MATCH_TIMEOUT_MS),
    });
    if (success) {
      logger.info('LOGIC', 'Patient Match Refresh was successful');
      yield put(WorklistIntegrationsActions.refreshPatientMatchSuccess({ id }));
    } else if (timeout) {
      logger.error('LOGIC', 'Patient Match Refresh timed out');
      yield put(
        WorklistIntegrationsActions.refreshPatientMatchTimeout({
          id,
        }),
      );
      yield put(WorklistIntegrationsActions.fetchOne({ id }));
    }
  } catch (error) {
    logger.error('LOGIC', 'Failed to refresh patient match', error);
    yield put(
      WorklistIntegrationsActions.refreshPatientMatchFailure({
        id,
        error,
      }),
    );
  }
}

function* removeDocumentPatientAsync() {
  const docDto: IntegrationRequestDto = yield select(selectIntegrationRequestDto);
  try {
    const bundle: IntegrationRequestBundle = yield removeIntegrationRequestPatient(docDto.id);
    yield put(WorklistIntegrationsActions.removeDocumentPatientSuccess({ bundle }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to remove document patient', error);
    yield put(
      WorklistIntegrationsActions.removeDocumentPatientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

function* searchPatientsAsync({ payload: { searchParams } }: SearchPatientsAction) {
  try {
    const patients: List<Patient> = yield getPatients(searchParams);
    yield put(WorklistIntegrationsActions.searchPatientsSuccess({ patients }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to search patients in Integrations Detail', error);

    yield put(
      WorklistIntegrationsActions.searchPatientsFailure({
        error,
      }),
    );
  }
}

function* updateDocumentPatientAsync({ payload: { patient } }: UpdatePatientAction) {
  const docDto: IntegrationRequestDto = yield select(selectIntegrationRequestDto);
  const currentPatient: Patient = yield select(selectWorklistCurrentPatient);
  try {
    const {
      patient: updatedPatient,
      document,
      needsRefresh,
    } = yield updateIntegrationRequestPatient(docDto.id, patient, currentPatient);
    if (needsRefresh) {
      yield put(
        WorklistIntegrationsActions.refreshPatientMatch({
          id: docDto.id,
        }),
      );
    }
    yield put(
      WorklistIntegrationsActions.updateDocumentPatientSuccess({
        id: docDto.id,
        patient: updatedPatient,
        integrationRequest: document,
      }),
    );
  } catch (error) {
    logger.error('LOGIC', 'Failed to update document patient', error);
    yield put(
      WorklistIntegrationsActions.updateDocumentPatientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

export function* patientsIntegrationRequestsSagas() {
  yield all([
    takeLatest(WorklistIntegrationsActions.addDocumentPatient.type, addDocumentPatientAsync),
    takeLatest(WorklistIntegrationsActions.createDocumentPatient.type, createDocumentPatientAsync),
    takeLatest(WorklistIntegrationsActions.refreshPatientMatch.type, refreshPatientMatchAsync),
    takeLatest(WorklistIntegrationsActions.removeDocumentPatient.type, removeDocumentPatientAsync),
    takeLatest(WorklistIntegrationsActions.searchPatients.type, searchPatientsAsync),
    takeLatest(WorklistIntegrationsActions.updateDocumentPatient.type, updateDocumentPatientAsync),
  ]);
}
