import { PayloadAction, createReducer } from '@reduxjs/toolkit';

import { Organization, Practitioner } from '@honestica/core-apps-common/types';

import {
  findContacts,
  findContactsFailure,
  findContactsSuccess,
  removeContactSuccess,
} from './contactDirectory.action';
import { ContactDirectoryState, ContactResource } from './contactState';

const INITIAL_STATE: ContactDirectoryState = {
  list: [],
  total: 0,
  loading: false,
  error: false,
};

function listContactsSuccess(
  state: ContactDirectoryState,
  action: PayloadAction<{
    list: (Practitioner | Organization)[];
    total: number;
  }>,
) {
  const { payload } = action;
  if (!payload?.list || !Array.isArray(payload?.list)) {
    return state;
  }

  return {
    error: false,
    list: payload.list.map((contact: Practitioner | Organization) => ({
      resourceId: contact.id,
      resourceType: contact.type,
    })),
    total: payload.total,
    loading: false,
  };
}

function loading(state: ContactDirectoryState, status: boolean) {
  return {
    ...state,
    loading: status,
  };
}

function listContactsFailure(state: ContactDirectoryState) {
  return {
    ...state,
    loading: false,
    error: true,
  };
}

function removeContact(
  state: ContactDirectoryState,
  { payload }: PayloadAction<Practitioner | Organization>,
) {
  return {
    ...state,
    list: state.list.filter(
      (resource: ContactResource) =>
        resource.resourceId !== payload.id || resource.resourceType !== payload.type,
    ),
  };
}

export const contactDirectoryReducer = createReducer(INITIAL_STATE, {
  [findContacts.type]: (state) => loading(state, true),
  [findContactsSuccess.type]: listContactsSuccess,
  [findContactsFailure.type]: listContactsFailure,
  [removeContactSuccess.type]: removeContact,
});
