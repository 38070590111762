import { ReactNode } from 'react';

import { Layout } from 'antd';
import cx from 'classnames';

import { Full } from './Full.component';

import styles from './Column.module.less';

const { Content } = Layout;

interface ColumnPageProps {
  children: ReactNode;
  navMenu: ReactNode;
  withPadding?: boolean;
  overflowHidden?: boolean;
}

export const Column = ({
  withPadding = true,
  children,
  navMenu,
  overflowHidden,
}: ColumnPageProps) => (
  <Full noPadding>
    <Layout>
      {navMenu}
      <Content
        className={cx(styles.container, {
          [styles.withPadding]: withPadding,
          [styles.contentWrapperOverflowHidden]: overflowHidden,
        })}
      >
        {children}
      </Content>
    </Layout>
  </Full>
);
