import { createSlice } from '@reduxjs/toolkit';

import { UpdateSearchParamsAction } from './inErrors.action';

export interface InErrorsDashboardSearchParams {
  fromDate?: Date;
  untilDate?: Date;
  id?: string;
  filename?: string;
  message_contains?: string;
  firstname?: string;
  lastname?: string;
  birthdate?: string;
  ipp?: string;
}

interface InErrorsDashboardView {
  searchParams: InErrorsDashboardSearchParams;
}

export interface InErrorsState {
  dashboardView: InErrorsDashboardView;
}

const INITIAL_IN_ERRORS_STATE: InErrorsState = {
  dashboardView: {
    searchParams: {},
  },
};

// Create a redux store slice to keep dashboard filters state when we navigate across app pages
const slice = createSlice({
  name: 'inErrors',
  initialState: INITIAL_IN_ERRORS_STATE,
  reducers: {
    updateSearchParams: (state, action: UpdateSearchParamsAction) => ({
      ...state,
      dashboardView: {
        searchParams: { ...state.dashboardView.searchParams, ...action.payload },
      },
    }),
  },
});

export const inErrorsReducer = slice.reducer;

export const InErrorsActions = slice.actions;
