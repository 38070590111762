import { SearchParams } from '@honestica/core-apps-common/types';

import { INITIAL_SEARCH_PARAMS } from '@store/documents/documents.state';

export function isPaginationOutOfBound(search: SearchParams, count: number) {
  const page = search.page || 1;
  if (count === 0 && page === 1) return false;
  const perPage = search.perPage || INITIAL_SEARCH_PARAMS.perPage || 20;

  return Math.ceil(count / perPage) < page;
}
