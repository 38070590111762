import { ApiErrorsKey, IApiError } from '../constants/errors.constants';

export function isApiError(value?: unknown): value is IApiError {
  return (
    !!value &&
    typeof value === 'object' &&
    !Array.isArray(value) &&
    Object.hasOwnProperty.call(value, 'key') &&
    Object.hasOwnProperty.call(value, 'message') &&
    Object.hasOwnProperty.call(value, 'statusCode') &&
    Object.values(ApiErrorsKey).includes((value as IApiError).key) &&
    typeof (value as IApiError).message === 'string' &&
    typeof (value as IApiError).statusCode === 'number'
  );
}
