import { Professional } from '@honestica/core-apps-common/types';
import { isPractitioner } from '@honestica/core-apps-common/validators';
import { SenderColors } from '@honestica/ui-kit/src/theme/colors';

export function setUserEntitiesAvatarColor(identities: Professional[]) {
  return identities?.reduce((acc, next, index) => {
    acc[next.id] = SenderColors[index % SenderColors.length];

    if (isPractitioner(next) && next.practitionerRoles.length > 0) {
      acc[next.practitionerRoles[0].id] = SenderColors[index % SenderColors.length];
    }

    return acc;
  }, {} as Record<string, string>);
}

export function sortUserIdentities(identities: Professional[]) {
  return identities.slice().sort((prev, current) => prev.name.localeCompare(current.name));
}
