import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { all, getContext, put, select, takeLatest } from 'redux-saga/effects';

import { documentRecipientBundleToDocumentRecipientDto } from '@honestica/core-apps-common/adaptors';
import {
  DocumentPatientDto,
  SendingRequestBundle,
  SendingRequestDto,
  SuggestedRecipients,
} from '@honestica/core-apps-common/types';

import * as API from '@api';
import {
  AddDematRecipientAction,
  AddPatientRecipientAction,
  AddPostalRecipientAction,
  UpdateRecipientAction,
  UpdateRecipientsAction,
} from '@store/documents/documents.actions';
import {
  selectDraftDetailDocumentDto,
  selectDraftDetailDocumentId,
} from '@store/documents/outgoing/draft.selector';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { updateDocumentInCache } from '@utils/caching.util';

function* addPatientRecipientSaga({
  payload: {
    documentPatient: { addresses, telecoms },
  },
}: AddPatientRecipientAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const patient: DocumentPatientDto = { ...docDto.patient!, addresses, telecoms };
    const bundle: SendingRequestBundle = yield API.addPatientRecipientToDocument(
      docDto.id,
      patient,
    );
    yield put(DraftDocumentsActions.addPatientRecipientSuccess({ document: bundle.document }));
    yield updateDocumentInCache({
      updatedDocument: bundle.document,
      queryClient,
    });
  } catch (error) {
    logger.error('LOGIC', 'Failed to add patient recipient', error);
    yield put(
      DraftDocumentsActions.addRecipientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

function* addPostalRecipientSaga({ payload: { postalRecipient } }: AddPostalRecipientAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const bundle: SendingRequestBundle = yield API.updateDocumentWithPostalRecipient(
      docDto.id,
      postalRecipient,
    );
    yield put(DraftDocumentsActions.addRecipientSuccess({ bundle }));
    yield updateDocumentInCache({
      updatedDocument: bundle.document,
      queryClient,
    });
  } catch (error) {
    logger.error('LOGIC', 'Failed to add postal recipient', error);
    yield put(
      DraftDocumentsActions.addRecipientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

function* addDematRecipientSaga({ payload: { dematRecipient } }: AddDematRecipientAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const bundle: SendingRequestBundle = yield API.updateDocumentWithDematRecipient(
      docDto.id,
      dematRecipient,
    );
    yield put(DraftDocumentsActions.addRecipientSuccess({ bundle }));
    yield updateDocumentInCache({
      updatedDocument: bundle.document,
      queryClient,
    });
  } catch (error) {
    logger.error('LOGIC', 'Failed to add demat recipient', error);
    yield put(
      DraftDocumentsActions.addRecipientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

function* addRecipientSaga({ payload: { recipient } }: UpdateRecipientAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const bundle: SendingRequestBundle = yield API.addRecipientToDocument(docDto.id, recipient);
    yield put(DraftDocumentsActions.addRecipientSuccess({ bundle }));
    yield updateDocumentInCache({
      updatedDocument: bundle.document,
      queryClient,
    });
  } catch (error) {
    logger.error('LOGIC', 'Failed to add recipient', error);
    yield put(
      DraftDocumentsActions.addRecipientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

function* removePatientRecipientSaga() {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto | undefined = yield select(selectDraftDetailDocumentDto);
  if (!docDto) return;
  const bundle: SendingRequestBundle = yield API.removePatientRecipientFromDocument(docDto.id);
  try {
    const document = docDto?.patient?.id ? bundle.document : docDto;
    yield put(
      DraftDocumentsActions.removeRecipientSuccess({
        document,
      }),
    );
    yield updateDocumentInCache({ updatedDocument: document, queryClient });
  } catch (error) {
    logger.error('LOGIC', 'Failed to remove patient recipient', error);
    yield put(
      DraftDocumentsActions.removeRecipientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

function* removeRecipientSaga({ payload: { recipient } }: UpdateRecipientAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);

  try {
    const bundle: SendingRequestBundle = yield API.removeRecipientFromDocument(
      docDto.id,
      recipient,
    );
    yield put(DraftDocumentsActions.removeRecipientSuccess({ document: bundle.document }));
    yield updateDocumentInCache({
      updatedDocument: bundle.document,
      queryClient,
    });
  } catch (error) {
    logger.error('LOGIC', 'Failed to remove document recipient', error);
    yield put(
      DraftDocumentsActions.removeRecipientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

function* updateRecipientSaga({ payload: { recipient: newRecipient } }: UpdateRecipientAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const bundle: SendingRequestBundle = yield API.updateDocumentRecipient(docDto.id, newRecipient);
    yield put(DraftDocumentsActions.updateRecipientSuccess({ document: bundle.document }));
    yield updateDocumentInCache({
      updatedDocument: bundle.document,
      queryClient,
    });
  } catch (error) {
    logger.error('LOGIC', 'Failed to update recipient', error);
    yield put(
      DraftDocumentsActions.updateRecipientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

function* addRecipientsSaga({ payload: { recipients } }: UpdateRecipientsAction) {
  const queryClient: QueryClient = yield getContext('queryClient');
  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const recipientDtos = recipients.map(documentRecipientBundleToDocumentRecipientDto);
    const bundle: SendingRequestBundle = yield API.addRecipientsToDocument(
      docDto.id,
      recipientDtos,
    );
    yield put(DraftDocumentsActions.addRecipientsSuccess({ bundle }));
    yield updateDocumentInCache({
      updatedDocument: bundle.document,
      queryClient,
    });
  } catch (error) {
    logger.error('LOGIC', 'Failed to add recipients', error);
    yield put(
      DraftDocumentsActions.addRecipientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

function* fetchSuggestedRecipientsSaga() {
  try {
    const currentDocumentId: string = yield select(selectDraftDetailDocumentId);
    const suggestions: SuggestedRecipients = yield API.fetchSuggestedRecipients(currentDocumentId);
    yield put(DraftDocumentsActions.fetchSuggestedRecipientsSuccess({ suggestions }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to fetch document recipients suggestions', error);
  }
}

export function* manageRecipientsDraftDocumentSagas() {
  yield all([
    takeLatest(DraftDocumentsActions.addPatientRecipient.type, addPatientRecipientSaga),
    takeLatest(DraftDocumentsActions.addPostalRecipient.type, addPostalRecipientSaga),
    takeLatest(DraftDocumentsActions.addDematRecipient.type, addDematRecipientSaga),
    takeLatest(DraftDocumentsActions.addRecipient.type, addRecipientSaga),
    takeLatest(DraftDocumentsActions.removePatientRecipient.type, removePatientRecipientSaga),
    takeLatest(DraftDocumentsActions.removeRecipient.type, removeRecipientSaga),
    takeLatest(DraftDocumentsActions.updateRecipient.type, updateRecipientSaga),
    takeLatest(DraftDocumentsActions.addRecipients.type, addRecipientsSaga),
    takeLatest(DraftDocumentsActions.fetchSuggestedRecipients.type, fetchSuggestedRecipientsSaga),
  ]);
}
