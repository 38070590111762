import { useEffect, useRef, useState } from 'react';

import { CaretDownOutlined } from '@ant-design/icons';
import cx from 'classnames';
import { addDays, isEqual } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useOutsideClick } from '../../hooks/useOutsideClick.hook';
import Button from '../Button';
import { DatePicker, RangeValueType } from '../DatePicker';

import { FilterProps } from './Filter.component';

import styles from './DateRangeFilter.module.less';

export type DateRangeValue = {
  fromDate: Date | undefined;
  untilDate: Date | undefined;
};
interface DateRangeProps {
  disabled?: boolean;
  format?: string;
  label: string;
  value?: { fromDate: Date; untilDate: Date } | undefined;
  testid?: string;
  onDateChange: (dates: DateRangeValue) => void;
}

const { RangePicker } = DatePicker;

export const DateRangeFilter = (
  props: Pick<FilterProps<DateRangeValue>, 'onResetFilter'> & DateRangeProps,
) => {
  const { t } = useTranslation();

  const [rangePickerVisible, setRangePickerVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [rangeValue, setRangeValue] = useState<DateRangeValue>(
    props.value ?? { fromDate: undefined, untilDate: undefined },
  );
  const dateFormat = props.format ?? 'dd/MM/y';

  const hasValues = rangeValue.fromDate !== undefined && rangeValue.untilDate !== undefined;

  const defaultValue: RangeValueType =
    props.value?.fromDate && props.value?.untilDate
      ? [props.value?.fromDate, props.value?.untilDate]
      : [null, null];

  const ref = useRef(null);

  const handleClickOutside = () => {
    if (!isOpen) {
      setRangePickerVisible(false);
    }
  };

  const handleOpenDateRangePicker = () => {
    setRangePickerVisible(true);
    setIsOpen(true);
  };

  const handleValidation = (dates: [Date | null, Date | null]) => {
    if (
      dates &&
      dates.length === 2 &&
      dates[0] !== null &&
      dates[1] !== null &&
      (dates[0] < dates[1] || isEqual(dates[0], dates[1])) &&
      (dates[0] !== rangeValue.fromDate || dates[1] !== rangeValue.untilDate)
    ) {
      const selectedDates = { fromDate: dates[0], untilDate: dates[1] };
      setRangeValue(selectedDates);
      setIsOpen(false);
      props.onDateChange(selectedDates);
    }
  };

  const handleDatesReset = (dates: [Date | null, Date | null] | null) => {
    if (!dates) {
      onResetFilter();
    }
  };

  const onResetFilter = () => {
    setRangeValue({ fromDate: undefined, untilDate: undefined });
    setRangePickerVisible(false);
    props.onResetFilter();
  };

  useEffect(() => {
    if (props.value) {
      setRangeValue(props.value);
    }
  }, [props.value]);

  const disabledDate = (current: Date) => {
    const prevLimit = Date.parse(addDays(new Date(), -(365 * 5)).toDateString()); // temporary
    const nextLimit = Date.now();
    return (
      current &&
      (Date.parse(current.toString()) < prevLimit || Date.parse(current.toString()) > nextLimit)
    );
  };

  useOutsideClick(ref, handleClickOutside);

  return (
    <>
      {!rangePickerVisible && !hasValues ? (
        <Button
          style={{ width: '230px' }}
          disabled={props.disabled}
          data-testid={`${props.testid}-empty`}
          onClick={() => handleOpenDateRangePicker()}
        >
          {props.label}
          <CaretDownOutlined />
        </Button>
      ) : (
        <div
          ref={ref}
          data-testid={props.testid} // because of https://github.com/ant-design/ant-design/issues/44882
          className={cx({ [styles.tag]: hasValues })}
        >
          <RangePicker
            open={isOpen}
            style={{ width: '230px' }}
            data-testid={`${props.testid}-inputs`}
            defaultValue={defaultValue}
            showTime
            allowEmpty={[true, true]}
            onChange={(dates) => handleDatesReset(dates)}
            onOpenChange={(open) => setIsOpen(open)}
            placeholder={[t('startDate'), t('endDate')]}
            format={dateFormat}
            disabled={props.disabled}
            allowClear
            onOk={(dates) => handleValidation(dates)}
            disabledDate={disabledDate}
          />
        </div>
      )}
    </>
  );
};
