import { logger } from '@services/logger/logger.service';
import { NavigateFunction } from 'react-router-dom';
import { all, getContext, takeEvery } from 'redux-saga/effects';

import { SentDocumentsActions } from '@store/documents/outgoing/sent.slice';

import { DraftDocumentsActions } from '../documents/outgoing/draft.slice';

import { OpenModalFromStore, closeModalFromStore, openModalFromStore } from './modal.action';

export function* onOpenModal({ payload }: ReturnType<OpenModalFromStore>) {
  const navigate: NavigateFunction = yield getContext('navigate');

  const { search } = window.location;
  const searchParams = new URLSearchParams(search);

  logger.info('UI', 'Open Modal', { ...payload });

  searchParams.set('modal', payload.modal);
  Object.keys(payload.search ?? {}).forEach((key) => {
    searchParams.set(key, payload.search?.[key] ?? '');
  });

  navigate(`?${searchParams}`, { replace: true });
}

export function* onCloseModal() {
  const navigate: NavigateFunction = yield getContext('navigate');
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);

  logger.info('UI', 'Close Modal', { modal: searchParams.get('modal') });

  searchParams.delete('modal');

  navigate(`?${searchParams}`, { replace: true });
}

export function* modalSagas() {
  yield all([
    takeEvery(closeModalFromStore.type, onCloseModal),
    takeEvery(openModalFromStore.type, onOpenModal),
    takeEvery(DraftDocumentsActions.groupMany.type, onCloseModal),
    takeEvery(SentDocumentsActions.removeDmpSuccess.type, onCloseModal),
    takeEvery(SentDocumentsActions.replaceDmpSuccess.type, onCloseModal),
  ]);
}
