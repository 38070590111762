import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Alert, Button } from '@honestica/ui-kit/src';

import { InfoMessage } from '@store/info-messages/infoMessages.state';

import styles from './ActionAlert.module.less';

interface Props {
  message: InfoMessage;
  onRemove: () => void;
  onCancel: () => void;
}

export function ActionAlert(props: Props) {
  const { t } = useTranslation();
  const { onCancel } = props;

  const Cancel = useMemo(
    () =>
      props.message.cancelable ? (
        <Button
          type="link"
          className={styles.button}
          data-testid="action-alert-button"
          onClick={onCancel}
        >
          {t('cancel')}
        </Button>
      ) : null,
    [t, props.message.cancelable, onCancel],
  );

  return (
    <Alert
      className={styles.alert}
      data-testid="action-alert"
      type="info"
      closable
      onClose={props.onRemove}
      message={t(`info.message.${props.message.type}`, props.message.labelOptions)}
      action={Cancel}
    />
  );
}
