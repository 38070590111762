import { Stream } from 'stream';

export type ContentType = string;

export const isContentType = (str: unknown): str is ContentType =>
  typeof str === 'string' && str !== '';

export interface Attachment {
  id?: string;
  parentRefId?: string;
  name?: string;
  contentType: ContentType;
  content: string;
  contentFile?: File;
  pagesCount?: number;
  description?: string;
}

export type AttachmentStream = { data: Buffer | Stream | string; filename: string };

export interface FileMetadata {
  id: string;
  name: string;
  contentType: ContentType;
  description?: string;
}

export interface FileMetadataWithContent extends FileMetadata {
  content: string;
}

export interface DocumentPreview {
  id: string;
  preview: string | null; // base64 image string
  numPages: number | null; // null if could not be calculated
}

export type DocumentsPreview = { [key: string]: DocumentPreview };
