import { CORE_APPS_INFO } from '@launcher/constants';
import { Selector, createSelector } from '@reduxjs/toolkit';

import {
  ApiDomain,
  CoreApp,
  FeatureFlags,
  FlowType,
  Professional,
  ProfessionalBaseType,
  SendingsDashboardType,
  UserSettings,
} from '@honestica/core-apps-common/types';
import {
  isConnector,
  isPractitioner,
  isPractitionerRole,
} from '@honestica/core-apps-common/validators';

import {
  extractProfessionalReferencesFromIdentities,
  filterDeviceIdentitiesOut,
  filterSenderIdentities,
  firstOrganizationIdentity,
} from '@utils/identities.util';

import { State } from '../reducer';

import { AuthenticationError, ProductAppName, UserDataTokenless } from './user.state';

export const getUser: Selector<State, UserDataTokenless> = (state) => state.user;

export const getIdentities: Selector<State, Professional[]> = (state) =>
  state.user.identities.identities;

export const selectIdentitiesWithoutDevice: Selector<State, Professional[]> = createSelector(
  getIdentities,
  (identities) => filterDeviceIdentitiesOut(identities),
);

export const selectSenderIdentities: Selector<State, Professional[]> = createSelector(
  getIdentities,
  (identities) => filterSenderIdentities(identities),
);

export const getIdentitiesReferences: Selector<State, string[]> = (state) =>
  extractProfessionalReferencesFromIdentities(state.user.identities.identities);

export const selectHasDeviceIdentity = createSelector(
  getIdentities,
  (_: State, dashboardType: SendingsDashboardType) => dashboardType,
  (identities, dashboardType) => {
    if (dashboardType !== SendingsDashboardType.Inbox) return false;
    return identities.find((identity) => isConnector(identity));
  },
);

export const getIsLifenSendingActivated: Selector<State, boolean> = (state) =>
  selectUserSettings(state).sendingShortcutEnabled;

export const hasMultipleIntegrations: Selector<State, boolean> = (state) =>
  state.user.integrationOrganizations.organizations.length > 1;

export const hasIntegrationOrganizations: Selector<State, boolean> = (state) =>
  state.user.integrationOrganizations.organizations.length > 0;

export const getIsAuthenticated: Selector<State, boolean> = (state) => state.user.isAuthenticated;

export const getSelectedIdentity: Selector<State, Professional | undefined> = createSelector(
  getUser,
  (_: State, currentApp: ProductAppName) => currentApp,
  (user, currentApp) => user.identities.selectedIdentities[currentApp],
);

export const selectSelectedIdentitySettings = createSelector(
  getSelectedIdentity,
  (_: State, currentApp: ProductAppName) => currentApp,
  (identity) => identity?.featureSets,
);

export const getUserId: Selector<State, string> = (state) => state.user?.uuid ?? '';

export const getCurrentWorkspaceId: Selector<State, string> = (state) =>
  state.user.currentWorkspaceId ?? '';

export const getCurrentWorkspaceName: Selector<State, string | undefined> = (state) =>
  state.user.workspaces.find((workspace) => workspace.id === getCurrentWorkspaceId(state))?.name;

export const getFeatureFlags: Selector<State, FeatureFlags> = (state) =>
  state.user.featureFlags.flags ?? {};

export const selectActiveApps: Selector<State, CoreApp[]> = createSelector(getUser, (user) => {
  const activeApps = [];
  if (user.settings.sendingShortcutEnabled) {
    activeApps.push(CORE_APPS_INFO['LIFEN-DOCUMENTS']);
  }

  const hasIdentityWithIntegrationEnabled = user.identities.identities.some((identity) => {
    const hasActiveIntegrationsModule = Boolean(identity.featureSets?.lifenFeatureSetIntegrations);

    return identity.type === ProfessionalBaseType.Organization && hasActiveIntegrationsModule;
  });

  if (hasIdentityWithIntegrationEnabled) {
    activeApps.push(CORE_APPS_INFO['LIFEN-INTEGRATIONS']);
  }

  return activeApps;
});

export const getIsAppLoading: Selector<State, boolean> = (state) => {
  // returns true while at least one condition is true

  const conditions = [state.user.identities.loading, state.user.featureFlags.loading];

  return conditions.includes(true) && !state.user.authError;
};

export const selectUserSettings: Selector<State, UserSettings> = (state) => state.user.settings;

export const getApiDomain: Selector<State, ApiDomain> = (state) =>
  /ramsaygds/.exec(getUser(state).apiUrl) ? 'rgds' : 'lifen';

export const selectLocale = (state: State) => getUser(state).preferredLocale;

export const getAuthenticationError: Selector<State, AuthenticationError | null> = (state) =>
  getUser(state).authError;

export const getSendingProhibitedSetting: Selector<State, boolean> = (state) =>
  selectUserSettings(state).sendingProhibited;

export const selectUserIdentityColors: Selector<State, Record<string, string>> = (state) =>
  state.user?.identityColors;

/**
 * Always prioritize the pract role on a pract
 *
 * if there is selected identity return is first pract role or the practitionner
 * return the first identity element otherwise
 * @param state
 * @param option
 * @returns
 */
export function selectSelectedOrDefaultIdentity(
  state: State,
  option: { withoutDeviceIdentities?: boolean; flowType?: FlowType } = {},
) {
  const selectedIdentity = getSelectedIdentity(
    state,
    option.flowType === 'integration' ? 'lifenIntegration' : 'lifenSending',
  );
  const identities = option.withoutDeviceIdentities
    ? selectIdentitiesWithoutDevice(state)
    : getIdentities(state);
  const firstIdentity = identities[0];

  if (selectedIdentity) {
    if (isPractitionerRole(selectedIdentity)) {
      return selectedIdentity;
    }
    if (isPractitioner(selectedIdentity) && selectedIdentity.practitionerRoles.length > 0) {
      return selectedIdentity.practitionerRoles[0];
    }
    return selectedIdentity;
  }

  if (option.flowType === 'integration') {
    return firstOrganizationIdentity(identities);
  }

  if (isPractitioner(firstIdentity) && firstIdentity.practitionerRoles.length > 0) {
    return firstIdentity.practitionerRoles[0];
  }
  return firstIdentity;
}
