import {
  InErrorsState,
  inErrorsReducer,
} from '@lifen-sending/features/in-errors/store/inErrors.slice';
import { combineReducers } from '@reduxjs/toolkit';

import { IntegrationsState } from '@store/integrations/integrations.state';
import { NotificationState } from '@store/notificationWindow/notificationWindow.state';

import { actionDeferredReducer } from './actionDeferred/actionsDeferred.slice';
import { ActionsDeferredState } from './actionDeferred/actionsDeferred.state';
import { contactDirectoryReducer } from './contactDirectory/contactDirectory.reducer';
import { ContactDirectoryState } from './contactDirectory/contactState';
import {
  ContactPreferencesState,
  contactPreferencesReducer,
} from './contactPreferences/contactPreferences.reducer';
import { desktopReducer } from './desktop/desktop.reducer';
import { DesktopState } from './desktop/desktop.state';
import { documentArchivedReducer } from './documents/incoming/archived.slice';
import { ArchivedDocumentsState } from './documents/incoming/archived.state';
import { documentInboxReducer } from './documents/incoming/inbox.slice';
import { InboxDocumentsState } from './documents/incoming/inbox.state';
import { integrationsIntegratedReducer } from './documents/integrations/integrated.slice';
import { IntegratedIntegrationsState } from './documents/integrations/integrated.state';
import { integrationsWorklistReducer } from './documents/integrations/worklist.slice';
import { WorklistIntegrationsState } from './documents/integrations/worklist.state';
import { documentDraftReducer } from './documents/outgoing/draft.slice';
import { DraftDocumentsState } from './documents/outgoing/draft.state';
import { documentErroredReducer } from './documents/outgoing/errored.slice';
import { ErroredDocumentsState } from './documents/outgoing/errored.state';
import { documentSentReducer } from './documents/outgoing/sent.slice';
import { SentDocumentsState } from './documents/outgoing/sent.state';
import { entitiesReducer } from './entities/entities.reducer';
import { EntitiesState } from './entities/entities.state';
import { errorsReducer } from './errors/errors.reducer';
import { ErrorsState } from './errors/errors.state';
import { infoMessagesReducer } from './info-messages/infoMessages.slice';
import { InfoMessagesState } from './info-messages/infoMessages.state';
import { integrationsReducer } from './integrations/integrations.slice';
import { notificationReducer } from './notificationWindow/notificationWindow.reducer';
import { notificationsReducer } from './notifications/notifications.reducer';
import { NotificationsState } from './notifications/notifications.state';
import {
  OrganizationDetailState,
  organizationDetailReducer,
} from './organizationDetail/organizationDetail.reducer';
import {
  OrganizationDirectoryState,
  organizationDirectoryReducer,
} from './organizationDirectory/organizationDirectory.reducer';
import {
  PatientDirectoryState,
  patientDirectoryReducer,
} from './patientDirectory/patientDirectory.reducer';
import {
  PractitionerDetailState,
  practitionerDetailReducer,
} from './practitionerDetail/practitionerDetail.reducer';
import {
  PractitionerDirectoryState,
  practitionerDirectoryReducer,
} from './practitionerDirectory/practitionerDirectory.reducer';
import { searchDirectoriesReducer } from './searchDirectories/searchDirectories.reducer';
import { SearchDirectoriesState } from './searchDirectories/searchDirectories.state';
import { selectionReducer } from './selection/selection.reducer';
import { SelectionState } from './selection/selection.state';
import { uploadDocumentsReducer } from './uploadDocuments/uploadDocuments.slice';
import { UploadDocumentsState } from './uploadDocuments/uploadDocuments.state';
import { userReducer } from './user/user.reducer';
import { UserDataTokenless } from './user/user.state';

export interface State {
  actionsDeferred: ActionsDeferredState;
  contactDirectory: ContactDirectoryState;
  contactPreferences: ContactPreferencesState;
  desktop: DesktopState;
  documentArchived: ArchivedDocumentsState;
  documentDraft: DraftDocumentsState;
  documentErrored: ErroredDocumentsState;
  documentInbox: InboxDocumentsState;
  documentSent: SentDocumentsState;
  entitiesState: EntitiesState;
  errors: ErrorsState;
  inErrors: InErrorsState;
  infoMessages: InfoMessagesState;
  integrationRequestWorklist: WorklistIntegrationsState;
  integrationRequestIntegrated: IntegratedIntegrationsState;
  integrations: IntegrationsState;
  notification: NotificationState;
  notifications: NotificationsState;
  organizationDetail: OrganizationDetailState;
  organizationDirectory: OrganizationDirectoryState;
  patientDirectory: PatientDirectoryState;
  practitionerDetail: PractitionerDetailState;
  practitionerDirectory: PractitionerDirectoryState;
  searchDirectories: SearchDirectoriesState;
  selection: SelectionState;
  uploadDocuments: UploadDocumentsState;
  user: UserDataTokenless;
}

export const createRootReducer = () =>
  combineReducers({
    actionsDeferred: actionDeferredReducer,
    contactDirectory: contactDirectoryReducer,
    contactPreferences: contactPreferencesReducer,
    desktop: desktopReducer,
    documentArchived: documentArchivedReducer,
    documentDraft: documentDraftReducer,
    documentErrored: documentErroredReducer,
    documentInbox: documentInboxReducer,
    documentSent: documentSentReducer,
    entitiesState: entitiesReducer,
    errors: errorsReducer,
    inErrors: inErrorsReducer,
    infoMessages: infoMessagesReducer,
    integrations: integrationsReducer,
    integrationRequestWorklist: integrationsWorklistReducer,
    integrationRequestIntegrated: integrationsIntegratedReducer,
    notification: notificationReducer,
    notifications: notificationsReducer,
    organizationDetail: organizationDetailReducer,
    organizationDirectory: organizationDirectoryReducer,
    patientDirectory: patientDirectoryReducer,
    practitionerDetail: practitionerDetailReducer,
    practitionerDirectory: practitionerDirectoryReducer,
    searchDirectories: searchDirectoriesReducer,
    selection: selectionReducer,
    uploadDocuments: uploadDocumentsReducer,
    user: userReducer,
  });
