import {
  AuthUser,
  FeatureFlags,
  Organization,
  Professional,
  SelectedIdentities,
} from '@honestica/core-apps-common/types';

export enum AuthenticationError {
  ExpiredSession = 'SESSION_EXPIRED',
  MisconfiguredAccount = 'MISCONFIGURED_ACCOUNT',
  Default = 'DEFAULT',
  Login = 'LOGIN_REDIRECTION_ISSUE',
}

export type ProductAppName = 'lifenIntegration' | 'lifenSending';

export interface UserData extends AuthUser {
  authError: AuthenticationError | null;
  identities: {
    loading: boolean;
    identities: Professional[];
    selectedIdentities: SelectedIdentities;
  };
  integrationOrganizations: {
    loading: boolean;
    organizations: Organization[];
  };
  identityColors: Record<string, string>;
  featureFlags: { loading: boolean; flags: FeatureFlags };
}

export type UserDataTokenless = Omit<UserData, 'token'>;

export const USER_INITIAL_STATE: UserDataTokenless = {
  isAuthenticated: false,
  authError: null,
  uuid: '',
  firstName: '',
  lastName: '',
  email: '',
  preferredLocale: '',
  workspaces: [],
  currentWorkspaceId: '',
  currentApiDomain: 'lifen',
  settings: {
    automaticEnsSendingDoctypes: [],
    dematPatientSendingProhibited: false,
    directMail: false,
    immediateModeActivated: true,
    internalSendingKeywords: '',
    internalSendingProhibited: 'yes',
    mainRecipientOnlyActivated: false,
    patientAutomaticResending: 'no',
    patientSendingProhibited: false,
    postMailColorActivated: true,
    postalShippingType: 'normal',
    readyToSendAutomaticSending: false,
    sendingProhibited: true,
    sendingTemplate: 'generic',
    patientSendingProhibitedValue: 'no',
    ensSendingAuthorized: false,
    insCoverAuthorized: false,
    mdnRequestAuthorized: false,
    integrationInsCoverAuthorized: false,
    sendingShortcutEnabled: true,
  },
  apiUrl: '',
  appName: '',
  identities: {
    loading: true,
    identities: [],
    selectedIdentities: { lifenIntegration: undefined, lifenSending: undefined },
  },
  integrationOrganizations: { loading: true, organizations: [] },
  identityColors: {},
  featureFlags: { loading: true, flags: {} },
};
