import { filterSettingsWriteableToFS } from '@launcher/helpers/desktop';
import { DesktopSettings, defaultDesktopSettings } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';
import { all, select, takeLatest } from 'redux-saga/effects';

import { DesktopActions } from '@store/desktop/desktop.action';
import { selectDesktopSettings } from '@store/desktop/desktop.selector';

function* syncSettingsToFSSaga() {
  try {
    const settings: DesktopSettings = yield select(selectDesktopSettings);

    const filtered = filterSettingsWriteableToFS(settings);

    if (Object.keys(filtered).length === 0) return;

    const previous: Partial<DesktopSettings> = yield window.electron.readLocalSettings();

    logger.info('DESKTOP', 'Writing settings to file', { settings });
    window.electron.writeLocalSettings({
      ...defaultDesktopSettings,
      ...previous,
      ...filtered,
    });
  } catch (err) {
    logger.error('DESKTOP', 'Writing settings file failed', err);
  }
}

export function* syncSettingsToFSSagas() {
  yield all([takeLatest(DesktopActions.setSettings.type, syncSettingsToFSSaga)]);
  yield all([takeLatest(DesktopActions.updateSettings.type, syncSettingsToFSSaga)]);
}
