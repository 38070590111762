import { tracking } from '@services/analytics';
import { AuthenticationClient, authClient } from '@services/authentication/authentication.service';
import { EnvManager } from '@services/environment/environment.service';
import { logger } from '@services/logger/logger.service';
import { saveAs } from 'file-saver';

import {
  FetchParams,
  HttpParams,
  MutationParams,
  MutationParamsFormData,
  SearchParams,
} from '@honestica/core-apps-common/types';

import { HttpError } from './http.error.service';

const BASE_URL = `${window.location.protocol}//${window.location.host}`;

export class Http {
  private token = '';

  private get version() {
    return 'v1';
  }

  private readonly authenticationClient: AuthenticationClient;

  public constructor(authenticationClient: AuthenticationClient) {
    this.authenticationClient = authenticationClient;
  }

  /*
    Temporary override for e2e tests only
    Can be removed safely after BECA migration
  */

  public setToken(value: string): void {
    this.token = value;
  }

  private getHeaders(
    params: { noCache: boolean; contentType?: string } = {
      noCache: false,
      contentType: 'application/json',
    },
  ) {
    const headers: Record<string, string> = {
      'Content-Type': params.contentType ?? 'application/json',
      Authorization: `Bearer ${this.token}`,
    };

    if (params.contentType === 'multipart/form-data') {
      headers.enctype = 'multipart/form-data';
      delete headers['Content-Type'];
    }

    if (params.noCache) {
      headers['Cache-Control'] = 'no-store';
    }
    return headers;
  }

  private readonly getSearchParams = (searchParams: SearchParams): string => {
    const params: URLSearchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(searchParams)) {
      if (value?.length === 0) {
        continue;
      }

      if (Array.isArray(value)) {
        value.forEach((v) => params.append(`${key}[]`, v));
      } else {
        params.set(key, String(value));
      }
    }

    return params.toString();
  };

  get baseUrl(): string {
    let host = EnvManager.getBaseUrlBackend();

    if (process.env.NODE_ENV === 'test') {
      host = 'http://localhost';
    }

    if (host === '') {
      host = BASE_URL;
    }
    return `${host}/apps/api/`;
  }

  private getURL(params: FetchParams): URL {
    let location = `${this.baseUrl}${params.version ?? this.version}${params.path}`;
    if (params.searchParams) {
      const searchParams = this.getSearchParams(params.searchParams);
      location += `?${searchParams}`;
    }
    return new URL(location);
  }

  private async fetchResponse({
    method,
    params,
    headerOptions,
    bodyParams,
  }: {
    method: string;
    params: FetchParams;
    headerOptions?: { noCache: boolean; contentType?: string };
    bodyParams?: BodyInit;
  }): Promise<{ header: Headers; data: any }> {
    const url = this.getURL(params);

    logger.info('HTTP', `REQ - ${method} ${url.toString()}`, {
      method,
      host: url.host,
      path: url.pathname,
    });

    const initRequest: RequestInit = bodyParams
      ? {
          method,
          headers: this.getHeaders(headerOptions),
          body: bodyParams,
        }
      : {
          method,
          headers: this.getHeaders(headerOptions),
        };

    let response;
    try {
      response = await fetch(url.toString(), initRequest);
    } catch (error) {
      logger.error('HTTP', `Failed to fetch ${method} ${url.toString()}`, error);
      throw new HttpError({ message: 'Failed to fetch', statusCode: 0 });
    }

    logger.info('HTTP', `RESP - ${method} ${url.toString()}`, {
      method,
      host: url.host,
      path: url.pathname,
      status: response.status,
    });

    if (response?.status === 401) {
      logger.info('HTTP', 'API return a 401 error. Logout user and redirect to the login page');
      tracking.userForcedLogout();
      await this.authenticationClient.logout();
    }

    const responseContentType = response.headers.get('content-type');
    let responseData;
    // TODO https://honestica.atlassian.net/browse/SEDEX-1421
    // should be handled but breaks some tests
    // if (responseContentType && /text\/plain/.exec(responseContentType.toLowerCase())) {
    //   responseData = response.text();
    // } else if (
    if (
      responseContentType &&
      /^application\/pdf|application\/zip|application\/octet-stream/.exec(
        responseContentType.toLowerCase(),
      )
    ) {
      responseData = await response.blob();
    } else {
      responseData = await response.json();
    }

    if (response?.status >= 400) {
      throw new HttpError({
        message: responseData?.message || response.statusText,
        statusCode: response.status,
        key: responseData.key,
        headers: response.headers,
        response: responseData,
      });
    }

    return { header: response.headers, data: responseData };
  }

  public async get(params: FetchParams, noCache = false) {
    const method = 'GET';
    return (await this.fetchResponse({ method, params, headerOptions: { noCache } })).data;
  }

  private async mutate<T>(params: MutationParams, method: 'POST' | 'PUT' | 'DELETE'): Promise<T> {
    return (await this.fetchResponse({ method, params, bodyParams: JSON.stringify(params.body) }))
      .data;
  }

  public async post<T>(params: MutationParams) {
    return await this.mutate<T>(params, 'POST');
  }

  public async download(params: MutationParams) {
    const method = 'POST';

    const response = await this.fetchResponse({
      method,
      params,
      bodyParams: JSON.stringify(params.body),
    });

    const filename = response.header.get('filename') || 'documents.zip';

    saveAs(response.data, filename);
  }

  public async put<T>(params: MutationParams) {
    return await this.mutate<T>(params, 'PUT');
  }

  public async postMultiFormData(params: MutationParamsFormData) {
    const method = 'POST';
    return (
      await this.fetchResponse({
        method,
        params,
        headerOptions: {
          noCache: false,
          contentType: 'multipart/form-data',
        },
        bodyParams: params.body,
      })
    ).data;
  }

  public async destroy(params: HttpParams) {
    const method = 'DELETE';
    return (await this.fetchResponse({ method, params })).data;
  }

  public async destroyWithBody<T>(params: MutationParams) {
    return await this.mutate<T>(params, 'DELETE');
  }
}

export const http = new Http(authClient);
