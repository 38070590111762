import { CoreApp } from '@honestica/core-apps-common/types';

import { DocumentPathToName } from '@constants/documents.constants';

export const getCurrentAppFromPathname = (pathname: string, apps: CoreApp[]): CoreApp | null =>
  apps.find((app) => pathname.includes(app.path)) || null;

export const getCurrentAppTag = (pathname: string, apps: CoreApp[]): string | undefined => {
  const currentApp = getCurrentAppFromPathname(pathname, apps);
  if (!currentApp) {
    return undefined;
  }

  return currentApp.name;
};

export const getCurrentPageTag = (pathname: string, apps: CoreApp[]): string | undefined => {
  const currentApp = getCurrentAppFromPathname(pathname, apps);

  if (!currentApp) {
    return undefined;
  }

  const currentPage = pathname.split(`/`)[2];

  if (!currentPage && !(`${currentApp.path}/${currentPage}/` in DocumentPathToName)) {
    return undefined;
  }

  const isDetailPage = pathname.split(`${currentApp.path}/${currentPage}/`)[1];

  return `page-${currentPage}-${isDetailPage ? 'detail' : 'dashboard'}`;
};
