import * as Sentry from '@sentry/react';
import { EnvManager } from '@services/environment/environment.service';
import { logger } from '@services/logger/logger.service';

import { version } from '../../../package.json';

import { SentryUser } from './monitoring.types';

export class Monitoring {
  constructor(private readonly sdk: typeof Sentry) {}

  public reduxEnhancer = Sentry.createReduxEnhancer({
    // It's a pitty but we have no choice in our case for data privacy reasons
    actionTransformer: (action) => ({ type: action.type }),
    stateTransformer: () => null,
  });

  // Configure the Sentry SDK
  // See: https://docs.sentry.io/error-reporting/quickstart#configure-the-sdk
  // See: https://docs.sentry.io/platforms/javascript/#capturing-the-user
  public init(): void {
    if (this.sdk.isInitialized()) {
      this.sdk.close();
    }
    this.sdk.init(this.initOptions);
    logger.info(
      'LOGIC',
      `Monitoring - Init Sentry with options ${JSON.stringify(this.initOptions)}`,
    );
  }

  // Identify the user
  public setUser(user: SentryUser): void {
    this.sdk.setUser(user);
  }

  public addEvent(category: string, message: string, level: string): void {
    this.sdk.addBreadcrumb({ category, message, level: level as Sentry.Severity });
  }

  public addError(exception: any): void {
    this.sdk.captureException(exception);
  }

  private get initOptions(): Sentry.BrowserOptions {
    // Enable on all environments except e2e and dev
    const enabled = !(EnvManager.isE2e() || EnvManager.getName() === 'dev');
    return {
      dsn: EnvManager.getSentryDsn(),
      tunnel: `https://${EnvManager.getBaseUrlProxy()}/private/proxy/sentry`,
      enabled,
      ignoreErrors: ['Non-Error exception captured'], // Sentry issue : https://github.com/getsentry/sentry-javascript/issues/2292
      release: version,
      environment: EnvManager.getName(),
      integrations: [],
      tracesSampleRate: 1,
    };
  }
}

export const monitoring = new Monitoring(Sentry);
