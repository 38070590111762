import { isLoginWebview } from '@launcher/helpers/desktop';
import { Action } from '@reduxjs/toolkit';
import { tracking } from '@services/analytics';
import { logger } from '@services/logger/logger.service';
import { monitoring } from '@services/monitoring';
import { realtimeClient } from '@services/realtimeClient.service';
import { EventChannel, eventChannel } from 'redux-saga';
import { all, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { FeatureFlags } from '@honestica/core-apps-common/types';
import { RealTimeEvent } from '@honestica/lifen-realtime-service';

import { UserData } from '@store/user/user.state';

import { InitFeatureFlags, fetchIdentitiesSuccess, initFeatureFlags } from '../user/user.actions';
import {
  getCurrentWorkspaceId,
  getFeatureFlags,
  getIdentitiesReferences,
  getUser,
  selectLocale,
} from '../user/user.selector';

function getEventChannel(channelName: string): EventChannel<Omit<unknown, 'undefined'>> {
  return eventChannel((emitter) => {
    realtimeClient
      .getClient()
      .subscribe(channelName)
      .bind('pusher:subscription_error', (error: any) => {
        logger.error('LOGIC', `Pusher subscription error`, error);
      })
      .bind_global((event: string, payload: RealTimeEvent<unknown>) => {
        emitter({
          type: event,
          payload: {
            channel: channelName,
            ...payload,
          },
        });
      });

    return () => realtimeClient.getClient().unsubscribe(channelName);
  });
}

function* initTrackingService() {
  const user: UserData = yield select(getUser);
  const featureFlags: FeatureFlags = yield select(getFeatureFlags);
  const locale: string = yield select(selectLocale);

  tracking.initUserData({
    userEmail: user.email,
    userFirstName: user.firstName,
    userId: user.uuid,
    userLastName: user.lastName,
    userWorkspaceId: user.currentWorkspaceId,
    currentApiDomain: user.currentApiDomain,
    locale,
  });
  tracking.initFeatureFlags(featureFlags);
  tracking.identify();
}

function* eventStreamSubscribe() {
  const identitiesReferences: string[] = yield select(getIdentitiesReferences);

  const channels = identitiesReferences.map((identityReference) =>
    getEventChannel(identityReference.replace('/', '-')),
  );

  while (true) {
    const actions: Action<string>[] = yield race(channels.map((channel) => take(channel)));
    const action = actions.find((a) => a !== undefined);
    if (action) {
      yield put(action);
    }
  }
}

function* setMonitoringUser(featureFlags: FeatureFlags) {
  // TODO: Move this to userAuthenticateUser hook
  // How to update featureFlag properly (using tag?)
  const { email, uuid } = yield select(getUser);
  const workspaceId: string = yield select(getCurrentWorkspaceId);
  monitoring.setUser({
    email,
    id: uuid,
    featureFlags,
    workspaceId,
  });
}

function* onInitFeatureFlags(action: ReturnType<InitFeatureFlags>) {
  logger.info('LOGIC', `onInitFeatureFlags`);
  const updatedFeatureFlags = action.payload;

  yield setMonitoringUser(updatedFeatureFlags);

  // Dot not subscribe to pusher events or init tracking in the desktop webview
  if (!isLoginWebview()) {
    yield initTrackingService();
    yield eventStreamSubscribe();
  }
}

function* featureFlagsSagas() {
  // Wait for user identities
  yield all([take(fetchIdentitiesSuccess.type)]);

  // Init pusher channels only after feature flags init to avoid to have restarting pusher if "use-proxy" flag is ON
  yield all([takeLatest(initFeatureFlags.type, onInitFeatureFlags)]);
}

export { featureFlagsSagas };
