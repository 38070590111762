'use client';

import { Suspense } from '@core-components/Suspense';
import * as Sentry from '@sentry/react';
import { EnvManager } from '@services/environment';
import { queryConfig } from '@services/react-query/react-query';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Layout, StyleProvider } from '@honestica/ui-kit/src';

import { store } from '@store';

import { ReduxSagaContextMiddleware } from './components/App/reduxSagaContext';
import { ErrorMessages } from './components/ErrorMessages';
import { ErrorPage } from './components/ErrorPage';
import { InfoMessages } from './components/InfoMessages';
import { NotificationWindow } from './components/NotificationWindow';
import { ViewportProvider } from './context/Viewport.context';
import { AuthProvider } from './providers/Auth.provider';
import { DesktopLoginProvider } from './providers/DesktopLogin.provider';
import { DesktopSettingsProvider } from './providers/DesktopSettings.provider';
import { EnvProvider } from './providers/Env.provider';
import { FeatureFlagsProvider } from './providers/FeatureFlags.provider';
import { I18nProvider } from './providers/I18n.provider';
import { UIKitProvider } from './providers/UIKit.provider';
import { UserIdentitiesProvider } from './providers/UserIdentities.provider';

type ProvidersProps = {
  children: React.ReactNode;
};

export function AppProviders({ children }: ProvidersProps) {
  const basename = EnvManager.getBrowserHistoryName();

  const queryClient = new QueryClient({ defaultOptions: queryConfig });

  return (
    <Suspense>
      <Provider store={store}>
        <Sentry.ErrorBoundary fallback={({ error }) => <ErrorPage error={error} />}>
          <EnvProvider>
            <QueryClientProvider client={queryClient}>
              {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools position="bottom-right" />}
              <DesktopSettingsProvider>
                <ViewportProvider>
                  <I18nProvider>
                    <StyleProvider hashPriority="high">
                      <UIKitProvider>
                        <Layout>
                          <BrowserRouter>
                            <ReduxSagaContextMiddleware>
                              <Routes>
                                <Route path="/apps/notification" element={<NotificationWindow />} />
                                <Route
                                  path={`${basename}/*`}
                                  element={
                                    <DesktopLoginProvider>
                                      <AuthProvider>
                                        <UserIdentitiesProvider>
                                          <FeatureFlagsProvider>{children}</FeatureFlagsProvider>
                                        </UserIdentitiesProvider>
                                      </AuthProvider>
                                    </DesktopLoginProvider>
                                  }
                                />
                                {basename && (
                                  <Route path="*" element={<Navigate to={basename} replace />} />
                                )}
                              </Routes>
                            </ReduxSagaContextMiddleware>
                            <ErrorMessages />
                            <InfoMessages />
                          </BrowserRouter>
                        </Layout>
                      </UIKitProvider>
                    </StyleProvider>
                  </I18nProvider>
                </ViewportProvider>
              </DesktopSettingsProvider>
            </QueryClientProvider>
          </EnvProvider>
        </Sentry.ErrorBoundary>
      </Provider>
    </Suspense>
  );
}
