import { useTranslation } from 'react-i18next';

import { AuthWorkspace } from '@honestica/core-apps-common/types';
import { Icon, Sidebar, Space, colors } from '@honestica/ui-kit/src';

import styles from './WorkspaceSwitch.module.less';

interface WorkspaceSwitchProps {
  currentWorkspaceId: string;
  workspaces: AuthWorkspace[];
  switchWorkspace: (id: string) => void;
}

export const WorkspaceSwitch = ({
  currentWorkspaceId,
  workspaces,
  switchWorkspace,
}: WorkspaceSwitchProps) => {
  const { t } = useTranslation();

  const handleSwitchWorkspace = (workspaceId: string) => {
    if (workspaceId !== currentWorkspaceId) {
      switchWorkspace(workspaceId);
    }
  };

  return (
    <Sidebar.Menu
      key="sub2"
      iconName="RealEstateUpdateHouseSync"
      data-testid="workspace-switch"
      header={<div className={styles.switchWorkspaceTitle}>{t('settings.workspace.switch')}</div>}
    >
      {workspaces.map((workspace) => {
        const isCurrent = workspace.id === currentWorkspaceId;
        return (
          <Sidebar.Menu.Item
            key={workspace.id}
            onClick={() => handleSwitchWorkspace(workspace.id)}
            selected={isCurrent}
          >
            <Space align="center">
              <Icon
                flex
                name="House2"
                size={16}
                color={isCurrent ? colors.primary[5] : 'inherit'}
              />
              <span>{workspace.name}</span>
            </Space>
          </Sidebar.Menu.Item>
        );
      })}
    </Sidebar.Menu>
  );
};
