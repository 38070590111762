import { isObject } from '@honestica/core-apps-common/utils';

export interface XhrStatus {
  isLoading: boolean;
  isInError: boolean;
  errorDetail?: string;
}

export interface XhrStatusWithTimeout extends XhrStatus {
  timeout: boolean;
}

export const isXhrStatus = (obj: unknown): obj is XhrStatus =>
  isObject(obj) &&
  !Array.isArray(obj) &&
  typeof (obj as XhrStatus).isLoading === 'boolean' &&
  typeof (obj as XhrStatus).isInError === 'boolean';
