import { logger } from '@services/logger/logger.service';

import { getPdfCreatorConfig } from './getPdfCreatorConfig.helper';
import { setPdfCreatorPrinterProfileGuid } from './setPdfCreatorPrinterProfileGuid.helper';
import { setPdfCreatorProfileTargetDirectory } from './setPdfCreatorProfileTargetDirectory.helper';

export async function configurePrinterForPdfCreator() {
  logger.info('DESKTOP', 'Starting PDFCreator configuration');

  const config = await getPdfCreatorConfig();

  logger.info('DESKTOP', 'Received PDFCreator config', config || {});

  if (!config) return;

  setPdfCreatorProfileTargetDirectory(config.targetDirectory.value);
  setPdfCreatorPrinterProfileGuid(config.guid.value);
}
