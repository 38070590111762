import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, CardBase, DangerBannerCard, Space, Typography } from '@honestica/ui-kit/src';

interface Props {
  className?: string;
  disabled?: boolean;
  value: string;
  onValueChange: (value: string) => void;
}

export function WatchPathSetting(props: Props) {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const { onValueChange } = props;

  const selectDirectory = useCallback(() => {
    const results = window.electron.selectDirectory({
      title: 'Sélectionnez un dossier',
      properties: ['openDirectory'],
    });

    if (!results || results.length !== 1) return;

    const directory = results[0];
    setError(null);

    if (window.electron.isExecPathDirectory(directory)) {
      setError(t('desktopSettings.watchPath.cannotUseLifen'));
    } else {
      onValueChange(directory);
    }
  }, [t, onValueChange]);

  const openDir = useCallback(() => {
    window.electron.showItemInFolder(props.value);
  }, [props.value]);

  return (
    <CardBase className={props.className}>
      <Space direction="vertical">
        <Typography.Text>{t('desktopSettings.watchPath.title')}</Typography.Text>

        <Typography.Text>
          {props.value || t('desktopSettings.watchPath.pickDirectory')}
        </Typography.Text>

        {!!error && <DangerBannerCard message={error} className="margin-bottom-md" />}
        <Space>
          {props.value && <Button onClick={openDir}>{t('desktopSettings.watchPath.open')}</Button>}

          <Button disabled={props.disabled} onClick={selectDirectory}>
            {t('desktopSettings.watchPath.edit')}
          </Button>
        </Space>
      </Space>
    </CardBase>
  );
}
