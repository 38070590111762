import {
  ARCHIVE,
  DRAFT,
  ERRORS,
  INBOX,
  INTEGRATED,
  SENT,
  WORKLIST,
} from '@constants/documents.constants';
import {
  DocumentDashboardType,
  DocumentsWithErroredActions,
} from '@store/documents/documents.state';
import { ArchivedDocumentsActions } from '@store/documents/incoming/archived.slice';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';
import { IntegratedIntegrationsActions } from '@store/documents/integrations/integrated.slice';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { ErroredDocumentsActions } from '@store/documents/outgoing/errored.slice';
import { SentDocumentsActions } from '@store/documents/outgoing/sent.slice';

export const DEFAULT_DASHBOARD_DATE_RANGE_DAYS = 90;
export const DEFAULT_DASHBOARD_HEIGHT = 200;
export const DASHBOARD_HEADER_HEIGHT = 55;
export const DASHBOARD_FOOTER_HEIGHT = 64;

export const DashboardActions: Record<DocumentDashboardType, DocumentsWithErroredActions> = {
  [INBOX]: InboxDocumentsActions,
  [ARCHIVE]: ArchivedDocumentsActions,
  [DRAFT]: DraftDocumentsActions,
  [SENT]: SentDocumentsActions,
  [WORKLIST]: WorklistIntegrationsActions,
  [INTEGRATED]: IntegratedIntegrationsActions,
  [ERRORS]: ErroredDocumentsActions,
};
