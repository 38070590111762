import { Avatar as AntAvatar } from 'antd';
import { AvatarProps } from 'antd/lib/avatar';
import cx from 'classnames';

import colors from '../../theme/colors';
import getRandomHexCodeFromTheme from '../../utils/colors';

import styles from './Avatar.module.less';

interface CustomAvatarProps extends AvatarProps {
  randomizeColor?: boolean;
  color?: string;
  background?: string;
}

export const Avatar = ({
  children,
  randomizeColor,
  style,
  color,
  background,
  ...otherProps
}: CustomAvatarProps) => {
  const defaultStyles = { backgroundColor: background ?? '#E6F7FF', color: color ?? '#262626' };

  let randomStyles = {};
  if (randomizeColor) {
    randomStyles = {
      backgroundColor: getRandomHexCodeFromTheme({
        darknessLevel: 4,
        colorsToExclude: ['yellow'],
      }),
      color: colors.white[0],
    };
  }
  return (
    <AntAvatar
      {...otherProps}
      style={{ ...defaultStyles, ...randomStyles, ...style }}
      className={cx(styles.avatar, otherProps.className)}
    >
      {children}
    </AntAvatar>
  );
};
