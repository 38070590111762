import { DesktopSettings, parseRegistrySetting } from '@launcher/utils';

import { fromPairs, isDefined } from '@honestica/core-apps-common/utils';

export async function getSettingsFromRegistry(): Promise<Partial<DesktopSettings>> {
  const keys = await window.electron.getLifenSettingsRegistryAccessor();

  const items = await Promise.all(keys.map(parseRegistrySetting));

  return fromPairs(items.filter(isDefined));
}
