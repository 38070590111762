import { openLink } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ProfessionalBaseType } from '@honestica/core-apps-common/types';
import { Sidebar } from '@honestica/ui-kit/src';

import { useModal } from '@hooks/useModal.hook';
import { useZendeskChat } from '@hooks/useZendeskChat.hook';
import { ModalType } from '@store/modal/modal.type';
import { getUser } from '@store/user/user.selector';

import { HelpReduxProps, helpConnector } from './Help.connector';

const HelpComponent = (props: HelpReduxProps) => {
  const { hideSupport, lifenSendingActivated } = props;
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { openZendeskChat } = useZendeskChat();

  const user = useSelector(getUser);
  const lifenIntegrationActivated = user.identities.identities.some((identity) => {
    const hasActiveIntegrationsModule = Boolean(identity.featureSets?.lifenFeatureSetIntegrations);

    return identity.type === ProfessionalBaseType.Organization && hasActiveIntegrationsModule;
  });

  const openZendesk = () => {
    try {
      openZendeskChat();
    } catch (error) {
      logger.error('UI', 'Failed to display the Zendesk Chat', error);
    }
  };

  return (
    <Sidebar.Menu iconName="QuestionCircleOutlined" data-testid="help-menu">
      <Sidebar.Menu.Item
        key="help-section"
        testid="help-content"
        onClick={() => openLink(t('help.helpSectionURL'))}
      >
        {t('help.helpSection')}
      </Sidebar.Menu.Item>
      {!hideSupport && (
        <Sidebar.Menu.Item
          key="support"
          testid="contact-form"
          onClick={() => openModal({ modal: ModalType.helpSupport })}
        >
          {t('help.supportSection')}
        </Sidebar.Menu.Item>
      )}
      {!hideSupport && (
        <Sidebar.Menu.Item key="intercom" testid="help-by-chat" onClick={openZendesk}>
          {t('help.helpByChat')}
        </Sidebar.Menu.Item>
      )}
      {lifenSendingActivated && (
        <Sidebar.Menu.Item
          key="news-lifen-sending"
          testid="news-lifen-sending"
          onClick={() => openLink(t('help.news.sending.link'))}
        >
          {t('help.news.sending.title')}
        </Sidebar.Menu.Item>
      )}
      {lifenIntegrationActivated && (
        <Sidebar.Menu.Item
          key="news-lifen-integration"
          testid="news-lifen-integration"
          onClick={() => openLink(t('help.news.integration.link'))}
        >
          {t('help.news.integration.title')}
        </Sidebar.Menu.Item>
      )}
      <Sidebar.Menu.Item
        key="about"
        testid="about"
        onClick={() => openModal({ modal: ModalType.helpAbout })}
      >
        {t('help.about')}
      </Sidebar.Menu.Item>
    </Sidebar.Menu>
  );
};

export const Help = helpConnector(HelpComponent);
