import { Selector } from '@reduxjs/toolkit';

import { UploadDocumentJob } from '@honestica/core-apps-common/types';

import { State } from '@store/reducer';

export const selectUploadDocumentJobs: Selector<State, UploadDocumentJob[]> = (state) =>
  state.uploadDocuments.jobs;
export const selectUploadIntegrationJobs: Selector<State, UploadDocumentJob[]> = (state) =>
  state.integrations.jobs;
