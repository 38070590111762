import { useCallback, useEffect, useRef, useState } from 'react';

import { LaunchDarklyService } from '@services/featureFlags/launchDarklyService';
import { logger } from '@services/logger/logger.service';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import {
  FeatureFlags,
  Professional,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import {
  ARCHIVE,
  DOCUMENTS_CACHE_MAX_IDENTITY_TO_PREFETCH,
  DRAFT,
  INBOX,
  SENT,
} from '@constants/documents.constants';
import { DesktopActions } from '@store/desktop/desktop.action';
import { selectDashboardSearchParams } from '@store/documents/documents.selector';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { State } from '@store/reducer';
import { initFeatureFlags, updateFeatureFlags } from '@store/user/user.actions';
import {
  getApiDomain,
  getIdentitiesReferences,
  getUser,
  selectSenderIdentities,
} from '@store/user/user.selector';
import { prefetchDocuments, prefetchSendingRequests } from '@utils/caching.util';
import { professionalToIdentityReference } from '@utils/identities.util';

export const FeatureFlagsProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const workspaceId = useSelector(getUser).currentWorkspaceId;
  const userIdentities = useSelector(selectSenderIdentities);
  const userIdentitiesReferences = useSelector(getIdentitiesReferences);
  const apiDomain = useSelector((state: State) => getApiDomain(state));

  const searchParamsDraft = useSelector((state: State) =>
    selectDashboardSearchParams(state, DRAFT),
  );
  const searchParamsSent = useSelector((state: State) => selectDashboardSearchParams(state, SENT));
  const searchParamsArchive = useSelector((state: State) =>
    selectDashboardSearchParams(state, ARCHIVE),
  );
  const searchParamsInbox = useSelector((state: State) =>
    selectDashboardSearchParams(state, INBOX),
  );
  const queryClient = useQueryClient();

  const [isInit, setIsInit] = useState(false);

  const client = useRef<LaunchDarklyService>();

  /*
  Prefetch draft documents for each identity (only if lifen sending if activated)
  */
  const fetchDraftSendingRequests = useCallback(
    (identities: Professional[], flags: FeatureFlags) => {
      identities.forEach((identity, index) => {
        // Limit number of identities to prefetch for performance reason
        if (index < DOCUMENTS_CACHE_MAX_IDENTITY_TO_PREFETCH) {
          prefetchSendingRequests({
            dashboardType: SendingsDashboardType.Draft,
            currentIdentity: professionalToIdentityReference(identity),
            searchParams: searchParamsDraft,
            dispatchFn: dispatch,
            queryClient,
            featureFlags: flags,
          });
        }
      });

      // Prefetch global draft dashboardif more than one identity
      if (identities.length > 1) {
        prefetchSendingRequests({
          dashboardType: SendingsDashboardType.Draft,
          searchParams: searchParamsDraft,
          dispatchFn: dispatch,
          queryClient,
          featureFlags: flags,
        });
      }

      // Get draft documents counter
      dispatch(DraftDocumentsActions.fetchDraftCounters);
      dispatch(DraftDocumentsActions.fetchTotalReadyToSend);
    },
    [dispatch, searchParamsDraft, queryClient],
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (!isInit && user.uuid && workspaceId && userIdentitiesReferences.length > 0) {
      logger.info('LOGIC', `LaunchDarkly userId: ${user.uuid}`);

      const LDUser = {
        key: user.uuid,
        custom: {
          workspaceId,
          identities: userIdentitiesReferences,
          apiDomain,
        },
      };

      if (!client.current) {
        client.current = new LaunchDarklyService();
      }

      client.current.onFlagsReady = (initializedFlags) => {
        dispatch(initFeatureFlags(initializedFlags));

        // Prefetch lifen sending data if activated
        if (user.settings.sendingShortcutEnabled && !initializedFlags['cafe-maintenance']) {
          // Prefetch draft sending requests for each identity
          fetchDraftSendingRequests(userIdentities, initializedFlags);

          prefetchSendingRequests({
            dashboardType: SendingsDashboardType.Sent,
            searchParams: searchParamsSent,
            dispatchFn: dispatch,
            queryClient,
            featureFlags: initializedFlags,
          });

          prefetchDocuments({
            dashboardType: SendingsDashboardType.Inbox,
            searchParams: searchParamsInbox,
            dispatchFn: dispatch,
            queryClient,
            featureFlags: initializedFlags,
          });
          prefetchDocuments({
            dashboardType: SendingsDashboardType.Archive,
            searchParams: searchParamsArchive,
            dispatchFn: dispatch,
            queryClient,
            featureFlags: initializedFlags,
          });
        }
      };

      client.current.onFlagsChange = (changedFlags) => {
        dispatch(updateFeatureFlags(changedFlags));

        if (changedFlags['enable-watchers-polling'] !== undefined) {
          // TODO : Move me into a saga
          dispatch(DesktopActions.startWatchers());
        } // or DesktopActions.initDesktop?

        if (changedFlags['cafe-maintenance'] === true) {
          dispatch(DesktopActions.closeWatchers()); // does not work in initDesktop.sagas
        }

        if (changedFlags['cafe-maintenance'] === false) {
          dispatch(DesktopActions.initDesktop());
        }
      };

      client.current.initialize(LDUser);

      setIsInit(true);
    }
  }, [
    isInit,
    user,
    workspaceId,
    dispatch,
    fetchDraftSendingRequests,
    userIdentitiesReferences,
    userIdentities,
    searchParamsDraft,
    searchParamsSent,
    searchParamsInbox,
    searchParamsArchive,
    queryClient,
    apiDomain,
  ]);

  return <>{children}</>;
};
