export function useZendeskChat() {
  const zendeskCommand = (command: string, action: string, callback?: () => void) => {
    window.zE(command, action, callback);
  };

  const openZendeskChat = () => {
    window.zE('messenger', 'show');
    window.zE('messenger', 'open');
  };

  return { zendeskCommand, openZendeskChat };
}
