import { ContactResource } from '../contactDirectory/contactState';

export interface SearchDirectoriesState {
  loading: boolean;
  error: boolean;
  contacts: ContactResource[];
  practitioners: string[];
  organizations: string[];
  totals: {
    contacts: number;
    practitioners: number;
    organizations: number;
  };
}

export const SEARCH_DIRECTORIES_INITIAL_STATE: SearchDirectoriesState = {
  error: false,
  loading: false,
  contacts: [],
  organizations: [],
  practitioners: [],
  totals: {
    contacts: 0,
    practitioners: 0,
    organizations: 0,
  },
};
