import { ReactElement } from 'react';

import { useLocation } from 'react-router-dom';

import { Modal, ModalProps } from '@honestica/ui-kit/src';

import { useModal } from '@hooks/useModal.hook';
import { ModalType } from '@store/modal/modal.type';

export interface ModalContent extends ModalProps {
  component: ReactElement;
  testId?: string;
}

type ModalsProps = {
  modalMap: {
    [modal: string]: ModalContent;
  };
};

export const ModalsComponent = ({ modalMap }: ModalsProps) => {
  const { search } = useLocation();
  const { closeModal } = useModal();
  const searchParams = new URLSearchParams(search);
  const modal = searchParams.get('modal') as ModalType;

  const modalContent = modalMap[modal];

  const { component, styles, onCancel, testId, footer, ...otherModalProps } = modalContent ?? {};

  return component ? (
    <Modal
      styles={{
        content: { padding: 0 },
        header: {
          paddingTop: '16px',
          paddingRight: '24px',
          paddingBottom: '16px',
          paddingLeft: '24px',
        },
        body: { padding: '24px' },
        footer: {
          paddingTop: '10px',
          paddingRight: '16px',
          paddingBottom: '10px',
          paddingLeft: '16px',
        },
        ...styles,
      }}
      open
      centered
      data-testid={testId}
      onCancel={(e) => {
        if (onCancel) onCancel(e);
        closeModal();
      }}
      footer={footer ?? null}
      {...otherModalProps}
    >
      {component}
    </Modal>
  ) : null;
};
