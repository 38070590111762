import { NonParsedRegistryItem } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';

export async function setPdfCreatorPrinterProfileGuid(guid: string) {
  try {
    logger.info('DESKTOP', `Setting PDFCreator ProfileGuid in printer: ${guid}`);

    const printers = await window.electron.getPrinterMappingRegistryAccessor();

    printers.forEach((p: NonParsedRegistryItem) => setLifenPrinterGuid(p, guid));
  } catch (err) {
    logger.error('DESKTOP', 'Could not set PDFCreator ProfileGuid in printer', err);
  }
}

const PROFILE_GUID_KEY = 'ProfileGuid';

export async function setLifenPrinterGuid(registryItem: NonParsedRegistryItem, guid: string) {
  const name = await registryItem.get('PrinterName');

  if (!/lifen/i.exec(name.value)) return;

  logger.info('DESKTOP', 'Lifen printer found (PDFCreator)');

  if (!registryItem.set) return;

  logger.info(
    'DESKTOP',
    `${PROFILE_GUID_KEY} for Lifen printer is going to be set with value ${guid} (PDFCreator)`,
  );

  registryItem.set!(PROFILE_GUID_KEY, 'REG_SZ', guid);
}
