import { isDesktopApp } from './desktop.utils';

export const openSettingsLink = ({ url }: { url: string }): void => {
  if (isDesktopApp()) {
    const win = window.electron.createSettingsWindow(url, { resizable: true, maximizable: true });
    /* Destroy notification window if main app is about to close */
    window.addEventListener('beforeunload', () => {
      win.destroy();
    });
    win.show();
  } else {
    window.open(url, '_blank');
  }
};

export const openLink = (url: string, newTab?: boolean): void => {
  if (isDesktopApp()) {
    window.electron.openLink(url);
  } else {
    window.open(url, newTab ? '_blank' : undefined);
  }
};
