import { useEffect } from 'react';

type Handler = (event: MouseEvent) => void;

export const useOutsideClick = (ref: React.RefObject<HTMLElement>, handler: Handler) => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [handler, ref]);

  return ref;
};
