import { useState } from 'react';

import { ErrorPage } from '@launcher/components/ErrorPage';
import { AppError } from '@launcher/constants';
import { getAllDesktopSettings, trayMenu } from '@launcher/helpers/desktop';
import { useAutoUpdate } from '@launcher/hooks';
import { isDesktopApp, isUsingNewBridgeAPI } from '@launcher/utils';
import { i18n } from '@services/i18n';
import { logger } from '@services/logger/logger.service';
import { useDispatch } from 'react-redux';

import { useOnMount } from '@honestica/core-apps-common/hooks';

import { history } from '@store';
import { DesktopActions } from '@store/desktop/desktop.action';

export const DesktopSettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const [hasLoadingSettingsError, setHasLoadingSettingsError] = useState(false);

  useOnMount(() => {
    if (isDesktopApp()) {
      /*
       * file transport logging is implemented only in the preload script
       * we wont support it in legacy electron app to avoid adding extra library (electron log)
       */
      logger.initDesktopLogger();

      // Set up tray once i18n is loaded
      if (isDesktopApp()) {
        logger.info('DESKTOP', 'Init desktop tray menu...');
        i18n.on('loaded', () => {
          if (isUsingNewBridgeAPI(window)) {
            trayMenu.init();
          }
        });
      }

      // set up history logging
      history.listen((location, action) => {
        logger.info('ROUTER', 'Location changed', { action, location: location.pathname });
      });

      logger.info('DESKTOP', 'Retrieving initial desktop settings');
      getAllDesktopSettings()
        .then((retrievedSettings) => {
          logger.info(
            'DESKTOP',
            'Retrieved initial desktop settings value: ',
            retrievedSettings.value,
          );
          dispatch(DesktopActions.setSettings(retrievedSettings));

          return undefined;
        })
        .catch((err) => {
          setHasLoadingSettingsError(true);
          logger.error('DESKTOP', 'Could not retrieve initial settings', err);
        });
    }

    logger.info('UI', `isDesktopApp: ${isDesktopApp()}`);
  });

  useAutoUpdate();

  if (hasLoadingSettingsError) {
    return <ErrorPage errorKey={AppError.LoadingDesktopSettingsFailure} />;
  }

  return <>{children}</>;
};
