import { useCallback, useMemo, useState } from 'react';

import { UsePollingSetting } from '@launcher/components/DesktopSettings/UsePollingSetting/UsePollingSetting.component';
import { DesktopSettings as DesktopSettingsType } from '@launcher/utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { diffInObj, isEmptyObj } from '@honestica/core-apps-common/utils';
import { Button, InfoBannerCard, Page, Space, Typography } from '@honestica/ui-kit/src';

import { CleanCacheAndRestart } from './CleanCacheAndRestart/CleanCacheAndRestart.component';
import { CleanOutboxSetting } from './CleanOutboxSetting/CleanOutboxSetting.component';
import { DesktopSettingsReduxProps, desktopSettingsConnector } from './DesktopSettings.connector';
import { MyRequestOnlySetting } from './MyRequestsOnlySetting/MyRequestOnlySetting.component';
import { StabilityThresholdSetting } from './StabilityThresholdSetting/StabilityThresholdSetting.component';
import {
  DesktopSettingsError,
  validateSettings,
} from './WatchPathSetting/DesktopSettings.validator';
import { WatchPathSetting } from './WatchPathSetting/WatchPathSetting.component';
import { WhitelistsDiagnostics } from './WhitelistsDiagnostics/WhitelistsDiagnostics.component';

import styles from './DesktopSettings.module.less';

export function DesktopSettingsComponent(props: DesktopSettingsReduxProps) {
  const { t } = useTranslation();
  const { updateDesktopSettings } = props;
  const navigate = useNavigate();

  const [errors, setErrors] = useState<DesktopSettingsError>({});

  const [keepFileDuringXDays, setKeepFileDuringXDays] = useState<number | ''>(
    props.settings.keepFileDuringXDays,
  );
  const [myRequestsOnly, setMyRequestsOnly] = useState(props.settings.myRequestsOnly);
  const [stabilityThreshold, setStabilityThreshold] = useState<number | ''>(
    props.settings.stabilityThreshold,
  );
  const [usePolling, setUsePolling] = useState<boolean>(props.settings.usePolling);

  const [watchPath, setWatchPath] = useState(props.settings.watchPath);

  const disabledSettings = useMemo(
    () =>
      props.immutableSettings.reduce(
        (settings, next) => ({ ...settings, [next]: true }),
        {} as Record<keyof DesktopSettingsType, boolean>,
      ),
    [props.immutableSettings],
  );

  const showRegistryLabel = useMemo(
    () =>
      disabledSettings.keepFileDuringXDays ||
      disabledSettings.stabilityThreshold ||
      disabledSettings.watchPath ||
      disabledSettings.usePolling ||
      disabledSettings.myRequestsOnly,
    [disabledSettings],
  );

  const onSave = useCallback(() => {
    const updates = diffInObj(props.settings, {
      keepFileDuringXDays: keepFileDuringXDays || 0,
      myRequestsOnly,
      stabilityThreshold: stabilityThreshold || 0,
      usePolling: usePolling || false,
      watchPath,
    });

    const errorsAfterValidate = validateSettings(updates);

    if (isEmptyObj(errorsAfterValidate)) {
      setErrors({});
    } else {
      setErrors(errorsAfterValidate);
      return;
    }

    if (!isEmptyObj(updates)) {
      updateDesktopSettings(updates);
    }

    navigate(-1);
  }, [
    props.settings,
    keepFileDuringXDays,
    myRequestsOnly,
    stabilityThreshold,
    usePolling,
    watchPath,
    navigate,
    updateDesktopSettings,
  ]);

  return (
    <Page.Full classes={{ content: styles.content }}>
      <Typography.Title level={4}>{t('desktopSettings.title')}</Typography.Title>
      {showRegistryLabel && (
        <InfoBannerCard
          className={styles.registryInfo}
          message={t('desktopSettings.fromRegistryInfo')}
        />
      )}
      <Space direction="vertical">
        <Typography.Text strong>{t('desktopSettings.application')}</Typography.Text>

        <WatchPathSetting
          disabled={disabledSettings.watchPath}
          value={watchPath}
          onValueChange={setWatchPath}
        />
        <MyRequestOnlySetting
          disabled={disabledSettings.myRequestsOnly}
          value={myRequestsOnly}
          onValueChange={setMyRequestsOnly}
        />

        <CleanOutboxSetting
          disabled={disabledSettings.keepFileDuringXDays}
          value={keepFileDuringXDays}
          onValueChange={setKeepFileDuringXDays}
        />

        <StabilityThresholdSetting
          disabled={disabledSettings.stabilityThreshold}
          error={errors.stabilityThreshold}
          value={stabilityThreshold}
          onValueChange={setStabilityThreshold}
        />

        <UsePollingSetting
          disabled={disabledSettings.usePolling}
          value={usePolling}
          onValueChange={setUsePolling}
        />

        <WhitelistsDiagnostics />

        <CleanCacheAndRestart />
      </Space>
      <Space className={styles.actions}>
        <Button onClick={() => navigate(-1)}>{t('desktopSettings.back')}</Button>
        <Button type="primary" onClick={onSave}>
          {t('desktopSettings.save')}
        </Button>
      </Space>
    </Page.Full>
  );
}

export const DesktopSettings = desktopSettingsConnector(DesktopSettingsComponent);
