import { useCallback, useEffect } from 'react';

import { sortUserIdentities } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';
import { useDispatch, useSelector } from 'react-redux';

import {
  Professional,
  ProfessionalBaseType,
  ResourceList,
} from '@honestica/core-apps-common/types';

import { getUserIdentities, getUserIntegrations } from '@api';
import {
  authenticateUserFailure,
  fetchIdentitiesFailure,
  fetchIdentitiesSuccess,
  fetchIntegrationsFailure,
  fetchIntegrationsSuccess,
} from '@store/user/user.actions';
import { getIsAuthenticated, getIsLifenSendingActivated } from '@store/user/user.selector';
import { AuthenticationError } from '@store/user/user.state';
import { filterSenderIdentities } from '@utils/identities.util';

export const UserIdentitiesProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const isLifenSendingActivated = useSelector(getIsLifenSendingActivated);
  const isAuthenticated = useSelector(getIsAuthenticated);

  /*
  Fetch loggued user identities from backend and dispatch them to store
  */
  const fetchIdentities = useCallback(async () => {
    try {
      const { list: identities, total }: ResourceList<Professional> = await getUserIdentities();

      const hasIntegrationIdentity = identities.some((identity) => {
        const hasActiveIntegrationsModule = Boolean(
          identity.featureSets?.lifenFeatureSetIntegrations,
        );

        return identity.type === ProfessionalBaseType.Organization && hasActiveIntegrationsModule;
      });

      if (!isLifenSendingActivated && !hasIntegrationIdentity) {
        dispatch(authenticateUserFailure(AuthenticationError.MisconfiguredAccount));
        logger.info('LOGIC', 'No active applications to display');
        return;
      }

      if (total === 0 || filterSenderIdentities(identities).length === 0)
        dispatch(authenticateUserFailure(AuthenticationError.MisconfiguredAccount));
      else {
        dispatch(fetchIdentitiesSuccess(sortUserIdentities(identities)));
        logger.info('LOGIC', 'User identities fetched', { total });
      }
    } catch (error) {
      logger.error('LOGIC', 'Failed to fetch user identities', error);
      dispatch(fetchIdentitiesFailure({ error })); // not handled https://honestica.atlassian.net/browse/REB-410
    }
  }, [dispatch, isLifenSendingActivated]);

  /*
  Fetch loggued user integrations from backend and dispatch them to store
  */
  const fetchIntegrations = useCallback(async () => {
    try {
      const { list: integrations, total }: ResourceList<Professional> = await getUserIntegrations();
      dispatch(fetchIntegrationsSuccess(integrations));
      logger.info('LOGIC', 'User integrations fetched', { total });
    } catch (error) {
      logger.error('LOGIC', 'Failed to fetch user integrations', error);
      dispatch(fetchIntegrationsFailure({ error }));
      // not handled https://honestica.atlassian.net/browse/REB-410
    }
  }, [dispatch]);

  /*
  Fetch all user data related
  */
  const fetchUserRelatedDatas = useCallback(async () => {
    // Fetch user identities and integrations
    await Promise.all([fetchIdentities(), fetchIntegrations()]);
  }, [fetchIdentities, fetchIntegrations]);

  /*
  Do nothing if we are not authenticated, or in the webview
  */
  useEffect(() => {
    if (isAuthenticated) {
      fetchUserRelatedDatas();
    }
  }, [fetchUserRelatedDatas, isAuthenticated]);

  return <>{children}</>;
};
