export function hasOwnProperty<X, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> {
  return isNonNullObject(obj) && Object.prototype.hasOwnProperty.call(obj, prop);
}

export function isNonNullObject(val: any): val is Record<string, any> {
  return typeof val === 'object' && val !== null;
}
