import { HttpError } from '@services/http/http.error.service';
import { logger } from '@services/logger/logger.service';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ApiErrorsKey } from '@honestica/core-apps-common/constants';
import {
  IntegrationsDashboardType,
  SendingsDashboardType,
  isIntegrationsDashboardType,
} from '@honestica/core-apps-common/types';

import { downloadDocuments, downloadIntegrationRequests } from '@api';
import { ARCHIVE, DRAFT, INBOX, INTEGRATED, SENT, WORKLIST } from '@constants/documents.constants';
import { BatchDownloadDocumentsAction } from '@store/documents/documents.actions';
import { ArchivedDocumentsActions } from '@store/documents/incoming/archived.slice';
import { IntegratedIntegrationsActions } from '@store/documents/integrations/integrated.slice';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';

import { InboxDocumentsActions } from '../../incoming/inbox.slice';
import { DraftDocumentsActions } from '../../outgoing/draft.slice';
import { SentDocumentsActions } from '../../outgoing/sent.slice';

const dashboardTypeToActions = {
  [INBOX]: InboxDocumentsActions,
  [ARCHIVE]: ArchivedDocumentsActions,
  [DRAFT]: DraftDocumentsActions,
  [SENT]: SentDocumentsActions,
  [INTEGRATED]: IntegratedIntegrationsActions,
  [WORKLIST]: WorklistIntegrationsActions,
};

function downloadDocumentsSaga(dashboardType: SendingsDashboardType | IntegrationsDashboardType) {
  const Actions = dashboardTypeToActions[dashboardType];
  return function* saga(action: BatchDownloadDocumentsAction) {
    try {
      if (isIntegrationsDashboardType(dashboardType)) {
        yield call(downloadIntegrationRequests, action.payload.ids);
      } else {
        yield call(downloadDocuments, action.payload.ids, dashboardType);
      }
      yield put(Actions.downloadManySuccess());
    } catch (error) {
      if (HttpError.getKey(error) === ApiErrorsKey.NO_DOCUMENT_ATTACHEMENT) {
        // When we try to download a unique document without attachment
        yield put(Actions.downloadManyNoAttachmentFailure({ error }));
        logger.error('LOGIC', 'No attachment', error);
      } else {
        logger.error('LOGIC', 'Failed to download documents', error);
        yield put(Actions.downloadManyFailure({ error }));
      }
    }
  };
}

export function* downloadDashboardDocumentsSagas() {
  yield takeLatest(InboxDocumentsActions.downloadMany.type, downloadDocumentsSaga(INBOX));
  yield takeLatest(ArchivedDocumentsActions.downloadMany.type, downloadDocumentsSaga(ARCHIVE));
  yield takeLatest(DraftDocumentsActions.downloadMany.type, downloadDocumentsSaga(DRAFT));
  yield takeLatest(SentDocumentsActions.downloadMany.type, downloadDocumentsSaga(SENT));

  yield takeLatest(
    IntegratedIntegrationsActions.downloadMany.type,
    downloadDocumentsSaga(INTEGRATED),
  );
  yield takeLatest(WorklistIntegrationsActions.downloadMany.type, downloadDocumentsSaga(WORKLIST));
}
