export const LOGIN_PARTITION = 'persist:lifenconnect';

export const DESKTOP_LOGIN = 'DESKTOP_LOGIN';

export const MAX_JOB_PROCESSING_COUNT = 5;
export const EMAIL = 'EMAIL';
export const EMAIL_FROM_LOCALSTORAGE_DESKTOP = 'EMAIL_FROM_LOCALSTORAGE_DESKTOP';
export const CONNECTION = 'CONNECTION';

export const WORKSPACE_ID_LOCALSTORAGE_KEY = 'WORKSPACE_ID';
export const SENDER_REFERENCE_LOCALSTORAGE_KEY = 'SENDER_REFERENCE'; // Deprecated: https://honestica.atlassian.net/browse/VLINT-475
export const SENDER_IDENTITY_REFERENCES_LOCALSTORAGE_KEY = 'SENDER_IDENTITY_REFERENCES';
export const SIGNUP_VIEW_TO_DISPLAY = 'view';
export const UTM_SOURCE = 'utm_source';
export const UTM_MEDIUM = 'utm_medium';
export const UTM_CAMPAIGN = 'utm_campaign';
export const UTM_TERM = 'utm_term';
export const UTM_CONTENT = 'utm_content';

export const ROOT_WATCHPATH = 'Lifen';
export const SENDING_WATCHPATH = 'Outbox';
export const SUCCESS_DIRECTORY = 'Success';
export const ERROR_DIRECTORY = 'Error';

export const WATCHERS_POLLING_INTERVAL_DEFAULT = 2000;
export const WATCHER_WRITE_POLLING_INTERVAL = 500;
export const UPLOAD_DEFAULT_RETRY_DELAY = 1000;
