import { FailureAction, SearchPatientsSuccessAction } from '../../documents.actions';
import { XHR_STATUS_ERROR, XHR_STATUS_IDLE, XHR_STATUS_LOADING } from '../../documents.state';
import { INITIAL_WORKLIST_INTEGRATIONS_STATE, WorklistIntegrationsState } from '../worklist.state';

export const onSearchPatients = (state: WorklistIntegrationsState): WorklistIntegrationsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    patientsRequest: XHR_STATUS_LOADING,
    patientIds: INITIAL_WORKLIST_INTEGRATIONS_STATE.detailView.patientIds,
  },
});

export const onSearchPatientsSuccess = (
  state: WorklistIntegrationsState,
  action: SearchPatientsSuccessAction,
): WorklistIntegrationsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    patientsRequest: XHR_STATUS_IDLE,
    patientIds: action.payload.patients.list.map((patient) => patient.id),
  },
});

export const onSearchPatientsFailure = (
  state: WorklistIntegrationsState,
  _action: FailureAction,
): WorklistIntegrationsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    patientsRequest: XHR_STATUS_ERROR,
  },
});

export const onFlushPatientSearch = (
  state: WorklistIntegrationsState,
): WorklistIntegrationsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    patientsRequest: XHR_STATUS_IDLE,
    patientIds: [],
  },
});
