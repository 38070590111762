import { createAction } from '@reduxjs/toolkit';

import {
  SearchDirectoriesResultWithContacts,
  SearchParams,
} from '@honestica/core-apps-common/types';

const REDUCER_NAME = 'searchDirectories';

export const setSearchDirectoriesLoading = createAction<void>(`${REDUCER_NAME}/setLoading`);
export const flushSearchedEntities = createAction<void>(`${REDUCER_NAME}/flush`);

const SEARCH = `${REDUCER_NAME}/search`;

export const searchDirectories = createAction<SearchParams>(SEARCH);
export const searchDirectoriesSuccess = createAction<SearchDirectoriesResultWithContacts>(
  `${SEARCH}Success`,
);
export const searchDirectoriesFailure = createAction<unknown>(`${SEARCH}Failure`);

export type FlushSearchedEntities = typeof flushSearchedEntities;
export type SearchDirectories = typeof searchDirectories;
export type SearchDirectoriesSuccess = typeof searchDirectoriesSuccess;
