import { Action, PayloadAction } from '@reduxjs/toolkit';
import { DocumentActionClickedActionType, Tracking } from '@services/analytics';
import { QueryClient } from '@tanstack/react-query';
import { all, getContext, select, takeLatest } from 'redux-saga/effects';

import {
  DashboardSearchParams,
  FeatureFlags,
  SendingRequestDto,
} from '@honestica/core-apps-common/types';

import { DRAFT, ERRORS, WORKLIST } from '@constants/documents.constants';
import {
  BatchSuccessAction,
  OneAction,
  OneSuccessAction,
} from '@store/documents/documents.actions';
import {
  selectDashboardSearchParams,
  selectDetailDocumentDto,
  selectDocumentEntity,
  selectManyDocuments,
} from '@store/documents/documents.selector';
import { ArchivedDocumentsActions } from '@store/documents/incoming/archived.slice';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import { WorklistIntegrationRequestDtoWithMeta } from '@store/documents/integrations/worklist.state';
import { selectDraftCurrentIdentityReference } from '@store/documents/outgoing/draft.selector';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { DraftDocumentDtoWithMeta } from '@store/documents/outgoing/draft.state';
import { ErroredDocumentsActions } from '@store/documents/outgoing/errored.slice';
import {
  MoveInsideSelectionPayload,
  moveDownInsideSelection,
  moveUpInsideSelection,
} from '@store/selection/selection.action';
import { SetSelectedIdentity, setSelectedIdentity } from '@store/user/user.actions';
import { getFeatureFlags } from '@store/user/user.selector';
import { findDocumentInCache } from '@utils/caching.util';

function getActionType(type: string): DocumentActionClickedActionType {
  let actionType = DocumentActionClickedActionType.Unknown;
  if (type.includes('cancelOne')) {
    actionType = DocumentActionClickedActionType.Delete;
  }
  if (type.includes('downloadOne')) {
    actionType = DocumentActionClickedActionType.Download;
  }
  if (type.includes('sendOne')) {
    actionType = DocumentActionClickedActionType.Send;
  }
  if (type.includes('duplicateOne')) {
    actionType = DocumentActionClickedActionType.Duplicate;
  }
  if (type.includes('splitOne')) {
    actionType = DocumentActionClickedActionType.Split;
  }
  if (type.includes('archiveOne')) {
    actionType = DocumentActionClickedActionType.Archive;
  }
  if (type.includes('restoreOne')) {
    actionType = DocumentActionClickedActionType.Restore;
  }
  if (type.includes('integrateOne')) {
    actionType = DocumentActionClickedActionType.Integrate;
  }
  if (type.includes('forwardOne')) {
    actionType = DocumentActionClickedActionType.Forward;
  }

  return actionType;
}

function* trackDocumentAction(data: OneAction) {
  const trackingService: Tracking = yield getContext('trackingService');
  const { type, payload } = data;

  trackingService.documentActionClicked({
    actionType: getActionType(type),
    communicationRequest: payload.id,
  });
}

function* trackRefreshPatientMatchSuccess({ payload }: OneAction) {
  const trackingService: Tracking = yield getContext('trackingService');
  trackingService.documentIntegrationMatchDone({ fhirReference: payload.id });
}

function* trackGroupedDocumentReordered({ payload }: PayloadAction<MoveInsideSelectionPayload>) {
  const trackingService: Tracking = yield getContext('trackingService');
  trackingService.groupedDocumentReordered({ fhirReference: payload.id });
}

function* trackSuccessfulMessagesSent({ payload }: BatchSuccessAction) {
  const queryClient: QueryClient = yield getContext('queryClient');
  const featureFlags: FeatureFlags = yield select(getFeatureFlags);
  const trackingService: Tracking = yield getContext('trackingService');

  const searchParams: DashboardSearchParams = yield select(selectDashboardSearchParams, DRAFT);
  const currentIdentity: string | undefined = yield select((state) =>
    selectDraftCurrentIdentityReference(state),
  );

  payload.ids.forEach((id) => {
    const resource = findDocumentInCache({
      dashboardType: DRAFT,
      identity: currentIdentity,
      search: { searchParams, featureFlags },
      documentId: id,
      queryClient,
    });

    if (resource) {
      trackingService.messageDocumentSent(resource, true);
    }
  });
}

function* trackSuccessfulMessageSent() {
  const trackingService: Tracking = yield getContext('trackingService');

  const documentDtoFromDetailView: SendingRequestDto = yield select(selectDetailDocumentDto, DRAFT);
  const resource = documentDtoFromDetailView;

  if (resource) {
    trackingService.messageDocumentSent(resource);
  }
}

function* trackNotificationSetAsRead({ payload }: OneSuccessAction) {
  const trackingService: Tracking = yield getContext('trackingService');
  const existingDocEntity: DraftDocumentDtoWithMeta | undefined = yield select(
    selectDocumentEntity,
    payload.id,
    ERRORS,
  );

  if (existingDocEntity?.resource) {
    trackingService.notificationSetAsRead({
      communicationRequest: existingDocEntity?.resource.id,
    });
  }
}

function* trackSuccessfulDocumentsIntegrated({ payload }: BatchSuccessAction) {
  const trackingService: Tracking = yield getContext('trackingService');
  const existingDocEntities: WorklistIntegrationRequestDtoWithMeta[] = yield select(
    selectManyDocuments,
    payload.ids,
    WORKLIST,
  );
  if (existingDocEntities) {
    existingDocEntities.forEach((existingDocEntity): void => {
      trackingService.messageDocumentIntegrated({
        document: existingDocEntity.resource,
        isBatchAction: true,
        numberOfItemInBatch: existingDocEntities.length,
      });
    });
  }
}

function* trackSuccessfulDocumentIntegrated({ payload }: OneSuccessAction) {
  const trackingService: Tracking = yield getContext('trackingService');
  const existingDocEntity: WorklistIntegrationRequestDtoWithMeta | undefined = yield select(
    selectDocumentEntity,
    payload.id,
    WORKLIST,
  );
  if (existingDocEntity) {
    trackingService.messageDocumentIntegrated({
      document: existingDocEntity.resource,
      isBatchAction: false,
    });
  }
}

function* trackSelectedIdentityUpdated({ payload }: ReturnType<SetSelectedIdentity>) {
  const trackingService: Tracking = yield getContext('trackingService');

  trackingService.selectedIdentityUpdated({
    newSelectedIdentity: `${payload.identity.type}/${payload.identity.id}`,
  });
}

export function* trackingDocumentActionsSaga() {
  yield all([takeLatest(DraftDocumentsActions.sendOne.type, trackDocumentAction)]);
  yield all([
    takeLatest((action: Action) => /downloadOne$/.test(action.type), trackDocumentAction),
  ]);
  yield all([takeLatest((action: Action) => /cancelOne$/.test(action.type), trackDocumentAction)]);
  yield all([takeLatest((action: Action) => /forwardOne$/.test(action.type), trackDocumentAction)]);
  yield all([takeLatest(DraftDocumentsActions.splitOne.type, trackDocumentAction)]);
  yield all([
    takeLatest((action: Action) => /duplicateOne$/.test(action.type), trackDocumentAction),
  ]);
  yield all([takeLatest(InboxDocumentsActions.archiveOne.type, trackDocumentAction)]);
  yield all([takeLatest(WorklistIntegrationsActions.integrateOne.type, trackDocumentAction)]);
  yield all([takeLatest(ArchivedDocumentsActions.restoreOne.type, trackDocumentAction)]);
  yield all([takeLatest(moveDownInsideSelection.type, trackGroupedDocumentReordered)]);
  yield all([takeLatest(moveUpInsideSelection.type, trackGroupedDocumentReordered)]);
  yield all([
    takeLatest(
      DraftDocumentsActions.refreshPatientMatchSuccess.type,
      trackRefreshPatientMatchSuccess,
    ),
  ]);
  yield all([takeLatest(DraftDocumentsActions.sendManySuccess.type, trackSuccessfulMessagesSent)]);
  yield all([takeLatest(DraftDocumentsActions.sendOneSuccess.type, trackSuccessfulMessageSent)]);
  yield all([
    takeLatest(ErroredDocumentsActions.markOneAsReadSuccess.type, trackNotificationSetAsRead),
  ]);
  yield all([
    takeLatest(
      WorklistIntegrationsActions.integrateOneSuccess.type,
      trackSuccessfulDocumentIntegrated,
    ),
  ]);
  yield all([
    takeLatest(
      WorklistIntegrationsActions.integrateManySuccess.type,
      trackSuccessfulDocumentsIntegrated,
    ),
  ]);
  yield all([takeLatest(setSelectedIdentity.type, trackSelectedIdentityUpdated)]);
}
