export function getInitials(name: string) {
  const initials = name
    .split(' ')
    .map((part: string) => part[0])
    .join('');

  return initials.toUpperCase().slice(0, 2);
}

export function getUserInitials({
  email,
  firstName,
  lastName,
}: {
  email: string;
  firstName?: string;
  lastName?: string;
}): string {
  return firstName && lastName
    ? (firstName[0] + lastName[0]).toUpperCase()
    : email.slice(0, 2).toUpperCase();
}

export function isUuid(str: string): boolean {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return uuidRegex.test(str);
}
