import { createSlice } from '@reduxjs/toolkit';

import { uploadReducer } from '@store/upload/upload.reducer';

import { INITIAL_UPLOAD_DOCUMENTS_STATE } from './uploadDocuments.state';

const uploadDocumentsSlice = createSlice({
  name: 'uploadDocuments',
  initialState: INITIAL_UPLOAD_DOCUMENTS_STATE,
  reducers: {
    ...uploadReducer,
  },
});

export const UploadDocumentsActions = uploadDocumentsSlice.actions;

export const uploadDocumentsReducer = uploadDocumentsSlice.reducer;
