import { createAction } from '@reduxjs/toolkit';

import { ModalType } from './modal.type';

export type OpenModalFromStore = typeof openModalFromStore;
export type CloseModalFromStore = typeof closeModalFromStore;

const REDUCER_NAME = 'modal';

export const openModalFromStore = createAction<{
  modal: ModalType;
  search?: Record<string, string | undefined>;
}>(`${REDUCER_NAME}/open`);
export const closeModalFromStore = createAction(`${REDUCER_NAME}/close`);
