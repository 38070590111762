import { ComponentProps } from 'react';

import { Tooltip } from 'antd';
import cx from 'classnames';

import Icon from '../../Icon';

import styles from './Sidebar.Icon.module.less';

interface SidebarIconProps extends ComponentProps<'div'> {
  iconName?: string; // For SVG Icons (core apps)
  iconUrl?: string; // For PNG Icons (external apps)
  isSelected: boolean;
  onClick: () => void;
  title: string;
}

export const SidebarIcon = (props: SidebarIconProps) => {
  const { iconName, iconUrl, isSelected, onClick, title, ...rest } = props;
  const InnerIcon = () => {
    if (props.iconName) {
      return (
        <Icon
          flex
          className={cx(styles.icon, props.isSelected && styles.iconSelected)}
          name={props.iconName}
          title={props.title}
        />
      );
    }
    return (
      <img src={props.iconUrl} alt={props.title} className={cx(styles.icon, styles.pngIcon)} />
    );
  };
  return (
    <Tooltip placement="right" color="black" title={props.title} trigger={['click', 'hover']}>
      <div
        className={cx(styles.iconWrapper, props.isSelected && styles.iconWrapperSelected)}
        aria-current={isSelected && 'true'}
        role="button"
        onClick={props.onClick}
        tabIndex={0}
        {...rest}
      >
        <InnerIcon />
      </div>
    </Tooltip>
  );
};
