import { DefaultOptions } from '@tanstack/react-query';

import { DOCUMENTS_CACHE_TIME } from '@constants/documents.constants';

export const queryConfig: DefaultOptions = {
  queries: {
    cacheTime: DOCUMENTS_CACHE_TIME,
    retry: false,
  },
};
