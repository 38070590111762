import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { NavigateFunction } from 'react-router-dom';
import { getContext, put, select, takeLatest } from 'redux-saga/effects';

import {
  DocumentStatus,
  DocumentVerificationStatus,
  Professional,
  SendingRequestBundle,
  SendingRequestDto,
  SendingsDashboardType,
  UserSettings,
} from '@honestica/core-apps-common/types';

import * as API from '@api';
import { DRAFT_DETAIL_VIEW_PATH, INBOX } from '@constants/documents.constants';
import { OneAction } from '@store/documents/documents.actions';
import { selectDetailDocumentDto } from '@store/documents/documents.selector';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { removeFromSelection } from '@store/selection/selection.action';
import { getSelectedIdentity, selectUserSettings } from '@store/user/user.selector';
import { refreshDraftDocumentsInCache } from '@utils/caching.util';
import { professionalToIdentityReference } from '@utils/identities.util';

function* forwardOneDocumentSaga({ payload: { id } }: OneAction) {
  try {
    const navigate: NavigateFunction = yield getContext('customNavigate');
    const queryClient: QueryClient = yield getContext('queryClient');

    const userSettings: UserSettings = yield select(selectUserSettings);
    const selectedIdentity: Professional | undefined = yield select(
      getSelectedIdentity,
      'lifenSending',
    );
    const selectedIdentityReference = selectedIdentity
      ? selectedIdentity.practRolePractitionerReference ??
        `${selectedIdentity.type}/${selectedIdentity.id}`
      : undefined; // should always be defined when a forward happens

    const res: { document: SendingRequestBundle['document'] } = yield API.forwardDocument(
      id,
      SendingsDashboardType.Inbox,
      userSettings,
      selectedIdentityReference,
    );
    yield put(
      InboxDocumentsActions.forwardOneSuccess({ id /* should update the initial doc meta */ }),
    );

    const selectedDocumentDto: SendingRequestDto = yield select(selectDetailDocumentDto, INBOX);
    const documentIdentity = professionalToIdentityReference(selectedDocumentDto.sender);

    refreshDraftDocumentsInCache({ identity: documentIdentity, queryClient });
    refreshDraftDocumentsInCache({ identity: 'all', queryClient });

    yield put(
      DraftDocumentsActions.incrementDraftCounter({
        identity: documentIdentity,
        count: 1,
      }),
    );

    if (
      selectedDocumentDto.status === DocumentStatus.Suspended &&
      selectedDocumentDto.verificationStatus === DocumentVerificationStatus.ReadyToSend
    ) {
      yield put(
        DraftDocumentsActions.incrementReadyToSendCounter({
          identity: documentIdentity,
          count: 1,
        }),
      );
    }

    yield put(removeFromSelection({ dashboard: SendingsDashboardType.Inbox, id }));
    navigate(`${DRAFT_DETAIL_VIEW_PATH}/${res.document.id}`);
  } catch (error) {
    logger.error('LOGIC', 'Failed to forward document', error);

    yield put(
      InboxDocumentsActions.forwardOneFailure({
        id,
        error,
      }),
    );
  }
}

export function* forwardInboxDocumentsSagas() {
  yield takeLatest(InboxDocumentsActions.forwardOne.type, forwardOneDocumentSaga);
}
