import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { getCurrentAppTag, getCurrentPageTag, openLink } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AppKeys } from '@honestica/core-apps-common/types';
import { validateEmail } from '@honestica/core-apps-common/utils';
import {
  Alert,
  Button,
  DangerBannerCard,
  Email,
  Form,
  Icon,
  Input,
  Space,
  Typography,
} from '@honestica/ui-kit/src';

import { createSupportTicket } from '@api';
import { getApiDomain, getUser, selectActiveApps } from '@store/user/user.selector';

import { formatComment, isIdValid } from './utils';

import styles from './SupportModal.module.less';

const SupportModalComponent = () => {
  const { email: userEmail } = useSelector(getUser);
  const apiDomain = useSelector(getApiDomain);

  const { t } = useTranslation();

  const { Paragraph, Text } = Typography;

  const [comment, setComment] = useState<string>();
  const [email, setEmail] = useState<string>(userEmail);
  const [formSent, setFormSent] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  const [subject, setSubject] = useState<string>();
  const [docId, setDocId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validateId = useCallback(() => isIdValid(docId, apiDomain), [docId, apiDomain]);

  const formatedComment = useCallback(() => {
    if (docId && comment) {
      return formatComment(docId, comment);
    }
    return '';
  }, [docId, comment]);

  const isFormValid = !!(
    name &&
    name.trim().length > 0 &&
    subject &&
    subject.trim().length > 0 &&
    comment &&
    comment.trim().length > 0 &&
    validateEmail(email) &&
    validateId()
  );

  const coreApps = useSelector(selectActiveApps);
  const { pathname } = useLocation();

  const currentApp = useMemo(
    () => getCurrentAppTag(pathname, coreApps) || AppKeys.Documents,
    [coreApps, pathname],
  );

  const currentPage = useMemo(() => getCurrentPageTag(pathname, coreApps), [coreApps, pathname]);

  const onClick = async () => {
    setIsLoading(true);
    try {
      if (!isFormValid) {
        setError(true);
        setIsLoading(false);

        return;
      }
      await createSupportTicket({
        comment: formatedComment(),
        subject,
        email,
        name,
        currentApp,
        currentPage,
      });
      setFormSent(true);
    } catch (e) {
      logger.error('HTTP', 'Failed to post support ticket', e);
      setError(true);
      setIsLoading(false);
    }
  };

  if (formSent) {
    return <Alert type="success" message={t('help.support.sent')} showIcon />;
  }

  return (
    <Form layout="vertical" data-testid="patient-edit">
      {error && <DangerBannerCard message={t('error.default')} className="margin-bottom-md" />}
      <Paragraph>
        <Trans
          i18nKey="help.support.help.title"
          values={{}}
          components={{
            italic: <Text italic />,
            icon: (
              <Icon
                name="ChatBubble"
                size={18}
                style={{ verticalAlign: 'text-bottom', display: 'inline-flex' }}
                inline-flex
                title={t('help.helpByChat')}
              />
            ),
          }}
        />
      </Paragraph>

      <Form.Item label={t('help.support.name')} htmlFor="name" className="margin-top-xl">
        <Input
          onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
          value={name}
          id="name"
          size="large"
          disabled={isLoading}
        />
      </Form.Item>
      <Email
        disabled={isLoading}
        email={email}
        errorMessage={t('email.validationErrorMessage')}
        formItemProps={{
          label: (
            <Space>
              <Icon name="EmailActionUnread" size={18} flex title={t('email.title')} />
              {t('email.title')}
            </Space>
          ),
        }}
        setEmail={(value: string) => setEmail(value)}
        size="large"
      />
      <Form.Item label={t('help.support.subject')} htmlFor="subject">
        <Input
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSubject(event.target.value)}
          value={subject}
          id="subject"
          size="large"
          disabled={isLoading}
        />
      </Form.Item>
      <Form.Item
        htmlFor="docId"
        help={!validateId() && docId ? t('help.support.error.docId') : ''}
        validateStatus={!validateId() && docId ? 'error' : ''}
      >
        <div className={styles.docIdLabel}>
          <Typography.Text>{t('help.support.docId')}</Typography.Text>
          <Button
            className={styles.docIdFaqLink}
            type="link"
            size="small"
            onClick={() => openLink(t('help.support.faqURL'), true)}
          >
            {t('help.support.faq')}
          </Button>
        </div>
        <Input
          onChange={(event: ChangeEvent<HTMLInputElement>) => setDocId(event.target.value)}
          value={docId}
          aria-label="docId"
          id="docId"
          size="large"
          maxLength={12}
          disabled={isLoading}
        />
      </Form.Item>

      <Form.Item label={t('help.support.comment')} htmlFor="comment">
        <Input.TextArea
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setComment(event.target.value)}
          value={comment}
          id="comment"
          size="large"
          rows={5}
          maxLength={2000}
          disabled={isLoading}
        />
      </Form.Item>
      <Space direction="vertical" align="end" fullWidth>
        <Space>
          <Button
            size="large"
            type="primary"
            onClick={onClick}
            disabled={!isFormValid || isLoading}
            loading={isLoading}
          >
            {t('help.support.sendButton')}
          </Button>
        </Space>
      </Space>
    </Form>
  );
};

export const SupportModal = SupportModalComponent;
