import { useSelector } from 'react-redux';

import { ConfigProvider as UIKitConfigProvider, colors, en_GB, fr_FR } from '@honestica/ui-kit/src';

import { selectLocale } from '@store/user/user.selector';

export const UIKitProvider = ({ children }: { children: React.ReactNode }) => {
  const locale = useSelector(selectLocale);
  /**
   * We must do this to be compliant with date-fns
   * @See https://github.com/ant-design/ant-design/issues/26699
   * @See https://github.com/react-component/picker/pull/289/files
   */
  let currentLocale;
  if (locale === 'en') {
    currentLocale = en_GB;
    en_GB.DatePicker!.lang.locale = 'en_GB';
  } else {
    fr_FR.DatePicker!.lang.locale = 'fr';
    currentLocale = fr_FR;
  }

  return (
    <UIKitConfigProvider
      theme={{
        token: {
          fontFamily: 'Open Sans',

          colorPrimary: colors.primary[5],
          colorLink: colors.primary[5],
        },
        components: {
          Button: {
            primaryShadow: 'none',
          },
          Layout: {
            bodyBg: colors.grey[2],
          },
          Menu: {
            iconSize: 24,
            itemBg: colors.primary[9],
          },
          Modal: {
            titleFontSize: 18,
          },
          Table: {
            rowSelectedBg: colors.orange[0],
            rowSelectedHoverBg: colors.orange[1],
          },
        },
      }}
      locale={currentLocale}
    >
      {children}
    </UIKitConfigProvider>
  );
};
