import colors, { ColorName } from '../theme/colors';

const getRandomHexCodeFromTheme = ({
  darknessLevel = 5,
  colorsToExclude = [],
}: {
  darknessLevel?: number;
  colorsToExclude?: ColorName[];
}) => {
  const colorsArray: ColorName[] = (Object.keys(colors) as ColorName[]).filter(
    (color: ColorName) => !['grey', 'white', 'black', ...colorsToExclude].includes(color),
  );
  const index = Math.floor(Math.random() * colorsArray.length);
  return colors[colorsArray[index]][darknessLevel];
};

export default getRandomHexCodeFromTheme;
