import {
  CounterDraft,
  CounterReadyToSend,
  EhrPatientIntegration,
  Encounter,
  List,
  SendingRequest,
  SendingRequestDto,
  SendingsDashboardType,
  SuggestedRecipients,
} from '@honestica/core-apps-common/types';

import { DRAFT } from '@constants/documents.constants';
import { XhrStatus, XhrStatusWithTimeout } from '@store/types';

import {
  BaseDashboardViewState,
  BaseDetailViewState,
  BaseEntitiesState,
  DelayedDocumentIdsToRefreshState,
  DocumentDtoWithMeta,
  DownloadRequestState,
  INITIAL_DASHBOARD_VIEW_STATE,
  INITIAL_DETAIL_VIEW_STATE,
  INITIAL_DOWNLOAD_STATE,
  INITIAL_SETTINGS_OVERRIDES_VIEW_STATE,
  LoadingDocumentIds,
  SettingsOverridesState,
  XHR_STATUS_IDLE,
} from '../documents.state';

export interface DraftDetailViewState extends BaseDetailViewState {
  encountersRequest: XhrStatus;
  encounters: List<Encounter>;
  ehrPatientsRequest: XhrStatus;
  ehrPatients: List<EhrPatientIntegration>;
  patientsRequest: XhrStatus;
  patientIds: string[];
  suggestedRecipients: SuggestedRecipients | undefined;
}

export interface ReadyToSendCounterState {
  totalReadyToSend: CounterReadyToSend;
}

export const INITIAL_READYTOSEND_COUNTER_STATE: CounterReadyToSend = {
  total: 0,
  identities: {},
};
export interface DraftCounterState {
  totalDraft: CounterDraft;
}

export const INITIAL_DRAFT_COUNTER_STATE: CounterDraft = {
  total: 0,
  identities: {},
};

export interface CurrentIdentityState {
  currentIdentity?: string;
}
export interface DraftDocumentsState {
  entities: BaseEntitiesState<DraftDocumentDtoWithMeta>;
  dashboardView: BaseDashboardViewState &
    DelayedDocumentIdsToRefreshState &
    DownloadRequestState &
    LoadingDocumentIds &
    SettingsOverridesState &
    ReadyToSendCounterState &
    DraftCounterState &
    CurrentIdentityState;
  detailView: DraftDetailViewState;
}

export const isDraftDocument = (obj: DocumentDtoWithMeta): obj is DraftDocumentDtoWithMeta =>
  obj.meta.docDashboardType === DRAFT;

export const INITIAL_DRAFT_DOCUMENTS_STATE: DraftDocumentsState = {
  entities: {},
  dashboardView: {
    ...INITIAL_DASHBOARD_VIEW_STATE,
    delayedIdsToRefresh: [],
    downloadRequest: INITIAL_DOWNLOAD_STATE,
    settingsOverrides: INITIAL_SETTINGS_OVERRIDES_VIEW_STATE,
    totalReadyToSend: INITIAL_READYTOSEND_COUNTER_STATE,
    totalDraft: INITIAL_DRAFT_COUNTER_STATE,
    currentIdentity: undefined,
    searchParams: {
      ...INITIAL_DASHBOARD_VIEW_STATE.searchParams,
      defaultDateRange: true,
      statuses: ['draft', 'suspended'],
      includePredictions: true,
      includeEhrFallback: true,
      includePractitionerRoles: true,
      includeIntegration: true,
      includeConnectors: true,
      includeUnselectedConnectors: true,
    },
  },
  detailView: {
    ...INITIAL_DETAIL_VIEW_STATE,
    encountersRequest: XHR_STATUS_IDLE,
    encounters: { total: 0, list: [] },
    ehrPatientsRequest: XHR_STATUS_IDLE,
    ehrPatients: { total: 0, list: [] },
    patientsRequest: XHR_STATUS_IDLE,
    patientIds: [],
    suggestedRecipients: undefined,
  },
};

export type DraftDocumentDtoWithMeta = {
  resource: SendingRequestDto;
  meta: DraftDocumentMetadata;
};

export type DraftSendingRequestWithMeta = {
  resource: SendingRequest;
  meta: DraftDocumentMetadata;
};

export enum DraftDocumentMetadataKey {
  cancelDocument = 'cancelDocument',
  docDashboardType = 'docDashboardType',
  duplicateDocument = 'duplicateDocument',
  fetchAttachmentsRequest = 'fetchAttachmentsRequest',
  fileStorageIds = 'fileStorageIds',
  groupDocument = 'groupDocument',
  refreshPatientMatch = 'refreshPatientMatch',
  removePatient = 'removePatient',
  removeRecipient = 'removeRecipient',
  sendDocument = 'sendDocument',
  setEnsConversationClosed = 'setEnsConversationClosed',
  setEnsMessage = 'setEnsMessage',
  splitDocument = 'splitDocument',
  updateDocType = 'updateDocType',
  updateIntegration = 'updateIntegration',
  updateEhrPatient = 'updateEhrPatient',
  updatePatient = 'updatePatient',
  updatePeriodDate = 'updatePeriodDate',
  updateRecipients = 'updateRecipients',
  updateSender = 'updateSender',
}
export interface DraftDocumentMetadata {
  // dashboard
  [DraftDocumentMetadataKey.groupDocument]: XhrStatus;
  // detail
  [DraftDocumentMetadataKey.fetchAttachmentsRequest]: XhrStatus;
  [DraftDocumentMetadataKey.fileStorageIds]: string[] | undefined;
  [DraftDocumentMetadataKey.refreshPatientMatch]: XhrStatusWithTimeout;
  [DraftDocumentMetadataKey.removePatient]: XhrStatus;
  [DraftDocumentMetadataKey.removeRecipient]: XhrStatus;
  [DraftDocumentMetadataKey.setEnsConversationClosed]: XhrStatus;
  [DraftDocumentMetadataKey.setEnsMessage]: XhrStatus;
  [DraftDocumentMetadataKey.updateDocType]: XhrStatus;
  [DraftDocumentMetadataKey.updateEhrPatient]: XhrStatus;
  [DraftDocumentMetadataKey.updateIntegration]: XhrStatus;
  [DraftDocumentMetadataKey.updatePatient]: XhrStatus;
  [DraftDocumentMetadataKey.updatePeriodDate]: XhrStatus;
  [DraftDocumentMetadataKey.updateRecipients]: XhrStatus;
  [DraftDocumentMetadataKey.updateSender]: XhrStatus;
  // common
  [DraftDocumentMetadataKey.cancelDocument]: XhrStatus;
  [DraftDocumentMetadataKey.duplicateDocument]: XhrStatus;
  [DraftDocumentMetadataKey.sendDocument]: XhrStatus;
  [DraftDocumentMetadataKey.splitDocument]: XhrStatus;
  [DraftDocumentMetadataKey.docDashboardType]: SendingsDashboardType.Draft;
}

export type DraftDocumentMetadataType = keyof DraftDocumentMetadata;

export const INITIAL_DRAFT_DOCUMENT_METADATA: DraftDocumentMetadata = {
  // dashboard
  [DraftDocumentMetadataKey.groupDocument]: XHR_STATUS_IDLE,
  // detail
  [DraftDocumentMetadataKey.fetchAttachmentsRequest]: XHR_STATUS_IDLE,
  [DraftDocumentMetadataKey.fileStorageIds]: undefined,
  [DraftDocumentMetadataKey.refreshPatientMatch]: { ...XHR_STATUS_IDLE, timeout: false },
  [DraftDocumentMetadataKey.removePatient]: XHR_STATUS_IDLE,
  [DraftDocumentMetadataKey.removeRecipient]: XHR_STATUS_IDLE,
  [DraftDocumentMetadataKey.setEnsConversationClosed]: XHR_STATUS_IDLE,
  [DraftDocumentMetadataKey.setEnsMessage]: XHR_STATUS_IDLE,
  [DraftDocumentMetadataKey.updateDocType]: XHR_STATUS_IDLE,
  [DraftDocumentMetadataKey.updateEhrPatient]: XHR_STATUS_IDLE,
  [DraftDocumentMetadataKey.updateIntegration]: { ...XHR_STATUS_IDLE },
  [DraftDocumentMetadataKey.updatePatient]: XHR_STATUS_IDLE,
  [DraftDocumentMetadataKey.updatePeriodDate]: XHR_STATUS_IDLE,
  [DraftDocumentMetadataKey.updateRecipients]: XHR_STATUS_IDLE,
  [DraftDocumentMetadataKey.updateSender]: XHR_STATUS_IDLE,
  // common
  [DraftDocumentMetadataKey.cancelDocument]: XHR_STATUS_IDLE,
  [DraftDocumentMetadataKey.duplicateDocument]: XHR_STATUS_IDLE,
  [DraftDocumentMetadataKey.sendDocument]: XHR_STATUS_IDLE,
  [DraftDocumentMetadataKey.splitDocument]: XHR_STATUS_IDLE,
  [DraftDocumentMetadataKey.docDashboardType]: DRAFT,
};
