import { getFileInMemory } from '@launcher/utils';

import {
  Address,
  CreateIntegrationRequest,
  DocumentPatient,
  DocumentPatientDto,
  DocumentRecipientBundle,
  DocumentRecipientDto,
  DocumentStatus,
  DocumentType,
  DocumentVerificationStatus,
  DocumentVerificationTypes,
  IntegrationRequestDto,
  MedicalExamType,
  Medium,
  MediumList,
  NewDocumentPayload,
  Patient,
  ProfessionalBaseType,
  SendingRequest,
  SendingRequestDto,
  Telecom,
  TelecomType,
  UploadDocumentJob,
} from '@honestica/core-apps-common/types';
import {
  getNameWithPrefix,
  parsePatientName,
  partitionArray,
} from '@honestica/core-apps-common/utils';

import { MAX_RECIPIENTS_COUNT } from '@constants/documents.constants';
import { Entities } from '@store/entities/entities.state';
import { UserData } from '@store/user/user.state';

import { hasConnectorEnabled } from './integrations.util';

function getCreateDocumentBase({ job, user }: { job: UploadDocumentJob; user: UserData }) {
  const { sender, flowType } = job;
  return {
    sender,
    requesterId: user.uuid,
    file: getFileInMemory(job.fileStorageId) as File,
    flowType,
    /**
     * Monkeypath to fix https://honestica.atlassian.net/browse/CORE-3149
     * @todo please delete this after the creation of alphonse /v1/users/{id}/settings endpoint
     * don't forget to delete userSettings property in packages/server/api/validators/schemas/Document.json
     */
    userSettings: user.settings,
  };
}

export function getCreateIntegrationRequest({
  job,
  user,
}: {
  job: UploadDocumentJob;
  user: UserData;
}): CreateIntegrationRequest {
  return {
    ...getCreateDocumentBase({ job, user }),
    userSettings: user.settings,
  };
}

export const getUploadDocumentPayload = ({
  job,
  user,
}: {
  job: UploadDocumentJob;
  user: UserData;
}): NewDocumentPayload => ({
  ...getCreateDocumentBase({ job, user }),
  documentsMerged: [],
  isReadOnly: false,
});

export const isEmailDematPatient = (document: SendingRequest | SendingRequestDto) =>
  document.patient?.telecoms && document.patient.telecoms.some((t) => t.type === TelecomType.EMAIL);

export const hasPostalAddress = (
  recipient: DocumentRecipientBundle | DocumentRecipientDto | DocumentPatientDto | DocumentPatient,
): boolean => recipient.addresses.length > 0;

const hasTelecom = (
  recipient: DocumentRecipientBundle | DocumentRecipientDto | DocumentPatientDto | DocumentPatient,
) => recipient.telecoms.length > 0;

export const isPatientRecipient = (
  documentPatient: SendingRequest['patient'] | SendingRequestDto['patient'],
): boolean =>
  !!(documentPatient && (hasTelecom(documentPatient) || hasPostalAddress(documentPatient)));

const mediumHasDeliveryErrors = (medium: Medium<Telecom | Address>): boolean =>
  !!medium.deliveryStatus && medium.deliveryStatus.status === 'error';

const mediumHasDeliverySuccess = (medium: Medium<Telecom | Address>): boolean =>
  !!medium.deliveryStatus && medium.deliveryStatus.status === 'success';

const mediumHasDeliverySuccessOrRead = (medium: Medium<Telecom | Address>): boolean =>
  !!medium.deliveryStatus &&
  (medium.deliveryStatus.status === 'success' || medium.deliveryStatus.status === 'read');

export const recipientHasSomeMediumsInyError = (
  recipient: DocumentPatientDto | DocumentPatient | DocumentRecipientDto | DocumentRecipientBundle,
): boolean => {
  const telecomsOrAddressesHasDeliveryErrors = ({
    telecoms,
    addresses,
  }: {
    telecoms: MediumList<Telecom>;
    addresses: MediumList<Address>;
  }) =>
    [
      telecoms.some((medium) => mediumHasDeliveryErrors(medium)),
      addresses.some((medium) => mediumHasDeliveryErrors(medium)),
    ].some((predicate) => predicate);

  return telecomsOrAddressesHasDeliveryErrors(recipient);
};

export const recipientHasAllMediumsInError = (
  recipient: DocumentPatientDto | DocumentPatient | DocumentRecipientDto | DocumentRecipientBundle,
): boolean => {
  const telecomsInErrorList = recipient.telecoms.filter((medium) =>
    mediumHasDeliveryErrors(medium),
  );
  const addressesInErrorList = recipient.addresses.filter((medium) =>
    mediumHasDeliveryErrors(medium),
  );

  return (
    telecomsInErrorList.length === recipient.telecoms.length &&
    addressesInErrorList.length === recipient.addresses.length
  );
};

export const recipientHasAllMediumsInSuccess = (
  recipient: DocumentPatientDto | DocumentPatient | DocumentRecipientDto | DocumentRecipientBundle,
): boolean => {
  const telecomsInSuccessList = recipient.telecoms.filter((medium) =>
    mediumHasDeliverySuccessOrRead(medium),
  );
  const addressesInSuccessList = recipient.addresses.filter((medium) =>
    mediumHasDeliverySuccess(medium),
  );

  return (
    telecomsInSuccessList.length === recipient.telecoms.length &&
    addressesInSuccessList.length === recipient.addresses.length
  );
};

export const patientRecipientHasSomeMediumInError = (
  patientRecipient: SendingRequest['patient'],
): boolean => (patientRecipient ? recipientHasSomeMediumsInyError(patientRecipient) : false);
export const patientRecipientHasAllMediumInError = (
  patientRecipient: SendingRequest['patient'],
): boolean => (patientRecipient ? recipientHasAllMediumsInError(patientRecipient) : false);
export const patientRecipientHasAllMediumInSuccess = (
  patientRecipient: SendingRequest['patient'],
): boolean => (patientRecipient ? recipientHasAllMediumsInSuccess(patientRecipient) : false);

export const orderRecipients = (
  recipients: DocumentRecipientBundle[],
  condition: (rec: DocumentRecipientBundle) => boolean,
) => {
  const partitioned = partitionArray(recipients ?? [], condition);
  return [...partitioned[0], ...partitioned[1]];
};

export const getTelecomsGroupedByTypes = (
  recipient: DocumentPatient | DocumentRecipientBundle,
): Map<TelecomType, Medium<Telecom>[]> => {
  const telecomsMap = new Map();
  recipient.telecoms.forEach((telecom) => {
    const collection = telecomsMap.get(telecom.type);
    if (!collection) {
      telecomsMap.set(telecom.type, [telecom]);
    } else {
      collection.push(telecom);
    }
  });
  return telecomsMap;
};

export const getMediumsForMultipleRecipients = (
  recipients: DocumentRecipientBundle[],
): { telecoms: MediumList<Telecom>; addresses: MediumList<Address> } => {
  const telecoms: MediumList<Telecom> = [];
  const addresses: MediumList<Address> = [];
  recipients.forEach((rec) => {
    telecoms.push(...rec.telecoms);
    addresses.push(...rec.addresses);
  });
  return { telecoms, addresses };
};

export const formatRecipientsForDashboard = (
  recipients: SendingRequest['recipients'],
  isPatientRec: boolean,
  orderCondition = (rec: DocumentRecipientBundle) => hasPostalAddress(rec),
): SendingRequest['recipients'][] => {
  if (!recipients) {
    return [];
  }
  const ordered = orderRecipients(recipients, orderCondition);
  const maxLength = isPatientRec ? 4 : 5;
  if (ordered.length > maxLength) {
    return [ordered.slice(0, maxLength - 1), ordered.slice(maxLength - 1)];
  }
  return [ordered];
};

export function calculateSendingRequestDtoRecipientCount(
  document: SendingRequestDto | undefined,
): number {
  let count = 0;
  if (!document) {
    return count;
  }

  // TODO : Remove filters when patient set as recipient will be moved to document's recipients
  count = document.recipients.filter((recipientDto) =>
    Object.values(ProfessionalBaseType).includes(recipientDto.type),
  ).length;
  return isPatientRecipient(document.patient) ? count + 1 : count;
}

export function calculateSendingRequestRecipientCount(
  document: SendingRequest | undefined,
): number {
  let count = 0;
  if (!document) {
    return count;
  }

  // TODO : Remove filters when patient set as recipient will be moved to document's recipients
  count = document.recipients.filter((recipientDto) => recipientDto.entity).length;
  return isPatientRecipient(document.patient) ? count + 1 : count;
}

/**
 * Returns all selected telecoms for a given recipient
 */
export function getRecipientTelecomsFromDocument(
  document: SendingRequest | undefined,
  recipientId: string,
): MediumList<Telecom> {
  const recipient = document?.recipients.find((rec) => rec.entity.id === recipientId);
  if (!recipient) return [];

  return recipient.telecoms;
}

/**
 * Returns all selected address for a given recipient
 */
export function getRecipientAddressesFromDocument(
  document: SendingRequest | undefined,
  recipientId: string,
): MediumList<Address> {
  const recipient = document?.recipients.find((rec) => rec.entity.id === recipientId);
  if (!recipient) return [];

  return recipient.addresses;
}

export function isSuspended(documentDto: SendingRequestDto | IntegrationRequestDto): boolean {
  return documentDto.status === DocumentStatus.Suspended;
}

export function isReadOnly(documentDto: SendingRequestDto): boolean {
  return documentDto.isReadOnly;
}

export function isCancelled(document: SendingRequest): boolean {
  return document.status === DocumentStatus.Cancelled;
}

export function isReadyToSend({
  document,
  patient,
}: {
  document: SendingRequestDto;
  patient: Patient | undefined;
}): boolean {
  // Status
  if (!(document.status === DocumentStatus.Suspended)) {
    return false;
  }

  // VerificationStatus
  if (
    ![
      DocumentVerificationStatus.ReadyToSend,
      DocumentVerificationStatus.UserVerificationNeeded,
      DocumentVerificationStatus.Downgraded,
    ].includes(document.verificationStatus)
  ) {
    return false;
  }

  // Verifications (will become useless with the new UserCorrectionNeeded status)
  const blockingVerifications = [
    DocumentVerificationTypes.integration_missing,
    DocumentVerificationTypes.patient_missing,
    DocumentVerificationTypes.recipients_missing,
    DocumentVerificationTypes.recipients_without_medium,
    DocumentVerificationTypes.email_telecom_without_mfa,
  ];
  const hasBlockingVerification = document.verifications.some((verification) =>
    blockingVerifications.includes(verification),
  );

  if (hasBlockingVerification) {
    return false;
  }

  // Sender
  if (!document.sender) {
    return false;
  }

  // Patient
  if (!patient || !parsePatientName(patient)) {
    return false;
  } // why should we need patient from entities?

  // Recipient count
  const recipientsCount = calculateSendingRequestDtoRecipientCount(document);

  // eslint-disable-next-line sonarjs/prefer-single-boolean-return
  if (recipientsCount > MAX_RECIPIENTS_COUNT) {
    return false;
  }

  return true;
}

export function areSomeConnectorsEnabledForSending(
  document: SendingRequestDto | undefined,
): boolean {
  return hasConnectorEnabled(document?.integration);
}

export function isReadyToBatchSend({
  document,
  patient,
}: {
  document: SendingRequestDto;
  patient: Patient | undefined;
}): boolean {
  if (!isReadyToSend({ document, patient })) {
    return false;
  }

  // eslint-disable-next-line sonarjs/prefer-single-boolean-return
  if (document.verificationStatus !== DocumentVerificationStatus.ReadyToSend) {
    return false;
  }

  return true;
}

export function documentIdIsReadyToSend({
  documentId,
  documents,
  patients,
}: {
  documentId: string;
  documents?: SendingRequestDto[];
  patients: Entities<Patient>;
}): boolean {
  const document = documents?.find((doc) => doc.id === documentId);
  let patient: Patient | undefined;

  if (!document) {
    return false;
  }
  if (document?.patient?.id) {
    patient = patients[document?.patient?.id];
  }
  return isReadyToBatchSend({ document, patient });
}

export function getDocumentTypeDisplayNameKey(documentType: DocumentType = 'OTHER'): string {
  return `documents.dashboard.columns.documentType.${documentType}`;
}

export function getDocumentTypeDisplayNameShortKey(documentType: DocumentType = 'OTHER'): string {
  return `documents.dashboard.columns.documentTypeShort.${documentType}`;
}

export function getMedicalExamTypeDisplayNameKey(medicalExamType: MedicalExamType) {
  return `documents.worklistDetail.medicalExamType.${medicalExamType}`;
}

export const getDocumentRecipientName = (document: SendingRequest) => {
  let recipientName =
    document.recipients.length > 0 ? getNameWithPrefix(document.recipients[0].entity) : undefined;
  const { integration } = document;

  if (integration) {
    recipientName = recipientName ?? integration.name;
  }
  return recipientName;
};
