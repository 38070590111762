import { ConnectedProps, connect } from 'react-redux';

import { State } from '@store/reducer';
import { switchWorkspace } from '@store/user/user.actions';
import { getIsLifenSendingActivated } from '@store/user/user.selector';

export type AppsReduxProps = ConnectedProps<typeof appsConnector>;

const mapStateToProps = (state: State) => ({
  lifenSendingActivated: getIsLifenSendingActivated(state),
});

const appsConnector = connect(mapStateToProps, {
  switchWorkspace,
});

export default appsConnector;
