import {
  startAutomaticCleaningOutbox,
  stopAutomaticCleaningOutbox,
} from '@launcher/helpers/desktop';
import { DesktopSettings } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';
import { all, select, takeLatest } from 'redux-saga/effects';

import { getIsLifenSendingActivated } from '@store/user/user.selector';

import { DesktopActions } from '../desktop.action';
import { selectDesktopSettings } from '../desktop.selector';

function* openSaga() {
  const settings: DesktopSettings = yield select(selectDesktopSettings);
  const isLifenSendingActivated: boolean = yield select(getIsLifenSendingActivated);

  const watchPaths: string[] = [];
  if (isLifenSendingActivated) {
    watchPaths.push(settings.watchPath);
  }

  if (watchPaths.length > 0) {
    startAutomaticCleaningOutbox(watchPaths, settings.keepFileDuringXDays);
  } else {
    logger.error('LOGIC', 'No watchPath found to call "startAutomaticCleaningOutbox"');
  }
}

function closeSaga() {
  stopAutomaticCleaningOutbox();
}

export function* cleanOutboxFolderSagas() {
  yield all([takeLatest(DesktopActions.startWatchers.type, openSaga)]);
  yield all([takeLatest(DesktopActions.closeWatchers.type, closeSaga)]);
}
