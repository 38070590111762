import { Address } from './address';
import { ProfessionalDto } from './professional';
import { Telecoms } from './telecom';

export enum InsQualification {
  PROV = 'PROV',
  VALI = 'VALI',
  QUALI = 'QUALI',
}

export type PatientGender = 'male' | 'female' | 'other';

export interface PatientBase {
  active: boolean;
  address: Address[];
  birthFirstname?: string;
  birthLastname?: string;
  birthdate?: string;
  gender?: PatientGender;
  generalPractitioner?: ProfessionalDto;
  ins?: string;
  insQualification?: InsQualification;
  ipp?: string;
  nir?: string;
  telecoms: Telecoms;
  usualFirstname?: string;
  usualLastname?: string;
  ehrDataOverwritten?: true;
}

export type CreatePatient = PatientBase;
export interface Patient extends PatientBase {
  id: string;
  versionId: string;
}

export interface PatientSearch {
  birthdate?: string;
  ipp?: string;
  firstname?: string;
  lastname?: string;
  gender?: PatientGender;
}
