import { isUsingNewBridgeAPI } from '@launcher/utils/desktop';
import { i18n } from '@services/i18n';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuItemConstructorOptions } from 'electron';

import { version as appVersion } from '../../../../package.json';

const image =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFMSURBVHgBpVPBUcNADJQuecAvHSR0YCrACQ1ABeAZME9MB1BBnB+GB+4AVwBXglNB3AF+5cEMJ9ZnY5MExwR25kZnydrTaU8s/uSNmAb0N+T9Olkoxz6HHbQSlv8k5QePSMTpfwvPOHq5FX8cI3i2lpkRi8eR1uucfeqEpLQvYw51LheuS6Qc6tGQDM2pZ3QHgT351CZfTaZwBHVIFWGVqdrBNLQnsBo2+Tzje51tJDcYMYJC7ciwkpZkC6hgDja8H1BBqXPsrrclf92kxB7lRbnWPuoUigRkzKGVbiuBqIVdS1WetOQQ11qIf3xSEBEZ73cVrGKE7j/Lpevwg05QRbIrQRXtPVn7XlSB97AzAYmDWZlyrHOOXtEPc1MRZbAaAnjdL5EpQE8wH+YOTQ7hCVfCzTvgtGJ3qejBz4gh8dwOHds+HfG/xhkSfwLu3ojG2yMZYAAAAABJRU5ErkJggg==';

class Tray {
  private displayAutoUpdate = false;

  // only for < 100.14.18
  private tray: null | { destroy: () => void } | void;

  private get icon() {
    return window.electron.getNativeImageModule().createFromDataURL(image);
  }

  public addUpdater() {
    this.displayAutoUpdate = true;

    // only for < 100.14.18
    if (!isUsingNewBridgeAPI(window) && !this.tray) {
      return;
    }

    this.makeTray();
  }

  // only for < 100.14.18
  public remove(): void {
    this.tray?.destroy();
    this.tray = null;
  }

  public init(): void {
    this.makeTray();

    if (!isUsingNewBridgeAPI(window)) {
      // only for < 100.14.18
      window.addEventListener('beforeunload', () => this.remove());
    }
  }

  private get menuItems() {
    if (!window.electron) return [];

    // click property as action is useless if >= 100.14.18
    // for >= 100.14.18 makeTrayMenu use id to set click as string

    return [
      {
        id: 'dashboard',
        label: i18n.t('bringWindowToFront'),
        click: () => window.electron.showCurrentWindow(),
      },
      {
        id: 'about',
        label: i18n.t('help.about'),
        submenu: [
          {
            label: `${i18n.t('app.distantVersion')} ${appVersion}`,
            enabled: false,
          },
          {
            label: `${i18n.t('app.localVersion')} ${window.electron.getElectronVersion()}`,
            enabled: false,
          },
        ],
      },
      ...(this.displayAutoUpdate ? [this.getMenuItemAutoUpdate()] : []),
      {
        id: 'exit',
        label: i18n.t('exit'),
        click: () => window.electron.exitApp(),
      },
    ] as MenuItemConstructorOptions[];
  }

  private getMenuItemAutoUpdate() {
    return {
      id: 'update',
      label: i18n.t('app.updateAvailable'),
      click: () => {
        window.electron.getAutoUpdater().quitAndInstall();
      },
    };
  }

  private makeTray() {
    if (!isUsingNewBridgeAPI(window)) {
      // only for < 100.14.18
      this.remove();
      this.tray = window.electron.makeTrayMenu({
        icon: this.icon,
        menuItems: this.menuItems,
      }) as void | { destroy: () => void };
    } else {
      window.electron.makeTrayMenu({ icon: this.icon, menuItems: this.menuItems }) as void | {
        destroy: () => void;
      };
    }
  }
}

export const trayMenu = new Tray();
