import { EnvManager } from '@services/environment';
import { addDays, format } from 'date-fns';

import {
  DashboardSearchParams,
  ParsedSearchParams,
  PatientSearch,
} from '@honestica/core-apps-common/types';

import { DEFAULT_DASHBOARD_DATE_RANGE_DAYS } from '@constants/dashboard.constants';
import { DRAFT } from '@constants/documents.constants';
import { DocumentDashboardType } from '@store/documents/documents.state';

export const defaultDateRangeRule = addDays(
  new Date(),
  -Number(EnvManager.getDashboardDateRangeDays() || DEFAULT_DASHBOARD_DATE_RANGE_DAYS),
);

export function searchParamsToParsedSearchParams(
  searchParams: DashboardSearchParams,
): ParsedSearchParams {
  const parsedSearchParams = { ...searchParams };
  const cleanoutList = ['defaultDateRange', 'dateRange', 'searchPatient']; // use constants instead (see ParsedSearchParams type)

  return Object.fromEntries(
    Object.entries(parsedSearchParams).reduce((acc, [key, val]) => {
      if (!cleanoutList.includes(key)) {
        acc.push([key, val]);
      }
      return acc;
    }, [] as [string, any][]),
  );
}

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-param-reassign */
export const parseSearchParams = (searchParams: DashboardSearchParams): ParsedSearchParams => {
  const parsedSearchParams = searchParamsToParsedSearchParams(searchParams);

  if (searchParams.defaultDateRange) {
    parsedSearchParams.fromDate = defaultDateRangeRule;
  } else if (searchParams.dateRange) {
    parsedSearchParams.fromDate = searchParams.dateRange.fromDate;
    parsedSearchParams.untilDate = searchParams.dateRange.untilDate;
  }

  if (searchParams.searchPatient) {
    Object.entries(searchParams.searchPatient).forEach(([key, val]) => {
      const newkey = `patientSearch[${key}]` as keyof ParsedSearchParams;

      parsedSearchParams[newkey] = val;
    });
  }
  return Object.entries(parsedSearchParams).reduce<Record<string, any>>((params, [key, val]) => {
    if (val !== undefined) {
      if (typeof val.getMonth === 'function') {
        params[key] = format(val, 'yyyy-MM-dd');
      } else {
        params[key] = val;
      }
    }

    return params;
  }, {}); // we could also filter '' here (done in getSearchparams)
};

export function cleanFFSearchParams({
  searchParams,
  dashboardType,
  currentIdentity,
}: {
  searchParams: DashboardSearchParams;
  dashboardType: DocumentDashboardType;
  currentIdentity?: string;
}): DashboardSearchParams {
  const cleanedParams = { ...searchParams };

  if (dashboardType === DRAFT && currentIdentity) {
    cleanedParams.selectedSenderFilter = undefined;
    cleanedParams.strictSender = currentIdentity;
  }

  return cleanedParams;
}

export function searchPatientToParamList(searchPatient: PatientSearch): string[] {
  return Object.keys(searchPatient).map((param) => param);
}
