import {
  SENDER_IDENTITY_REFERENCES_LOCALSTORAGE_KEY,
  SENDER_REFERENCE_LOCALSTORAGE_KEY,
} from '@launcher/constants';

import {
  Connector,
  Organization,
  Practitioner,
  PractitionerRole,
  Professional,
  ProfessionalDto,
} from '@honestica/core-apps-common/types';
import {
  formatProfessionnalName,
  getNameWithPrefix,
  getProfessionalAsReference,
} from '@honestica/core-apps-common/utils';
import {
  isConnector,
  isOrganization,
  isPractitioner,
  isPractitionerRole,
} from '@honestica/core-apps-common/validators';

interface IdentityOption {
  value: string;
  label: JSX.Element | string;
  disabled: boolean;
}

export function getActivitiesOptions(
  identity: Practitioner,
  currentWorkspace?: string,
): IdentityOption[] {
  return identity.practitionerRoles.map((activity) => ({
    value: getProfessionalAsReference(activity),
    label: currentWorkspace
      ? `${getNameWithPrefix(formatProfessionnalName(activity))} - ${currentWorkspace}`
      : getNameWithPrefix(activity),
    disabled: false,
  }));
}

export function getSortedIdentities(
  identities: (Organization | Practitioner)[],
): (Organization | Practitioner)[] {
  return [...identities].sort((a, b) => `${a.name}`.localeCompare(b.name));
}

export function identitiesOptionsForSender(
  identities: Professional[],
  currentWorkspace?: string,
): IdentityOption[] {
  const sortedIdentities = getSortedIdentities(identities);

  return sortedIdentities.reduce<IdentityOption[]>((mem, identity) => {
    if (isPractitioner(identity) && identity.practitionerRoles.length > 0) {
      mem.push(...getActivitiesOptions(identity, currentWorkspace));
      return mem;
    }

    mem.push({
      value: getProfessionalAsReference(identity),
      label: currentWorkspace
        ? `${getNameWithPrefix(formatProfessionnalName(identity))} - ${currentWorkspace}`
        : getNameWithPrefix(identity),
      disabled: false,
    });

    return mem;
  }, []);
}

export function filterDeviceIdentitiesOut(
  identities: (Organization | Practitioner | Connector)[],
): (Organization | Practitioner)[] {
  return identities.filter((identity) => !isConnector(identity)) as (Organization | Practitioner)[];
}

export function firstOrganizationIdentity(
  identities: (Organization | Practitioner)[],
): Organization | undefined {
  return identities.find((identity) => isOrganization(identity)) as Organization;
}

export function filterSenderIdentities(
  identities: Professional[],
): (Organization | PractitionerRole)[] {
  return filterDeviceIdentitiesOut(identities).reduce<Professional[]>((acc, identity) => {
    if (isPractitioner(identity) && identity.practitionerRoles.length > 0) {
      acc.push(identity.practitionerRoles[0]);
    } else if (isPractitionerRole(identity)) {
      acc.push(identity);
    } else if (isOrganization(identity)) {
      acc.push(identity);
    } else if (isPractitioner(identity)) {
      acc.push(identity);
    } // this case should be deleted when practRole migration is completed
    return acc;
  }, []);
}

export function identitiesListToObject(identities: Professional[]): Record<string, Professional> {
  return identities.reduce<Record<string, Professional>>((acc, identity) => {
    acc[getProfessionalAsReference(identity)] = identity;
    return acc;
  }, {});
}

export function extractProfessionalFromIdentities(
  identities: (Organization | Practitioner)[],
): Record<string, Professional> {
  return identities.reduce<Record<string, Professional>>((acc, identity) => {
    acc[getProfessionalAsReference(identity)] = identity;

    if (isPractitioner(identity) && identity.practitionerRoles.length > 0) {
      for (const practitionerRole of identity.practitionerRoles) {
        acc[getProfessionalAsReference(practitionerRole)] = practitionerRole;
      }
    }

    return acc;
  }, {});
}

export function extractProfessionalReferencesFromIdentities(
  identities: (Organization | Practitioner)[],
): string[] {
  return identities.reduce<string[]>((acc, identity) => {
    acc.push(getProfessionalAsReference(identity));

    if (isPractitioner(identity) && identity.practitionerRoles.length > 0) {
      for (const practitionerRole of identity.practitionerRoles) {
        acc.push(getProfessionalAsReference(practitionerRole));
      }
    }

    return acc;
  }, []);
}

export function findIdentityByReference(
  identities: Professional[],
  reference: string | null | undefined,
): Professional | undefined {
  if (!reference) return undefined;
  const professionals = extractProfessionalFromIdentities(identities);
  return professionals[reference];
}

export function getSenderIdentitiesFromLS() {
  const senderIdentityReferencesFromLS = localStorage.getItem(
    SENDER_IDENTITY_REFERENCES_LOCALSTORAGE_KEY,
  );

  return senderIdentityReferencesFromLS && JSON.parse(senderIdentityReferencesFromLS);
}

export function urlIdentityToIdentityReference(urlIdentity: string) {
  const [type, id] = urlIdentity.split(/-(.*)/s); // only the first "-" because of "uuid" format in tests
  return `${type}/${id}`; // add a format validation here?
}

export function identityReferenceToUrlIdentity(urlIdentity: string) {
  const [type, id] = urlIdentity.split('/');
  return `${type}-${id}`;
}

export function professionalToIdentityReference(identity: Professional | ProfessionalDto) {
  return `${identity.type}/${identity.id}`;
}

export function identityToUrlIdentity(identity: Professional) {
  return `${identity.type}-${identity.id}`;
}

export function getSelectedIdentities(
  identities: Professional[],
  exisitingSenderIdentities: {
    existingSenderIdentityLifenSending: Professional | undefined;
    existingSenderIdentityLifenIntegration: Professional | undefined;
  },
) {
  // Deprecated: https://honestica.atlassian.net/browse/VLINT-475
  const oldSenderReferenceFromLS = localStorage.getItem(SENDER_REFERENCE_LOCALSTORAGE_KEY);
  const oldSenderIdentityFromLS = findIdentityByReference(identities, oldSenderReferenceFromLS);

  let lifenSendingDefaultIdentity = filterDeviceIdentitiesOut(identities)[0];
  if (
    isPractitioner(lifenSendingDefaultIdentity) &&
    lifenSendingDefaultIdentity.practitionerRoles.length > 0
  ) {
    [lifenSendingDefaultIdentity] = lifenSendingDefaultIdentity.practitionerRoles;
  }

  const lifenIntegrationDefaultIdentity = firstOrganizationIdentity(identities);

  return {
    lifenSending:
      exisitingSenderIdentities.existingSenderIdentityLifenSending ||
      oldSenderIdentityFromLS ||
      lifenSendingDefaultIdentity,
    lifenIntegration: isOrganization(
      exisitingSenderIdentities.existingSenderIdentityLifenIntegration,
    )
      ? exisitingSenderIdentities.existingSenderIdentityLifenIntegration
      : lifenIntegrationDefaultIdentity,
  };
}

export function getExistingSenderIdentitiesFromLS(identities: Professional[]) {
  const senderIdentities = getSenderIdentitiesFromLS();

  return {
    existingSenderIdentityLifenSending: findIdentityByReference(
      identities,
      senderIdentities?.lifenSending,
    ),
    existingSenderIdentityLifenIntegration: findIdentityByReference(
      identities,
      senderIdentities?.lifenIntegration,
    ),
  };
}
