import { ENS_EMAIL_DOMAIN } from '../constants';
import {
  Address,
  Organization,
  Practitioner,
  PractitionerRole,
  Professional,
  ProfessionalBase,
  ProfessionalDto,
  Telecom,
  TelecomType,
} from '../types';

export function findProfessional(
  { id, type }: ProfessionalDto,
  professionals: Professional[],
): Professional | undefined {
  return professionals.find((professional) => professional.id === id && professional.type === type);
}

export function getProfessionalAsReference(
  professional: ProfessionalBase | Practitioner | Organization | ProfessionalDto,
): string {
  const resourceType = professional.type;
  const { id } = professional;

  return resourceType && id && `${resourceType}/${id}`;
}

export function isMssTelecom(telecom: { type?: TelecomType }): boolean {
  return telecom.type === TelecomType.MSSANTE;
}

export function isEnsTelecom(telecom: { value?: string }): boolean {
  return !!telecom.value?.match(new RegExp(`^[0-9]+@${ENS_EMAIL_DOMAIN}`));
}

export function isLifenMSSTelecom(telecom: { value?: string }): boolean {
  if (!telecom.value) {
    return false;
  }
  const mssRegex = /(lifen-test.mssante.fr)|(lifen.mssante.fr)$/g;

  return mssRegex.test(telecom.value);
}

export function getSendableTelecomsForMail(professional: Professional): Telecom[] {
  let validTelecoms: Telecom[] = [...professional.telecoms.lifen, ...professional.telecoms.mssante];
  if ((professional as Practitioner)?.practitionerRoles?.length) {
    validTelecoms = ((professional as Practitioner)?.practitionerRoles ?? []).reduce(
      (telecomList: Telecom[], pratRole: PractitionerRole) => [
        ...telecomList,
        ...pratRole.telecoms.lifen,
        ...pratRole.telecoms.mssante,
      ],
      [],
    );
  }
  return validTelecoms;
}

export function getPreferredTelecomsForMail(professional: Professional): Telecom[] {
  return [...professional.telecoms.lifen, ...professional.telecoms.mssante].filter(
    (telecom) => telecom.isContactPreference,
  );
}

export function getPreferredSecuredTelecoms(professional: Professional): Telecom[] {
  return [
    ...professional.telecoms.lifen,
    ...professional.telecoms.mssante,
    ...professional.telecoms.apicrypt,
  ].filter((telecom) => telecom.isContactPreference);
}

export function getMssSanteIfNotPreferred(professional: Professional) {
  if (professional.telecoms.mssante.some((t) => t.isContactPreference)) return [];
  return professional.telecoms.mssante;
}

interface TelecomsConfig {
  withEmail?: boolean;
}

export function getSendableTelecoms(
  professional: Professional,
  config?: TelecomsConfig,
): Telecom[] {
  return [
    ...professional.telecoms.lifen,
    ...professional.telecoms.mssante,
    ...professional.telecoms.apicrypt,
    ...(config?.withEmail ? professional.telecoms.email : []),
  ];
}

export function getPreferredAndEnforcedOrSendableTelecoms(
  professional: Professional,
  config?: TelecomsConfig,
) {
  const preferredTelecoms = getPreferredSecuredTelecoms(professional);

  return preferredTelecoms.length > 0
    ? [...preferredTelecoms, ...getMssSanteIfNotPreferred(professional)]
    : getSendableTelecoms(professional, config);
}

export function getPreferredOrSendableTelecoms(
  professional: Professional,
  config?: TelecomsConfig,
): Telecom[] {
  const preferredTelecoms = getPreferredSecuredTelecoms(professional);

  return preferredTelecoms.length > 0
    ? preferredTelecoms
    : getSendableTelecoms(professional, config);
}

export function getPostalContactPreference(professional: Professional): Address[] {
  /*
   * If a professional has a postal contact preference, we
   * have now way to know which one, so we take the first one.
   */
  return professional.hasPostalContactPreference ? professional.address.slice(0, 1) : [];
}
