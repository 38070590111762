import { logger } from '@services/logger/logger.service';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { SendingRequestBundle, SendingRequestDto } from '@honestica/core-apps-common/types';

import * as API from '@api';
import {
  SetCustomEnsMessageAction,
  SetEnsConversationClosedAction,
} from '@store/documents/documents.actions';
import { selectDraftDetailDocumentDto } from '@store/documents/outgoing/draft.selector';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';

function* setCustomEnsMessageSaga({ payload: { ensMessage } }: SetCustomEnsMessageAction) {
  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const bundle: SendingRequestBundle = yield API.setCustomEnsMessage(docDto.id, ensMessage);
    yield put(DraftDocumentsActions.setCustomEnsMessageSuccess({ document: bundle.document }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to set custom ens message', error);
    yield put(
      DraftDocumentsActions.setCustomEnsMessageFailure({
        id: docDto.id,
        error,
      }),
    ); // no effect
  }
}

function* setEnsConversationClosedSaga({
  payload: { isEnsConversationClosed },
}: SetEnsConversationClosedAction) {
  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const bundle: SendingRequestBundle = yield API.setEnsConversationStatus(
      docDto.id,
      isEnsConversationClosed,
    );
    yield put(DraftDocumentsActions.setEnsConversationClosedSuccess({ document: bundle.document }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to set ens conversation status', error);
    yield put(
      DraftDocumentsActions.setEnsConversationClosedFailure({
        id: docDto.id,
        error,
      }),
    ); // no effect
  }
}

export function* manageEnsDraftDocumentsSagas() {
  yield all([
    takeLatest(DraftDocumentsActions.setCustomEnsMessage.type, setCustomEnsMessageSaga),
    takeLatest(DraftDocumentsActions.setEnsConversationClosed.type, setEnsConversationClosedSaga),
  ]);
}
