import { ConnectedProps, connect } from 'react-redux';

import { DesktopActions } from '@store/desktop/desktop.action';
import { closeModalFromStore } from '@store/modal/modal.action';
import {
  notificationAddError,
  notificationAddPending,
  notificationAddSuccess,
  resetNotification,
} from '@store/notificationWindow/notificationWindow.action';
import { getNotificationState } from '@store/notificationWindow/notificationWindow.selector';
import { State } from '@store/reducer';

export type NotificationWindowReduxProps = ConnectedProps<typeof notificationWindowConnector>;

const mapStateToProps = (state: State) => getNotificationState(state);

export const notificationWindowConnector = connect(mapStateToProps, {
  resetNotification,
  notificationAddPending,
  notificationAddSuccess,
  notificationAddError,
  closeModalFromStore,
  showOutboxErrorFolder: DesktopActions.showOutboxErrorFolder,
});
