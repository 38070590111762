import { DesktopSettings } from '@launcher/utils';

import { isDefined } from '@honestica/core-apps-common/utils';

export const STABILITY_THRESHOLD_MIN_MS = 400;
export const STABILITY_THRESHOLD_MAX_MS = 10000;

export function validateSettings(settings: Partial<DesktopSettings>): DesktopSettingsError {
  if (
    isDefined(settings.stabilityThreshold) &&
    (settings.stabilityThreshold < STABILITY_THRESHOLD_MIN_MS ||
      settings.stabilityThreshold > STABILITY_THRESHOLD_MAX_MS)
  ) {
    return { stabilityThreshold: 'desktopSettings.errors.stabilityThreshold' };
  }

  return {};
}

export interface DesktopSettingsError {
  stabilityThreshold?: string;
}
