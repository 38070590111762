import { defaultDesktopSettings } from '@launcher/utils';
import { useTranslation } from 'react-i18next';

import {
  CardBase,
  DangerBannerCard,
  PositiveInteger,
  Space,
  Typography,
} from '@honestica/ui-kit/src';

import {
  STABILITY_THRESHOLD_MAX_MS,
  STABILITY_THRESHOLD_MIN_MS,
} from '../WatchPathSetting/DesktopSettings.validator';

interface Props {
  className?: string;
  disabled?: boolean;
  error?: string;
  value: number | '';
  onValueChange: (value: number | '') => void;
}

export function StabilityThresholdSetting(props: Props) {
  const { t } = useTranslation();

  return (
    <CardBase className={props.className}>
      <Space direction="vertical">
        {props.error && (
          <DangerBannerCard
            message={t(props.error, {
              defaultValue: defaultDesktopSettings.stabilityThreshold,
              max: STABILITY_THRESHOLD_MAX_MS,
              min: STABILITY_THRESHOLD_MIN_MS,
            })}
          />
        )}
        <Space>
          <Typography.Text strong>{t('desktopSettings.stabilityThreshold.label')}</Typography.Text>
          <PositiveInteger
            aria-label={t('desktopSettings.stabilityThreshold.label')}
            disabled={props.disabled}
            value={props.value}
            onValueChange={props.onValueChange}
          />
          <Typography.Text strong>{t('desktopSettings.stabilityThreshold.unit')}</Typography.Text>
        </Space>

        <Typography.Text>
          {t('desktopSettings.stabilityThreshold.defaultValue', {
            value: defaultDesktopSettings.stabilityThreshold,
          })}
        </Typography.Text>
      </Space>
    </CardBase>
  );
}
