import { ComponentProps, FC } from 'react';

import CheckCircleTwoTone from '@ant-design/icons/CheckCircleTwoTone';

import colors from '../../theme/colors';

import { Svg, SvgProps } from './IconWrapper';

const twoTonesIcons: Record<string, FC<SvgProps>> = {
  checkCircle: (props) => (
    <CheckCircleTwoTone
      twoToneColor={colors.green[5]}
      {...(props as ComponentProps<typeof CheckCircleTwoTone>)}
    />
  ),
  ContactsAppTitleIcon: (props) => (
    <Svg {...props} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <>
        <rect x="6" width="17" height="21" rx="3" fill="#FF6742" fillOpacity="0.3" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.4375 2.08333C23.4375 0.93274 22.5048 0 21.3542 0H3.64583C2.49524 0 1.5625 0.93274 1.5625 2.08333V23.0885C1.5625 24.1434 2.41702 24.9988 3.47187 25H21.3542C22.5048 25 23.4375 24.0673 23.4375 22.9167V2.08333ZM3.64583 2.60417C3.64583 2.31652 3.87902 2.08333 4.16667 2.08333H20.8333C21.121 2.08333 21.3542 2.31652 21.3542 2.60417V3.88021C21.3541 3.97888 21.2985 4.06912 21.2104 4.11354L18.9188 5.25937C17.8552 5.78492 17.1836 6.86998 17.1875 8.05625V22.6542C17.1875 22.798 17.0709 22.9146 16.9271 22.9146H4.16667C3.87902 22.9146 3.64583 22.6814 3.64583 22.3937V2.60417ZM19.8469 7.12709C19.493 7.30228 19.2696 7.66348 19.2708 8.05834V10.9104C19.2707 11.0008 19.3174 11.0848 19.3942 11.1324C19.4711 11.1799 19.5671 11.1843 19.6479 11.1438L21.2104 10.3625C21.2985 10.3181 21.3541 10.2278 21.3542 10.1292V6.79584C21.3543 6.70545 21.3076 6.62144 21.2308 6.57388C21.1539 6.52632 21.0579 6.52201 20.9771 6.56251L19.8469 7.12709ZM21.3542 16.9031C21.3541 17.0018 21.2985 17.092 21.2104 17.1364L19.6479 17.9177C19.5671 17.9582 19.4711 17.9539 19.3942 17.9063C19.3174 17.8588 19.2707 17.7748 19.2708 17.6844V13.8302C19.2711 13.7316 19.3266 13.6415 19.4146 13.5969L20.9771 12.8156C21.0579 12.7751 21.1539 12.7794 21.2308 12.827C21.3076 12.8745 21.3543 12.9586 21.3542 13.0489V16.9031ZM19.4146 20.3635C19.3266 20.4081 19.2711 20.4983 19.2708 20.5969V22.6563C19.2708 22.8001 19.3874 22.9167 19.5312 22.9167H20.8333C21.121 22.9167 21.3542 22.6835 21.3542 22.3958V19.8156C21.3543 19.7252 21.3076 19.6412 21.2308 19.5937C21.1539 19.5461 21.0579 19.5418 20.9771 19.5823L19.4146 20.3635Z"
          fill="#2F3343"
        />
      </>
    </Svg>
  ),
  MessagingAppTitleIcon: (props) => (
    <Svg {...props} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <>
        <g clipPath="url(#clip0)">
          <rect x="7" y="-4" width="23" height="21" rx="3" fill="#FF6742" fillOpacity="0.3" />
          <path
            d="M22.9167 1.5625H5.72915C5.17661 1.5625 4.64671 1.78199 4.25601 2.17269C3.86531 2.56339 3.64581 3.0933 3.64581 3.64583V17.7083C3.64581 18.2609 3.86531 18.7908 4.25601 19.1815C4.64671 19.5722 5.17661 19.7917 5.72915 19.7917H22.9167C23.4692 19.7917 23.9991 19.5722 24.3898 19.1815C24.7805 18.7908 25 18.2609 25 17.7083V3.64583C25 3.0933 24.7805 2.56339 24.3898 2.17269C23.9991 1.78199 23.4692 1.5625 22.9167 1.5625ZM22.3958 3.64583C22.534 3.64583 22.6664 3.70071 22.7641 3.79838C22.8618 3.89606 22.9167 4.02853 22.9167 4.16667V4.375C22.9166 4.46077 22.8954 4.54521 22.8549 4.6208C22.8143 4.6964 22.7558 4.76082 22.6844 4.80833L14.8489 10.026C14.7653 10.0818 14.6672 10.1122 14.5667 10.1135C14.4661 10.1148 14.3673 10.087 14.2823 10.0333L5.97185 4.78542C5.89748 4.73844 5.83622 4.6734 5.79378 4.59635C5.75134 4.5193 5.7291 4.43276 5.72915 4.34479V4.16667C5.72915 4.02853 5.78402 3.89606 5.8817 3.79838C5.97937 3.70071 6.11185 3.64583 6.24998 3.64583H22.3958ZM6.24998 17.7083C6.11185 17.7083 5.97937 17.6535 5.8817 17.5558C5.78402 17.4581 5.72915 17.3256 5.72915 17.1875V8.04063C5.72984 7.94778 5.75534 7.8568 5.80301 7.77712C5.85068 7.69744 5.91879 7.63195 6.00027 7.58744C6.08176 7.54292 6.17366 7.521 6.26647 7.52394C6.35927 7.52688 6.4496 7.55458 6.52811 7.60417L14.0281 12.3396C14.1943 12.4444 14.3868 12.5 14.5833 12.5C14.789 12.4995 14.99 12.4386 15.1614 12.325L22.1073 7.69375C22.1856 7.64165 22.2765 7.61172 22.3704 7.60714C22.4643 7.60255 22.5577 7.62347 22.6407 7.66769C22.7237 7.71191 22.7932 7.77777 22.8418 7.85829C22.8904 7.9388 22.9162 8.03097 22.9167 8.125V17.1875C22.9167 17.3256 22.8618 17.4581 22.7641 17.5558C22.6664 17.6535 22.534 17.7083 22.3958 17.7083H6.24998Z"
            fill="#2F3343"
          />
          <path
            d="M20.3125 21.3541H2.60417C2.46603 21.3541 2.33356 21.2993 2.23588 21.2016C2.13821 21.1039 2.08333 20.9714 2.08333 20.8333V6.24998C2.08333 5.97371 1.97359 5.70876 1.77824 5.51341C1.58289 5.31806 1.31793 5.20831 1.04167 5.20831C0.7654 5.20831 0.500448 5.31806 0.305097 5.51341C0.109747 5.70876 0 5.97371 0 6.24998L0 21.3541C0 21.9067 0.219493 22.4366 0.610194 22.8273C1.0009 23.218 1.5308 23.4375 2.08333 23.4375H20.3125C20.5888 23.4375 20.8537 23.3277 21.0491 23.1324C21.2444 22.937 21.3542 22.6721 21.3542 22.3958C21.3542 22.1195 21.2444 21.8546 21.0491 21.6592C20.8537 21.4639 20.5888 21.3541 20.3125 21.3541Z"
            fill="#2F3343"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="25" height="25" fill="white" />
          </clipPath>
        </defs>
      </>
    </Svg>
  ),
  PatientsAppTitleIcon: (props) => (
    <Svg {...props} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <>
        <rect x="8" width="17" height="21" rx="3" fill="#FF6742" fillOpacity="0.3" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6251 13.0209C15.6251 11.295 14.2259 9.89587 12.5001 9.89587C10.7742 9.89587 9.37506 11.295 9.37506 13.0209C9.37506 14.7468 10.7742 16.1459 12.5001 16.1459C14.2259 16.1459 15.6251 14.7468 15.6251 13.0209ZM17.2063 20.8334C17.382 20.8343 17.5457 20.744 17.6386 20.5948C17.7309 20.4484 17.7337 20.2627 17.6459 20.1136C16.5679 18.2989 14.6134 17.1866 12.5027 17.1866C10.3919 17.1866 8.43743 18.2989 7.35943 20.1136C7.27098 20.2614 7.27098 20.4459 7.35943 20.5938C7.4521 20.7434 7.61578 20.8341 7.79172 20.8334H17.2063Z"
          fill="#2F3343"
        />
        <path
          d="M12.5 16.1459C14.2259 16.1459 15.625 14.7468 15.625 13.0209C15.625 11.295 14.2259 9.89587 12.5 9.89587C10.7741 9.89587 9.375 11.295 9.375 13.0209C9.375 14.7468 10.7741 16.1459 12.5 16.1459Z"
          fill="#2F3343"
        />
        <path
          d="M12.5 17.1875C10.3885 17.1862 8.43273 18.2983 7.35418 20.1135C7.26573 20.2614 7.26573 20.4459 7.35418 20.5937C7.44782 20.7449 7.61389 20.8358 7.79168 20.8333H17.2063C17.382 20.8342 17.5456 20.7439 17.6385 20.5948C17.7282 20.4473 17.7282 20.2621 17.6385 20.1146C16.5636 18.2991 14.6099 17.1862 12.5 17.1875Z"
          fill="#2F3343"
        />
        <path
          d="M20.3125 3.125H17.7084C17.4207 3.125 17.1875 3.35819 17.1875 3.64583V4.6875C17.1875 4.97515 17.4207 5.20833 17.7084 5.20833H19.7917C20.0793 5.20833 20.3125 5.44152 20.3125 5.72917V22.3958C20.3125 22.6835 20.0793 22.9167 19.7917 22.9167H5.20835C4.92071 22.9167 4.68752 22.6835 4.68752 22.3958V5.72917C4.68752 5.44152 4.92071 5.20833 5.20835 5.20833H7.29169C7.57934 5.20833 7.81252 4.97515 7.81252 4.6875V3.64583C7.81252 3.35819 7.57934 3.125 7.29169 3.125H4.68752C3.53693 3.125 2.60419 4.05774 2.60419 5.20833V22.9167C2.60419 24.0673 3.53693 25 4.68752 25H20.3125C21.4631 25 22.3959 24.0673 22.3959 22.9167V5.20833C22.3959 4.05774 21.4631 3.125 20.3125 3.125Z"
          fill="#2F3343"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9375 7.8125C10.0746 7.8125 9.375 7.11294 9.375 6.25V3.125C9.375 1.39911 10.7741 0 12.5 0C14.2259 0 15.625 1.39911 15.625 3.125V6.25C15.625 7.11294 14.9254 7.8125 14.0625 7.8125H10.9375ZM12.5 1.5625C11.9247 1.5625 11.4583 2.02887 11.4583 2.60417C11.4583 3.17946 11.9247 3.64583 12.5 3.64583C13.0753 3.64583 13.5417 3.17946 13.5417 2.60417C13.5417 2.02887 13.0753 1.5625 12.5 1.5625Z"
          fill="#2F3343"
        />
      </>
    </Svg>
  ),
  AppStoreAppTitleIcon: (props) => (
    <Svg {...props} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <>
        <circle cx="15.5" cy="9.5" r="9.5" fill="#FF6742" fillOpacity="0.3" />
        <path
          d="M12.5 24.7396C14.9208 24.7396 17.2872 24.0218 19.3 22.6769C21.3128 21.332 22.8815 19.4204 23.8079 17.1839C24.7343 14.9474 24.9767 12.4864 24.5044 10.1122C24.0322 7.73795 22.8665 5.55707 21.1547 3.84533C19.443 2.1336 17.2621 0.96789 14.8878 0.495623C12.5136 0.0233556 10.0526 0.26574 7.81613 1.19213C5.57964 2.11851 3.66808 3.68729 2.32318 5.70008C0.978276 7.71287 0.260437 10.0793 0.260437 12.5C0.264296 15.745 1.55506 18.8559 3.84959 21.1505C6.14412 23.445 9.25507 24.7358 12.5 24.7396ZM12.5 2.86461C14.4057 2.86461 16.2686 3.42972 17.8532 4.48847C19.4377 5.54722 20.6727 7.05207 21.402 8.81271C22.1313 10.5734 22.3221 12.5107 21.9503 14.3798C21.5785 16.2489 20.6608 17.9658 19.3133 19.3133C17.9658 20.6608 16.2489 21.5785 14.3798 21.9503C12.5107 22.3221 10.5733 22.1313 8.81271 21.402C7.05206 20.6727 5.54722 19.4377 4.48846 17.8532C3.42971 16.2686 2.8646 14.4057 2.8646 12.5C2.86736 9.9454 3.8834 7.4962 5.6898 5.6898C7.49619 3.88341 9.9454 2.86737 12.5 2.86461Z"
          fill="#2F3343"
        />
        <path
          d="M6.53438 13.8021H10.9375C11.0066 13.8021 11.0728 13.8295 11.1217 13.8784C11.1705 13.9272 11.1979 13.9934 11.1979 14.0625V18.4656C11.1979 18.811 11.3351 19.1422 11.5793 19.3864C11.8235 19.6305 12.1547 19.7677 12.5 19.7677C12.8453 19.7677 13.1765 19.6305 13.4207 19.3864C13.6649 19.1422 13.8021 18.811 13.8021 18.4656V14.0625C13.8021 13.9934 13.8295 13.9272 13.8784 13.8784C13.9272 13.8295 13.9934 13.8021 14.0625 13.8021H18.4656C18.811 13.8021 19.1422 13.6649 19.3863 13.4207C19.6305 13.1765 19.7677 12.8453 19.7677 12.5C19.7677 12.1547 19.6305 11.8235 19.3863 11.5793C19.1422 11.3351 18.811 11.1979 18.4656 11.1979H14.0625C13.9934 11.1979 13.9272 11.1705 13.8784 11.1217C13.8295 11.0728 13.8021 11.0066 13.8021 10.9375V6.53335C13.8021 6.18801 13.6649 5.85682 13.4207 5.61263C13.1765 5.36845 12.8453 5.23126 12.5 5.23126C12.1547 5.23126 11.8235 5.36845 11.5793 5.61263C11.3351 5.85682 11.1979 6.18801 11.1979 6.53335V10.9375C11.1979 11.0066 11.1705 11.0728 11.1217 11.1217C11.0728 11.1705 11.0066 11.1979 10.9375 11.1979H6.53438C6.18905 11.1979 5.85786 11.3351 5.61367 11.5793C5.36948 11.8235 5.2323 12.1547 5.2323 12.5C5.2323 12.8453 5.36948 13.1765 5.61367 13.4207C5.85786 13.6649 6.18905 13.8021 6.53438 13.8021Z"
          fill="#2F3343"
        />
      </>
    </Svg>
  ),
};

export type TwoTonesIconName = keyof typeof twoTonesIcons;

export default twoTonesIcons;
