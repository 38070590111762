import {
  DesktopSettings,
  RegistryPair,
  RegistryPairAlike,
  defaultDesktopSettings,
} from '@launcher/utils';

/**
 * Check if it is a valid registry pair by checking if it is defined in model and it matches types
 * @param pair any value that could be assimiliated to a registry pair
 * @returns true if it is a valid pair, false otherwise
 */
export function isValidRegistryPair(pair: RegistryPairAlike): pair is RegistryPair {
  const [key, value] = pair;

  if (!(key in defaultDesktopSettings)) return false;

  return typeof defaultDesktopSettings[key as keyof DesktopSettings] === typeof value;
}
