import { DesktopSettings } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';

import { hasOwnProperty } from '@honestica/core-apps-common/validators';

import { configurePrinterForPdfCreator } from '../pdfCreator/configurePrinterForPdfCreator.helper';

import { resetFileSettings } from './resetFileSettingsFromFS.helper';

export async function getSettingsFromFS(): Promise<Partial<DesktopSettings>> {
  try {
    // Await necessary to catch read errors
    return await window.electron.readLocalSettings();
  } catch (error) {
    if (hasOwnProperty(error, 'code') && error.code === 'ENOENT') {
      logger.warn('DESKTOP', "Settings file doesn't exist. Creating it.");
      // If there's no file we assume it's the first launch and override registry config
      configurePrinterForPdfCreator();
    } else {
      logger.error('DESKTOP', 'Invalid settings file. Overriding it.', error);
    }

    return (await resetFileSettings()) || {};
  }
}
