import { ChangeEvent, ForwardedRef, forwardRef } from 'react';

import { Input, InputRef } from 'antd';
import Form, { FormItemProps } from 'antd/lib/form';
import { InputProps } from 'antd/lib/input';
import { useDebounce } from 'use-debounce';

import { isApiCryptOrMssEmail, validateEmail } from '@honestica/core-apps-common/utils';

export interface EmailInputProps {
  disabled?: boolean;
  email: string;
  errorMessage?: string;
  placeholder?: string;
  setEmail?: (value: string) => void;
  validator?: (email: string) => boolean;
  formItemProps?: FormItemProps;
  isDematRecipient?: boolean;
  dematErrorMesage?: string;
}

export const EmailInput = forwardRef(
  (
    {
      disabled = false,
      email,
      errorMessage,
      placeholder,
      setEmail,
      validator = validateEmail,
      formItemProps,
      isDematRecipient,
      dematErrorMesage,
      ...otherProps
    }: EmailInputProps & InputProps,
    ref: ForwardedRef<InputRef>,
  ): JSX.Element => {
    const [debouncedEmail] = useDebounce<string>(email, 1000);

    let validateStatus: 'success' | 'error' | undefined;
    let help;
    if (debouncedEmail.length > 0 && !disabled) {
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (validator && validator(debouncedEmail)) {
        if (isDematRecipient && dematErrorMesage && isApiCryptOrMssEmail(email)) {
          validateStatus = 'error';
          help = dematErrorMesage;
        } else {
          validateStatus = 'success';
        }
      } else {
        validateStatus = 'error';
        help = errorMessage;
      }
    }

    const onChange = setEmail
      ? (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
      : undefined;

    return (
      <Form.Item
        hasFeedback
        validateStatus={formItemProps?.validateStatus ?? validateStatus}
        help={formItemProps?.help ?? help}
        htmlFor="email"
        labelCol={{ span: 24 }}
        {...formItemProps}
      >
        <Input
          onChange={onChange}
          placeholder={placeholder}
          value={email}
          disabled={disabled}
          id="email"
          name="email"
          ref={ref}
          {...otherProps}
        />
      </Form.Item>
    );
  },
);
