import { createSlice } from '@reduxjs/toolkit';

import { generateDownloadActions } from '../common/reducers/downloadDocuments.reducers';
import { updateDocumentAttachments } from '../common/reducers/fetchAttachments.reducers';
import {
  fetchDocumentsSuccess,
  fetchMany,
  fetchManyFailure,
  fetchManySuccess,
  fetchOne,
  fetchOneFailure,
  fetchOneSuccess,
  setDetailViewDocument,
  setDetailViewDocumentDto,
} from '../common/reducers/fetchDocuments.reducers';
import {
  flushLoadingDocumentIds,
  updateLoadingDocumentIds,
} from '../common/reducers/updateLoadingDocumentIds.reducers';
import {
  updateAndDropManySuccess,
  updateDocumentAndMeta,
  updateFailure,
  updateMany,
  updateOne,
  updateOneFailure,
  updateOneSuccess,
} from '../common/reducers/updateMeta.reducers';
import { updateSearchParams } from '../common/reducers/updateSearchParams.reducers';
import {
  BatchAction,
  BatchFailureAction,
  BatchSuccessAction,
  DetailViewDocumentDtoAction,
  FetchDocumentSuccessAction,
  GetDocumentAction,
  GetDocumentSuccessAction,
  OneAction,
  OneFailureAction,
  OneSuccessAction,
  SelectDocumentIDAction,
  UpdateDocumentSuccessAction,
} from '../documents.actions';

import {
  INITIAL_INBOX_DOCUMENTS_STATE,
  INITIAL_INBOX_DOCUMENT_METADATA,
  InboxDocumentMetadataKey,
  InboxDocumentsState,
} from './inbox.state';

const slice = createSlice({
  name: 'inboxDocuments',
  initialState: INITIAL_INBOX_DOCUMENTS_STATE,
  reducers: {
    selectOne: (state, _: SelectDocumentIDAction) => ({ ...state, selectedId: _.payload }),

    updateSearchParams,
    updateLoadingDocumentIds: (state, action) => updateLoadingDocumentIds(state, action),
    flushLoadingDocumentIds,

    fetchMany,
    fetchManySuccess: (state, action) =>
      fetchManySuccess(state, action, INITIAL_INBOX_DOCUMENT_METADATA),
    fetchManyFailure,
    fetchDocumentsSuccess: (state, action) =>
      fetchDocumentsSuccess(state, action, INITIAL_INBOX_DOCUMENT_METADATA),

    archiveMany: (state, action: BatchAction) =>
      updateMany(state, action, InboxDocumentMetadataKey.archiveRequest),
    archiveManySuccess: (state, action: BatchSuccessAction): InboxDocumentsState =>
      updateAndDropManySuccess(state, action, InboxDocumentMetadataKey.archiveRequest),
    archiveManyFailure: (state, action: BatchFailureAction) =>
      updateFailure(state, action, InboxDocumentMetadataKey.archiveRequest),

    /*
     * DETAIL
     */

    /*
     * View
     */

    getOne: (state, _action: GetDocumentAction) => state,
    setDetailViewDocument: (state, action: GetDocumentSuccessAction) =>
      setDetailViewDocument(state, action, INITIAL_INBOX_DOCUMENTS_STATE.detailView),
    setDetailViewDocumentDto: (state, action: DetailViewDocumentDtoAction) =>
      setDetailViewDocumentDto(
        state,
        action,
        INITIAL_INBOX_DOCUMENTS_STATE.detailView,
        INITIAL_INBOX_DOCUMENT_METADATA,
      ),

    fetchOne,
    fetchOneSuccess: (state, action: FetchDocumentSuccessAction) =>
      fetchOneSuccess(state, action, INITIAL_INBOX_DOCUMENT_METADATA), // updates entities
    fetchOneFailure,

    fetchManyAttachments: (state, action: OneAction) =>
      updateOne(state, action, InboxDocumentMetadataKey.fetchAttachmentsRequest),
    fetchManyAttachmentsSuccess: updateDocumentAttachments,
    fetchManyAttachmentsFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, InboxDocumentMetadataKey.fetchAttachmentsRequest),

    /**
     * Entities
     */

    archiveOne: (state, action: OneAction) =>
      updateOne(state, action, InboxDocumentMetadataKey.archiveRequest),
    archiveOneSuccess: (state, action: OneSuccessAction) =>
      updateOneSuccess(state, action, InboxDocumentMetadataKey.archiveRequest),
    archiveOneFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, InboxDocumentMetadataKey.archiveRequest),

    forwardOne: (state, action: OneAction) =>
      updateOne(state, action, InboxDocumentMetadataKey.forwardRequest),
    forwardOneSuccess: (state, action: OneSuccessAction) =>
      updateOneSuccess(state, action, InboxDocumentMetadataKey.forwardRequest),
    forwardOneFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, InboxDocumentMetadataKey.forwardRequest),

    /*
     * ENS entities
     */

    closeEnsConversation: (state, action: OneAction) =>
      updateOne(state, action, InboxDocumentMetadataKey.closeEnsConversation),
    closeEnsConversationSuccess: (state, action: UpdateDocumentSuccessAction) =>
      updateDocumentAndMeta(
        state,
        action.payload.document,
        InboxDocumentMetadataKey.closeEnsConversation,
      ),
    closeEnsConversationFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, InboxDocumentMetadataKey.closeEnsConversation),
  },
});

export const documentInboxReducer = slice.reducer;

export const InboxDocumentsActions = { ...slice.actions, ...generateDownloadActions(slice.name) };
