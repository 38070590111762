import { useTranslation } from 'react-i18next';

import { CardBase, Checkbox, Space, Typography } from '@honestica/ui-kit/src';

interface Props {
  className?: string;
  disabled?: boolean;
  value: boolean;
  onValueChange: (value: boolean) => void;
}

export function UsePollingSetting(props: Props) {
  const { t } = useTranslation();

  return (
    <CardBase className={props.className}>
      <Space direction="vertical">
        <Checkbox
          disabled={props.disabled}
          checked={props.value}
          onClick={() => props.onValueChange(!props.value)}
        >
          <Typography.Text strong>{t('desktopSettings.usePolling.checkboxLabel')}</Typography.Text>
        </Checkbox>

        <Typography.Text>{t('desktopSettings.usePolling.description')}</Typography.Text>
      </Space>
    </CardBase>
  );
}
