import { Component, ReactNode } from 'react';

import { Menu, MenuProps } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import cx from 'classnames';

import colors from '../../../theme/colors';
import Icon from '../../Icon';
import Space from '../../Space';
import iconStyles from '../Icon/Sidebar.Icon.module.less';

import styles from './Sidebar.Menu.module.less';

interface SidebarMenuItemProps {
  children: ReactNode;
  onClick?: () => void;
  selected?: boolean;
  testid?: string;
}

const SidebarMenuItem = ({ children, onClick, selected, testid }: SidebarMenuItemProps) => (
  <div
    data-testid={testid}
    className={styles.dropdownMenuItemWrapper}
    onClick={onClick}
    role="button"
    tabIndex={0}
  >
    <Space fullWidth>
      {children}
      {selected && <Icon name="TickMinor" color={colors.primary[5]} size={20} />}
    </Space>
  </div>
);

interface SidebarMenuProps extends MenuProps {
  iconName: string;
  children: ReactNode;
  header?: ReactNode;
  expandIcon?: ReactNode;
}

export class SidebarMenu extends Component<SidebarMenuProps> {
  static Item = SidebarMenuItem;

  render() {
    const { children, header, iconName, ...rest } = this.props;
    return (
      <Menu
        mode="vertical"
        expandIcon={
          <Icon
            flex
            className={cx(iconStyles.icon, styles.dropdownMenuIcon)}
            name={this.props.iconName}
          />
        }
        className={styles.dropdownMenuTitle}
        {...rest}
      >
        <SubMenu popupClassName={cx('settings-submenu-popup', styles.dropdownMenuWrapper)}>
          <div>{this.props.header ?? <></>}</div>
          <div className={styles.dropdownMenuContent}>{this.props.children}</div>
        </SubMenu>
      </Menu>
    );
  }
}
