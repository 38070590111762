import { EnvManager } from '@services/environment/environment.service';
import { logger } from '@services/logger/logger.service';
import Pusher, { Options } from 'pusher-js';

if (process.env.NODE_ENV === 'development') {
  Pusher.log = (msg) => {
    logger.debug('LOGIC', `Pusher - ${msg}`);
  };
}
class RealtimeClient {
  private pusher: Pusher | null;

  public getClient(): Pusher {
    if (this.pusher) {
      return this.pusher;
    }
    // Init client
    // Get pusher key
    const key = EnvManager.getPusherAppKey();

    logger.info('LOGIC', `Pusher - getClient - key: ${key}`);

    // Get pusher options
    const options: Options = {
      enableStats: false,
      cluster: 'eu',
      forceTLS: true,
      wsHost: EnvManager.getBaseUrlProxy(),
      wsPath: EnvManager.getPusherWsPath(),
    };
    logger.info('LOGIC', `Pusher - getClient - Options ${JSON.stringify(options)}`);
    this.pusher = new Pusher(key ?? '', options);
    return this.pusher;
  }

  public getPusherURL(): string {
    return this.getClient().config.wsHost;
  }
}

export const realtimeClient = new RealtimeClient();
