import { logger } from '@services/logger/logger.service';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import {
  addContactPreference as addContactPreferenceCall,
  removeContactPreference as removeContactPreferenceCall,
} from '@api';

import {
  AddContactPreference,
  RemoveContactPreference,
  addContactPreference,
  addContactPreferenceFailure,
  addContactPreferenceSuccess,
  removeContactPreference,
  removeContactPreferenceFailure,
  removeContactPreferenceSuccess,
} from './contactPreferences.action';

function* addContactPreferenceAsync({ payload }: ReturnType<AddContactPreference>) {
  const { resourceId, data, resourceType } = payload;
  if (!resourceId) {
    throw new Error('Id params is mandatory to add contact preference');
  }

  if (!data) {
    throw new Error('Missing data to add contact preference');
  }

  try {
    const category = data === 'postal' ? 'address' : 'telecom';
    const value = data === 'postal' ? '' : data.value;

    yield call(addContactPreferenceCall, { resourceId, resourceType, category, value });
    yield put(addContactPreferenceSuccess(payload));
  } catch (error) {
    logger.error('HTTP', 'Failed to add contact preference', error);
    yield put(addContactPreferenceFailure(payload));
  }
}

function* removeContactPreferenceAsync({ payload }: ReturnType<RemoveContactPreference>) {
  const { resourceId, data, resourceType } = payload;
  if (!resourceId) {
    throw new Error('Id params is mandatory to remove contact preference');
  }
  if (!data) {
    throw new Error('Missing data to remove contact preference');
  }

  try {
    const category = data === 'postal' ? 'address' : 'telecom';
    const value = data === 'postal' ? '' : data.value;
    yield call(removeContactPreferenceCall, { resourceId, resourceType, category, value });
    yield put(removeContactPreferenceSuccess(payload));
  } catch (error) {
    logger.error('HTTP', 'Failed to remove contact preference', error);
    yield put(removeContactPreferenceFailure(payload));
  }
}

export function* contactPreferencesSagas() {
  yield all([
    takeEvery(addContactPreference.type, addContactPreferenceAsync),
    takeEvery(removeContactPreference.type, removeContactPreferenceAsync),
  ]);
}
