import { PayloadAction } from '@reduxjs/toolkit';
import { tracking } from '@services/analytics';
import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  ReplaceDmpFailureAction,
  ReplaceDmpSuccessAction,
} from '@store/documents/documents.actions';
import { SentDocumentsActions } from '@store/documents/outgoing/sent.slice';
import { IntegrationsActions } from '@store/integrations/integrations.slice';
import { UploadJobFailurePayload, UploadJobSuccessPayload } from '@store/upload/upload.reducer';
import { UploadDocumentsActions } from '@store/uploadDocuments/uploadDocuments.slice';

function trackJobUploadSucceed({
  payload: { job, documentId },
}: PayloadAction<UploadJobSuccessPayload>) {
  const { uploadSource } = job;
  tracking.trackFileUploaded({ documentId, uploadSource });
}

function trackJobUploadFailed({ payload: { errors } }: PayloadAction<UploadJobFailurePayload>) {
  tracking.trackFileUploadFailed({ errorCause: errors[0] });
}

function trackDocumentFileUploaded({ payload: { uploadSource, id } }: ReplaceDmpSuccessAction) {
  tracking.trackFileUploaded({ documentId: id, uploadSource });
}

function trackDocumentFileUploadFailed({ payload }: ReplaceDmpFailureAction) {
  tracking.trackFileUploadFailed({ errorCause: payload.errors[0] });
}

export function* trackingUploadJobActionsSaga() {
  yield all([takeEvery(UploadDocumentsActions.jobUploadFailure.type, trackJobUploadFailed)]);
  yield all([takeEvery(UploadDocumentsActions.jobUploadSuccess.type, trackJobUploadSucceed)]);
  yield all([takeEvery(IntegrationsActions.jobUploadFailure.type, trackJobUploadFailed)]);
  yield all([takeEvery(IntegrationsActions.jobUploadSuccess.type, trackJobUploadSucceed)]);
  yield all([takeLatest(SentDocumentsActions.replaceDmpSuccess.type, trackDocumentFileUploaded)]);
  yield all([
    takeLatest(SentDocumentsActions.replaceDmpFailure.type, trackDocumentFileUploadFailed),
  ]);
}
