import { replaceAttachmentsInMemory } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';
import { all, put, takeLatest } from 'redux-saga/effects';

import {
  Attachment,
  IntegrationsDashboardType,
  SendingsDashboardType,
  isIntegrationsDashboardType,
} from '@honestica/core-apps-common/types';

import * as API from '@api';
import { ARCHIVE, DRAFT, INBOX, INTEGRATED, SENT, WORKLIST } from '@constants/documents.constants';
import { FetchDocumentAction } from '@store/documents/documents.actions';
import { ArchivedDocumentsActions } from '@store/documents/incoming/archived.slice';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';
import { IntegratedIntegrationsActions } from '@store/documents/integrations/integrated.slice';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { SentDocumentsActions } from '@store/documents/outgoing/sent.slice';

const Actions = {
  [INBOX]: InboxDocumentsActions,
  [ARCHIVE]: ArchivedDocumentsActions,
  [DRAFT]: DraftDocumentsActions,
  [SENT]: SentDocumentsActions,
  [WORKLIST]: WorklistIntegrationsActions,
  [INTEGRATED]: IntegratedIntegrationsActions,
};

function fetchManyDocumentAttachmentsSaga(
  dashboardType: SendingsDashboardType | IntegrationsDashboardType,
) {
  return function* saga({ payload: { id } }: FetchDocumentAction) {
    try {
      const attachments: Attachment[] = isIntegrationsDashboardType(dashboardType)
        ? yield API.fetchIntegrationRequestAttachments(id)
        : yield API.fetchDocumentAttachments(id, dashboardType);
      const fileStorageIds = replaceAttachmentsInMemory(attachments, dashboardType);
      yield put(Actions[dashboardType].fetchManyAttachmentsSuccess({ id, fileStorageIds }));
    } catch (error) {
      logger.error('LOGIC', 'Failed to get document attachments', error);
      yield put(
        Actions[dashboardType].fetchManyAttachmentsFailure({
          id,
          error,
        }),
      );
    }
  };
}

export function* fetchDocumentAttachmentsSagas() {
  yield all([
    takeLatest(
      InboxDocumentsActions.fetchManyAttachments.type,
      fetchManyDocumentAttachmentsSaga(SendingsDashboardType.Inbox),
    ),
    takeLatest(
      ArchivedDocumentsActions.fetchManyAttachments.type,
      fetchManyDocumentAttachmentsSaga(SendingsDashboardType.Archive),
    ),
    takeLatest(
      DraftDocumentsActions.fetchManyAttachments.type,
      fetchManyDocumentAttachmentsSaga(SendingsDashboardType.Draft),
    ),
    takeLatest(
      SentDocumentsActions.fetchManyAttachments.type,
      fetchManyDocumentAttachmentsSaga(SendingsDashboardType.Sent),
    ),
    takeLatest(
      WorklistIntegrationsActions.fetchManyAttachments.type,
      fetchManyDocumentAttachmentsSaga(IntegrationsDashboardType.Worklist),
    ),
    takeLatest(
      IntegratedIntegrationsActions.fetchManyAttachments.type,
      fetchManyDocumentAttachmentsSaga(IntegrationsDashboardType.Integrated),
    ),
  ]);
}
