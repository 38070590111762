import { http } from '@services/http/http.service';

import {
  Organization,
  Professional,
  ResourceList,
  SupportTicket,
} from '@honestica/core-apps-common/types';

const path = '/user';

export async function getUserIdentities(): Promise<ResourceList<Professional>> {
  return await http.get({ path: `${path}/identities` });
}

export async function getUserIntegrations(): Promise<ResourceList<Organization>> {
  return await http.get({ path: `${path}/integrations` });
}

export async function createSupportTicket(ticket: SupportTicket): Promise<void> {
  return await http.post({ path: `${path}/support`, body: { ticket } });
}
