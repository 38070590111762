import i18n from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { i18nSharedConfig } from './sharedConfig';

i18n
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ...i18nSharedConfig,
    backend: {
      loadPath: `/apps/locales/{{lng}}/{{lng}}.json?version=${process.env.VERSION}`,
    },
  });

export { i18n };
