import {
  DesktopSettings,
  NonParsedRegistryItem,
  RegistryPairAlike,
  isDesktopSettingOfType,
  isValidRegistryPair,
} from '@launcher/utils';

import { camelize, isNill } from '@honestica/core-apps-common/utils';

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function parseRegistrySetting(item: NonParsedRegistryItem) {
  // Settings are case insensitive -> camelize
  const key: keyof DesktopSettings = camelize(item.key.split('\\').slice(-1)[0]) as any;
  const value = (await item.get('value'))?.value;

  if (isNill(value)) return undefined;

  if (isDesktopSettingOfType(key, 'number') && /^\d*$/.exec(value)) {
    const pair: RegistryPairAlike = [key, parseInt(value, 10)];
    if (isValidRegistryPair(pair)) return pair;
  }

  if (isDesktopSettingOfType(key, 'boolean')) {
    if (value.toLowerCase() === 'true') {
      const pair: RegistryPairAlike = [key, true];
      if (isValidRegistryPair(pair)) return pair;
    }

    if (value.toLowerCase() === 'false') {
      const pair: RegistryPairAlike = [key, false];
      if (isValidRegistryPair(pair)) return pair;
    }

    if (value === '1') {
      const pair: RegistryPairAlike = [key, true];
      if (isValidRegistryPair(pair)) return pair;
    }

    if (value === '0') {
      const pair: RegistryPairAlike = [key, false];
      if (isValidRegistryPair(pair)) return pair;
    }
  }

  if (isDesktopSettingOfType(key, 'string')) {
    const pair: RegistryPairAlike = [key, value];
    if (isValidRegistryPair(pair)) return pair;
  }

  return undefined;
}
