import { all, put, takeEvery } from 'redux-saga/effects';

import { List, Organization } from '@honestica/core-apps-common/types';

import { getOrganizations } from '@api';

import {
  FindOrganizations,
  findOrganizations,
  findOrganizationsFailure,
  findOrganizationsSuccess,
} from './organizationDirectory.action';

export function* findOrganizationsAsync({ payload: searchParams }: ReturnType<FindOrganizations>) {
  try {
    const organizations: List<Organization> = yield getOrganizations(searchParams);

    yield put(findOrganizationsSuccess(organizations));
  } catch (error) {
    yield put(findOrganizationsFailure(error));
  }
}

export function* organizationDirectorySagas() {
  yield all([takeEvery(findOrganizations.type, findOrganizationsAsync)]);
}
