import { PayloadAction } from '@reduxjs/toolkit';
import { logger } from '@services/logger/logger.service';
import { WatchOptions } from 'chokidar';
import { EventChannel, eventChannel } from 'redux-saga';

import { FlowType } from '@honestica/core-apps-common/types';

import { watcherTracker } from './watcherTracker';

const commonOptions: WatchOptions = {
  ignored: '**/.*',
  depth: 0,
  ignoreInitial: true,
};

function createFileWatchingChannel(config: Partial<WatchOptions> = {}): EventChannel<string> {
  const initFileWatcherMethod = window.electron.initFileWatcher;
  return eventChannel((emit) => {
    const watcher = initFileWatcherMethod({ ...commonOptions, ...config });

    logger.info('DESKTOP', `Starting sending watcher. settings: ${JSON.stringify(config)}`);

    watcher
      .on('add', (filePath: string) => {
        logger.info('DESKTOP', `WATCHER : new file detected in sending watcher`);

        watcherTracker.onWatchFile(filePath);

        emit(filePath);
      })
      .on('error', (error: Error) => logger.error('DESKTOP', 'Watcher error', error, false));
    return () => {
      try {
        logger.info('DESKTOP', `Closing sending watcher`);
        watcher.close();
      } catch (error) {
        logger.error('DESKTOP', `Watcher error : while closing sending`, error, false);
      }
    };
  });
}

type ActionCallback = (...args: any) => PayloadAction<any>;

export interface WatcherChannel {
  callback: ActionCallback;
  channel: EventChannel<string>;
  name: FlowType;
}

export type WatcherChannelConfigs = Omit<WatcherChannel, 'channel'>[];

export function initFileWatchers(
  channelsToCreate: WatcherChannelConfigs,
  config: Partial<WatchOptions> = {},
): WatcherChannel[] {
  return channelsToCreate.map((channelConfig) => ({
    ...channelConfig,
    channel: createFileWatchingChannel(config),
  }));
}

export function closeWatchers(watchers: WatcherChannel[]): void {
  watchers.forEach((watcher) => watcher.channel.close());
}
