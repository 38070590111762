import { useEffect, useRef } from 'react';

import { Table, type TableProps } from 'antd';

import { useReachBottom } from '../../hooks/useReachBottom.hook';

type InfiniteScrollTableProps<T> = TableProps<T> & {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  loadMoreLabel?: string;
  noMoreDataLabel?: string;
};

export const InfiniteScrollTable = <T extends object>({
  fetchNextPage,
  hasNextPage,
  loadMoreLabel,
  noMoreDataLabel,
  loading,
  ...props
}: InfiniteScrollTableProps<T>) => {
  const tableRef: Parameters<typeof Table>[0]['ref'] = useRef(null);
  const isBottomReached = useReachBottom({
    elt: tableRef.current?.nativeElement.querySelector.apply(tableRef.current?.nativeElement, [
      '.ant-table-body',
    ]),
  });

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!hasNextPage) {
      return;
    }
    if (isBottomReached) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we want to evaluate the condition only when isBottomReached changes
  }, [isBottomReached]);

  return (
    <Table
      pagination={false}
      ref={tableRef}
      {...props}
      summary={() => (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={5}>
            <p>{hasNextPage ? loadMoreLabel : noMoreDataLabel}</p>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      )}
    />
  );
};
