import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { NavigateFunction } from 'react-router-dom';
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import {
  DashboardSearchParams,
  FeatureFlags,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import { restoreDocument, restoreDocuments } from '@api';
import { ARCHIVE, ARCHIVE_PATH } from '@constants/documents.constants';
import { BatchAction, OneAction } from '@store/documents/documents.actions';
import {
  selectDashboardSearchParams,
  selectManyDocumentsFileStorageIds,
} from '@store/documents/documents.selector';
import { ArchivedDocumentsActions } from '@store/documents/incoming/archived.slice';
import { removeFromSelection, removeManyFromSelection } from '@store/selection/selection.action';
import { getFeatureFlags } from '@store/user/user.selector';
import { invalidateDashboardCache } from '@utils/caching.util';

function* restoreOneDocumentSaga({ payload: { id } }: OneAction) {
  const featureFlags: FeatureFlags = yield select(getFeatureFlags);
  const navigate: NavigateFunction = yield getContext('customNavigate');
  const queryClient: QueryClient = yield getContext('queryClient');
  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, [id], ARCHIVE);
  try {
    const searchParams: DashboardSearchParams = yield select((state) =>
      selectDashboardSearchParams(state, SendingsDashboardType.Archive),
    );

    yield restoreDocument(id);
    yield put(ArchivedDocumentsActions.restoreOneSuccess({ id, fileStorageIds }));

    invalidateDashboardCache({
      dashboardType: SendingsDashboardType.Archive,
      search: { searchParams, featureFlags },
      refetchType: 'active',
      queryClient,
    });
    invalidateDashboardCache({
      dashboardType: SendingsDashboardType.Inbox,
      refetchType: 'inactive',
      queryClient,
    });

    yield put(removeFromSelection({ dashboard: SendingsDashboardType.Archive, id }));
    navigate(ARCHIVE_PATH);
  } catch (error) {
    logger.error('LOGIC', 'Failed to restore document', error);
    yield put(
      ArchivedDocumentsActions.restoreOneFailure({
        id,
        error,
      }),
    );
  }
}

function* restoreManyDocumentsMany({ payload: { ids } }: BatchAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const featureFlags: FeatureFlags = yield select(getFeatureFlags);
  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, ids, ARCHIVE);
  try {
    yield put(ArchivedDocumentsActions.updateLoadingDocumentIds({ ids }));
    yield call(restoreDocuments, ids);
    yield put(removeManyFromSelection({ dashboard: SendingsDashboardType.Archive, ids }));
    yield put(ArchivedDocumentsActions.restoreManySuccess({ ids, fileStorageIds }));
    yield put(ArchivedDocumentsActions.flushLoadingDocumentIds());
  } catch (error) {
    logger.error('LOGIC', 'Failed to restore batch of Document', error);
    yield put(
      ArchivedDocumentsActions.restoreManyFailure({
        ids,
        error,
      }),
    );
  } finally {
    const searchParams: DashboardSearchParams = yield select((state) =>
      selectDashboardSearchParams(state, SendingsDashboardType.Archive),
    );
    invalidateDashboardCache({
      dashboardType: SendingsDashboardType.Archive,
      search: { searchParams, featureFlags },
      refetchType: 'active',
      queryClient,
    });
    invalidateDashboardCache({
      dashboardType: SendingsDashboardType.Inbox,
      refetchType: 'inactive',
      queryClient,
    });
  }
}

export function* restoreArchivedDocumentsSagas() {
  yield all([
    takeLatest(ArchivedDocumentsActions.restoreOne.type, restoreOneDocumentSaga),
    takeLatest(ArchivedDocumentsActions.restoreMany.type, restoreManyDocumentsMany),
  ]);
}
