import { SendingRequestDto } from '@honestica/core-apps-common/types';

import {
  updateDocumentAndMeta,
  updateOne,
  updateOneFailure,
} from '../../common/reducers/updateMeta.reducers';
import {
  OneAction,
  OneFailureAction,
  SuggestedRecipientsSuccessAction,
} from '../../documents.actions';
import { XHR_STATUS_IDLE } from '../../documents.state';
import {
  DraftDocumentMetadataKey,
  DraftDocumentsState,
  INITIAL_DRAFT_DOCUMENTS_STATE,
} from '../draft.state';

export const onUpdateRecipients = (
  state: DraftDocumentsState,
  action: OneAction,
): DraftDocumentsState => ({
  ...updateOne(state, action, DraftDocumentMetadataKey.updateRecipients),
  detailView: {
    ...state.detailView,
    suggestedRecipients: undefined,
  },
});

export const onUpdateRecipientsFailure = (
  state: DraftDocumentsState,
  action: OneFailureAction,
): DraftDocumentsState => ({
  ...updateOneFailure(state, action, DraftDocumentMetadataKey.updateRecipients),
  detailView: {
    ...state.detailView,
    suggestedRecipients: undefined,
  },
});

export const onUpdateRecipientsSuccess = (
  state: DraftDocumentsState,
  resource: SendingRequestDto,
): DraftDocumentsState => {
  const newState = updateDocumentAndMeta(
    state,
    resource,
    DraftDocumentMetadataKey.updateRecipients,
  );

  return {
    ...newState,
    entities: {
      ...newState.entities,
      [resource.id]: {
        ...newState.entities[resource.id],
        meta: {
          ...newState.entities[resource.id].meta,
          setEnsMessage: XHR_STATUS_IDLE,
          setEnsConversationClosed: XHR_STATUS_IDLE,
        },
      },
    },
    detailView: {
      ...newState.detailView,
      suggestedRecipients: undefined,
    },
  };
};

export const onRemoveRecipient = (
  state: DraftDocumentsState,
  action: OneAction,
): DraftDocumentsState => ({
  ...updateOne(state, action, DraftDocumentMetadataKey.removeRecipient),
  detailView: {
    ...state.detailView,
    suggestedRecipients: INITIAL_DRAFT_DOCUMENTS_STATE.detailView.suggestedRecipients,
  },
});

export const onRemoveRecipientFailure = (
  state: DraftDocumentsState,
  action: OneFailureAction,
): DraftDocumentsState => ({
  ...updateOneFailure(state, action, DraftDocumentMetadataKey.removeRecipient),
  detailView: {
    ...state.detailView,
    suggestedRecipients: INITIAL_DRAFT_DOCUMENTS_STATE.detailView.suggestedRecipients,
  },
});

export const onRemoveRecipientSuccess = (
  state: DraftDocumentsState,
  resource: SendingRequestDto,
): DraftDocumentsState => {
  const newState = updateDocumentAndMeta(state, resource, DraftDocumentMetadataKey.removeRecipient);
  return {
    ...newState,
    detailView: {
      ...newState.detailView,
      suggestedRecipients: INITIAL_DRAFT_DOCUMENTS_STATE.detailView.suggestedRecipients,
    },
  };
};

export const setSuggestedRecipientsSuccess = (
  state: DraftDocumentsState,
  action: SuggestedRecipientsSuccessAction,
): DraftDocumentsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    suggestedRecipients: action.payload.suggestions,
  },
});
