import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { logger } from '@services/logger/logger.service';

import { List, Practitioner } from '@honestica/core-apps-common/types';

import {
  findPractitioners,
  findPractitionersFailure,
  findPractitionersSuccess,
} from './practitionerDirectory.action';

export interface PractitionerDirectoryState {
  list: string[];
  total: number;
  loading: boolean;
  error: boolean;
}

const INITIAL_STATE: PractitionerDirectoryState = {
  list: [],
  total: 0,
  loading: false,
  error: false,
};

function listPractitionersSuccess(
  state: PractitionerDirectoryState,
  action: PayloadAction<List<Practitioner>>,
) {
  const { payload } = action;

  if (!payload?.list || !Array.isArray(payload?.list)) {
    return state;
  }

  logger.info('STATE', 'listPractitionersSuccess', {
    length: payload.list.length,
    total: payload.total,
  });

  return {
    error: false,
    list: payload.list.map((practitioner: Practitioner) => practitioner.id),
    total: payload.total,
    loading: false,
  };
}

function loading(state: PractitionerDirectoryState, status: boolean) {
  return {
    ...state,
    loading: status,
  };
}

function listPractitionersFailure(state: PractitionerDirectoryState) {
  return { ...state, loading: false, error: true };
}

export const practitionerDirectoryReducer = createReducer(INITIAL_STATE, {
  [findPractitioners.type]: (state) => loading(state, true),
  [findPractitionersSuccess.type]: listPractitionersSuccess,
  [findPractitionersFailure.type]: listPractitionersFailure,
});
