import { DashboardSearchParams } from '@honestica/core-apps-common/types';

import { ArchivedDocumentsState } from '@store/documents/incoming/archived.state';
import { InboxDocumentsState } from '@store/documents/incoming/inbox.state';
import { DraftDocumentsState } from '@store/documents/outgoing/draft.state';
import { SentDocumentsState } from '@store/documents/outgoing/sent.state';

import { UpdateSearchParamsAction } from '../../documents.actions';
import { DocumentsState, INITIAL_DASHBOARD_VIEW_STATE } from '../../documents.state';

export function updateSearchParams<T extends DocumentsState>(
  state: T,
  action: UpdateSearchParamsAction,
): T {
  let searchParams: DashboardSearchParams;

  const DraftOrSent = state as DraftDocumentsState | SentDocumentsState;
  const SentOrArchivedOrInbox = state as
    | ArchivedDocumentsState
    | SentDocumentsState
    | InboxDocumentsState;

  if (action.payload.clearSearch) {
    searchParams = DraftOrSent.dashboardView.settingsOverrides
      ? {
          ...INITIAL_DASHBOARD_VIEW_STATE.searchParams,
          ...(typeof SentOrArchivedOrInbox.dashboardView.searchParams.defaultDateRange ===
            'boolean' && {
            defaultDateRange: true,
          }),
          hideOtherRequesters: DraftOrSent.dashboardView.settingsOverrides.myRequestOnly,
        }
      : { ...INITIAL_DASHBOARD_VIEW_STATE.searchParams };
  } else {
    searchParams = action.payload.search
      ? { ...state.dashboardView.searchParams, ...action.payload.search }
      : { ...state.dashboardView.searchParams };
  }

  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      searchParams,
    },
  };
}

// TODO: we should have specific updateSearchParams functions for each dashboard to avoid using general INITIAL_DASHBOARD_VIEW_STATE + specific conditions or use the dashboardtype
// https://honestica.atlassian.net/browse/SEDEX-1387
