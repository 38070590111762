export const authorizedUrls = [
  'http://localhost:3001', // DEV
  'http://localhost:3000',
  'https://app-staging.lifen.fr', // STAGING
  'https://app.post-prod.lifen.fr', // POST PROD
  'https://app.lifen.fr', // PROD
  'https://login-staging.lifen.fr', // LOGIN
  'https://login.lifen.fr',
  'https://origin-oauth2.public.staging.lifen.fr',
  'https://login.public.post-prod.lifen.fr',
  `file://${__dirname}/../pages/error.html`, // LOCAL ERROR
];

export const reviewAppRegex =
  /(https:\/\/)((lifen-core-apps-)|(lifen-app-)).*(.private.review.lifen.fr)/;

export const LOGIN_PARTITION = 'persist:lifenconnect';

export const NOTIFICATION_WINDOW_HEIGHT = 160;
export const NOTIFICATION_WINDOW_WIDTH = 365;
