import { createAction } from '@reduxjs/toolkit';

import { FlowType } from '@honestica/core-apps-common/types';

import { NotificationJobError, NotificationJobSuccess } from './notificationWindow.state';

export type NotificationActionTypes =
  (typeof NotificationActions)[keyof typeof NotificationActions];

export type NotificationAddPending = typeof notificationAddPending;
export type NotificationAddSuccess = typeof notificationAddSuccess;
export type NotificationAddError = typeof notificationAddError;

export const NOTIFICATION_REDUCE_NAME = 'notification';

export const notificationAddPending = createAction<{ count: number; flowType: FlowType }>(
  `${NOTIFICATION_REDUCE_NAME}/addPending`,
);

export const notificationAddSuccess = createAction<NotificationJobSuccess>(
  `${NOTIFICATION_REDUCE_NAME}/addSuccess`,
);

export const notificationAddError = createAction<NotificationJobError>(
  `${NOTIFICATION_REDUCE_NAME}/addError`,
);

export const resetNotification = createAction<void>(`${NOTIFICATION_REDUCE_NAME}/reset`);

export const NotificationActions = {
  addError: notificationAddError,
  addPending: notificationAddPending,
  addSuccess: notificationAddSuccess,
  reset: resetNotification,
};
