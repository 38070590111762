import { Address } from './address';
import { Professional, ProfessionalDto } from './professional';
import { MediumDeliveryError } from './sending';
import { RecipientPrediction } from './sendingRequest.types';
import { Telecom } from './telecom';

/**
 * The property @sentToFallback can be :
 * - 'yes' if there is a resent
 * - 'no' if there isn't a resent
 * - 'error' if the resent is in error
 */
export type MediumDeliveryStatusWithError = {
  status: MediumDeliveryStatusEnum.Error;
  error: MediumDeliveryError;
  sentToFallback?: SentToFallbackStatus;
};

/**
 * The status Yes, SkippedNoMedium or SkippedAlreadySent
 * indicate that the sender must have a 'yes_if_bounce' or
 * 'yes_if_bounce_or_unread' as patientAutomaticResending
 */
export enum SentToFallbackStatus {
  Yes = 'yes',
  No = 'no',
  SkippedNoMedium = 'no_fallback_medium',
  SkippedAlreadySent = 'already_sent',
}

export enum MediumDeliveryStatusEnum {
  Pending = 'pending',
  Success = 'success',
  Read = 'read',
  Error = 'error',
}
export type MediumDeliveryStatusOnly = {
  status:
    | MediumDeliveryStatusEnum.Pending
    | MediumDeliveryStatusEnum.Success
    | MediumDeliveryStatusEnum.Read;
  sentToFallback?:
    | SentToFallbackStatus.Yes
    | SentToFallbackStatus.No
    | SentToFallbackStatus.SkippedAlreadySent
    | SentToFallbackStatus.SkippedNoMedium;
};

export type MediumDeliveryStatus = { mediumId: string } & (
  | MediumDeliveryStatusWithError
  | MediumDeliveryStatusOnly
);

export type Medium<T extends Telecom | Address> = T & {
  // We put de deliveryStatus to optionnal here
  // because of we use the sames DTOs to make
  // GET & PUT Requests
  // And to keep retro-compatibility with Lifen Mail V1.alpha
  deliveryStatus?: MediumDeliveryStatus;
  customMessage?: string;
  replaced?: boolean;
  conversationClosed?: boolean;
};

export type MediumList<T extends Telecom | Address> = Medium<T>[];

export interface RecipientDto extends ProfessionalDto {
  telecoms: MediumList<Telecom>;
}

export enum RecipientVerificationTypes {
  recipients_other_than_high = 'recipients-other-than-high',
  recipients_without_medium = 'recipients-without-medium',
}

export type RecipientVerificationType =
  | RecipientVerificationTypes.recipients_other_than_high
  | RecipientVerificationTypes.recipients_without_medium;

export type VerifiableType = 'Document' | 'Patient' | 'Recipient'; // Prediction?

export interface RecipientVerification {
  id: number;
  key: RecipientVerificationType;
  verifiableId: number;
  verifiableType: VerifiableType;
  dismissedAt?: string;
}

export interface ProfessionalDtoWithPrediction extends ProfessionalDto {
  prediction?: ExtractedPrediction;
  verifications: RecipientVerification[];
}

export interface DocumentRecipientDto extends ProfessionalDtoWithPrediction {
  telecoms: MediumList<Telecom>;
  addresses: MediumList<Address>;
}

export interface CreateDematRecipient {
  email: string;
  phoneNumber: string;
  name: string;
}

export interface CreatePostalRecipient {
  recipientName: string;
  address: string;
  complementaryAdress?: string;
  complementaryAdressBis?: string;
  zipCode: string;
  city: string;
}

export interface RecipientBundle {
  recipient: Professional;
  telecoms: MediumList<Telecom>;
}

interface DocumentRecipientBundleBase {
  entity: Professional;
  prediction?: ExtractedPrediction;
  verifications: RecipientVerification[];
}

export interface DocumentRecipientBundle extends DocumentRecipientBundleBase {
  telecoms: MediumList<Telecom>;
  addresses: MediumList<Address>;
}

export interface ExtractedPrediction {
  grade?: RecipientPrediction['predictionCertainty'];
  notCertainCase?: string;
  fromAddress: string;
}

// Intermediate types here
// Better to manipulate objects
export type ExtractedDeliveryStatus = Record<string, Record<string, MediumDeliveryStatus>>;
export type ExtractedCustomMessages = Record<string, Record<string, string | undefined>>;
export type ExtractedRecipientsPredictions = Record<string, ExtractedPrediction>;
export type ExtractedGlobalPredictions = ExtractedPrediction[];
export type PossibleRecipient = fhir.Resource & { prediction: ExtractedPrediction };

export type TelecomsAddressesTuple = [MediumList<Telecom>, MediumList<Address>];

export const isDocumentRecipientDto = (val: any): val is DocumentRecipientDto =>
  typeof val.id === 'string' &&
  typeof val.type === 'string' &&
  Array.isArray(val.telecoms) &&
  Array.isArray(val.addresses);
