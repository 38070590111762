import { Action } from '@reduxjs/toolkit';
import { BatchActionClickedActionType, Tracking } from '@services/analytics';
import { all, getContext, takeLatest } from 'redux-saga/effects';

import { BatchAction } from '@store/documents/documents.actions';
import { ArchivedDocumentsActions } from '@store/documents/incoming/archived.slice';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';

function getActionType(type: string): BatchActionClickedActionType {
  let actionType = BatchActionClickedActionType.Unknown;
  if (type.includes('cancelMany')) {
    actionType = BatchActionClickedActionType.Delete;
  }
  if (type.includes('downloadMany')) {
    actionType = BatchActionClickedActionType.Download;
  }
  if (type.includes('sendMany')) {
    actionType = BatchActionClickedActionType.Send;
  }
  if (type.includes('groupMany')) {
    actionType = BatchActionClickedActionType.Group;
  }
  if (type.includes('duplicateMany')) {
    actionType = BatchActionClickedActionType.Duplicate;
  }
  if (type.includes('splitMany')) {
    actionType = BatchActionClickedActionType.Split;
  }
  if (type.includes('archiveMany')) {
    actionType = BatchActionClickedActionType.Archive;
  }
  if (type.includes('restoreMany')) {
    actionType = BatchActionClickedActionType.Restore;
  }
  if (type.includes('integrateMany')) {
    actionType = BatchActionClickedActionType.Integrate;
  }

  return actionType;
}

function* trackBatchActions(data: BatchAction): any {
  const trackingService: Tracking = yield getContext('trackingService');
  const { type, payload } = data;
  const documentIds = payload.ids;

  trackingService.batchActionClicked({
    actionType: getActionType(type),
    numberOfSelectedRows: documentIds.length,
    documentIds,
  });
}

export function* trackingDocumentBatchActionsSaga() {
  yield all([takeLatest((action: Action) => /cancelMany$/.test(action.type), trackBatchActions)]);
  yield all([takeLatest((action: Action) => /downloadMany$/.test(action.type), trackBatchActions)]);
  yield all([takeLatest(DraftDocumentsActions.sendMany.type, trackBatchActions)]);
  yield all([takeLatest(DraftDocumentsActions.groupMany.type, trackBatchActions)]);
  yield all([
    takeLatest((action: Action) => /duplicateMany$/.test(action.type), trackBatchActions),
  ]);
  yield all([takeLatest(DraftDocumentsActions.splitMany.type, trackBatchActions)]);
  yield all([takeLatest(InboxDocumentsActions.archiveMany.type, trackBatchActions)]);
  yield all([takeLatest(ArchivedDocumentsActions.restoreMany.type, trackBatchActions)]);
  yield all([takeLatest(WorklistIntegrationsActions.integrateMany.type, trackBatchActions)]);
}
