import { ComponentProps, ReactNode } from 'react';

import cx from 'classnames';

import Typography from '../Typography';

import styles from './EmptyState.module.less';

export interface EmptyStateProps {
  actions?: ReactNode;
  className?: string;
  description?: string;
  image: ComponentProps<'img'>;
  title: string;
  'data-testid'?: string;
}

export const EmptyState = (props: EmptyStateProps) => (
  <div className={cx(styles.root, props.className)} data-testid={props['data-testid']}>
    <img height={100} {...props.image} className={cx('margin-bottom-lg', styles.image)} alt="" />
    <Typography.Title level={5}>{props.title}</Typography.Title>
    {!!props.description && (
      <Typography.Text className={styles.description}>{props.description}</Typography.Text>
    )}
    {!!props.actions && <div>{props.actions}</div>}
  </div>
);
