import { v4 as uuid } from 'uuid';

import { MimeType } from '@honestica/core-apps-common/constants';
import {
  Attachment,
  DocumentJobStatus,
  FlowType,
  Professional,
  UploadDocumentJob,
  UploadSource,
} from '@honestica/core-apps-common/types';
import { arrify, diffInDays, isDefined } from '@honestica/core-apps-common/utils';

const binaryFiles: Map<string, { file: File; dashboardType: string }> = new Map();

export async function fileToBase64(file: File): Promise<string> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export const base64ToFile = (attachment: Attachment): File => {
  const name = attachment.name ?? `attachment`;
  const buffer = Buffer.from(attachment.content, 'base64');
  return new File([buffer], name, { type: attachment.contentType });
};

export const fileToBuffer = async (file: File): Promise<Buffer> => {
  const arrayBuffer = await file.arrayBuffer();
  return Buffer.from(arrayBuffer);
};

export const bufferToFile = (
  buffer: Buffer,
  fileName: string,
  fileType: MimeType = MimeType.Pdf,
): File => {
  const blob = new Blob([buffer as BlobPart], { type: fileType });
  return new File([blob], fileName, { type: fileType });
};

export function setFileInMemory(file: File, dashboardType = ''): string {
  const uid = uuid();
  binaryFiles.set(uid, { file, dashboardType });
  return uid;
}

export function putAttachmentsInMemory(
  attachments: Attachment[] | undefined,
  dashboardType: string,
): string[] {
  return arrify(attachments).map((a) => setFileInMemory(base64ToFile(a), dashboardType));
}

export function replaceAttachmentsInMemory(
  attachments: Attachment[] | undefined,
  dashboardType: string,
): string[] {
  removeAllDashboardFilesInMemory(dashboardType);
  return putAttachmentsInMemory(attachments, dashboardType);
}

export function getFileInMemory(uid: string | undefined): File | undefined {
  if (!uid) {
    return undefined;
  }
  return binaryFiles.get(uid)?.file;
}

export function getFilesInMemory(uids: string[] = []) {
  return uids.map((uid) => getFileInMemory(uid)).filter(isDefined);
}

export function removeFilesInMemory(fileUids: string[]) {
  fileUids.forEach((key: string) => {
    binaryFiles.delete(key);
  });
}

export function removeAllDashboardFilesInMemory(dashboardType: string) {
  binaryFiles.forEach((value, key) => {
    if (value.dashboardType === dashboardType) binaryFiles.delete(key);
  });
}

export const isFileOlderThanXDays = (fileStats: any, x: number): boolean => {
  const fileCreationDate = new Date(fileStats.ctime);
  const today = new Date();
  const daysDiff = diffInDays(today, fileCreationDate);
  return daysDiff >= x;
};

interface FileToConvertToDocumentJobParams {
  file: File;
  fileName: string;
  filePath?: string;
  sender: Professional;
  uploadSource: UploadSource;
  flowType: FlowType;
}

export function convertFileToDocumentJob(
  params: FileToConvertToDocumentJobParams,
): UploadDocumentJob {
  const id = setFileInMemory(params.file);

  return {
    status: DocumentJobStatus.Pending,
    sender: params.sender,
    uploadSource: params.uploadSource,
    startDate: new Date(),
    id: uuid(),
    fileName: params.fileName,
    fileStorageId: id,
    filePath: params.filePath || '',
    flowType: params.flowType,
  };
}
