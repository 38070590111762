import { useCallback } from 'react';

import { cleanOutboxFolder } from '@launcher/helpers/desktop';
import { useSelector } from 'react-redux';

import { selectDesktopSettings } from '@store/desktop/desktop.selector';

export function useCleanOutbox() {
  const settings = useSelector(selectDesktopSettings);

  return useCallback(() => cleanOutboxFolder([settings.watchPath], 0), [settings]);
}
