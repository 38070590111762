import { Tracking } from '@services/analytics';
import { all, getContext, select, takeLatest } from 'redux-saga/effects';

import { JobStatus } from '@honestica/core-apps-common/types';
import { isProcessed } from '@honestica/core-apps-common/utils';

import { OpenDrawerFromStore, openDrawerFromStore } from '@store/drawer/drawer.action';
import {
  FindPatients,
  UpdatePatientJobState,
  enqueuePatientsImportJobSuccess,
  findPatients,
  updatePatientJobStateSuccess,
} from '@store/patientDirectory/patientDirectory.action';
import { getJob } from '@store/patientDirectory/patientDirectory.selector';
import { getCurrentWorkspaceId } from '@store/user/user.selector';

function* trackPatientCardViewed({ payload }: ReturnType<OpenDrawerFromStore>) {
  const trackingService: Tracking = yield getContext('trackingService');
  if (payload.drawer === 'patient-detail') {
    trackingService.patientCardViewed({ patientFhirReference: payload?.patientId });
  }
}

function* trackPatientSearched({ payload }: ReturnType<FindPatients>) {
  const trackingService: Tracking = yield getContext('trackingService');
  if (payload.searchText) {
    trackingService.patientSearched();
  }
}

function* trackImportStarted() {
  const trackingService: Tracking = yield getContext('trackingService');
  const currentWorkspaceId: string = yield select(getCurrentWorkspaceId);
  trackingService.patientDirectoryImportStarted({ workspaceFhirReference: currentWorkspaceId });
}

function* trackUpdatePatientJob({ payload: job }: ReturnType<UpdatePatientJobState>) {
  const trackingService: Tracking = yield getContext('trackingService');
  if (isProcessed(job)) {
    const {
      importReport: { invalidRecords, createdRecords, updatedRecords },
      status: jobStatus,
    } = yield select(getJob);
    const currentWorkspaceId: string = yield select(getCurrentWorkspaceId);
    if (jobStatus === JobStatus.Completed) {
      trackingService.patientDirectoryImportDone({
        patientsCreated: createdRecords,
        patientsFailed: invalidRecords,
        patientsUpdated: updatedRecords,
        workspaceFhirReference: currentWorkspaceId,
      });
    }
    if (jobStatus === JobStatus.Failed)
      trackingService.patientDirectoryImportFailed({ errorCause: undefined });
  }
}

export function* trackingPatientDirectorySagas() {
  yield all([takeLatest(updatePatientJobStateSuccess.type, trackUpdatePatientJob)]);
  yield all([takeLatest(enqueuePatientsImportJobSuccess.type, trackImportStarted)]);
  yield all([takeLatest(findPatients.type, trackPatientSearched)]);
  yield all([takeLatest(openDrawerFromStore.type, trackPatientCardViewed)]);
}
