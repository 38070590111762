export enum SourceType {
  LIFEN_INTEGRATION_FORWARD = 'lifen_integration_forward',
  UNKNOWN = 'unknown',
  MSS_EMAIL = 'mss_email',
}

export interface DocumentSource {
  type: SourceType;
  display?: string;
}
