import { createAction } from '@reduxjs/toolkit';

import {
  ErrorPayload,
  FeatureFlags,
  LogoutOptions,
  Organization,
  Professional,
  SelectedIdentities,
} from '@honestica/core-apps-common/types';

import { AuthenticationError, ProductAppName, UserData } from './user.state';

export type AuthenticateFailure = typeof authenticateUserFailure;
export type AuthenticateUserSuccess = typeof authenticateUserSuccess;
export type FetchIdentitiesSuccess = typeof fetchIdentitiesSuccess;
export type FetchIdentitiesFailure = typeof fetchIdentitiesFailure;
export type FetchIntegrationsSuccess = typeof fetchIntegrationsSuccess;
export type FetchIntegrationsFailure = typeof fetchIntegrationsFailure;
export type Logout = typeof logout;
export type InitFeatureFlags = typeof initFeatureFlags;
export type UpdateFeatureFlags = typeof initFeatureFlags;
export type SetSelectedIdentity = typeof setSelectedIdentity;
export type InitSelectedIdentities = typeof initSelectedIdentities;
export type SwitchWorkspace = typeof switchWorkspace;

const REDUCER_NAME = 'user';

const AUTHENTICATE = `${REDUCER_NAME}/authenticate`;

export const authenticateUser = createAction<void>(AUTHENTICATE);
export const authenticateUserSuccess = createAction<Partial<UserData>>(`${AUTHENTICATE}Success`);
export const authenticateUserFailure = createAction<AuthenticationError>(`${AUTHENTICATE}Failure`);

const FETCH_IDENTITIES = `${REDUCER_NAME}/fetchIdentities`;

export const fetchIdentitiesSuccess = createAction<Professional[]>(`${FETCH_IDENTITIES}Success`);
export const fetchIdentitiesFailure = createAction<ErrorPayload>(`${FETCH_IDENTITIES}Failure`);

const FETCH_INTEGRATIONS = `${REDUCER_NAME}/fetchIntegrations`;

export const fetchIntegrationsSuccess = createAction<Organization[]>(
  `${FETCH_INTEGRATIONS}Success`,
);
export const fetchIntegrationsFailure = createAction<ErrorPayload>(`${FETCH_INTEGRATIONS}Failure`);

export const login = createAction<void>(`${REDUCER_NAME}/login`);

export const logout = createAction<LogoutOptions | undefined>(`${REDUCER_NAME}/logout`);

export const setSelectedIdentity = createAction<{
  identity: Professional;
  isFromLocalSettings?: boolean;
  currentApp: ProductAppName;
}>(`${REDUCER_NAME}/setSelectedIdentity`);

export const initSelectedIdentities = createAction<{
  selectedIdentities: SelectedIdentities;
}>(`${REDUCER_NAME}/initSelectedIdentities`);

export const initFeatureFlags = createAction<FeatureFlags>(`${REDUCER_NAME}/initFeatureFlags`);
export const updateFeatureFlags = createAction<FeatureFlags>(`${REDUCER_NAME}/updateFeatureFlags`);

export const switchWorkspace = createAction<string>(`${REDUCER_NAME}/switchWorkspace`);
