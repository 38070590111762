export enum AppKeys {
  Documents = 'LIFEN-DOCUMENTS',
  Integrations = 'LIFEN-INTEGRATIONS',
}

export enum MainRouteUrls {
  DesktopSettings = 'desktop-settings',
  Documents = 'documents',
  Integrations = 'integrations',
  Maintenance = 'maintenance',
}

export enum AppHomeRouteUrls {
  DesktopSettings = 'desktop-settings',
  Documents = 'documents/draft',
  Integrations = 'integrations',
}

export type CoreApp = {
  id: string;
  displayName: string;
  iconUrl: string;
  storeIcon: string;
  path: MainRouteUrls;
  name: AppKeys;
};
