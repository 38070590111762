import { ChangeEvent, ForwardedRef, forwardRef, useCallback } from 'react';

import { Input, InputProps, InputRef } from 'antd';

import { isPositiveInteger } from '@honestica/core-apps-common/utils';

interface Props {
  className?: string;
  disabled?: boolean;
  value: number | '';
  onValueChange: (value: number | '') => void;
}

export const PositiveIntegerInput = forwardRef(
  (props: Props & InputProps, ref: ForwardedRef<InputRef>) => {
    const { onValueChange, ...inputProps } = props;

    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.value === '') {
          onValueChange('');
          return;
        }

        const value = Number(e.currentTarget.value);
        if (isPositiveInteger(value)) {
          onValueChange(value);
        }
      },
      [onValueChange],
    );

    return <Input ref={ref} {...inputProps} onChange={onChange} />;
  },
);
