import { Tracking, tracking } from '@services/analytics';
import { logger } from '@services/logger/logger.service';

export const WATCHER_TRACKER_MIN_ELAPSED_TIME = 1000;
export const WATCHER_TRACKER_POLLING_INTERVAL = 500;

export class WatcherTracker {
  private watchFiles: string[];

  private lastWatchedFileDate: number | undefined;

  private timeoutId: NodeJS.Timeout | undefined;

  private readonly trackingService: Tracking;

  public constructor(trackingService: Tracking) {
    this.watchFiles = [];
    this.trackingService = trackingService;
  }

  public onWatchFile(filePath: string): void {
    this.watchFiles.push(filePath);
    this.lastWatchedFileDate = Date.now();

    if (!this.timeoutId) {
      this.scheduleTrackingEvent();
    }
  }

  private scheduleTrackingEvent() {
    this.timeoutId = setTimeout(() => {
      this.shouldSendTrackingEvent();
    }, WATCHER_TRACKER_POLLING_INTERVAL);
  }

  private shouldSendTrackingEvent() {
    if (
      this.lastWatchedFileDate &&
      Date.now() - this.lastWatchedFileDate > WATCHER_TRACKER_MIN_ELAPSED_TIME
    ) {
      if (this.watchFiles.length > 1) {
        logger.info('LOGIC', `Batch file upload start with ${this.watchFiles.length} files.`);
        this.trackingService.trackBatchFileUploadStart({
          documentsCount: this.watchFiles.length,
          uploadSource: 'watcher',
        });
      }
      this.watchFiles = [];
      this.lastWatchedFileDate = undefined;

      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    } else {
      this.scheduleTrackingEvent();
    }
  }
}

export const watcherTracker = new WatcherTracker(tracking);
