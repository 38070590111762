import { LoincCodeType, MediumAndTelecomType, SourceType } from '..';
import { IntegrationsDashboardType } from '../entities/integrationRequest.types';
import { PatientSearch } from '../entities/patient.types';
import {
  DocumentVerificationStatus,
  RecipientId,
  SendingsDashboardType,
} from '../entities/sendingRequest.types';

export enum SelectedFilterAll {
  ALL = 'all',
}
export type SelectedMediumFilter = MediumAndTelecomType | SelectedFilterAll;

export type SelectedDocumentTypeFilter = LoincCodeType | SelectedFilterAll;

export type DateRangeValue = {
  fromDate: Date;
  untilDate: Date;
};
export interface SearchParams extends SortByParams {
  searchText?: string;
  page?: number;
  perPage?: number;
  dashboardType?: SendingsDashboardType | IntegrationsDashboardType | 'errors';
  // Contact
  id?: string;
  userId?: string;
  // Sending
  ids?: string[];
  // Integration
  useGenericOptions?: boolean;
  // BFF
  'ids:not'?: string[];
  withTelecom?: string[];
  allowTelecomMissing?: boolean;
  hideOtherRequesters?: boolean;
  recipient?: RecipientId;
  selectedMediumFilter?: SelectedMediumFilter;
  selectedSenderFilter?: string;
  selectedStatusFilter?: DocumentVerificationStatus;
  verificationStatus?: DocumentVerificationStatus;
  medium?: SelectedMediumFilter;
  sender?: string;
  showRead?: boolean;
  cursor_field?: string;
  before?: string;
  // extends SortByParams should also be cleaned with bff
}
export interface DashboardSearchParams extends SortByParams {
  dashboardType?: SendingsDashboardType | IntegrationsDashboardType | 'errors';
  dateRange?: DateRangeValue;
  defaultDateRange?: boolean;
  documentType?: SelectedDocumentTypeFilter;
  hideOtherRequesters?: boolean;
  page?: number;
  perPage?: number;
  recipient?: RecipientId;
  searchPatient?: PatientSearch;
  selectedMediumFilter?: SelectedMediumFilter;
  selectedSenderFilter?: string;
  strictSender?: string;
  selectedStatusFilter?: DocumentVerificationStatus;
  verificationStatus?: DocumentVerificationStatus;
  medium?: SelectedMediumFilter;
  connector?: SelectedMediumFilter;
  sender?: string;
  signingPractitioner?: string;
  source?: SourceType;
  statuses?: string[];
  showRead?: boolean;
  sortBy?: string;
  includeConnectors?: boolean;
  includeUnselectedConnectors?: boolean;
  includeDeliveryStatuses?: boolean;
  includeIntegration?: boolean;
  includePractitionerRoles?: boolean;
  includeEhrFallback?: boolean;
  includePredictions?: boolean;
}

export interface ParsedSearchParams
  extends Omit<DashboardSearchParams, 'defaultDateRange' | 'dateRange' | 'searchPatient'> {
  // could exclude more params ?
  fromDate?: Date;
  untilDate?: Date;
  'patientSearch[birthdate]'?: string;
  'patientSearch[ipp]'?: string;
  'patientSearch[firstname]'?: string;
  'patientSearch[lastname]'?: string;
}

export type SortByParams = {
  sortByAuthoredOn?: SortOrder;
};

export enum SortOrder {
  'Desc' = 'desc',
  'Asc' = 'asc',
}

export interface GetDocumentParams {
  clearSearch?: boolean;
  search?: DashboardSearchParams;
}
