import { FailureAction, SearchPatientsSuccessAction } from '../../documents.actions';
import { XHR_STATUS_ERROR, XHR_STATUS_IDLE, XHR_STATUS_LOADING } from '../../documents.state';
import { DraftDocumentsState, INITIAL_DRAFT_DOCUMENTS_STATE } from '../draft.state';

export const onSearchPatients = (state: DraftDocumentsState): DraftDocumentsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    patientsRequest: XHR_STATUS_LOADING,
    patientIds: INITIAL_DRAFT_DOCUMENTS_STATE.detailView.patientIds,
  },
});

export const onSearchPatientsSuccess = (
  state: DraftDocumentsState,
  action: SearchPatientsSuccessAction,
): DraftDocumentsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    patientsRequest: XHR_STATUS_IDLE,
    patientIds: action.payload.patients.list.map((patient) => patient.id),
  },
});

export const onSearchPatientsFailure = (
  state: DraftDocumentsState,
  _action: FailureAction,
): DraftDocumentsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    patientsRequest: XHR_STATUS_ERROR,
  },
});

export const onFlushPatientSearch = (state: DraftDocumentsState): DraftDocumentsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    patientsRequest: XHR_STATUS_IDLE,
    patientIds: [],
  },
});
