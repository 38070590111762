import { isDesktopApp } from '@launcher/utils';
import { tracking } from '@services/analytics';
import { logger } from '@services/logger/logger.service';

import { trayMenu } from '.';

const RELEASE_SERVER_URL = 'https://electron-releases.lifen.fr';
let appUpdateInterval: NodeJS.Timeout | null = null;

export function registerAutoUpdaterListeners() {
  const autoUpdater = window.electron.getAutoUpdater();

  autoUpdater.on('checking-for-update', () =>
    logger.info('DESKTOP', `AutoUpdater: <checking-for-update>`),
  );

  autoUpdater.on('error', (error: Error) => {
    logger.error('DESKTOP', `AutoUpdater: <error> ${error}`, {}, false);
    tracking.appUpdateFailed({ errorCause: error?.message });
  });

  autoUpdater.on('update-available', () => {
    logger.info('DESKTOP', `AutoUpdater: <update-available>`);
    tracking.appUpdateStarted({});
  });

  autoUpdater.on('update-not-available', () => {
    logger.info('DESKTOP', `AutoUpdater: <update-not-available>`);
  });

  autoUpdater.on('update-downloaded', (_event: Event, releaseName: string, url: string) => {
    logger.info('DESKTOP', `AutoUpdater: <update-downloaded> ${releaseName} ${url}`);
    tracking.appUpdateCompleted({});
    trayMenu.addUpdater();
  });

  autoUpdater.on('before-quit-for-update', () => {
    logger.info('DESKTOP', `AutoUpdater: <before-quit-for-update>`);
    if (window.electron.getPlatform() === 'darwin') {
      window.electron.quitApp();
    }
  });
}

export async function setAppUpdate(releaseChannel: string, releaseUrl?: string) {
  if (!guardNotUpdatable()) return;

  const autoUpdater = window.electron.getAutoUpdater();

  const url = getAppUpdateURL(releaseChannel, releaseUrl);

  autoUpdater.setFeedURL({ url });

  logger.info('DESKTOP', `AutoUpdater: setFeedUrl "${url}"`);

  autoUpdater.checkForUpdates();

  // Check for updates every 2 hours
  if (appUpdateInterval) clearInterval(appUpdateInterval);
  appUpdateInterval = setInterval(() => autoUpdater.checkForUpdates(), 2 * 3600 * 1000);
}

function guardNotUpdatable() {
  if (!isDesktopApp()) return false;

  const hasNoUpdateExe =
    window.electron.getPlatform() === 'win32' && !window.electron.isUpdateAvailable();

  if (hasNoUpdateExe) {
    logger.info('DESKTOP', 'AutoUpdater: not initialize, NO update.exe');
    return false;
  }

  return true;
}

function getAppUpdateURL(releaseChannel: string, releaseUrl?: string) {
  const dirtyUrl = releaseUrl || RELEASE_SERVER_URL;
  const rootUrl = dirtyUrl.trim().replace(/\/$/, '');

  const platform =
    window.electron.getPlatform() === 'win32'
      ? 'win32'
      : `${window.electron.getPlatform()}_${window.electron.getArch()}`;

  const currentDesktopVersion = window.electron?.getElectronVersion().split('-')[0];

  return `${rootUrl}/update/${platform}/${currentDesktopVersion}/${releaseChannel || 'stable'}`;
}
