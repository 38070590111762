function assertInput(value: unknown): asserts value is string {
  if (!(typeof value === 'string') || value.length === 0) {
    throw new ConfigError(`[EnvironmentVariable] value must be a string and not empty.`);
  }
}

export class ConfigError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ConfigError';

    Object.setPrototypeOf(this, ConfigError.prototype);
  }
}

const getOptionalEnvironmentVariable = (value: string): string | undefined =>
  window.client_env ? window.client_env[value] : undefined;

const getSensitiveString = (value: string): string | undefined => {
  assertInput(value);

  const environmentVariable: string | undefined = getOptionalEnvironmentVariable(value);

  if (value === undefined || value === null) {
    return undefined;
  }

  return environmentVariable;
};

const convertStringToNumber = (value: string): number | undefined => {
  const valueToNumber = Number(value);

  if (!Number.isInteger(value)) {
    return undefined;
  }

  return valueToNumber;
};

export const getNumber = (value: string): number | undefined => {
  const environmentVariable: string | undefined = getOptionalEnvironmentVariable(value);

  if (environmentVariable === undefined) {
    return undefined;
  }

  return convertStringToNumber(environmentVariable);
};

export const getString = (value: string): string | undefined => getSensitiveString(value);
