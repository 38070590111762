import { SetCurrentIdentityAction } from '@store/documents/documents.actions';

import { DraftDocumentsState, INITIAL_DRAFT_DOCUMENTS_STATE } from '../draft.state';

export function setCurrentIdentity<T extends DraftDocumentsState>(
  state: T,
  action: SetCurrentIdentityAction,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      currentIdentity: action.payload.identity,
      searchParams: { ...state.dashboardView.searchParams, page: 1 },
    },
  };
}

export function resetCurrentIdentity<T extends DraftDocumentsState>(state: T): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      currentIdentity: INITIAL_DRAFT_DOCUMENTS_STATE.dashboardView.currentIdentity,
      searchParams: { ...state.dashboardView.searchParams, page: 1 },
    },
  };
}
