import { ConnectedProps, connect } from 'react-redux';

import { selectDesktopState } from '@store/desktop/desktop.selector';
import { State } from '@store/reducer';

export type CleanOutboxSettingReduxProps = ConnectedProps<typeof cleanOutboxSettingConnector>;

interface OwnProps {
  className?: string;
  disabled?: boolean;
  value: number | '';
  onValueChange: (value: number | '') => void;
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const desktop = selectDesktopState(state);

  return {
    ...ownProps,
    settings: desktop.settings,
  };
};

export const cleanOutboxSettingConnector = connect(mapStateToProps, {});
