import { TFunction } from 'i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import { CountryCode as CountryCodeLib } from 'libphonenumber-js/types';

export interface PhoneSupportedCountryCodes {
  name: string;
  isoCode: CountryCode;
}

export type CountryCode = CountryCodeLib;

export const DEFAULT_COUNTRY_CODE_IF_NOT_SPECIFIED = 'FR';

export function phoneSupportedCountryCodes(t: TFunction): PhoneSupportedCountryCodes[] {
  return [
    /* correspondence table can be found here: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 */
    { name: t('phone.countryCode.FR'), isoCode: 'FR' },
    { name: t('phone.countryCode.GP'), isoCode: 'GP' },
    { name: t('phone.countryCode.RE'), isoCode: 'RE' },
    { name: t('phone.countryCode.MQ'), isoCode: 'MQ' },
    { name: t('phone.countryCode.YT'), isoCode: 'YT' },
    { name: t('phone.countryCode.NC'), isoCode: 'NC' },
    { name: t('phone.countryCode.PF'), isoCode: 'PF' },
    { name: t('phone.countryCode.GF'), isoCode: 'GF' },
    { name: t('phone.countryCode.LB'), isoCode: 'LB' },
  ];
}
export function validateCellPhone(
  phoneNumber: string,
  countryCode: CountryCode,
  options: { allowBlank?: boolean; strict?: boolean } = { allowBlank: true, strict: true },
): boolean {
  if (phoneNumber.length === 0 && options.allowBlank) {
    return true;
  }
  if (options.strict) {
    return !!parsePhoneNumberFromString(phoneNumber, countryCode)?.isValid();
  }
  return !!parsePhoneNumberFromString(phoneNumber, countryCode)?.isPossible();
}

export function getInternationalPhoneNumber(
  number: string,
  country: CountryCode,
): string | undefined {
  return parsePhoneNumberFromString(number, country)?.format('E.164').toString();
}

export function getFormatedPhoneNumber(
  rawNumber: string | undefined,
  defaultCountryCode: CountryCode,
): { countryCode: CountryCode; phoneNumber: string } {
  if (!rawNumber || rawNumber?.length === 0) {
    return {
      countryCode: defaultCountryCode,
      phoneNumber: '',
    };
  }

  const formatedNumber = parsePhoneNumberFromString(rawNumber, defaultCountryCode);

  return {
    countryCode: formatedNumber?.country ?? 'FR',
    phoneNumber:
      formatedNumber?.format('NATIONAL', {
        humanReadable: true,
      }) || '',
  };
}
