export function isObject(obj: unknown): obj is object {
  return typeof obj === 'object' && obj !== null;
}

export function keyBy<T extends { [key: string]: any }, K extends keyof T>(
  resources: T[],
  key: K,
): { [key: string]: T } {
  return keyByFunc(resources, (resource: T) => resource[key]);
}

export function keyByFunc<T extends { [key: string]: any }>(
  resources: T[],
  by: (data: T) => string,
): { [key: string]: T } {
  return resources.reduce((accumulator: { [key: string]: T }, resource: T) => {
    const key = by(resource);
    if (!key) {
      return accumulator;
    }
    accumulator[key] = resource;
    return accumulator;
  }, {});
}

export function groupBy<T extends { [key: string]: any }, K extends keyof T>(
  resources: T[],
  key: K,
): { [key: string]: T[] } {
  return groupByFunc(resources, (resource: T) => resource[key]);
}

export function groupByFunc<T extends { [key: string]: any }>(
  resources: T[],
  by: (data: T) => string,
): { [key: string]: T[] } {
  return resources.reduce((accumulator: { [key: string]: T[] }, resource: T) => {
    const key = by(resource);
    if (!key) {
      return accumulator;
    }

    if (accumulator[key] && Array.isArray(accumulator[key])) {
      accumulator[key] = [...accumulator[key], resource];
    } else {
      accumulator[key] = [resource];
    }
    return accumulator;
  }, {});
}

export function uniqBy<T>(resources: T[], iterator: (arg: T) => any): T[] {
  return Array.from(
    resources
      .reduce((map, item) => {
        map.set(iterator(item), item);
        return map;
      }, new Map())
      .values(),
  );
}

export function mergeLeftWith<U extends Record<string, any>, V extends Record<string, any>>(
  a: U,
  b: V,
  fn: (a: any, b: any) => boolean,
) {
  // Types might be improved here, currently returning U
  const merged = { ...a };

  for (const key in b) {
    if (key in merged && fn(merged[key], b[key])) {
      merged[key] = b[key] as any;
    }
  }

  return merged;
}

/**
 * Return an object with all the fields that are strictly different from original
 * @param original original object to compare
 * @param toCheck potential object to compare
 * @returns partial object with only differents field
 */
export function diffInObj<T>(original: T, toCheck: Partial<T>) {
  const res: Partial<T> = {};

  let key: keyof T;

  for (key in toCheck) {
    if (original[key] !== toCheck[key]) {
      res[key] = toCheck[key];
    }
  }

  return res;
}

export function isEmptyObj(obj: unknown) {
  if (isObject(obj)) {
    return Object.keys(obj).length === 0;
  }

  return false;
}
