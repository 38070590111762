export function fromPairs<T>(arr: [string, T][]) {
  const obj: Record<string, T> = {};

  for (const [key, value] of arr) {
    obj[key] = value;
  }
  return obj;
}

export function last<T>(
  arr: T,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
): T extends readonly [...infer _rest, infer L]
  ? L
  : T extends never[]
  ? undefined
  : T extends unknown[]
  ? T[number]
  : undefined {
  return Array.isArray(arr) && arr.length ? arr[arr.length - 1] : undefined;
}

type NonNull = string | number | boolean | symbol | bigint | object;
export function compact<T = NonNull>(arr: (T | null | undefined)[]): T[] {
  return arr.filter((it) => it !== null && it !== undefined) as T[];
}

export function first<T>(
  arr: T,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
): T extends readonly [infer F, ...infer _rest]
  ? F
  : T extends never[]
  ? undefined
  : T extends unknown[]
  ? T[0]
  : undefined {
  return Array.isArray(arr) && arr.length ? arr[0] : undefined;
}

type Arrify<T> = T extends null | undefined ? [] : T extends readonly unknown[] ? T : [T];
export function arrify<T>(arr: T | undefined | null): Arrify<T> {
  if (arr === undefined || arr === null) return <Arrify<T>>[];

  return Array.isArray(arr) ? <Arrify<T>>arr : <Arrify<T>>[arr];
}

type RemoveDuplicates<T> = T extends unknown[] ? T : [];
export function removeDuplicates<T>(arr: T): RemoveDuplicates<T> {
  if (!arr || !Array.isArray(arr) || !arr.length) return <RemoveDuplicates<T>>[];

  return <RemoveDuplicates<T>>[...new Set(arr)];
}

type PartitionArrayReturnType<T> = [T[], T[]];
export function partitionArray<T>(
  arr: T[],
  validate: (item: T) => boolean,
): PartitionArrayReturnType<T> {
  return arr.reduce(
    ([left, right]: PartitionArrayReturnType<T>, item: T): PartitionArrayReturnType<T> =>
      validate(item) ? [[...left, item], right] : [left, [...right, item]],
    [[], []],
  );
}

export function some<T>(arr: T[], predicate: (value: T) => boolean): boolean {
  return arr.some((value: T) => predicate(value));
}

export function times<T>(fn: (i: number) => T, nb: number): T[] {
  return Array.from({ length: nb }, (_, index) => fn(index));
}

export function areStringArrayContentSimilar(
  array1: (string | undefined)[],
  array2: (string | undefined)[],
) {
  const noDiff1 = array1.filter((i) => !array2.includes(i)).length === 0;
  const noDiff2 = array2.filter((i) => !array1.includes(i)).length === 0;

  return noDiff1 && noDiff2;
}

export function flatten<T>(arr: T[][]): T[] {
  const res = [];
  for (const v of arr) {
    res.push(...v);
  }
  return res;
}
