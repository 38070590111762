import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { NOTIFICATION_WINDOW_HEIGHT, NOTIFICATION_WINDOW_WIDTH } from '@launcher/helpers/desktop';
import { useInterval } from '@launcher/hooks';
import { isDesktopApp as isDesktopAppUtil } from '@launcher/utils';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { logger } from '@services/logger/logger.service';
import {
  EXTRA_ROOM,
  NOTIFICATION_CONTAINER_ID,
  NOTIFICATION_LOADED_MESSAGE,
  NOTIFICATION_SHOW_MAIN,
} from '@services/notificationWindow';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { DocumentJobError, FlowType } from '@honestica/core-apps-common/types';
import {
  MAX_FILE_SIZE,
  PDF_PAGES_LIMIT,
  PDF_PAGES_LIMIT_INTEGRATIONS,
} from '@honestica/lifen-pdf-viewer/src/utilsLib/constants';
import { Button, Icon, Space, Spin, Typography, colors } from '@honestica/ui-kit/src';

import { NotificationActions } from '@store/notificationWindow/notificationWindow.action';
import {
  NotificationJobError,
  NotificationJobSuccess,
} from '@store/notificationWindow/notificationWindow.state';

import {
  NotificationWindowReduxProps,
  notificationWindowConnector,
} from './notificationWindow.connector';

import styles from './NotificationWindow.module.less';

function preventDestroyWindowOnClose(reset: () => void) {
  /* Window is hidden instead of being
import { NOTIFICATION_SHOW_MAIN, NOTIFICATION_CONTAINER_ID, EXTRA_ROOM, NOTIFICATION_LOADED_MESSAGE } from '@services/notificationWindow';
     destroyed on close button click */
  window.onbeforeunload = (e: Event) => {
    e.returnValue = false;
    resetAndHideWindow(reset);
  };
}

let mainWindowId: number | undefined;

function resetAndHideWindow(
  reset: () => void,
  options?: { focusMainWindow: boolean; flowType: FlowType },
) {
  reset();
  /* Ask the main window to come back to focus */
  if (options?.focusMainWindow && mainWindowId) {
    window.electron
      .getIpcRenderer()
      .sendTo(mainWindowId, `${NOTIFICATION_SHOW_MAIN}/${options?.flowType}`);
  }
  window.electron.hideCurrentWindow();
}

function resizeWindowBasedOnContent() {
  const mutationObserver = new MutationObserver((mutations) => {
    mutations.forEach(() => {
      const width =
        document.getElementById(NOTIFICATION_CONTAINER_ID)?.scrollWidth ||
        NOTIFICATION_WINDOW_WIDTH;
      const height =
        (document.getElementById(NOTIFICATION_CONTAINER_ID)?.scrollHeight ||
          NOTIFICATION_WINDOW_HEIGHT) + EXTRA_ROOM;
      window.electron.resizeCurrentWindow(width, height);
    });
  });
  mutationObserver.observe(document.documentElement, {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
    attributeOldValue: true,
    characterDataOldValue: true,
  });
}

/* We wait for an initial message from the main window
   to get a reference for its sender id */
function listenForInitialMessageFromMain() {
  const ipcRenderer = window.electron.getIpcRenderer();
  ipcRenderer.on(NOTIFICATION_LOADED_MESSAGE, (e: any) => {
    ipcRenderer.sendTo(e.senderId, NOTIFICATION_LOADED_MESSAGE);
    mainWindowId = e.senderId;
  });
}

const NotificationWrapper = ({
  children,
  isDesktopApp,
}: {
  children: ReactNode;
  isDesktopApp: boolean;
}) => (
  <div
    className={cx(styles.notificationWrapper, { [styles.notificationWrapperWeb]: !isDesktopApp })}
    id={NOTIFICATION_CONTAINER_ID}
    data-testid={NOTIFICATION_CONTAINER_ID}
  >
    {children}
  </div>
);

const getAmountOfErrorsByCode = (errors: NotificationJobError[]) => {
  const errorCodes: DocumentJobError[] = [];
  errors.forEach((err) => errorCodes.push(...err.errors));
  const errorsByCode: Record<string, number> = {};
  Object.values(DocumentJobError).forEach((value) => {
    const amount = errorCodes.filter((err) => err === value).length;
    if (amount < 1) return;
    errorsByCode[value] = amount;
  });
  return errorsByCode;
};

const Errors = ({
  errors,
  handleSeeErrors,
  isDesktopApp,
}: {
  errors: NotificationJobError[];
  handleSeeErrors: () => void;
  isDesktopApp: boolean;
}) => {
  const { t } = useTranslation();
  const errorsByCode = useMemo(() => getAmountOfErrorsByCode(errors), [errors]);
  const isLifenIntegration = errors[0]?.flowType === 'integration';
  return (
    <Space size="large" align="start">
      <Icon name="CrossCircle" color={colors.red[5]} />
      <Space direction="vertical">
        <Typography.Text strong>
          {t('documents.notification.error.title', { count: errors.length })}
        </Typography.Text>
        {Object.keys(errorsByCode).map((error, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Typography.Text type="secondary" key={String(i)}>
            {t(`documents.notification.error.${error}`, {
              count: errorsByCode[error],
              fileSize: MAX_FILE_SIZE,
              maxPages: isLifenIntegration ? PDF_PAGES_LIMIT_INTEGRATIONS : PDF_PAGES_LIMIT,
            })}
          </Typography.Text>
        ))}
        {isDesktopApp && errors[0].uploadSource === 'watcher' && (
          <Button type="primary" onClick={handleSeeErrors} className={styles.fullWidthButton}>
            {t('documents.notification.seeErrors')}
          </Button>
        )}
      </Space>
    </Space>
  );
};

const Success = ({
  isDesktopApp,
  success,
  handleFinalize,
  resetNotification,
}: {
  isDesktopApp: boolean;
  success: NotificationJobSuccess[];
  handleFinalize: () => void;
  resetNotification: () => void;
}) => {
  const { t } = useTranslation();
  const [count, setCount] = useState<number>(5);

  useInterval(() => {
    setCount(count - 1);
    if (count === 1) {
      if (isDesktopApp) {
        resetNotification();
        window.electron.hideCurrentWindow();
      } else {
        handleFinalize();
      }
    }
  }, 1000);

  return (
    <Space size="large" align="start">
      <Icon name="CheckCircle" color={colors.green[5]} />
      <Space direction="vertical">
        <Typography.Text strong>
          {t('documents.notification.success', { count: success.length })}
        </Typography.Text>
        <Typography.Text type="secondary">
          {t('documents.notification.countdown', { count })}
        </Typography.Text>
        <Button type="primary" className={styles.fullWidthButton} onClick={handleFinalize}>
          {t('documents.notification.finalize.title')}
        </Button>
      </Space>
    </Space>
  );
};

const SuccessErrors = ({
  success,
  errors,
  handleFinalize,
  handleSeeErrors,
  isDesktopApp,
}: {
  success: NotificationJobSuccess[];
  errors: NotificationJobError[];
  handleFinalize: () => void;
  handleSeeErrors: () => void;
  isDesktopApp: boolean;
}) => {
  const { t } = useTranslation();
  const errorsByCode = useMemo(() => getAmountOfErrorsByCode(errors), [errors]);
  const isLifenIntegration = errors[0]?.flowType === 'integration';
  return (
    <Space direction="vertical" size="large">
      <Space size="large" align="start">
        <Icon name="CheckCircle" color={colors.green[5]} />
        <Typography.Text strong>
          {t('documents.notification.success', { count: success.length })}
        </Typography.Text>
      </Space>
      <Space size="large" align="start">
        <Icon name="CrossCircle" color={colors.red[5]} />
        <Space direction="vertical">
          <Typography.Text strong>
            {t('documents.notification.error.title', { count: errors.length })}
          </Typography.Text>
          {Object.keys(errorsByCode).map((error, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Typography.Text type="secondary" key={String(i)}>
              {t(`documents.notification.error.${error}`, {
                count: errorsByCode[error],
                fileSize: MAX_FILE_SIZE,
                maxPages: isLifenIntegration ? PDF_PAGES_LIMIT_INTEGRATIONS : PDF_PAGES_LIMIT,
              })}
            </Typography.Text>
          ))}
          <Space className={styles.fullWidthButton}>
            <Button type="primary" onClick={handleFinalize}>
              {t('documents.notification.finalize.short')}
            </Button>
            {isDesktopApp && errors[0].uploadSource === 'watcher' && (
              <Button onClick={handleSeeErrors}>{t('documents.notification.seeErrors')}</Button>
            )}
          </Space>
        </Space>
      </Space>
    </Space>
  );
};

const Pending = ({ pending }: { pending: number }) => {
  const { t } = useTranslation();
  return (
    <Space size="large" align="start">
      <Spin />
      <Space direction="vertical">
        <Typography.Text strong>{t('pleaseWait')}</Typography.Text>
        <Typography.Text type="secondary">
          {t('documents.notification.pending', { count: pending })}
        </Typography.Text>
      </Space>
    </Space>
  );
};

const Notification = (props: NotificationWindowReduxProps) => {
  const isDesktopApp = useMemo(() => isDesktopAppUtil(), []);

  useEffect(() => {
    if (isDesktopAppUtil()) {
      /**
       * Listens to IPC messages and dispatches
       * actions inside notification window
       */

      wireListenerToState([
        [NotificationActions.addPending.type, props.notificationAddPending],
        [NotificationActions.addSuccess.type, props.notificationAddSuccess],
        [NotificationActions.addError.type, props.notificationAddError],
      ]);

      preventDestroyWindowOnClose(props.resetNotification);
      resizeWindowBasedOnContent();
      listenForInitialMessageFromMain();
    }
  }, [
    props.notificationAddError,
    props.notificationAddPending,
    props.notificationAddSuccess,
    props.resetNotification,
  ]);

  const hasErrors = props.errors.length > 0;
  const hasSuccess = props.success.length > 0;
  const hasOnlyErrors = hasErrors && !hasSuccess;
  const hasOnlySuccess = hasSuccess && !hasErrors;

  const handleSeeErrors = useCallback(() => {
    if (isDesktopAppUtil()) {
      const firstError = props.errors[0];
      props.showOutboxErrorFolder({ type: firstError.flowType, fileName: firstError.fileName });
      resetAndHideWindow(props.resetNotification);
    } else {
      props.resetNotification();
      props.closeModalFromStore();
    }
  }, [props]);

  const handleFinalize = useCallback(() => {
    if (isDesktopAppUtil()) {
      resetAndHideWindow(props.resetNotification, {
        focusMainWindow: true,
        flowType: props.success[0]?.flowType,
      });
    } else {
      props.resetNotification();
      props.closeModalFromStore();
    }
  }, [props]);

  if (props.pending > 0) {
    return (
      <NotificationWrapper isDesktopApp={isDesktopApp}>
        <Pending pending={props.pending} />
      </NotificationWrapper>
    );
  }

  if (hasOnlySuccess) {
    return (
      <NotificationWrapper isDesktopApp={isDesktopApp}>
        <Success
          isDesktopApp={isDesktopApp}
          success={props.success}
          handleFinalize={handleFinalize}
          resetNotification={props.resetNotification}
        />
      </NotificationWrapper>
    );
  }

  if (hasOnlyErrors) {
    return (
      <NotificationWrapper isDesktopApp={isDesktopApp}>
        <Errors
          errors={props.errors}
          handleSeeErrors={handleSeeErrors}
          isDesktopApp={isDesktopApp}
        />
      </NotificationWrapper>
    );
  }

  if (hasErrors && hasSuccess) {
    return (
      <NotificationWrapper isDesktopApp={isDesktopApp}>
        <SuccessErrors
          isDesktopApp={isDesktopApp}
          errors={props.errors}
          success={props.success}
          handleSeeErrors={handleSeeErrors}
          handleFinalize={handleFinalize}
        />
      </NotificationWrapper>
    );
  }
  return <div data-testid="notification-empty" />;
};

export const NotificationWindow = notificationWindowConnector(Notification);

function wireListenerToState(items: [string, ActionCreatorWithPayload<any>][]) {
  for (const [type, actionCreator] of items) {
    window.electron.getIpcRenderer().on(type, (_e: any, payload?: any) => {
      try {
        actionCreator(payload);
      } catch (err) {
        logger.warn('DESKTOP', `${type} could not be performed inside notification window`);
      }
    });
  }
}
