import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { addGenericErrorMessage, removeGenericErrorMessage } from '@store/errors/errors.action';

export function useOnOffline(callback: () => void) {
  useEffect(() => {
    window.addEventListener('offline', callback, { passive: true });
    return () => window.removeEventListener('offline', callback);
  }, [callback]);
}

export function useOnOnline(callback: () => void) {
  useEffect(() => {
    window.addEventListener('online', callback, { passive: true });
    return () => window.removeEventListener('online', callback);
  }, [callback]);
}

export function useNotifyNetworkOffline() {
  const dispatch = useDispatch();

  useOnOffline(() => {
    dispatch(addGenericErrorMessage('network'));
  });

  useOnOnline(() => dispatch(removeGenericErrorMessage()));
}
