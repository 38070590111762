export enum MedicalExamType {
  ECG = '04.01.01.01', // ECG
  EchographieCoeur = '04.01.03.01', // Echographie du coeur
  EpreuveEffort = '04.01.08', // Epreuve d'effort
  Holter = '04.01.02.02', // Holter
  Electromyographie = '01.01.01.01', // Électromyographie
  Electroencephalographie = '01.01.01.04', // Électroencéphalographie
  PotentielsEvoquesSomesthesiques = '01.01.09.03', // Potentiels évoqués somesthésiques
  PotentielsEvoquesVisuels = '01.01.09.01', //  Potentiels évoqués visuels
  ExplorationFonctionnelleDeLaRespiration = '06.01.07.04', // Exploration fonctionnelle de la respiration
}
