import { logger } from '@services/logger/logger.service';
import { all, put, takeEvery } from 'redux-saga/effects';

import {
  List,
  Patient,
  PatientsImportJobState,
  RollbackPatientsImportJobState,
} from '@honestica/core-apps-common/types';
import { isProcessed } from '@honestica/core-apps-common/utils';

import {
  destroyPatients,
  getPatient,
  getPatients,
  getUserLatestPatientJobState,
  postPatient,
  postPatientsImportJob,
  putPatient,
  putPatientsImportJob,
} from '@api';
import { openDrawerFromStore } from '@store/drawer/drawer.action';
import { openModalFromStore } from '@store/modal/modal.action';
import { ModalType } from '@store/modal/modal.type';

import {
  CreatePatient,
  CreatePatientSuccess,
  DeletePatients,
  EnqueuePatientsImportJob,
  EnqueueRollbackPatientsImportJob,
  FindPatient,
  FindPatients,
  UpdatePatient,
  UpdatePatientJobState,
  UpdatePatientSuccess,
  createPatient,
  createPatientFailure,
  createPatientSuccess,
  deletePatients,
  deletePatientsFailure,
  deletePatientsSuccess,
  enqueuePatientsImportJob,
  enqueuePatientsImportJobFailure,
  enqueuePatientsImportJobSuccess,
  enqueueRollbackPatientsImportJob,
  enqueueRollbackPatientsImportJobFailure,
  enqueueRollbackPatientsImportJobSuccess,
  fetchUserLatestPatientJobState,
  fetchUserLatestPatientJobStateFailure,
  fetchUserLatestPatientJobStateSuccess,
  findPatient,
  findPatientFailure,
  findPatientSuccess,
  findPatients,
  findPatientsFailure,
  findPatientsSuccess,
  updatePatient,
  updatePatientFailure,
  updatePatientJobState,
  updatePatientJobStateFailure,
  updatePatientJobStateSuccess,
  updatePatientSuccess,
} from './patientDirectory.action';

// CRUD
export function* createPatientAsync({ payload: patient }: ReturnType<CreatePatient>) {
  try {
    const createdPatient: Patient = yield postPatient(patient);
    yield put(createPatientSuccess(createdPatient));
  } catch (error) {
    logger.error('LOGIC', 'Failed to create patient', error);
    yield put(createPatientFailure(error));
  }
}

export function* findPatientAsync({ payload: patientId }: ReturnType<FindPatient>) {
  try {
    const patient: Patient = yield getPatient(patientId);
    yield put(findPatientSuccess(patient));
  } catch (error) {
    logger.error('LOGIC', 'Failed to find patient', error);
    yield put(findPatientFailure(error));
  }
}

export function* findPatientsAsync({ payload: searchParams }: ReturnType<FindPatients>) {
  try {
    const patients: List<Patient> = yield getPatients(searchParams);

    yield put(findPatientsSuccess(patients));
  } catch (error) {
    logger.error('LOGIC', 'Failed to find patients', error);
    yield put(findPatientsFailure(error));
  }
}

export function* updatePatientAsync({ payload: patient }: ReturnType<UpdatePatient>) {
  try {
    const updatedPatient: Patient = yield putPatient(patient);

    yield put(updatePatientSuccess(updatedPatient));
  } catch (error) {
    logger.error('LOGIC', 'Failed to update patient', error);
    yield put(updatePatientFailure(error));
  }
}

export function* deletePatientsAsync({ payload: { patientIds } }: ReturnType<DeletePatients>) {
  try {
    const deletedPatient: Patient = yield destroyPatients(patientIds.map((id) => id.toString()));

    yield put(deletePatientsSuccess(deletedPatient));
  } catch (error) {
    logger.error('LOGIC', 'Failed to delete patients', error);
    yield put(deletePatientsFailure(error));
  }
}

export function* patientDrawerRedirect({
  payload: patient,
}: ReturnType<CreatePatientSuccess> | ReturnType<UpdatePatientSuccess>) {
  yield put(openDrawerFromStore({ drawer: 'patient-detail', patientId: patient.id }));
}

// Jobs
export function* enqueuePatientsImportJobAsync({ payload }: ReturnType<EnqueuePatientsImportJob>) {
  try {
    const job: PatientsImportJobState = yield postPatientsImportJob(payload);
    yield put(enqueuePatientsImportJobSuccess(job));
    yield put(openModalFromStore({ modal: ModalType.patientsImportJobMonitor }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to enqueue patients import job', error);
    yield put(enqueuePatientsImportJobFailure(error));
  }
}

export function* enqueueRollbackPatientsImportJobAsync({
  payload,
}: ReturnType<EnqueueRollbackPatientsImportJob>) {
  try {
    const job: RollbackPatientsImportJobState = yield putPatientsImportJob(payload);
    yield put(enqueueRollbackPatientsImportJobSuccess(job));
    yield put(openModalFromStore({ modal: ModalType.cancelPatientsImportJobMonitor }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to enqueue patients import job rollback', error);
    yield put(enqueueRollbackPatientsImportJobFailure(error));
  }
}

export function* updatePatientJobStateAsync({ payload: job }: ReturnType<UpdatePatientJobState>) {
  try {
    yield put(updatePatientJobStateSuccess(job));

    if (isProcessed(job)) {
      yield put(findPatients({}));
    }
  } catch (error) {
    logger.error('LOGIC', 'Failed to update patient job state', error);
    yield put(updatePatientJobStateFailure(error));
  }
}

export function* fetchUserLatestPatientJobStateAsync() {
  try {
    const job: PatientsImportJobState = yield getUserLatestPatientJobState();

    yield put(fetchUserLatestPatientJobStateSuccess(job));
    yield put(updatePatientJobState(job));
  } catch (error) {
    logger.error('LOGIC', "Failed to fetch users's latest patient job state", error);
    yield put(fetchUserLatestPatientJobStateFailure(error));
  }
}

export function* patientDirectorySagas() {
  yield all([
    takeEvery(enqueuePatientsImportJob.type, enqueuePatientsImportJobAsync),
    takeEvery(enqueueRollbackPatientsImportJob.type, enqueueRollbackPatientsImportJobAsync),
    takeEvery(fetchUserLatestPatientJobState.type, fetchUserLatestPatientJobStateAsync),
    takeEvery(updatePatientJobState.type, updatePatientJobStateAsync),
    takeEvery(createPatient.type, createPatientAsync),
    takeEvery(findPatient.type, findPatientAsync),
    takeEvery(findPatients.type, findPatientsAsync),
    takeEvery(updatePatient.type, updatePatientAsync),
    takeEvery(deletePatients.type, deletePatientsAsync),
    takeEvery(updatePatientSuccess.type, patientDrawerRedirect),
    takeEvery(createPatientSuccess.type, patientDrawerRedirect),
  ]);
}
