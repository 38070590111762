import { ConnectedProps, connect } from 'react-redux';

import { DesktopActions } from '@store/desktop/desktop.action';
import { selectDesktopState } from '@store/desktop/desktop.selector';
import { State } from '@store/reducer';

export type DesktopSettingsReduxProps = ConnectedProps<typeof desktopSettingsConnector>;

const mapStateToProps = (state: State) => {
  const desktop = selectDesktopState(state);
  return {
    immutableSettings: desktop.immutableSettings,
    settings: desktop.settings,
  };
};

export const desktopSettingsConnector = connect(mapStateToProps, {
  updateDesktopSettings: DesktopActions.updateSettings,
});
