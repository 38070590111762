import { http } from '@services/http';

// Custom client is used to override axios default client and avoid a useless dependency
export const customClient = async <T>({
  url,
  method,
  params,
  data,
}: {
  url: string;
  method: 'get' | 'post' | 'put' | 'delete' | 'patch';
  params?: any;
  data?: BodyType<unknown>;
  responseType?: string;
}): Promise<T> => {
  switch (method) {
    case 'get':
      return await http.get({ path: url, searchParams: params, version: 'v2' });
    case 'post':
      return await http.post({ path: url, body: data, version: 'v2' });
    case 'put':
      return await http.put({ path: url, body: data, version: 'v2' });
    case 'delete':
      return await http.destroy({ path: url, version: 'v2' });
    default:
      throw new Error(`Method ${method} is not managed by custom client.`);
  }
};

export default customClient;

export type ErrorType<ErrorData> = ErrorData;

export type BodyType<BodyData> = BodyData & { headers?: any };
