export enum TelecomType {
  PHONE = 'phone',
  LANDLINE = 'landlinePhone',
  APICRYPT = 'apicrypt',
  MSSANTE = 'mssante',
  EMAIL = 'email',
  LIFEN = 'lifen',
  ENS = 'ens',
}

export interface Telecom {
  id: string | undefined;
  value: string;
  type: TelecomType;
  displayName?: string;
  isContactPreference: boolean;
}

export type Telecoms = { [p in TelecomType]: Telecom[] };
