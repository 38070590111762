import { FetchAttachmentsSuccessAction } from '@store/documents/documents.actions';
import { DocumentsStateWithDetailView, XHR_STATUS_IDLE } from '@store/documents/documents.state';

export function updateDocumentAttachments<T extends DocumentsStateWithDetailView>(
  state: T,
  action: FetchAttachmentsSuccessAction,
): T {
  return {
    ...state,
    entities: Object.entries(state.entities).reduce(
      (entities, [id, entity]) => ({
        ...entities,
        [id]: {
          ...entity,
          meta: {
            ...entity.meta,
            fetchAttachmentsRequest: XHR_STATUS_IDLE,
            fileStorageIds:
              id === action.payload.id
                ? action.payload.fileStorageIds
                : // When a document is displayed in the detailView, attachments from other documents are removed from the client memory,
                  // so we need to clean up the client fileStorageIds as well.
                  undefined,
          },
        },
      }),
      {} as T['entities'],
    ),
  };
}
