import React from 'react';

import { Page, Spinner } from '@honestica/ui-kit/src';

type SuspenseProp = {
  children: React.ReactNode;
};

export const Suspense = (props: SuspenseProp) => (
  <React.Suspense
    fallback={
      <Page.Full>
        <Spinner />
      </Page.Full>
    }
  >
    {props.children}
  </React.Suspense>
);
