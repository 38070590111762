import { createAction } from '@reduxjs/toolkit';

import { List, Organization, SearchParams } from '@honestica/core-apps-common/types';

const REDUCER_NAME = 'organizationDirectory';

const FIND_ORGANIZATIONS = `${REDUCER_NAME}/find`;

export const findOrganizations = createAction<SearchParams>(FIND_ORGANIZATIONS);
export const findOrganizationsSuccess = createAction<List<Organization>>(
  `${FIND_ORGANIZATIONS}Success`,
);
export const findOrganizationsFailure = createAction<unknown>(`${FIND_ORGANIZATIONS}Failure`);

export type FindOrganizations = typeof findOrganizations;
