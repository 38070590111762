import { createSlice } from '@reduxjs/toolkit';

import {
  BatchAction,
  BatchFailureAction,
  BatchSuccessAction,
  CreatePatientAction,
  DetailViewDocumentDtoAction,
  FailureAction,
  FetchDocumentAction,
  FetchDocumentSuccessAction,
  FetchDocumentsAndNavigateAction,
  GetDocumentAction,
  GetDocumentSuccessAction,
  OneAction,
  OneFailureAction,
  OneSuccessAction,
  SearchPatientsAction,
  SearchPatientsSuccessAction,
  SwitchEncounterAction,
  SwitchEncounterSuccessAction,
  UpdateDocumentDatePeriodAction,
  UpdateDocumentTitleAction,
  UpdateIntegrationPatientSuccessAction,
  UpdateIntegrationRequestBundleSuccessAction,
  UpdateIntegrationRequestSuccessAction,
  UpdateIntegrationRequestTypeAction,
  UpdateMedicalExamTypeAction,
  UpdateOriginalSenderAction,
  UpdatePatientAction,
} from '@store/documents/documents.actions';

import {
  delayedDocumentIdToRefresh,
  purgeDocumentIdsToRefetch,
} from '../common/reducers/delayedDocumentsToRefresh.reducers';
import { generateDownloadActions } from '../common/reducers/downloadDocuments.reducers';
import { updateDocumentAttachments } from '../common/reducers/fetchAttachments.reducers';
import {
  fetchDocumentsSuccess,
  fetchManyFailure,
  fetchManyIntegration,
  fetchManySuccess,
  fetchOne,
  fetchOneFailure,
  fetchOneInDashboardSuccess,
  fetchOneSuccess,
  removeOneInDashboardSuccess,
  setDetailViewDocument,
  setDetailViewDocumentDto,
} from '../common/reducers/fetchDocuments.reducers';
import {
  updateAndDropManySuccess,
  updateDocumentAndMeta,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateOne,
  updateOneFailure,
  updateOneSuccess,
  updateOneTimeout,
  updateOneTimeoutFailure,
  updateOneTimeoutSuccess,
} from '../common/reducers/updateMeta.reducers';
import { updateSearchParams } from '../common/reducers/updateSearchParams.reducers';

import {
  onRefreshPatientMatch,
  onSwitchEncounterSuccess,
} from './reducers/worklist.encounters.reducers';
import {
  onFlushPatientSearch,
  onSearchPatients,
  onSearchPatientsFailure,
  onSearchPatientsSuccess,
} from './reducers/worklist.patient.reducers';
import {
  INITIAL_WORKLIST_INTEGRATIONS_STATE,
  INITIAL_WORKLIST_INTEGRATION_DOCUMENT_METADATA,
  WorklistIntegrationRequestMetadataKey,
} from './worklist.state';

const slice = createSlice({
  name: 'worklistIntegrations',
  initialState: INITIAL_WORKLIST_INTEGRATIONS_STATE,
  reducers: {
    /*
     * DASHBOARD
     */

    /*
     * View
     */

    fetchMany: fetchManyIntegration,
    fetchManySuccess: (state, _) =>
      fetchManySuccess(state, _, INITIAL_WORKLIST_INTEGRATION_DOCUMENT_METADATA),
    fetchManyFailure,
    fetchDocumentsSuccess: (state, _) =>
      fetchDocumentsSuccess(state, _, INITIAL_WORKLIST_INTEGRATION_DOCUMENT_METADATA),

    updateSearchParams,

    /*
     * Entities
     */

    cancelMany: (state, _: BatchAction) =>
      updateMany(state, _, WorklistIntegrationRequestMetadataKey.cancelDocument),
    cancelManySuccess: (state, _: BatchSuccessAction) =>
      updateManySuccess(state, _, WorklistIntegrationRequestMetadataKey.cancelDocument),
    cancelManyFailure: (state, _: BatchFailureAction) =>
      updateFailure(state, _, WorklistIntegrationRequestMetadataKey.cancelDocument),

    integrateMany: (state, _: BatchAction) =>
      updateMany(state, _, WorklistIntegrationRequestMetadataKey.integrateDocument),
    integrateManySuccess: (state, _: BatchSuccessAction) =>
      updateAndDropManySuccess(state, _, WorklistIntegrationRequestMetadataKey.integrateDocument),
    integrateManyFailure: (state, _: BatchFailureAction) =>
      updateFailure(state, _, WorklistIntegrationRequestMetadataKey.integrateDocument),

    duplicateMany: (state, action: BatchAction) =>
      updateMany(state, action, WorklistIntegrationRequestMetadataKey.duplicateDocument),
    duplicateManySuccess: (state, action: BatchSuccessAction) =>
      updateManySuccess(state, action, WorklistIntegrationRequestMetadataKey.duplicateDocument),
    duplicateManyFailure: (state, action: BatchFailureAction) =>
      updateFailure(state, action, WorklistIntegrationRequestMetadataKey.duplicateDocument),

    delayedDocumentIdToRefresh,
    purgeDocumentIdsToRefetch,

    /*
     * DETAIL
     */

    /*
     * View
     */

    getOne: (state, _action: GetDocumentAction) => state,
    setDetailViewDocument: (state, action: GetDocumentSuccessAction) =>
      setDetailViewDocument(state, action, INITIAL_WORKLIST_INTEGRATIONS_STATE.detailView),
    setDetailViewDocumentDto: (state, action: DetailViewDocumentDtoAction) =>
      setDetailViewDocumentDto(
        state,
        action,
        INITIAL_WORKLIST_INTEGRATIONS_STATE.detailView,
        INITIAL_WORKLIST_INTEGRATION_DOCUMENT_METADATA,
      ),

    fetchOne,
    fetchOneWithIntegrationData: (state, action: FetchDocumentAction) =>
      updateOne(state, action, WorklistIntegrationRequestMetadataKey.fetchAttachmentsRequest), // to update detail integration data + attachments of document that have integration data (use the same saga but a different reducer)
    fetchOneSuccess: (state, action: FetchDocumentSuccessAction) =>
      fetchOneSuccess(state, action, INITIAL_WORKLIST_INTEGRATION_DOCUMENT_METADATA), // updates entities
    fetchOneFailure,
    fetchOneInDashboardSuccess: (state, action: FetchDocumentSuccessAction) =>
      fetchOneInDashboardSuccess(state, action, INITIAL_WORKLIST_INTEGRATION_DOCUMENT_METADATA),
    removeOneInDashboardSuccess: (state, action: FetchDocumentAction) =>
      removeOneInDashboardSuccess(state, action),

    fetchManyAttachments: (state, action: OneAction) =>
      updateOne(state, action, WorklistIntegrationRequestMetadataKey.fetchAttachmentsRequest),
    fetchManyAttachmentsSuccess: updateDocumentAttachments,
    fetchManyAttachmentsFailure: (state, action: OneFailureAction) =>
      updateOneFailure(
        state,
        action,
        WorklistIntegrationRequestMetadataKey.fetchAttachmentsRequest,
      ),
    fetchManyAndNavigate: (state, _action: FetchDocumentsAndNavigateAction) => state,

    /*
     * Entities
     */

    cancelOne: (state, action: OneAction) =>
      updateOne(state, action, WorklistIntegrationRequestMetadataKey.cancelDocument),
    cancelOneSuccess: (state, action: OneSuccessAction) =>
      updateOneSuccess(state, action, WorklistIntegrationRequestMetadataKey.cancelDocument),
    cancelOneFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, WorklistIntegrationRequestMetadataKey.cancelDocument),

    integrateOne: (state, action: OneAction) =>
      updateOne(state, action, WorklistIntegrationRequestMetadataKey.integrateDocument),
    integrateOneSuccess: (state, action: OneSuccessAction) =>
      updateOneSuccess(state, action, WorklistIntegrationRequestMetadataKey.integrateDocument),
    integrateOneFailure: (state, action: OneFailureAction) =>
      updateFailure(state, action, WorklistIntegrationRequestMetadataKey.integrateDocument),

    updateDocumentType: (state, action: UpdateIntegrationRequestTypeAction) =>
      updateOne(state, action, WorklistIntegrationRequestMetadataKey.updateDocType),
    updateDocumentTypeSuccess: (state, action: UpdateIntegrationRequestSuccessAction) =>
      updateDocumentAndMeta(
        state,
        action.payload.document,
        WorklistIntegrationRequestMetadataKey.updateDocType,
      ),
    updateDocumentTypeFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, WorklistIntegrationRequestMetadataKey.updateDocType),

    updateDocumentDatePeriod: (state, action: UpdateDocumentDatePeriodAction) =>
      updateOne(state, action, WorklistIntegrationRequestMetadataKey.updatePeriodDate),
    updateIntegrationRequestDatePeriodSuccess: (
      state,
      action: UpdateIntegrationRequestSuccessAction,
    ) =>
      updateDocumentAndMeta(
        state,
        action.payload.document,
        WorklistIntegrationRequestMetadataKey.updatePeriodDate,
      ),
    updateDocumentDatePeriodFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, WorklistIntegrationRequestMetadataKey.updatePeriodDate),

    addDocumentPatient: (state, action: UpdatePatientAction) =>
      updateOne(state, action, WorklistIntegrationRequestMetadataKey.updatePatient),
    createDocumentPatient: (state, action: CreatePatientAction) =>
      updateOne(state, action, WorklistIntegrationRequestMetadataKey.updatePatient),

    addDocumentPatientSuccess: (state, action: UpdateIntegrationRequestBundleSuccessAction) =>
      updateDocumentAndMeta(
        state,
        action.payload.bundle.document,
        WorklistIntegrationRequestMetadataKey.updatePatient,
      ),
    addDocumentPatientFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, WorklistIntegrationRequestMetadataKey.updatePatient), // updates entities

    updateDocumentPatient: (state, action: UpdatePatientAction) =>
      updateOne(state, action, WorklistIntegrationRequestMetadataKey.updatePatient),
    updateDocumentPatientSuccess: (state, action: UpdateIntegrationPatientSuccessAction) =>
      action.payload.integrationRequest
        ? updateDocumentAndMeta(
            state,
            action.payload.integrationRequest,
            WorklistIntegrationRequestMetadataKey.updatePatient,
          )
        : updateOneSuccess(state, action, WorklistIntegrationRequestMetadataKey.updatePatient), // updates entities
    updateDocumentPatientFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, WorklistIntegrationRequestMetadataKey.updatePatient),

    removeDocumentPatient: (state, action: OneAction) =>
      updateOne(state, action, WorklistIntegrationRequestMetadataKey.removePatient),
    removeDocumentPatientSuccess: (state, action: UpdateIntegrationRequestBundleSuccessAction) =>
      updateDocumentAndMeta(
        state,
        action.payload.bundle.document,
        WorklistIntegrationRequestMetadataKey.removePatient,
      ),
    removeDocumentPatientFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, WorklistIntegrationRequestMetadataKey.removePatient),

    searchPatients: (state, _action: SearchPatientsAction) => onSearchPatients(state),
    searchPatientsSuccess: (state, action: SearchPatientsSuccessAction) =>
      onSearchPatientsSuccess(state, action),
    searchPatientsFailure: (state, action: FailureAction) => onSearchPatientsFailure(state, action),

    flushPatientSearch: (state) => onFlushPatientSearch(state),

    refreshPatientMatch: (state, action: OneAction) => onRefreshPatientMatch(state, action),
    refreshPatientMatchSuccess: (state, action: OneAction) =>
      updateOneTimeoutSuccess(
        state,
        action,
        WorklistIntegrationRequestMetadataKey.refreshPatientMatch,
      ),
    refreshPatientMatchTimeout: (state, action: OneAction) =>
      updateOneTimeout(state, action, WorklistIntegrationRequestMetadataKey.refreshPatientMatch),
    refreshPatientMatchFailure: (state, action: OneFailureAction) =>
      updateOneTimeoutFailure(
        state,
        action,
        WorklistIntegrationRequestMetadataKey.refreshPatientMatch,
      ),

    switchEncounter: (state, _action: SwitchEncounterAction) => state,
    switchEncounterSuccess: (state, action: SwitchEncounterSuccessAction) =>
      onSwitchEncounterSuccess(state, action),
    switchEncounterFailure: (state, _action: OneFailureAction) => state,

    updateMedicalExamType: (state, action: UpdateMedicalExamTypeAction) =>
      updateOne(state, action, WorklistIntegrationRequestMetadataKey.updateMedicalExamType),
    updateMedicalExamTypeSuccess: (state, action: UpdateIntegrationRequestSuccessAction) =>
      updateDocumentAndMeta(
        state,
        action.payload.document,
        WorklistIntegrationRequestMetadataKey.updateMedicalExamType,
      ),
    updateMedicalExamTypeFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, WorklistIntegrationRequestMetadataKey.updateMedicalExamType),

    updateOriginalSender: (state, action: UpdateOriginalSenderAction) =>
      updateOne(state, action, WorklistIntegrationRequestMetadataKey.updateOriginalSender),
    updateOriginalSenderSuccess: (state, action: UpdateIntegrationRequestSuccessAction) =>
      updateDocumentAndMeta(
        state,
        action.payload.document,
        WorklistIntegrationRequestMetadataKey.updateOriginalSender,
      ),
    updateOriginalSenderFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, WorklistIntegrationRequestMetadataKey.updateOriginalSender),
    updateDocumentTitle: (state, action: UpdateDocumentTitleAction) =>
      updateOne(state, action, WorklistIntegrationRequestMetadataKey.updateDocumentTitle),
    updateDocumentTitleSuccess: (state, action: UpdateIntegrationRequestSuccessAction) =>
      updateDocumentAndMeta(
        state,
        action.payload.document,
        WorklistIntegrationRequestMetadataKey.updateDocumentTitle,
      ),
    updateDocumentTitleFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, WorklistIntegrationRequestMetadataKey.updateDocumentTitle),
  },
});

export const integrationsWorklistReducer = slice.reducer;

export const WorklistIntegrationsActions = {
  ...slice.actions,
  ...generateDownloadActions(slice.name),
};
