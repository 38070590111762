import { logger } from '@services/logger/logger.service';

export type RouterStorage = {
  from: string;
  to: string;
};

type LocalStorageItems = {
  LIFEN_USER_EMAIL: string;
  WORKSPACE_ID: string;
  ROUTER: RouterStorage;
  LIFEN_APPS_USE_PRIVATE_URLS: boolean;
};

export type StorageKey = keyof LocalStorageItems;

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class LocalStorage {
  public static get<T extends StorageKey>(key: T): LocalStorageItems[T] | undefined {
    try {
      const value = localStorage.getItem(key);
      if (value !== null) {
        return JSON.parse(value);
      }
    } catch (err) {
      logger.warn('LOGIC', '[LocaleStorage]::getItem has thrown an error');
    }

    return undefined;
  }

  public static set(key: StorageKey, value: LocalStorageItems[StorageKey]): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch {
      logger.warn('LOGIC', '[LocaleStorage]::setItem has thrown an error');
    }
  }

  public static remove(key: StorageKey): void {
    try {
      localStorage.removeItem(key);
    } catch {
      logger.warn('LOGIC', '[LocaleStorage]::removeItem has thrown an error');
    }
  }
}
