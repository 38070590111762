export type FeatureFlagItems =
  | 'cafe-in-errors-dashboard'
  | 'enable-watchers-polling'
  | 'use-low-cpu-document-parsing'
  | 'cafe-maintenance';

export enum DesktopReleaseChannel {
  Alpha = 'alpha',
  Beta = 'beta',
  Stable = 'stable',
}

export type FeatureFlags = Partial<
  Record<FeatureFlagItems, boolean> & { 'desktop-release-channel': DesktopReleaseChannel }
>;
