import { logger } from '@services/logger/logger.service';
import { useTranslation } from 'react-i18next';

import { Sidebar } from '@honestica/ui-kit/src';

import { useZendeskChat } from '@hooks/useZendeskChat.hook';

import styles from './Chat.module.less';

export const Chat = () => {
  const { t } = useTranslation();
  const { openZendeskChat } = useZendeskChat();

  const openZendesk = () => {
    try {
      openZendeskChat();
    } catch (error) {
      logger.error('UI', 'Failed to display the Zendesk Chat', error);
    }
  };

  return (
    <Sidebar.Icon
      data-testid="sidebar-menu-chat"
      key="sidebar-menu-chat"
      iconName="ChatBubble"
      title={t('menuChat')}
      isSelected={false}
      className={styles.sidebarMenuChat}
      onClick={() => openZendesk()}
    />
  );
};
