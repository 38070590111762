import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { logger } from '@services/logger/logger.service';

import { Organization } from '@honestica/core-apps-common/types';

import {
  findOrganizations,
  findOrganizationsFailure,
  findOrganizationsSuccess,
} from './organizationDirectory.action';

export interface OrganizationDirectoryState {
  list: string[];
  total: number;
  loading: boolean;
  error: boolean;
}

const INITIAL_STATE: OrganizationDirectoryState = {
  list: [],
  total: 0,
  loading: false,
  error: false,
};

function listOrganizationsSuccess(
  state: OrganizationDirectoryState,
  action: PayloadAction<{ list: Organization[]; total: number }>,
) {
  const { payload } = action;

  if (!payload?.list || !Array.isArray(payload?.list)) {
    return state;
  }

  logger.info('STATE', 'listOrganizationsSuccess', {
    length: payload.list.length,
    total: payload.total,
  });

  return {
    error: false,
    list: payload.list.map((organization: Organization) => organization.id),
    total: payload.total,
    loading: false,
  };
}

function loading(state: OrganizationDirectoryState, status: boolean) {
  return {
    ...state,
    loading: status,
  };
}

function listOrganizationsFailure(state: OrganizationDirectoryState) {
  return {
    ...state,
    loading: false,
    error: true,
  };
}

export const organizationDirectoryReducer = createReducer(INITIAL_STATE, {
  [findOrganizations.type]: (state) => loading(state, true),
  [findOrganizationsSuccess.type]: listOrganizationsSuccess,
  [findOrganizationsFailure.type]: listOrganizationsFailure,
});
