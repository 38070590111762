import { PayloadAction } from '@reduxjs/toolkit';

import {
  DocumentJobError,
  DocumentJobStatus,
  SendingRequest,
  UploadDocumentJob,
} from '@honestica/core-apps-common/types';
import { RcFile } from '@honestica/ui-kit/src';

import { IntegrationsState } from '@store/integrations/integrations.state';
import {
  INITIAL_UPLOAD_DOCUMENTS_STATE,
  UploadDocumentsState,
} from '@store/uploadDocuments/uploadDocuments.state';

type UploadState = UploadDocumentsState | IntegrationsState;

type AddUploadJobPayload = UploadDocumentJob;

export type UploadJobFailurePayload = {
  job: UploadDocumentJob;
  errors: DocumentJobError[];
  error: unknown;
};

export type UploadJobProcessPayload = { job: UploadDocumentJob };
export type UploadJobSuccessPayload = { job: UploadDocumentJob; documentId: SendingRequest['id'] };

const onAddJob = (state: UploadState, action: PayloadAction<AddUploadJobPayload>) => {
  const newJobs = [...state.jobs, action.payload];
  return {
    ...state,
    jobs: newJobs,
  };
};

const onJobSuccess = (state: UploadState, action: PayloadAction<UploadJobSuccessPayload>) => {
  const newJobs = state.jobs.map((job) => {
    if (job.id !== action.payload.job.id) {
      return job;
    }
    return { ...job, status: DocumentJobStatus.Processed, documentId: action.payload.documentId };
  });
  return {
    ...state,
    jobs: newJobs,
  };
};

const onJobProcess = (state: UploadState, action: PayloadAction<UploadJobProcessPayload>) => {
  const newJobs = state.jobs.map((job) => {
    if (job.id !== action.payload.job.id) {
      return job;
    }
    return { ...job, status: DocumentJobStatus.Processing };
  });
  return {
    ...state,
    jobs: newJobs,
  };
};

const onJobFailure = (state: UploadState, action: PayloadAction<UploadJobFailurePayload>) => {
  const newJobs = state.jobs.map((job) => {
    if (job.id !== action.payload.job.id) return job;
    return { ...job, status: DocumentJobStatus.Failed, errors: action.payload.errors };
  });
  return {
    ...state,
    jobs: newJobs,
  };
};

const onReset = () => INITIAL_UPLOAD_DOCUMENTS_STATE;

export const uploadReducer = {
  addUploadJob: onAddJob,
  resetUploadJobs: onReset,
  jobUploadSuccess: onJobSuccess,
  jobUploadProcess: onJobProcess,
  jobUploadFailure: onJobFailure,
  uploadAllDocsFromWatchedFolder: (state: UploadState) => state,
  uploadFromButton: (state: UploadState, _action: PayloadAction<RcFile>) => state,
  uploadFromWatcher: (state: UploadState, _action: PayloadAction<string>) => state,
  uploadFromDnD: (state: UploadState, _action: PayloadAction<File[]>) => state,
  uploadFromDnDForbidden: (state: UploadState) => state,
};
