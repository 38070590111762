import React, { useState } from 'react';

import { Input, InputProps } from 'antd';

const reDisallowedChars = /[^0-9/-]/g;

// match DD/MM/YYYY + string rest
const reDDMMYYYY = /^(\d{2})?(\/)?(\d{2})?(\/)?(\d{4})?(.*)$/;

type Format = 'DD/MM/YYYY';

type DateElements = {
  day: string;
  month: string;
  year: string;
  s1: string;
  s2: string;
  rest: string;
};

export const getDDMMYYYY = (value: string, separator = '/'): DateElements => {
  const matches = reDDMMYYYY.exec(value);
  const day = matches?.[1] ? matches[1] : '';
  const month = matches?.[3] ? matches[3] : '';
  const year = matches?.[5] ? matches[5] : '';
  const rest = matches?.[6] ? matches[6] : '';
  const s1 = day && (rest || month) ? separator : '';
  const s2 = month && (rest || year) ? separator : '';
  return { day, month, year, s1, s2, rest };
};

export const applyMaskDDMMYYYY = (value: string) => {
  const { day, month, year, s1, s2, rest } = getDDMMYYYY(value);
  return `${day}${s1}${month}${s2}${year}${rest}`;
};

export const getFormat = (value: string): Format | null => {
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(value)) return 'DD/MM/YYYY';
  return null;
};

export const isValidDate = (maybeDate: Date): maybeDate is Date =>
  Object.prototype.toString.call(maybeDate) === '[object Date]' &&
  !Number.isNaN(maybeDate.getTime());

export const dateStrToUtcDate = (dateStr: string): Date | null => {
  const format = getFormat(dateStr.trim());
  if (!format) return null;

  const { day, month, year } = getDDMMYYYY(dateStr);

  const date = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day), 0, 0, 0, 0));

  return isValidDate(date) ? date : null;
};

/**
 * Convert a date to YYYY-MM-DD.
 */
export const toIsoDate = (value: string) => {
  const date = dateStrToUtcDate(value);
  return date ? date.toISOString().slice(0, 10) : '';
};

/**
 * Convert a date to DD/MM/YYYY.
 */
export const toDateDDMMYYYY = (value: string) => {
  const date = dateStrToUtcDate(value);
  return date ? date.toLocaleDateString('fr-FR') : '';
};

/**
 * Input with a mask for dates, either YYYY-MM-DD or DD/MM/YYYY.
 */
export const DateInput: React.FC<InputProps> = ({ defaultValue, onChange, ...otherProps }) => {
  const [componentValue, setComponentValue] = useState(toDateDDMMYYYY(String(defaultValue)));

  return (
    <Input
      {...otherProps}
      defaultValue={defaultValue}
      maxLength={10}
      value={componentValue}
      onChange={(e) => {
        const rawValue = e.target.value.replace(reDisallowedChars, '');
        setComponentValue(applyMaskDDMMYYYY(rawValue));
        if (onChange) onChange(e);
      }}
    />
  );
};
