import { HttpError } from '@services/http/http.error.service';

import {
  DashboardSearchParams,
  IntegrationsDashboardType,
} from '@honestica/core-apps-common/types';
import { isIntegrationBundle } from '@honestica/core-apps-common/validators';

import { ArchivedDocumentsState } from '@store/documents/incoming/archived.state';
import { DraftDocumentsState } from '@store/documents/outgoing/draft.state';
import { ErroredDocumentsState } from '@store/documents/outgoing/errored.state';
import { SentDocumentsState } from '@store/documents/outgoing/sent.state';

import {
  DetailViewDocumentDtoAction,
  FetchDocumentAction,
  FetchDocumentFailureAction,
  FetchDocumentSuccessAction,
  FetchDocumentsAction,
  FetchDocumentsFailureAction,
  FetchDocumentsFromCacheSuccessAction,
  FetchDocumentsSuccessAction,
  FetchIntegrationRequestsAction,
  FetchIntegrationRequestsSuccessAction,
  GetDocumentSuccessAction,
} from '../../documents.actions';
import {
  DetailViewState,
  DocumentMetadata,
  DocumentsState,
  DocumentsStateWithDetailView,
  INITIAL_DASHBOARD_VIEW_STATE,
  XHR_STATUS_ERROR,
  XHR_STATUS_IDLE,
  XHR_STATUS_LOADING,
} from '../../documents.state';

export function fetchMany<T extends DocumentsState>(state: T, action: FetchDocumentsAction): T {
  let searchParams: DashboardSearchParams;

  const DraftOrSent = state as DraftDocumentsState | SentDocumentsState;
  const SentOrArchived = state as ArchivedDocumentsState | SentDocumentsState;
  const DraftState = state as DraftDocumentsState;
  const ErrorState = state as ErroredDocumentsState;

  if (action.payload.clearSearch) {
    searchParams = {
      ...INITIAL_DASHBOARD_VIEW_STATE.searchParams,
      ...(typeof SentOrArchived.dashboardView.searchParams.defaultDateRange === 'boolean' && {
        defaultDateRange: true,
      }),
      ...(DraftOrSent.dashboardView.settingsOverrides && {
        hideOtherRequesters: DraftOrSent.dashboardView.settingsOverrides.myRequestOnly,
      }),
      ...(ErrorState.dashboardView.searchParams.hideOtherRequesters && {
        hideOtherRequesters: ErrorState.dashboardView.searchParams.hideOtherRequesters,
      }),
    };
  } else {
    searchParams = action.payload.search
      ? { ...state.dashboardView.searchParams, ...action.payload.search }
      : { ...state.dashboardView.searchParams };
  }

  let currentIdentity;

  if (action.payload.clearIdentity) {
    currentIdentity = undefined;
  } else if (action.payload.currentIdentity) {
    currentIdentity = action.payload.currentIdentity;
  } else {
    currentIdentity = DraftState.dashboardView.currentIdentity;
  }

  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      searchParams,
      documentsRequest: XHR_STATUS_LOADING,
      currentIdentity,
    },
  };
}

export function fetchManyIntegration<T extends DocumentsState>(
  state: T,
  action: FetchIntegrationRequestsAction,
): T {
  let searchParams: DashboardSearchParams;

  if (action.payload.clearSearch) {
    const dashboardType =
      action.payload.search?.dashboardType || IntegrationsDashboardType.Worklist;
    searchParams = {
      ...INITIAL_DASHBOARD_VIEW_STATE.searchParams,
      dashboardType,
    };
  } else {
    searchParams = action.payload.search
      ? { ...state.dashboardView.searchParams, ...action.payload.search }
      : state.dashboardView.searchParams;
  }

  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      searchParams,
      documentsRequest: XHR_STATUS_LOADING,
    },
  };
}

export function fetchManySuccess<T extends DocumentsState>(
  state: T,
  action: FetchDocumentsSuccessAction | FetchIntegrationRequestsSuccessAction,
  initialDocumentMetadata: DocumentMetadata,
): T {
  const { documents } = action.payload.entities;

  const newEntities = Object.fromEntries(
    documents.map((resource) => {
      const previous = state.entities[resource.id];

      if (previous) {
        return [
          resource.id,
          {
            resource,
            meta: previous.meta,
          },
        ];
      }
      return [resource.id, { resource, meta: initialDocumentMetadata }];
    }),
  );

  return {
    ...state,
    entities: { ...newEntities },
    dashboardView: {
      ...state.dashboardView,
      ids: documents.map((doc) => doc.id),
      total: action.payload.total,
      documentsRequest: XHR_STATUS_IDLE,
    },
  };
}

export function fetchManyFailure<T extends DocumentsState>(
  state: T,
  _action: FetchDocumentsFailureAction,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      documentsRequest: XHR_STATUS_ERROR,
    },
  };
}

// DETAIL

export function setDetailViewDocument<T extends DocumentsStateWithDetailView>(
  state: T,
  action: GetDocumentSuccessAction,
  initialDetailViewState: DetailViewState,
): T {
  const { id } = action.payload;

  return {
    ...state,
    detailView: {
      ...initialDetailViewState,
      selectedId: id,
      documentRequest: XHR_STATUS_IDLE,
    },
  };
}

export function setDetailViewDocumentDto<T extends DocumentsStateWithDetailView>(
  state: T,
  action: DetailViewDocumentDtoAction,
  initialDetailViewState: DetailViewState,
  initialDocumentMetadata: DocumentMetadata,
): T {
  let entity: any = state.entities[action.payload.id];

  if (!entity) {
    entity = {
      resource: action.payload,
      meta: {
        ...initialDocumentMetadata,
      },
    };
  }

  return {
    ...state,
    entities: {
      ...state.entities,
      [action.payload.id]: entity,
    },
    detailView: {
      ...initialDetailViewState,
      selectedId: action.payload.id,
      documentDto: { ...action.payload },
    },
  };
}

export function fetchOne<T extends DocumentsStateWithDetailView>(
  state: T,
  _action: FetchDocumentAction,
): T {
  return {
    ...state,
    detailView: {
      ...state.detailView,
      documentRequest: XHR_STATUS_LOADING,
    },
  };
}

export function fetchOneInDashboardSuccess<T extends DocumentsStateWithDetailView>(
  state: T,
  action: FetchDocumentSuccessAction,
  initialDocumentMetadata: DocumentMetadata,
): T {
  const { document } = action.payload.bundle;

  return {
    ...state,
    entities: {
      ...state.entities,
      [document.id]: {
        resource: document,
        meta: {
          ...initialDocumentMetadata,
        },
      },
    },
    dashboardView: {
      ...state.dashboardView,
      ids: [document.id, ...state.dashboardView.ids],
      total: state.dashboardView.total + 1,
      documentsRequest: XHR_STATUS_IDLE,
    },
  };
}

export function removeOneInDashboardSuccess<T extends DocumentsStateWithDetailView>(
  state: T,
  action: FetchDocumentAction,
): T {
  const { id } = action.payload;
  const entityIndex: number = state.dashboardView.ids.indexOf(id);
  if (entityIndex === -1) return state;
  const newEntities = { ...state.entities };

  delete newEntities[id];
  return {
    ...state,
    entities: newEntities,
    dashboardView: {
      ...state.dashboardView,
      ids: state.dashboardView.ids.filter((dashboardViewId) => dashboardViewId !== id),
      total: state.dashboardView.total - 1,
      documentsRequest: XHR_STATUS_IDLE,
    },
  };
}

export function fetchOneSuccess<T extends DocumentsStateWithDetailView>(
  state: T,
  action: FetchDocumentSuccessAction,
  initialDocumentMetadata: DocumentMetadata,
): T {
  const { document } = action.payload.bundle;

  const currentEntity = state.entities[document.id];
  let entity;

  if (currentEntity) {
    const resetPatientMatch = isIntegrationBundle(action.payload.bundle)
      ? {}
      : { updateRecipients: XHR_STATUS_IDLE }; // to reset refreshPatientMatch (this props is not present in every detailView, should be done elsewhere)

    entity = {
      ...currentEntity,
      resource: document,
      meta: {
        ...currentEntity.meta,
        ...resetPatientMatch,
      },
    };
  } else {
    entity = {
      resource: document,
      meta: {
        ...initialDocumentMetadata,
      },
    };
  }

  return {
    ...state,
    entities: {
      ...state.entities,
      [document.id]: entity,
    },
  };
}

export function fetchOneFailure<T extends DocumentsStateWithDetailView>(
  state: T,
  action: FetchDocumentFailureAction,
): T {
  return {
    ...state,
    detailView: {
      ...state.detailView,
      documentRequest: {
        ...XHR_STATUS_ERROR,
        errorDetail: HttpError.getKey(action.payload.error),
      },
    },
  };
}

export function fetchDocumentsSuccess<T extends DocumentsState>(
  state: T,
  action: FetchDocumentsFromCacheSuccessAction,
  initialDocumentMetadata: DocumentMetadata,
): T {
  const { documents } = action.payload;

  const newEntities = Object.fromEntries(
    documents.map((resource) => {
      const previous = state.entities[resource.id];

      if (state.entities[resource.id]) {
        return [
          resource.id,
          {
            meta: previous.meta,
          },
        ];
      }
      return [resource.id, { meta: initialDocumentMetadata }];
    }),
  );

  return {
    ...state,
    entities: { ...newEntities },
    dashboardView: {
      ...state.dashboardView,
      ids: documents.map((doc) => doc.id),
      total: action.payload.total,
    },
  };
}
