/**
 * Unique identifier for mutation errors
 * IMPORTANT!: This needs to be in sync with i18n translations error.mutation.{name}
 */

export enum MutationError {
  archiveDocument = 'archiveDocument',
  cancelDocument = 'cancelDocument',
  cancelDocumentAlreadyCanceled = 'cancelDocumentAlreadyCanceled',
  cancelManyDocuments = 'cancelManyDocuments',
  downloadDocument = 'downloadDocument',
  downloadManyDocuments = 'downloadManyDocuments',
  forwardDocument = 'forwardDocument',
  groupDocument = 'groupDocument',
  removeDocumentPatient = 'removeDocumentPatient',
  removeVerification = 'removeVerification',
  restoreDocument = 'restoreDocument',
  sendDocument = 'sendDocument',
  sendDocumentAlreadySent = 'sendDocumentAlreadySent',
  sendManyDocuments = 'sendManyDocuments',
  splitDocument = 'splitDocument',
  updateDocumentPatient = 'updateDocumentPatient',
  dndForbidden = 'dndForbidden',
}

export type MutationErrorName = keyof typeof MutationError;

export type GenericError = 'network' | 'server';

export interface ErrorsState {
  mutations: MutationErrorName[];
  generic: GenericError | null;
}

export const ERRORS_INITIAL_STATE: ErrorsState = {
  mutations: [],
  generic: null,
};
