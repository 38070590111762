import { Selector } from '@reduxjs/toolkit';

import { Attachment, Organization, Patient, Practitioner } from '@honestica/core-apps-common/types';

import { State } from '../reducer';

import { Entities, EntitiesState } from './entities.state';

export const getEntities: Selector<State, EntitiesState> = (state) => state.entitiesState;

export const getPractitionerEntities: Selector<State, Entities<Practitioner>> = (state: State) =>
  getEntities(state).Practitioner;

export const getPatientEntities: Selector<State, Entities<Patient>> = (state: State) =>
  getEntities(state).Patient;

export const getOrganizationEntities: Selector<State, Entities<Organization>> = (state: State) =>
  getEntities(state).Organization;

export const getAttachmentEntities: Selector<State, Entities<Attachment[]>> = (state: State) =>
  getEntities(state).Attachments;
