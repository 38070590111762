import { BatchPayload, OnePayload } from '@store/documents/documents.actions';

export enum DeferredActionsType {
  sendDocument = 'sendDocument',
  sendDocumentAbort = 'sendDocumentAbort',
  sendMany = 'sendMany',
  sendManyAbort = 'sendManyAbort',
}

export type DeferredId = string;

export interface SendDocumentDeferred {
  type: DeferredActionsType.sendDocument;
  args: OnePayload;
  delay: number;
  id: DeferredId;
}

export interface SendDocumentsDeferred {
  type: DeferredActionsType.sendMany;
  args: BatchPayload;
  delay: number;
  id: DeferredId;
}

export type AllowedDeferrableAction = SendDocumentsDeferred | SendDocumentDeferred;

export type AllowedDeferrableActionType = AllowedDeferrableAction['type'];

export type ActionsDeferredState = AllowedDeferrableAction[];
