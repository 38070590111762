import {
  Activity,
  ActivityDto,
  DocumentPatient,
  DocumentPatientDto,
  DocumentRecipientBundle,
  DocumentRecipientDto,
} from '../types';

export function activityToActivityDto(activity: Activity): ActivityDto {
  return {
    addresses: activity.addresses,
    id: activity.entity.id,
    telecoms: activity.telecoms,
    practRolePractitionerReference: activity.practRolePractitionerReference,
  };
}

export function documentRecipientBundleToDocumentRecipientDto(
  bundle: DocumentRecipientBundle,
): DocumentRecipientDto {
  return {
    id: bundle.entity.id,
    type: bundle.entity.type,
    practRolePractitionerReference: bundle.entity.practRolePractitionerReference,
    addresses: bundle.addresses,
    telecoms: bundle.telecoms,
    verifications: [], // should not be needed
  };
}

export function documentPatientToDocumentPatientDto({
  patient,
  ehrPatient,
  ...mediums
}: DocumentPatient): DocumentPatientDto {
  return { id: patient.id, ehrPatientId: ehrPatient?.id, ...mediums };
}
