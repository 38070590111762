import { ensureWatchPathFolders } from '@launcher/helpers/desktop';
import { isDesktopApp } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { DesktopActions } from '@store/desktop/desktop.action';
import {
  SetDesktopSettingsAction,
  UpdateDesktopSettingsAction,
} from '@store/desktop/desktop.reducer';
import { UploadDocumentsActions } from '@store/uploadDocuments/uploadDocuments.slice';
import { getIsLifenSendingActivated } from '@store/user/user.selector';

function* saga(action: UpdateDesktopSettingsAction) {
  if (!isDesktopApp()) return;

  const isStartup = isInitAction(action);

  const settings = action.payload;

  const newSendingPath = settings.watchPath;

  const isLifenSendingActivated: boolean = yield select(getIsLifenSendingActivated);

  const shouldRestartSendingWatcher = isLifenSendingActivated && !!newSendingPath;

  // at startup this action is made in initDesktopSaga
  if (shouldRestartSendingWatcher) {
    yield ensureWatchPathFolders([newSendingPath]);
  }

  if (isStartup) {
    logger.info('DESKTOP', " Kickoff current watchfolders' files upload...");
    if (isLifenSendingActivated) {
      yield put(UploadDocumentsActions.uploadAllDocsFromWatchedFolder());
    }
  }

  if (shouldRestartSendingWatcher) {
    logger.info('DESKTOP', `${isStartup ? 'Starting' : 'Restarting'} file watchers...`);
    yield put(DesktopActions.startWatchers());
  }
}

function isInitAction(
  action: SetDesktopSettingsAction | UpdateDesktopSettingsAction,
): action is SetDesktopSettingsAction {
  return action.type === DesktopActions.initWatchers.type;
}

export function* startWatcherOnWatchPathChangedSagas() {
  yield all([
    takeLatest(DesktopActions.updateSettings.type, saga),
    takeLatest(DesktopActions.initWatchers.type, saga),
  ]);
}
