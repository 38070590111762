import {
  IntegrationsDashboardType,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import { DETAIL_VIEW, DocumentTypeToPath, ERRORS } from '@constants/documents.constants';

const AllDashboardTypes = {
  ...SendingsDashboardType,
  ...IntegrationsDashboardType,
  Errors: ERRORS as typeof ERRORS,
};

export function isDashboardRoute(pathname: string) {
  const type = getDashboardTypeFromRoute(pathname);

  return type && !isDetailRoute(pathname);
}

export function isDetailRoute(pathname: string) {
  return pathname.includes(DETAIL_VIEW);
}

// TODO: remove this function when old detail route is removed in Lifen Integration
export function isOldDetailRoute(pathname: string) {
  const type = getDashboardTypeFromRoute(pathname);
  if (!type) return false;
  return DocumentTypeToPath[type] === pathname.split('/').slice(0, -1).join('/');
}

// TODO: remove this function when old detail route is removed in Lifen Integration
export function isOldDashboardRoute(pathname: string) {
  const type = getDashboardTypeFromRoute(pathname);

  return type && !isOldDetailRoute(pathname) && !isDetailRoute(pathname);
}

export function getDashboardTypeFromRoute(pathname: string) {
  return Object.values(AllDashboardTypes).find((dashboard) => pathname.includes(dashboard)) || null;
}
