import { createReducer } from '@reduxjs/toolkit';

import {
  findOrganization,
  findOrganizationFailure as findOrganizationFailureAction,
  findOrganizationSuccess as findOrganizationSuccessAction,
} from './organizationDetail.action';

export interface OrganizationDetailState {
  loading: boolean;
  error: boolean;
}

const INITIAL_STATE: OrganizationDetailState = {
  loading: false,
  error: false,
};

function loading(state: OrganizationDetailState, status: boolean) {
  return {
    ...state,
    loading: status,
  };
}

function findOrganizationSuccess(state: OrganizationDetailState) {
  return {
    ...state,
    loading: false,
    error: false,
  };
}

function findOrganizationFailure(state: OrganizationDetailState) {
  return {
    ...state,
    loading: false,
    error: true,
  };
}

export const organizationDetailReducer = createReducer(INITIAL_STATE, {
  [findOrganization.type]: (state) => loading(state, true),
  [findOrganizationSuccessAction.type]: findOrganizationSuccess,
  [findOrganizationFailureAction.type]: findOrganizationFailure,
});
