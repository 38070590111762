import { updateOneSuccess } from '@store/documents/common/reducers/updateMeta.reducers';
import {
  RemoveDmpSentDocumentSuccessAction,
  ReplaceDmpSuccessAction,
  StoreLocallyToReplaceDmpSuccessAction,
} from '@store/documents/documents.actions';
import { SentDocumentMetadataKey, SentDocumentsState } from '@store/documents/outgoing/sent.state';

export const onStoreLocallyToReplaceDmpSentDocumentSuccess = (
  state: SentDocumentsState,
  action: StoreLocallyToReplaceDmpSuccessAction,
): SentDocumentsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    replaceDmpFileStorageId: action.payload.fileStorageId,
    replaceDmpFileName: action.payload.fileName,
  },
});

export const onStoreLocallyToReplaceDmpSentDocumentFailure = (
  state: SentDocumentsState,
): SentDocumentsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    replaceDmpFileStorageId: undefined,
    replaceDmpFileName: undefined,
  },
});

export const onRemoveDmpDocumentSuccess = (
  state: SentDocumentsState,
  action: RemoveDmpSentDocumentSuccessAction,
): SentDocumentsState => {
  const { id, bundle } = action.payload;
  const { integration } = bundle.document;
  const entity = state.entities[id];
  const newState = {
    ...updateOneSuccess(state, action, SentDocumentMetadataKey.removeDmpRequest),
  };

  newState.entities[id].resource = {
    ...entity.resource,
    integration,
  };

  return newState;
};

export const onReplaceDmpDocumentSuccess = (
  state: SentDocumentsState,
  action: ReplaceDmpSuccessAction,
): SentDocumentsState => {
  const { id, bundle } = action.payload;
  const { integration } = bundle.document;
  const entity = state.entities[id];
  const newState = {
    ...updateOneSuccess(state, action, SentDocumentMetadataKey.replaceDmpRequest),
    detailView: {
      ...state.detailView,
      replaceDmpFileStorageId: undefined,
    },
  };

  newState.entities[id].resource = {
    ...entity.resource,
    integration,
  };

  return newState;
};
