export enum ModalType {
  cancelAndReplace = 'cancelAndReplace',
  cancelPatientsImportJobMonitor = 'cancelPatientsImportJobMonitor',
  cleanOutboxConfirmation = 'cleanOutboxConfirmation',
  closeConversation = 'closeConversation',
  dmpDraftSettings = 'dmpDraftSettings',
  dmpSentCancel = 'dmpSentCancel',
  dmpSentEdit = 'dmpSentEdit',
  dmpSentSettings = 'dmpSentSettings',
  documentJobNotification = 'documentJobNotification',
  documentsGroup = 'documentsGroup',
  helpAbout = 'settingsAbout',
  helpSupport = 'settingsSupport',
  patientsImportCsvTemplateSelection = 'patientsImportCsvTemplateSelection',
  patientsImportDoctolibTemplateInfo = 'patientsImportDoctolibTemplateInfo',
  patientsImportJobMonitor = 'patientsImportJobMonitor',
  patientsImportLifenTemplateInfo = 'patientsImportLifenTemplateInfo',
  settingsQA = 'settingsQA',
}
