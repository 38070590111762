import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { tracking } from '@services/analytics';
import { put, select, takeEvery } from 'redux-saga/effects';

import { BatchDeferredPayload, OneDeferredPayload } from '@store/documents/documents.actions';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { State } from '@store/reducer';

import {
  ActionDeferredActions,
  CancelDeferredAction,
  ExecuteDeferredAction,
} from '../actionsDeferred.slice';
import { ActionsDeferredState, AllowedDeferrableActionType } from '../actionsDeferred.state';

const mutationNameToAction: Record<
  AllowedDeferrableActionType,
  ActionCreatorWithPayload<OneDeferredPayload | BatchDeferredPayload>
> = {
  sendDocument: DraftDocumentsActions.sendOne,
  sendMany: DraftDocumentsActions.sendMany,
};

const mutationNameToAbortAction: Record<
  AllowedDeferrableActionType,
  ActionCreatorWithPayload<any>
> = {
  sendDocument: DraftDocumentsActions.sendOneAbort,
  sendMany: DraftDocumentsActions.sendManyAbort,
};

export const getState = (state: State) => state.actionsDeferred;

function* deferredSaga(action: ExecuteDeferredAction) {
  const actionToDispatch = mutationNameToAction[action.payload.type];
  tracking.trackDeferredDone({
    payloadType: action.payload.type,
    id: action.payload.id,
  });
  yield put(actionToDispatch({ ...action.payload.args, deferredId: action.payload.id }));
  yield put(ActionDeferredActions.done({ type: action.payload.type, id: action.payload.id }));
}

function* abortSaga(action: CancelDeferredAction) {
  const state: ActionsDeferredState = yield select(getState);
  const deferredAction = state.find((deferred) => deferred.id === action.payload.id);

  if (deferredAction) {
    const actionToDispatch = mutationNameToAbortAction[deferredAction.type];

    yield put(actionToDispatch(deferredAction.args));
    yield put(ActionDeferredActions.done({ type: deferredAction.type, id: deferredAction.id }));
  }
}

export function* executeActionDeferred() {
  yield takeEvery(ActionDeferredActions.executeOne.type, deferredSaga);
  yield takeEvery(ActionDeferredActions.cancel.type, abortSaga);
}
