import { createAction } from '@reduxjs/toolkit';

import {
  Organization,
  Practitioner,
  ProfessionalBase,
  ProfessionalBaseType,
  SearchParams,
} from '@honestica/core-apps-common/types';

import { ContactDirectoryState } from './contactState';

const REDUCER_NAME = 'contactDirectory';

const FIND_CONTACTS = `${REDUCER_NAME}/findContact`;

export const findContacts = createAction<SearchParams>(FIND_CONTACTS);
export const findContactsSuccess = createAction<ContactDirectoryState>(`${FIND_CONTACTS}Success`);
export const findContactsFailure = createAction<unknown>(`${FIND_CONTACTS}Failure`);

const ADD_CONTACT = `${REDUCER_NAME}/addContact`;

export const addContact = createAction<{ resourceId: string; resourceType: ProfessionalBaseType }>(
  ADD_CONTACT,
);
export const addContactSuccess = createAction<ProfessionalBase>(`${ADD_CONTACT}Success`);
export const addContactFailure = createAction<unknown>(`${ADD_CONTACT}Failure`);

const REMOVE_CONTACT = `${REDUCER_NAME}/removeContact`;

export const removeContact = createAction<{ contactId: number | null }>(REMOVE_CONTACT);
export const removeContactSuccess = createAction<Practitioner | Organization>(
  `${REMOVE_CONTACT}Success`,
);
export const removeContactFailure = createAction<unknown>(`${REMOVE_CONTACT}Failure`);

export type FindContacts = typeof findContacts;
export type AddContact = typeof addContact;
export type RemoveContact = typeof removeContact;
