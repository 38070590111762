import { ViewerUtils } from '@launcher/components/lazy/viewer.lazy';

import { DocumentJobError } from '@honestica/core-apps-common/types';
import {
  MAX_FILE_HEIGHT_INTEGRATION,
  MAX_FILE_SIZE,
} from '@honestica/lifen-pdf-viewer/src/utilsLib/constants';

export const hasPdfExtension = (fileName: string): boolean => {
  const splitted = fileName.toLowerCase().split('.');
  return splitted[splitted.length - 1] === 'pdf';
};

const isFileSizeOk = (file: Buffer): boolean => {
  const fileSizeInMo = file.buffer.byteLength / 1000000;
  return fileSizeInMo < MAX_FILE_SIZE;
};

export const checkFileForErrors = async (
  file: Buffer,
  fileName: string,
  options?: {
    maxPageNumber?: number;
    offsetSizePage?: number;
    allowFileWithRotate?: boolean;
    maxWidth?: number;
    maxHeight?: number;
  },
): Promise<DocumentJobError[]> => {
  const errors: DocumentJobError[] = [];
  try {
    if (!isFileSizeOk(file)) {
      return [DocumentJobError.FileSizeTooBig];
    }
    if (!hasPdfExtension(fileName)) {
      return [DocumentJobError.WrongFileType];
    }
    const formatCheck = await (
      await ViewerUtils
    ).checkFileFormat(
      file,
      options?.maxPageNumber,
      options?.offsetSizePage,
      options?.allowFileWithRotate,
      options?.maxWidth,
      options?.maxHeight,
    );
    if (formatCheck.tooManyPages) {
      errors.push(DocumentJobError.TooManyPages);
    }
    if (formatCheck.hasOnePageTooBig) {
      // if the provided option is related to integration, use A3 file format error message
      errors.push(
        options?.maxWidth === MAX_FILE_HEIGHT_INTEGRATION
          ? DocumentJobError.IsNotA3
          : DocumentJobError.IsNotA4,
      );
    }
  } catch (err) {
    return [DocumentJobError.CorruptedFile];
  }

  return errors;
};
