import * as PusherMod from 'pusher';

import { isCommunicationRequest } from '../helpers';
import {
  DocumentFlowRealTimeEventType,
  DocumentFlowRealtimeEventPayload,
  RealTimeEvent,
  RealtimeResource,
} from '../types';

const Pusher = PusherMod.default;
export class RealtimeClient {
  private readonly pusherClient: PusherMod | undefined;

  constructor() {
    const {
      PUSHER_APP_ID,
      PUSHER_APP_KEY,
      PUSHER_SECRET_KEY,
      PUSHER_CLUSTER,
      PUSHER_PORT,
      PUSHER_ENCRYPTION_MASTER_KEY,
    } = process.env;

    if (!PUSHER_APP_ID || !PUSHER_APP_KEY || !PUSHER_SECRET_KEY) {
      return;
    }

    this.pusherClient = new Pusher({
      appId: PUSHER_APP_ID,
      key: PUSHER_APP_KEY,
      secret: PUSHER_SECRET_KEY,
      useTLS: true,
      cluster: PUSHER_CLUSTER ?? 'eu',
      port: PUSHER_PORT,
      encryptionMasterKeyBase64: PUSHER_ENCRYPTION_MASTER_KEY,
    });
  }

  // publishes to the user channel, will most likely disappear in the near future
  public publishFromFhirResource(
    eventType: DocumentFlowRealTimeEventType,
    resource: RealtimeResource,
  ) {
    const senderId = this.getSenderIdFromResource(resource);

    if (!senderId) {
      return;
    }

    this.publishToChannel(this.getSenderChannelKey(senderId), {
      eventType,
      payload: this.getDocumentFlowRealtimeEventPayload(resource),
    });
  }

  public publishToChannel<T>(channel: string, event: RealTimeEvent<T>) {
    if (!this.pusherClient) {
      return;
    }

    this.pusherClient.trigger(channel, event.eventType, event.payload);
  }

  private getSenderIdFromResource(resource: RealtimeResource): string | undefined {
    if (resource && isCommunicationRequest(resource)) {
      return resource.sender?.reference;
    }

    return undefined;
  }

  getDocumentFlowRealtimeEventPayload(
    resource: RealtimeResource,
  ): DocumentFlowRealtimeEventPayload {
    return {
      resource: { id: resource.id },
    };
  }

  getProfessionalChannelKey(professionalReference: string): string | undefined {
    // TODO: move extractReference to the common package and use it here
    const ref = /\/?(.*)\/(.*)/.exec(professionalReference);
    if (ref === null) {
      return undefined;
    }
    return `${ref[1]}-${ref[2]}`;
  }

  getSenderChannelKey(senderId: string): string {
    return senderId.replace('/', '-');
  }
}
