// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

/**
 * L'event "Action Error Displayed" est envoyé lorsqu'une erreur intervient suite à une
 * action de l'utilisateur (ex: cancel failed, send failed, ...)
 */
export interface ActionErrorDisplayed {
  /**
   * Le nom de l'action qui a échoué
   */
  actionErrorName?: string;
  [property: string]: any;
}

export interface AppOpened {
  app?: string;
  openingMethod?: OpeningMethod;
  [property: string]: any;
}

export enum OpeningMethod {
  DefaultAtLaunch = 'defaultAtLaunch',
  FromStorePage = 'fromStorePage',
  UserSwitch = 'userSwitch',
}

/**
 * L’event “App Update Completed” est un event qui sera déclenché à la fin de l'update
 * automatique (fin de l'installation) de l'application locale. Il nous permettra de suivre
 * l'avancement du déploiement des updates.
 */
export interface AppUpdateCompleted {
  desktopVersion?: string;
  webVersion?: string;
  [property: string]: any;
}

/**
 * L’event “App Update Failed” est un event qui sera déclenché lorsqu’une erreur a
 * interrompu le process d’update.
 */
export interface AppUpdateFailed {
  /**
   * version de l'app locale avant la tentative de mise à jour
   */
  desktopVersion?: string;
  errorCause?: string;
  webVersion?: string;
  [property: string]: any;
}

/**
 * L’event “App Update Started” est un event qui sera déclenché lors du début de l'update
 * automatique (lancement du téléchargement) de l'application locale.  Il nous permettra de
 * suivre l'avancement du déploiement des updates.
 */
export interface AppUpdateStarted {
  /**
   * version de l'app locale avant la mise à jour
   */
  desktopVersion?: string;
  webVersion?: string;
  [property: string]: any;
}

/**
 * Envoyé lorsque l'utilisateur clique sur un bouton qui permet de faire une action groupée.
 */
export interface BatchActionClicked {
  /**
   * Permet de distinguer le type de l'action cliquée par l'utilisateur
   */
  actionType: BatchActionClickedActionType;
  /**
   * Identifiants FHIR des documents sélectionnés
   */
  documentIds?: any[];
  numberOfSelectedRows: number;
  [property: string]: any;
}

/**
 * Permet de distinguer le type de l'action cliquée par l'utilisateur
 */
export enum BatchActionClickedActionType {
  Archive = 'archive',
  Delete = 'delete',
  Download = 'download',
  Duplicate = 'duplicate',
  Group = 'group',
  Integrate = 'integrate',
  Restore = 'restore',
  Send = 'send',
  Split = 'split',
  Unknown = 'unknown',
}

/**
 * When a user submits a bulk update on selected documents
 */
export interface BatchDocumentsModified {
  /**
   * number of documents to be updated
   */
  documents: number;
  /**
   * the title to apply to all the selected documents
   */
  documentTitle?: boolean;
  /**
   * the type to apply to selected documents
   */
  documentType?: string;
  [property: string]: any;
}

/**
 * L'upload de documents en masse se termine
 */
export interface BatchFileUploadEnd {
  currentWorkspaceFhirReference?: string;
  currentWorkspaceName?: string;
  documentsCount?: number;
  [property: string]: any;
}

/**
 * L'upload de documents en masse démarre
 */
export interface BatchFileUploadStart {
  currentWorkspaceFhirReference?: string;
  currentWorkspaceName?: string;
  documentsCount?: number;
  /**
   * Comment le document a-t-il été uploadé ?
   */
  source?: BatchFileUploadStartSource;
  [property: string]: any;
}

/**
 * Comment le document a-t-il été uploadé ?
 */
export enum BatchFileUploadStartSource {
  Button = 'button',
  DragDrop = 'drag&drop',
  LifenServer = 'lifenServer',
  Opening = 'opening',
  Outbox = 'outbox',
}

export interface CloseEnsConversation {
  closed?: boolean;
  documentId?: string;
  [property: string]: any;
}

export interface ContactCardViewed {
  contactFhirReference?: string;
  contactType?: TType;
  [property: string]: any;
}

export enum TType {
  Organization = 'organization',
  Practitioner = 'practitioner',
}

export interface ContactSearched {
  contactType?: ContactType;
  [property: string]: any;
}

export enum ContactType {
  All = 'all',
  Favorites = 'favorites',
  Organization = 'organization',
  Practitioner = 'practitioner',
}

export interface CustomEnsMessageAdded {
  documentId?: string;
  isEmpty?: boolean;
  [property: string]: any;
}

export interface DashboardFiltered {
  dashboardType?: string;
  dateRange?: { [key: string]: any };
  defaultDateRange?: boolean;
  documentTypeFiltered?: string;
  /**
   * When the dashboard is filtered to display only documents created/imported by the user
   */
  hideOtherRequesters?: boolean;
  mediumFiltered?: string;
  page?: number;
  patientAdvancedFiltered?: any[];
  patientFiltered?: boolean;
  recipient?: string;
  servicesFiltered?: string;
  signingPractitionerFiltered?: string;
  /**
   * When a dashboard is filtered by source of the document (Lifen integration only)
   */
  sourceFiltered?: string;
  statusFiltered?: string;
  [property: string]: any;
}

export interface DocumentActionClicked {
  actionType?: DocumentActionClickedActionType;
  communicationRequest?: string;
  [property: string]: any;
}

export enum DocumentActionClickedActionType {
  Archive = 'archive',
  Delete = 'delete',
  Download = 'download',
  Duplicate = 'duplicate',
  Forward = 'forward',
  Integrate = 'integrate',
  Restore = 'restore',
  Send = 'send',
  Split = 'split',
  Unknown = 'unknown',
}

export interface DocumentDatePeriodModified {
  newEndDate?: string;
  newStartDate?: string;
  previousEndDate?: string;
  previousStartDate?: string;
  [property: string]: any;
}

export interface DocumentDeleted {
  isBatchAction: boolean;
  numberOfItemInBatch?: number;
  [property: string]: any;
}

/**
 * L'event "Document Id Copied" est envoyé lorsqu'un utilisateur clic sur le bouton pour
 * copier l'id du document
 */
export interface DocumentIDCopied {
  fhirReference?: string;
  [property: string]: any;
}

export interface DocumentIntegrated {
  documentType?: string;
  isBatchAction: boolean;
  numberOfItemInBatch?: number;
  /**
   * source corresponds to the document source, which can be LIFEN INTEGRATION or LIFEN
   * INTEGRATION FORWARD.
   */
  source?: string;
  [property: string]: any;
}

/**
 * When the user clicks to go to the previous or next document
 */
export interface DocumentNavigatorClicked {
  dashboardType?: string;
  /**
   * navigation = next if the user go to the next document / navigation = previous if the user
   * go to previous document
   */
  navigation?: string;
  [property: string]: any;
}

export interface DocumentOriginalSenderModified {
  newValue?: string;
  previousValue?: string;
  [property: string]: any;
}

export interface DocumentPrinted {
  fhirReference?: string;
  [property: string]: any;
}

export interface DocumentSenderModified {
  newSender?: string;
  previousSender?: string;
  [property: string]: any;
}

/**
 * L'event "Document Title Modified" est utilisé dans Lifen intégration lorsque
 * l'utilisateur modifie le titre via le bouton reset ou l'input text
 */
export interface DocumentTitleModified {
  isDefaultTitle: boolean;
  [property: string]: any;
}

export interface DocumentTypeModified {
  communicationRequest?: string;
  desktopVersion?: string;
  newType?: string;
  previousType?: string;
  webVersion?: string;
  [property: string]: any;
}

export interface DraftDashboardIdentityNavigationLinkClicked {
  /**
   * identity reference
   */
  identity: string;
  [property: string]: any;
}

export interface EHRIntegrationModfied {
  communicationRequest?: string;
  desktopId?: string;
  desktopVersion?: string;
  env?: string;
  newState?: string;
  previousState?: string;
  webVersion?: string;
  [property: string]: any;
}

/**
 * When the user selects an encounter
 */
export interface EncounterUpdated {
  communicationRequest?: string;
  desktopId?: string;
  desktopVersion?: string;
  env?: string;
  newEncounter?: string;
  previousEncounter?: string;
  webVersion?: string;
  [property: string]: any;
}

export interface ErrorQuickviewTriggered {
  errorCause?: string;
  fhirReference?: string;
  [property: string]: any;
}

/**
 * L'upload du document a échoué
 */
export interface FileUploadFailed {
  /**
   * Cause de l'erreur
   */
  errorCause?: string;
  [property: string]: any;
}

/**
 * Upload d'un document via l'imprimante virtuelle
 */
export interface FileUploaded {
  currentWorkspaceFhirReference?: string;
  currentWorkspaceName?: string;
  fhirReference?: string;
  /**
   * Comment le document a-t-il été uploadé ?
   */
  source?: FileUploadedSource;
  [property: string]: any;
}

/**
 * Comment le document a-t-il été uploadé ?
 */
export enum FileUploadedSource {
  Button = 'button',
  DragDrop = 'drag&drop',
  LifenServer = 'lifenServer',
  Outbox = 'outbox',
}

export interface FilterMenuOpened {
  context?: Context;
  desktopVersion?: string;
  platform?: Platform;
  webVersion?: string;
  workspaceFhirReference?: string;
  [property: string]: any;
}

export enum Context {
  App = 'app',
  Web = 'web',
}

export enum Platform {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Tablet = 'tablet',
}

export interface GroupedDocumentReordered {
  fhirReference?: string;
  [property: string]: any;
}

export interface InErrorsDashboardFiltered {
  filename?: string;
  fromDate?: Date;
  id?: string;
  message_contains?: string;
  patientFiltered?: boolean;
  untilDate?: Date;
  [property: string]: any;
}

export interface IntegrationMatchDone {
  fhirReference?: string;
  [property: string]: any;
}

/**
 * Cet event est envoyé lorsque l'utilisateur sélectionne ou supprime un type d'examen
 * médical
 */
export interface MedicalExamTypeModified {
  newType?: string;
  previousType?: string;
  [property: string]: any;
}

/**
 * Ouverture du message
 */
export interface MessageOpened {
  /**
   * will become fhirReference
   */
  communicationId?: string;
  context?: Context;
  documentLoincType?: string;
  /**
   * Référence de la ressource en question, avec le type de la resource:
   * CommunicationRequest/123, Communication/123
   */
  fhirReference?: string;
  /**
   * Lu / Non Lu
   */
  isUnread?: boolean;
  messageType?: MessageType;
  platform?: Platform;
  purpose?: Purpose;
  requesterFhirReference?: string;
  requesterName?: string;
  senderFhirReference?: string;
  senderName?: string;
  [property: string]: any;
}

export enum MessageType {
  Classic = 'classic',
  Quickview = 'quickview',
}

export enum Purpose {
  Personal = 'personal',
  Professional = 'professional',
}

export interface MessageSent {
  communicationRequest?: string;
  currentWorkspaceFhirName?: string;
  currentWorkspaceFhirReference?: string;
  deliveredToPatient?: DeliveredToPatient;
  dematPatient?: boolean;
  desktopVersion?: string;
  dmpIntegration?: DmpIntegration | null;
  documentLoincType?: string;
  dpiIntegration?: DPIIntegration;
  /**
   * Référence de la ressource en question, avec le type de la resource:
   * CommunicationRequest/123, Communication/123
   */
  fhirReference?: string;
  includeMessageText?: boolean;
  isAutonomous?: boolean;
  /**
   * L'action a été déclenchée en Batch
   */
  isBatchAction?: boolean;
  /**
   * Est-ce que le doc est issu de publipostage ?
   */
  isDirectMail?: boolean;
  /**
   * Le message envoyé est-il un message groupé ?
   */
  isGrouped?: string;
  /**
   * Nombre de caractères du message associé à l'envoi
   */
  messageTextLength?: number;
  quickviewRecipientAmount?: number;
  requesterFhirReference?: string;
  requesterName?: string;
  senderFhirReference?: string;
  senderName?: string;
  /**
   * l'ID du Thread dans notre DB  (lifen mail)
   */
  threadId?: string;
  /**
   * l'ID du message dans notre DB (lifen mail)
   */
  threadItemId?: string;
  webVersion?: string;
  workspaceFhirReference?: string;
  [property: string]: any;
}

export enum DeliveredToPatient {
  Demat = 'demat',
  DematAndPaper = 'dematAndPaper',
  False = 'false',
  Paper = 'paper',
}

export enum DmpIntegration {
  Selected = 'selected',
  Unselected = 'unselected',
}

export enum DPIIntegration {
  Auto = 'auto',
  Manual = 'manual',
  Unselected = 'unselected',
}

export interface NotificationClicked {
  communication?: string;
  communicationRequest?: string;
  desktopVersion?: string;
  notificationType?: string;
  webVersion?: string;
  [property: string]: any;
}

export interface NotificationMenuOpened {
  desktopVersion?: string;
  hasNewNotifications?: boolean;
  webVersion?: string;
  [property: string]: any;
}

export interface PageAccountCreationViewed {
  fhirReference?: string;
  source?: string;
  [property: string]: any;
}

export interface PageIntegratedDetailViewed {
  /**
   * source corresponds to the document source, which can be LIFEN INTEGRATION or LIFEN
   * INTEGRATION FORWARD.
   */
  source?: string;
  status?: string;
  [property: string]: any;
}

export interface PageQuickviewLandingViewed {
  fhirReference?: string;
  [property: string]: any;
}

export interface PageQuickviewOTPViewed {
  ''?: { [key: string]: any };
  fhirReference?: string;
  [property: string]: any;
}

export interface PageWorklistDetailViewed {
  hasDateOfProcedureDetected?: boolean;
  pageCount?: number;
  /**
   * source corresponds to the document source, which can be LIFEN INTEGRATION or LIFEN
   * INTEGRATION FORWARD.
   */
  source?: string;
  status?: string;
  [property: string]: any;
}

export interface PatientAdded {
  patientId?: string;
  [property: string]: any;
}

export interface PatientCardViewed {
  patientFhirReference?: string;
  patientType?: TType;
  [property: string]: any;
}

/**
 * lorsque l’on créé un nouveau patient dans la vue de détail d’une CR depuis la modale
 * patient
 *
 * lorsque l’on créé un nouveau patient pour l’ajouter en tant que recipient depuis la
 * modale d’ajout de destinataire
 */
export interface PatientCreated {
  desktopVersion?: string;
  fhirReference?: string;
  hasNir?: boolean;
  webVersion?: string;
  [property: string]: any;
}

export interface PatientDirectoryImportDone {
  patientsCreated?: number;
  patientsFailed?: number;
  patientsUpdated?: number;
  workspaceFhirReference?: string;
  [property: string]: any;
}

export interface PatientDirectoryImportFailed {
  errorCause?: string;
  [property: string]: any;
}

export interface PatientDirectoryImportStarted {
  workspaceFhirReference?: string;
  [property: string]: any;
}

export interface PatientMatchRelaunched {
  /**
   * matchStatus corresponds to the patient's match status preceding the user's action
   */
  matchStatus?: string;
  [property: string]: any;
}

export interface PatientModifyClicked {
  /**
   * matchStatus corresponds to the patient's match status preceding the user's action
   */
  matchStatus?: string;
  [property: string]: any;
}

export interface PatientRemoved {
  /**
   * matchStatus corresponds to the patient's match status preceding the user's action
   */
  matchStatus?: string;
  patientId?: string;
  [property: string]: any;
}

export interface PatientSearched {
  app?: string;
  [property: string]: any;
}

export interface PatientUpdated {
  desktopVersion?: string;
  hasNir?: boolean;
  /**
   * matchStatus corresponds to the patient's match status preceding the user's action
   */
  matchStatus?: string;
  patientId?: string;
  updatedBy?: string;
  updatedInformation?: any[];
  webVersion?: string;
  [property: string]: any;
}

/**
 * Cet event est envoyé lorsqu'un utilisateur ajoute un destinataire à la communication
 * request
 */
export interface RecipientAdded {
  communicationRequest?: string;
  fhirReference?: string;
  /**
   * Telecoms selected (postal, demat, ...
   */
  telecoms?: any[];
  /**
   * Recipient type added (patient, practitionner)
   */
  type?: string;
  [property: string]: any;
}

export interface RecipientRemoved {
  communicationRequest?: string;
  fhirReference?: string;
  type?: string;
  [property: string]: any;
}

export interface RecipientUpdated {
  communicationRequest?: string;
  fhirReference?: string;
  /**
   * New telecoms selected (postal, demat, ...
   */
  telecoms?: any[];
  /**
   * Recipient type updated (patient, practitionner)
   */
  type?: string;
  [property: string]: any;
}

/**
 * Envoyé lorsque l'utilisateur change l'émetteur dans le dropdown Prochains envois
 */
export interface SelectedIdentityUpdated {
  newSelectedIdentity?: string;
  [property: string]: any;
}

/**
 * Envoyé lorsque l'utilisateur clique sur les CTA Valider/Remplacer/Supprimer le
 * destinataire d'une vérification
 */
export interface VerificationAction {
  actionType?: string;
  dismissedAt?: string;
  notCertainCase?: string;
  verifiableId?: string;
  verifiableType?: string;
  verificationId?: string;
  verificationKey?: string;
  [property: string]: any;
}
