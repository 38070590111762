import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { logger } from '@services/logger/logger.service';

import { ROLLBACK_PATIENTS_IMPORT_QUEUE_NAME } from '@honestica/core-apps-common/constants/index';
import { Patient, PatientList, PatientsImportJobState } from '@honestica/core-apps-common/types';

import {
  createPatient,
  createPatientFailure,
  createPatientSuccess,
  deletePatients,
  deletePatientsFailure,
  deletePatientsSuccess,
  enqueuePatientsImportJob,
  enqueuePatientsImportJobFailure,
  enqueuePatientsImportJobSuccess,
  enqueueRollbackPatientsImportJob,
  enqueueRollbackPatientsImportJobFailure,
  enqueueRollbackPatientsImportJobSuccess,
  fetchUserLatestPatientJobStateSuccess,
  findPatient,
  findPatientFailure,
  findPatientSuccess,
  findPatients,
  findPatientsFailure,
  findPatientsSuccess,
  updatePatient,
  updatePatientFailure,
  updatePatientJobStateFailure,
  updatePatientJobStateSuccess,
  updatePatientSuccess,
} from './patientDirectory.action';

export interface PatientDirectoryState {
  list: Patient['id'][];
  total: number;
  loading: boolean;
  error: boolean;
  drawerError: boolean;
  modalError: boolean;
  modalLoading: boolean;
  job: {
    jobId: PatientsImportJobState['jobId'] | undefined;
    status: PatientsImportJobState['status'] | undefined;
    progress: PatientsImportJobState['progress'] | undefined;
    isRollback: boolean;
    importReport: {
      invalidRecords: number;
      createdRecords: number;
      updatedRecords: number;
      totalRecords: number;
    };
    timestamp: number;
  };
}

const INITIAL_STATE: PatientDirectoryState = {
  list: [],
  total: 0,
  loading: false,
  error: false,
  drawerError: false,
  modalError: false,
  modalLoading: false,
  job: {
    jobId: undefined,
    isRollback: false,
    status: undefined,
    progress: undefined,
    importReport: { invalidRecords: 0, createdRecords: 0, updatedRecords: 0, totalRecords: 0 },
    timestamp: 0,
  },
};

function resetJob(state: PatientDirectoryState, action: PayloadAction): PatientDirectoryState {
  logger.info('STATE', action.type);
  return {
    ...state,
    error: false,
    drawerError: false,
    modalError: false,
    modalLoading: true,
    job: {
      ...INITIAL_STATE.job,
    },
  };
}

function updateJob(
  state: PatientDirectoryState,
  action: PayloadAction<PatientsImportJobState>,
): PatientDirectoryState {
  const { payload } = action;

  const { result, status, jobType, timestamp, ...jobProps } = payload;

  logger.info('STATE', action.type, {
    job: payload,
  });

  if (
    state.modalLoading &&
    ![enqueuePatientsImportJobSuccess.type, enqueueRollbackPatientsImportJobSuccess.type].includes(
      action.type,
    )
  ) {
    return state;
  }

  if (state.job.timestamp > timestamp) {
    return state;
  }

  const fileErrorLength = result?.fileError.length;
  const validationErrorLength = result?.validationError.length;

  return {
    ...state,
    error: false,
    drawerError: false,
    modalError: false,
    modalLoading: false,
    job: {
      status,
      ...jobProps,
      timestamp,
      isRollback: jobType === ROLLBACK_PATIENTS_IMPORT_QUEUE_NAME,
      importReport: {
        invalidRecords: fileErrorLength + validationErrorLength,
        createdRecords: result?.createdIds.length,
        updatedRecords: result?.updatedIds.length,
        totalRecords: result?.totalRecords,
      },
    },
  };
}

function addPatients(
  state: PatientDirectoryState,
  action: PayloadAction<PatientList>,
): PatientDirectoryState {
  const { payload } = action;

  if (!Array.isArray(payload.list)) {
    return { ...state, loading: false, error: true };
  }

  logger.info('STATE', action.type, {
    length: payload.list.length,
    total: payload.total,
  });

  return {
    ...state,
    list: payload.list.map((patient: Patient) => patient.id),
    total: payload.total,
    loading: false,
    error: false,
    drawerError: false,
  };
}

function addPatient(
  state: PatientDirectoryState,
  action: PayloadAction<Patient>,
): PatientDirectoryState {
  const { payload: patient } = action;

  if (!patient?.id) {
    return { ...state };
  }
  logger.info('STATE', action.type, { addedId: patient.id });

  return {
    ...state,
    list: Array.from(new Set([patient.id, ...state.list])),
    total: state.total + 1,
    loading: false,
    drawerError: false,
  };
}

function loading(state: PatientDirectoryState, status: boolean): PatientDirectoryState {
  return { ...state, loading: status };
}

function failure(state: PatientDirectoryState, action: PayloadAction): PatientDirectoryState {
  logger.info('STATE', action.type);

  return {
    ...state,
    loading: false,
    error: true,
  };
}

function drawerFailure(state: PatientDirectoryState, action: PayloadAction): PatientDirectoryState {
  logger.info('STATE', action.type);

  return { ...state, loading: false, drawerError: true };
}

function modalLoading(state: PatientDirectoryState): PatientDirectoryState {
  return { ...state, modalLoading: true };
}

function modalFailure(state: PatientDirectoryState, action: PayloadAction): PatientDirectoryState {
  logger.info('STATE', action.type);

  return { ...state, loading: false, modalLoading: false, modalError: true };
}

function success(state: PatientDirectoryState, action: PayloadAction): PatientDirectoryState {
  logger.info('STATE', action.type);

  return { ...state, loading: false, error: false, drawerError: false };
}

export const patientDirectoryReducer = createReducer(INITIAL_STATE, {
  [createPatient.type]: (state) => loading(state, true),
  [enqueuePatientsImportJob.type]: resetJob,
  [enqueueRollbackPatientsImportJob.type]: modalLoading,
  [findPatient.type]: (state) => loading(state, true),
  [findPatients.type]: (state) => loading(state, true),
  [updatePatient.type]: (state) => loading(state, true),
  [deletePatients.type]: (state) => loading(state, true),
  [fetchUserLatestPatientJobStateSuccess.type]: updateJob,
  [updatePatientJobStateSuccess.type]: updateJob,
  [enqueuePatientsImportJobSuccess.type]: updateJob,
  [enqueueRollbackPatientsImportJobSuccess.type]: updateJob,
  [createPatientSuccess.type]: addPatient,
  [deletePatientsSuccess.type]: addPatients,
  [findPatientSuccess.type]: addPatient,
  [findPatientsSuccess.type]: addPatients,
  [updatePatientSuccess.type]: success,
  [enqueuePatientsImportJobFailure.type]: modalFailure,
  [enqueueRollbackPatientsImportJobFailure.type]: modalFailure,
  [updatePatientJobStateFailure.type]: failure,
  [createPatientFailure.type]: drawerFailure,
  [deletePatientsFailure.type]: failure,
  [findPatientFailure.type]: drawerFailure,
  [findPatientsFailure.type]: failure,
  [updatePatientFailure.type]: drawerFailure,
});
