import { HTMLAttributes, ReactNode } from 'react';

import { Col, Row } from 'antd';
import cx from 'classnames';

import { Size } from '../../@types/props';
import colors from '../../theme/colors';
import Icon from '../Icon';
import { OneToneIconName } from '../Icon/OneToneIcons';

import styles from './DetailCard.module.less';

type CompoundSize = {
  body?: Size;
  header?: Size;
};

type CardType = 'default' | 'warning' | 'danger' | 'info' | 'neutral' | 'disabled' | 'primary';
interface BannerProps extends HTMLAttributes<HTMLDivElement> {
  message?: string;
}

interface DetailCardProps extends BannerProps {
  centerIcon?: boolean;
  header?: ReactNode;
  iconName?: OneToneIconName;
  noInnerPadding?: boolean;
  size?: Size | CompoundSize;
  type?: CardType;
  onClose?: () => void;
}

// Used for icon color
const colorMap: Record<CardType, string> = {
  default: colors.grey[5],
  warning: colors.yellow[7],
  danger: colors.red[6],
  info: colors.cyan[6],
  neutral: colors.grey[7],
  disabled: colors.grey[5],
  primary: colors.geekblue[1],
};

const paddingMap: Record<Size, string> = {
  default: styles.noPadding,
  small: styles.smallPadding,
  medium: styles.mediumPadding,
  large: styles.largePadding,
  full: styles.fullPaddingAndMargin,
};

type CardWithIconProps = Pick<
  DetailCardProps,
  'size' | 'noInnerPadding' | 'type' | 'children' | 'centerIcon' | 'message' | 'onClose'
> & { iconName: OneToneIconName };

const CardWithIcon = (props: CardWithIconProps) => (
  <Row
    gutter={16}
    wrap={false}
    className={cx(
      paddingMap[consolidatedSizes(props.size || 'medium').body],
      props.noInnerPadding && styles.noInnerPadding,
      styles.overflowHidden,
    )}
    align={props.centerIcon ? 'middle' : 'top'}
  >
    <Col>
      <Icon flex name={props.iconName} color={colorMap[props.type || 'default']} size={16} />
    </Col>
    <Col flex="auto">
      {props.message}
      {props.children}
    </Col>

    {props.onClose && (
      <Col>
        <Icon
          role="button"
          tabIndex={0}
          onClick={props.onClose}
          flex
          name="CrossClose"
          color={colorMap[props.type || 'default']}
          size={12}
          className={styles.iconClose}
        />
      </Col>
    )}
  </Row>
);

const CardWithoutIcon = ({
  header,
  size = 'large',
  noInnerPadding,
  children,
  message,
}: Pick<DetailCardProps, 'size' | 'noInnerPadding' | 'children' | 'header' | 'message'>) => {
  const content =
    children || message ? (
      <>
        {message}
        {children}
      </>
    ) : undefined;
  return (
    <>
      {header && (
        <Row gutter={16} wrap={false} className={styles.overflowHidden}>
          <Col flex="auto">
            <div
              className={cx(
                styles.header,
                paddingMap[consolidatedSizes(size).header],
                !content && styles.headerNoChildren,
              )}
            >
              {header}
            </div>
          </Col>
        </Row>
      )}
      <Row gutter={16} wrap={false} className={styles.overflowHidden}>
        <Col flex="auto">
          {content && (
            <div
              className={cx(
                paddingMap[consolidatedSizes(size).body],
                noInnerPadding && styles.noInnerPadding,
              )}
            >
              {content}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

function sizeIsCompound(size: any): size is CompoundSize {
  return Boolean(size?.body) || Boolean(size?.header);
}

function consolidatedSizes(size: DetailCardProps['size']): { body: Size; header: Size } {
  const body = (sizeIsCompound(size) ? size?.body : size) ?? 'medium';
  const header = (sizeIsCompound(size) ? size?.header : size) ?? 'medium';
  return { header, body };
}

export const DetailCard = ({
  children,
  className,
  size,
  iconName,
  header,
  style,
  type,
  noInnerPadding,
  centerIcon,
  message,
  onClose,
  ...rest
}: DetailCardProps) => (
  <div
    {...rest}
    className={cx(
      styles.card,
      type && (type === 'disabled' ? 'disabled' : styles[type]),
      className,
    )}
    style={style}
  >
    {iconName ? (
      <CardWithIcon
        size={size}
        noInnerPadding={noInnerPadding}
        type={type}
        iconName={iconName}
        centerIcon={centerIcon}
        message={message}
        onClose={onClose}
      >
        {children}
      </CardWithIcon>
    ) : (
      <CardWithoutIcon
        size={size}
        noInnerPadding={noInnerPadding}
        header={header}
        message={message}
      >
        {children}
      </CardWithoutIcon>
    )}
  </div>
);

export const DangerCard = (props: BannerProps) => (
  <DetailCard size="small" iconName="DiamondAlert" type="danger" {...props} />
);

export const InfoCard = (props: BannerProps) => (
  <DetailCard size="small" iconName="CircleInformation" type="info" {...props} />
);

export const WarningCard = (props: BannerProps) => (
  <DetailCard size="small" iconName="RoadSignWarning" type="warning" {...props} />
);
