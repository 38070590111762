/**
 * Status report from Lifen
 */
export enum SendingLifenStatus {
  LifenPreparation = 'preparation',
  LifenInProgress = 'in-progress',
  LifenSuspended = 'suspended',
  LifenEnteredInError = 'entered-in-error',
  LifenCompleted = 'completed',
}

/**
 * Status report from external service
 * provider (postal service, apicrypt, ...)
 */
export enum SendingExternalStatus {
  ExternalPendingFeedback = 'pending_feedback',
  ExternalProcessing = 'processing',
  ExternalInTransit = 'in_transit',
  ExternalError = 'error',
  ExternalDelivered = 'delivered',
}

/**
 * Reason for delivery error
 */

export enum MediumDeliveryError {
  AddressCity = 'address_city',
  AddressCountry = 'address_country',
  AddressInvalid = 'address_invalid',
  AddressLine = 'address_line',
  AddressPostalCode = 'address_postalcode',
  ApicryptAttachmentTooBig = 'apicrypt_attachment_too_big',
  ApicryptMessageTooLong = 'apicrypt_message_too_long',
  DeviceNotAuthorized = 'device_not_authorized',
  DmpUnavailable = 'dmp-unavailable',
  DmpInactive = 'dmp-inactive',
  DmpTerminated = 'dmp-terminated',
  MailAddressInvalid = 'mail_address_invalid',
  MailDoesNotExist = 'mail_does_not_exist',
  MailTimeout = 'mail_timeout',
  MailboxFull = 'mailbox_full',
  MessageContentRejected = 'message_content_rejected',
  MissingTelecom = 'missing_telecom',
  MssMessageTooBig = 'mss_message_too_big',
  Npai = 'npai',
  PdfDimensions = 'pdf_dimensions',
  PdfError = 'pdf_error',
  PdfTooBig = 'pdf_too_big',
  PdfNullBinary = 'pdf_null_binary',
  PersonalMessageTooLong = 'personal_message_too_long',
  RecipientOperatorProblem = 'recipient_operator_problem',
  RefusedMail = 'refused_mail',
  SystemError = 'system-error',
  TemporaryFailure = 'temporary_failure',
  UnclaimedMail = 'unclaimed_mail',
}

export interface SendingStatus {
  id: string;
  sentAt: string;
  value: SendingLifenStatus | SendingExternalStatus;
  error?: MediumDeliveryError;
}
