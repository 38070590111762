import { EnvManager } from '@services/environment/environment.service';
import { logger } from '@services/logger/logger.service';
import { LDClient, LDUser, initialize } from 'launchdarkly-js-client-sdk';

import { FeatureFlagItems, FeatureFlags } from '@honestica/core-apps-common/types';

type OnChangeFlagsPayload = Partial<
  Record<
    FeatureFlagItems,
    {
      current: boolean;
      previous: boolean;
    }
  >
>;

type OnChangeFlagsItem = [
  FeatureFlagItems,
  {
    current: boolean;
    previous: boolean;
  },
];

export class LaunchDarklyService {
  private user: LDUser;

  private ldClient: LDClient;

  private isFlagsReadyEventAlreadySent = false;

  public onFlagsReady?: (flags: Partial<Record<FeatureFlagItems, boolean>>) => void;

  public onFlagsChange?: (flags: Partial<Record<FeatureFlagItems, boolean>>) => void;

  public initialize(u?: LDUser): void {
    if (u) {
      this.user = u;
    }

    if (this.ldClient) {
      this.close();
    }

    this.ldClient = initialize(EnvManager.getLdToken() ?? '', this.user, {
      baseUrl: EnvManager.getLDRelayUrl(),
      eventsUrl: EnvManager.getLDRelayUrl(),
      streamUrl: EnvManager.getLDRelayUrl(),
    });

    this.ldClient.on('change', this.onChange, this);
    this.ldClient.on('ready', this.onReady, this);
    this.ldClient.on('error', this.onError, this);
  }

  private onReady(): void {
    logger.info('HTTP', 'LaunchDarklyService - onReady');
    const allFlags = this.ldClient.allFlags();

    logger.info('HTTP', 'LaunchDarklyService - onReady - Init flags', allFlags);

    // Only emit "onFlagsReady" event once
    if (this.onFlagsReady !== undefined && !this.isFlagsReadyEventAlreadySent) {
      this.onFlagsReady(allFlags);
      this.isFlagsReadyEventAlreadySent = true;
    }
  }

  private onChange(payload: OnChangeFlagsPayload): void {
    const entries = Object.entries(payload) as OnChangeFlagsItem[];

    // Only get flags that have changed
    const changedFlags = entries.reduce((acc, [key, { current, previous }]) => {
      if (current !== previous) {
        acc[key] = current;
      }
      return acc;
    }, {} as FeatureFlags);

    // Not any change, return
    if (Object.keys(changedFlags).length === 0) {
      return;
    }

    logger.info('HTTP', 'LaunchDarklyService - onChange - Update flags', changedFlags);

    if (this.onFlagsChange !== undefined) {
      this.onFlagsChange(changedFlags);
    }
  }

  private onError(error: unknown): void {
    const errorMsg = error instanceof Error ? error.message : JSON.stringify(error);
    logger.error('HTTP', `LaunchDarklyComponent - onError - ${errorMsg}`);
  }

  private close(): void {
    this.ldClient.off('ready', this.onReady, this);
    this.ldClient.off('change', this.onChange, this);
    this.ldClient.off('error', this.onError, this);
    this.ldClient.close();
  }
}
