import { logger } from '@services/logger/logger.service';
import { put, select, takeLatest } from 'redux-saga/effects';

import { SendingRequest, SendingRequestBundle } from '@honestica/core-apps-common/types';

import * as API from '@api';
import { selectInboxDetailDocumentId } from '@store/documents/incoming/inbox.selector';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';

function* closeEnsConversationSaga() {
  const currentDocumentId: SendingRequest['id'] = yield select(selectInboxDetailDocumentId);
  try {
    const { document }: SendingRequestBundle = yield API.closeEnsConversation(currentDocumentId);
    yield put(InboxDocumentsActions.closeEnsConversationSuccess({ document }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to close ens conversation', error);
    yield put(
      InboxDocumentsActions.closeEnsConversationFailure({
        id: currentDocumentId,
        error,
      }),
    );
  }
}

export function* manageEnsInboxDocumentsSagas() {
  yield takeLatest(InboxDocumentsActions.closeEnsConversation.type, closeEnsConversationSaga);
}
