import { ApiDomain } from '@honestica/core-apps-common/types';

export function formatComment(docId: string, comment: string): string {
  return `[${docId}]\n${comment}`;
}
export function isIdValid(docId: string | undefined, apiDomain: ApiDomain): boolean {
  if (!docId) return false;
  const docIdInfo = docId.split('-');

  const [tag, id] = docIdInfo;

  const cleanId = !!id && id.replace(/\D/g, '');

  return (
    docIdInfo.length === 2 &&
    !!tag &&
    !!id &&
    !!cleanId &&
    ((apiDomain === 'lifen' && tag === 'L') || (apiDomain === 'rgds' && tag === 'R')) &&
    id.length === cleanId.length &&
    id.length <= 10 &&
    id.length >= 5
  );
}
