import { useCallback, useMemo } from 'react';

import { defaultDesktopSettings } from '@launcher/utils';
import { useTranslation } from 'react-i18next';

import {
  Button,
  CardBase,
  Checkbox,
  PositiveInteger,
  Space,
  Typography,
} from '@honestica/ui-kit/src';

import { useModal } from '@hooks/useModal.hook';
import { ModalType } from '@store/modal/modal.type';

import {
  CleanOutboxSettingReduxProps,
  cleanOutboxSettingConnector,
} from './CleanOutboxSetting.connector';

import styles from './CleanOutboxSetting.module.less';

export function CleanOutboxSettingComponent(props: CleanOutboxSettingReduxProps) {
  const { t } = useTranslation();
  const { onValueChange } = props;
  const { openModal } = useModal();

  const defaultValue = useMemo(() => {
    if (props.settings.keepFileDuringXDays > 0) return props.settings.keepFileDuringXDays;
    return defaultDesktopSettings.keepFileDuringXDays;
  }, [props.settings]);

  const onToggle = useCallback(() => {
    if (props.value === '' || props.value > 0) {
      onValueChange(0);
    } else {
      onValueChange(defaultValue);
    }
  }, [props.value, onValueChange, defaultValue]);

  return (
    <CardBase className={props.className}>
      <Space direction="vertical">
        <Checkbox disabled={props.disabled} checked={props.value > 0} onClick={onToggle}>
          <Typography.Text strong>{t('desktopSettings.cleanOutbox.checkboxLabel')}</Typography.Text>
        </Checkbox>

        {props.value !== 0 && (
          <>
            <Space>
              <Typography.Text>
                {t('desktopSettings.cleanOutbox.keepFileDurationLabel')}
              </Typography.Text>
              <PositiveInteger
                aria-label={t('desktopSettings.cleanOutbox.keepFileDurationLabel')}
                className={styles.input}
                disabled={props.disabled}
                value={props.value}
                onValueChange={props.onValueChange}
              />
            </Space>

            <Typography.Text className={styles.warning}>
              {t('desktopSettings.cleanOutbox.warning')}
            </Typography.Text>

            <Button
              className="margin-top-xs"
              onClick={() => openModal({ modal: ModalType.cleanOutboxConfirmation })}
            >
              {t('desktopSettings.cleanOutbox.deleteFiles')}
            </Button>
          </>
        )}
      </Space>
    </CardBase>
  );
}

export const CleanOutboxSetting = cleanOutboxSettingConnector(CleanOutboxSettingComponent);
