import { trackingDocumentActionsSaga } from './trackDocumentActions.sagas';
import { trackingDocumentBatchActionsSaga } from './trackDocumentBatchActions.sagas';
import { trackingDocumentDashboardFiltersSagas } from './trackDocumentDashboardFilters.sagas';
import { trackingDocumentENSMessagesSagas } from './trackDocumentENSMessage.sagas';
import { trackingDocumentRecipientsSagas } from './trackDocumentRecipients.sagas';
import { trackingDocumentUpdatesSagas } from './trackDocumentUpdates.sagas';
import { trackingEncounterSagas } from './trackEncounter.sagas';
import { trackingErrorSaga } from './trackErrors.sagas';
import { trackingPageSaga } from './trackPages.sagas';
import { trackingPatientSagas } from './trackPatient.sagas';
import { trackingPatientDirectorySagas } from './trackPatientDirectory';
import { trackingProfessionalDirectorySagas } from './trackProfessionalDirectory';
import { trackingUploadJobActionsSaga } from './trackUploadJob.sagas';

export const trackingSagas = [
  trackingDocumentActionsSaga,
  trackingDocumentBatchActionsSaga,
  trackingDocumentDashboardFiltersSagas,
  trackingDocumentENSMessagesSagas,
  trackingDocumentRecipientsSagas,
  trackingDocumentUpdatesSagas,
  trackingPageSaga,
  trackingPatientSagas,
  trackingUploadJobActionsSaga,
  trackingPatientDirectorySagas,
  trackingProfessionalDirectorySagas,
  trackingErrorSaga,
  trackingEncounterSagas,
];
