import { Professional } from './professional';

export enum JobStatus {
  Completed = 'completed',
  Waiting = 'waiting',
  Active = 'active',
  Delayed = 'delayed',
  Failed = 'failed',
  Paused = 'paused',
}

export enum PatientImportQueue {
  Rollback = 'rollback',
  Import = 'import',
}
export interface JobState<T> {
  jobId: string;
  jobType: string;
  status: JobStatus;
  progress: number;
  result: T;
  timestamp: number;
  userId: string;
}

export interface CsvPatientsImportReport {
  fileError: {
    type: string;
    code: string;
    message: string;
    row: number;
  }[];
  validationError: { lineNumber: number; errors: string[] }[];
  totalRecords: number;
}
export interface PatientsImportReport extends CsvPatientsImportReport {
  createdIds: string[];
  updatedIds: string[];
}

export type PatientsImportJobState = JobState<PatientsImportReport>;

export type RollbackPatientsImportJobState = JobState<undefined>;

export enum PatientsImportTemplate {
  Lifen = 'lifen',
  Doctolib = 'doctolib',
}

export interface PatientsImportJobData {
  csv: string;
  identities: Professional[];
  template: PatientsImportTemplate;
}
