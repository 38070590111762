import { sagaMiddleware } from '@launcher/components/App/reduxSagaContext';
import { Middleware, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { EnvManager } from '@services/environment/environment.service';
import { monitoring } from '@services/monitoring';

import { history } from './history';
import loggingMiddleware from './middleware/loggingMiddleware';
import { createRootReducer } from './reducer';
import { rootSaga } from './sagas';

export const getStore = (middlewares: Middleware[] = []) => {
  const middleware = [
    sagaMiddleware,
    ...getDefaultMiddleware({ serializableCheck: false }),
    loggingMiddleware,
    ...middlewares,
  ];

  const store = configureStore({
    reducer: createRootReducer(),
    enhancers: [monitoring.reduxEnhancer],
    middleware,
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

const store = getStore();

// We allow access of the store only when we are in a Cypress environnement
if (EnvManager.getName() === 'octopus') {
  // eslint-disable-next-line no-underscore-dangle
  window.__store__ = store;
  // eslint-disable-next-line no-underscore-dangle
  window.__history__ = history;
}

export { store, history };
