import { PathsByDashboard } from '@core-components/DocumentsDashboard/documentsDashboard.constant';
import { replaceAttachmentsInMemory } from '@launcher/utils/desktop/file.utils';
import { logger } from '@services/logger/logger.service';
import { NavigateFunction } from 'react-router-dom';
import {
  all,
  call,
  delay,
  getContext,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import {
  DashboardSearchParams,
  DocumentsList,
  IntegrationRequestBundle,
  IntegrationRequestsList,
  IntegrationsDashboardType,
  SendingRequest,
  SendingRequestBundle,
  SendingsDashboardType,
  isIntegrationsDashboardType,
  isSendingDashboardType,
} from '@honestica/core-apps-common/types';
import { isIntegration } from '@honestica/core-apps-common/validators';

import * as API from '@api';
import {
  ARCHIVE,
  DRAFT,
  ERRORS,
  INBOX,
  INTEGRATED,
  SENT,
  WORKLIST,
} from '@constants/documents.constants';
import {
  FetchDocumentAction,
  FetchDocumentsAndNavigateAction,
} from '@store/documents/documents.actions';
import {
  selectDashboardIds,
  selectDashboardSearchParams,
  selectDetailDocument,
  selectDocumentEntity,
} from '@store/documents/documents.selector';
import {
  DocumentDashboardType,
  DocumentDtoWithMetaFromType,
  DocumentsWithErroredActions,
} from '@store/documents/documents.state';
import { ArchivedDocumentsActions } from '@store/documents/incoming/archived.slice';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';
import { IntegratedIntegrationsActions } from '@store/documents/integrations/integrated.slice';
import { selectWorklistDelayedDocumentIds } from '@store/documents/integrations/worklist.selector';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import {
  selectDraftCurrentIdentityReference,
  selectDraftDelayedDocumentIds,
} from '@store/documents/outgoing/draft.selector';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { ErroredDocumentsActions } from '@store/documents/outgoing/errored.slice';
import { SentDocumentsActions } from '@store/documents/outgoing/sent.slice';
import { realtimeCommunicationUpdated } from '@store/externalEvents/externalEvents.action';
import { getAttachmentsFromPayload } from '@utils/attachments.util';
import { isPaginationOutOfBound } from '@utils/pagination.util';
import { cleanFFSearchParams } from '@utils/search.util';

const Actions: Record<DocumentDashboardType, DocumentsWithErroredActions> = {
  [INBOX]: InboxDocumentsActions,
  [ARCHIVE]: ArchivedDocumentsActions,
  [DRAFT]: DraftDocumentsActions,
  [SENT]: SentDocumentsActions,
  [ERRORS]: ErroredDocumentsActions,
  [WORKLIST]: WorklistIntegrationsActions,
  [INTEGRATED]: IntegratedIntegrationsActions,
};

const OneActions = {
  [INBOX]: InboxDocumentsActions,
  [ARCHIVE]: ArchivedDocumentsActions,
  [DRAFT]: DraftDocumentsActions,
  [SENT]: SentDocumentsActions,
  [WORKLIST]: WorklistIntegrationsActions,
  [INTEGRATED]: IntegratedIntegrationsActions,
};

function getOneDocumentSaga(dashboardType: SendingsDashboardType | IntegrationsDashboardType) {
  return function* saga({ payload: { id } }: FetchDocumentAction) {
    const existingDocEntity: DocumentDtoWithMetaFromType<typeof dashboardType> | undefined =
      yield select(selectDocumentEntity, id, dashboardType);

    if (existingDocEntity) {
      yield put(OneActions[dashboardType].setDetailViewDocument({ id }));

      if (
        existingDocEntity.resource.integration &&
        !isIntegration(existingDocEntity.resource.integration) &&
        (dashboardType === DRAFT ||
          dashboardType === SENT ||
          isIntegrationsDashboardType(dashboardType))
      ) {
        yield put(OneActions[dashboardType].fetchOneWithIntegrationData({ id }));
        return;
      }

      // Document entity exists, but document attachments are missing – Fetch only attachments
      if (existingDocEntity.meta.fileStorageIds === undefined) {
        yield put(OneActions[dashboardType].fetchManyAttachments({ id }));
      }
    }

    // Document entity not found – Fetch the whole document
    else {
      yield put(OneActions[dashboardType].fetchOne({ id }));
    }
  };
}

function fetchOneDocumentSaga(dashboardType: SendingsDashboardType | IntegrationsDashboardType) {
  return function* saga({ payload: { id } }: FetchDocumentAction) {
    try {
      const bundle: SendingRequestBundle | IntegrationRequestBundle = isIntegrationsDashboardType(
        dashboardType,
      )
        ? yield API.fetchIntegrationRequest(id, dashboardType)
        : yield API.fetchDocument(id, dashboardType);

      const fileStorageIds = replaceAttachmentsInMemory(
        getAttachmentsFromPayload(bundle),
        dashboardType,
      );

      // Ugly hack: underlying reducers expect attachments to be set in the entities instead of at the root of the bundle
      const bundleWithAttachments = {
        ...bundle,
        entities: {
          ...bundle.entities,
          attachments: bundle.attachments,
        },
      };

      yield put(OneActions[dashboardType].fetchOneSuccess({ bundle: bundleWithAttachments }));
      yield put(OneActions[dashboardType].fetchManyAttachmentsSuccess({ id, fileStorageIds }));
      yield put(OneActions[dashboardType].setDetailViewDocument({ id }));
    } catch (error) {
      logger.error('LOGIC', 'Failed to get document', error);
      yield put(OneActions[dashboardType].fetchOneFailure({ error }));
    }
  };
}

function fetchOneDocumentIfNeededSaga(dashboardType: SendingsDashboardType) {
  return function* saga({ payload }: ReturnType<typeof realtimeCommunicationUpdated>) {
    try {
      const currentDocument: SendingRequest | undefined = yield select(
        selectDetailDocument,
        dashboardType,
      );
      if (currentDocument && payload.resource.id === currentDocument.id) {
        const bundle: SendingRequestBundle | IntegrationRequestBundle = isIntegrationsDashboardType(
          dashboardType,
        )
          ? yield API.fetchIntegrationRequest(currentDocument.id, dashboardType)
          : yield API.fetchDocument(currentDocument.id, dashboardType);

        const fileStorageIds = replaceAttachmentsInMemory(
          getAttachmentsFromPayload(bundle),
          dashboardType,
        );
        yield put(OneActions[dashboardType].fetchOneSuccess({ bundle }));
        yield put(
          OneActions[dashboardType].fetchManyAttachmentsSuccess({
            id: currentDocument.id,
            fileStorageIds,
          }),
        );
      } else {
        logger.info('LOGIC', `Dismissing update on document ${payload.resource.id}`);
      }
    } catch (error) {
      logger.error('LOGIC', 'Failed to get Document', error);
      yield put(OneActions[dashboardType].fetchOneFailure({ error }));
    }
  };
}

function fetchManyDocumentsSaga(dashboardType: DocumentDashboardType) {
  return function* getDocumentsSaga() {
    try {
      const searchParams: DashboardSearchParams = yield select((state) =>
        selectDashboardSearchParams(state, dashboardType),
      );
      const currentIdentity: string | undefined = yield select((state) =>
        selectDraftCurrentIdentityReference(state),
      );

      let fn;
      const params = cleanFFSearchParams({
        searchParams,
        dashboardType,
        currentIdentity,
      });

      if (dashboardType === ERRORS) {
        fn = API.fetchErroredDocuments;
      } else if (isSendingDashboardType(dashboardType)) {
        fn = API.fetchDocuments;
        params.dashboardType = dashboardType;
      } else {
        fn = API.fetchIntegrationRequests;
      }

      const response: DocumentsList | IntegrationRequestsList = yield call(fn, params);

      if (isPaginationOutOfBound(searchParams, response.total)) {
        yield put(Actions[dashboardType].fetchMany({ search: { ...params, page: 1 } }));
      } else {
        yield put(Actions[dashboardType].fetchManySuccess(response));

        // Re-fetch imported documents (indicated by Pusher to update the document status) that were delayed
        // due to the fetchMany request not being finished.
        if (dashboardType === DRAFT || dashboardType === WORKLIST) {
          const actionDelayedDocumentIds = {
            [DRAFT]: selectDraftDelayedDocumentIds,
            [WORKLIST]: selectWorklistDelayedDocumentIds,
          };
          const delayedDocIds: string[] = yield select(actionDelayedDocumentIds[dashboardType]);
          yield all(delayedDocIds.map((id) => put(OneActions[dashboardType].fetchOne({ id }))));
          yield put(OneActions[dashboardType].purgeDocumentIdsToRefetch());
        }
      }
    } catch (error) {
      logger.error('LOGIC', `Failed to fetch "${dashboardType}" dashboard Document list`, error);
      yield put(Actions[dashboardType].fetchManyFailure({ error }));
    }
  };
}

// TODO: Replace this saga when using the cache on Lifen Integration.
function fetchManyDocumentsAndNavigateSaga(dashboardType: IntegrationsDashboardType) {
  return function* fetchDocumentsAndNavigateSaga({ payload }: FetchDocumentsAndNavigateAction) {
    try {
      yield put(Actions[dashboardType].fetchMany({ search: { page: payload.page } }));

      const { success, timeout } = yield race({
        success: take(Actions[dashboardType].fetchManySuccess.type),
        timeout: delay(10 * 1000), // 10 seconds
      });
      if (success) {
        const navigate: NavigateFunction = yield getContext('navigate');
        const dashboardIds: string[] = yield select(selectDashboardIds, dashboardType);
        const documentIdIndex = payload?.navigateToLastDocument ? dashboardIds.length - 1 : 0;
        const documentId = dashboardIds[documentIdIndex];
        logger.info(
          'LOGIC',
          `Successfully fetched ${dashboardType} dashboard documents and navigating to document ${documentId}`,
        );

        navigate(`/apps${PathsByDashboard[dashboardType]}/${documentId}`);
      } else if (timeout) {
        logger.error('LOGIC', `Fetch "${dashboardType}" dashboard documents timed out`, timeout);
        yield put(Actions[dashboardType].fetchManyFailure({ error: timeout }));
      }
    } catch (error) {
      logger.error('LOGIC', `Failed to fetch "${dashboardType}" dashboard documents`, error);
      yield put(Actions[dashboardType].fetchManyFailure({ error }));
    }
  };
}

export function* fetchDocumentsSagas() {
  yield all([
    // Get One – get a document entity from the store, or trigger a fetchOne if it not exists
    // (e.g. when opening a detaiView from the dasboardView)
    takeLatest(InboxDocumentsActions.getOne.type, getOneDocumentSaga(INBOX)),
    takeLatest(ArchivedDocumentsActions.getOne.type, getOneDocumentSaga(ARCHIVE)),
    takeLatest(DraftDocumentsActions.getOne.type, getOneDocumentSaga(DRAFT)),
    takeLatest(SentDocumentsActions.getOne.type, getOneDocumentSaga(SENT)),
    takeLatest(WorklistIntegrationsActions.getOne.type, getOneDocumentSaga(WORKLIST)),
    takeLatest(IntegratedIntegrationsActions.getOne.type, getOneDocumentSaga(INTEGRATED)),

    // Fetch One – always fetch a document
    // (e.g. when updating a document, or triggered by the initial getOne of a document)
    takeLatest(InboxDocumentsActions.fetchOne.type, fetchOneDocumentSaga(INBOX)),
    takeLatest(ArchivedDocumentsActions.fetchOne.type, fetchOneDocumentSaga(ARCHIVE)),
    takeLatest(DraftDocumentsActions.fetchOne.type, fetchOneDocumentSaga(DRAFT)),
    takeLatest(SentDocumentsActions.fetchOne.type, fetchOneDocumentSaga(SENT)),
    takeLatest(WorklistIntegrationsActions.fetchOne.type, fetchOneDocumentSaga(WORKLIST)),
    takeLatest(IntegratedIntegrationsActions.fetchOne.type, fetchOneDocumentSaga(INTEGRATED)),

    // Fetch One with integration – to update existing document entity with detail integration data
    // and update its PDFs/attachments.
    // Note: use the same saga but a different reducer
    takeLatest(DraftDocumentsActions.fetchOneWithIntegrationData.type, fetchOneDocumentSaga(DRAFT)),
    takeLatest(SentDocumentsActions.fetchOneWithIntegrationData.type, fetchOneDocumentSaga(SENT)),
    takeLatest(
      WorklistIntegrationsActions.fetchOneWithIntegrationData.type,
      fetchOneDocumentSaga(WORKLIST),
    ),
    takeLatest(
      IntegratedIntegrationsActions.fetchOneWithIntegrationData.type,
      fetchOneDocumentSaga(INTEGRATED),
    ),

    // Fetch One (realtime communication) – always fetch a document
    takeLatest(realtimeCommunicationUpdated.type, fetchOneDocumentIfNeededSaga(INBOX)),

    // Fetch Many – always fetch documents
    takeLatest(InboxDocumentsActions.fetchMany.type, fetchManyDocumentsSaga(INBOX)),
    takeLatest(ArchivedDocumentsActions.fetchMany.type, fetchManyDocumentsSaga(ARCHIVE)),
    takeLatest(DraftDocumentsActions.fetchMany.type, fetchManyDocumentsSaga(DRAFT)),
    takeLatest(SentDocumentsActions.fetchMany.type, fetchManyDocumentsSaga(SENT)),
    takeLatest(ErroredDocumentsActions.fetchMany.type, fetchManyDocumentsSaga(ERRORS)),
    takeLatest(WorklistIntegrationsActions.fetchMany.type, fetchManyDocumentsSaga(WORKLIST)),
    takeLatest(IntegratedIntegrationsActions.fetchMany.type, fetchManyDocumentsSaga(INTEGRATED)),

    // Fetch Many and navigate to the next detail view page
    takeLatest(
      WorklistIntegrationsActions.fetchManyAndNavigate.type,
      fetchManyDocumentsAndNavigateSaga(WORKLIST),
    ),
    takeLatest(
      IntegratedIntegrationsActions.fetchManyAndNavigate.type,
      fetchManyDocumentsAndNavigateSaga(INTEGRATED),
    ),
  ]);
}
