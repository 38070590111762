import { createReducer } from '@reduxjs/toolkit';

import {
  GetNotificationsSuccess as GetNotificationsSuccessAction,
  getNotificationsSuccess as getNotificationsSuccessAction,
} from './notifications.action';
import { NOTIFICATIONS_INITIAL_STATE, NotificationsState } from './notifications.state';

function getNotificationsSuccess(
  _state: NotificationsState,
  action: ReturnType<GetNotificationsSuccessAction>,
) {
  return {
    ...action.payload,
    loaded: true,
  };
}

export const notificationsReducer = createReducer(NOTIFICATIONS_INITIAL_STATE, {
  [getNotificationsSuccessAction.type]: getNotificationsSuccess,
});
