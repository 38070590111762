import { tracking } from '@services/analytics';
import { takeEvery } from 'redux-saga/effects';

import { removeMessageToAction } from '../helpers/removeMessageToActions.helper';
import { InfoMessageActions, RemoveInfoMessageAction } from '../infoMessages.slice';

function* saga(action: RemoveInfoMessageAction) {
  const callback = removeMessageToAction[action.payload.type];

  tracking.trackRemoveInfoMessage({
    payloadType: action.payload.type,
    id: action.payload.id,
  });

  if (callback) yield callback(action.payload.id);
}

export function* handleRemoveMessageSagas() {
  yield takeEvery(InfoMessageActions.removeOne.type, saga);
}
