import { ROOT_WATCHPATH, SENDING_WATCHPATH } from '@launcher/constants';
import { DesktopSettings, defaultDesktopSettings } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';

import { cloneObj, isSameType } from '@honestica/core-apps-common/utils';

import { getSettingsFromFS } from './getSettingsFromFS.helper';
import { getSettingsFromRegistry } from './getSettingsFromRegistry.helper';

export type DesktopSettingsRetrieved = {
  value: DesktopSettings;
  immutables: (keyof DesktopSettings)[];
};

export async function getAllDesktopSettings(): Promise<DesktopSettingsRetrieved> {
  const settings = cloneObj(defaultDesktopSettings);

  const [fsSettings, registrySettings] = await Promise.all([
    getSettingsFromFS(),
    getSettingsFromRegistry(),
  ]);

  logger.info('DESKTOP', 'Settings from file system', fsSettings);
  logger.info('DESKTOP', 'Settings from registry', registrySettings);

  let key: keyof DesktopSettings;
  // eslint-disable-next-line guard-for-in
  for (key in settings) {
    const settingVal = settings[key];

    const regVal = registrySettings[key];
    if (isSameType(settingVal, regVal)) {
      // Would be better to handle type properly rather than using any
      (settings as any)[key] = regVal;
      continue;
    }

    let fsVal = fsSettings[key];

    // TODO: remove after v5 migration
    if (key === 'stabilityThreshold' && typeof fsVal === 'string') {
      // temp fix for vlegacy 4 setting files where this value is stored as string
      // whereas v5 expect a number
      fsVal = Number(fsVal);
    }

    if (isSameType(settings[key], fsVal)) {
      // Would be better to handle type properly rather than using any
      (settings as any)[key] = fsVal;
    }
  }

  const homedir = window.electron.getHomedir();

  if (!settings.watchPath) {
    settings.watchPath = window.electron.joinPaths(homedir, ROOT_WATCHPATH, SENDING_WATCHPATH);
  }

  logger.info('DESKTOP', 'Merged settings', settings);

  return {
    value: settings,
    immutables: Object.keys(registrySettings) as (keyof DesktopSettings)[],
  };
}
