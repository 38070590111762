import { useSelector } from 'react-redux';

import { Page, Spinner } from '@honestica/ui-kit/src';

import { getIsAppLoading } from '@store/user/user.selector';

import { Apps } from './components/Apps';

export const App = () => {
  const isLoading = useSelector(getIsAppLoading);

  const renderSpinner = () => (
    <Page.Empty>
      <Spinner />
    </Page.Empty>
  );

  return (
    <>
      {isLoading ? (
        renderSpinner()
      ) : (
        <>
          <Apps />
        </>
      )}
    </>
  );
};
