import { BridgeApi } from '@launcher/helpers/desktop';
import { logger } from '@services/logger/logger.service';

import { FlowType } from '@honestica/core-apps-common/types';

import { DRAFT_PATH, INTEGRATIONS_PATH } from '@constants/documents.constants';
import { history } from '@store/history';

import { NOTIFICATION_LOADED_MESSAGE, NOTIFICATION_SHOW_MAIN } from './constants';

class NotificationWindow {
  private win: ReturnType<BridgeApi['createNotificationWindow']> | undefined;

  public get isInitialized() {
    return !!this.win;
  }

  public async init() {
    logger.info('DESKTOP', 'Loading notification window...');
    /*
    Notification window is always running while the app is running.
    We only show or hide it based on events or user interaction.
    This is so it does not go through the app loader every time
    we want to display something inside of it.
   */
    if (this.win) {
      return;
    }

    this.win = window.electron.createNotificationWindow(
      `${window.location.origin}/apps/notification`,
      {
        /* We send an initial message to the notification window so it can get the main window's ID
        WARNING: This will only work with bridge API v1.2.1+, or the polyfill */
        onDidFinishLoad: () => this.sendMessageToNotificationWindow(NOTIFICATION_LOADED_MESSAGE),
      },
    );

    /* Destroy notification window if main app is about to close */
    window.addEventListener('beforeunload', () => {
      this.win?.destroy();
    });

    const ipcRenderer = window.electron.getIpcRenderer();

    /*
     * Show & focus main window when notification window asks for it,
     * and redirect to correct path depending on flow Type.
     */
    const flowTypesPathMap: Record<FlowType, { path: string }> = {
      sending: { path: DRAFT_PATH },
      integration: { path: INTEGRATIONS_PATH },
    };

    Object.entries(flowTypesPathMap).forEach(([flowType, { path }]) => {
      ipcRenderer.on(`${NOTIFICATION_SHOW_MAIN}/${flowType}`, () => {
        window.electron.showCurrentWindow();
        window.electron.focusCurrentWindow();
        window.electron.restoreCurrentWindow();
        history.push(path);
      });
    });

    /* Wait for notification window to send us a message indicating that is has finished loading */
    await new Promise<void>((resolve) => {
      window.electron.getIpcRenderer().once(NOTIFICATION_LOADED_MESSAGE, () => {
        resolve();
      });
    });
    logger.info('DESKTOP', 'Notification window has loaded.');
  }

  public async showNotifWindow() {
    if (this.win?.isVisible?.()) return; // isVisible is not exposed before 100.14.3
    /*
      We normally prevent the window from being destroyed.
      However, we rebuild the window as a safety measure
      if that's the case. Then we wait a little bit
      for the app to boot.
    */
    if (!this.win || this.win.isDestroyed()) {
      throw new Error('Notification window is destroyed');
    }
    this.win.show();
  }

  public sendMessageToNotificationWindow = (channel: string, data?: any) => {
    const webContentsId = this.win?.getWebContentId();
    if (webContentsId) {
      window.electron.getIpcRenderer().sendTo(webContentsId, channel, data);
    }
  };
}

export const notificationWindow = new NotificationWindow();
