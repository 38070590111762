import { logger } from '@services/logger/logger.service';
import { useNavigate } from 'react-router-dom';

import { ModalType } from '@store/modal/modal.type';

export function useModal() {
  const navigate = useNavigate();

  const { search } = window.location;
  const searchParams = new URLSearchParams(search);

  const openModal = ({
    modal,
    urlSearchParams,
  }: {
    modal: ModalType;
    urlSearchParams?: Record<string, string | undefined>;
  }) => {
    logger.info('UI', 'Open Modal', { modal });

    searchParams.set('modal', modal);
    Object.keys(urlSearchParams ?? {}).forEach((key) => {
      searchParams.set(key, urlSearchParams?.[key] ?? '');
    });
    navigate(`?${searchParams}`, { replace: true });
  };

  const closeModal = () => {
    logger.info('UI', 'Close Modal', { modal: searchParams.get('modal') });

    searchParams.delete('modal');
    navigate(`?${searchParams}`, { replace: true });
  };

  return { openModal, closeModal };
}
