import { ReactNode } from 'react';

import cx from 'classnames';

import styles from './CardBase.module.less';

interface Props {
  className?: string;
  children: ReactNode;
}

export const CardBase = (props: Props) => (
  <div className={cx(styles.root, props.className)}>{props.children}</div>
);
