import { createSlice } from '@reduxjs/toolkit';

import { generateDownloadActions } from '../common/reducers/downloadDocuments.reducers';
import { updateDocumentAttachments } from '../common/reducers/fetchAttachments.reducers';
import {
  fetchDocumentsSuccess,
  fetchMany,
  fetchManyFailure,
  fetchManySuccess,
  fetchOne,
  fetchOneFailure,
  fetchOneSuccess,
  setDetailViewDocument,
  setDetailViewDocumentDto,
} from '../common/reducers/fetchDocuments.reducers';
import {
  updateOne,
  updateOneFailure,
  updateOneSuccess,
} from '../common/reducers/updateMeta.reducers';
import { updateSearchParams } from '../common/reducers/updateSearchParams.reducers';
import {
  DetailViewDocumentDtoAction,
  FetchDocumentAction,
  FetchDocumentSuccessAction,
  GetDocumentAction,
  GetDocumentSuccessAction,
  OneAction,
  OneFailureAction,
  OneSuccessAction,
  RemoveDmpSentDocumentAction,
  RemoveDmpSentDocumentSuccessAction,
  ReplaceDmpAction,
  ReplaceDmpSuccessAction,
  ReplaceMssAction,
  SelectDocumentIDAction,
  StoreLocallyToReplaceDmpAction,
  StoreLocallyToReplaceDmpSuccessAction,
} from '../documents.actions';

import { setOverrides, updateOverrides } from './reducers/desktop.settingsOverrides.reducers';
import {
  onRemoveDmpDocumentSuccess,
  onReplaceDmpDocumentSuccess,
  onStoreLocallyToReplaceDmpSentDocumentFailure,
  onStoreLocallyToReplaceDmpSentDocumentSuccess,
} from './reducers/sent.dmpDocument.reducers';
import {
  INITIAL_SENT_DOCUMENTS_STATE,
  INITIAL_SENT_DOCUMENT_METADATA,
  SentDocumentMetadataKey,
} from './sent.state';

const slice = createSlice({
  name: 'sentDocuments',
  initialState: INITIAL_SENT_DOCUMENTS_STATE,
  reducers: {
    selectOne: (state, _: SelectDocumentIDAction) => ({ ...state, selectedId: _.payload }),

    /*
     * DASHBOARD
     */

    /*
     * View
     */

    setOverrides: (state, action) => setOverrides(state, action),
    updateOverrides: (state, action) => updateOverrides(state, action),

    updateSearchParams,

    fetchMany,
    fetchManySuccess: (state, action) =>
      fetchManySuccess(state, action, INITIAL_SENT_DOCUMENT_METADATA),
    fetchManyFailure,
    fetchDocumentsSuccess: (state, action) =>
      fetchDocumentsSuccess(state, action, INITIAL_SENT_DOCUMENT_METADATA),

    /*
     * Entities
     */

    /*
     * DETAIL
     */

    /*
     * View
     */

    getOne: (state, _action: GetDocumentAction) => state,
    setDetailViewDocument: (state, action: GetDocumentSuccessAction) =>
      setDetailViewDocument(state, action, INITIAL_SENT_DOCUMENTS_STATE.detailView),
    setDetailViewDocumentDto: (state, action: DetailViewDocumentDtoAction) =>
      setDetailViewDocumentDto(
        state,
        action,
        INITIAL_SENT_DOCUMENTS_STATE.detailView,
        INITIAL_SENT_DOCUMENT_METADATA,
      ),

    fetchOne,
    fetchOneWithIntegrationData: (state, action: FetchDocumentAction) =>
      updateOne(state, action, SentDocumentMetadataKey.fetchAttachmentsRequest), // to update detail integration data + attachments of document that have integration data (use the same saga but a different reducer)
    fetchOneSuccess: (state, action: FetchDocumentSuccessAction) =>
      fetchOneSuccess(state, action, INITIAL_SENT_DOCUMENT_METADATA), // updates entities
    fetchOneFailure,

    fetchManyAttachments: (state, action: OneAction) =>
      updateOne(state, action, SentDocumentMetadataKey.fetchAttachmentsRequest),
    fetchManyAttachmentsSuccess: updateDocumentAttachments,
    fetchManyAttachmentsFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, SentDocumentMetadataKey.fetchAttachmentsRequest),

    forwardOne: (state, action: OneAction) =>
      updateOne(state, action, SentDocumentMetadataKey.forwardRequest),
    forwardOneSuccess: (state, action: OneSuccessAction) =>
      updateOneSuccess(state, action, SentDocumentMetadataKey.forwardRequest),
    forwardOneFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, SentDocumentMetadataKey.forwardRequest),

    /*
     * Entities
     */

    removeDmp: (state, action: RemoveDmpSentDocumentAction) =>
      updateOne(state, action, SentDocumentMetadataKey.removeDmpRequest),
    removeDmpSuccess: (state, action: RemoveDmpSentDocumentSuccessAction) =>
      onRemoveDmpDocumentSuccess(state, action),
    removeDmpFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, SentDocumentMetadataKey.removeDmpRequest),

    replaceDmp: (state, action: ReplaceDmpAction) =>
      updateOne(state, action, SentDocumentMetadataKey.replaceDmpRequest),
    replaceDmpSuccess: (state, action: ReplaceDmpSuccessAction) =>
      onReplaceDmpDocumentSuccess(state, action),
    replaceDmpFailure: (state, _) =>
      updateOneFailure(state, _, SentDocumentMetadataKey.replaceDmpRequest),

    replaceMss: (state, action: ReplaceMssAction) =>
      updateOne(state, action, SentDocumentMetadataKey.replaceMssRequest),
    replaceMssSuccess: (state, action: OneSuccessAction) =>
      updateOneSuccess(state, action, SentDocumentMetadataKey.replaceMssRequest),
    replaceMssFailure: (state, _) =>
      updateOneFailure(state, _, SentDocumentMetadataKey.replaceMssRequest),

    storeLocallyToReplaceDmp: (state, _action: StoreLocallyToReplaceDmpAction) => state,
    storeLocallyToReplaceDmpSuccess: (state, action: StoreLocallyToReplaceDmpSuccessAction) =>
      onStoreLocallyToReplaceDmpSentDocumentSuccess(state, action),
    storeLocallyToReplaceDmpFailure: (state) =>
      onStoreLocallyToReplaceDmpSentDocumentFailure(state),
  },
});

export const documentSentReducer = slice.reducer;

export const SentDocumentsActions = { ...slice.actions, ...generateDownloadActions(slice.name) };
