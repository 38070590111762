import { ConnectedProps, connect } from 'react-redux';

import { State } from '@store/reducer';
import { getFeatureFlags, getUser, selectActiveApps } from '@store/user/user.selector';

const mapStateToProps = (state: State) => ({
  coreApps: selectActiveApps(state),
  featureFlags: getFeatureFlags(state),
  user: getUser(state),
});

export const mainSidebarConnector = connect(mapStateToProps, {});

export type MainSidebarReduxProps = ConnectedProps<typeof mainSidebarConnector>;
