import {
  Attachment,
  Organization,
  Patient,
  Practitioner,
  PractitionerRole,
} from '@honestica/core-apps-common/types';

export type Entities<T> = { [id: string]: T };

export type EntitiesType = keyof EntitiesState;
export interface EntitiesState {
  Organization: Entities<Organization>;
  Patient: Entities<Patient>;
  PractitionerRole: Entities<PractitionerRole>;
  Practitioner: Entities<Practitioner>;
  Attachments: Entities<Attachment[]>;
}

export const INITIAL_ENTITIES_STATE: EntitiesState = {
  Organization: {},
  Patient: {},
  PractitionerRole: {},
  Practitioner: {},
  Attachments: {},
};
