import { PayloadAction, createAction } from '@reduxjs/toolkit';

import {
  CounterDraft,
  CounterReadyToSend,
  CreateDematRecipient,
  CreatePatient,
  CreatePostalRecipient,
  DmpConnectorSettings,
  DmpDocumentConnector,
  DocumentJobError,
  DocumentPatientDto,
  DocumentRecipientBundle,
  DocumentRecipientDto,
  DocumentVerificationV2,
  DocumentsList,
  EhrPatientIntegration,
  Encounter,
  EntitiesBase,
  ErrorPayload,
  GetDocumentParams,
  Integration,
  IntegrationMode,
  IntegrationRequestBundle,
  IntegrationRequestDto,
  IntegrationRequestsList,
  List,
  MedicalExamType,
  Patient,
  RecipientVerification,
  SearchParams,
  SendingRequest,
  SendingRequestBundle,
  SendingRequestDto,
  SuggestedRecipients,
  UploadSource,
} from '@honestica/core-apps-common/types';

import { DeferredId } from '@store/actionDeferred/actionsDeferred.state';

import { SendingRequestID } from './documents.state';

export type Id = { id: SendingRequestID };
type SendingBundle = { bundle: SendingRequestBundle };
type IntegrationBundle = { bundle: IntegrationRequestBundle };
type Bundle = { bundle: SendingRequestBundle | IntegrationRequestBundle };
type Ids = { ids: SendingRequestID[] };
type FileStorageIds = { fileStorageIds?: string[] };
type FileStorageId = { fileStorageId: string };

/**
 * DASHBOARDs
 */

/**
 * Many
 */

export type BatchPayload = Ids;
export type BatchAction = PayloadAction<BatchPayload>;

type DeferredPayload = { deferredId: DeferredId };

export type BatchDeferredPayload = BatchPayload & DeferredPayload;
export type BatchDeferredAction = PayloadAction<BatchDeferredPayload>;

export type BatchSuccessPayload = Ids & Partial<FileStorageIds>;
export type BatchSuccessAction = PayloadAction<BatchSuccessPayload>;

export type BatchDeferredSuccessPayload = BatchSuccessPayload & DeferredPayload;
export type BatchDeferredSuccessAction = PayloadAction<BatchDeferredSuccessPayload>;

export type BatchFailurePayload = Ids & ErrorPayload;
export type BatchFailureAction = PayloadAction<BatchFailurePayload>;

export type BatchFailureOnlyErrorAction = PayloadAction<ErrorPayload>;

export type BatchDownloadDocumentsPayload = Ids & { fileName?: string };
export type BatchDownloadDocumentsAction = PayloadAction<BatchDownloadDocumentsPayload>;
export type BatchDownloadDocumentsFailureAction = PayloadAction<ErrorPayload>;

/**
 * Search params
 */

export type UpdateSearchParamsPayload = GetDocumentParams;
export type UpdateSearchParamsAction = PayloadAction<UpdateSearchParamsPayload>;

/**
 * Entities
 */
export type UpdateEntitiesPayload = EntitiesBase;
export const updateEntitiesAction = createAction<UpdateEntitiesPayload>(`UpdateEntities`);
/**
 * Fetch Documents
 */

export type FetchDocumentsPayload = GetDocumentParams & {
  currentIdentity?: string;
  clearIdentity?: boolean;
};
export type FetchDocumentsSuccessPayload = DocumentsList & {
  moreBefore?: boolean;
  moreAfter?: boolean;
};

export type FetchDocumentsSuccessFromCachePayload = { documents: SendingRequest[]; total: number };

export type FetchErrorsDocumentsPayload = FetchDocumentsPayload & { showRead?: boolean };
export type FetchDocumentsAction = PayloadAction<FetchErrorsDocumentsPayload>;
export type FetchErrorsDocumentsAction = PayloadAction<FetchErrorsDocumentsPayload>;
export type FetchDocumentsSuccessAction = PayloadAction<FetchDocumentsSuccessPayload>;
export type FetchDocumentsFailureAction = PayloadAction<ErrorPayload>;
export type FetchDocumentsFromCacheSuccessAction =
  PayloadAction<FetchDocumentsSuccessFromCachePayload>;

export type FetchDocumentsAndNavigatePayload = {
  page: number;
  navigateToFirstDocument?: boolean;
  navigateToLastDocument?: boolean;
};
export type FetchDocumentsAndNavigateAction = PayloadAction<FetchDocumentsAndNavigatePayload>;

export type FetchTotalReadyToSendPayload = CounterReadyToSend;
export type FetchTotalReadyToSendSuccessAction = PayloadAction<FetchTotalReadyToSendPayload>;
export type FetchTotalReadyToSendFailureAction = PayloadAction<ErrorPayload>;

export type FetchTotalDraftPayload = CounterDraft;
export type FetchTotalDraftSuccessAction = PayloadAction<FetchTotalDraftPayload>;
export type FetchTotalDraftFailureAction = PayloadAction<ErrorPayload>;

export type UpdateTotalDraftOrReadyToSendPayload = { identity: string | undefined; count: number };
export type IncrementTotalDraftAction = PayloadAction<UpdateTotalDraftOrReadyToSendPayload>;
export type DecrementTotalDraftAction = PayloadAction<UpdateTotalDraftOrReadyToSendPayload>;
export type IncrementTotalReadyToSendAction = PayloadAction<UpdateTotalDraftOrReadyToSendPayload>;
export type DecrementTotalReadyToSendAction = PayloadAction<UpdateTotalDraftOrReadyToSendPayload>;

export type SetCurrentIdentityPayload = {
  identity: string;
};
export type SetCurrentIdentityAction = PayloadAction<SetCurrentIdentityPayload>;

export type FetchIntegrationRequestPayload = GetDocumentParams;
export type FetchIntegrationRequestSuccessPayload = IntegrationRequestsList & {
  moreBefore?: boolean;
  moreAfter?: boolean;
};

export type FetchIntegrationRequestsAction = PayloadAction<FetchIntegrationRequestPayload>;
export type FetchIntegrationRequestsSuccessAction =
  PayloadAction<FetchIntegrationRequestSuccessPayload>;

/**
 * DETAILs
 */

/**
 * One
 */

export type OnePayload = Id;
export type OneAction = PayloadAction<OnePayload>;

export type OneDeferredPayload = OnePayload & DeferredPayload;
export type OneDeferredAction = PayloadAction<OneDeferredPayload>;

export type OneSuccessPayload = OnePayload & Partial<FileStorageIds>;
export type OneSuccessAction = PayloadAction<OneSuccessPayload>;

export type OneDeferredSuccessPayload = OneSuccessPayload & DeferredPayload;
export type OneDeferredSuccessAction = PayloadAction<OneDeferredSuccessPayload>;

export type OneFailurePayload = OnePayload & ErrorPayload;
export type OneFailureAction = PayloadAction<OneFailurePayload>;

export type FailureAction = PayloadAction<ErrorPayload>;

export type SelectDocumentIDAction = PayloadAction<SendingRequestID>;

/**
 * Fetch Document
 */

export type FetchDocumentPayload = Id;
export type FetchDocumentSuccessPayload = Bundle;

export type FetchDocumentAction = PayloadAction<FetchDocumentPayload>;
export type FetchDocumentSuccessAction = PayloadAction<FetchDocumentSuccessPayload>;
export type FetchDocumentFailureAction = PayloadAction<ErrorPayload>;

/**
 * Get Document
 */

export type GetDocumentPayload = Id;
export type GetDocumentSuccessPayload = Id;

export type GetDocumentAction = PayloadAction<GetDocumentPayload>;
export type GetDocumentSuccessAction = PayloadAction<GetDocumentSuccessPayload>;

export type DetailViewDocumentDtoPayload = SendingRequestDto | IntegrationRequestDto;
export type DetailViewDocumentDtoAction = PayloadAction<DetailViewDocumentDtoPayload>;

/**
 * Fetch Document Attachments
 */

export type FetchAttachmentsSuccessPayload = Id & FileStorageIds;
export type FetchAttachmentsSuccessAction = PayloadAction<FetchAttachmentsSuccessPayload>;

/**
 * Update document and meta
 */

export type UpdateDocumentSuccessPayload = { document: SendingRequestDto };
export type UpdateDocumentSuccessAction = PayloadAction<UpdateDocumentSuccessPayload>;

export type UpdateDocumentTypePayload = OnePayload & { docType: string };
export type UpdateDocumentTypeAction = PayloadAction<UpdateDocumentTypePayload>;

interface DatePeriod {
  startPeriod: SendingRequestDto['startPeriod'];
  endPeriod?: SendingRequestDto['endPeriod'];
}
export type UpdateDocumentDatePeriodPayload = OnePayload & { newPeriod: DatePeriod };
export type UpdateDocumentDatePeriodAction = PayloadAction<UpdateDocumentDatePeriodPayload>;

export type UpdateDocumentSenderPayload = OnePayload & {
  newSender: SendingRequestDto['sender'];
  senderName: string;
};
export type UpdateDocumentSenderAction = PayloadAction<UpdateDocumentSenderPayload>;
export type UpdateDocumentSenderSuccessPayload = UpdateDocumentBundleSuccessPayload & {
  senderName: string;
};
export type UpdateDocumentSenderSuccessAction = PayloadAction<UpdateDocumentSenderSuccessPayload>;

export type AddPatientRecipientPayload = OnePayload & {
  documentPatient: Pick<DocumentPatientDto, 'addresses' | 'telecoms'>;
};
export type AddPatientRecipientAction = PayloadAction<AddPatientRecipientPayload>;

export type UpdateDocumentBundleSuccessPayload = SendingBundle;
export type UpdateDocumentBundleSuccessAction = PayloadAction<UpdateDocumentBundleSuccessPayload>;

export type AddPostalRecipientPayload = OnePayload & { postalRecipient: CreatePostalRecipient };
export type AddPostalRecipientAction = PayloadAction<AddPostalRecipientPayload>;

export type AddDematRecipientPayload = OnePayload & { dematRecipient: CreateDematRecipient };
export type AddDematRecipientAction = PayloadAction<AddDematRecipientPayload>;

export type UpdateRecipientPayload = OnePayload & { recipient: DocumentRecipientDto };
export type UpdateRecipientAction = PayloadAction<UpdateRecipientPayload>;

export type UpdateRecipientsPayload = OnePayload & { recipients: DocumentRecipientBundle[] };
export type UpdateRecipientsAction = PayloadAction<UpdateRecipientsPayload>;

export type UpdatePatientPayload = OnePayload & {
  patient: Patient;
  skipGamUpdate?: boolean;
};
export type UpdatePatientAction = PayloadAction<UpdatePatientPayload>;

export type CreatePatientPayload = OnePayload & { patient: CreatePatient };
export type CreatePatientAction = PayloadAction<CreatePatientPayload>;

export type UpdatedPatientPayload = OnePayload & {
  patient: Patient;
  document: SendingRequestDto | undefined;
};
export type UpdatedPatientSuccessAction = PayloadAction<UpdatedPatientPayload>;

export type SearchPatientsPayload = { searchParams: SearchParams };
export type SearchPatientsAction = PayloadAction<SearchPatientsPayload>;

export type SearchPatientsSuccessPayload = { patients: List<Patient> };
export type SearchPatientsSuccessAction = PayloadAction<SearchPatientsSuccessPayload>;

export type SuggestedRecipientsSuccessPayload = { suggestions: SuggestedRecipients };
export type SuggestedRecipientsSuccessAction = PayloadAction<SuggestedRecipientsSuccessPayload>;

export type FetchEncountersPayload = { integrationId: string };
export type FetchEncountersAction = PayloadAction<FetchEncountersPayload>;

export type FetchEncountersSuccessPayload = { encounters: List<Encounter> };
export type FetchEncountersSuccessAction = PayloadAction<FetchEncountersSuccessPayload>;

export type SwitchEncounterPayload = { encounter: Encounter; integrationId: string };
export type SwitchEncounterAction = PayloadAction<SwitchEncounterPayload>;

export type SwitchEncounterSuccessPayload = OnePayload & { encounter: Encounter };
export type SwitchEncounterSuccessAction = PayloadAction<SwitchEncounterSuccessPayload>;

export type FetchEhrPatientsAction = PayloadAction;

export type FetchEhrPatientsSuccessPayload = { ehrPatients: List<EhrPatientIntegration> };
export type FetchEhrPatientsSuccessAction = PayloadAction<FetchEhrPatientsSuccessPayload>;

export type UpdateEhrPatientPayload = OnePayload & { ehrPatientIntegration: EhrPatientIntegration };
export type UpdateEhrPatientAction = PayloadAction<UpdateEhrPatientPayload>;

export type VerificationPayload = { verification: RecipientVerification | DocumentVerificationV2 };
export type RemoveVerificationPayload = OnePayload & VerificationPayload;
export type RemoveVerificationAction = PayloadAction<RemoveVerificationPayload>;
export type RemoveVerificationSuccessPayload = { document: SendingRequestDto };
export type RemoveVerificationSuccessAction = PayloadAction<RemoveVerificationSuccessPayload>;
export type RemoveVerificationFailurePayload = OnePayload & ErrorPayload;
export type RemoveVerificationFailureAction = PayloadAction<RemoveVerificationFailurePayload>;

export type UpdateEhrPatientSuccessPayload = OnePayload & {
  integration: Integration;
  ehrPatient: Patient | undefined;
};
export type UpdateEhrPatientSuccessAction = PayloadAction<UpdateEhrPatientSuccessPayload>;

export interface ToggleConnector {
  integrationId: string;
  connectorId: string;
  integrationMode?: IntegrationMode;
}
export type ToggleConnectorPayload = OnePayload & ToggleConnector;
export type ToggleConnectorAction = PayloadAction<ToggleConnectorPayload>;

export type UpdateConnectorSettingsPayload = OnePayload & {
  integrationId: string;
  connector: DmpDocumentConnector;
};
export type UpdateConnectorSettingsAction = PayloadAction<UpdateConnectorSettingsPayload>;

export type ToggleConnectorIntegrationMode = {
  connectorId: string;
  fallbackReference: string;
  integrationId: string;
  integrationMode: IntegrationMode;
};
export type ToggleConnectorIntegrationModePayload = OnePayload & ToggleConnectorIntegrationMode;
export type ToggleConnectorIntegrationModeAction =
  PayloadAction<ToggleConnectorIntegrationModePayload>;

export type SetCustomEnsMessagePayload = OnePayload & { ensMessage: string };
export type SetCustomEnsMessageAction = PayloadAction<SetCustomEnsMessagePayload>;

export type SetEnsConversationClosedPayload = OnePayload & { isEnsConversationClosed: boolean };
export type SetEnsConversationClosedAction = PayloadAction<SetEnsConversationClosedPayload>;

export type ClearStoragePayload = Partial<FileStorageIds>;
export type ClearStorageAction = PayloadAction<ClearStoragePayload>;

export type DownloadOnePayload = OnePayload;
export type DownloadOneAction = PayloadAction<DownloadOnePayload>;

export type RemoveDmpSentDocumentPayload = OnePayload & {
  integrationId: string;
  connectorId: string;
};
export type RemoveDmpSentDocumentAction = PayloadAction<RemoveDmpSentDocumentPayload>;

export type RemoveDmpSentDocumentSuccessPayload = OnePayload & SendingBundle;
export type RemoveDmpSentDocumentSuccessAction = PayloadAction<RemoveDmpSentDocumentSuccessPayload>;

export type ReplaceDmpPayload = OnePayload &
  FileStorageId & {
    integrationId: string;
    connectorId: string;
    settings: DmpConnectorSettings;
  };
export type ReplaceDmpAction = PayloadAction<ReplaceDmpPayload>;

export type ReplaceDmpSuccessPayload = OnePayload &
  SendingBundle &
  FileStorageId & { uploadSource: UploadSource; fileName?: string };
export type ReplaceDmpSuccessAction = PayloadAction<ReplaceDmpSuccessPayload>;

export type ReplaceDmpFailurePayload = OnePayload &
  SendingBundle &
  FileStorageId & {
    uploadSource: UploadSource;
    fileName: string;
    errors: DocumentJobError[];
  } & ErrorPayload;
export type ReplaceDmpFailureAction = PayloadAction<ReplaceDmpFailurePayload>;

export type StoreLocallyToReplaceDmpPayload = OnePayload & { file: File };
export type StoreLocallyToReplaceDmpAction = PayloadAction<StoreLocallyToReplaceDmpPayload>;

export type StoreLocallyToReplaceDmpSuccessPayload = OnePayload &
  FileStorageId & { fileName?: string };
export type StoreLocallyToReplaceDmpSuccessAction =
  PayloadAction<StoreLocallyToReplaceDmpSuccessPayload>;

export type ReplaceMssPayload = OnePayload & {
  file: File;
  telecoms: string[];
};
export type ReplaceMssAction = PayloadAction<ReplaceMssPayload>;

export type ReplaceMssFailurePayload = OnePayload & {
  errors: DocumentJobError[];
} & ErrorPayload;
export type ReplaceMssFailureAction = PayloadAction<ReplaceMssFailurePayload>;

/*
 * Integration documents
 */

interface DatePeriod {
  startPeriod: IntegrationRequestDto['startPeriod'];
  endPeriod?: IntegrationRequestDto['endPeriod'];
}
export type UpdateIntegrationRequestDatePeriodPayload = OnePayload & { newPeriod: DatePeriod };
export type UpdateIntegrationRequestDatePeriodAction =
  PayloadAction<UpdateIntegrationRequestDatePeriodPayload>;

export type UpdateIntegrationRequestSuccessPayload = IntegrationRequestBundle;
export type UpdateIntegrationRequestSuccessAction =
  PayloadAction<UpdateIntegrationRequestSuccessPayload>;

export type UpdateIntegrationRequestTypePayload = OnePayload & { docType: string };
export type UpdateIntegrationRequestTypeAction = PayloadAction<UpdateIntegrationRequestTypePayload>;

export type UpdateMedicalExamTypePayload = OnePayload & {
  medicalExamType: MedicalExamType | null;
};
export type UpdateMedicalExamTypeAction = PayloadAction<UpdateMedicalExamTypePayload>;
export type UpdateOriginalSenderPayload = OnePayload & {
  originalSender: string | null;
  originalSenderDisplayName: string | null;
};
export type UpdateOriginalSenderAction = PayloadAction<UpdateOriginalSenderPayload>;

export type UpdateDocumentTitlePayload = OnePayload & { documentTitle: string };
export type UpdateDocumentTitleAction = PayloadAction<UpdateDocumentTitlePayload>;
export type FetchOriginalSendersAction = PayloadAction<{
  searchText?: string;
  useGenericOptions?: boolean;
}>;

export type UpdateIntegrationRequestBundleSuccessPayload = IntegrationBundle;
export type UpdateIntegrationRequestBundleSuccessAction =
  PayloadAction<UpdateIntegrationRequestBundleSuccessPayload>;

export type UpdateIntegrationPatientPayload = OnePayload & {
  patient: Patient;
  integrationRequest: IntegrationRequestDto | undefined;
};
export type UpdateIntegrationPatientSuccessAction = PayloadAction<UpdateIntegrationPatientPayload>;
