/* REALTIME */

import { replaceAttachmentsInMemory } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';
import { NavigateFunction } from 'react-router-dom';
import { all, getContext, put, select, takeEvery } from 'redux-saga/effects';

import {
  Attachment,
  DocumentStatus,
  IntegrationRequestBundle,
  IntegrationsDashboardType,
} from '@honestica/core-apps-common/types';

import * as API from '@api';
import { WORKLIST_PATH } from '@constants/documents.constants';
import { selectDashboardIds, selectDashboardIsLoading } from '@store/documents/documents.selector';
import { selectWorklistDetailDocumentId } from '@store/documents/integrations/worklist.selector';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import { getAttachmentEntities } from '@store/entities/entities.selector';
import { Entities } from '@store/entities/entities.state';
import {
  IntegrationRealtimeCommunicationRequestUpdated,
  integrationRealtimeCommunicationRequestUpdated,
} from '@store/externalEvents/externalEvents.action';
import { selectPathName } from '@store/router/router.selector';
import { getAttachmentsFromPayload } from '@utils/attachments.util';
import { isOldDashboardRoute, isOldDetailRoute } from '@utils/route.utils';

function* updateAttachmentsEntities(documentId: string, bundle: IntegrationRequestBundle) {
  const entityAttachments: Attachment[] | undefined = getAttachmentsFromPayload(bundle);
  const entitiesAttachments: Entities<Attachment[]> = yield select(getAttachmentEntities);

  return [
    ...Object.values(entitiesAttachments).flatMap((attachments: Attachment[]) =>
      attachments[0].parentRefId === documentId ? [] : attachments,
    ),
    ...(entityAttachments ?? []),
  ];
}

function* fetchOneDashboardDocument({ documentId }: { documentId: string }) {
  try {
    const bundle: IntegrationRequestBundle = yield API.fetchIntegrationRequest(
      documentId,
      IntegrationsDashboardType.Worklist,
    );

    const isDocumentFetchable = [DocumentStatus.Suspended, DocumentStatus.Draft].includes(
      bundle.document.status,
    );

    if (isDocumentFetchable) {
      const newAttachments: Attachment[] = yield updateAttachmentsEntities(documentId, bundle);

      yield put(
        WorklistIntegrationsActions.fetchOneInDashboardSuccess({
          bundle: {
            ...bundle,
            entities: { ...bundle.entities, attachments: newAttachments },
          },
        }),
      );
    }
  } catch (err) {
    logger.error('LOGIC', '[realtime] Failed to refresh worklist dashboard for one document', err);
  }
}

function* removeOneDashboardDocument({ id }: { id: string }) {
  try {
    yield put(
      WorklistIntegrationsActions.removeOneInDashboardSuccess({
        id,
      }),
    );
  } catch (err) {
    logger.error('LOGIC', '[realtime] Failed to remove one document from worklist dashboard', err);
  }
}

function* refreshOneDocument(
  bundle: IntegrationRequestBundle,
  documentId: string,
  selectedId: string,
) {
  const newAttachments: Attachment[] = yield updateAttachmentsEntities(documentId, bundle);

  yield put(
    WorklistIntegrationsActions.fetchOneSuccess({
      bundle: {
        ...bundle,
        entities: { ...bundle.entities, attachments: newAttachments },
      },
    }),
  );

  if (documentId === selectedId) {
    // Detail view
    const fileStorageIds = replaceAttachmentsInMemory(
      getAttachmentsFromPayload(bundle),
      IntegrationsDashboardType.Worklist,
    );

    yield put(
      WorklistIntegrationsActions.fetchManyAttachmentsSuccess({
        id: bundle.document.id,
        fileStorageIds,
      }),
    );
  }
}

function readIdentityIdFromPathname(pathname: string) {
  return /integrations\/([0-9]+)\/?/i.exec(pathname)?.[1];
}

function* refreshIntegrationRequestSaga({
  payload,
}: ReturnType<IntegrationRealtimeCommunicationRequestUpdated>) {
  try {
    const documentId = payload.resource.id;
    const navigate: NavigateFunction = yield getContext('customNavigate');

    logger.info('LOGIC', `[realtime] refreshing document id: ${documentId}`);

    if (!documentId) return;

    const pathname: string = yield select(selectPathName);
    const selectedId: string = yield select(selectWorklistDetailDocumentId);
    const dashboardIds: string[] = yield select(
      selectDashboardIds,
      IntegrationsDashboardType.Worklist,
    );

    // only listen to changes to documents for the currently selected identity
    const identityId = readIdentityIdFromPathname(pathname);
    const isCurrentIdentity = `Organization-${identityId}` === payload.channel;

    const isCurrentDetailPage = isOldDetailRoute(pathname) && documentId === selectedId;
    const isDocumentInDashboard = dashboardIds.includes(documentId);
    const isCurrentDashboard = isOldDashboardRoute(pathname) && isCurrentIdentity;
    const isDashboardLoading: boolean = yield select(
      selectDashboardIsLoading,
      IntegrationsDashboardType.Worklist,
    );

    const shouldRefreshDocument =
      isCurrentDetailPage || (isCurrentDashboard && isDocumentInDashboard);

    if (!shouldRefreshDocument) {
      // Pusher indicates that an imported document has been updated, but the fetchMany request is not finished yet.
      // In this case, we delay the refresh of the document, by storing its ID in the delayedIdsToRefresh array.
      if (isDashboardLoading) {
        yield put(WorklistIntegrationsActions.delayedDocumentIdToRefresh({ id: documentId }));
      } else if (isCurrentDashboard) {
        yield fetchOneDashboardDocument({ documentId });
      }
      return;
    }

    const bundle: IntegrationRequestBundle = yield API.fetchIntegrationRequest(
      documentId,
      IntegrationsDashboardType.Worklist,
    );

    // Do not refresh document already sent or cancelled
    const isDocumentNotRefreshable = [DocumentStatus.Active, DocumentStatus.Cancelled].includes(
      bundle.document.status,
    );

    if (isCurrentDetailPage && isDocumentNotRefreshable) {
      navigate(WORKLIST_PATH);
    } else if (isCurrentDashboard && isDocumentNotRefreshable) {
      yield removeOneDashboardDocument({ id: documentId });
    } else {
      yield refreshOneDocument(bundle, documentId, selectedId);
    }
  } catch (err) {
    logger.error('LOGIC', '[realtime] Failed to refresh worklist document', err);
  }
}

export function* integrationRequestRealtimeSagas() {
  yield all([
    takeEvery(integrationRealtimeCommunicationRequestUpdated.type, refreshIntegrationRequestSaga),
  ]);
}
