import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { NavigateFunction } from 'react-router-dom';
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import {
  DashboardSearchParams,
  FeatureFlags,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import { splitDocument, splitDocuments } from '@api';
import { DRAFT, DRAFT_PATH } from '@constants/documents.constants';
import {
  selectDashboardSearchParams,
  selectManyDocumentsFileStorageIds,
} from '@store/documents/documents.selector';
import { selectDraftCurrentIdentityReference } from '@store/documents/outgoing/draft.selector';
import { removeFromSelection, removeManyFromSelection } from '@store/selection/selection.action';
import { getFeatureFlags } from '@store/user/user.selector';
import {
  refreshDraftCacheAfterActionOnManyDocuments,
  refreshDraftCacheAfterActionOnOneDocument,
} from '@utils/caching.util';
import { identityReferenceToUrlIdentity } from '@utils/identities.util';

import { BatchAction, OneAction } from '../../documents.actions';
import { DraftDocumentsActions } from '../draft.slice';

function* splitOneDocumentSaga({ payload: { id } }: OneAction) {
  const navigate: NavigateFunction = yield getContext('customNavigate');
  const queryClient: QueryClient = yield getContext('queryClient');
  const featureFlags: FeatureFlags = yield select(getFeatureFlags);
  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, [id], DRAFT);
  const currentIdentity: string | undefined = yield select((state) =>
    selectDraftCurrentIdentityReference(state),
  );
  const path = currentIdentity
    ? `${DRAFT_PATH}/${identityReferenceToUrlIdentity(currentIdentity)}`
    : DRAFT_PATH;
  try {
    yield splitDocument(id);
    yield put(DraftDocumentsActions.splitOneSuccess({ id, fileStorageIds }));
    const searchParams: DashboardSearchParams = yield select((state) =>
      selectDashboardSearchParams(state, DRAFT),
    );
    refreshDraftCacheAfterActionOnOneDocument({
      documentId: id,
      currentIdentity,
      search: { searchParams, featureFlags },
      queryClient,
    });
    yield put(DraftDocumentsActions.fetchDraftCounters);

    yield put(removeFromSelection({ dashboard: SendingsDashboardType.Draft, id }));
    navigate(path);
  } catch (error) {
    logger.error('LOGIC', `Failed to split document n°${id}`, error);
    yield put(
      DraftDocumentsActions.splitOneFailure({
        id,
        error,
      }),
    );
  }
}

function* splitManyDocumentsAsync({ payload: { ids } }: BatchAction) {
  const queryClient: QueryClient = yield getContext('queryClient');
  const featureFlags: FeatureFlags = yield select(getFeatureFlags);
  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, ids, DRAFT);
  const currentIdentity: string | undefined = yield select((state) =>
    selectDraftCurrentIdentityReference(state),
  );
  try {
    yield put(DraftDocumentsActions.updateLoadingDocumentIds({ ids }));
    yield call(splitDocuments, ids);
    yield put(removeManyFromSelection({ dashboard: SendingsDashboardType.Draft, ids }));
    yield put(DraftDocumentsActions.splitManySuccess({ ids, fileStorageIds }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to split documents', error);
    yield put(
      DraftDocumentsActions.splitManyFailure({
        ids,
        error,
      }),
    );
  } finally {
    const searchParams: DashboardSearchParams = yield select((state) =>
      selectDashboardSearchParams(state, DRAFT),
    );
    refreshDraftCacheAfterActionOnManyDocuments({
      documentIds: ids,
      currentIdentity,
      search: { searchParams, featureFlags },
      queryClient,
    });
    // Fetch documents draft/readyToSend counters
    yield put(DraftDocumentsActions.fetchDraftCounters);
    yield put(DraftDocumentsActions.fetchTotalReadyToSend);
    yield put(DraftDocumentsActions.flushLoadingDocumentIds());
  }
}

export function* splitDraftDocumentsSagas() {
  yield all([
    takeLatest(DraftDocumentsActions.splitOne.type, splitOneDocumentSaga),
    takeLatest(DraftDocumentsActions.splitMany.type, splitManyDocumentsAsync),
  ]);
}
