import { PayloadAction, createReducer } from '@reduxjs/toolkit';

import {
  Organization,
  Practitioner,
  ProfessionalBase,
  SearchDirectoriesResultWithContacts,
} from '@honestica/core-apps-common/types';

import {
  flushSearchedEntities,
  searchDirectories,
  searchDirectoriesFailure,
  searchDirectoriesSuccess,
  setSearchDirectoriesLoading,
} from './searchDirectories.actions';
import {
  SEARCH_DIRECTORIES_INITIAL_STATE,
  SearchDirectoriesState,
} from './searchDirectories.state';

function setLoading(state: SearchDirectoriesState): SearchDirectoriesState {
  return {
    ...state,
    loading: true,
  };
}

function search(state: SearchDirectoriesState): SearchDirectoriesState {
  return {
    ...state,
    error: false,
    loading: true,
  };
}

function searchFailure(state: SearchDirectoriesState): SearchDirectoriesState {
  return {
    ...state,
    error: true,
    loading: false,
  };
}

function searchSuccess(
  state: SearchDirectoriesState,
  action: PayloadAction<SearchDirectoriesResultWithContacts>,
): SearchDirectoriesState {
  const results = action.payload;

  const contacts = results.contacts.list.map((contact: ProfessionalBase) => ({
    resourceId: contact.id,
    resourceType: contact.type,
  }));

  const practitioners = results.practitioners.list.map(
    (practitioner: Practitioner) => practitioner.id,
  );

  const organizations = results.organizations.list.map(
    (organization: Organization) => organization.id,
  );

  return {
    ...state,
    error: false,
    loading: false,
    contacts,
    organizations,
    practitioners,
    totals: {
      contacts: results.contacts.total,
      practitioners: results.practitioners.total,
      organizations: results.organizations.total,
    },
  };
}

function flushEntities(state: SearchDirectoriesState): SearchDirectoriesState {
  return {
    ...state,
    error: false,
    loading: false,
    contacts: [],
    organizations: [],
    practitioners: [],
    totals: {
      contacts: 0,
      practitioners: 0,
      organizations: 0,
    },
  };
}

export const searchDirectoriesReducer = createReducer(SEARCH_DIRECTORIES_INITIAL_STATE, {
  [setSearchDirectoriesLoading.type]: setLoading,
  [flushSearchedEntities.type]: flushEntities,
  [searchDirectories.type]: search,
  [searchDirectoriesSuccess.type]: searchSuccess,
  [searchDirectoriesFailure.type]: searchFailure,
});
