import { PayloadAction } from '@reduxjs/toolkit';
import { logger } from '@services/logger/logger.service';
import { all, takeLatest } from 'redux-saga/effects';

import { authenticateUserSuccess } from '../user/user.actions';
import { UserData } from '../user/user.state';

const onAuth = ({ payload }: PayloadAction<UserData>) => {
  const { uuid, token } = payload;

  logger.init(uuid, token);
};

function* loggerSagas() {
  yield all([takeLatest(authenticateUserSuccess.type, onAuth)]);
}

export { loggerSagas };
