import { MAX_JOB_PROCESSING_COUNT, UPLOAD_DEFAULT_RETRY_DELAY } from '@launcher/constants';
import { fileToBuffer, getFileInMemory, isDesktopApp, removeFilesInMemory } from '@launcher/utils';
import { PayloadAction } from '@reduxjs/toolkit';
import { tracking } from '@services/analytics';
import { HttpError } from '@services/http/http.error.service';
import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { call, delay, getContext, put, select } from 'redux-saga/effects';

import {
  DashboardSearchParams,
  DocumentJobError,
  DocumentJobStatus,
  FeatureFlags,
  FlowType,
  IntegrationsDashboardType,
  Professional,
  SendingRequestBundle,
  SendingRequestDto,
  SendingsDashboardType,
  UploadDocumentJob,
} from '@honestica/core-apps-common/types';
import {
  MAX_FILE_HEIGHT_INTEGRATION,
  MAX_FILE_WIDTH_INTEGRATION,
  OFFSET_INTEGRATIONS_FILES,
  PDF_PAGES_LIMIT_INTEGRATIONS,
} from '@honestica/lifen-pdf-viewer/src/utilsLib/constants';

import * as API from '@api';
import { createIntegrationRequest, createDocument as documentsCreateDocument } from '@api';
import { DesktopActions } from '@store/desktop/desktop.action';
import { selectDashboardSearchParams } from '@store/documents/documents.selector';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import {
  selectUploadDocumentJobs,
  selectUploadIntegrationJobs,
} from '@store/uploadDocuments/uploadDocuments.selector';
import { UserData } from '@store/user/user.state';
import { addDraftDocumentInCache, getDashboardDocumentsQueryKey } from '@utils/caching.util';
import { checkFileForErrors } from '@utils/checkFile.util';
import { getCreateIntegrationRequest, getUploadDocumentPayload } from '@utils/document.util';
import { professionalToIdentityReference } from '@utils/identities.util';

import { DraftDocumentsActions } from '../documents/outgoing/draft.slice';
import { IntegrationsActions } from '../integrations/integrations.slice';
import {
  notificationAddError,
  notificationAddSuccess,
} from '../notificationWindow/notificationWindow.action';
import { UploadDocumentsActions } from '../uploadDocuments/uploadDocuments.slice';
import { getFeatureFlags, getUser, selectIdentitiesWithoutDevice } from '../user/user.selector';

export const refreshDashboard = {
  integration: () => WorklistIntegrationsActions.fetchMany({}),
  sending: () => DraftDocumentsActions.fetchMany({}),
};

interface RefreshIntegrationDashboardCacheProps {
  queryClient: QueryClient;
}
interface RefreshSendingDashboardCacheProps {
  newDocument: SendingRequestDto;
  currentIdentity: string;
  hasManyIdentities: boolean;
  searchParams: DashboardSearchParams;
  queryClient: QueryClient;
  featureFlags: FeatureFlags;
}

// utils

export const refreshDashboardCache = {
  integration: ({ queryClient }: RefreshIntegrationDashboardCacheProps) =>
    queryClient.invalidateQueries({
      queryKey: getDashboardDocumentsQueryKey({
        dashboardType: IntegrationsDashboardType.Worklist,
      }),
    }),
  sending: ({
    newDocument,
    currentIdentity,
    hasManyIdentities,
    searchParams,
    queryClient,
    featureFlags,
  }: RefreshSendingDashboardCacheProps) => {
    addDraftDocumentInCache({
      newDocument,
      identity: currentIdentity,
      refetchType: 'active',
      searchParams,
      queryClient,
      featureFlags,
    });
    if (hasManyIdentities) {
      addDraftDocumentInCache({
        newDocument,
        refetchType: 'active',
        searchParams,
        queryClient,
        featureFlags,
      });
    }
  },
};

export const jobFailure: Record<
  FlowType,
  (args: ReturnType<typeof UploadDocumentsActions.jobUploadFailure>['payload']) => any
> = {
  integration: (args) => IntegrationsActions.jobUploadFailure(args),
  sending: (args) => UploadDocumentsActions.jobUploadFailure(args),
};

export const jobSuccess: Record<
  FlowType,
  (args: ReturnType<typeof UploadDocumentsActions.jobUploadSuccess>['payload']) => any
> = {
  integration: (args) => IntegrationsActions.jobUploadSuccess(args),
  sending: (args) => UploadDocumentsActions.jobUploadSuccess(args),
};
export const jobProcess: Record<
  FlowType,
  (args: ReturnType<typeof UploadDocumentsActions.jobUploadProcess>['payload']) => any
> = {
  integration: (args) => IntegrationsActions.jobUploadProcess(args),
  sending: (args) => UploadDocumentsActions.jobUploadProcess(args),
};

export const resetJobs: Record<
  FlowType,
  typeof UploadDocumentsActions.resetUploadJobs | typeof IntegrationsActions.resetUploadJobs
> = {
  integration: IntegrationsActions.resetUploadJobs,
  sending: UploadDocumentsActions.resetUploadJobs,
};

export const uploadJobsState = {
  integration: selectUploadIntegrationJobs,
  sending: selectUploadDocumentJobs,
};

export const createSaga: Record<FlowType, (job: UploadDocumentJob) => Generator> = {
  sending: createSendingRequestSaga,
  integration: createIntegrationRequestSaga,
};

// private sagas

export function* createSendingRequestSaga(job: UploadDocumentJob) {
  try {
    const user: UserData = yield select(getUser);
    const document = getUploadDocumentPayload({ job, user });
    const newDoc: { id: string } = yield call(documentsCreateDocument, document);
    yield put(jobSuccess.sending({ job, documentId: newDoc.id }));
  } catch (error) {
    if (error instanceof HttpError && error.statusCode === 429) {
      const retryAfter = error.headers?.get('retry-after');
      const retryDelay = retryAfter ? parseInt(retryAfter, 10) * 1000 : UPLOAD_DEFAULT_RETRY_DELAY;

      yield delay(retryDelay);
      yield call(createSaga.sending, job);
    } else {
      logger.error('LOGIC', 'Failed to upload job', error);
      yield put(
        jobFailure.sending({
          job,
          errors: [
            error instanceof Error && error.message === 'Suspicious file'
              ? DocumentJobError.SuspiciousFile
              : DocumentJobError.UploadFailed,
          ],
          error,
        }),
      );
    }
  }
}

export function* createIntegrationRequestSaga(job: UploadDocumentJob) {
  try {
    const user: UserData = yield select(getUser);
    const integrationRequest = getCreateIntegrationRequest({ job, user });
    const newDoc: { id: string } = yield call(createIntegrationRequest, integrationRequest);
    yield put(jobSuccess.integration({ job, documentId: newDoc.id }));
  } catch (error) {
    if (error instanceof HttpError && error.statusCode === 429) {
      yield delay(1000);
      yield call(createSaga.integration, job);
    } else {
      logger.error('LOGIC', 'Failed to upload integration job', error);
      yield put(
        jobFailure.integration({
          job,
          errors: [
            error instanceof Error && error.message === 'Suspicious file'
              ? DocumentJobError.SuspiciousFile
              : DocumentJobError.UploadFailed,
          ],
          error,
        }),
      );
    }
  }
}

export function* processJob({ job, flowType }: { job: UploadDocumentJob; flowType: FlowType }) {
  yield put(jobProcess[flowType]({ job }));

  const { fileName, fileStorageId } = job;
  const options =
    flowType === 'integration'
      ? {
          offsetSizePage: OFFSET_INTEGRATIONS_FILES,
          maxPageNumber: PDF_PAGES_LIMIT_INTEGRATIONS,
          allowFileWithRotate: true,
          maxWidth: MAX_FILE_WIDTH_INTEGRATION,
          maxHeight: MAX_FILE_HEIGHT_INTEGRATION,
        }
      : {};
  try {
    const file: Buffer = yield call(fileToBuffer, getFileInMemory(fileStorageId) as File);
    const errors: DocumentJobError[] = yield call(checkFileForErrors, file, fileName, options);
    if (errors.length > 0) {
      yield put(jobFailure[flowType]({ job, errors, error: undefined }));
      return;
    }

    yield call(createSaga[flowType], job);
  } catch (error) {
    logger.error('LOGIC', 'Failed to process job', error);
    yield put(jobFailure[flowType]({ job, errors: [DocumentJobError.Default], error }));
  }
}

export function* checkJob({
  action,
  flowType,
}: {
  action?: PayloadAction<UploadDocumentJob>;
  flowType: FlowType;
}) {
  const jobs: UploadDocumentJob[] = yield select(uploadJobsState[flowType]);
  const PendingJobs = jobs.filter((job) => job.status === 'PENDING');
  const ProcessingJobs = jobs.filter((job) => job.status === 'PROCESSING');

  if (ProcessingJobs.length >= MAX_JOB_PROCESSING_COUNT) {
    return;
  }
  if (PendingJobs.length === 0) {
    return;
  }

  if (action?.payload && action?.payload.status !== 'PENDING') {
    return;
  }

  yield call(processJob, { flowType, job: action?.payload ?? PendingJobs[0] });
}

// public sagas handlers

export function checkAndProcessJobSaga(flowType: FlowType) {
  return function* handleNewJob(action: PayloadAction<UploadDocumentJob>) {
    yield call(checkJob, { flowType, action });
  };
}

export function createHandleJobFailureSaga(flowType: FlowType) {
  return function* handleJobFailure({
    payload,
  }: ReturnType<typeof UploadDocumentsActions.jobUploadFailure>) {
    const jobs: UploadDocumentJob[] = yield select(uploadJobsState[flowType]);
    const PendingJobs = jobs.filter((j) => j.status === DocumentJobStatus.Pending);
    const ProcessingJobs = jobs.filter((job) => job.status === 'PROCESSING');
    const FinishedJobs = jobs.filter(
      (j) => j.status === DocumentJobStatus.Processed || j.status === DocumentJobStatus.Failed,
    );

    if (PendingJobs.length < 1 && ProcessingJobs.length < 1) {
      if (FinishedJobs.length > 1) {
        tracking.trackBatchFileUploadEnd(FinishedJobs.length);
      }
      yield put(resetJobs[flowType]());
    }

    const {
      job: { uploadSource, filePath, id, fileStorageId, fileName },
      errors,
    } = payload;
    yield put(notificationAddError({ id, errors, fileName, uploadSource, flowType }));
    yield call(removeFilesInMemory, [fileStorageId]);

    if (isDesktopApp() && uploadSource === 'watcher') {
      yield put(DesktopActions.moveNonImportedFileToError({ filePath, flowType }));
    }

    if (PendingJobs.length > 0) {
      yield call(checkJob, { flowType });
    }
  };
}

export function createHandleJobSuccessSaga(flowType: FlowType) {
  return function* handleJobSuccess({
    payload,
  }: ReturnType<typeof UploadDocumentsActions.jobUploadSuccess>) {
    const featureFlags: FeatureFlags = yield select(getFeatureFlags);
    const jobs: UploadDocumentJob[] = yield select(uploadJobsState[flowType]);

    const PendingJobs = jobs.filter((job) => job.status === DocumentJobStatus.Pending);
    const ProcessingJobs = jobs.filter((job) => job.status === 'PROCESSING');
    const FinishedJobs = jobs.filter(
      (j) => j.status === DocumentJobStatus.Processed || j.status === DocumentJobStatus.Failed,
    );

    if (PendingJobs.length < 1 && ProcessingJobs.length < 1) {
      if (FinishedJobs.length > 1) {
        tracking.trackBatchFileUploadEnd(FinishedJobs.length);
      }
      yield put(resetJobs[flowType]());
    }

    const queryClient: QueryClient = yield getContext('queryClient');

    const { uploadSource, filePath, id, fileStorageId, fileName } = payload.job;
    yield put(notificationAddSuccess({ id, fileName, flowType }));
    yield call(removeFilesInMemory, [fileStorageId]);

    if (flowType === 'sending') {
      // todo : add a try catch here?
      const bundle: SendingRequestBundle = yield call(
        API.fetchDocument,
        payload.documentId,
        SendingsDashboardType.Draft,
      );
      const documentIdentity = professionalToIdentityReference(bundle.document.sender);
      const identities: Professional[] = yield select(selectIdentitiesWithoutDevice);
      const hasManyIdentities = identities.length > 1;
      const searchParams: DashboardSearchParams = yield select((state) =>
        selectDashboardSearchParams(state, SendingsDashboardType.Draft),
      );
      yield call(refreshDashboardCache[flowType], {
        newDocument: bundle.document,
        currentIdentity: documentIdentity,
        hasManyIdentities,
        searchParams,
        queryClient,
        featureFlags,
      });
      yield put(
        DraftDocumentsActions.incrementDraftCounter({ identity: documentIdentity, count: 1 }),
      );
    } else {
      yield put(refreshDashboard[flowType]());
    }

    if (isDesktopApp() && uploadSource === 'watcher') {
      yield put(DesktopActions.moveImportedFileToSuccess({ filePath, flowType }));
    }

    if (PendingJobs.length > 0) {
      yield call(checkJob, { flowType });
    }
  };
}
