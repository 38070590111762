import { logger } from '@services/logger/logger.service';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import { Practitioner } from '@honestica/core-apps-common/types';

import { getPractitioner } from '@api';

import { getPractitionerEntities } from '../entities/entities.selector';
import { Entities } from '../entities/entities.state';

import {
  FindPractitioner,
  findPractitioner,
  findPractitionerFailure,
  findPractitionerSuccess,
} from './practitionerDetail.action';

export function* findPractitionersAsync({ payload }: ReturnType<FindPractitioner>) {
  const practitioners: Entities<Practitioner> = yield select(getPractitionerEntities);
  const id: string = payload;

  try {
    if (!id) {
      throw new Error('Id params is mandatory to fetch practitioner');
    }

    if (practitioners[id]) {
      yield put({
        type: findPractitionerSuccess.type,
        payload: practitioners[id],
      });
    }

    const practitioner: Practitioner = yield getPractitioner({
      id,
    });

    if (!practitioner) {
      throw new Error(`Practitioner ${id} not found`);
    }

    yield put({ type: findPractitionerSuccess.type, payload: practitioner });
  } catch (e) {
    logger.error('LOGIC', 'Failed to find practitioner', e);
    yield put({ type: findPractitionerFailure.type });
  }
}

export function* practitionerDetailSagas() {
  yield all([takeEvery(findPractitioner.type, findPractitionersAsync)]);
}
