import {
  Attachment,
  IntegrationRequestBundle,
  SendingRequestBundle,
} from '@honestica/core-apps-common/types';

export function getPageCountFromAttachments(attachments: Attachment[] | undefined): number | null {
  if (!attachments?.length) return null;
  return attachments[0]?.pagesCount ?? null;
}

export const getAttachmentsFromPayload = (
  bundle: IntegrationRequestBundle | SendingRequestBundle,
): Attachment[] => {
  if (bundle.entities?.attachments) return bundle.entities.attachments;

  return bundle.attachments ?? [];
};
