import { LOGIN_PARTITION } from '@launcher/constants';
import { useTranslation } from 'react-i18next';

import { Button, Collapse, Icon, Space, Typography } from '@honestica/ui-kit/src';

async function cleanRestart() {
  await window.electron.clearCache();
  await window.electron.clearCache(LOGIN_PARTITION);
  await window.electron.clearSessionStorageData({ options: {} });
  await window.electron.clearSessionStorageData({ partition: LOGIN_PARTITION, options: {} });
  const redirectUrl = `${window.location.origin}/apps/`;
  window.electron?.redirectTo(redirectUrl);
}

export function CleanCacheAndRestart() {
  const { t } = useTranslation();

  return (
    <Collapse>
      <Collapse.Panel header={t('desktopSettings.cache.title')} key="1">
        <Space direction="vertical">
          <Typography.Text>{t('desktopSettings.cache.description')}</Typography.Text>

          <Button onClick={cleanRestart}>
            <Space>
              <Icon name="Trash" size={12} />
              {t('desktopSettings.cache.button')}
            </Space>
          </Button>
        </Space>
      </Collapse.Panel>
    </Collapse>
  );
}
