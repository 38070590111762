import { DocumentVerification, DocumentVerificationTypes } from '@honestica/core-apps-common/types';

const WHITELIST: DocumentVerification[] = [
  DocumentVerificationTypes.integration_missing,
  DocumentVerificationTypes.ai_not_available,
];

export function isDocumentVerification(verification: DocumentVerification) {
  return WHITELIST.includes(verification);
}
