import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  createUploadAllDocsSaga,
  createUploadDocumentFromButtonSaga,
  createUploadDocumentFromDnDSaga,
  createUploadDocumentFromWatcherSaga,
} from '@store/upload/addNewFile.sagas';

import { UploadDocumentsActions } from '../uploadDocuments.slice';

export function* uploadDocumentsSagas() {
  yield all([
    takeLatest(
      UploadDocumentsActions.uploadAllDocsFromWatchedFolder.type,
      createUploadAllDocsSaga('sending'),
    ),
  ]);
  yield all([
    takeLatest(
      UploadDocumentsActions.uploadFromButton.type,
      createUploadDocumentFromButtonSaga('sending'),
    ),
  ]);
  yield all([
    takeEvery(
      UploadDocumentsActions.uploadFromWatcher.type,
      createUploadDocumentFromWatcherSaga('sending'),
    ),
  ]);
  yield all([
    takeLatest(
      UploadDocumentsActions.uploadFromDnD.type,
      createUploadDocumentFromDnDSaga('sending'),
    ),
  ]);
}
