import { DocumentJobError, FlowType, UploadDocumentJob } from '@honestica/core-apps-common/types';

export type NotificationJobSuccess = {
  id: UploadDocumentJob['id'];
  fileName: string;
  flowType: FlowType;
};

export interface NotificationJobError extends NotificationJobSuccess {
  errors: DocumentJobError[];
  uploadSource: UploadDocumentJob['uploadSource'];
  flowType: FlowType;
}

export type NotificationState = {
  pending: number;
  success: NotificationJobSuccess[];
  errors: NotificationJobError[];
};

export const INITIAL_STATE: NotificationState = {
  pending: 0,
  errors: [],
  success: [],
};
