import { moveItemAtIndexInSelection, swapItemWithNextInSelection } from '@lifen-sending/utils';
import { PayloadAction, createReducer } from '@reduxjs/toolkit';

import {
  IntegrationRequestDto,
  IntegrationsDashboardType,
  SendingRequestDto,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import { FetchIntegrationRequestsSuccessAction } from '@store/documents/documents.actions';
import { IntegratedIntegrationsActions } from '@store/documents/integrations/integrated.slice';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';

import {
  MoveInsideSelectionPayload,
  RemoveFromSelection,
  RemoveManyFromSelection,
  SetPositionInsideSelection,
  UpdateSelection,
  moveDownInsideSelection,
  moveUpInsideSelection,
  removeFromSelection,
  removeManyFromSelection,
  setPositionInsideSelection,
  updateSelection,
} from './selection.action';
import { INITIAL_SELECTION_STATE, SelectionState } from './selection.state';

function updateSelectionState(
  state: SelectionState,
  action: ReturnType<UpdateSelection>,
): SelectionState {
  return {
    ...state,
    [action.payload.dashboard]: action.payload.ids,
  };
}

function removeItemFromSelection(
  state: SelectionState,
  action: ReturnType<RemoveFromSelection>,
): SelectionState {
  const currentSelection = state[action.payload.dashboard];
  const index = currentSelection.indexOf(action.payload.id);
  if (index < 0) {
    return state;
  }

  const selection = [...currentSelection];
  selection.splice(index, 1);

  return {
    ...state,
    [action.payload.dashboard]: selection,
  };
}

function removeItemsFromSelection(
  state: SelectionState,
  action: ReturnType<RemoveManyFromSelection>,
): SelectionState {
  const { dashboard, ids } = action.payload;
  const currentSelection = state[dashboard];

  if (ids.length === 0) {
    return state;
  }

  const selection = currentSelection.filter((id) => !ids.includes(id));

  return {
    ...state,
    [dashboard]: selection,
  };
}

function moveItemDownInsideSelection(
  state: SelectionState,
  action: PayloadAction<MoveInsideSelectionPayload>,
): SelectionState {
  const currentSelection = state[action.payload.dashboard];
  const index = currentSelection.indexOf(action.payload.id);
  if (index < 0 || index > currentSelection.length - 2) {
    return state;
  }

  const selection = swapItemWithNextInSelection(currentSelection, index);

  return {
    ...state,
    [action.payload.dashboard]: selection,
  };
}

function moveItemUpInsideSelection(
  state: SelectionState,
  action: PayloadAction<MoveInsideSelectionPayload>,
): SelectionState {
  const currentSelection = state[action.payload.dashboard];
  const index = currentSelection.indexOf(action.payload.id);
  if (index < 1) {
    return state;
  }

  const selection = swapItemWithNextInSelection(currentSelection, index - 1);

  return {
    ...state,
    [action.payload.dashboard]: selection,
  };
}

function setItemPositionInsideSelection(
  state: SelectionState,
  action: ReturnType<SetPositionInsideSelection>,
): SelectionState {
  const currentSelection = state[action.payload.dashboard];
  const newIndex = action.payload.position;

  const selection = moveItemAtIndexInSelection(currentSelection, action.payload.item, newIndex);

  return {
    ...state,
    [action.payload.dashboard]: selection,
  };
}

function cleanSelectionOnFetchManySuccess(
  state: SelectionState,
  documents: SendingRequestDto[] | IntegrationRequestDto[],
  dashboardType: SendingsDashboardType | IntegrationsDashboardType,
): SelectionState {
  const currentSelection = state[dashboardType];

  const fetchIds = documents.map((doc) => doc.id);

  const selection = currentSelection.filter((id) => fetchIds.includes(id));

  return {
    ...state,
    [dashboardType]: selection,
  };
}

export const selectionReducer = createReducer(INITIAL_SELECTION_STATE, {
  [updateSelection.type]: updateSelectionState,
  [moveDownInsideSelection.type]: moveItemDownInsideSelection,
  [moveUpInsideSelection.type]: moveItemUpInsideSelection,
  [setPositionInsideSelection.type]: setItemPositionInsideSelection,
  [removeFromSelection.type]: removeItemFromSelection,
  [removeManyFromSelection.type]: removeItemsFromSelection,

  // Integration
  [IntegratedIntegrationsActions.fetchManySuccess.type]: (
    state,
    action: FetchIntegrationRequestsSuccessAction,
  ) =>
    cleanSelectionOnFetchManySuccess(
      state,
      action.payload.entities.documents,
      IntegrationsDashboardType.Integrated,
    ),
  [WorklistIntegrationsActions.fetchManySuccess.type]: (
    state,
    action: FetchIntegrationRequestsSuccessAction,
  ) =>
    cleanSelectionOnFetchManySuccess(
      state,
      action.payload.entities.documents,
      IntegrationsDashboardType.Worklist,
    ),
});
