import { Action } from '@reduxjs/toolkit';
import { Tracking } from '@services/analytics';
import { all, getContext, select, takeLatest } from 'redux-saga/effects';

import {
  DocumentType,
  IOriginalSender,
  IntegrationRequestDto,
  IntegrationsDashboardType,
  MedicalExamType,
  SendingRequestDto,
} from '@honestica/core-apps-common/types';

import {
  UpdateDocumentDatePeriodAction,
  UpdateDocumentSenderAction,
  UpdateIntegrationRequestTypeAction,
  UpdateMedicalExamTypeAction,
  UpdateOriginalSenderAction,
} from '@store/documents/documents.actions';
import { selectDetailDocumentDto } from '@store/documents/documents.selector';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import { selectDraftDetailDocumentDto } from '@store/documents/outgoing/draft.selector';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { selectPathName } from '@store/router/router.selector';

import { getDashboardTypeFromRoute } from './utils/tracking.utils';

function* trackDocumentTypeModified({ payload: { docType } }: UpdateIntegrationRequestTypeAction) {
  const trackingService: Tracking = yield getContext('trackingService');
  const pathname: string = yield select(selectPathName);
  const dashboardType = getDashboardTypeFromRoute(pathname);

  if (dashboardType) {
    const previousType: DocumentType = yield select(
      (state) => selectDetailDocumentDto(state, dashboardType)?.documentType,
    );
    trackingService.documentTypeModified({ newType: docType, previousType });
  }
}

function* trackDocumentDatePeriodModified({
  payload: { newPeriod },
}: UpdateDocumentDatePeriodAction) {
  const trackingService: Tracking = yield getContext('trackingService');
  const { startPeriod, endPeriod } = newPeriod;
  const pathname: string = yield select(selectPathName);
  const dashboardType = getDashboardTypeFromRoute(pathname);

  if (dashboardType) {
    const docDto: SendingRequestDto | IntegrationRequestDto = yield select((state) =>
      selectDetailDocumentDto(state, dashboardType),
    );
    trackingService.documentDatePeriodModified({
      newStartDate: startPeriod?.toString(),
      newEndDate: endPeriod?.toString(),
      previousStartDate: docDto.startPeriod?.toString(),
      previousEndDate: docDto.endPeriod?.toString(),
    });
  }
}

function* trackDocumentSenderModified({ payload: { newSender } }: UpdateDocumentSenderAction) {
  const trackingService: Tracking = yield getContext('trackingService');
  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  trackingService.documentSenderModified({
    newSender: newSender.id,
    previousSender: docDto.sender.id,
  });
}

function* trackMedicalExamTypeModified({
  payload: { medicalExamType },
}: UpdateMedicalExamTypeAction) {
  const trackingService: Tracking = yield getContext('trackingService');

  const previousType: MedicalExamType = yield select(
    (state) => selectDetailDocumentDto(state, IntegrationsDashboardType.Worklist)?.medicalExamType,
  );
  trackingService.medicalExamTypeModified({ newType: medicalExamType, previousType });
}

function* trackDocumentOriginalSenderModified({
  payload: { originalSender },
}: UpdateOriginalSenderAction) {
  const trackingService: Tracking = yield getContext('trackingService');

  const currentOriginalSenderOrganization: IOriginalSender | null = yield select(
    (state) =>
      selectDetailDocumentDto(state, IntegrationsDashboardType.Worklist)
        ?.originalSenderOrganization,
  );

  trackingService.documentOriginalSenderModified({
    newValue: originalSender,
    previousValue: currentOriginalSenderOrganization
      ? `${currentOriginalSenderOrganization.type}/${currentOriginalSenderOrganization.id}`
      : null,
  });
}

export function* trackingDocumentUpdatesSagas() {
  yield all([
    takeLatest(
      (action: Action) => /updateDocumentType$/.test(action.type),
      trackDocumentTypeModified,
    ),
  ]);
  yield all([
    takeLatest(
      (action: Action) => /updateDocumentDatePeriod$/.test(action.type),
      trackDocumentDatePeriodModified,
    ),
  ]);
  yield all([
    takeLatest(DraftDocumentsActions.updateDocumentSender.type, trackDocumentSenderModified),
  ]);
  yield all([
    takeLatest(
      WorklistIntegrationsActions.updateMedicalExamType.type,
      trackMedicalExamTypeModified,
    ),
  ]);
  yield all([
    takeLatest(
      WorklistIntegrationsActions.updateOriginalSender.type,
      trackDocumentOriginalSenderModified,
    ),
  ]);
}
