import { addInfoMessageOnCancelledSagas } from './addInfoOnCancelled.saga';
import { addInfoMessageOnDeferredSagas } from './addInfoOnDeferred.saga';
import { addInfoMessageOnSuccessSagas } from './addInfoOnSuccess.saga';
import { handleCancelMessageSagas } from './handleCancelMessage.sagas';
import { handleRemoveMessageSagas } from './handleRemoveMessage.sagas';

export const infoMessagesSagas = [
  addInfoMessageOnCancelledSagas,
  addInfoMessageOnDeferredSagas,
  addInfoMessageOnSuccessSagas,
  handleCancelMessageSagas,
  handleRemoveMessageSagas,
];
