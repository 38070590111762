import {
  IntegrationsDashboardType,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

export interface SelectionState {
  [SendingsDashboardType.Draft]: string[];
  [SendingsDashboardType.Archive]: string[];
  [SendingsDashboardType.Inbox]: string[];
  [SendingsDashboardType.Sent]: string[];
  [IntegrationsDashboardType.Worklist]: string[];
  [IntegrationsDashboardType.Integrated]: string[];
}

export const INITIAL_SELECTION_STATE: SelectionState = {
  [SendingsDashboardType.Draft]: [],
  [SendingsDashboardType.Archive]: [],
  [SendingsDashboardType.Inbox]: [],
  [SendingsDashboardType.Sent]: [],
  [IntegrationsDashboardType.Worklist]: [],
  [IntegrationsDashboardType.Integrated]: [],
};
