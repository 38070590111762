import { PayloadAction } from '@reduxjs/toolkit';

import { DashboardSettingsOverride } from '../../documents.state';
import { DraftDocumentsState } from '../draft.state';
import { SentDocumentsState } from '../sent.state';

export function setOverrides<T extends DraftDocumentsState | SentDocumentsState>(
  state: T,
  action: SetDashboardOverridesAction,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      settingsOverrides: action.payload,
      searchParams: {
        ...state.dashboardView.searchParams,
        hideOtherRequesters: action.payload?.myRequestOnly,
      },
    },
  };
}

export function updateOverrides<T extends DraftDocumentsState | SentDocumentsState>(
  state: T,
  action: SetDashboardOverridesAction,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      settingsOverrides: {
        ...(state.dashboardView.settingsOverrides ?? {}),
        ...action.payload,
      },
      searchParams: {
        ...state.dashboardView.searchParams,
        hideOtherRequesters: action.payload?.myRequestOnly,
      },
    },
  };
}

export type SetDashboardOverridesPayload = DashboardSettingsOverride | undefined;
export type UpdateDashboardOverridesPayload = Partial<DashboardSettingsOverride>;

export type SetDashboardOverridesAction = PayloadAction<SetDashboardOverridesPayload>;
export type UpdateDashboardOverridesAction = PayloadAction<UpdateDashboardOverridesPayload>;
