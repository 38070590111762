import { logger } from '@services/logger/logger.service';
import { all, put, takeEvery } from 'redux-saga/effects';

import { Organization, Practitioner, ProfessionalBase } from '@honestica/core-apps-common/types';

import {
  addContact as addContactCall,
  getContacts,
  removeContact as removeContactCall,
} from '@api';

import {
  AddContact,
  FindContacts,
  RemoveContact,
  addContact,
  addContactFailure,
  addContactSuccess,
  findContacts,
  findContactsFailure,
  findContactsSuccess,
  removeContact,
  removeContactFailure,
  removeContactSuccess,
} from './contactDirectory.action';
import { ContactDirectoryState } from './contactState';

export function* findContactsAsync({ payload: searchParams }: ReturnType<FindContacts>) {
  try {
    const contacts: ContactDirectoryState = yield getContacts(searchParams);
    yield put(findContactsSuccess(contacts));
  } catch (error) {
    logger.error('LOGIC', 'Failed to find contacts', error);
    yield put(findContactsFailure(error));
  }
}

export function* addContactAsync({ payload }: ReturnType<AddContact>) {
  const { resourceId } = payload;
  const { resourceType } = payload;

  try {
    if (!resourceId) {
      throw new Error('resourceId is mandatory');
    }
    const contact: ProfessionalBase = yield addContactCall({ resourceId, resourceType });
    yield put(addContactSuccess(contact));
  } catch (error) {
    logger.error('LOGIC', 'Failed to add contact', error);
    yield put(addContactFailure(error));
  }
}

export function* removeContactAsync({ payload }: ReturnType<RemoveContact>) {
  try {
    if (!payload.contactId) {
      throw new Error('contactId is mandatory');
    }
    const contact: Practitioner | Organization = yield removeContactCall(payload.contactId);
    yield put(removeContactSuccess(contact));
  } catch (error) {
    logger.error('LOGIC', 'Failed to remove contact', error);
    yield put(removeContactFailure(error));
  }
}

export function* contactDirectorySagas() {
  yield all([
    takeEvery(findContacts.type, findContactsAsync),
    takeEvery(addContact.type, addContactAsync),
    takeEvery(removeContact.type, removeContactAsync),
  ]);
}
