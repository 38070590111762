import { createAction } from '@reduxjs/toolkit';

import { Practitioner } from '@honestica/core-apps-common/types';

const REDUCER_NAME = 'practitionerDetail';

const FIND_PRACTITIONER = `${REDUCER_NAME}/findOne`;

export const findPractitioner = createAction<string>(FIND_PRACTITIONER);
export const findPractitionerSuccess = createAction<Practitioner>(`${FIND_PRACTITIONER}Success`);
export const findPractitionerFailure = createAction<unknown>(`${FIND_PRACTITIONER}Failure`);

export type FindPractitioner = typeof findPractitioner;
