import {
  DraftDocumentsState,
  INITIAL_DRAFT_COUNTER_STATE,
  INITIAL_READYTOSEND_COUNTER_STATE,
} from '@store/documents/outgoing/draft.state';

import {
  FetchTotalDraftFailureAction,
  FetchTotalDraftSuccessAction,
  FetchTotalReadyToSendFailureAction,
  FetchTotalReadyToSendSuccessAction,
  IncrementTotalDraftAction,
  IncrementTotalReadyToSendAction,
} from '../../documents.actions';

export function fetchDraftCounterSuccess<T extends DraftDocumentsState>(
  state: T,
  action: FetchTotalDraftSuccessAction,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      totalDraft: {
        ...state.dashboardView.totalDraft,
        total: action.payload.total,
        identities: action.payload.identities,
      },
    },
  };
}

export function incrementDraftCounter<T extends DraftDocumentsState>(
  state: T,
  action: IncrementTotalReadyToSendAction,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      totalDraft: {
        ...state.dashboardView.totalDraft,
        total: state.dashboardView.totalDraft.total + action.payload.count,
        identities: action.payload.identity
          ? {
              ...state.dashboardView.totalDraft.identities,
              [action.payload.identity]:
                state.dashboardView.totalDraft.identities[action.payload.identity] +
                action.payload.count,
            }
          : { ...state.dashboardView.totalDraft.identities },
      },
    },
  };
}

export function incrementReadyToSendCounter<T extends DraftDocumentsState>(
  state: T,
  action: IncrementTotalDraftAction,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      totalReadyToSend: {
        ...state.dashboardView.totalReadyToSend,
        total: state.dashboardView.totalReadyToSend.total + action.payload.count,
        identities: action.payload.identity
          ? {
              ...state.dashboardView.totalReadyToSend.identities,
              [action.payload.identity]:
                state.dashboardView.totalReadyToSend.identities[action.payload.identity] +
                action.payload.count,
            }
          : { ...state.dashboardView.totalReadyToSend.identities },
      },
    },
  };
}

export function decrementDraftCounter<T extends DraftDocumentsState>(
  state: T,
  action: IncrementTotalDraftAction,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      totalDraft: {
        ...state.dashboardView.totalDraft,
        total: state.dashboardView.totalDraft.total - action.payload.count,
        identities: action.payload.identity
          ? {
              ...state.dashboardView.totalDraft.identities,
              [action.payload.identity]:
                state.dashboardView.totalDraft.identities[action.payload.identity] -
                action.payload.count,
            }
          : { ...state.dashboardView.totalDraft.identities },
      },
    },
  };
}

export function decrementReadyToSendCounter<T extends DraftDocumentsState>(
  state: T,
  action: IncrementTotalDraftAction,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      totalReadyToSend: {
        ...state.dashboardView.totalReadyToSend,
        total: state.dashboardView.totalReadyToSend.total - action.payload.count,
        identities: action.payload.identity
          ? {
              ...state.dashboardView.totalReadyToSend.identities,
              [action.payload.identity]:
                state.dashboardView.totalReadyToSend.identities[action.payload.identity] -
                action.payload.count,
            }
          : { ...state.dashboardView.totalReadyToSend.identities },
      },
    },
  };
}
export function fetchDraftCounterFailure<T extends DraftDocumentsState>(
  state: T,
  _action: FetchTotalDraftFailureAction,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      totalDraft: INITIAL_DRAFT_COUNTER_STATE,
    },
  };
}

export function fetchTotalReadyToSendSuccess<T extends DraftDocumentsState>(
  state: T,
  action: FetchTotalReadyToSendSuccessAction,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      totalReadyToSend: {
        ...state.dashboardView.totalReadyToSend,
        total: action.payload.total,
        identities: action.payload.identities,
      },
    },
  };
}

export function fetchTotalReadyToSendFailure<T extends DraftDocumentsState>(
  state: T,
  _action: FetchTotalReadyToSendFailureAction,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      totalReadyToSend: INITIAL_READYTOSEND_COUNTER_STATE,
    },
  };
}
