import { Professional } from '@honestica/core-apps-common/types';
import { compact, titleCase } from '@honestica/core-apps-common/utils';
import { isPractitionerRole } from '@honestica/core-apps-common/validators';

export function getNameWithPrefix(resource: Pick<Professional, 'name' | 'prefix'>) {
  const nameWithPrefix = compact<string>([resource.prefix?.trim(), resource.name])
    .filter((s) => s.length > 0)
    .join(' ');

  return titleCase(nameWithPrefix);
}

export function formatPractitionerRoleName(practitionerRoleName: string) {
  const splitted = practitionerRoleName.split(' @ ');
  return splitted.length === 2 ? splitted[0] : practitionerRoleName;
}

export function formatProfessionnalName(professionnal: Professional) {
  if (isPractitionerRole(professionnal)) {
    const formatedName = formatPractitionerRoleName(professionnal.name);
    return {
      ...professionnal,
      name: formatedName,
    };
  }
  return professionnal;
} // TODO handle it in BECA? : https://honestica.atlassian.net/browse/REB-357
