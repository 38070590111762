import { ProxyMode, isDesktopApp, openLink } from '@launcher/utils';
import { useTranslation } from 'react-i18next';

import { Space, Typography } from '@honestica/ui-kit/src';

import { version } from '../../../../../package.json';

import { AboutModalReduxProps, aboutModalConnector } from './AboutModal.connector';

const { Text, Link } = Typography;

const AboutModalComponent = (props: AboutModalReduxProps) => {
  const { t } = useTranslation();

  return (
    <Space direction="vertical" size="small">
      <Text>
        {t('help.version')}: {version}
      </Text>
      {isDesktopApp() && (
        <>
          <Text>
            {t('settings.proxyMode')}: {props.settings.proxyMode}
          </Text>
          {props.settings.proxyMode === ProxyMode.Manual && (
            <>
              <Text>
                {t('settings.proxyUrl')}: {props.settings.proxyUrl}
              </Text>
              <Text>
                {t('settings.pacUrl')}: {props.settings.pacUrl}
              </Text>
            </>
          )}
          <Text>
            {t('settings.sso')}: {props.settings.ssoConnection || t('no')}
          </Text>
        </>
      )}
      <br />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link onClick={() => openLink(t('help.legalConditionsURL'))}>
        {t('help.legalConditions')}
      </Link>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link onClick={() => openLink(t('help.rgpdURL'))}>{t('help.rgpd')}</Link>
    </Space>
  );
};

export const AboutModal = aboutModalConnector(AboutModalComponent);
