import { createSelector } from '@reduxjs/toolkit';

import { State } from '../reducer';

export const selectErrorsState = (state: State) => state.errors;

export const selectMutationErrors = createSelector(selectErrorsState, (state) => state.mutations);

export const selectAreErrorMessagesHidden = () =>
  // Hide error message in notification window
  window.location.pathname === '/notification';
