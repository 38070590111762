import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  ActionsDeferredState,
  AllowedDeferrableAction,
  AllowedDeferrableActionType,
  DeferredId,
} from './actionsDeferred.state';

const slice = createSlice({
  name: 'actionsDeferred',
  initialState: [] as ActionsDeferredState,
  reducers: {
    addOne: (state, action: AddDeferredAction) => state.concat([action.payload]),

    cancel: (state, _action: CancelDeferredAction) => state,

    remove: (state, _action: RemoveDeferredAction) => state,

    executeOne: (state, _action: ExecuteDeferredAction) => state,

    done: (state, action: DoneDeferredAction) =>
      state.filter((actions) => actions.id !== action.payload.id),
  },
});

export const ActionDeferredActions = slice.actions;
export const actionDeferredReducer = slice.reducer;

type Id = { id: DeferredId };

export type AddDeferredPayload = AllowedDeferrableAction;
export type AddDeferredAction = PayloadAction<AddDeferredPayload>;

export type CancelDeferredPayload = { type: AllowedDeferrableActionType } & Id;
export type CancelDeferredAction = PayloadAction<CancelDeferredPayload>;

export type RemoveDeferredPayload = { type: AllowedDeferrableActionType } & Id;
export type RemoveDeferredAction = PayloadAction<RemoveDeferredPayload>;

export type ExecuteDeferredPayload = AllowedDeferrableAction;
export type ExecuteDeferredAction = PayloadAction<ExecuteDeferredPayload>;

export type DoneDeferredPayload = { type: AllowedDeferrableActionType } & Id;
export type DoneDeferredAction = PayloadAction<DoneDeferredPayload>;

export const ACTION_DEFERRED_DELAY = 5000;
