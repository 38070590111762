import { useCallback, useState } from 'react';

import { isDesktopApp } from '@launcher/utils';
import { EnvManager } from '@services/environment/environment.service';
import { useTranslation } from 'react-i18next';

import { isDefined } from '@honestica/core-apps-common/utils';
import { Button, Form, Input, Space, Typography } from '@honestica/ui-kit/src';

import { useModal } from '@hooks/useModal.hook';

import { QAModalReduxProps, qAModalConnector } from './qAModal.connector';

import styles from './QAModal.module.less';

export function QAModalComponent(props: QAModalReduxProps) {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  const [reviewAppURL, setReviewAppURL] = useState<string>(
    EnvManager.getName() === 'review' ? `${window.location.origin}/apps` : '',
  );
  const [releaseUrl, setReleaseUrl] = useState<string>(props.releaseServerURL ?? '');

  const canSave: boolean = (props.releaseServerURL ?? '') !== releaseUrl || reviewAppURL.length > 0;

  const save = useCallback(() => {
    if (reviewAppURL.length && !reviewAppURL.includes(window.location.origin)) {
      window.electron?.writeLocalUrl({
        app: {
          url: reviewAppURL,
        },
      });
      window.electron?.redirectTo(reviewAppURL);
    }

    if (releaseUrl !== props.releaseServerURL) {
      props.updateDesktopSettings({ releaseServerURL: releaseUrl });
    }

    closeModal();
  }, [closeModal, props, releaseUrl, reviewAppURL]);

  const reset = useCallback(() => {
    if (isDefined(props.releaseServerURL)) {
      props.updateDesktopSettings({ releaseServerURL: undefined });
    }

    closeModal();
  }, [closeModal, props]);

  return (
    <Form layout="vertical">
      {isDesktopApp() && (
        <>
          <Typography.Text strong>{t('settings.parameters.qa.modal.reviewAppUrl')}</Typography.Text>
          <Form.Item htmlFor="url" className="margin-top-sm">
            <Input
              id="url"
              size="large"
              value={reviewAppURL}
              onChange={(e) => setReviewAppURL(e.target.value)}
            />
          </Form.Item>
        </>
      )}

      <Typography.Text strong>{t('settings.parameters.qa.modal.releaseServerUrl')}</Typography.Text>
      <Form.Item htmlFor="url" className="margin-top-sm">
        <Input
          id="url"
          size="large"
          value={releaseUrl}
          onChange={(e) => setReleaseUrl(e.target.value)}
        />
      </Form.Item>

      <Space fullWidth className={styles.alignRight}>
        <Button size="large" type="primary" onClick={save} disabled={!canSave}>
          {t('settings.parameters.qa.modal.save.button')}
        </Button>
        <Button size="large" type="primary" danger onClick={reset}>
          {t('settings.parameters.qa.modal.reset.button')}
        </Button>
      </Space>
    </Form>
  );
}

export const QAModal = qAModalConnector(QAModalComponent);
