export enum LogLevel {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

export enum LogEnvironment {
  DEV = 'dev',
  TEST = 'test',
  STAGING = 'staging',
  PRODUCTION = 'production',
  POST_PROD = 'post-prod',
}

export type LogAppName = 'core-apps-server' | 'core-apps-client' | 'lifen-patient';

export interface Log {
  readonly level: LogLevel;
  readonly environment: LogEnvironment;
  readonly version: string;
  readonly message: any;
  readonly appname: LogAppName;
}

export interface LogOptions {
  readonly data?: Record<string, any>;
  readonly method?: string;
  readonly status?: number;
  readonly duration?: number;
  readonly error?: string;
  readonly uuid?: string;
}

export type BecaMode = 'full' | 'dry-run' | 'proxy';

export interface ClientLog extends Log {
  readonly '@timestamp': string;
  readonly host: string;
  readonly path: string;
  readonly source: 'HTTP' | 'API' | 'LOGIC' | 'STATE' | 'UI' | 'ROUTER' | 'DESKTOP';
  readonly userId: string;
  readonly sessionId: string;
  readonly becaMode?: BecaMode;
}

export interface ServerLogOptions extends LogOptions {
  readonly host?: string;
  readonly path?: string;
  readonly userId?: string;
  readonly userWorkspaceId?: string;
  readonly userTenant?: string;
  readonly ip?: string;
  // used for tracing event for Lifen Events;
  readonly traceId?: string;
  readonly force?: boolean;
}

export interface ServerLog extends Log, ServerLogOptions {
  readonly source:
    | 'HTTP'
    | 'CONTROLLER'
    | 'USECASE'
    | 'HELPER'
    | 'ADAPTER'
    | 'UTILS'
    | 'OTHER'
    | 'FHIR';
}
