import {
  differenceInDays,
  differenceInYears,
  isBefore as isBeforeDate,
  isFuture,
  isPast,
  isSameDay as isSameDate,
  isValid,
  parse,
  parseISO,
} from 'date-fns';
import { enGB, fr } from 'date-fns/locale';
import { Locale } from 'date-fns/types';
import { format, toZonedTime } from 'date-fns-tz';

interface LocaleTable {
  [key: string]: Locale;
}

const localeTable: LocaleTable = { fr, en: enGB };

const SHORT_DATE_REGEX = /^\d{2}\/\d{2}\/\d{4}$/g;

const YYYY_MM_DD = 'yyyy-MM-dd';
/**
 * @param date the date string have to be in format YYYY-MM-DD
 * @returns true if the @param date is after the current date
 */
export const isFutureDate = (date: Date | null | string, formatString = YYYY_MM_DD): boolean => {
  if (date === null) {
    return false;
  }
  return isFuture(typeof date === 'string' ? parse(date, formatString, new Date()) : date);
};

/**
 * @param date the date string have to be in format YYYY-MM-DD
 * @returns true if the @param date is before the current date
 */
export const isPastDate = (date: string | undefined): boolean => {
  if (typeof date === 'undefined') {
    return false;
  }
  return isPast(parse(date, YYYY_MM_DD, new Date()));
};

/**
 * @param date if type is string, the date have to be in following format : IETF-compliant RFC 2822 timestamps or ISO8601
 * @param dateToTest
 * @returns
 */
export const isSameDay = (date: string | undefined | Date, dateToTest = new Date()): boolean => {
  if (typeof date === 'undefined') {
    return true;
  }
  if (typeof date === 'string') {
    const parseDate = parseISO(date);
    if (isValid(parseDate)) {
      return isSameDate(parseDate, dateToTest);
    }
    return false;
  }
  return isSameDate(date, dateToTest);
};

export function isBefore(date: string, dateToTestAgainst: string): boolean {
  const parseDate = parseISO(date);
  const parsDateToTestAgainst = new Date(dateToTestAgainst);
  if (isValid(parseDate) && isValid(parsDateToTestAgainst)) {
    return isBeforeDate(parseDate, parsDateToTestAgainst);
  }
  return false;
}

/**
 * Order by the most recent date first.
 *
 * @param array the array to orderc
 * @param datePropertyKey the property key on which the order will be perform
 * @returns the array sorted
 */
export function orderByDate<T>(array: T[], datePropertyKey: keyof T): T[] {
  return array.sort((a, b) =>
    a[datePropertyKey] && b[datePropertyKey] && a[datePropertyKey] < b[datePropertyKey] ? 1 : -1,
  );
}
const formatDateToDateUTC = (date: Date): string => {
  const formattedDate = format(toZonedTime(date, 'UTC'), 'yyyy-MM-dd HH:mm:ss', {
    timeZone: 'UTC',
  });
  return `${formattedDate.replace(' ', 'T')}Z`;
};

export const formatDateUTC = (date: string | undefined = undefined): string => {
  if (typeof date === 'undefined') {
    return formatDateToDateUTC(new Date());
  }
  if (typeof date === 'string') {
    const parseDate = parseISO(date);

    if (isValid(parseDate)) {
      return formatDateToDateUTC(parseDate);
    }
  }
  return formatDateToDateUTC(new Date());
};

export const formatDateAndHours = (date: string | undefined): string | undefined => {
  if (typeof date === 'string') {
    const parseDate = parseISO(date);
    if (isValid(parseDate)) {
      return format(parseDate, 'dd MMMM yyyy, HH:mm', { locale: fr });
    }
  }
  return undefined;
};

export const formatDateToServer = (date: Date | string | undefined | null): string | undefined => {
  if (!date) {
    return undefined;
  }
  if (typeof date !== 'string') {
    if (isValid(date)) {
      return format(date, YYYY_MM_DD);
    }
    return undefined;
  }

  let formatToApply;
  if ('-'.includes(date[2])) {
    formatToApply = 'dd-MM-yyyy';
  } else if ('/'.includes(date[2])) {
    formatToApply = 'dd/MM/yyyy';
  } else {
    formatToApply = YYYY_MM_DD;
  }

  const parseDate = parse(date, formatToApply, new Date());
  if (isValid(parseDate)) {
    return format(parseDate, YYYY_MM_DD);
  }
  return undefined;
};

export function randomDate(): string {
  return format(new Date(+new Date() - Math.floor(Math.random() * 10000000000)), YYYY_MM_DD);
}

export const formatDate = (
  date: string | undefined | Date,
  wantedFormat: string,
): string | undefined => {
  if (!date) return undefined;
  if (typeof date === 'string') {
    const parseDate = parseISO(date);
    if (isValid(parseDate)) {
      return format(parseDate, wantedFormat, { locale: fr });
    }
    return undefined;
  }
  return format(date, wantedFormat, { locale: fr });
};

export const formatDateShort = (date: string | undefined | Date): string | undefined =>
  formatDate(date, 'dd/MM/yyyy');

export const formatDateLong = (date: string | undefined): string | undefined =>
  formatDate(date, 'dd MMM yyyy');

export const diffInDays = (dateToCompare: Date, newDate: Date): number =>
  differenceInDays(dateToCompare, newDate);

export function diffInYears(date: string): number {
  return differenceInYears(new Date(), parseISO(date));
}

export function formatDateInHourOrShortDate(date: Date | string | undefined) {
  if (typeof date === 'undefined') {
    return format(new Date(), 'HH:mm');
  }
  if (typeof date === 'string') {
    const parseDate = parseISO(date);
    if (isValid(parseDate)) {
      return isSameDay(date) ? format(parseDate, 'HH:mm') : formatDateShort(date);
    }
    return undefined;
  }
  return isSameDay(date) ? format(date, 'HH:mm') : formatDateShort(date);
}

export function formatDateInHourOrDateAndHour(date: Date | string | undefined, locale: string) {
  if (typeof date === 'undefined') {
    return format(new Date(), 'HH:mm');
  }
  if (typeof date === 'string') {
    const parseDate = parseISO(date);
    if (isValid(parseDate)) {
      return isSameDay(date) ? format(parseDate, 'HH:mm') : formatDateLongWithHours(date, locale);
    }
    return undefined;
  }
  return isSameDay(date) ? format(date, 'HH:mm') : formatDateLongWithHours(date, locale);
}

export function formatDateInText(date: Date | string | undefined, today: string) {
  if (typeof date === 'undefined') {
    return today;
  }
  if (typeof date === 'string') {
    const parseDate = parseISO(date);
    if (isValid(parseDate)) {
      return isSameDay(date) ? today : format(parseDate, 'EEEE d LLLL yyyy', { locale: fr });
    }
    return undefined;
  }
  return isSameDay(date) ? today : format(date, 'EEEE d LLLL yyyy', { locale: fr });
}

export function formatDateLongWithHours(date: Date | string | undefined, locale: string) {
  if (typeof date === 'undefined') {
    return format(new Date(), 'dd MMM yyyy, HH:mm', { locale: localeTable[locale] });
  }
  if (typeof date === 'string') {
    const parseDate = parseISO(date);
    if (isValid(parseDate)) {
      return format(parseDate, 'dd MMM yyyy, HH:mm', { locale: localeTable[locale] });
    }
    return undefined;
  }
  return format(date, 'dd MMM yyyy, HH:mm', { locale: localeTable[locale] });
}

export function validateDateShort(date: string): boolean {
  return date === '' || date.match(SHORT_DATE_REGEX) !== null;
}

export function getDateWhithoutHour(date: string) {
  if (typeof date === 'string') {
    const parseDate = Date.parse(date);
    const oneDay = 24 * 60 * 60 * 1000;
    if (parseDate) {
      return Math.floor(parseDate / oneDay) * oneDay;
    }
  }
  return undefined;
}

export function formatProcedureDateInHourOrShortDate(date: Date | string | undefined) {
  if (typeof date === 'undefined') {
    return '-';
  }
  if (typeof date === 'string') {
    const parseDate = parseISO(date);
    if (isValid(parseDate)) {
      return isSameDay(date) ? format(parseDate, 'dd/MM/yyyy') : formatDateShort(date);
    }
    return undefined;
  }
  return isSameDay(date) ? format(date, 'dd/MM/yyyy') : formatDateShort(date);
}

export function youngerThan2MonthsFilter() {
  const now = new Date();
  const twoMonthsAgo = new Date(now.getTime() - 2 * 30 * 24 * 60 * 60 * 1000);
  return `gt${formatDate(twoMonthsAgo, 'yyyy-MM-dd')}`;
}
