import { all, takeEvery } from 'redux-saga/effects';

import {
  checkAndProcessJobSaga,
  createHandleJobFailureSaga,
  createHandleJobSuccessSaga,
} from '@store/upload/processUploadJob.sagas';

import { UploadDocumentsActions } from '../uploadDocuments.slice';

export function* processUploadDocumentJobSagas() {
  yield all([
    takeEvery(UploadDocumentsActions.addUploadJob.type, checkAndProcessJobSaga('sending')),
  ]);
  yield all([
    takeEvery(UploadDocumentsActions.jobUploadFailure.type, createHandleJobFailureSaga('sending')),
  ]);
  yield all([
    takeEvery(UploadDocumentsActions.jobUploadSuccess.type, createHandleJobSuccessSaga('sending')),
  ]);
}
