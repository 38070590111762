import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { all, getContext, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  List,
  Patient,
  SendingRequestBundle,
  SendingRequestDto,
} from '@honestica/core-apps-common/types';

import * as API from '@api';
import {
  CreatePatientAction,
  SearchPatientsAction,
  UpdatePatientAction,
} from '@store/documents/documents.actions';
import {
  selectDraftDetailCurrentPatient,
  selectDraftDetailDocumentDto,
} from '@store/documents/outgoing/draft.selector';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { updateDocumentInCache } from '@utils/caching.util';
import { hasConnectorWithPreprocessing } from '@utils/integrations.util';

// updates entities
function* addOneDocumentPatientSaga({ payload: { patient } }: UpdatePatientAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const bundle: SendingRequestBundle = yield API.addDocumentPatient(
      docDto.id,
      {
        id: patient.id,
        telecoms: [],
        addresses: [],
      },
      hasConnectorWithPreprocessing(docDto.integration),
    );
    yield put(DraftDocumentsActions.addDocumentPatientSuccess({ bundle }));
    yield updateDocumentInCache({
      updatedDocument: bundle.document,
      queryClient,
    });
  } catch (error) {
    logger.error('LOGIC', 'Failed to add document patient', error);
    yield put(
      DraftDocumentsActions.addDocumentPatientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

// updates entities
function* createOneDocumentPatientSaga({ payload: { patient } }: CreatePatientAction) {
  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const bundle: SendingRequestBundle = yield API.createDocumentPatient(
      docDto.id,
      patient,
      hasConnectorWithPreprocessing(docDto.integration),
    );
    yield put(DraftDocumentsActions.addDocumentPatientSuccess({ bundle }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to create document patient', error);
    yield put(
      DraftDocumentsActions.addDocumentPatientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

// updates entities
function* updateOneDocumentPatientSaga({
  payload: { patient, skipGamUpdate },
}: UpdatePatientAction) {
  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  const currentPatient: Patient = yield select(selectDraftDetailCurrentPatient);
  try {
    const {
      patient: updatedPatient,
      document,
      needsRefresh,
    } = yield API.updateDocumentPatient(docDto.id, patient, currentPatient, skipGamUpdate);

    if (needsRefresh) {
      yield put(
        DraftDocumentsActions.refreshPatientMatch({
          id: docDto.id,
        }),
      );
    }

    yield put(
      DraftDocumentsActions.updateDocumentPatientSuccess({
        id: docDto.id,
        patient: updatedPatient,
        document,
      }),
    );
  } catch (error) {
    logger.error('LOGIC', 'Failed to update document patient', error);
    yield put(
      DraftDocumentsActions.updateDocumentPatientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

function* removeOneDocumentPatientSaga() {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const bundle: SendingRequestBundle = yield API.removeDocumentPatient(docDto.id);
    yield put(DraftDocumentsActions.removeDocumentPatientSuccess({ bundle }));
    yield updateDocumentInCache({
      updatedDocument: bundle.document,
      queryClient,
    });
  } catch (error) {
    logger.error('LOGIC', 'Failed to remove document patient', error);
    yield put(
      DraftDocumentsActions.removeDocumentPatientFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

// updates entities
function* searchManyPatientsSaga({ payload: { searchParams } }: SearchPatientsAction) {
  try {
    const patients: List<Patient> = yield API.getPatients(searchParams);
    yield put(DraftDocumentsActions.searchPatientsSuccess({ patients }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to search patients in Draft Detail', error);

    yield put(
      DraftDocumentsActions.searchPatientsFailure({
        error,
      }),
    );
  }
}

export function* managePatientsDraftDocumentsSaga() {
  yield all([
    takeLatest(DraftDocumentsActions.addDocumentPatient.type, addOneDocumentPatientSaga),
    takeLatest(DraftDocumentsActions.createDocumentPatient.type, createOneDocumentPatientSaga),
    takeEvery(DraftDocumentsActions.updateDocumentPatient.type, updateOneDocumentPatientSaga),
    takeLatest(DraftDocumentsActions.removeDocumentPatient.type, removeOneDocumentPatientSaga),
    takeLatest(DraftDocumentsActions.searchPatients.type, searchManyPatientsSaga),
  ]);
}
