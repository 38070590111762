import { NIR_LENGTH } from '@honestica/core-apps-common/constants';

type ValidateNirParams = {
  readonly identifier: string;
};

type ValidateNirResult = {
  identifier: string;
  validationError?: NirErrors;
};

export enum NirErrors {
  EMPTY = 'empty',
  LENGTH = 'length',
  FIRST_NUMBER = 'firstNumber',
  PARSING = 'parsing',
  COMPUTING = 'computing',
}

/**
 * Numbers that we consider valid when dealing with DMP services.
 *
 * We explicitly exclude 7 and 8 that are known to be unsupported.
 */
const VALID_NIR_STARTING_NUMBER = ['1', '2', '3', '4'];

export function validateNIR({ identifier }: ValidateNirParams): ValidateNirResult {
  let nirWorkingCopy: string = identifier.trim().toUpperCase();

  const isFirstNumberValid: boolean = VALID_NIR_STARTING_NUMBER.includes(
    nirWorkingCopy.substring(0, 1),
  );
  const nir: ValidateNirResult = { identifier };

  if (nirWorkingCopy.length === 0) {
    return {
      identifier: '',
      validationError: NirErrors.EMPTY,
    };
  }
  if (nirWorkingCopy.length !== NIR_LENGTH) {
    return { ...nir, validationError: NirErrors.LENGTH };
  }
  if (!isFirstNumberValid) {
    return {
      ...nir,
      validationError: NirErrors.FIRST_NUMBER,
    };
  }

  // this allows processing of corsican NIR
  // it allows key to be computed
  nirWorkingCopy = nirWorkingCopy.replace('2A', '19');
  nirWorkingCopy = nirWorkingCopy.replace('2B', '18');

  const nirWithoutKey: number = +nirWorkingCopy.substring(0, 13);
  const nirKey: number = +nirWorkingCopy.substring(13, 15);

  if (Number.isNaN(nirWithoutKey) || Number.isNaN(nirKey)) {
    return { ...nir, validationError: NirErrors.PARSING };
  }

  const isValidKey = 97 - (nirWithoutKey % 97) === nirKey;

  if (!isValidKey) {
    return { ...nir, validationError: NirErrors.COMPUTING };
  }

  return nir;
}
