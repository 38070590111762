import React from 'react';

import { useCustomNavigate } from '@launcher/hooks/useCustomNavigate.hook';
import { tracking } from '@services/analytics';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import createSagaMiddleware from 'redux-saga';

interface ReduxSagaContextMiddlewareProps {
  children: React.ReactNode;
}

export const sagaMiddleware = createSagaMiddleware();

export const ReduxSagaContextMiddleware: React.FC<ReduxSagaContextMiddlewareProps> = ({
  children,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const customNavigate = useCustomNavigate(); // Use for manage beta environment
  sagaMiddleware.setContext({
    navigate,
    customNavigate,
    trackingService: tracking,
    queryClient,
  });

  return <>{children}</>;
};
