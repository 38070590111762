import { logger } from '@services/logger/logger.service';

import {
  IntegrationRequest,
  IntegrationRequestDto,
  Professional,
  ProfessionalBaseType,
  SendingRequest,
  SendingRequestDto,
} from '@honestica/core-apps-common/types';

import { EntitiesState, EntitiesType } from './entities/entities.state';

export function getEntity(
  professional: {
    type: EntitiesType;
    id: string;
  },
  entities: EntitiesState,
): Professional {
  const entity = entities[professional.type]?.[professional.id] as Professional;
  if (!entity) {
    logger.error('LOGIC', `Could not find entity ${professional.type}/${professional.id}`);
  }
  return entity;
}

/* DOCUMENTS */

export function documentDtoToSendingRequest(
  documentDto: SendingRequestDto,
  entities: EntitiesState,
): SendingRequest {
  const { sender, patient, recipients } = documentDto;
  return {
    ...documentDto,
    sender: getEntity({ id: sender.id, type: sender.type }, entities),
    patient: patient && {
      ...patient,
      patient: entities.Patient[patient.id],
      ehrPatient: entities.Patient[patient?.ehrPatientId ?? ''],
    },
    recipients: recipients
      .filter((recipientDto) => Object.values(ProfessionalBaseType).includes(recipientDto.type)) // TODO : Remove this filter when patient set as recipient will be moved to document's recipients
      .map((recipientDto) => {
        const entity: Professional = getEntity(recipientDto, entities);
        return {
          entity,
          telecoms: recipientDto.telecoms,
          addresses: recipientDto.addresses,
          prediction: recipientDto.prediction,
          verifications: recipientDto.verifications,
        };
      })
      // Fixing a rare use case where we don't have the entity - https://honestica.atlassian.net/browse/CORE-3884
      .filter((recipient) => !!recipient.entity),
  };
}

/* INTEGRATIONS */
export function integrationRequestDtoToIntegrationRequest(
  integrationRequestDto: IntegrationRequestDto,
  entities: EntitiesState,
): IntegrationRequest {
  const { sender, originalSenderOrganization, originalSenderPractitioner, patient, source } =
    integrationRequestDto;
  return {
    ...integrationRequestDto,
    attachments: entities.Attachments[integrationRequestDto.id],
    sender: getEntity({ id: sender.id, type: sender.type }, entities),
    originalSenderOrganization,
    originalSenderPractitioner,
    patient: patient && {
      ...patient,
      patient: entities.Patient[patient.id],
      ehrPatient: patient.ehrPatientId ? entities.Patient[patient.ehrPatientId] : undefined,
    },
    source,
  };
}
