import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { some } from '@honestica/core-apps-common/utils';

import { infoMessageToCancelledMessage } from './helpers/infoMessageToCancelled.helper';
import { INFO_MESSAGES_INITIAL_STATE, InfoMessage } from './infoMessages.state';

const infoSlice = createSlice({
  name: 'infoMessages',
  initialState: INFO_MESSAGES_INITIAL_STATE,
  reducers: {
    addOne: (state, action: AddInfoMessageAction) => {
      const message: InfoMessage = {
        ...action.payload,
        cancelable: infoMessageToCancelledMessage[action.payload.type] !== null,
      };

      // Handle existing message
      if (some(state, (mes) => mes.id === action.payload.id)) {
        return state.map((m) => (m.id === action.payload.id ? message : m));
      }

      return state.concat(message);
    },

    cancelOne: (state, action: CancelInfoMessageAction) =>
      state.filter((message) => message.id !== action.payload.id),

    removeOne: (state, action: RemoveInfoMessageAction) =>
      state.filter((message) => message.id !== action.payload.id),
  },
});

export const infoMessagesReducer = infoSlice.reducer;
export const InfoMessageActions = infoSlice.actions;

export type AddInfoMessagePayload = Omit<InfoMessage, 'cancelable'>;
export type AddInfoMessageAction = PayloadAction<AddInfoMessagePayload>;

export type CancelInfoMessagePayload = Omit<InfoMessage, 'cancelable' | 'labelOptions'>;
export type CancelInfoMessageAction = PayloadAction<CancelInfoMessagePayload>;

export type RemoveInfoMessagePayload = Omit<InfoMessage, 'cancelable' | 'labelOptions'>;
export type RemoveInfoMessageAction = PayloadAction<RemoveInfoMessagePayload>;

export const SUCCESS_ACTIONS_MESSAGE_VISIBLE_FOR_MS = 2000; // could be dynamic/settable?
