import {
  DocumentStatus,
  DocumentVerificationStatus,
  IntegrationRequestDto,
  IntegrationsDashboardType,
  Patient,
} from '@honestica/core-apps-common/types';
import { parsePatientName } from '@honestica/core-apps-common/utils';

import { INTEGRATED, WORKLIST } from '@constants/documents.constants';
import { Entities } from '@store/entities/entities.state';
import { hasConnectorEnabled } from '@utils/integrations.util';

function isSenderPresent(integrationRequest: IntegrationRequestDto): boolean {
  return !!integrationRequest.sender;
}

function isStatusSuspended(integrationRequest: IntegrationRequestDto): boolean {
  return integrationRequest.status === DocumentStatus.Suspended;
}

function isPatientPresent(patient: Patient | undefined): boolean {
  return !!patient;
}

function isPatientNameValid(patient: Patient): boolean {
  return !!parsePatientName(patient);
}

function isConnectorEnabled(integrationRequest: IntegrationRequestDto): boolean {
  return hasConnectorEnabled(integrationRequest.integration);
}

function areRequiredFieldsPresent(integrationRequest: IntegrationRequestDto): boolean {
  return !!integrationRequest.medicalExamType || !!integrationRequest.originalSenderOrganization;
}

export function isReadyToIntegrate({
  integrationRequest,
  patient,
  withRequiredFields,
}: {
  integrationRequest: IntegrationRequestDto;
  patient?: Patient;
  withRequiredFields?: boolean;
}): boolean {
  if (!isSenderPresent(integrationRequest)) return false;
  if (!isStatusSuspended(integrationRequest)) return false;
  if (!isPatientPresent(patient)) return false;
  if (!!patient && !isPatientNameValid(patient)) return false;
  if (!isConnectorEnabled(integrationRequest)) return false;
  // eslint-disable-next-line sonarjs/prefer-single-boolean-return
  if (withRequiredFields && !areRequiredFieldsPresent(integrationRequest)) return false;
  return true;
}

export function isReadyToBatchIntegrate({
  integrationRequest,
  patient,
  withRequiredFields,
}: {
  integrationRequest: IntegrationRequestDto;
  patient: Patient | undefined;
  withRequiredFields?: boolean;
}): boolean {
  if (!isReadyToIntegrate({ integrationRequest, patient, withRequiredFields })) {
    return false;
  }

  // eslint-disable-next-line sonarjs/prefer-single-boolean-return
  if (integrationRequest.verificationStatus !== DocumentVerificationStatus.ReadyToImport) {
    return false;
  }

  return true;
}

export function integrationRequestIdIsReadyToIntegrate(
  integrationRequestID: string,
  integrationRequests: IntegrationRequestDto[],
  patients: Entities<Patient>,
  withRequiredFields?: boolean,
): boolean {
  const integrationRequest = integrationRequests.find((doc) => doc.id === integrationRequestID);
  let patient: Patient | undefined;

  if (!integrationRequest) {
    return false;
  }
  if (integrationRequest?.patient?.id) {
    patient = patients[integrationRequest?.patient?.id];
  }

  return isReadyToBatchIntegrate({ integrationRequest, patient, withRequiredFields });
}

export function isConnectorEnabledForIntegration(
  document: IntegrationRequestDto | undefined,
): boolean {
  return hasConnectorEnabled(document?.integration);
}

export function getIntegrationDashboardType(pathname: string) {
  const pattern = new RegExp(`/(${[INTEGRATED, WORKLIST].join('|')})`, 'i');
  return pathname.match(pattern)?.[1].toLocaleLowerCase() as IntegrationsDashboardType;
}
