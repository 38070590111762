import { cleanOutboxFolderSagas } from './cleanWatcherFolder.sagas';
import { initDesktopSagas } from './initDesktop.sagas';
import { moveOutboxFileSagas } from './moveOutboxFile.sagas';
import { showOutboxErrorFolderSagas } from './showOutboxErrorFolder.sagas';
import { startWatcherOnWatchPathChangedSagas } from './startWatcherOnWatchPathChanged.sagas';
import { syncSettingsToFSSagas } from './syncSettingsToFS.sagas';
import { triggerActionOnNewFileInWatchedFolder } from './triggerActionOnNewFileInWatchedFolder';

export const desktopSagas = [
  cleanOutboxFolderSagas,
  initDesktopSagas,
  moveOutboxFileSagas,
  showOutboxErrorFolderSagas,
  startWatcherOnWatchPathChangedSagas,
  syncSettingsToFSSagas,
  triggerActionOnNewFileInWatchedFolder,
];
