import { ERROR_DIRECTORY, SUCCESS_DIRECTORY } from '@launcher/constants';
import { moveFileOrDelete, removeFile } from '@launcher/helpers/desktop';
import { DesktopSettings } from '@launcher/utils';
import { all, select, takeEvery } from 'redux-saga/effects';

import { DesktopActions, MoveFileAction } from '../desktop.action';
import { selectDesktopSettings } from '../desktop.selector';

function* moveFileAfterImportSaga(data: MoveFileAction) {
  const settings: DesktopSettings = yield select(selectDesktopSettings);

  const { watchPath } = settings;

  if (settings.keepFileDuringXDays > 0) {
    const folder =
      data.type === DesktopActions.moveNonImportedFileToError.type
        ? ERROR_DIRECTORY
        : SUCCESS_DIRECTORY;

    moveFileOrDelete(data.payload.filePath, [watchPath, folder]);
  } else {
    removeFile(data.payload.filePath);
  }
}

export function* moveOutboxFileSagas() {
  yield all([takeEvery(DesktopActions.moveNonImportedFileToError.type, moveFileAfterImportSaga)]);
  yield all([takeEvery(DesktopActions.moveImportedFileToSuccess.type, moveFileAfterImportSaga)]);
}
