import { ConnectedProps, connect } from 'react-redux';

import { DesktopActions } from '@store/desktop/desktop.action';
import { selectDesktopSettings } from '@store/desktop/desktop.selector';
import { State } from '@store/reducer';

export type QAModalReduxProps = ConnectedProps<typeof qAModalConnector>;

export const qAModalConnector = connect(
  (state: State) => ({
    releaseServerURL: selectDesktopSettings(state).releaseServerURL,
  }),
  {
    updateDesktopSettings: DesktopActions.updateSettings,
  },
);
