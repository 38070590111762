import { ReactNode } from 'react';

import { Layout } from 'antd';
import cx from 'classnames';

import styles from './Full.module.less';

const { Content } = Layout;

interface FullPageProps {
  classes?: {
    root?: string;
    content?: string;
  };
  children: ReactNode;
  noPadding?: boolean;
  scroll?: boolean;
  hasSider?: boolean;
  testId?: string;
}

export const Full = ({
  classes,
  children,
  hasSider = true,
  noPadding = false,
  scroll = false,
  testId,
}: FullPageProps) => (
  <Layout className={cx(styles.layout, classes?.root)} hasSider={hasSider} data-testid={testId}>
    <Content
      className={cx(
        styles.container,
        { [styles.noPadding]: noPadding },
        classes?.content,
        scroll && styles.scroll,
      )}
    >
      {children}
    </Content>
  </Layout>
);
