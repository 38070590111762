import { createSlice } from '@reduxjs/toolkit';

import {
  fetchDocumentsSuccess,
  fetchMany,
  fetchManyFailure,
  fetchManySuccess,
} from '../common/reducers/fetchDocuments.reducers';
import {
  updateFailure,
  updateMany,
  updateManySuccess,
  updateOne,
  updateOneFailure,
  updateOneSuccess,
} from '../common/reducers/updateMeta.reducers';
import { updateSearchParams } from '../common/reducers/updateSearchParams.reducers';
import {
  BatchFailureOnlyErrorAction,
  DetailViewDocumentDtoAction,
  OneAction,
  OneFailureAction,
  OneSuccessAction,
} from '../documents.actions';

import {
  INITIAL_ERRORED_DOCUMENTS_STATE,
  INITIAL_ERRORED_DOCUMENT_METADATA,
} from './errored.state';

const slice = createSlice({
  name: 'erroredDocuments',
  initialState: INITIAL_ERRORED_DOCUMENTS_STATE,
  reducers: {
    updateSearchParams,

    fetchMany,
    fetchManySuccess: (state, _) => fetchManySuccess(state, _, INITIAL_ERRORED_DOCUMENT_METADATA),
    fetchManyFailure,
    fetchManyAttachments: (_state, _action: OneAction) => {},
    fetchDocumentsSuccess: (state, _) =>
      fetchDocumentsSuccess(state, _, INITIAL_ERRORED_DOCUMENT_METADATA),
    setDetailViewDocumentDto: (_state, _action: DetailViewDocumentDtoAction) => {},

    markAllAsRead: (state) =>
      updateMany(
        state,
        { payload: { ids: [state.dashboardView.ids[0]] }, type: 'markAsRead' },
        'markAsRead',
      ),
    markAllAsReadSuccess: (state) =>
      updateManySuccess(
        state,
        { payload: { ids: [state.dashboardView.ids[0]] }, type: 'markAsRead' },
        'markAsRead',
      ),
    markAllAsReadFailure: (state, _: BatchFailureOnlyErrorAction) =>
      updateFailure(state, _, 'markAsRead'),

    markOneAsRead: (state, _: OneAction) => updateOne(state, _, 'markAsRead'),
    markOneAsReadSuccess: (state, _: OneSuccessAction) => updateOneSuccess(state, _, 'markAsRead'),
    markOneAsReadFailure: (state, _: OneFailureAction) => updateOneFailure(state, _, 'markAsRead'),

    markOneAsUnread: (state, _: OneAction) => updateOne(state, _, 'markAsUnread'),
    markOneAsUnreadSuccess: (state, _: OneSuccessAction) =>
      updateOneSuccess(state, _, 'markAsUnread'),
    markOneAsUnreadFailure: (state, _: OneFailureAction) => updateFailure(state, _, 'markAsUnread'),

    toggleShowRead: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.dashboardView.searchParams.showRead = !state.dashboardView.searchParams.showRead;
    },
  },
});

export const documentErroredReducer = slice.reducer;

export const ErroredDocumentsActions = slice.actions;
