import { groupBy, keyBy } from '@lifen-sending/utils';
import { PayloadAction, createReducer } from '@reduxjs/toolkit';

import {
  Attachment,
  Organization,
  Patient,
  Practitioner,
  PractitionerRole,
  Professional,
  ProfessionalBaseType,
  ResourceList,
  Telecom,
} from '@honestica/core-apps-common/types';
import {
  isOrganization,
  isPatient,
  isPractitioner,
  isPractitionerRole,
} from '@honestica/core-apps-common/validators';

import {
  FetchDocumentSuccessAction,
  FetchDocumentsSuccessAction,
  FetchIntegrationRequestsSuccessAction,
  SearchPatientsSuccessAction,
  UpdateDocumentBundleSuccessAction,
  UpdateEhrPatientSuccessAction,
  UpdateIntegrationPatientSuccessAction,
  UpdateIntegrationRequestBundleSuccessAction,
  UpdateIntegrationRequestSuccessAction,
  UpdatedPatientSuccessAction,
  updateEntitiesAction,
} from '@store/documents/documents.actions';
import { IntegratedIntegrationsActions } from '@store/documents/integrations/integrated.slice';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { findOrganizationSuccess } from '@store/organizationDetail/organizationDetail.action';
import { findOrganizationsSuccess } from '@store/organizationDirectory/organizationDirectory.action';

import {
  addContactSuccess,
  findContactsSuccess,
  removeContactSuccess,
} from '../contactDirectory/contactDirectory.action';
import {
  AddContactPreferenceSuccess,
  RemoveContactPreferenceSuccess,
  addContactPreferenceSuccess,
  removeContactPreferenceSuccess,
} from '../contactPreferences/contactPreferences.action';
import { ArchivedDocumentsActions } from '../documents/incoming/archived.slice';
import { InboxDocumentsActions } from '../documents/incoming/inbox.slice';
import { ErroredDocumentsActions } from '../documents/outgoing/errored.slice';
import { SentDocumentsActions } from '../documents/outgoing/sent.slice';
import {
  createPatientSuccess,
  deletePatientsSuccess,
  findPatientSuccess,
  findPatientsSuccess,
  updatePatientSuccess,
} from '../patientDirectory/patientDirectory.action';
import { findPractitionerSuccess } from '../practitionerDetail/practitionerDetail.action';
import { findPractitionersSuccess } from '../practitionerDirectory/practitionerDirectory.action';
import {
  SearchDirectoriesSuccess,
  searchDirectoriesSuccess,
} from '../searchDirectories/searchDirectories.actions';
import { FetchIdentitiesSuccess, fetchIdentitiesSuccess } from '../user/user.actions';

import { Entities, EntitiesState, INITIAL_ENTITIES_STATE } from './entities.state';

function setPractitioners(
  state: EntitiesState,
  action: PayloadAction<ResourceList<Practitioner>>,
): EntitiesState {
  const { payload } = action;
  if (!Array.isArray(payload?.list)) {
    return state;
  }
  const newPractitionerList = payload?.list;
  const practitionersById = keyBy(newPractitionerList, 'id');

  return {
    ...state,
    Practitioner: { ...state.Practitioner, ...practitionersById },
  };
}

function setOrganizations(
  state: EntitiesState,
  action: PayloadAction<ResourceList<Organization>>,
): EntitiesState {
  const { payload } = action;
  if (!Array.isArray(payload?.list)) {
    return state;
  }
  const newOrganizationList = payload?.list;
  const organizationsById = keyBy(newOrganizationList, 'id');

  return {
    ...state,
    Organization: { ...state.Organization, ...organizationsById },
  };
}

function setPatients(state: EntitiesState, action: PayloadAction<ResourceList<Patient>>) {
  const { payload } = action;
  if (!Array.isArray(payload?.list)) {
    return state;
  }
  const newPatientList = payload?.list.filter((patient) => patient.active);
  const patientsById = keyBy(newPatientList, 'id');

  return {
    ...state,
    Patient: { ...state.Patient, ...patientsById },
  };
}

function setEntity(
  state: EntitiesState,
  { payload: entity }: PayloadAction<Patient | Professional>,
): EntitiesState {
  if (isPatient(entity)) {
    return {
      ...state,
      Patient: { ...state.Patient, [entity.id]: entity },
    };
  }

  return {
    ...state,
    [entity.type]: { ...state[entity.type], [entity.id]: entity },
  };
}

function setProfessionals(state: EntitiesState, action: PayloadAction<ResourceList<Professional>>) {
  const { list = [] } = action.payload;

  if (list.length === 0) {
    return state;
  }

  return list.reduce((newState: EntitiesState, professional: Professional) => {
    if (isPractitioner(professional)) {
      // eslint-disable-next-line no-param-reassign
      state.Practitioner[professional.id] = professional;
    }

    if (isOrganization(professional)) {
      // eslint-disable-next-line no-param-reassign
      state.Organization[professional.id] = professional;
    }

    if (isPractitionerRole(professional)) {
      // eslint-disable-next-line no-param-reassign
      state.PractitionerRole[professional.id] = professional;
    }

    return newState;
  }, state);
}

function setSearchDirectoriesResults(
  state: EntitiesState,
  action: ReturnType<SearchDirectoriesSuccess>,
): EntitiesState {
  const { practitioners, practitionerRoles, organizations, contacts } = action.payload;
  const allProfessionals: (Practitioner | Organization | PractitionerRole)[] = [
    ...practitioners.list,
    ...organizations.list,
    ...(contacts?.list ?? []),
    ...practitionerRoles.list,
  ];

  const entitiesByType = groupBy(allProfessionals, (professional) => professional.type);

  const newEntities: EntitiesState = Object.entries(entitiesByType).reduce(
    (entities, [entityType, entityArray]) => ({
      ...entities,
      [entityType]: { ...state[entityType as ProfessionalBaseType], ...keyBy(entityArray, 'id') },
    }),
    state,
  );

  return newEntities;
}

function setResources(
  state: EntitiesState,
  entities: {
    patients?: Patient[];
    practitioners?: Practitioner[];
    organizations?: Organization[];
    practitionerRoles?: PractitionerRole[];
    attachments?: Attachment[];
  },
): EntitiesState {
  setPatientsStateFromEntitesPatient(state, entities.patients);
  setOrganizationsStateFromEntitiesOrganization(state, entities.organizations);
  setPractRoleStateFromEntites(state, entities.practitionerRoles);
  setPractAndPractRoleStateFromEntites(state, entities.practitioners);
  setAttachmentsStateFromEntitesAttachments(state, entities.attachments);
  return state;
}

function togglePreference(
  state: EntitiesState,
  action: ReturnType<AddContactPreferenceSuccess | RemoveContactPreferenceSuccess>,
  isEnabled: boolean,
): EntitiesState {
  const { data, resourceId, resourceType } = action.payload;

  if (!data || !resourceId || !resourceType) {
    return state;
  }

  const professional: Practitioner | Organization =
    resourceType === 'Practitioner'
      ? state.Practitioner[resourceId]
      : state.Organization[resourceId];

  if (!professional) {
    return state;
  }

  if (data === 'postal') {
    professional.hasPostalContactPreference = isEnabled;
  } else {
    const telecomType = data.type;
    const telecomToUpdate = professional.telecoms[telecomType].find(
      (telecom: Telecom) => telecom.id === data.id,
    );
    if (!telecomToUpdate) {
      return state;
    }

    telecomToUpdate.isContactPreference = isEnabled;
  }

  return state;
}

export const entitiesReducer = createReducer(INITIAL_ENTITIES_STATE, {
  /* GLOBAL */
  [searchDirectoriesSuccess.type]: setSearchDirectoriesResults,
  [fetchIdentitiesSuccess.type]: (state, action: ReturnType<FetchIdentitiesSuccess>) => {
    setProfessionals(state, {
      ...action,
      payload: { list: action.payload as Professional[], total: 0 },
    });
  },
  [updateEntitiesAction.type]: (state, action: ReturnType<typeof updateEntitiesAction>) =>
    setResources(state, action.payload),

  /* LIFEN DOCUMENTS */
  [ArchivedDocumentsActions.fetchManySuccess.type]: (state, action: FetchDocumentsSuccessAction) =>
    setResources(state, action.payload.entities),
  [InboxDocumentsActions.fetchManySuccess.type]: (state, action: FetchDocumentsSuccessAction) =>
    setResources(state, action.payload.entities),
  [DraftDocumentsActions.fetchManySuccess.type]: (state, action: FetchDocumentsSuccessAction) =>
    setResources(state, action.payload.entities),
  [DraftDocumentsActions.updateDocumentSenderSuccess.type]: (
    state,
    action: UpdateDocumentBundleSuccessAction,
  ) => setResources(state, action.payload.bundle.entities),
  [SentDocumentsActions.fetchManySuccess.type]: (state, action: FetchDocumentsSuccessAction) =>
    setResources(state, action.payload.entities),
  [ErroredDocumentsActions.fetchManySuccess.type]: (state, action: FetchDocumentsSuccessAction) =>
    setResources(state, action.payload.entities),
  [DraftDocumentsActions.fetchOneSuccess.type]: (state, action: FetchDocumentSuccessAction) =>
    setResources(state, action.payload.bundle.entities),
  [DraftDocumentsActions.addRecipientSuccess.type]: (
    state,
    action: UpdateDocumentBundleSuccessAction,
  ) => setResources(state, action.payload.bundle.entities),
  [DraftDocumentsActions.addRecipientsSuccess.type]: (
    state,
    action: UpdateDocumentBundleSuccessAction,
  ) => setResources(state, action.payload.bundle.entities),
  [DraftDocumentsActions.addDocumentPatientSuccess.type]: (
    state,
    action: UpdateDocumentBundleSuccessAction,
  ) => setResources(state, action.payload.bundle.entities),
  [DraftDocumentsActions.updateDocumentPatientSuccess.type]: (
    state,
    action: UpdatedPatientSuccessAction,
  ) => setEntity(state, { payload: action.payload.patient, type: 'Patient' }),
  [InboxDocumentsActions.fetchOneSuccess.type]: (state, action: FetchDocumentSuccessAction) =>
    setResources(state, action.payload.bundle.entities),
  [ArchivedDocumentsActions.fetchOneSuccess.type]: (state, action: FetchDocumentSuccessAction) =>
    setResources(state, action.payload.bundle.entities),
  [DraftDocumentsActions.fetchOneSuccess.type]: (state, action: FetchDocumentSuccessAction) =>
    setResources(state, action.payload.bundle.entities),
  [SentDocumentsActions.fetchOneSuccess.type]: (state, action: FetchDocumentSuccessAction) =>
    setResources(state, action.payload.bundle.entities),

  /* PATIENT DIRECTORY */
  [createPatientSuccess.type]: setEntity,
  [deletePatientsSuccess.type]: setPatients,
  [findPatientSuccess.type]: setEntity,
  [findPatientsSuccess.type]: setPatients,
  [DraftDocumentsActions.searchPatientsSuccess.type]: (
    state,
    action: SearchPatientsSuccessAction,
  ) => setPatients(state, { ...action, payload: action.payload.patients }),
  [WorklistIntegrationsActions.searchPatientsSuccess.type]: (
    state,
    action: SearchPatientsSuccessAction,
  ) => setPatients(state, { ...action, payload: action.payload.patients }),
  [updatePatientSuccess.type]: setEntity,

  /* PROFESSIONAL DIRECTORY */
  [addContactPreferenceSuccess.type]: (state, action: ReturnType<AddContactPreferenceSuccess>) =>
    togglePreference(state, action, true),
  [addContactSuccess.type]: setEntity,
  [findContactsSuccess.type]: setProfessionals,
  [findOrganizationSuccess.type]: setEntity,
  [findOrganizationsSuccess.type]: setOrganizations,
  [findPractitionerSuccess.type]: setEntity,
  [findPractitionersSuccess.type]: setPractitioners,
  [removeContactPreferenceSuccess.type]: (
    state,
    action: ReturnType<RemoveContactPreferenceSuccess>,
  ) => togglePreference(state, action, false),
  [removeContactSuccess.type]: setEntity,

  /* LIFEN INTEGRATIONS  */
  [WorklistIntegrationsActions.fetchManySuccess.type]: (
    state,
    action: FetchIntegrationRequestsSuccessAction,
  ) => setResources(state, action.payload.entities),
  [IntegratedIntegrationsActions.fetchManySuccess.type]: (
    state,
    action: FetchIntegrationRequestsSuccessAction,
  ) => setResources(state, action.payload.entities),
  [WorklistIntegrationsActions.fetchOneSuccess.type]: (
    state,
    action: UpdateIntegrationRequestBundleSuccessAction,
  ) => setResources(state, action.payload.bundle.entities),
  [WorklistIntegrationsActions.addDocumentPatientSuccess.type]: (
    state,
    action: UpdateIntegrationRequestBundleSuccessAction,
  ) => setResources(state, action.payload.bundle.entities),
  [WorklistIntegrationsActions.updateDocumentPatientSuccess.type]: (
    state,
    action: UpdateIntegrationPatientSuccessAction,
  ) => setEntity(state, { payload: action.payload.patient, type: 'Patient' }),
  [WorklistIntegrationsActions.updateDocumentTitleSuccess.type]: (
    state,
    action: UpdateIntegrationRequestSuccessAction,
  ) => setAttachments(state, action.payload.document.id, action.payload.attachments || []),
  [IntegratedIntegrationsActions.fetchOneSuccess.type]: (
    state,
    action: UpdateIntegrationRequestBundleSuccessAction,
  ) => setResources(state, action.payload.bundle.entities),
  [WorklistIntegrationsActions.fetchOneInDashboardSuccess.type]: (
    state,
    action: UpdateIntegrationRequestBundleSuccessAction,
  ) => setResources(state, action.payload.bundle.entities),
  [DraftDocumentsActions.updateEhrPatientSuccess.type]: (
    state,
    action: UpdateEhrPatientSuccessAction,
  ) =>
    action.payload.ehrPatient &&
    setEntity(state, {
      payload: action.payload.ehrPatient,
      type: 'Patient',
    }),
  [WorklistIntegrationsActions.updateOriginalSenderSuccess.type]: (
    state,
    action: ReturnType<typeof WorklistIntegrationsActions.updateOriginalSenderSuccess>,
  ) => setResources(state, action.payload.entities),
});
function setPatientsStateFromEntitesPatient(state: EntitiesState, patients: Patient[] | undefined) {
  if (patients) {
    for (const patient of patients) {
      // eslint-disable-next-line no-param-reassign
      state.Patient[patient.id] = patient;
    }
  }
}
function setOrganizationsStateFromEntitiesOrganization(
  state: EntitiesState,
  organizations: Organization[] | undefined,
) {
  if (organizations && organizations.length > 0) {
    for (const organization of organizations) {
      // eslint-disable-next-line no-param-reassign
      state.Organization[organization.id] = organization;
    }
  }
}
function setPractRoleStateFromEntites(
  state: EntitiesState,
  practitionerRoles: PractitionerRole[] | undefined,
) {
  if (practitionerRoles && practitionerRoles.length > 0) {
    for (const practitionerRole of practitionerRoles) {
      // eslint-disable-next-line no-param-reassign
      state.PractitionerRole[practitionerRole.id] = practitionerRole;
    }
  }
}
function setPractAndPractRoleStateFromEntites(
  state: EntitiesState,
  practitioners: Practitioner[] | undefined,
) {
  if (practitioners && practitioners.length > 0) {
    for (const practitioner of practitioners) {
      // eslint-disable-next-line no-param-reassign
      state.Practitioner[practitioner.id] = practitioner;
      if (practitioner.practitionerRoles && practitioner.practitionerRoles.length > 0) {
        for (const practitionerRole of practitioner.practitionerRoles) {
          // eslint-disable-next-line no-param-reassign
          state.PractitionerRole[practitionerRole.id] = practitionerRole;
        }
      }
    }
  }
}
function setAttachmentsStateFromEntitesAttachments(
  state: EntitiesState,
  attachments: Attachment[] | undefined,
) {
  if (!attachments) return;

  // eslint-disable-next-line no-param-reassign
  state.Attachments = attachments.reduce((acc, attachment) => {
    const documentId = attachment.parentRefId as string;
    if (!Object.prototype.hasOwnProperty.call(acc, documentId)) acc[documentId] = [];
    acc[documentId].push(attachment);
    return acc;
  }, {} as Entities<Attachment[]>);
}

function setAttachments(state: EntitiesState, id: string, attachments: Attachment[]) {
  if (!state.Attachments) {
    // eslint-disable-next-line no-param-reassign
    state.Attachments = {} as Entities<Attachment[]>;
  }

  // eslint-disable-next-line no-param-reassign
  state.Attachments[id] = attachments;
}
