import {
  IntegrationRequestDto,
  SendingRequestDto,
  isIntegrationsDashboardType,
  isSendingDashboardType,
} from '@honestica/core-apps-common/types';

import { ERRORS } from '@constants/documents.constants';
import {
  documentDtoToSendingRequest,
  integrationRequestDtoToIntegrationRequest,
} from '@store/adapters';
import { DocumentDashboardType, SendingRequestFromType } from '@store/documents/documents.state';
import { EntitiesState } from '@store/entities/entities.state';

interface Params<T extends DocumentDashboardType> {
  docType: T;
  document: SendingRequestDto | IntegrationRequestDto;
  entities: EntitiesState;
}

export function convertDtoToDocumentByType<T extends DocumentDashboardType>(params: Params<T>) {
  if (isSendingDashboardType(params.docType) || params.docType === ERRORS) {
    return documentDtoToSendingRequest(
      params.document as SendingRequestDto,
      params.entities,
    ) as SendingRequestFromType<T>;
  }

  if (isIntegrationsDashboardType(params.docType)) {
    return integrationRequestDtoToIntegrationRequest(
      params.document as IntegrationRequestDto,
      params.entities,
    ) as SendingRequestFromType<T>;
  }

  return undefined;
}
