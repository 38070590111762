import { Auth0Client, RedirectLoginResult } from '@auth0/auth0-spa-js';

import {
  Auth0User,
  FullAuthUser,
  LoginOptions,
  LogoutOptions,
} from '@honestica/core-apps-common/types';

import { auth0UserToUser } from './adapters';

export class LifenConnectClient {
  constructor(private readonly authClient: Auth0Client) {}

  public static async init({
    audience,
    clientId,
    domain,
    issuer,
    redirectUri,
    scope,
    cacheLocation,
  }: {
    audience: string;
    clientId: string;
    domain: string;
    issuer: string;
    redirectUri: string;
    scope?: string;
    cacheLocation: 'localstorage' | 'memory';
  }): Promise<LifenConnectClient> {
    // Because of clock skew issues, you may occasionally encounter the error The token was issued in the future.
    // The leeway parameter can be used to allow a few seconds of leeway to ID Token expiration times,
    // to prevent that from occurring.

    const authClient = new Auth0Client({
      audience,
      client_id: clientId,
      domain,
      leeway: 3600, // fix
      redirect_uri: redirectUri,
      issuer,
      scope,
      cacheLocation,
    });

    return new LifenConnectClient(authClient);
  }

  public async isAuthenticated(): Promise<boolean> {
    return (await this.authClient?.isAuthenticated()) ?? false;
  }

  public async getUserSettings(): Promise<FullAuthUser | undefined> {
    if (await this.isAuthenticated()) {
      const token = await this.getTokenSilently();
      const user = await this.getUser();
      return auth0UserToUser({ auth0User: user, token, isAuthenticated: true });
    }

    return undefined;
  }

  public async login(): Promise<void> {
    await this.authClient.loginWithPopup();
  }

  public async loginWithRedirect(loginOptions: LoginOptions): Promise<void> {
    await this.authClient.loginWithRedirect(loginOptions);
  }

  public async logout(settings?: LogoutOptions): Promise<void> {
    return await this.authClient.logout(settings);
  }

  public async handleRedirect(): Promise<RedirectLoginResult> {
    return await this.authClient.handleRedirectCallback();
  }

  private async getTokenSilently(): Promise<string> {
    return await this.authClient?.getTokenSilently();
  }

  private async getUser(): Promise<Auth0User> {
    return (await this.authClient?.getUser()) as Auth0User;
  }
}
