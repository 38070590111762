import {
  updateAndDropManySuccess,
  updateOneSuccess,
} from '@store/documents/common/reducers/updateMeta.reducers';
import {
  BatchDeferredSuccessAction,
  OneDeferredSuccessAction,
} from '@store/documents/documents.actions';

import { DraftDocumentMetadataKey, DraftDocumentsState } from '../draft.state';

export function sendOneSuccess(state: DraftDocumentsState, action: OneDeferredSuccessAction) {
  const newState = updateOneSuccess(state, action, DraftDocumentMetadataKey.sendDocument);

  return {
    ...newState,
    dashboardView: {
      ...newState.dashboardView,
      ids: newState.dashboardView.ids.filter((id) => id !== action.payload.id),
    },
  } as DraftDocumentsState;
}

export function sendManySuccess(state: DraftDocumentsState, action: BatchDeferredSuccessAction) {
  const newState = updateAndDropManySuccess(state, action, DraftDocumentMetadataKey.sendDocument);

  return {
    ...newState,
    dashboardView: {
      ...newState.dashboardView,
      ids: newState.dashboardView.ids.filter((id) => !action.payload.ids.includes(id)),
    },
  } as DraftDocumentsState;
}
