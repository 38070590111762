import React, { FC } from 'react';

import { DesktopSettings } from '@launcher/components/DesktopSettings';
import { ErrorPage } from '@launcher/components/ErrorPage';
import { ForbiddenAccess } from '@launcher/components/ForbiddenAccess';
import { AppError } from '@launcher/constants';
import { Breakpoint, useViewportLargerThan } from '@launcher/context/Viewport.context';
import { useDefaultAppRoute } from '@launcher/hooks';
import { isDesktopApp } from '@launcher/utils';
import * as Sentry from '@sentry/react';
import { Translation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import { MainRouteUrls } from '@honestica/core-apps-common/types';

import { getFeatureFlags } from '@store/user/user.selector';

import { LauncherModals } from '../Modals';
import { MainSidebar } from '../Sidebar';

import appsConnector, { AppsReduxProps } from './apps.connector';

const LazySendingRoutes = React.lazy(
  () => import('@lifen-sending/components/DocumentsRouter/DocumentsRouter.component'),
);
const LazyIntegrationRoute = React.lazy(
  () => import('@lifen-integration/components/IntegrationsRouter/IntegrationsRouter.component'),
);

const AppsComponent: FC<AppsReduxProps> = ({ lifenSendingActivated, switchWorkspace }) => {
  const showSidebar = useViewportLargerThan(Breakpoint.L);
  useDefaultAppRoute();
  const featureFlags = useSelector(getFeatureFlags);

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {showSidebar && <MainSidebar switchWorkspace={switchWorkspace} />}
      {featureFlags['cafe-maintenance'] ? (
        <Routes>
          <Route
            path={MainRouteUrls.Maintenance}
            element={<ErrorPage errorKey={AppError.Maintenance} />}
          />
          <Route path="*" element={<Navigate to={MainRouteUrls.Maintenance} replace />} />
        </Routes>
      ) : (
        <>
          <Sentry.ErrorBoundary
            fallback={
              <Translation>
                {(t) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      height: '100vh',
                    }}
                  >
                    <p>{t('error.boundary.apologies')}</p>
                    <p>{t('error.boundary.contact')}</p>
                  </div>
                )}
              </Translation>
            }
          >
            <Routes>
              <Route
                path={`${MainRouteUrls.Documents}/*`}
                element={lifenSendingActivated ? <LazySendingRoutes /> : <ForbiddenAccess />}
              />
              <Route path={`${MainRouteUrls.Integrations}/*`} element={<LazyIntegrationRoute />} />
              {isDesktopApp() && (
                <Route path={MainRouteUrls.DesktopSettings} element={<DesktopSettings />} />
              )}
            </Routes>
          </Sentry.ErrorBoundary>
        </>
      )}
      <LauncherModals />
    </div>
  );
};

export const Apps = appsConnector(AppsComponent);
