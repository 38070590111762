import { useCallback, useMemo } from 'react';

import { isLoginWebview as isLoginWebviewUtil } from '@launcher/helpers/desktop/loginWebview.helper';
import { http } from '@services/http/http.service';
import { i18n } from '@services/i18n';
import { LocalStorage } from '@services/localStorage.service';
import { logger } from '@services/logger/logger.service';
import { useDispatch } from 'react-redux';

import { AuthUser } from '@honestica/core-apps-common/types';

import { authenticateUserSuccess } from '@store/user/user.actions';

export function useAuthenticateUser() {
  const dispatch = useDispatch();

  const isLoginWebview = useMemo(() => isLoginWebviewUtil(), []);

  return useCallback(
    async (user: AuthUser) => {
      logger.info('LOGIC', 'handleAuthenticationSuccess', {
        email: user.email,
        workspace: user.currentWorkspaceId,
      });

      // Persist email and workspace ID for next connection
      if (user?.email?.length > 0) {
        await LocalStorage.set('LIFEN_USER_EMAIL', user.email);
      }

      if (user?.currentWorkspaceId?.length > 0) {
        await LocalStorage.set('WORKSPACE_ID', user.currentWorkspaceId);
      }

      // User data has been dispatched, no more reason to go further
      if (isLoginWebview) {
        return;
      }

      // Set token that will be passed to each request using HTTP service
      if (user.token) {
        http.setToken(user.token);
      }

      i18n.changeLanguage(user.preferredLocale);

      // Dispatch action to store user data
      dispatch(authenticateUserSuccess(user));
    },
    [dispatch, isLoginWebview],
  );
}
