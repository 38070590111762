import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from '../../Button';

import styles from './TextInputForm.module.less';

interface TextInputFormProps {
  defaultValue?: string | undefined;
  placeholder: string;
  onSearch: (value: string) => void;
}

export const TextInputForm = ({ defaultValue, placeholder, onSearch }: TextInputFormProps) => {
  const { t } = useTranslation();

  const submit = (values: any) => {
    onSearch(values['value']);
  };

  return (
    <div className={styles.textInputForm}>
      <Form
        data-testid="text-input-form"
        layout="vertical"
        className={styles.form}
        onFinish={submit}
      >
        <div className={styles.textInputSearchFormFields}>
          <Form.Item htmlFor="text" initialValue={defaultValue} name="value">
            <Input
              name="value"
              data-testid="text-input-value"
              placeholder={placeholder}
              autoFocus
              maxLength={100}
            />
          </Form.Item>
        </div>
        <Button htmlType="submit" type="primary" className={styles.submit}>
          {t('search')}
        </Button>
      </Form>
    </div>
  );
};
