import { v4 as uuid } from 'uuid';

import { DeferredActionsType } from '@store/actionDeferred/actionsDeferred.state';
import {
  BatchDeferredSuccessAction,
  OneDeferredSuccessAction,
  UpdateDocumentSenderSuccessAction,
} from '@store/documents/documents.actions';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';

import { ActionsDispatchingSuccessInfoMessage, InfoMessageType } from '../infoMessages.state';

const successActionsToInfoMessageType: Record<string, InfoMessageType> = {
  [DraftDocumentsActions.sendManySuccess.type]: InfoMessageType.documentsSent,
  [DraftDocumentsActions.sendOneSuccess.type]: InfoMessageType.documentSent,
  [InboxDocumentsActions.downloadManyNoAttachmentFailure.type]:
    InfoMessageType.downloadWithoutAttachment,
  [DraftDocumentsActions.updateDocumentSenderSuccess.type]: InfoMessageType.updateDocumentSender,
};

export const triggerDeferredActionsTypeToPendingInfoMessageType: Record<string, InfoMessageType> = {
  [DeferredActionsType.sendMany]: InfoMessageType.sendDocumentsPending,
  [DeferredActionsType.sendDocument]: InfoMessageType.sendDocumentPending,
};

export function convertSuccessActionsToInfoMessagePayload(
  action: ActionsDispatchingSuccessInfoMessage,
) {
  if (isSendDraftDocumentsSuccessAction(action)) {
    const SendManyAction = action as BatchDeferredSuccessAction;
    return {
      type: successActionsToInfoMessageType[action.type],
      labelOptions: { count: SendManyAction.payload?.ids.length },
      id: SendManyAction.payload.deferredId,
    };
  }
  if (isSendDocumentSuccessAction(action)) {
    const SendOneAction = action as OneDeferredSuccessAction;
    return {
      type: successActionsToInfoMessageType[action.type],
      id: SendOneAction.payload.deferredId,
    };
  }
  if (isDownloadManyNoAttachmentFailureAction(action)) {
    return {
      type: successActionsToInfoMessageType[action.type],
      id: uuid(),
    };
  }
  if (isUpdateDocumentSenderSuccessAction(action)) {
    // add FF
    const updateDocumentSenderAction = action as UpdateDocumentSenderSuccessAction;
    return {
      type: successActionsToInfoMessageType[action.type],
      labelOptions: { senderName: updateDocumentSenderAction.payload?.senderName },
      id: updateDocumentSenderAction.payload?.bundle.document.id,
    };
  }

  return undefined;
}

function isSendDraftDocumentsSuccessAction(action: ActionsDispatchingSuccessInfoMessage): boolean {
  return action.type === DraftDocumentsActions.sendManySuccess.type;
}

function isSendDocumentSuccessAction(action: ActionsDispatchingSuccessInfoMessage): boolean {
  return action.type === DraftDocumentsActions.sendOneSuccess.type;
}

function isDownloadManyNoAttachmentFailureAction(
  action: ActionsDispatchingSuccessInfoMessage,
): boolean {
  return action.type === InboxDocumentsActions.downloadManyNoAttachmentFailure.type;
}
function isUpdateDocumentSenderSuccessAction(
  action: ActionsDispatchingSuccessInfoMessage,
): boolean {
  return action.type === DraftDocumentsActions.updateDocumentSenderSuccess.type;
}
