import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { all, getContext, put, select, takeLatest } from 'redux-saga/effects';

import {
  DocumentStatus,
  DocumentVerificationStatus,
  SendingRequestBundle,
  SendingRequestDto,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import * as API from '@api';
import {
  UpdateDocumentDatePeriodAction,
  UpdateDocumentSenderAction,
  UpdateDocumentTypeAction,
} from '@store/documents/documents.actions';
import { selectDraftDetailDocumentDto } from '@store/documents/outgoing/draft.selector';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { invalidateDashboardCache, updateDocumentInCache } from '@utils/caching.util';
import { professionalToIdentityReference } from '@utils/identities.util';

function* updateOneDocumentTypeSaga({ payload: { docType: newType } }: UpdateDocumentTypeAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const { document } = yield API.updateDocument(docDto.id, {
      documentType: newType,
    });
    yield put(DraftDocumentsActions.updateDocumentTypeSuccess({ document }));
    yield updateDocumentInCache({ updatedDocument: document, queryClient });
  } catch (error) {
    logger.error('LOGIC', 'Failed to update document type', error);
    yield put(
      DraftDocumentsActions.updateDocumentTypeFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

function* updateOneDocumentDatePeriodSaga({
  payload: { newPeriod },
}: UpdateDocumentDatePeriodAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  const { startPeriod, endPeriod } = newPeriod;
  try {
    const docUpdated: SendingRequestBundle = yield API.updateDocument(docDto.id, {
      startPeriod,
      endPeriod,
    });
    yield put(
      DraftDocumentsActions.updateDocumentDatePeriodSuccess({ document: docUpdated.document }),
    );
    yield updateDocumentInCache({ updatedDocument: docUpdated.document, queryClient });
  } catch (err) {
    logger.error('LOGIC', 'Failed to update date period document', err);
    yield put(
      DraftDocumentsActions.updateDocumentDatePeriodFailure({
        document: { ...docDto, startPeriod: docDto.authoredOn }, // strange value
      }),
    ); // Why??? -> no error message
  }
}

function* updateOneDocumentSenderSaga({
  payload: { newSender, senderName },
}: UpdateDocumentSenderAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const bundleUpdated: SendingRequestBundle = yield API.updateDocument(docDto.id, {
      sender: newSender,
    });
    yield put(
      DraftDocumentsActions.updateDocumentSenderSuccess({ bundle: bundleUpdated, senderName }),
    );
    // Update cache for global draft
    yield updateDocumentInCache({
      updatedDocument: bundleUpdated.document,
      queryClient,
    });
    // Invalidate cache for previous and new identity
    const previousIdentity = professionalToIdentityReference(docDto.sender);
    const newIdentity = professionalToIdentityReference(bundleUpdated.document.sender);
    yield invalidateDashboardCache({
      dashboardType: SendingsDashboardType.Draft,
      identity: previousIdentity,
      refetchType: 'active',
      queryClient,
    });
    yield invalidateDashboardCache({
      dashboardType: SendingsDashboardType.Draft,
      identity: newIdentity,
      refetchType: 'active',
      queryClient,
    });
    // Update draft/readyToSend counters for previous and new identity
    yield put(
      DraftDocumentsActions.decrementDraftCounter({
        identity: previousIdentity,
        count: 1,
      }),
    );
    yield put(
      DraftDocumentsActions.incrementDraftCounter({
        identity: newIdentity,
        count: 1,
      }),
    );
    if (
      bundleUpdated.document.status === DocumentStatus.Suspended &&
      bundleUpdated.document.verificationStatus === DocumentVerificationStatus.ReadyToSend
    ) {
      yield put(
        DraftDocumentsActions.incrementReadyToSendCounter({ identity: newIdentity, count: 1 }),
      );
      yield put(
        DraftDocumentsActions.decrementReadyToSendCounter({ identity: previousIdentity, count: 1 }),
      );
    }
  } catch (err) {
    logger.error('LOGIC', 'Failed to update document sender', err);
    yield put(
      DraftDocumentsActions.updateDocumentSenderFailure({
        document: { ...docDto, sender: docDto.sender },
      }),
    ); // Why??? -> no error message
  }
}

export function* updatePropertiesDraftDocumentsSagas() {
  yield all([
    takeLatest(DraftDocumentsActions.updateDocumentType.type, updateOneDocumentTypeSaga),
    takeLatest(
      DraftDocumentsActions.updateDocumentDatePeriod.type,
      updateOneDocumentDatePeriodSaga,
    ),
    takeLatest(DraftDocumentsActions.updateDocumentSender.type, updateOneDocumentSenderSaga),
  ]);
}
