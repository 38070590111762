import {
  CONNECTION,
  DESKTOP_LOGIN,
  EMAIL,
  EMAIL_FROM_LOCALSTORAGE_DESKTOP,
  LOGIN_PARTITION,
  WORKSPACE_ID_LOCALSTORAGE_KEY,
} from '@launcher/constants';
import { EnvManager } from '@services/environment/environment.service';
import { LocalStorage } from '@services/localStorage.service';
import { Logger } from '@services/logger/logger.service';

export const isLoginWebview = (): boolean => {
  const params = new URL(window.location.href).searchParams;
  const isLoginWebViewFromSessionStorage = !!window.sessionStorage.getItem('IS_LOGIN_WEBVIEW');
  const isLoginWebivewFromSearchParams = Boolean(params.get(DESKTOP_LOGIN));

  // We use session storage to keep trace of webview after auth provider redirection (we lost search params in this case)
  if (isLoginWebivewFromSearchParams && !isLoginWebViewFromSessionStorage) {
    window.sessionStorage.setItem('IS_LOGIN_WEBVIEW', '1');
  }

  return isLoginWebivewFromSearchParams || isLoginWebViewFromSessionStorage;
};

export function getLoginWebviewSearchParams(username: string, ssoConnection: string) {
  const searchParams = new URLSearchParams();

  // Allows to differenciate login webview from main app
  searchParams.set(DESKTOP_LOGIN, 'true');

  const workspaceIdFromLocalStorage = LocalStorage.get('WORKSPACE_ID');
  const emailFromLocalStorage = LocalStorage.get('LIFEN_USER_EMAIL');

  if (workspaceIdFromLocalStorage) {
    searchParams.set(WORKSPACE_ID_LOCALSTORAGE_KEY, workspaceIdFromLocalStorage);
  }

  if (username !== '') {
    if (emailFromLocalStorage) {
      LocalStorage.remove('LIFEN_USER_EMAIL');
    }
    searchParams.set(EMAIL, username);
  } else if (emailFromLocalStorage) {
    searchParams.set(EMAIL_FROM_LOCALSTORAGE_DESKTOP, emailFromLocalStorage);
  }

  if (ssoConnection !== '') {
    searchParams.set(CONNECTION, ssoConnection);
  }

  return searchParams.toString();
}

/**
 * The login page might be expired after the app is open for a long time.
 * When the focus on the app comes back, we check if the session is expired (auth0 cookie), then restart if needed
 */
export function resetAuthFlowWhenSessionIsExpiredOfWebview(
  webview: any,
  isDesktopApp: boolean,
  logger: Logger,
) {
  const event = isDesktopApp ? 'dom-ready' : 'focus';
  const checkAndRestartAuthFlow = async () => {
    logger.info('LOGIC', 'Starting session expired checker');
    if (webview) {
      logger.info('LOGIC', 'Checking if session is expired');

      const cookies = await window.electron.getCookies({
        partition: LOGIN_PARTITION,
        filter: {
          name: 'auth0',
          domain: EnvManager.getAuth0Domain(),
        },
      });

      if (cookies.length === 0) {
        logger.info('LOGIC', 'Session expired: restarting login flow');
        webview.goToIndex(0);
      }
    } else {
      logger.info('LOGIC', 'Clearing session expired checker');
      window.removeEventListener(event, checkAndRestartAuthFlow);
    }
  };
  logger.info('LOGIC', 'Adding session expired checker');
  window.addEventListener(event, checkAndRestartAuthFlow);
}
