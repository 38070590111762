import {
  Children,
  Component,
  ComponentProps,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  ReactText,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { Layout, Space, Tag } from 'antd';
import cx from 'classnames';

import Typography from '../Typography';

import styles from './NavMenu.module.less';

const { Sider } = Layout;
const { Title } = Typography;

interface NavMenuProps {
  children?: ReactNode;
  childrenClassName?: string;
  title?: string;
  onSelectItem: (key: ReactText) => void;
  currentKey?: ReactText;
  actions?: ReactElement[];
  illustration?: ReactElement;
  rightElement?: ReactElement;
  menuInfos?: any;
  withFooterContainer?: boolean;
}

interface NavSubmMenuProps {
  children: ReactNode;
  indent?: boolean;
  icon?: ReactNode;
  title?: string;
}

export interface NavMenuItemProps extends ComponentProps<'div'> {
  indent?: boolean;
  current?: boolean;
  icon?: ReactNode;
  itemKey: ReactText;
  count?: number | string;
  title?: string;
  fontWeightReset?: boolean;
}

const MenuContext = createContext<{
  onClick: NavMenuProps['onSelectItem'];
  currentKey: NavMenuProps['currentKey'];
}>({
  onClick: () => {},
  currentKey: 0,
});

const NavWithFooter = ({ condition, children }: { condition: boolean; children: ReactNode[] }) => (
  <>{condition ? <div className={styles.withFooter}>{children}</div> : children}</>
); // should handle real footer (distinct from children)

const NavMenuComponent = ({
  children,
  childrenClassName,
  title,
  onSelectItem,
  currentKey,
  actions,
  illustration,
  rightElement,
  withFooterContainer,
  ...otherProps
}: NavMenuProps) => {
  const providerValue = useMemo(
    () => ({ onClick: onSelectItem, currentKey }),
    [onSelectItem, currentKey],
  );

  return (
    <Sider className={styles.wrapper} theme="light" width={280} {...otherProps}>
      <NavWithFooter condition={!!withFooterContainer}>
        <NavMenuTitle title={title} illustration={illustration} rightElement={rightElement} />
        <NavMenuActions actions={actions} />
        <MenuContext.Provider value={providerValue}>
          <div className={cx(styles.menuItemWrapper, childrenClassName)}>{children}</div>
        </MenuContext.Provider>
      </NavWithFooter>
    </Sider>
  );
};

const NavMenuActions = ({ actions }: { actions?: ReactElement[] }) => (
  <>
    {actions && (
      <Space direction="vertical" className={styles.actionButtonWrapper}>
        {actions}
      </Space>
    )}
  </>
);

const NavMenuTitle = ({
  title,
  illustration,
  rightElement,
}: {
  title?: string;
  illustration?: ReactElement;
  rightElement?: ReactElement;
}) => (
  <>
    {title && (
      <div className={styles.titleContainer}>
        {illustration}
        <Title level={4} className={styles.title}>
          {title}
        </Title>
        {rightElement}
      </div>
    )}
  </>
);

const NavMenuItem = ({
  className,
  children,
  indent = false,
  icon,
  title,
  itemKey,
  count,
  fontWeightReset,
  ...otherProps
}: NavMenuItemProps) => {
  const { onClick, currentKey } = useContext(MenuContext);
  const isCurrent = itemKey === currentKey;

  return (
    <div
      className={cx(styles.menuItem, className, {
        [styles.indented]: indent,
        [styles.current]: isCurrent,
        [styles.fontWeightReset]: fontWeightReset,
      })}
      role="button"
      onClick={() => onClick(itemKey)}
      onKeyPress={(e) => e.key === 'Enter' && onClick(itemKey)}
      tabIndex={0}
      {...otherProps}
    >
      <div key={itemKey} className={styles.navMenuContainer}>
        <div className={styles.iconAndTitleContainer}>
          {icon}
          <span className={styles.itemName}>{title}</span>
        </div>
        {!!count && (
          <Tag className={styles.notifCounter} data-testid="sidebar-counter">
            {Number(count) > 99 ? '99+' : count}
          </Tag>
        )}
      </div>
      {children}
    </div>
  );
};

const NavSubMenu = ({ children, indent = false, icon, title }: NavSubmMenuProps) => {
  const { currentKey } = useContext(MenuContext);
  const childrenKeys = useMemo(
    () =>
      Children.toArray(children).reduce<ReactText[]>((mem, child) => {
        const key = (child as unknown as { props?: { itemKey?: ReactText } }).props?.itemKey;
        if (key) {
          mem.push(key);
        }
        return mem;
      }, []),
    [children],
  );

  const isCurrent = useMemo(
    () => currentKey !== undefined && childrenKeys.includes(currentKey),
    [childrenKeys, currentKey],
  );

  const [visible, setVisible] = useState(isCurrent);

  const toggleVisibility: MouseEventHandler<HTMLDivElement> = () => setVisible(!visible);

  return (
    <div
      className={cx(styles.menuItem, { [styles.indented]: indent, [styles.current]: isCurrent })}
      role="button"
      onClick={toggleVisibility}
      tabIndex={0}
    >
      <div className={styles.titleContainer}>
        {icon}
        {title && (
          <Title level={5} className={styles.subtitle}>
            {title}
          </Title>
        )}
      </div>
      {visible && <div className={styles.menuItemWrapper}>{children}</div>}
    </div>
  );
};

// TODO: These components should be tested in isolation (99+, no notification if count undefined....)

export class NavMenu extends Component<NavMenuProps> {
  static Item = NavMenuItem;

  static SubMenu = NavSubMenu;

  render() {
    const { children } = this.props;
    return <NavMenuComponent {...this.props}>{children}</NavMenuComponent>;
  }
}
