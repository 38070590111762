import { DocumentStatus } from '@honestica/core-apps-common/types';
import { isIntegration } from '@honestica/core-apps-common/validators';

import { OneAction, SwitchEncounterSuccessAction } from '../../documents.actions';
import { XHR_STATUS_LOADING } from '../../documents.state';
import { WorklistIntegrationsState } from '../worklist.state';

export function onRefreshPatientMatch(state: WorklistIntegrationsState, action: OneAction) {
  return {
    ...state,
    entities: {
      ...state.entities,
      [action.payload.id]: {
        resource: {
          ...state.entities[action.payload.id].resource,
          status: DocumentStatus.Draft,
        },
        meta: {
          ...state.entities[action.payload.id].meta,
          refreshPatientMatch: { ...XHR_STATUS_LOADING, timeout: false },
        },
      },
    },
    detailView: {
      ...state.detailView,
      ...(state.detailView.documentDto && {
        documentDto: {
          ...state.detailView.documentDto,
          status: DocumentStatus.Draft,
        },
      }),
    },
  };
}

export const onSwitchEncounterSuccess = (
  state: WorklistIntegrationsState,
  action: SwitchEncounterSuccessAction,
): WorklistIntegrationsState => {
  const { id, encounter } = action.payload;

  const entity = state.entities[id];

  if (!entity || !isIntegration(entity.resource.integration)) {
    return state;
  }

  return {
    ...state,
    entities: {
      ...state.entities,
      [id]: {
        ...entity,
        resource: {
          ...entity.resource,
          integration: {
            ...entity.resource.integration,
            encounter,
          },
        },
      },
    },
  };
};
