import { ERROR_DIRECTORY, SUCCESS_DIRECTORY } from '@launcher/constants';

export function getAllNestedWatchPaths(watchPaths: string[], includeRoot = true): string[] {
  const allWatchPaths: string[] = [];

  for (const path of watchPaths) {
    if (typeof path !== 'string' || path.length < 1) {
      continue;
    }

    if (includeRoot) allWatchPaths.push(path);

    allWatchPaths.push(
      window.electron.joinPaths(path, SUCCESS_DIRECTORY), // /Success
      window.electron.joinPaths(path, ERROR_DIRECTORY), // /Error
    );
  }

  return allWatchPaths;
}
