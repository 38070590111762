import { Action } from '@reduxjs/toolkit';
import { Tracking } from '@services/analytics';
import { all, getContext, select, takeLatest } from 'redux-saga/effects';

import { DashboardSearchParams } from '@honestica/core-apps-common/types';

import { selectDashboardSearchParams } from '@store/documents/documents.selector';
import { selectDraftCurrentIdentityReference } from '@store/documents/outgoing/draft.selector';
import { selectPathName } from '@store/router/router.selector';
import { cleanFFSearchParams, searchPatientToParamList } from '@utils/search.util';

import { getDashboardTypeFromRoute } from './utils/tracking.utils';

function* trackFilters() {
  const pathname: string = yield select(selectPathName);
  const dashboardType = getDashboardTypeFromRoute(pathname);

  if (dashboardType) {
    const trackingService: Tracking = yield getContext('trackingService');
    const searchParams: DashboardSearchParams = yield select((state) =>
      selectDashboardSearchParams(state, dashboardType),
    );
    const currentIdentity: string | undefined = yield select((state) =>
      selectDraftCurrentIdentityReference(state),
    );

    const params = cleanFFSearchParams({
      searchParams,
      dashboardType,
      currentIdentity,
    });

    const {
      selectedSenderFilter,
      strictSender,
      selectedStatusFilter,
      selectedMediumFilter,
      source,
      recipient,
      page,
      dateRange,
      defaultDateRange,
      searchPatient,
      hideOtherRequesters,
    } = params;

    if (
      searchPatient ||
      (selectedSenderFilter && !strictSender) ||
      selectedStatusFilter ||
      selectedMediumFilter ||
      source ||
      hideOtherRequesters ||
      recipient ||
      defaultDateRange === false ||
      dateRange ||
      (page && page !== 1)
    ) {
      trackingService.dashboardFiltered({
        patientAdvancedFiltered: searchPatient
          ? searchPatientToParamList(searchPatient)
          : undefined,
        servicesFiltered: !strictSender ? selectedSenderFilter : undefined,
        sourceFiltered: source,
        statusFiltered: selectedStatusFilter,
        mediumFiltered: selectedMediumFilter,
        hideOtherRequesters,
        recipient,
        page,
        dashboardType,
        dateRange,
        defaultDateRange,
      });
    }
  }
}

export function* trackingDocumentDashboardFiltersSagas() {
  yield all([takeLatest((action: Action) => /fetchManySuccess$/.test(action.type), trackFilters)]);
}
