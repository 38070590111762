import { useCallback, useEffect, useState } from 'react';

import { EnvManager } from '@services/environment';
import { useTranslation } from 'react-i18next';

import { Badge, Icon, Space } from '@honestica/ui-kit/src';

export enum ServiceReachabilityService {
  intercom = 'intercom',
  launchdarkly = 'launchdarkly',
  pusher = 'pusher',
  segment = 'segment',
}

enum Status {
  pending = 'pending',
  loading = 'loading',
  success = 'success',
  error = 'error',
}

type Props = {
  service: ServiceReachabilityService;
  needsRefreshAt?: Date;
};

const servicePingUrl: Record<ServiceReachabilityService, string[]> = {
  intercom: [
    'https://api-iam.intercom.io' /* bypass-url-check-spec */,
    'https://widget.intercom.io' /* bypass-url-check-spec */,
    'https://js.intercomcdn.com' /* bypass-url-check-spec */,
  ],
  launchdarkly: [
    'https://app.launchdarkly.com' /* bypass-url-check-spec */,
    'https://events.launchdarkly.com' /* bypass-url-check-spec */,
  ],
  pusher: [
    `https://${EnvManager.getBaseUrlProxy() || ''}${EnvManager.getPusherWsPath() || ''}`,
  ] /* bypass-url-check-spec */,
  segment: [
    'https://api.segment.io' /* bypass-url-check-spec */,
    'https://cdn.segment.com' /* bypass-url-check-spec */,
  ],
};

export function ServiceReachability(props: Props) {
  const { t } = useTranslation();
  const [status, setStatus] = useState(Status.pending);
  const [lastRefresh, setLastRefresh] = useState(props.needsRefreshAt);

  const checkStatus = useCallback(async () => {
    setStatus(Status.loading);
    const urls = servicePingUrl[props.service];
    await Promise.all(urls.map((url) => fetch(url, { mode: 'no-cors' })))
      .then(() => setStatus(Status.success))
      .catch(() => setStatus(Status.error));
  }, [props.service]);

  useEffect(() => {
    if (props.needsRefreshAt && (!lastRefresh || props.needsRefreshAt > lastRefresh)) {
      setStatus(Status.pending);
      setLastRefresh(props.needsRefreshAt);
    }
  }, [lastRefresh, props.needsRefreshAt]);

  useEffect(() => {
    if (status === Status.pending) {
      checkStatus();
    }
  }, [checkStatus, status]);

  return (
    <Space direction="horizontal" className="align-items-start">
      <span>
        {status === Status.loading || status === Status.pending ? (
          <Icon name="Refresh" size={14} />
        ) : (
          <Badge status={status} />
        )}
      </span>
      <span>{t(`whitelistsDiagnostics.${props.service}`)}</span>
    </Space>
  );
}
