import { CreateDematRecipient, MediumType } from '../types';
import { Telecom, TelecomType, Telecoms } from '../types/entities/telecom';
import { isTelecom } from '../validators';

export const getMediumType = (val: any): TelecomType | MediumType =>
  isTelecom(val) ? val.type : MediumType.POSTAL;

export const getEmptyTelecoms = (): Telecoms => ({
  phone: [],
  landlinePhone: [],
  email: [],
  apicrypt: [],
  mssante: [],
  lifen: [],
  ens: [],
});

export const getPhoneTelecomFromValue = (value: string, isLandline = false): Telecom => ({
  id: undefined,
  type: isLandline ? TelecomType.LANDLINE : TelecomType.PHONE,
  value,
  isContactPreference: false,
});

export const getEmailTelecomFromValue = (value: string): Telecom => ({
  id: undefined,
  type: TelecomType.EMAIL,
  value,
  isContactPreference: false,
});

export const getTelecomFromDematRecipient = (
  dematRecipient: CreateDematRecipient,
): Partial<Telecoms> => {
  const telecoms: Telecoms = {
    email: [],
    lifen: [],
    phone: [],
    landlinePhone: [],
    mssante: [],
    apicrypt: [],
    ens: [],
  };
  telecoms.email.push({
    id: undefined,
    value: dematRecipient.email,
    type: TelecomType.EMAIL,
    displayName: dematRecipient.name,
    isContactPreference: false,
  });
  telecoms.phone.push({
    id: undefined,
    value: dematRecipient.phoneNumber,
    type: TelecomType.PHONE,
    displayName: dematRecipient.name,
    isContactPreference: false,
  });

  return telecoms;
};
