import { ReactNode, useMemo } from 'react';

import {
  Dropdown as AntDropdown,
  DropDownProps as AntDropdownProps,
  Menu,
  MenuItemProps,
  MenuProps,
} from 'antd';
import cx from 'classnames';

import colors from '../../theme/colors';
import Icon from '../Icon';
import Space from '../Space';

import styles from './Dropdown.module.less';

export type DropdownMenuOptions = (DropdownMenuItemProps | DropdownMenuItemGroupProps)[];

export type DropdownMenuItemProps = MenuItemProps & {
  key: string;
  selected?: boolean;
  displayTitle?: boolean;
  dataTestId?: string;
};

type DropdownMenuItemGroupProps = {
  key: string;
  title: string;
  items: DropdownMenuItemProps[];
};

type DropdownProps = Omit<AntDropdownProps, 'overlay'> & {
  options: DropdownMenuOptions;
  selectedItemKeys?: string[];
  menuProps?: MenuProps;
  header?: ReactNode;
  children: ReactNode;
};

function isMenuItemGroup(val: any): val is DropdownMenuItemGroupProps {
  return Array.isArray(val?.items);
}

const MenuItemComponent = ({
  children,
  className,
  key,
  selectedItemKeys,
  selected,
  dataTestId,
  ...rest
}: DropdownMenuItemProps & { selectedItemKeys?: string[] }) => (
  <Menu.Item
    {...rest}
    className={cx(styles.menuItem, className)}
    key={key}
    data-testid={dataTestId}
  >
    <Space fullWidth>
      {children}
      {(selected || selectedItemKeys?.includes(key)) && (
        <Icon
          name="TickMinor"
          color={colors.primary[5]}
          title={key}
          size={20}
          className={styles.currentlySelectedIcon}
        />
      )}
    </Space>
  </Menu.Item>
);

export const Dropdown = ({
  children,
  header,
  menuProps,
  options,
  selectedItemKeys,
  ...rest
}: DropdownProps) => {
  const overlay = useMemo(
    () => (
      <Menu className={styles.menu} {...menuProps}>
        {header && (
          <Menu.Item disabled className={styles.dropdownHeader}>
            {header}
          </Menu.Item>
        )}

        {options.map((option) => {
          if (isMenuItemGroup(option)) {
            return (
              <Menu.ItemGroup title={option.title} key={option.key}>
                {option.items.map((childOption) =>
                  MenuItemComponent({
                    ...childOption,
                    selectedItemKeys,
                  }),
                )}
              </Menu.ItemGroup>
            );
          }
          return MenuItemComponent({ ...option, selectedItemKeys });
        })}
      </Menu>
    ),
    [options, selectedItemKeys, menuProps, header],
  );

  return (
    <AntDropdown dropdownRender={() => overlay} trigger={['click']} {...rest}>
      {children}
    </AntDropdown>
  );
};
