import { getContext, select, takeLatest } from 'redux-saga/effects';

import { GetDocumentAction } from '@store/documents/documents.actions';
import { selectDocumentEntity, selectDocumentPageCount } from '@store/documents/documents.selector';
import { DocumentDtoWithMetaFromType } from '@store/documents/documents.state';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';
import { IntegratedIntegrationsActions } from '@store/documents/integrations/integrated.slice';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import { selectPathName } from '@store/router/router.selector';

import { getDashboardTypeFromRoute } from './utils/tracking.utils';

function* trackDetailPageViewed({ payload: { id } }: GetDocumentAction): any {
  const trackingService = yield getContext('trackingService');
  const pathname: string = yield select(selectPathName);
  const dashboardType = getDashboardTypeFromRoute(pathname);
  if (dashboardType) {
    const existingDocEntity: DocumentDtoWithMetaFromType<typeof dashboardType> | undefined =
      yield select(selectDocumentEntity, id, dashboardType);
    if (existingDocEntity) {
      const document = existingDocEntity?.resource;
      const pagesCount = yield select(selectDocumentPageCount, id);
      trackingService.trackDetailPageViewed(dashboardType, document, pagesCount);
    }
  }
}

export function* trackingPageSaga() {
  yield takeLatest(WorklistIntegrationsActions.getOne.type, trackDetailPageViewed);
  yield takeLatest(IntegratedIntegrationsActions.getOne.type, trackDetailPageViewed);
  yield takeLatest(WorklistIntegrationsActions.fetchOne.type, trackDetailPageViewed);
  yield takeLatest(IntegratedIntegrationsActions.fetchOne.type, trackDetailPageViewed);
  yield takeLatest(InboxDocumentsActions.getOne.type, trackDetailPageViewed);
  yield takeLatest(InboxDocumentsActions.fetchOne.type, trackDetailPageViewed);
}
