import { useEffect, useRef } from 'react';

type Callback = (...args: any) => void;

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<Callback | null>(null);

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval
  useEffect(() => {
    function tick() {
      if (savedCallback.current) savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
};
