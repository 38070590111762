import { downloadDashboardDocumentsSagas } from './common/sagas/downloadDashboardDocuments.sagas';
import { downloadDetailSagas } from './common/sagas/downloadDetail.sagas';
import { fetchDocumentAttachmentsSagas } from './common/sagas/fetchAttachments.sagas';
import { fetchDocumentsSagas } from './common/sagas/fetchDocuments.sagas';
import { restoreArchivedDocumentsSagas } from './incoming/sagas/archived.restore.sagas';
import { archiveInboxDocumentsSagas } from './incoming/sagas/inbox.archive.sagas';
import { forwardInboxDocumentsSagas } from './incoming/sagas/inbox.forward.sagas';
import { manageEnsInboxDocumentsSagas } from './incoming/sagas/inbox.manageEns.sagas';
import { cancelIntegrationRequestsSagas } from './integrations/sagas/worklist.cancel.sagas';
import { duplicateWorklistDocumentsSagas } from './integrations/sagas/worklist.duplicate.sagas';
import { encountersIntegrationRequestsSaga } from './integrations/sagas/worklist.encounters.sagas';
import { integrateIntegrationRequestsSagas } from './integrations/sagas/worklist.integrate.sagas';
import { patientsIntegrationRequestsSagas } from './integrations/sagas/worklist.patient.sagas';
import { updateIntegrationRequestSagas } from './integrations/sagas/worklist.update.sagas';
import { setDashboardSettingsSagas } from './outgoing/sagas/desktop.setDashboardSettings.sagas';
import { draftDocumentsCountersSagas } from './outgoing/sagas/draft.counters.sagas';
import { duplicateDraftDocumentsSagas } from './outgoing/sagas/draft.duplicate.sagas';
import { groupDraftDocumentsSagas } from './outgoing/sagas/draft.group.sagas';
import { integrationsDraftDocumentsSaga } from './outgoing/sagas/draft.integrations.sagas';
import { manageEnsDraftDocumentsSagas } from './outgoing/sagas/draft.manageEns.saga';
import { managePatientsDraftDocumentsSaga } from './outgoing/sagas/draft.managePatients.saga';
import { manageRecipientsDraftDocumentSagas } from './outgoing/sagas/draft.manageRecipients.sagas';
import { sendDraftDocumentsSagas } from './outgoing/sagas/draft.send.sagas';
import { splitDraftDocumentsSagas } from './outgoing/sagas/draft.split.sagas';
import { updatePropertiesDraftDocumentsSagas } from './outgoing/sagas/draft.updateProperties.sagas';
import { documentVerificationsSagas } from './outgoing/sagas/draft.verifications.sagas';
import { erroredDocumentsSagas } from './outgoing/sagas/errored.markAsReadUnread.sagas';
import { forwardSentDocumentsSagas } from './outgoing/sagas/sent.forward.sagas';
import { manageDmpSentDocumentsSagas } from './outgoing/sagas/sent.manageDmp.sagas';
import { manageMssSentDocumentsSagas } from './outgoing/sagas/sent.manageMss.sagas';

export const documentsSagas = [
  // Common
  downloadDashboardDocumentsSagas,
  downloadDetailSagas,
  fetchDocumentAttachmentsSagas,
  fetchDocumentsSagas,

  // Inbox Documents(s)
  archiveInboxDocumentsSagas,
  forwardInboxDocumentsSagas,
  manageEnsInboxDocumentsSagas,

  // Archived Document(s)
  restoreArchivedDocumentsSagas,

  // Draft Document(s)
  documentVerificationsSagas,
  duplicateDraftDocumentsSagas,
  groupDraftDocumentsSagas,
  integrationsDraftDocumentsSaga,
  manageEnsDraftDocumentsSagas,
  managePatientsDraftDocumentsSaga,
  manageRecipientsDraftDocumentSagas,
  draftDocumentsCountersSagas,
  sendDraftDocumentsSagas,
  splitDraftDocumentsSagas,
  updatePropertiesDraftDocumentsSagas,

  // Sent Document(s)
  forwardSentDocumentsSagas,
  manageDmpSentDocumentsSagas,
  manageMssSentDocumentsSagas,

  // Integrated & Worklist Integration Document(s)
  cancelIntegrationRequestsSagas,
  integrateIntegrationRequestsSagas,
  encountersIntegrationRequestsSaga,
  patientsIntegrationRequestsSagas,
  updateIntegrationRequestSagas,
  duplicateWorklistDocumentsSagas,

  // Errored Documents
  erroredDocumentsSagas,

  // Desktop
  setDashboardSettingsSagas,
];
