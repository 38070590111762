import { DocumentStatus } from '@honestica/core-apps-common/types';
import { isIntegration } from '@honestica/core-apps-common/validators';

import {
  FailureAction,
  FetchEhrPatientsSuccessAction,
  FetchEncountersSuccessAction,
  OneAction,
  OneFailureAction,
  SwitchEncounterSuccessAction,
  UpdateEhrPatientAction,
  UpdateEhrPatientSuccessAction,
} from '../../documents.actions';
import { XHR_STATUS_ERROR, XHR_STATUS_IDLE, XHR_STATUS_LOADING } from '../../documents.state';
import { DraftDocumentsState, INITIAL_DRAFT_DOCUMENTS_STATE } from '../draft.state';

export function onRefreshPatientMatch(state: DraftDocumentsState, action: OneAction) {
  return {
    ...state,
    entities: {
      ...state.entities,
      [action.payload.id]: {
        resource: {
          ...state.entities[action.payload.id].resource,
          status: DocumentStatus.Draft,
        },
        meta: {
          ...state.entities[action.payload.id].meta,
          refreshPatientMatch: { ...XHR_STATUS_LOADING, timeout: false },
        },
      },
    },
    detailView: {
      ...state.detailView,
      ...(state.detailView.documentDto && {
        documentDto: {
          ...state.detailView.documentDto,
          status: DocumentStatus.Draft,
        },
      }),
    },
  };
}

export const onFetchEhrPatients = (state: DraftDocumentsState): DraftDocumentsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    ehrPatientsRequest: XHR_STATUS_LOADING,
    ehrPatients: INITIAL_DRAFT_DOCUMENTS_STATE.detailView.ehrPatients,
  },
});

export const onFetchEhrPatientsFailure = (
  state: DraftDocumentsState,
  _action: FailureAction,
): DraftDocumentsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    ehrPatientsRequest: XHR_STATUS_ERROR,
    ehrPatients: INITIAL_DRAFT_DOCUMENTS_STATE.detailView.ehrPatients,
  },
});

export const onFetchEhrPatientsSuccess = (
  state: DraftDocumentsState,
  action: FetchEhrPatientsSuccessAction,
): DraftDocumentsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    ehrPatientsRequest: XHR_STATUS_IDLE,
    ehrPatients: {
      total: action.payload.ehrPatients.total,
      list: action.payload.ehrPatients.list,
    },
  },
});

export const onUpdateEhrPatient = (
  state: DraftDocumentsState,
  action: UpdateEhrPatientAction,
): DraftDocumentsState => ({
  ...state,
  entities: {
    ...state.entities,
    [action.payload.id]: {
      ...state.entities[action.payload.id],
      meta: {
        ...state.entities[action.payload.id].meta,
        updateEhrPatient: { ...XHR_STATUS_LOADING },
      },
    },
  },
});

export const onUpdateEhrPatientFailure = (
  state: DraftDocumentsState,
  action: OneFailureAction,
): DraftDocumentsState => ({
  ...state,
  entities: {
    ...state.entities,
    [action.payload.id]: {
      ...state.entities[action.payload.id],
      meta: {
        ...state.entities[action.payload.id].meta,
        updateEhrPatient: { ...XHR_STATUS_IDLE },
      },
    },
  },
});

export const onUpdateEhrPatientSuccess = (
  state: DraftDocumentsState,
  action: UpdateEhrPatientSuccessAction,
): DraftDocumentsState => {
  const { id, integration, ehrPatient } = action.payload;

  const entity = state.entities[id];
  const patient = entity.resource.patient && {
    ...entity.resource.patient,
    ...(ehrPatient && { ehrPatientId: ehrPatient.id }),
  };

  if (!ehrPatient) {
    delete patient?.ehrPatientId;
  }

  return {
    ...state,
    entities: {
      ...state.entities,
      [id]: {
        ...entity,
        resource: {
          ...entity.resource,
          integration,
          patient,
        },
        meta: {
          ...state.entities[action.payload.id].meta,
          updateEhrPatient: { ...XHR_STATUS_IDLE },
        },
      },
    },
  };
};

export const onFetchEncounters = (state: DraftDocumentsState): DraftDocumentsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    encountersRequest: XHR_STATUS_LOADING,
    encounters: INITIAL_DRAFT_DOCUMENTS_STATE.detailView.encounters,
  },
});

export const onFetchEncountersSuccess = (
  state: DraftDocumentsState,
  action: FetchEncountersSuccessAction,
): DraftDocumentsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    encountersRequest: XHR_STATUS_IDLE,
    encounters: {
      total: action.payload.encounters.total,
      list: action.payload.encounters.list,
    },
  },
});

export const onFetchEncountersFailure = (
  state: DraftDocumentsState,
  _action: FailureAction,
): DraftDocumentsState => ({
  ...state,
  detailView: {
    ...state.detailView,
    encountersRequest: XHR_STATUS_ERROR,
    encounters: INITIAL_DRAFT_DOCUMENTS_STATE.detailView.encounters,
  },
});

export const onSwitchEncounterSuccess = (
  state: DraftDocumentsState,
  action: SwitchEncounterSuccessAction,
): DraftDocumentsState => {
  const { id, encounter } = action.payload;

  const entity = state.entities[id];

  if (!entity || !isIntegration(entity.resource.integration)) {
    return state;
  }

  return {
    ...state,
    entities: {
      ...state.entities,
      [id]: {
        ...entity,
        resource: {
          ...entity.resource,
          integration: {
            ...entity.resource.integration,
            encounter,
          },
        },
      },
    },
  };
};
