import { MouseEventHandler, useRef, useState } from 'react';

import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';
import { Dropdown } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useOutsideClick } from '../../../hooks/useOutsideClick.hook';
import Button from '../../Button';
import Icon from '../../Icon';

import { TextInputForm } from './TextInputForm.component';

import styles from './TextInputFilter.module.less';

interface TextInputFilterProps {
  value: string | undefined;
  title: string;
  disabled?: boolean;
  placeholder: string;
  onSubmit: (value: string) => void;
  onReset: () => void;
}

export const TextInputFilter = ({
  value,
  title,
  placeholder,
  disabled,
  onSubmit,
  onReset,
}: TextInputFilterProps) => {
  const { t } = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const formWrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClick(formWrapperRef, () => {
    setDropdownOpen(false);
  });

  const onSearch = (input: string) => {
    if (!input || input.length < 1) {
      onReset();
    } else {
      onSubmit(input);
    }

    setDropdownOpen(false);
  };

  const onClickFilterButton = () => {
    if (dropdownOpen) {
      return;
    }
    setDropdownOpen((state) => !state);
  };

  const onResetFilter: MouseEventHandler = (e) => {
    e.stopPropagation();
    onReset();
  };

  const buttonContent = value || title;

  return (
    <Dropdown
      className={styles.wrapper}
      overlay={
        <div ref={formWrapperRef}>
          <TextInputForm defaultValue={value} onSearch={onSearch} placeholder={placeholder} />
        </div>
      }
      trigger={['click']}
      open={dropdownOpen}
      disabled={disabled}
      destroyPopupOnHide
    >
      <Button
        className={cx({ [styles.filledButton]: value }, styles.button)}
        onClick={onClickFilterButton}
      >
        {buttonContent}
        {value ? (
          <Icon
            name="Cancel"
            className={styles.cancelIcon}
            size={16}
            title={t('resetFilter')}
            data-testid="cancel-icon"
            onClick={onResetFilter}
          />
        ) : (
          <CaretDownOutlined />
        )}
      </Button>
    </Dropdown>
  );
};
