import { getAllNestedWatchPaths } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';

import { EnsureDirCallbacks } from '..';

const callbacks: EnsureDirCallbacks = {
  onFailedToCreateDir: (path, err) =>
    logger.error('DESKTOP', `Failed to create ${path} folder`, { error: err }),
  onDirAlreadyExists: (path) => logger.info('DESKTOP', `${path} folder already exists!`),
  onStartCreatingDir: (path: string) => logger.info('DESKTOP', `Creating ${path} folder`),
  onPathIsNotDir: (path, result) =>
    logger.error('DESKTOP', `${path} already exists, but it is not a folder`, {
      result,
    }),
};

export async function ensureWatchPathFolders(watchPaths: string[]): Promise<void> {
  logger.info('DESKTOP', `Creating ${watchPaths.length} WatchPath folders...`);

  try {
    const foldersToCreate = getAllNestedWatchPaths(watchPaths);

    await Promise.all(foldersToCreate.map((path) => window.electron.ensureDir(path, callbacks)));
  } catch (error) {
    logger.error('DESKTOP', `Cannot set up WatchPath folders`, error);
  }
}
