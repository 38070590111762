import { Professional, ProfessionalBase, ProfessionalDto } from '../types';

export function professionalToProfessionalDto({
  id,
  type,
  practRolePractitionerReference,
}: Professional | ProfessionalBase): ProfessionalDto {
  return {
    id,
    type,
    ...(practRolePractitionerReference ? { practRolePractitionerReference } : {}),
  };
}
