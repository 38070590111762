import { useCallback, useMemo } from 'react';

import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';
import { MenuProps } from 'antd';

import Button from '../Button';
import { Dropdown, DropdownMenuItemProps, DropdownMenuOptions } from '../Dropdown';
import Typography from '../Typography';

import { Filter, FilterProps } from './Filter.component';

import styles from './DropdownFilter.module.less';

interface DropdownFilterProps {
  selectedKeys: string[];
  defaultKey: string;
  disabled?: boolean;
  label: string;
  options: DropdownMenuOptions;
  testid?: string;
}

export const DropdownFilter = (
  props: Pick<FilterProps<string>, 'onResetFilter'> & DropdownFilterProps,
) => {
  const isDefaultValueSelected = useMemo(
    () => props.selectedKeys.includes(props.defaultKey),
    [props.selectedKeys, props.defaultKey],
  );

  const menuOptions = useMemo(
    () =>
      props.options.map(
        ({ title, icon, displayTitle = true, dataTestId, ...rest }: DropdownMenuItemProps) => ({
          ...rest,
          children: (
            <div data-testid={dataTestId} className={styles.option}>
              {icon}
              {displayTitle && <Typography.Text className={styles.title}>{title}</Typography.Text>}
            </div>
          ),
        }),
      ),
    [props.options],
  );

  const selectedValue = useCallback(() => {
    const optionSelected: DropdownMenuItemProps | undefined = props.options.find((option) =>
      props.selectedKeys.includes(option.key),
    );
    return optionSelected?.title ?? '';
  }, [props.options, props.selectedKeys]);

  const filter = () => (
    <Dropdown
      disabled={props.disabled}
      menuProps={{ 'data-testid': `${props.testid}-menu` } as MenuProps}
      options={menuOptions}
      selectedItemKeys={props.selectedKeys}
    >
      <Button data-testid={props.testid}>
        {props.label}
        <CaretDownOutlined />
      </Button>
    </Dropdown>
  );

  return (
    <Filter
      isDefaultValueSelected={isDefaultValueSelected}
      onResetFilter={props.onResetFilter}
      renderFilter={filter}
      selectedValue={selectedValue}
    />
  );
};
