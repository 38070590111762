import {
  fileToBuffer,
  getFileInMemory,
  removeFilesInMemory,
  setFileInMemory,
} from '@launcher/utils';
import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { all, getContext, put, select, takeLatest } from 'redux-saga/effects';

import {
  DocumentJobError,
  SendingRequestBundle,
  SendingRequestDto,
  UserSettings,
} from '@honestica/core-apps-common/types';
import { isIntegration } from '@honestica/core-apps-common/validators';

import { cancelDmpDocument, replaceDmpDocument } from '@api';
import { SENT } from '@constants/documents.constants';
import {
  RemoveDmpSentDocumentAction,
  ReplaceDmpAction,
  ReplaceDmpFailureAction,
  ReplaceDmpSuccessAction,
  StoreLocallyToReplaceDmpAction,
} from '@store/documents/documents.actions';
import { SentDocumentsActions } from '@store/documents/outgoing/sent.slice';
import { openModalFromStore } from '@store/modal/modal.action';
import { ModalType } from '@store/modal/modal.type';
import { notificationAddError } from '@store/notificationWindow/notificationWindow.action';
import { selectUserSettings } from '@store/user/user.selector';
import { updateDocumentInCache } from '@utils/caching.util';
import { checkFileForErrors } from '@utils/checkFile.util';

import { selectDocumentDto } from '../sent.selector';

function* removeDmpSaga({
  payload: { id, integrationId, connectorId },
}: RemoveDmpSentDocumentAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  try {
    const bundle: SendingRequestBundle = yield cancelDmpDocument(id, integrationId, connectorId);
    yield put(SentDocumentsActions.removeDmpSuccess({ bundle, id }));

    const docDto: SendingRequestDto = yield select(selectDocumentDto);

    if (!isIntegration(docDto.integration)) {
      return;
    }

    const updatedDocument = {
      ...docDto,
      integration: {
        ...docDto.integration,
        connectors: {
          ...docDto.integration?.connectors,
          ...(bundle.document.integration?.connectors.DMP &&
            docDto.integration?.connectors.DMP && {
              DMP: {
                ...docDto.integration?.connectors.DMP,
                ...(bundle.document.integration?.connectors.DMP.wasReplacedOrCanceled && {
                  wasReplacedOrCanceled: {
                    ...bundle.document.integration?.connectors.DMP?.wasReplacedOrCanceled,
                  },
                }),
              },
            }),
        },
      },
    };

    yield updateDocumentInCache({
      updatedDocument,
      dashboardType: SENT,
      queryClient,
    });
    yield put(SentDocumentsActions.setDetailViewDocumentDto(updatedDocument));
  } catch (error) {
    logger.error(
      'LOGIC',
      `Failed to cancel dmp connector ${connectorId} in integration ${integrationId} for document ${id}`,
      error,
    );
    yield put(
      SentDocumentsActions.removeDmpFailure({
        id,
        error,
      }),
    );
  }
}

function* storeLocallyToReplaceDmpSaga({ payload: { id, file } }: StoreLocallyToReplaceDmpAction) {
  try {
    const fileStorageId = setFileInMemory(file);
    const fileFromStorage = getFileInMemory(fileStorageId);
    const fileName = fileFromStorage?.name;
    if (!fileName) {
      throw new Error();
    }
    yield put(
      SentDocumentsActions.storeLocallyToReplaceDmpSuccess({ id, fileStorageId, fileName }),
    );
    yield put(openModalFromStore({ modal: ModalType.dmpSentSettings }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to upload a file in storage to replace DMP document', error);
    yield put(SentDocumentsActions.storeLocallyToReplaceDmpFailure());
  }
}

function* replaceDmpSaga({
  payload: { id, integrationId, connectorId, settings, fileStorageId },
}: ReplaceDmpAction) {
  const uploadSource = 'button';
  const { documentTitle: fileName, visibility } = settings;

  try {
    const file = getFileInMemory(fileStorageId);
    if (!file) {
      yield put(
        SentDocumentsActions.replaceDmpFailure({
          id,
          uploadSource,
          fileStorageId,
          fileName,
          errors: [DocumentJobError.Default],
        }),
      );
      return;
    }
    const fileBuffer: Buffer = yield fileToBuffer(file);
    const errors: DocumentJobError[] = yield checkFileForErrors(fileBuffer, fileName);
    if (errors.length) {
      yield put(
        SentDocumentsActions.replaceDmpFailure({
          id,
          uploadSource,
          fileStorageId,
          fileName,
          errors,
        }),
      );
      return;
    }
    const userSettings: UserSettings = yield select(selectUserSettings);

    const bundle: SendingRequestBundle = yield replaceDmpDocument(
      id,
      integrationId,
      connectorId,
      file,
      settings,
      userSettings,
    );
    yield put(SentDocumentsActions.replaceDmpSuccess({ id, bundle, fileStorageId, uploadSource }));
  } catch (error) {
    logger.error(
      'LOGIC',
      `Failed to replace dmp connector ${connectorId} in integration ${integrationId} for document ${id} with file name ${fileName} and visibility ${visibility}`,
      error,
    );
    yield put(
      SentDocumentsActions.replaceDmpFailure({
        id,
        uploadSource,
        fileStorageId,
        fileName,
        errors: [DocumentJobError.UploadFailed],
      }),
    );
  }
}

function replaceDmpSuccessSaga({ payload: { fileStorageId } }: ReplaceDmpSuccessAction) {
  removeFilesInMemory([fileStorageId]);
}

function* replaceDmpFailureSaga({ payload }: ReplaceDmpFailureAction) {
  const { fileName, fileStorageId, uploadSource, errors } = payload;
  yield put(
    notificationAddError({
      id: '',
      errors,
      fileName,
      uploadSource,
      flowType: 'sending',
    }),
  );
  removeFilesInMemory([fileStorageId]);
}

export function* manageDmpSentDocumentsSagas() {
  yield all([
    takeLatest(SentDocumentsActions.removeDmp.type, removeDmpSaga),
    takeLatest(SentDocumentsActions.storeLocallyToReplaceDmp.type, storeLocallyToReplaceDmpSaga),
    takeLatest(SentDocumentsActions.replaceDmp.type, replaceDmpSaga),
    takeLatest(SentDocumentsActions.replaceDmpSuccess.type, replaceDmpSuccessSaga),
    takeLatest(SentDocumentsActions.replaceDmpFailure.type, replaceDmpFailureSaga),
  ]);
}
