export const IS_DEV = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

export enum BinaryContentType {
  EML_CONTENT_TYPE = 'application/message/rfc822',
  HTML_CONTENT_TYPE = 'application/html',
  PDF_CONTENT_TYPE = 'application/pdf',
  HPRIM_CONTENT_TYPE = 'application/x-hprimnet',
}
export const MAX_FILE_SIZE = 15;
export const PDF_PAGES_LIMIT = 40;
export const MAX_FILE_WIDTH = 598; // A4 ie. 21cm
export const MAX_FILE_HEIGHT = 844; // A4 ie. 29,7cm

// Lifen Integrations Constants
export const PDF_PAGES_LIMIT_INTEGRATIONS = 100;
export const OFFSET_INTEGRATIONS_FILES = 10 / 100;
export const MAX_FILE_WIDTH_INTEGRATION = 1190; // 42cm
export const MAX_FILE_HEIGHT_INTEGRATION = 1190; // 42cm
