import { Connectors } from './connector.types';
import { Encounter } from './encounter.types';
import { Organization } from './organization';
import { Patient } from './patient.types';

export enum IntegrationStatus {
  NotSelected = 'not-selected',
  IntegrationSelected = 'integration-selected',
  Error = 'error',
}

export enum PatientMatchStatus {
  Found = 'found',
  NotFound = 'not-found',
}

export enum PatientMatchError {
  TooManyPatient = 'too-many-patient',
  MissingInfo = 'missing-info',
  NoPatient = 'no-patient',
  NoEncounter = 'no-encounter',
  RecoveryMode = 'recoveryMode',
  Default = 'default',
}

export interface Integration {
  id: Organization['id'];
  name: string;
  encounter: Encounter | undefined;
  connectors: Partial<Connectors>;
}

export interface EhrPatientIntegration {
  id: Organization['id'];
  name: string;
  ehrPatient: Patient | undefined;
  failureReason?: PatientMatchError;
}
