import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { NavigateFunction } from 'react-router-dom';
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import {
  DashboardSearchParams,
  DocumentStatus,
  DocumentVerificationStatus,
  FeatureFlags,
  SendingRequestDto,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import { duplicateDocument, duplicateDocuments } from '@api';
import { DRAFT, DRAFT_DETAIL_VIEW_PATH, DRAFT_PATH } from '@constants/documents.constants';
import {
  selectDashboardSearchParams,
  selectManyDocumentsFileStorageIds,
} from '@store/documents/documents.selector';
import { selectDraftCurrentIdentityReference } from '@store/documents/outgoing/draft.selector';
import { removeFromSelection, removeManyFromSelection } from '@store/selection/selection.action';
import { getFeatureFlags } from '@store/user/user.selector';
import {
  refreshDraftCacheAfterActionOnManyDocuments,
  refreshDraftCacheAfterActionOnOneDocument,
} from '@utils/caching.util';
import {
  identityReferenceToUrlIdentity,
  professionalToIdentityReference,
} from '@utils/identities.util';

import { BatchAction, OneAction } from '../../documents.actions';
import { DraftDocumentsActions } from '../draft.slice';

function* duplicateOneDocumentSaga({ payload: { id } }: OneAction) {
  const featureFlags: FeatureFlags = yield select(getFeatureFlags);
  const navigate: NavigateFunction = yield getContext('customNavigate');
  const queryClient: QueryClient = yield getContext('queryClient');

  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, [id], DRAFT);
  const currentIdentity: string | undefined = yield select((state) =>
    selectDraftCurrentIdentityReference(state),
  );
  const searchParams: DashboardSearchParams = yield select((state) =>
    selectDashboardSearchParams(state, DRAFT),
  );
  const path = currentIdentity
    ? `${DRAFT_PATH}/${identityReferenceToUrlIdentity(currentIdentity)}/detail`
    : DRAFT_DETAIL_VIEW_PATH;
  try {
    const document: SendingRequestDto = yield duplicateDocument(id);
    yield put(DraftDocumentsActions.duplicateOneSuccess({ id, fileStorageIds }));
    const { identity } = refreshDraftCacheAfterActionOnOneDocument({
      documentId: id,
      currentIdentity,
      search: { searchParams, featureFlags },
      queryClient,
    });
    yield put(DraftDocumentsActions.incrementDraftCounter({ identity, count: 1 }));
    if (
      document.status === DocumentStatus.Suspended &&
      document.verificationStatus === DocumentVerificationStatus.ReadyToSend
    ) {
      yield put(DraftDocumentsActions.incrementReadyToSendCounter({ identity, count: 1 }));
    }

    yield put(removeFromSelection({ dashboard: SendingsDashboardType.Draft, id }));
    navigate(`${path}/${document.id}`);
  } catch (error) {
    logger.error('LOGIC', `Failed to duplicate document n°${id}`, error);
    yield put(
      DraftDocumentsActions.duplicateOneFailure({
        id,
        error,
      }),
    );
  }
}

function* duplicateManyDocumentsSaga({ payload: { ids } }: BatchAction) {
  const queryClient: QueryClient = yield getContext('queryClient');
  const featureFlags: FeatureFlags = yield select(getFeatureFlags);
  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, ids, DRAFT);
  const currentIdentity: string | undefined = yield select((state) =>
    selectDraftCurrentIdentityReference(state),
  );
  try {
    yield put(DraftDocumentsActions.updateLoadingDocumentIds({ ids }));
    yield call(duplicateDocuments, ids);
    yield put(removeManyFromSelection({ dashboard: SendingsDashboardType.Draft, ids }));
    yield put(DraftDocumentsActions.duplicateManySuccess({ ids, fileStorageIds }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to duplicate documents', error);
    yield put(
      DraftDocumentsActions.duplicateManyFailure({
        ids,
        error,
      }),
    );
  } finally {
    const searchParams: DashboardSearchParams = yield select((state) =>
      selectDashboardSearchParams(state, DRAFT),
    );

    const { documentsInCache } = refreshDraftCacheAfterActionOnManyDocuments({
      documentIds: ids,
      currentIdentity,
      search: { searchParams, featureFlags },
      queryClient,
    });

    // Increment draft counter for each document's identity
    for (const documentInCache of documentsInCache) {
      const documentInCacheIdentity = professionalToIdentityReference(documentInCache.sender);
      yield put(
        DraftDocumentsActions.incrementDraftCounter({
          identity: documentInCacheIdentity,
          count: 1,
        }),
      );
      if (
        documentInCache.status === DocumentStatus.Suspended &&
        documentInCache.verificationStatus === DocumentVerificationStatus.ReadyToSend
      ) {
        yield put(
          DraftDocumentsActions.incrementReadyToSendCounter({
            identity: documentInCacheIdentity,
            count: 1,
          }),
        );
      }
    }
    yield put(DraftDocumentsActions.flushLoadingDocumentIds());
  }
}

export function* duplicateDraftDocumentsSagas() {
  yield all([
    takeLatest(DraftDocumentsActions.duplicateOne.type, duplicateOneDocumentSaga),
    takeLatest(DraftDocumentsActions.duplicateMany.type, duplicateManyDocumentsSaga),
  ]);
}
