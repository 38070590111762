import { delay, put, takeEvery } from 'redux-saga/effects';

import { infoMessageToCancelledMessage } from '../helpers/infoMessageToCancelled.helper';
import {
  CancelInfoMessageAction,
  InfoMessageActions,
  SUCCESS_ACTIONS_MESSAGE_VISIBLE_FOR_MS,
} from '../infoMessages.slice';

function* saga(action: CancelInfoMessageAction) {
  const type = infoMessageToCancelledMessage[action.payload.type];

  if (type) {
    yield put(InfoMessageActions.addOne({ type, id: action.payload.id }));
    yield delay(SUCCESS_ACTIONS_MESSAGE_VISIBLE_FOR_MS);
    yield put(InfoMessageActions.removeOne({ type, id: action.payload.id }));
  }
}

export function* addInfoMessageOnCancelledSagas() {
  yield takeEvery(InfoMessageActions.cancelOne.type, saga);
}
