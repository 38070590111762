import { ConnectedProps, connect } from 'react-redux';

import { removeGenericErrorMessage, removeMutationErrorMessage } from '@store/errors/errors.action';
import { selectAreErrorMessagesHidden, selectErrorsState } from '@store/errors/errors.selector';
import { State } from '@store/reducer';

export const errorMessagesConnector = connect(
  (state: State) => ({
    ...selectErrorsState(state),
    hide: selectAreErrorMessagesHidden(),
  }),
  {
    remove: removeMutationErrorMessage,
    removeGeneric: removeGenericErrorMessage,
  },
);

export type ErrorMessagesReduxProps = ConnectedProps<typeof errorMessagesConnector>;
