import { forwardRef } from 'react';

import { DatePicker } from 'antd';
import { PickerProps } from 'antd/es/date-picker/generatePicker';

const TimePicker = forwardRef<any, PickerProps>((props, ref) => (
  <DatePicker {...props} picker="time" mode={undefined} ref={ref} />
));

TimePicker.displayName = 'TimePicker';

export default TimePicker;
