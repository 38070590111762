import { UserSettings } from '../auth/user.type';

import { Attachment } from './attachment';
import { DocumentSource } from './documentSource.types';
import { MedicalExamType } from './medicalExamType.types';
import { IOriginalSender, Organization } from './organization';
import { Patient } from './patient.types';
import { Practitioner } from './practitioner';
import { Professional, ProfessionalDto } from './professional';
import {
  DocumentBase,
  DocumentPatient,
  DocumentPatientDto,
  FlowType,
} from './sendingRequest.types';
import { ISigningPractitioner } from './signingPractitioner.types';

interface IntegrationRequestBase extends DocumentBase {
  uuid?: string;
  documentType?: string;
  medicalExamType?: MedicalExamType;
  originalSenderOrganization?: IOriginalSender;
}
export interface IntegrationRequestDto extends IntegrationRequestBase {
  sender: ProfessionalDto;
  patient?: DocumentPatientDto;
  originalSenderPractitioner?: ISigningPractitioner;
  source?: DocumentSource;
}

export interface IntegrationRequest extends IntegrationRequestBase {
  sender: Professional;
  patient?: DocumentPatient;
  attachments?: Attachment[];
  originalSenderPractitioner?: ISigningPractitioner;
  source?: DocumentSource;
}

export enum IntegrationsDashboardType {
  Worklist = 'worklist',
  Integrated = 'integrated',
}

export const isIntegrationsDashboardType = (val: unknown): val is IntegrationsDashboardType =>
  typeof val === 'string' &&
  Object.values(IntegrationsDashboardType).includes(val as IntegrationsDashboardType);

export interface IntegrationRequestBundle {
  document: IntegrationRequestDto;
  attachments?: Attachment[]; // TODO: delete
  entities: {
    patients: Patient[];
    organizations: Organization[];
    practitioners: Practitioner[];
    attachments?: Attachment[];
  };
}

export interface CreateIntegrationRequest {
  requesterId: string;
  sender: ProfessionalDto;
  file: File;
  flowType: FlowType;
  userSettings: UserSettings;
}
