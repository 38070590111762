import { isDesktopApp } from '@launcher/utils';
import { EnvManager } from '@services/environment/environment.service';
import { monitoring } from '@services/monitoring';

import { ClientLog, Log, LogEnvironment, LogLevel } from '@honestica/core-apps-common/types';

import { version } from '../../../package.json';

type EnqueueLog = Pick<ClientLog, 'source' | 'message' | 'level'>;
type QueueLog = EnqueueLog & Pick<ClientLog, '@timestamp'>;

interface LoggerOptions {
  environment: LogEnvironment;
  version: Log['version'];
  beforeEnqueue(log: EnqueueLog): void;
}

type OptionsKey = Record<string, unknown>;

export const sessionId = Math.random().toString(36).substring(2, 15);

export class Logger {
  private get maxRetry() {
    return 3;
  }

  private get flushInterval() {
    return 30000;
  }

  private consoleEnabled = false;

  private queue: QueueLog[] = [];

  private initialized = false;

  private userId: ClientLog['userId'] = '';

  private token = '';

  private desktopLogger: any;

  private desktopLoggerInit = false;

  private disabled = false;

  private retryAttempt = 0;

  get url(): string {
    return `${EnvManager.getBaseUrlBackend() ?? ''}/apps/api/v1/logs`;
  }

  constructor(private readonly options: LoggerOptions) {
    this.shouldEnableFeatures(this.options.environment);
    this.startFlush();
  }

  private startFlush() {
    setInterval(() => this.flush(), this.flushInterval);
  }

  private shouldEnableFeatures(environment: LogEnvironment) {
    this.consoleEnabled =
      environment !== LogEnvironment.PRODUCTION && process.env.NODE_ENV !== 'test';
  }

  private get canSend(): boolean {
    return this.initialized && !this.disabled && this.queue.length > 0 && !EnvManager.isE2e();
  }

  private getRequestInit(logs: Log[]): RequestInit {
    return {
      headers: {
        Authorization: this.token,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(logs),
    };
  }

  private formatOptions(options: OptionsKey) {
    const formatedOptions: Record<string, string | number | boolean> = {};
    for (const [key, value] of Object.entries(options)) {
      formatedOptions[key] =
        typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean'
          ? value
          : JSON.stringify(value);
    }

    return formatedOptions;
  }

  private async send(logs: Log[]): Promise<void> {
    try {
      const response = await fetch(this.url, this.getRequestInit(logs));

      if (!response.ok) {
        this.retry(response);
        return;
      }

      this.queue = [];
      this.retryAttempt = 0;
    } catch (err) {
      console.error(`Logger throw an error when sending logs`, err);
    }
  }

  private retry(response: Response) {
    // After 3 http 401 response from server, disable logger
    if (response.status === 401) {
      this.retryAttempt += 1;

      if (this.retryAttempt >= this.maxRetry) {
        this.disabled = true;
      }
    }
  }

  private async writeToFile(log: EnqueueLog): Promise<void> {
    try {
      this.desktopLogger[log.level](log.message, JSON.stringify(log, null, '  '));
    } catch (err) {
      console.error(err);
    }
  }

  private format(): ClientLog[] {
    return this.queue.map((log) => ({
      ...log,
      appname: 'core-apps-client',
      environment: this.options.environment,
      version: this.options.version,
      host: window.location.host,
      path: window.location.pathname,
      userId: this.userId,
      sessionId,
    }));
  }

  private addTimeStamp(log: EnqueueLog) {
    const now = new Date();
    return {
      ...log,
      '@timestamp': now.toISOString(),
    };
  }

  public flush(): void {
    if (this.canSend) {
      this.send(this.format());
    }
  }

  private enqueue(log: EnqueueLog): void {
    this.queue.push(this.addTimeStamp(log));
  }

  private log(log: EnqueueLog): void {
    if (this.disabled) {
      console.warn(
        `Logger is disabled after ${this.maxRetry} failed attempt to send datas with 401 status.`,
      );
      return;
    }

    this.options.beforeEnqueue(log);
    this.enqueue(log);

    if (this.consoleEnabled) {
      // eslint-disable-next-line no-console
      console[log.level](log.message, JSON.stringify(log, null, '  '));
    }

    if (isDesktopApp() && this.desktopLoggerInit) {
      this.writeToFile(log);
    }
  }

  public init(userId: ClientLog['userId'], token: string): void {
    this.userId = userId;
    this.token = `Bearer ${token}`;
    this.initialized = true;
  }

  public initDesktopLogger(): void {
    if (isDesktopApp() && window.electron.getLogger) {
      this.desktopLogger = window.electron.getLogger();
      this.desktopLogger.transports.console.level = false; // disable console to avoid duplicate
      this.desktopLogger.transports.file.resolvePath = () =>
        window.electron.joinPaths(window.electron.getPath('userData'), 'Logs', 'lifen_remote.log');

      this.desktopLoggerInit = true;
    }
  }

  public debug(
    source: ClientLog['source'],
    message: ClientLog['message'],
    options: OptionsKey = {},
  ): void {
    this.log({ source, message, level: LogLevel.DEBUG, ...this.formatOptions(options) });
  }

  public info(
    source: ClientLog['source'],
    message: ClientLog['message'],
    options: OptionsKey = {},
  ): void {
    this.log({ source, message, level: LogLevel.INFO, ...this.formatOptions(options) });
  }

  public warn(
    source: ClientLog['source'],
    message: ClientLog['message'],
    options: OptionsKey = {},
  ): void {
    this.log({ source, message, level: LogLevel.WARN, ...this.formatOptions(options) });
  }

  public error(
    source: ClientLog['source'],
    message: ClientLog['message'],
    error: any = {},
    capture = true,
  ): void {
    this.log({
      source,
      message,
      level: LogLevel.ERROR,
      ...{
        error: error instanceof Error ? error?.message : JSON.stringify(error as any),
      },
    });

    // Send errors to Sentry
    if (capture) {
      if (error instanceof Error || Object.keys(error).length !== 0) {
        // Capture the inial error as a linked error to better group them https://docs.sentry.io/platforms/javascript/configuration/integrations/linkederrors/
        const sentryError = new Error(message);
        sentryError.cause = error;
        monitoring.addError(sentryError);
      } else {
        monitoring.addError(message);
      }
    }
  }
}

export const logger = new Logger({
  environment: EnvManager.getName() as unknown as LogEnvironment,
  version,
  beforeEnqueue: (log: EnqueueLog) => {
    monitoring.addEvent(log.source, log.message, log.level);
  },
});
