import { ROOT_WATCHPATH, SENDING_WATCHPATH } from '@launcher/constants';
import { DesktopSettings, defaultDesktopSettings } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';

import { filterSettingsWriteableToFS } from './filterSettingsWriteableToFS.helper';

export async function resetFileSettings() {
  try {
    logger.info('DESKTOP', 'Reset FS settings');
    const dirPath = window.electron.getPath('userData');
    await window.electron.ensureDir(dirPath);

    const homedir = window.electron.getHomedir();
    const watchPath = window.electron.joinPaths(homedir, ROOT_WATCHPATH, SENDING_WATCHPATH);

    const settings: DesktopSettings = {
      ...(filterSettingsWriteableToFS(defaultDesktopSettings) as DesktopSettings),
      watchPath,
    };

    await window.electron.writeLocalSettings(settings);

    return settings;
  } catch (error) {
    logger.error('DESKTOP', 'Could not reset file settings', { error });
  }

  return undefined;
}
