import { ComponentProps, FC } from 'react';

import { Space as AntSpace } from 'antd';
import cx from 'classnames';

import styles from './Space.module.less';

interface SpaceProps extends ComponentProps<typeof AntSpace> {
  fullWidth?: boolean;
  fullHeight?: boolean;
  justify?: 'start' | 'end' | 'center';
}

const Space: FC<SpaceProps> = ({
  children,
  fullWidth = false,
  fullHeight = false,
  justify,
  className,
  ...otherProps
}) => (
  <AntSpace
    className={cx({ [styles.fullWidth]: fullWidth, [styles.fullHeight]: fullHeight }, className)}
    style={justify ? { justifyContent: justify } : {}}
    {...otherProps}
  >
    {children}
  </AntSpace>
);

export default Space;
