import { ChangeEvent, ForwardedRef, forwardRef } from 'react';

import Input, { InputProps, InputRef } from 'antd/lib/input';
import { useDebouncedCallback } from 'use-debounce';

import colors from '../../../theme/colors';
import Icon from '../../Icon';

import styles from './SearchInput.module.less';

export interface SearchInputProps {
  callback: (value: string) => void;
  minSearchTextLength?: number;
}

export const SearchInput = forwardRef(
  (
    {
      defaultValue,
      callback,
      placeholder,
      style,
      minSearchTextLength = 1,
      ...others
    }: SearchInputProps & InputProps,
    ref: ForwardedRef<InputRef>,
  ): JSX.Element => {
    const debouncedCallback = useDebouncedCallback((value: string) => {
      if (value === '' || value.length >= minSearchTextLength) {
        callback(value);
      }
    }, 500);

    return (
      <Input
        style={style}
        size="middle"
        defaultValue={defaultValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => debouncedCallback(e.target.value)}
        placeholder={placeholder}
        allowClear
        prefix={
          <Icon
            size={16}
            color={colors.grey[5]}
            className={styles.centeredSearchIcon}
            name="Search"
          />
        }
        ref={ref}
        {...others}
      />
    );
  },
);
