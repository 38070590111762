import {
  IntegrationRequest,
  IntegrationRequestDto,
  IntegrationsDashboardType,
} from '@honestica/core-apps-common/types';

import { INTEGRATED } from '@constants/documents.constants';
import { XhrStatus } from '@store/types';

import {
  BaseDashboardViewState,
  BaseDetailViewState,
  BaseEntitiesState,
  INITIAL_DASHBOARD_VIEW_STATE,
  INITIAL_DETAIL_VIEW_STATE,
  XHR_STATUS_IDLE,
} from '../documents.state';

export type IntegratedDetailViewState = BaseDetailViewState;
export interface IntegratedIntegrationsState {
  entities: BaseEntitiesState<IntegratedIntegrationRequestDtoWithMeta>;
  dashboardView: BaseDashboardViewState;
  detailView: BaseDetailViewState;
}

export const INITIAL_INTEGRATED_INTEGRATIONS_STATE: IntegratedIntegrationsState = {
  entities: {},
  dashboardView: {
    ...INITIAL_DASHBOARD_VIEW_STATE,
    searchParams: { ...INITIAL_DASHBOARD_VIEW_STATE.searchParams, defaultDateRange: true },
  },
  detailView: INITIAL_DETAIL_VIEW_STATE,
};

export interface IntegratedIntegrationRequestDtoWithMeta {
  resource: IntegrationRequestDto;
  meta: IntegratedIntegrationRequestMetadata;
}

export interface IntegratedIntegrationRequestWithMeta {
  resource: IntegrationRequest;
  meta: IntegratedIntegrationRequestMetadata;
}

export enum IntegratedIntegrationRequestMetadataKey {
  fetchAttachmentsRequest = 'fetchAttachmentsRequest',
  fileStorageIds = 'fileStorageIds',
  docDashboardType = 'docDashboardType',
}

export interface IntegratedIntegrationRequestMetadata {
  [IntegratedIntegrationRequestMetadataKey.fetchAttachmentsRequest]: XhrStatus;
  [IntegratedIntegrationRequestMetadataKey.fileStorageIds]: string[] | undefined;
  [IntegratedIntegrationRequestMetadataKey.docDashboardType]: IntegrationsDashboardType.Integrated;
}

export type IntegratedIntegrationRequestMetadataType = keyof IntegratedIntegrationRequestMetadata;

export const INITIAL_INTEGRATED_INTEGRATION_DOCUMENT_METADATA: IntegratedIntegrationRequestMetadata =
  {
    [IntegratedIntegrationRequestMetadataKey.fetchAttachmentsRequest]: XHR_STATUS_IDLE,
    [IntegratedIntegrationRequestMetadataKey.fileStorageIds]: undefined,
    [IntegratedIntegrationRequestMetadataKey.docDashboardType]: INTEGRATED,
  };
