import { Component, ComponentProps, ReactElement } from 'react';

import { v4 as uuid } from 'uuid';

export interface SvgProps extends ComponentProps<'svg'> {
  title?: string;
  desc?: string;
  children?: ReactElement<Component<'svg'>>;
}

export const Svg = (props: SvgProps) => {
  const { children, viewBox, title, desc, fill, ...otherProps } = props;

  const titleId = uuid();
  const descId = uuid();

  return (
    <svg
      {...otherProps}
      viewBox={viewBox ?? '0 0 24 24'}
      fill={fill ?? 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={`${titleId} ${descId}`}
    >
      {title && <title id={titleId}>{title}</title>}
      {desc && <desc id={descId}>{desc}</desc>}
      {children}
    </svg>
  );
};
