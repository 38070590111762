import { logger } from '@services/logger/logger.service';
import { put, select, takeLatest } from 'redux-saga/effects';

import { CounterDraft, CounterReadyToSend } from '@honestica/core-apps-common/types';

import { fetchTotalDraftCounter, fetchTotalReadyToSend } from '@api';
import { selectReadyToSendAutomaticSending } from '@store/documents/documents.selector';

import { DraftDocumentsActions } from '../draft.slice';

function* getTotalReadyToSendSaga() {
  const hasReadyToSendAutomaticSending: boolean = yield select(selectReadyToSendAutomaticSending);
  if (hasReadyToSendAutomaticSending) {
    try {
      const result: CounterReadyToSend = yield fetchTotalReadyToSend();
      yield put(DraftDocumentsActions.fetchTotalReadyToSendSuccess(result));
    } catch (error) {
      logger.error('LOGIC', 'Failed to get total ready to send counter', error);
      yield put(DraftDocumentsActions.fetchTotalReadyToSendFailure({ error }));
    }
  }
}

function* getTotalDraftSaga() {
  try {
    const result: CounterDraft = yield fetchTotalDraftCounter();
    yield put(DraftDocumentsActions.fetchDraftCounterSuccess(result));
  } catch (error) {
    logger.error('LOGIC', 'Failed to get total draft counter', error);
    yield put(DraftDocumentsActions.fetchDraftCounterFailure({ error }));
  }
}

export function* draftDocumentsCountersSagas() {
  yield takeLatest(DraftDocumentsActions.fetchMany.type, getTotalReadyToSendSaga);
  yield takeLatest(DraftDocumentsActions.fetchMany.type, getTotalDraftSaga);
  yield takeLatest(DraftDocumentsActions.fetchDraftCounters.type, getTotalDraftSaga);
  yield takeLatest(DraftDocumentsActions.fetchTotalReadyToSend.type, getTotalReadyToSendSaga);
}
