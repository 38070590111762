import { Selector, createSelector } from '@reduxjs/toolkit';

import {
  DmpDocumentConnector,
  DocumentPatient,
  Patient,
  SendingRequest,
  SendingRequestDto,
  SendingsDashboardType,
  SuggestedRecipients,
} from '@honestica/core-apps-common/types';
import {
  formatProfessionnalName,
  getNameWithPrefix,
  hasHospitalBasePatientIntegration,
  isPatientMinor,
} from '@honestica/core-apps-common/utils';
import { isIntegration } from '@honestica/core-apps-common/validators';

import {
  selectDetailDocument,
  selectDetailDocumentDto,
  selectDetailDocumentMeta,
  selectDetailViewState,
  selectDocumentsState,
  selectReadyToSendAutomaticSending,
} from '@store/documents/documents.selector';
import {
  DraftDetailViewState,
  DraftDocumentMetadata,
  DraftDocumentMetadataKey,
  INITIAL_DRAFT_DOCUMENT_METADATA,
} from '@store/documents/outgoing/draft.state';
import { getPatientEntities } from '@store/entities/entities.selector';
import { State } from '@store/reducer';
import { selectSenderIdentities } from '@store/user/user.selector';
import {
  areSomeConnectorsEnabledForSending,
  calculateSendingRequestDtoRecipientCount,
  isCancelled,
  isReadyToSend,
  isSuspended,
} from '@utils/document.util';
import { identitiesListToObject } from '@utils/identities.util';

/*
 * DRAFT DASHBOARD
 */

export function selectSendingRequestIds(documentsState: State) {
  const subState = selectDocumentsState(documentsState, SendingsDashboardType.Draft);
  return subState.dashboardView.ids.reduce<string[]>((ids, id) => {
    const isSendingStatusLoading = subState.entities[id].meta.sendDocument.isLoading;
    return isSendingStatusLoading ? [...ids, id] : ids;
  }, []);
}

export function selectDraftDelayedDocumentIds(documentsState: State) {
  const subState = selectDocumentsState(documentsState, SendingsDashboardType.Draft);
  return subState.dashboardView.delayedIdsToRefresh;
}

export const selectDraftCurrentIdentityReference = (state: State) =>
  state.documentDraft?.dashboardView.currentIdentity;

export const selectDraftCurrentIdentity = (state: State) => {
  const ref = selectDraftCurrentIdentityReference(state);
  if (ref) {
    return identitiesListToObject(selectSenderIdentities(state))[ref];
  }
  return undefined;
};

export const selectFormatedDraftCurrentIdentity = (state: State) => {
  const identity = selectDraftCurrentIdentity(state);
  if (identity) {
    return {
      ...identity,
      name: getNameWithPrefix(formatProfessionnalName(identity)),
    };
  }
  return undefined;
};

export const selectDashboardTotalReadyToSend = (state: State) =>
  state.documentDraft.dashboardView.totalReadyToSend;

export const selectDashboardTotalReadyToSendValue = createSelector(
  selectDashboardTotalReadyToSend,
  selectDraftCurrentIdentityReference,
  (totalReadyToSend, currentIdentity) => {
    if (currentIdentity) {
      return totalReadyToSend.identities[currentIdentity];
    }
    return totalReadyToSend.total;
  },
);

export const selectDraftCounters = (state: State) => state.documentDraft.dashboardView.totalDraft;

export const selectShowBannerByDashboard = createSelector(
  selectDashboardTotalReadyToSendValue,
  selectReadyToSendAutomaticSending,
  (totalReadyToSendValue, readyToSendAutomaticSending) =>
    readyToSendAutomaticSending && totalReadyToSendValue,
);

/*
 * DOCUMENT DETAIL VIEW GENERICS
 */

export const selectDraftDetailViewState: Selector<State, DraftDetailViewState> = (state) =>
  selectDetailViewState(state, SendingsDashboardType.Draft) as DraftDetailViewState;

export const selectDraftDetailDocument: Selector<State, SendingRequest | undefined> = (state) =>
  selectDetailDocument(state, SendingsDashboardType.Draft);

export const selectDraftDetailDocumentDto: Selector<State, SendingRequestDto | undefined> = (
  state,
) => selectDetailDocumentDto(state, SendingsDashboardType.Draft);

export const selectDraftDetailDocumentMeta: Selector<State, DraftDocumentMetadata> = (state) =>
  (selectDetailDocumentMeta(state, SendingsDashboardType.Draft) as DraftDocumentMetadata) ||
  INITIAL_DRAFT_DOCUMENT_METADATA;

/*
 * DRAFT DETAIL
 */

export const selectDraftDetailCurrentPatient: Selector<State, Patient | undefined> = (state) =>
  selectDraftDetailDocument(state)?.patient?.patient;

export const selectDraftDetailDocumentId: Selector<State, string | undefined> = (state) =>
  selectDocumentsState(state, SendingsDashboardType.Draft).detailView.selectedId;

export const selectUpdateDocTypeState: Selector<
  State,
  DraftDocumentMetadata[DraftDocumentMetadataKey.updateDocType]
> = (state) => selectDraftDetailDocumentMeta(state).updateDocType;

export const selectUpdateSenderState: Selector<
  State,
  DraftDocumentMetadata[DraftDocumentMetadataKey.updateSender]
> = (state) => selectDraftDetailDocumentMeta(state).updateSender;

export const selectUpdateRecipientsState: Selector<
  State,
  DraftDocumentMetadata[DraftDocumentMetadataKey.updateRecipients]
> = (state) => selectDraftDetailDocumentMeta(state).updateRecipients;

export const selectDraftDetailRemoveRecipientState: Selector<
  State,
  DraftDocumentMetadata[DraftDocumentMetadataKey.removeRecipient]
> = (state) => selectDraftDetailDocumentMeta(state).removeRecipient;

export const selectUpdateIntegrationsState: Selector<
  State,
  DraftDocumentMetadata[DraftDocumentMetadataKey.updateIntegration]
> = (state) => selectDraftDetailDocumentMeta(state).updateIntegration;

export const selectRefreshPatientMatchState: Selector<
  State,
  DraftDocumentMetadata[DraftDocumentMetadataKey.refreshPatientMatch]
> = (state) => selectDraftDetailDocumentMeta(state).refreshPatientMatch;

export const selectUpdatePeriodeDateState: Selector<
  State,
  DraftDocumentMetadata[DraftDocumentMetadataKey.updatePeriodDate]
> = (state) => selectDraftDetailDocumentMeta(state).updatePeriodDate;

export const selectUpdatePatientsState: Selector<
  State,
  DraftDocumentMetadata[DraftDocumentMetadataKey.updatePatient]
> = (state) => selectDraftDetailDocumentMeta(state).updatePatient;

export const selectUpdateEhrPatientsState: Selector<
  State,
  DraftDocumentMetadata[DraftDocumentMetadataKey.updatePatient]
> = (state) => selectDraftDetailDocumentMeta(state).updateEhrPatient;

export const selectFetchAttachmentsState: Selector<
  State,
  DraftDocumentMetadata[DraftDocumentMetadataKey.updatePatient]
> = (state) => selectDraftDetailDocumentMeta(state).fetchAttachmentsRequest;

export const selectIsHospitalBasePatientSearchLoading: Selector<State, boolean> = (state) =>
  // patient matching will be triggered by any of those 2 actions:
  selectUpdatePatientsState(state).isLoading || selectRefreshPatientMatchState(state).isLoading;

const selectDraftDetailIsUpdatingDocument: Selector<State, boolean> = createSelector(
  selectDraftDetailDocumentMeta,
  (meta) =>
    meta.removePatient.isLoading ||
    meta.removeRecipient.isLoading ||
    meta.setEnsConversationClosed.isLoading ||
    meta.setEnsMessage.isLoading ||
    meta.updateDocType.isLoading ||
    meta.updateIntegration.isLoading ||
    meta.updateEhrPatient.isLoading ||
    meta.updatePatient.isLoading ||
    meta.updatePeriodDate.isLoading ||
    meta.updateRecipients.isLoading ||
    meta.updateSender.isLoading,
);

export const selectAreSomeConnectorsEnabledForSending: Selector<State, boolean> = (state) => {
  const document = selectDraftDetailDocumentDto(state);

  return areSomeConnectorsEnabledForSending(document);
};

export const selectIsPatientWithGam: Selector<State, boolean> = (state) => {
  const document = selectDraftDetailDocumentDto(state);
  const patientEntity = document?.patient?.id
    ? getPatientEntities(state)[document?.patient?.id]
    : undefined;

  return !!(
    document?.integration &&
    document.patient?.ehrPatientId &&
    patientEntity?.ehrDataOverwritten
  );
};

export const selectIsIntegrationWithHospitalBasePatient = createSelector(
  selectDraftDetailDocumentDto,
  (document) => hasHospitalBasePatientIntegration(document?.integration),
);

export const selectDraftDetailPatient: Selector<State, Patient | undefined> = (state) => {
  const patientId = selectDraftDetailDocumentDto(state)?.patient?.id;
  return patientId ? getPatientEntities(state)[patientId] : undefined;
};

export const selectDraftDetailDocumentPatient: Selector<State, DocumentPatient | undefined> = (
  state,
) => selectDraftDetailDocument(state)?.patient;

export const selectDraftDetailPatientsList: Selector<State, Patient[]> = (state) => {
  const { patientIds } = selectDraftDetailViewState(state);
  const patients = getPatientEntities(state);
  return patientIds.map((id) => patients[id]);
};

export const selectDraftDetailRecipientCount: Selector<State, number> = (state) => {
  const documentDto = selectDraftDetailDocumentDto(state);
  return calculateSendingRequestDtoRecipientCount(documentDto);
};

export const selectDraftDetailIsReadyToSend: Selector<State, boolean> = (state) => {
  const document = selectDraftDetailDocumentDto(state);
  if (!document) {
    return false;
  }

  const patient = selectDraftDetailPatient(state);
  return isReadyToSend({
    document,
    patient,
  });
};

export const selectDraftDetailIsSuspended: Selector<State, boolean> = (state) => {
  const document = selectDraftDetailDocumentDto(state);
  if (!document) {
    return false;
  }

  return isSuspended(document);
};

export const selectDraftDetailSuggestedRecipients: Selector<
  State,
  SuggestedRecipients | undefined
> = (state) => selectDraftDetailViewState(state).suggestedRecipients;

export const selectDraftDetailDocIntegrationId: Selector<State, string> = (state) => {
  const integration = selectDraftDetailDocumentDto(state)?.integration;
  return isIntegration(integration) ? integration.id : '';
};

export const selectDraftDetailDmpConnector: Selector<State, DmpDocumentConnector | undefined> = (
  state,
) => {
  const document = selectDraftDetailDocumentDto(state);
  const integration = document?.integration;
  if (isIntegration(integration)) {
    return integration.connectors.DMP;
  }

  return undefined;
};

export const selectDraftDetailIsPatientMinor: Selector<State, boolean> = (state) => {
  const ehrPatientId = selectDraftDetailDocumentDto(state)?.patient?.ehrPatientId ?? '';
  const patient = getPatientEntities(state)[ehrPatientId];

  if (!patient) {
    return false;
  }

  return isPatientMinor(patient);
};

export const selectDraftDetailIsSending: Selector<State, boolean> = (state) =>
  selectDraftDetailDocumentMeta(state).sendDocument?.isLoading;

export const selectAreAllDraftDetailActionsDisabled: Selector<State, boolean> = (state) => {
  const document = selectDraftDetailDocument(state);
  return areAllDraftDetailActionsDisabled(state, document);
};

export const areAllDraftDetailActionsDisabled: Selector<State, boolean> = (
  state,
  document: SendingRequest | undefined,
) => {
  const isDocumentFetching = selectDraftDetailViewState(state).documentRequest.isLoading;
  const isRemovingDocument = selectDraftDetailDocumentMeta(state).cancelDocument.isLoading;
  const isDuplicating = selectDraftDetailDocumentMeta(state).duplicateDocument.isLoading;
  const isSplitting = selectDraftDetailDocumentMeta(state).splitDocument.isLoading;
  const isUpdating = selectDraftDetailIsUpdatingDocument(state);
  const isSending = selectDraftDetailIsSending(state);
  const isDocumentDeleted = Boolean(document && isCancelled(document));

  return (
    !document ||
    isDocumentFetching ||
    isRemovingDocument ||
    isDuplicating ||
    isSending ||
    isSplitting ||
    isUpdating ||
    isDocumentDeleted
  );
};

export const selectDraftDetailCanSendDocument = createSelector(
  selectDraftDetailIsSending,
  selectDraftDetailIsReadyToSend,
  (sending, ready) => !sending && ready,
);

export const selectDraftDetailSender = createSelector(
  selectDraftDetailDocumentDto,
  (doc) => doc?.sender,
);
