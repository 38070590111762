import { ReactNode } from 'react';

import { Col, Layout, Row } from 'antd';

import styles from './Centered.module.less';

const { Content } = Layout;

interface OneColumnPageProps {
  children: ReactNode;
}

export const Centered = ({ children }: OneColumnPageProps) => (
  <Layout className={styles.layout}>
    <Content>
      <Row className={styles.layout}>
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 12, offset: 6 }}
          className={styles.flexColumn}
        >
          <div className={styles.container}>{children}</div>
        </Col>
      </Row>
    </Content>
  </Layout>
);
