import { MediumDeliveryStatus } from './recipient';

export enum ConnectorType {
  Dmp = 'DMP',
  TerrESante = 'TERR-ESANTE',
  Manual = 'MANUAL',
  Ehr = 'EHR',
  Gam = 'GAM',
  DematPatientSending = 'DEMAT-PATIENT-SENDING',
  In = 'IN',
}

export const SENDING_DISPLAYABLED_DEVICES = [
  ConnectorType.Dmp,
  ConnectorType.Ehr,
  ConnectorType.Gam,
  ConnectorType.Manual,
  ConnectorType.TerrESante,
  ConnectorType.DematPatientSending,
];

export const SENDING_DESTINATION_DEVICES: ConnectorType[] = [
  ConnectorType.Dmp,
  ConnectorType.Ehr,
  ConnectorType.Manual,
  ConnectorType.TerrESante,
];

export const INTEGRATION_DISPLAYABLED_DEVICES = [ConnectorType.Ehr];

export interface IntegrationRecipient {
  reference: string;
  name: string;
}

export interface Connector {
  id: string;
  owner: string;
  name: string;
  type: ConnectorType;
  fallbackRecipient?: IntegrationRecipient;
  integrationRecipient?: IntegrationRecipient;
  hospitalBasePatientMatch: boolean;
}

export enum DmpVisibility {
  VisibleForAll = 'VISIBLE_FOR_ALL',
  HideForProfessionals = 'HIDE_FOR_PROFESSIONALS',
  HideForPatient = 'HIDE_FOR_PATIENT',
  HideForParents = 'HIDE_FOR_PARENTS', // Only for minor patients
}

export interface DmpWasReplacedOrCanceled {
  type: 'replaced' | 'canceled';
  replacedDocumentId?: string;
}

export interface DmpConnectorSettings {
  documentTitle: string;
  visibility: DmpVisibility;
}

export enum ConnectorDisabledReason {
  /* PREPROCESSING REASONS */
  NoPatient = 'no-patient',
  IdentityCertificationFailure = 'identity-certification-failure',
  IdentityCertificationBusy = 'identity-certification-busy',
  IdentityCertificationMissingInfo = 'identity-certification-missing-info',
  InternalSystemError = 'internal-system-error',
  DmpInactive = 'dmp-inactive',
  DmpTerminated = 'dmp-terminated',
  TooManyPatients = 'too-many-patient',
  MissingInfo = 'missing-info',
  /* CORE APPS REASONS */
  Default = 'default',
  NoEncounter = 'no-encounter',
  NoPatientMatch = 'no-patient-match',
  DmpUnknownError = 'dmp-unknown-error',
}

/**
 * For EHR connectors, determines if document will be sent to the EHR automatically,
 * or to a dedicated service (PMSI) that integrates documents manually when auto is
 * not available, or the user has a doubt about the patient match accuracy.
 */
export type IntegrationMode = 'auto' | 'manual';

export type DocumentConnectorBase = Connector & {
  deliveryStatus?: MediumDeliveryStatus;
  isSelected: boolean;
  isDisabled: boolean;
  isDisabledReason: ConnectorDisabledReason | undefined;
  isUnavailable: boolean;
};

export type ConnectorSummary = Pick<
  DocumentConnectorBase,
  'id' | 'name' | 'deliveryStatus' | 'type'
>;
export interface GenericDocumentConnector extends DocumentConnectorBase {
  type:
    | ConnectorType.DematPatientSending
    | ConnectorType.Manual
    | ConnectorType.TerrESante
    | ConnectorType.Gam;
}

export interface DmpDocumentConnector extends DocumentConnectorBase {
  type: ConnectorType.Dmp;
  wasReplacedOrCanceled?: DmpWasReplacedOrCanceled;
  isReplacing?: boolean;
  settings: DmpConnectorSettings;
}

export interface EhrDocumentConnector extends DocumentConnectorBase {
  type: ConnectorType.Ehr;
  integrationMode: IntegrationMode;
  isAutoIntegrationPossible: boolean;
}

export type DocumentConnector =
  | GenericDocumentConnector
  | DmpDocumentConnector
  | EhrDocumentConnector;

export interface Connectors extends Record<ConnectorType, DocumentConnectorBase> {
  EHR: EhrDocumentConnector;
  DMP: DmpDocumentConnector;
}
