import { createAction } from '@reduxjs/toolkit';

import { SelectionState } from './selection.state';

const REDUCER_NAME = 'selection';

export type UpdateSelection = typeof updateSelection;
export const updateSelection = createAction<{ dashboard: keyof SelectionState; ids: string[] }>(
  `${REDUCER_NAME}/update`,
);

export interface MoveInsideSelectionPayload {
  dashboard: keyof SelectionState;
  id: string;
}
export const moveDownInsideSelection = createAction<MoveInsideSelectionPayload>(
  `${REDUCER_NAME}/moveOneDown`,
);
export const moveUpInsideSelection = createAction<MoveInsideSelectionPayload>(
  `${REDUCER_NAME}/moveOneUp`,
);

export type RemoveFromSelection = typeof removeFromSelection;
export const removeFromSelection = createAction<{ dashboard: keyof SelectionState; id: string }>(
  `${REDUCER_NAME}/removeOne`,
);
export type RemoveManyFromSelection = typeof removeManyFromSelection;
export const removeManyFromSelection = createAction<{
  dashboard: keyof SelectionState;
  ids: string[];
}>(`${REDUCER_NAME}/removeMany`);

export type SetPositionInsideSelection = typeof setPositionInsideSelection;
export const setPositionInsideSelection = createAction<{
  dashboard: keyof SelectionState;
  item: string;
  position: number;
}>(`${REDUCER_NAME}/moveAt`);
