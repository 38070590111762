import { Tracking, tracking } from '@services/analytics';
import { all, getContext, race, select, take, takeLatest } from 'redux-saga/effects';

import {
  DocumentPatientDto,
  DocumentRecipientDto,
  SendingRequestDto,
} from '@honestica/core-apps-common/types';

import { UpdateRecipientAction } from '@store/documents/documents.actions';
import { selectDraftDetailDocumentDto } from '@store/documents/outgoing/draft.selector';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';

function* trackAddPatientRecipientSaga() {
  const trackingService: Tracking = yield getContext('trackingService');
  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  const patient: DocumentPatientDto = { ...docDto.patient! };

  trackingService.documentRecipientAdded({
    communicationRequest: docDto.id,
    fhirReference: patient.id,
    telecoms: patient.telecoms.map((telecom) => telecom.type),
    type: 'Patient',
  });
}

function* trackAddRecipientSaga({ payload: { recipient } }: UpdateRecipientAction) {
  const trackingService: Tracking = yield getContext('trackingService');
  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);

  const { success } = yield race({
    success: take(DraftDocumentsActions.addRecipientSuccess.type),
    failure: take(DraftDocumentsActions.addRecipientFailure.type),
  });

  if (success) {
    trackingService.documentRecipientAdded({
      communicationRequest: docDto.id,
      fhirReference: recipient.id,
      telecoms: (recipient as DocumentRecipientDto).telecoms.map((telecom) => telecom.type),
      type: recipient.type,
    });
  }
}

function* trackUpdateRecipientSaga({ payload: { recipient } }: UpdateRecipientAction) {
  const trackingService: Tracking = yield getContext('trackingService');
  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);

  const { success } = yield race({
    success: take(DraftDocumentsActions.updateRecipientSuccess.type),
    failure: take(DraftDocumentsActions.updateRecipientFailure.type),
  });

  if (success) {
    trackingService.documentRecipientUpdated({
      communicationRequest: docDto.id,
      fhirReference: recipient.id,
      telecoms: (recipient as DocumentRecipientDto).telecoms.map((telecom) => telecom.type),
      type: recipient.type,
    });
  }
}

function* trackRemoveRecipientSaga({ payload: { recipient } }: UpdateRecipientAction) {
  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);

  const { success } = yield race({
    success: take(DraftDocumentsActions.removeRecipientSuccess.type),
    failure: take(DraftDocumentsActions.removeRecipientFailure.type),
  });

  if (success) {
    tracking.documentRecipientRemoved({
      communicationRequest: docDto.id,
      fhirReference: recipient.id,
    });
  }
}

export function* trackingDocumentRecipientsSagas() {
  yield all([takeLatest(DraftDocumentsActions.addRecipient.type, trackAddRecipientSaga)]);
  yield all([
    takeLatest(DraftDocumentsActions.addPatientRecipientSuccess.type, trackAddPatientRecipientSaga),
  ]);
  yield all([takeLatest(DraftDocumentsActions.updateRecipient.type, trackUpdateRecipientSaga)]);
  yield all([takeLatest(DraftDocumentsActions.removeRecipient.type, trackRemoveRecipientSaga)]);
}
