import { createAction } from '@reduxjs/toolkit';

import { ErrorPayload } from '@honestica/core-apps-common/types';

import {
  BatchDownloadDocumentsAction,
  BatchDownloadDocumentsPayload,
  BatchFailureOnlyErrorAction,
  DownloadOnePayload,
  OneFailurePayload,
} from '@store/documents/documents.actions';
import {
  DocumentsState,
  XHR_STATUS_ERROR,
  XHR_STATUS_IDLE,
  XHR_STATUS_LOADING,
} from '@store/documents/documents.state';

// DASHBOARD

export function downloadMany<T extends DocumentsState>(
  state: T,
  _: BatchDownloadDocumentsAction, // Arg required for downloadDocumentsSaga
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      downloadRequest: XHR_STATUS_LOADING,
    },
  };
}

export function downloadManySuccess<T extends DocumentsState>(state: T): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      downloadRequest: XHR_STATUS_IDLE,
    },
  };
}

export function downloadManyFailure<T extends DocumentsState>(
  state: T,
  _action: BatchFailureOnlyErrorAction,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      downloadRequest: XHR_STATUS_ERROR,
    },
  };
}

// eslint-disable-next-line sonarjs/no-identical-functions
export function downloadManyNoAttachmentFailure<T extends DocumentsState>(
  state: T,
  _action: BatchFailureOnlyErrorAction,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      downloadRequest: XHR_STATUS_ERROR,
    },
  };
}

// DETAIL
export function generateDownloadActions(namespace: string) {
  return {
    downloadOne: createAction<DownloadOnePayload>(`${namespace}/downloadOne`),
    downloadOneSuccess: createAction(`${namespace}/downloadOneSuccess`),
    downloadOneFailure: createAction<OneFailurePayload>(`${namespace}/downloadOneFailure`),

    downloadMany: createAction<BatchDownloadDocumentsPayload>(`${namespace}/downloadMany`),
    downloadManySuccess: createAction(`${namespace}/downloadManySuccess`),
    downloadManyFailure: createAction<ErrorPayload>(`${namespace}/downloadManyFailure`),
    downloadManyNoAttachmentFailure: createAction<ErrorPayload>(
      `${namespace}/downloadManyNoAttachmentFailure`,
    ),
  };
}
