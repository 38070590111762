import { put, takeEvery } from 'redux-saga/effects';

import {
  ActionDeferredActions,
  AddDeferredAction,
} from '@store/actionDeferred/actionsDeferred.slice';
import { AllowedDeferrableActionType } from '@store/actionDeferred/actionsDeferred.state';

import { InfoMessageActions } from '../infoMessages.slice';
import { InfoMessageType } from '../infoMessages.state';

const deferredActionsToInfoMessageType: Record<AllowedDeferrableActionType, InfoMessageType> = {
  sendDocument: InfoMessageType.sendDocumentPending,
  sendMany: InfoMessageType.sendDocumentsPending,
};

function* saga(action: AddDeferredAction) {
  const type = deferredActionsToInfoMessageType[action.payload.type];
  yield put(InfoMessageActions.addOne({ type, id: action.payload.id }));
}

export function* addInfoMessageOnDeferredSagas() {
  yield takeEvery(ActionDeferredActions.addOne.type, saga);
}
