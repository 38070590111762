import {
  IntegrationRequest,
  IntegrationRequestDto,
  IntegrationsDashboardType,
} from '@honestica/core-apps-common/types';

import { WORKLIST } from '@constants/documents.constants';
import { XhrStatus, XhrStatusWithTimeout } from '@store/types';

import {
  BaseDashboardViewState,
  BaseDetailViewState,
  BaseEntitiesState,
  DelayedDocumentIdsToRefreshState,
  INITIAL_DASHBOARD_VIEW_STATE,
  INITIAL_DETAIL_VIEW_STATE,
  XHR_STATUS_IDLE,
} from '../documents.state';

export interface WorklistDetailViewState extends BaseDetailViewState {
  patientsRequest: XhrStatus;
  patientIds: string[];
}
export interface WorklistIntegrationsState {
  entities: BaseEntitiesState<WorklistIntegrationRequestDtoWithMeta>;
  dashboardView: BaseDashboardViewState & DelayedDocumentIdsToRefreshState;
  detailView: WorklistDetailViewState;
}

export const INITIAL_WORKLIST_INTEGRATIONS_STATE: WorklistIntegrationsState = {
  entities: {},
  dashboardView: {
    ...INITIAL_DASHBOARD_VIEW_STATE,
    searchParams: { ...INITIAL_DASHBOARD_VIEW_STATE.searchParams, defaultDateRange: true },
    delayedIdsToRefresh: [],
  },
  detailView: {
    ...INITIAL_DETAIL_VIEW_STATE,
    patientsRequest: XHR_STATUS_IDLE,
    patientIds: [],
  },
};

export type WorklistIntegrationRequestDtoWithMeta = {
  resource: IntegrationRequestDto;
  meta: WorklistIntegrationRequestMetadata;
};

export interface WorklistIntegrationRequestWithMeta {
  resource: IntegrationRequest;
  meta: WorklistIntegrationRequestMetadata;
}

export enum WorklistIntegrationRequestMetadataKey {
  removePatient = 'removePatient',
  updateDocType = 'updateDocType',
  updateIntegration = 'updateIntegration',
  refreshPatientMatch = 'refreshPatientMatch',
  updatePatient = 'updatePatient',
  updatePeriodDate = 'updatePeriodDate',
  cancelDocument = 'cancelDocument',
  integrateDocument = 'integrateDocument',
  fetchAttachmentsRequest = 'fetchAttachmentsRequest',
  fileStorageIds = 'fileStorageIds',
  docDashboardType = 'docDashboardType',
  updateMedicalExamType = 'updateMedicalExamType',
  updateOriginalSender = 'updateOriginalSender',
  duplicateDocument = 'duplicateDocument',
  updateDocumentTitle = 'updateDocumentTitle',
}

export interface WorklistIntegrationRequestMetadata {
  // detail
  [WorklistIntegrationRequestMetadataKey.fetchAttachmentsRequest]: XhrStatus;
  [WorklistIntegrationRequestMetadataKey.fileStorageIds]: string[] | undefined;
  [WorklistIntegrationRequestMetadataKey.removePatient]: XhrStatus;
  [WorklistIntegrationRequestMetadataKey.updateDocType]: XhrStatus;
  [WorklistIntegrationRequestMetadataKey.updateIntegration]: XhrStatus;
  [WorklistIntegrationRequestMetadataKey.refreshPatientMatch]: XhrStatusWithTimeout;
  [WorklistIntegrationRequestMetadataKey.updatePatient]: XhrStatus;
  [WorklistIntegrationRequestMetadataKey.updatePeriodDate]: XhrStatus;
  [WorklistIntegrationRequestMetadataKey.updateMedicalExamType]: XhrStatus;
  [WorklistIntegrationRequestMetadataKey.updateOriginalSender]: XhrStatus;
  [WorklistIntegrationRequestMetadataKey.updateDocumentTitle]: XhrStatus;
  // common
  [WorklistIntegrationRequestMetadataKey.cancelDocument]: XhrStatus;
  [WorklistIntegrationRequestMetadataKey.integrateDocument]: XhrStatus;
  [WorklistIntegrationRequestMetadataKey.docDashboardType]: IntegrationsDashboardType.Worklist;
  [WorklistIntegrationRequestMetadataKey.duplicateDocument]: XhrStatus;
}

export type WorklistIntegrationRequestMetadataType = keyof WorklistIntegrationRequestMetadata;

export const INITIAL_WORKLIST_INTEGRATION_DOCUMENT_METADATA: WorklistIntegrationRequestMetadata = {
  // detail
  [WorklistIntegrationRequestMetadataKey.fetchAttachmentsRequest]: XHR_STATUS_IDLE,
  [WorklistIntegrationRequestMetadataKey.fileStorageIds]: undefined,
  [WorklistIntegrationRequestMetadataKey.removePatient]: XHR_STATUS_IDLE,
  [WorklistIntegrationRequestMetadataKey.updateDocType]: XHR_STATUS_IDLE,
  [WorklistIntegrationRequestMetadataKey.refreshPatientMatch]: {
    ...XHR_STATUS_IDLE,
    timeout: false,
  },
  [WorklistIntegrationRequestMetadataKey.updateIntegration]: { ...XHR_STATUS_IDLE },
  [WorklistIntegrationRequestMetadataKey.updatePatient]: XHR_STATUS_IDLE,
  [WorklistIntegrationRequestMetadataKey.updatePeriodDate]: XHR_STATUS_IDLE,
  [WorklistIntegrationRequestMetadataKey.updateMedicalExamType]: XHR_STATUS_IDLE,
  [WorklistIntegrationRequestMetadataKey.updateOriginalSender]: XHR_STATUS_IDLE,
  [WorklistIntegrationRequestMetadataKey.updateDocumentTitle]: XHR_STATUS_IDLE,
  // common
  [WorklistIntegrationRequestMetadataKey.cancelDocument]: XHR_STATUS_IDLE,
  [WorklistIntegrationRequestMetadataKey.integrateDocument]: XHR_STATUS_IDLE,
  [WorklistIntegrationRequestMetadataKey.docDashboardType]: WORKLIST,
  [WorklistIntegrationRequestMetadataKey.duplicateDocument]: XHR_STATUS_IDLE,
};
