import { createSlice } from '@reduxjs/toolkit';

import { generateDownloadActions } from '../common/reducers/downloadDocuments.reducers';
import { updateDocumentAttachments } from '../common/reducers/fetchAttachments.reducers';
import {
  fetchDocumentsSuccess,
  fetchMany,
  fetchManyFailure,
  fetchManySuccess,
  fetchOne,
  fetchOneFailure,
  fetchOneSuccess,
  setDetailViewDocument,
  setDetailViewDocumentDto,
} from '../common/reducers/fetchDocuments.reducers';
import {
  flushLoadingDocumentIds,
  updateLoadingDocumentIds,
} from '../common/reducers/updateLoadingDocumentIds.reducers';
import {
  updateAndDropManySuccess,
  updateFailure,
  updateMany,
  updateOne,
  updateOneFailure,
  updateOneSuccess,
} from '../common/reducers/updateMeta.reducers';
import { updateSearchParams } from '../common/reducers/updateSearchParams.reducers';
import {
  BatchAction,
  BatchFailureAction,
  BatchSuccessAction,
  DetailViewDocumentDtoAction,
  FetchDocumentSuccessAction,
  GetDocumentAction,
  GetDocumentSuccessAction,
  OneAction,
  OneFailureAction,
  OneSuccessAction,
  SelectDocumentIDAction,
} from '../documents.actions';

import {
  ArchivedDocumentMetadataKey,
  ArchivedDocumentsState,
  INITIAL_ARCHIVED_DOCUMENTS_STATE,
  INITIAL_ARCHIVED_DOCUMENT_METADATA,
} from './archived.state';

const slice = createSlice({
  name: 'archivedDocuments',
  initialState: INITIAL_ARCHIVED_DOCUMENTS_STATE,
  reducers: {
    selectOne: (state, _: SelectDocumentIDAction) => ({ ...state, selectedId: _.payload }),

    updateSearchParams,
    updateLoadingDocumentIds: (state, action) => updateLoadingDocumentIds(state, action),
    flushLoadingDocumentIds,

    fetchMany,
    fetchManySuccess: (state, action) =>
      fetchManySuccess(state, action, INITIAL_ARCHIVED_DOCUMENT_METADATA),
    fetchManyFailure,
    fetchDocumentsSuccess: (state, action) =>
      fetchDocumentsSuccess(state, action, INITIAL_ARCHIVED_DOCUMENT_METADATA),

    restoreMany: (state, action: BatchAction) =>
      updateMany(state, action, ArchivedDocumentMetadataKey.restoreRequest),
    restoreManySuccess: (state, action: BatchSuccessAction): ArchivedDocumentsState =>
      updateAndDropManySuccess(state, action, ArchivedDocumentMetadataKey.restoreRequest),
    restoreManyFailure: (state, action: BatchFailureAction) =>
      updateFailure(state, action, ArchivedDocumentMetadataKey.restoreRequest),

    /*
     * DETAIL
     */

    /*
     * View
     */

    getOne: (state, _action: GetDocumentAction) => state,
    setDetailViewDocument: (state, action: GetDocumentSuccessAction) =>
      setDetailViewDocument(state, action, INITIAL_ARCHIVED_DOCUMENTS_STATE.detailView),
    setDetailViewDocumentDto: (state, action: DetailViewDocumentDtoAction) =>
      setDetailViewDocumentDto(
        state,
        action,
        INITIAL_ARCHIVED_DOCUMENTS_STATE.detailView,
        INITIAL_ARCHIVED_DOCUMENT_METADATA,
      ),

    fetchOne,
    fetchOneSuccess: (state, action: FetchDocumentSuccessAction) =>
      fetchOneSuccess(state, action, INITIAL_ARCHIVED_DOCUMENT_METADATA), // updates entities
    fetchOneFailure,

    fetchManyAttachments: (state, action: OneAction) =>
      updateOne(state, action, ArchivedDocumentMetadataKey.fetchAttachmentsRequest),
    fetchManyAttachmentsSuccess: updateDocumentAttachments,
    fetchManyAttachmentsFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, ArchivedDocumentMetadataKey.fetchAttachmentsRequest),

    /*
     * Entities
     */

    restoreOne: (state, action: OneAction) =>
      updateOne(state, action, ArchivedDocumentMetadataKey.restoreRequest),
    restoreOneSuccess: (state, action: OneSuccessAction) =>
      updateOneSuccess(state, action, ArchivedDocumentMetadataKey.restoreRequest),
    restoreOneFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, ArchivedDocumentMetadataKey.restoreRequest),
  },
});

export const documentArchivedReducer = slice.reducer;

export const ArchivedDocumentsActions = {
  ...slice.actions,
  ...generateDownloadActions(slice.name),
};
