import {
  IntegrationsDashboardType,
  MainRouteUrls,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

export const MAX_RECIPIENTS_COUNT = 20;
export const MIN_RECIPIENTS_COUNT = 1;

export const ARCHIVE = SendingsDashboardType.Archive;
export const DRAFT = SendingsDashboardType.Draft;
export const INBOX = SendingsDashboardType.Inbox;
export const SENT = SendingsDashboardType.Sent;
export const ERRORS = 'errors';
export const IN_ERRORS = 'in-errors';

export const DETAIL_VIEW = 'detail';

export const ARCHIVE_DETAIL_VIEW = `${ARCHIVE}/${DETAIL_VIEW}`;
export const DRAFT_DETAIL_VIEW = `${DRAFT}/${DETAIL_VIEW}`;
export const INBOX_DETAIL_VIEW = `${INBOX}/${DETAIL_VIEW}`;
export const SENT_DETAIL_VIEW = `${SENT}/${DETAIL_VIEW}`;
export const ERRORS_DETAIL_VIEW = `${ERRORS}/${DETAIL_VIEW}`;

export const DOCUMENTS_PATH = `/${MainRouteUrls.Documents}`;

export const ARCHIVE_PATH = `${DOCUMENTS_PATH}/${ARCHIVE}`;
export const DRAFT_PATH = `${DOCUMENTS_PATH}/${DRAFT}`;
export const INBOX_PATH = `${DOCUMENTS_PATH}/${INBOX}`;
export const SENT_PATH = `${DOCUMENTS_PATH}/${SENT}`;
export const ERRORS_PATH = `${DOCUMENTS_PATH}/${ERRORS}`;

export const DRAFT_DETAIL_VIEW_PATH = `${DRAFT_PATH}/${DETAIL_VIEW}`;
export const SENT_DETAIL_VIEW_PATH = `${SENT_PATH}/${DETAIL_VIEW}`;
export const ERRORS_DETAIL_VIEW_PATH = `${ERRORS_PATH}/${DETAIL_VIEW}`;

export const DOCUMENTS_ROOT_PATHS = [ARCHIVE_PATH, DRAFT_PATH, ERRORS_PATH, INBOX_PATH, SENT_PATH];

export const INTEGRATIONS_PATH = `/${MainRouteUrls.Integrations}`;

export const WORKLIST = IntegrationsDashboardType.Worklist;
export const WORKLIST_PATH = `${INTEGRATIONS_PATH}/${WORKLIST}`;
export const INTEGRATED = IntegrationsDashboardType.Integrated;
export const INTEGRATED_PATH = `${INTEGRATIONS_PATH}/${INTEGRATED}`;

export const INTEGRATION_ROOT_PATHS = [INTEGRATED_PATH, WORKLIST_PATH];

export const DocumentPathToName = {
  [INBOX_PATH]: INBOX,
  [ARCHIVE_PATH]: ARCHIVE,
  [DRAFT_PATH]: DRAFT,
  [SENT_PATH]: SENT,
  [ERRORS_PATH]: ERRORS,
  [INTEGRATED_PATH]: INTEGRATED,
  [WORKLIST_PATH]: WORKLIST,
} as const;

export const DocumentTypeToPath = {
  [INBOX]: INBOX_PATH,
  [ARCHIVE]: ARCHIVE_PATH,
  [DRAFT]: DRAFT_PATH,
  [SENT]: SENT_PATH,
  [INTEGRATED]: INTEGRATED_PATH,
  [WORKLIST]: WORKLIST_PATH,
  [ERRORS]: ERRORS_PATH,
} as const;

export const dashboardTypeAvailable = [ARCHIVE, DRAFT, INBOX, SENT, ERRORS, WORKLIST, INTEGRATED];

export const dashboardTypeAvailableExceptError = [
  ARCHIVE,
  DRAFT,
  INBOX,
  SENT,
  WORKLIST,
  INTEGRATED,
];

export const DOCUMENT_CACHE_QUERY_KEY = 'document';
export const DOCUMENTS_CACHE_QUERY_KEY = 'documents';
export const DOCUMENTS_CACHE_RETRY_COUNT = 2;
export const DOCUMENTS_CACHE_STALE_TIME = 24 * 60 * 60 * 1000; // 24h.  staleTime determines for how long a certain response will still be considered fresh (or not stale), dismissing the need for a new request.
export const DOCUMENTS_CACHE_TIME = 1 * 60 * 60 * 1000; // 1h. cacheTime determines for how long a certain response is supposed to stay in cache before it gets garbage collected.
export const DOCUMENTS_CACHE_MAX_IDENTITY_TO_PREFETCH = 30;

export const SENDING_REQUESTS_API_URL = '/sending_requests';
