import { useMemo } from 'react';

import { AccountSettings } from '@launcher/components/Sidebar/AccountSettings';
import { Help } from '@launcher/components/Sidebar/Help';
import { WorkspaceSwitch } from '@launcher/components/Sidebar/WorkspaceSwitch';
import { APP_HOME_URLS, APP_URLS } from '@launcher/constants';
import { EnvManager } from '@services/environment/environment.service';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppKeys } from '@honestica/core-apps-common/types';
import { Sidebar, colors } from '@honestica/ui-kit/src';

import { Chat } from '../Chat';

import { MainSidebarReduxProps, mainSidebarConnector } from './mainSidebar.connector';

interface SidebarProps extends MainSidebarReduxProps {
  switchWorkspace: (id: string) => void;
}

const logoColors: Record<string, string> = {
  staging: colors.magenta[3],
  review: colors.magenta[3],
  'post-prod': colors.cyan[4],
  prod: colors.brand[5],
  dev: colors.magenta[3],
};

const LOGO_COLOR = logoColors[EnvManager.getName() ?? 'dev'];

const MainSidebarComponent = (props: SidebarProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const currentApp = useMemo(
    () => props.coreApps.find((app) => pathname.includes(app.path))?.name,
    [pathname, props.coreApps],
  );

  const handleSelectApp = (appKey: string) => {
    if (APP_URLS[appKey as AppKeys]) {
      navigate(`${APP_HOME_URLS[appKey as AppKeys]}`);
    }
  };

  return (
    <Sidebar
      data-testid="sidebar-menu"
      bottomMenu={
        <>
          <Chat />
          {props.user.workspaces.length > 1 && (
            <WorkspaceSwitch
              switchWorkspace={props.switchWorkspace}
              workspaces={props.user.workspaces}
              currentWorkspaceId={props.user.currentWorkspaceId}
            />
          )}
          <Help />
          <AccountSettings />
        </>
      }
      logoColor={LOGO_COLOR}
    >
      {props.coreApps.map((app) => (
        <Sidebar.Icon
          data-testid={`sidebar-menu-${app.name}`}
          key={app.name}
          iconName={app.iconUrl}
          title={t(app.displayName)}
          isSelected={app.name === currentApp}
          onClick={() => handleSelectApp(app.name)}
        />
      ))}
    </Sidebar>
  );
};

export const MainSidebar = mainSidebarConnector(MainSidebarComponent);
