export function swapItemWithNextInSelection<T = string>(selection: T[], index: number): T[] {
  return [
    ...selection.slice(0, index),
    selection[index + 1],
    selection[index],
    ...selection.slice(index + 2),
  ];
}

export function moveItemAtIndexInSelection<T = string>(
  selection: T[],
  item: T,
  newIndex: number,
): T[] {
  const currentIndex = selection.indexOf(item);

  const newSelection = [...selection.slice(0, currentIndex), ...selection.slice(currentIndex + 1)];

  return [...newSelection.slice(0, newIndex), item, ...newSelection.slice(newIndex)];
}
