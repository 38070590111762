import { REFRESH_PATIENT_MATCH_TIMEOUT_MS } from '@launcher/constants';
import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import {
  all,
  delay,
  getContext,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import {
  DmpConnectorSettings,
  EhrPatientIntegration,
  Encounter,
  Integration,
  List,
  SendingRequestBundle,
  SendingRequestDto,
} from '@honestica/core-apps-common/types';
import { isIntegration } from '@honestica/core-apps-common/validators';

import * as API from '@api';
import {
  FetchEncountersAction,
  OneAction,
  SwitchEncounterAction,
  ToggleConnectorAction,
  ToggleConnectorIntegrationModeAction,
  UpdateConnectorSettingsAction,
  UpdateDocumentDatePeriodAction,
  UpdateEhrPatientAction,
  updateEntitiesAction,
} from '@store/documents/documents.actions';
import { selectDraftDetailDocumentDto } from '@store/documents/outgoing/draft.selector';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { updateDocumentInCache } from '@utils/caching.util';

function* refreshPatientMatchSaga({ payload: { id } }: UpdateDocumentDatePeriodAction | OneAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  // TODO NEXT
  try {
    const res: SendingRequestBundle | Record<string, never> = yield API.refreshPatientMatch(id);

    if (res.document) {
      // The new backend returned the document synchronously => this is not true with the existing Beca implementation
      const bundle = res as SendingRequestBundle;

      yield updateDocumentInCache({
        updatedDocument: bundle.document,
        queryClient,
      });
      yield put(updateEntitiesAction(bundle.entities));

      yield put(DraftDocumentsActions.refreshPatientMatchSuccess({ id }));
    } else {
      /*
        When patient match is reprocessed, Beca sends two pusher events, the first one when the status became "draft" the second one when the status returns to "suspended". In receiveSendingRequestUpdated we catch these events and update entities with updateEntitiesAction. Here we catch the action corresponding to the first pusher event. It is it's not appropriate but working only because the document is a "draft" and so no editable in the app. Whe should find a better way to do that. It would be usefull to get more info in the pusher event.
      */
      const actiontoTake = updateEntitiesAction.type;
      const { success, timeout } = yield race({
        success: take(actiontoTake),
        timeout: delay(REFRESH_PATIENT_MATCH_TIMEOUT_MS),
      });
      if (success) {
        logger.info('LOGIC', 'Patient Match Refresh was successful');
        yield put(DraftDocumentsActions.refreshPatientMatchSuccess({ id }));
      } else if (timeout) {
        logger.error('LOGIC', 'Patient Match Refresh timed out');
        yield put(
          DraftDocumentsActions.refreshPatientMatchTimeout({
            id,
          }),
        );
        yield put(DraftDocumentsActions.fetchOne({ id }));
      }
    }
  } catch (error) {
    logger.error('LOGIC', 'Failed to refresh patient match', error);
    yield put(
      DraftDocumentsActions.refreshPatientMatchFailure({
        id,
        error,
      }),
    );
  }
}

function* fetchEncountersSaga({ payload: { integrationId } }: FetchEncountersAction) {
  try {
    const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
    const encounters: List<Encounter> = yield API.fetchEncounters(docDto.id, integrationId);
    yield put(DraftDocumentsActions.fetchEncountersSuccess({ encounters }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to fetch encounters', error);
    yield put(
      DraftDocumentsActions.fetchEncountersFailure({
        error,
      }),
    );
  }
}

function* fetchEhrPatientsSaga() {
  try {
    const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
    const ehrPatients: List<EhrPatientIntegration> = yield API.fetchEhrPatients(docDto.id);
    yield put(DraftDocumentsActions.fetchEhrPatientsSuccess({ ehrPatients }));
  } catch (err) {
    logger.error('LOGIC', 'Failed to fetch patient matchs', err);
    yield put(
      DraftDocumentsActions.fetchEhrPatientsFailure({
        error: 'Failed to fetch patient matchs',
      }),
    );
  }
}

function* updateEhrPatientSaga({ payload: { ehrPatientIntegration } }: UpdateEhrPatientAction) {
  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const integration: Integration = yield API.updateEhrPatient(
      docDto.id,
      ehrPatientIntegration.ehrPatient?.id,
      ehrPatientIntegration.id,
    );
    yield put(
      DraftDocumentsActions.updateEhrPatientSuccess({
        id: docDto.id,
        integration,
        ehrPatient: ehrPatientIntegration.ehrPatient,
      }),
    );
  } catch (err) {
    logger.error('LOGIC', 'Failed to replace EHR patient', err);
    yield put(
      DraftDocumentsActions.updateEhrPatientFailure({
        id: docDto.id,
        error: 'Failed to replace EHR patient',
      }),
    ); // no effect
  }
}

function* switchEncounterSaga({ payload: { integrationId, encounter } }: SwitchEncounterAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);

  try {
    yield API.replaceEncounter(docDto.id, integrationId, encounter);
    if (!isIntegration(docDto.integration)) {
      return;
    }

    const updatedDocument = {
      ...docDto,
      integration: {
        ...docDto.integration,
        encounter,
      },
    };

    yield updateDocumentInCache({
      updatedDocument,
      queryClient,
    });
    yield put(DraftDocumentsActions.setDetailViewDocumentDto(updatedDocument));
  } catch (error) {
    logger.error('LOGIC', 'Failed to replace Encounter', error);
    yield put(
      DraftDocumentsActions.switchEncounterFailure({
        id: docDto.id,
        error,
      }),
    ); // no effect
  }
}

function* onToggleConnectorSaga({
  payload: { integrationId, connectorId, integrationMode },
}: ToggleConnectorAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);
  try {
    const bundle: SendingRequestBundle = yield API.toggleConnector({
      documentId: docDto.id,
      integrationId,
      connectorId,
      integrationMode,
    });
    yield put(DraftDocumentsActions.toggleConnectorSuccess({ bundle }));
    yield updateDocumentInCache({
      updatedDocument: bundle.document,
      queryClient,
    });
  } catch (error) {
    logger.error('LOGIC', `Failed to toggle connector ${connectorId}`, error);
    yield put(
      DraftDocumentsActions.toggleConnectorFailure({
        id: docDto.id,
        error,
      }),
    ); // no effect
  }
}

function* onUpdateConnectorSettingsSaga({
  payload: { integrationId, connector },
}: UpdateConnectorSettingsAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);

  try {
    const bundle: SendingRequestBundle = yield API.updateConnectorSettings({
      documentId: docDto.id,
      ...connector,
      integrationId,
      connectorId: connector.id,
      settings: connector.settings as DmpConnectorSettings,
    });

    yield updateDocumentInCache({
      updatedDocument: bundle.document,
      queryClient,
    });
    yield put(DraftDocumentsActions.setDetailViewDocumentDto(bundle.document));

    yield put(DraftDocumentsActions.updateConnectorSettingsSuccess({ bundle }));
  } catch (error) {
    logger.error(
      'LOGIC',
      `Failed to update connector settings (CONNECTOR ID = ${connector.id})`,
      error,
    );
    yield put(
      DraftDocumentsActions.updateConnectorSettingsFailure({
        id: docDto.id,

        error,
      }),
    );
  }
}

function* onToggleConnectorIntegrationModeSaga({
  payload: { id, ...connectorIntegrationMode },
}: ToggleConnectorIntegrationModeAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const docDto: SendingRequestDto = yield select(selectDraftDetailDocumentDto);

  try {
    const bundle: SendingRequestBundle = yield API.toggleConnectorIntegrationMode({
      ...connectorIntegrationMode,
      documentId: docDto.id,
    });

    yield updateDocumentInCache({
      updatedDocument: bundle.document,
      queryClient,
    });
    yield put(DraftDocumentsActions.setDetailViewDocumentDto(bundle.document));

    yield put(DraftDocumentsActions.toggleConnectorIntegrationModeSuccess({ bundle }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to toggle connector integration mode', error);
    yield put(
      DraftDocumentsActions.updateConnectorSettingsFailure({
        id: docDto.id,
        error,
      }),
    );
  }
}

export function* integrationsDraftDocumentsSaga() {
  yield all([
    takeLatest(DraftDocumentsActions.fetchEncounters.type, fetchEncountersSaga),
    takeLatest(DraftDocumentsActions.fetchEhrPatients.type, fetchEhrPatientsSaga),
    takeLatest(DraftDocumentsActions.updateEhrPatient.type, updateEhrPatientSaga),
    takeLatest(DraftDocumentsActions.refreshPatientMatch.type, refreshPatientMatchSaga),
    takeLatest(DraftDocumentsActions.switchEncounter.type, switchEncounterSaga),
    takeEvery(DraftDocumentsActions.toggleConnector.type, onToggleConnectorSaga),
    takeEvery(
      DraftDocumentsActions.toggleConnectorIntegrationMode.type,
      onToggleConnectorIntegrationModeSaga,
    ),
    takeEvery(DraftDocumentsActions.updateConnectorSettings.type, onUpdateConnectorSettingsSaga),
  ]);
}
