export type ReleaseChannel = 'stable' | 'beta' | 'alpha' | 'alpha-on-staging';

export enum ProxyMode {
  NoProxy = 'no-proxy',
  System = 'system',
  Manual = 'manual',
}

export type DesktopConnectionSettings = {
  pacUrl: string;
  proxyMode: ProxyMode;
  proxyUrl: string;
  ssoConnection: string;
  username: string;
};

export type ImportWatcherLocalSettings = {
  isImportWatcherActivated: boolean;
  printerFolderName?: string;
};

export type OtherDesktopSettings = {
  autoUpdateEnabled: boolean;
  hideSupport: boolean;
  myRequestsOnly: boolean;
};

export type QADesktopSettings = {
  releaseServerURL?: string;
};

export type WatcherLocalSettings = {
  keepFileDuringXDays: number;
  stabilityThreshold: number;
  usePollingInterval: number | false;
  watchPath: string;
  usePolling: boolean;
};

export type DesktopSettings = DesktopConnectionSettings &
  ImportWatcherLocalSettings &
  OtherDesktopSettings &
  QADesktopSettings &
  WatcherLocalSettings;

export type RegistryPair = [keyof DesktopSettings, number | boolean | string];

export type RegistryPairAlike = [string, number | boolean | string];

// Would be better if this was given by electron app
export interface NonParsedRegistryItem {
  key: string;
  get: (arg1: string) => Promise<Winreg.RegistryItem>;
  set: ((arg1: string, arg2: string, arg3: string) => Promise<void>) | undefined;
}
