import { all, delay, put, takeEvery } from 'redux-saga/effects';

import { ActionDeferredActions } from '@store/actionDeferred/actionsDeferred.slice';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';

import {
  convertSuccessActionsToInfoMessagePayload,
  triggerDeferredActionsTypeToPendingInfoMessageType,
} from '../helpers/successActionsToInfoMessageType.helper';
import { InfoMessageActions, SUCCESS_ACTIONS_MESSAGE_VISIBLE_FOR_MS } from '../infoMessages.slice';
import {
  ActionsDispatchingPendingInfoMessage,
  ActionsDispatchingSuccessInfoMessage,
} from '../infoMessages.state';

function* afterTriggering(action: ActionsDispatchingPendingInfoMessage) {
  yield put(
    InfoMessageActions.removeOne({
      type: triggerDeferredActionsTypeToPendingInfoMessageType[action.payload.type],
      id: action.payload.id,
    }),
  );
}

function* successfullyTriggered(action: ActionsDispatchingSuccessInfoMessage) {
  const infoMessagePayload = convertSuccessActionsToInfoMessagePayload(action);

  if (!infoMessagePayload) return;

  yield put(InfoMessageActions.addOne(infoMessagePayload));

  yield delay(SUCCESS_ACTIONS_MESSAGE_VISIBLE_FOR_MS);

  yield put(
    InfoMessageActions.removeOne({ type: infoMessagePayload.type, id: infoMessagePayload.id }),
  );
}

export function* addInfoMessageOnSuccessSagas() {
  yield all([
    takeEvery(ActionDeferredActions.executeOne.type, afterTriggering),
    takeEvery(DraftDocumentsActions.sendManySuccess.type, successfullyTriggered),
    takeEvery(DraftDocumentsActions.sendOneSuccess.type, successfullyTriggered),

    // simple actions (not deferred) : could be done in an other saga ("addInfoBasic")?
    takeEvery(InboxDocumentsActions.downloadManyNoAttachmentFailure.type, successfullyTriggered),
    takeEvery(DraftDocumentsActions.updateDocumentSenderSuccess.type, successfullyTriggered),
  ]);
}
