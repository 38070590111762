import { isDesktopApp } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';
import { notificationWindow } from '@services/notificationWindow/notificationWindow.service';
import { all, put, takeLatest } from 'redux-saga/effects';

import { openModalFromStore } from '@store/modal/modal.action';
import { ModalType } from '@store/modal/modal.type';
import {
  NotificationActionTypes,
  NotificationActions,
} from '@store/notificationWindow/notificationWindow.action';

/**
 * On desktop, we send notification actions to the notification
 * window so they are dispatched in the right window.
 */
export function* handleNotificationAsync({ payload, type }: ReturnType<NotificationActionTypes>) {
  if (isDesktopApp() && notificationWindow.isInitialized) {
    try {
      notificationWindow.showNotifWindow();
    } catch (error) {
      notificationWindow.init();
      notificationWindow.showNotifWindow();
      logger.warn('DESKTOP', 'notificationWindow was reset', {
        error: error instanceof Error ? error.message : JSON.stringify(error as any),
      });
    }
    notificationWindow.sendMessageToNotificationWindow(type, payload);
  } else {
    yield put(openModalFromStore({ modal: ModalType.documentJobNotification }));
  }
}

export function* notificationWindowSagas() {
  yield all(
    Object.values(NotificationActions).map((action) => takeLatest(action, handleNotificationAsync)),
  );
}
