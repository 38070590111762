import { BASENAME } from '@launcher/constants';
import { EnvManager } from '@services/environment';
import { useNavigate } from 'react-router-dom';

export const useCustomNavigate = () => {
  const navigate = useNavigate();
  const basename = EnvManager.isTest() ? '' : BASENAME;

  return (path: string, searchParams?: URLSearchParams) =>
    searchParams
      ? navigate({ pathname: `${basename}${path}`, search: searchParams?.toString() })
      : navigate(`${basename}${path}`);
};
