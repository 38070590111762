import { useTranslation } from 'react-i18next';

import { EmptyState, Page } from '@honestica/ui-kit/src';

import LifenIllustration from '@assets/free-file.png';

export const ForbiddenAccess = () => {
  const { t } = useTranslation();
  return (
    <Page.Empty>
      <EmptyState
        image={{ src: LifenIllustration }}
        title={t('error.forbiddenAccess.app_not_activated.title')}
        description={t('error.forbiddenAccess.app_not_activated.description')}
      />
    </Page.Empty>
  );
};
