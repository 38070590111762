import { ComponentProps } from 'react';

import cx from 'classnames';

import OneToneIcons, { OneToneIconName } from './OneToneIcons';
import TwoTonesIcons, { TwoTonesIconName } from './TwoTonesIcons';

import styles from './Icon.module.less';

interface IconProps extends ComponentProps<'span'> {
  name: OneToneIconName | TwoTonesIconName;
  noContainer?: boolean;
  size?: number;
  color?: string;
  flex?: boolean;
  title?: string;
  desc?: string;
  rotate?: number;
}

export const Icon = ({
  name,
  size = 24,
  style,
  color,
  className,
  flex = false,
  title,
  rotate = 0,
  desc = '',
  noContainer = false,
  onClick,
  ...otherProps
}: IconProps) => {
  if (!OneToneIcons[name] && !TwoTonesIcons[name]) {
    console.warn(`Icon "${name}" was not found`);
    return <></>;
  }

  const Svg = OneToneIcons[name] ?? TwoTonesIcons[name];

  if (noContainer)
    return (
      <Svg
        className={className}
        height={size}
        width={size}
        title={title}
        desc={desc}
        style={{ transform: `rotate(${rotate}deg)` }}
      />
    );

  return (
    <span
      {...otherProps}
      className={cx(
        '_Icon',
        styles.icon,
        { [styles.flex]: flex, [styles.hoverable]: !!onClick },
        className,
      )}
      style={{ ...style, color: color ?? 'inherit' }}
      onClick={onClick}
    >
      <Svg
        height={size}
        width={size}
        title={title}
        desc={desc}
        style={{ transform: `rotate(${rotate}deg)` }}
      />
    </span>
  );
};
