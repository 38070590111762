import { ReactNode } from 'react';

import { Layout } from 'antd';
import cx from 'classnames';

import styles from './Empty.module.less';

const { Content } = Layout;

interface EmptyPageProps {
  children: ReactNode;
  containerClassName?: string;
}

export const Empty = ({ containerClassName, children, ...others }: EmptyPageProps) => (
  <Layout {...others}>
    <Content className={styles.background}>
      <div className={cx(styles.container, containerClassName)}>{children}</div>
    </Content>
  </Layout>
);
