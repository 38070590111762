import { ReactNode } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';
import { Tag } from '../Tag';

import styles from './Filter.module.less';

export interface FilterProps<T> {
  isDefaultValueSelected: boolean;
  onResetFilter: () => void;
  renderFilter: () => ReactNode;
  selectedValue: () => ReactNode | T;
}

export const Filter = <T,>(props: FilterProps<T>) => {
  const { t } = useTranslation();

  if (props.isDefaultValueSelected) {
    return <>{props.renderFilter()}</>;
  }

  return (
    <Tag
      closable
      onClose={props.onResetFilter}
      className={cx(styles.filterTag, styles.tag)}
      closeIcon={<Icon name="Cancel" size={16} title={t('resetFilter')} />}
    >
      {props.selectedValue() ?? ''}
    </Tag>
  );
};
