import {
  SendingRequest,
  SendingRequestDto,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import { ARCHIVE } from '@constants/documents.constants';
import { XhrStatus } from '@store/types';

import {
  BaseDashboardViewState,
  BaseDetailViewState,
  BaseEntitiesState,
  INITIAL_DASHBOARD_VIEW_STATE,
  INITIAL_DETAIL_VIEW_STATE,
  XHR_STATUS_IDLE,
} from '../documents.state';

export type ArchivedDetailViewState = BaseDetailViewState;
export interface ArchivedDocumentsState {
  entities: BaseEntitiesState<ArchivedDocumentDtoWithMeta>;
  dashboardView: BaseDashboardViewState;
  detailView: ArchivedDetailViewState;
}

export const INITIAL_ARCHIVED_DOCUMENTS_STATE: ArchivedDocumentsState = {
  entities: {},
  dashboardView: {
    ...INITIAL_DASHBOARD_VIEW_STATE,
    searchParams: { ...INITIAL_DASHBOARD_VIEW_STATE.searchParams, defaultDateRange: true },
  },
  detailView: INITIAL_DETAIL_VIEW_STATE,
};

export type ArchivedDocumentDtoWithMeta = {
  resource: SendingRequestDto;
  meta: ArchivedDocumentMetadata;
};

export type ArchivedSendingRequestWithMeta = {
  resource: SendingRequest;
  meta: ArchivedDocumentMetadata;
};

export enum ArchivedDocumentMetadataKey {
  restoreRequest = 'restoreRequest',
  fetchAttachmentsRequest = 'fetchAttachmentsRequest',
  fileStorageIds = 'fileStorageIds',
  docDashboardType = 'docDashboardType',
}

export type ArchivedDocumentMetadata = {
  // common
  [ArchivedDocumentMetadataKey.fileStorageIds]: string[] | undefined;
  [ArchivedDocumentMetadataKey.docDashboardType]: SendingsDashboardType.Archive;
  // detail
  [ArchivedDocumentMetadataKey.fetchAttachmentsRequest]: XhrStatus;
  [ArchivedDocumentMetadataKey.restoreRequest]: XhrStatus;
};

export type ArchivedDocumentMetadataType = keyof ArchivedDocumentMetadata;

export const INITIAL_ARCHIVED_DOCUMENT_METADATA: ArchivedDocumentMetadata = {
  // common
  [ArchivedDocumentMetadataKey.fileStorageIds]: undefined,
  [ArchivedDocumentMetadataKey.docDashboardType]: ARCHIVE,
  // detail
  [ArchivedDocumentMetadataKey.fetchAttachmentsRequest]: XHR_STATUS_IDLE,
  [ArchivedDocumentMetadataKey.restoreRequest]: XHR_STATUS_IDLE,
};
