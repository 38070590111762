import { parseISO } from 'date-fns';

import {
  ConnectorType,
  DocumentBase,
  DocumentStatus,
  Encounter,
  IntegrationRequest,
  IntegrationRequestDto,
  Patient,
  PatientMatchStatus,
  SendingRequestDto,
  isSendingRequestDto,
} from '@honestica/core-apps-common/types';
import { formatDateShort, isSameDay } from '@honestica/core-apps-common/utils';

import { professionalToIdentityReference } from './identities.util';

export function hasConnectorEnabled(integration: DocumentBase['integration']): boolean {
  if (!integration?.connectors) return false;

  return Object.values(integration.connectors || []).some((connector) => connector.isSelected);
}

export function filterIntegrationRecipients(
  document: SendingRequestDto | IntegrationRequestDto | undefined,
) {
  if (!document) {
    return undefined;
  }

  if (!isSendingRequestDto(document)) {
    return document;
  }

  return {
    ...document,
    recipients: document.recipients.filter((recipient) => {
      const recipientIdentityReference = professionalToIdentityReference(recipient);
      const connectors = document.integration?.connectors
        ? Object.values(document.integration?.connectors)
        : [];
      if (connectors.length > 0) {
        const recipientExistsInConnectors = connectors.filter(
          (connector) =>
            connector.fallbackRecipient &&
            connector.fallbackRecipient.reference === recipientIdentityReference,
        );
        return recipientExistsInConnectors.length === 0;
      }
      return true;
    }),
  };
}

/**
 *
 * @param integration
 * @returns true if a connector needs backend preprocessing (patientMatch which a DMP or hospitalBase)
 */

export function hasConnectorWithPreprocessing(integration: DocumentBase['integration']): boolean {
  if (!integration) return false;

  return Object.values(integration.connectors || []).some(
    (connector) => connector.type === ConnectorType.Dmp || connector.hospitalBasePatientMatch,
  );
}

export function getEncounterTitle(
  encounter: Encounter | undefined,
  encounterDateText: string,
): string | undefined {
  if (!encounter) {
    return '';
  }
  const start = formatDateShort(encounter.start);

  let encounterDates = '';

  if (!encounter.end || isSameDay(encounter.start, parseISO(encounter.end))) {
    encounterDates = `${encounterDateText} ${start}`;
  } else {
    const end = formatDateShort(encounter.end);
    encounterDates = `${start} — ${end}`;
  }

  if (encounter.externalId) {
    return `${encounterDates} · IEP : ${encounter.externalId}`;
  }

  return encounterDates;
}

export function isIntegrationRequestCancelled(document: IntegrationRequest): boolean {
  return document.status === DocumentStatus.Cancelled;
}

export function getPatientMatchStatus(ehrPatient: Patient | undefined): PatientMatchStatus {
  return ehrPatient ? PatientMatchStatus.Found : PatientMatchStatus.NotFound;
}
