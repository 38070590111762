import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { NavigateFunction } from 'react-router-dom';
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import {
  DashboardSearchParams,
  FeatureFlags,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import { archiveDocument, archiveDocuments } from '@api';
import { INBOX, INBOX_PATH } from '@constants/documents.constants';
import {
  selectDashboardSearchParams,
  selectManyDocumentsFileStorageIds,
} from '@store/documents/documents.selector';
import { removeFromSelection, removeManyFromSelection } from '@store/selection/selection.action';
import { getFeatureFlags } from '@store/user/user.selector';
import { invalidateDashboardCache } from '@utils/caching.util';

import { BatchAction, OneAction } from '../../documents.actions';
import { InboxDocumentsActions } from '../inbox.slice';

function* archiveOneDocumentSaga({ payload: { id } }: OneAction) {
  const featureFlags: FeatureFlags = yield select(getFeatureFlags);
  const navigate: NavigateFunction = yield getContext('customNavigate');
  const queryClient: QueryClient = yield getContext('queryClient');

  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, [id], INBOX);

  try {
    yield archiveDocument(id);
    yield put(InboxDocumentsActions.archiveOneSuccess({ id, fileStorageIds }));

    const searchParams: DashboardSearchParams = yield select((state) =>
      selectDashboardSearchParams(state, INBOX),
    );
    invalidateDashboardCache({
      dashboardType: SendingsDashboardType.Inbox,
      search: { searchParams, featureFlags },
      refetchType: 'active',
      queryClient,
    });
    invalidateDashboardCache({
      dashboardType: SendingsDashboardType.Archive,
      refetchType: 'inactive',
      queryClient,
    });

    yield put(removeFromSelection({ dashboard: SendingsDashboardType.Inbox, id }));
    navigate(INBOX_PATH);
  } catch (error) {
    logger.error('LOGIC', 'Failed to archive document', error);
    yield put(
      InboxDocumentsActions.archiveOneFailure({
        id,
        error,
      }),
    );
  }
}

function* archiveManyDocumentsSaga({ payload: { ids } }: BatchAction) {
  const queryClient: QueryClient = yield getContext('queryClient');
  const featureFlags: FeatureFlags = yield select(getFeatureFlags);
  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, ids, INBOX);
  try {
    yield put(InboxDocumentsActions.updateLoadingDocumentIds({ ids }));
    yield call(archiveDocuments, ids);
    yield put(removeManyFromSelection({ dashboard: SendingsDashboardType.Inbox, ids }));
    yield put(InboxDocumentsActions.archiveManySuccess({ ids, fileStorageIds }));

    const searchParams: DashboardSearchParams = yield select((state) =>
      selectDashboardSearchParams(state, INBOX),
    );
    invalidateDashboardCache({
      dashboardType: SendingsDashboardType.Inbox,
      search: { searchParams, featureFlags },
      refetchType: 'active',
      queryClient,
    });
    invalidateDashboardCache({
      dashboardType: SendingsDashboardType.Archive,
      refetchType: 'inactive',
      queryClient,
    });

    yield put(InboxDocumentsActions.flushLoadingDocumentIds());
  } catch (error) {
    logger.error('LOGIC', 'Failed to archive documents', error);
    yield put(
      InboxDocumentsActions.archiveManyFailure({
        ids,
        error,
      }),
    );
  }
}

export function* archiveInboxDocumentsSagas() {
  yield all([
    takeLatest(InboxDocumentsActions.archiveOne.type, archiveOneDocumentSaga),
    takeLatest(InboxDocumentsActions.archiveMany.type, archiveManyDocumentsSaga),
  ]);
}
