export function isDesktopApp() {
  return Boolean(
    typeof window.electron !== 'undefined' ||
      (typeof window.process !== 'undefined' && window.process.versions.electron),
  );
}

enum DesktopVersionWithBridgeApi {
  maj = 100,
  min = 14,
  patch = 17, // 18 in production but set here to 17 to enable autoupdate tests
}

export function isUsingNewBridgeAPI(window: Window) {
  const [maj, min, patch] = window.electron.getElectronVersion().split(/[-.]/);

  return (
    Number(maj) >= DesktopVersionWithBridgeApi.maj &&
    Number(min) >= DesktopVersionWithBridgeApi.min &&
    Number(patch) >= DesktopVersionWithBridgeApi.patch
  );
}
