import { all, put, takeEvery } from 'redux-saga/effects';

import { ApiErrorsKey } from '@honestica/core-apps-common/constants';
import { isApiError } from '@honestica/core-apps-common/utils';

import {
  BatchDownloadDocumentsFailureAction,
  BatchFailureAction,
  OneFailureAction,
} from '@store/documents/documents.actions';
import { ArchivedDocumentsActions } from '@store/documents/incoming/archived.slice';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice'; // TODO handle all draftDashboard failures?
import { SentDocumentsActions } from '@store/documents/outgoing/sent.slice';
import { UploadDocumentsActions } from '@store/uploadDocuments/uploadDocuments.slice';

import { addMutationErrorMessage } from './errors.action';
import { MutationError } from './errors.state';

// Step 1: Add action type here for mutation errors
type Failures = BatchFailureAction | OneFailureAction | BatchDownloadDocumentsFailureAction;

// Step 2: add a case here
// update MutationError (error.state)
// update fr.json : "error.mutation....""

// Would be better to index with action types but they are typed as string
const typeToMutationErrorName: { [key: string]: MutationError } = {
  [ArchivedDocumentsActions.restoreManyFailure.type]: MutationError.restoreDocument,
  [DraftDocumentsActions.cancelManyFailure.type]: MutationError.cancelManyDocuments,
  [DraftDocumentsActions.cancelOneFailure.type]: MutationError.cancelDocument,
  [DraftDocumentsActions.downloadManyFailure.type]: MutationError.downloadManyDocuments,
  [DraftDocumentsActions.groupManyFailure.type]: MutationError.groupDocument,
  [DraftDocumentsActions.removeDocumentPatientFailure.type]: MutationError.removeDocumentPatient,
  [DraftDocumentsActions.removeVerificationFailure.type]: MutationError.removeVerification,
  [DraftDocumentsActions.sendManyFailure.type]: MutationError.sendManyDocuments,
  [DraftDocumentsActions.sendOneFailure.type]: MutationError.sendDocument,
  [DraftDocumentsActions.splitOneFailure.type]: MutationError.splitDocument,
  [DraftDocumentsActions.updateDocumentPatientFailure.type]: MutationError.updateDocumentPatient,
  [InboxDocumentsActions.archiveOneFailure.type]: MutationError.archiveDocument,
  [InboxDocumentsActions.downloadManyFailure.type]: MutationError.downloadManyDocuments,
  [InboxDocumentsActions.downloadOneFailure.type]: MutationError.downloadDocument,
  [InboxDocumentsActions.forwardOneFailure.type]: MutationError.forwardDocument,
  [SentDocumentsActions.downloadOneFailure.type]: MutationError.downloadDocument,
  [SentDocumentsActions.forwardOneFailure.type]: MutationError.forwardDocument,
  [UploadDocumentsActions.uploadFromDnDForbidden.type]: MutationError.dndForbidden,
};

const errorCodeToMutationErrorName: { [key: string]: MutationError } = {
  [ApiErrorsKey.DOCUMENT_ALREADY_SENT]: MutationError.sendDocumentAlreadySent,
  [ApiErrorsKey.DOCUMENT_ALREADY_CANCELLED]: MutationError.cancelDocumentAlreadyCanceled,
};

function* addMessage(action: Failures) {
  let errorCode;
  if (isApiError(action.payload?.error)) {
    errorCode = action.payload.error.key;
  }

  if (errorCode && Object.keys(errorCodeToMutationErrorName).includes(errorCode)) {
    const error = errorCodeToMutationErrorName[errorCode];
    yield put(addMutationErrorMessage(error));
  } else {
    // TODO : type check not working
    const error = typeToMutationErrorName[action.type];

    if (error) {
      yield put(addMutationErrorMessage(error));
    }
  }
}

// Step 3: add a case here
export function* errorsSagas() {
  // Customs
  yield all([
    takeEvery(ArchivedDocumentsActions.restoreManyFailure.type, addMessage),
    takeEvery(DraftDocumentsActions.cancelManyFailure.type, addMessage),
    takeEvery(DraftDocumentsActions.cancelOneFailure.type, addMessage),
    takeEvery(DraftDocumentsActions.downloadManyFailure.type, addMessage),
    takeEvery(DraftDocumentsActions.groupManyFailure.type, addMessage),
    takeEvery(DraftDocumentsActions.removeDocumentPatientFailure.type, addMessage),
    takeEvery(DraftDocumentsActions.removeVerificationFailure.type, addMessage),
    takeEvery(DraftDocumentsActions.sendManyFailure.type, addMessage),
    takeEvery(DraftDocumentsActions.sendOneFailure.type, addMessage),
    takeEvery(DraftDocumentsActions.splitOneFailure.type, addMessage),
    takeEvery(DraftDocumentsActions.updateDocumentPatientFailure.type, addMessage),
    takeEvery(InboxDocumentsActions.archiveOneFailure.type, addMessage),
    takeEvery(InboxDocumentsActions.downloadManyFailure.type, addMessage),
    takeEvery(InboxDocumentsActions.downloadOneFailure.type, addMessage),
    takeEvery(InboxDocumentsActions.forwardOneFailure.type, addMessage),
    takeEvery(SentDocumentsActions.downloadOneFailure.type, addMessage),
    takeEvery(SentDocumentsActions.forwardOneFailure.type, addMessage),
    takeEvery(UploadDocumentsActions.uploadFromDnDForbidden.type, addMessage),
  ]);
}
