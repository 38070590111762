import { ReactText } from 'react';

import { createAction } from '@reduxjs/toolkit';

import {
  CreatePatient as CreatePatientType,
  List,
  Patient,
  PatientsImportJobData,
  PatientsImportJobState,
  RollbackPatientsImportJobState,
  SearchParams,
} from '@honestica/core-apps-common/types';
import { JobRealTimeEventType } from '@honestica/lifen-realtime-service';

export type EnqueuePatientsImportJob = typeof enqueuePatientsImportJob;
export type EnqueueRollbackPatientsImportJob = typeof enqueueRollbackPatientsImportJob;
export type FetchUserLatestPatientsImportJobState = typeof fetchUserLatestPatientJobState;
export type UpdatePatientJobState = typeof updatePatientJobState;
export type UpdatePatientJobStateSuccess = typeof updatePatientJobStateSuccess;
export type CreatePatient = typeof createPatient;
export type CreatePatientSuccess = typeof createPatientSuccess;
export type FindPatient = typeof findPatient;
export type FindPatients = typeof findPatients;
export type UpdatePatient = typeof updatePatient;
export type UpdatePatientSuccess = typeof updatePatientSuccess;
export type DeletePatients = typeof deletePatients;

const REDUCER_NAME = 'patientDirectory';

const CREATE = `${REDUCER_NAME}/create`;

export const createPatient = createAction<CreatePatientType>(CREATE);
export const createPatientSuccess = createAction<Patient>(`${CREATE}Success`);
export const createPatientFailure = createAction<unknown>(`${CREATE}Failure`);

const ENQUEUE_IMPORT_JOBS = `${REDUCER_NAME}/enqueueImportJob`;

export const enqueuePatientsImportJob = createAction<PatientsImportJobData>(ENQUEUE_IMPORT_JOBS);
export const enqueuePatientsImportJobSuccess = createAction<PatientsImportJobState>(
  `${ENQUEUE_IMPORT_JOBS}Success`,
);
export const enqueuePatientsImportJobFailure = createAction<unknown>(
  `${ENQUEUE_IMPORT_JOBS}Failure`,
);

const ENQUEUE_ROLLBACK_IMPORT_JOBS = `${REDUCER_NAME}/enqueueRollbackImportJob`;

export const enqueueRollbackPatientsImportJob = createAction<string>(ENQUEUE_ROLLBACK_IMPORT_JOBS);
export const enqueueRollbackPatientsImportJobSuccess = createAction<RollbackPatientsImportJobState>(
  `${ENQUEUE_ROLLBACK_IMPORT_JOBS}Success`,
);
export const enqueueRollbackPatientsImportJobFailure = createAction<unknown>(
  `${ENQUEUE_ROLLBACK_IMPORT_JOBS}Failure`,
);

const FETCH_USER_LATEST_JOB_STATE = `${REDUCER_NAME}/fetchUserLatestJobState`;

export const fetchUserLatestPatientJobState = createAction<void>(FETCH_USER_LATEST_JOB_STATE);
export const fetchUserLatestPatientJobStateSuccess = createAction<
  PatientsImportJobState | RollbackPatientsImportJobState
>(`${FETCH_USER_LATEST_JOB_STATE}Success`);
export const fetchUserLatestPatientJobStateFailure = createAction<unknown>(
  `${FETCH_USER_LATEST_JOB_STATE}Failure`,
);

export const updatePatientJobState = createAction<
  PatientsImportJobState | RollbackPatientsImportJobState
>(JobRealTimeEventType.UPDATE_PATIENTS_IMPORT_JOB_STATE);
export const updatePatientJobStateSuccess = createAction<
  PatientsImportJobState | RollbackPatientsImportJobState
>(`${JobRealTimeEventType.UPDATE_PATIENTS_IMPORT_JOB_STATE}Success`);
export const updatePatientJobStateFailure = createAction<unknown>(
  `${JobRealTimeEventType.UPDATE_PATIENTS_IMPORT_JOB_STATE}Failure`,
);

const DELETE_MANY = `${REDUCER_NAME}/deleteMany`;

export const deletePatients = createAction<{ patientIds: ReactText[] }>(DELETE_MANY);
export const deletePatientsSuccess = createAction<Patient>(`${DELETE_MANY}Success`);
export const deletePatientsFailure = createAction<unknown>(`${DELETE_MANY}Failure`);

const FIND_ONE = `${REDUCER_NAME}/findOne`;

export const findPatient = createAction<Patient['id']>(FIND_ONE);
export const findPatientSuccess = createAction<Patient>(`${FIND_ONE}Success`);
export const findPatientFailure = createAction<unknown>(`${FIND_ONE}Failure`);

const FIND_MANY = `${REDUCER_NAME}/findMany`;

export const findPatients = createAction<SearchParams>(FIND_MANY);
export const findPatientsSuccess = createAction<List<Patient>>(`${FIND_MANY}Success`);
export const findPatientsFailure = createAction<unknown>(`${FIND_MANY}Failure`);

const UPDATE_ONE = `${REDUCER_NAME}/updateOne`;

export const updatePatient = createAction<Patient>(UPDATE_ONE);
export const updatePatientSuccess = createAction<Patient>(`${UPDATE_ONE}Success`);
export const updatePatientFailure = createAction<unknown>(`${UPDATE_ONE}Failure`);
