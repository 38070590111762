import {
  WATCHERS_POLLING_INTERVAL_DEFAULT,
  WATCHER_WRITE_POLLING_INTERVAL,
} from '@launcher/constants';
import {
  WatcherChannel,
  WatcherChannelConfigs,
  closeWatchers,
  initFileWatchers,
} from '@launcher/helpers/desktop';
import { DesktopSettings } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';
import { WatchOptions } from 'chokidar';
import { all, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { FeatureFlags } from '@honestica/core-apps-common/types';

import { notificationAddPending } from '@store/notificationWindow/notificationWindow.action';
import { UploadDocumentsActions } from '@store/uploadDocuments/uploadDocuments.slice';
import { getFeatureFlags, getIsLifenSendingActivated } from '@store/user/user.selector';

import { DesktopActions } from '../desktop.action';
import { UpdateDesktopSettingsAction } from '../desktop.reducer';
import { selectDesktopSettings } from '../desktop.selector';

let closeExistingChannels: null | (() => void) = null;

window.addEventListener('beforeunload', () => {
  if (closeExistingChannels) closeExistingChannels();
});

function* listenToChannel(config: WatcherChannel) {
  const { callback, channel, name: channelName } = config;
  try {
    logger.info('DESKTOP', `Starting ${channelName} channel`);
    while (true) {
      const { newFileInFilePath, restart, updateSettings } = yield race({
        newFileInFilePath: take(channel),
        restart: take(DesktopActions.startWatchers),
        updateSettings: take(DesktopActions.updateSettings),
      });

      if (newFileInFilePath) {
        yield put(callback(newFileInFilePath)); // process file
        yield put(notificationAddPending({ count: 1, flowType: channelName }));
      }

      if (
        restart ||
        (updateSettings &&
          ['stabilityThreshold', 'usePollingInterval', 'usePolling', 'watchPath'].some(
            (key) => key in updateSettings.payload,
          ))
      ) {
        logger.info('DESKTOP', `Closing ${channelName} chanel`);
        channel.close();
        break;
      }
    }
  } catch (err) {
    logger.info('DESKTOP', `Ending upload on added file in ${channelName}`);
  }
}

// Generators are not working well with callbacks, only solution is to use custom eventChannel
function* openSaga() {
  if (closeExistingChannels) closeExistingChannels();

  const settings: DesktopSettings = yield select(selectDesktopSettings);
  const featureFlags: FeatureFlags = yield select(getFeatureFlags); // should be removed after customer migration re setup
  const lifenSendingActivated: boolean = yield select(getIsLifenSendingActivated);

  const config: Partial<WatchOptions> = {
    ...((featureFlags['enable-watchers-polling'] || settings.usePolling) && {
      usePolling: true,
      interval: WATCHERS_POLLING_INTERVAL_DEFAULT,
    }),
    awaitWriteFinish: {
      stabilityThreshold: settings.stabilityThreshold,
      pollInterval: WATCHER_WRITE_POLLING_INTERVAL,
    },
  };

  const watcherConfigs: WatcherChannelConfigs = [];

  if (lifenSendingActivated) {
    watcherConfigs.push({ name: 'sending', callback: UploadDocumentsActions.uploadFromWatcher });
  }

  const channels = initFileWatchers(watcherConfigs, config);

  closeExistingChannels = () => closeWatchers(channels);

  yield all(channels.map(listenToChannel));
}

function* updateWatcherSettingsSaga(action: UpdateDesktopSettingsAction) {
  if (['stabilityThreshold', 'usePolling'].some((key) => key in action.payload)) {
    yield openSaga();
  }
}

function closeSaga() {
  if (closeExistingChannels) closeExistingChannels();
}

export function* triggerActionOnNewFileInWatchedFolder() {
  yield all([takeLatest(DesktopActions.startWatchers.type, openSaga)]);
  yield all([takeLatest(DesktopActions.updateSettings.type, updateWatcherSettingsSaga)]);
  yield all([takeLatest(DesktopActions.closeWatchers.type, closeSaga)]);
}
