import { select } from 'redux-saga/effects';

import {
  IntegrationRequestDto,
  IntegrationsDashboardType,
  SendingRequestDto,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import { selectDetailDocumentDto } from '@store/documents/documents.selector';
import { selectPathName } from '@store/router/router.selector';

const AllSendingDashboardType = {
  ...SendingsDashboardType,
  ...IntegrationsDashboardType,
};
export function getDashboardTypeFromRoute(pathname: string) {
  return (
    Object.values(AllSendingDashboardType).find((dashboard) => pathname.includes(dashboard)) || null
  );
}

export function* getCurrentDocument() {
  const pathname: string = yield select(selectPathName);
  const dashboardType = getDashboardTypeFromRoute(pathname);
  if (dashboardType) {
    const docDto: SendingRequestDto | IntegrationRequestDto = yield select((state) =>
      selectDetailDocumentDto(state, dashboardType),
    );
    return docDto;
  }
  return undefined;
}
