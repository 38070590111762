import {
  ActivityDto,
  Connector,
  ConnectorType,
  DmpConnectorSettings,
  DocumentConnector,
  EhrDocumentConnector,
  Integration,
  IntegrationMode,
  IntegrationRequestBundle,
  Organization,
  Patient,
  Practitioner,
  PractitionerRole,
  ProfessionalBaseType,
  SendingRequestBundle,
  Telecom,
  TelecomType,
} from '../types';

export function isPatient(val: any): val is Patient {
  return (
    (typeof val?.usualFirstname === 'string' || typeof val?.birthFirstname === 'string') &&
    typeof val?.active === 'boolean' &&
    typeof val?.id === 'string'
  );
}

export function isProfessional(val: any): val is Practitioner | Organization {
  return typeof val?.type === 'string' && typeof val?.name === 'string';
}

export function isPractitioner(value: any): value is Practitioner {
  return value?.type === ProfessionalBaseType.Practitioner;
}

export function isOrganization(value: any): value is Organization {
  return value?.type === ProfessionalBaseType.Organization;
}

export function isEnsPatientOrganization(value: any): boolean {
  return isOrganization(value) && value.telecoms.ens.length > 0;
}

export function isConnector(value: any): value is Connector {
  return Object.hasOwnProperty.call(value, 'owner');
}

export function isIntegration(value: any): value is Integration {
  return typeof value?.id === 'string' && typeof value.connectors === 'object';
}

export const isIntegrationBundle = (
  bundle: SendingRequestBundle | IntegrationRequestBundle,
): bundle is IntegrationRequestBundle => 'integrationRequest' in bundle;

export function isDocumentConnector(value: any): value is DocumentConnector {
  return (
    typeof value?.isSelected === 'boolean' &&
    typeof value?.isDisabled === 'boolean' &&
    typeof value?.owner === 'string'
  );
}

export const isEhrConnector = (val: any): val is EhrDocumentConnector =>
  val?.type === ConnectorType.Ehr;

export function isPractitionerRole(value: any): value is PractitionerRole {
  return value?.type === ProfessionalBaseType.PractitionerRole;
}

export const isTelecomType = (val: any): val is TelecomType =>
  Object.values(TelecomType).includes(val);

export function isIntegrationMode(val: any): val is IntegrationMode {
  return typeof val === 'string' && (val === 'auto' || val === 'manual');
}

export const isTelecom = (val: any): val is Telecom =>
  typeof val?.value === 'string' && isTelecomType(val?.type);

export const isActivityDto = (val: any): val is ActivityDto => isPractitionerRole(val);

export function isRecipientOrgaManuallyCreated(entity: any): entity is Organization {
  return isOrganization(entity) && !!(entity as Organization).isManuallyCreated;
}

export function isDmpConnectorSettings(val: any): val is DmpConnectorSettings {
  return !!val?.documentTitle && !!val?.visibility;
}
