import { ExecuteDeferredAction } from '@store/actionDeferred/actionsDeferred.slice';
import { DeferredId } from '@store/actionDeferred/actionsDeferred.state';
import {
  BatchDeferredSuccessAction,
  BatchDownloadDocumentsFailureAction,
  OneDeferredSuccessAction,
  UpdateDocumentSenderSuccessAction,
} from '@store/documents/documents.actions';

/**
 * Unique identifier information messages
 * IMPORTANT!: This needs to be in sync with i18n translations info.message.{name}
 */
export enum InfoMessageType {
  documentSent = 'documentSent',
  documentsSent = 'documentsSent',
  sendDocumentCancelled = 'sendDocumentCancelled',
  sendDocumentsCancelled = 'sendDocumentsCancelled',
  sendDocumentPending = 'sendDocumentPending',
  sendDocumentsPending = 'sendDocumentsPending',
  downloadWithoutAttachment = 'downloadWithoutAttachment',
  updateDocumentSender = 'updateDocumentSender',
}

export interface InfoMessage {
  cancelable?: boolean;
  labelOptions?: Record<string, any>; // this causes a type error: TOptions | undefined;
  type: InfoMessageType;
  id: DeferredId;
}

export type InfoMessagesState = InfoMessage[];

export const INFO_MESSAGES_INITIAL_STATE: InfoMessagesState = []; // TODO: improvements: find a format to conserve sending order

export type ActionsDispatchingPendingInfoMessage = ExecuteDeferredAction;

export type ActionsDispatchingSuccessInfoMessage =
  | BatchDeferredSuccessAction
  | OneDeferredSuccessAction
  | BatchDownloadDocumentsFailureAction
  | UpdateDocumentSenderSuccessAction;
