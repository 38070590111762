export function camelize(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, '');
}

export function snakelize(str: string): string {
  return str?.replace(/(-|_|\.|\s)+(.)?/g, (_match: string, _sep: string, char: string): string =>
    char ? `_${char}` : '',
  );
}

export function snakelizeSkipDash(str: string): string {
  return str?.replace(/(_|\.|\s)+(.)?/g, (_match: string, _sep: string, char: string): string =>
    char ? `_${char}` : '',
  );
}

export function stripHtml(str: string): string {
  return str.replace(/<br\s*\/?>/gi, '\r\n').replace(/(<([^>]+)>)/gi, '');
}

export function titleCase(name = '') {
  const sentence = name.toLowerCase().split(' ');
  for (let i = 0; i < sentence.length; i += 1) {
    if (sentence[i][0]) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    } else {
      sentence[i] = '';
    }
  }
  return sentence.join(' ');
}

export function truncate(string: string, limit: number): string {
  if (string.length > limit) {
    return `${string.slice(0, limit)}...`;
  }
  return string;
}
