import { logger } from '@services/logger/logger.service';
import { all, put, takeLatest } from 'redux-saga/effects';

import { getNotifications as fetchNotifications } from '@api';

import { ErroredDocumentsActions } from '../documents/outgoing/errored.slice';
import { SentDocumentsActions } from '../documents/outgoing/sent.slice';

import {
  getNotifications,
  getNotificationsFailure,
  getNotificationsSuccess,
} from './notifications.action';
import { NotificationsState } from './notifications.state';

export function* getNotificationsAsync() {
  try {
    const response: NotificationsState = yield fetchNotifications();

    yield put(getNotificationsSuccess(response));
  } catch (error) {
    logger.error('LOGIC', 'Failed to fetch menu infos', error);
    yield put(getNotificationsFailure(error));
  }
}

export function* notificationsSagas() {
  yield all([takeLatest(SentDocumentsActions.fetchMany.type, getNotificationsAsync)]);
  yield all([takeLatest(ErroredDocumentsActions.fetchMany.type, getNotificationsAsync)]);
  yield all([takeLatest(ErroredDocumentsActions.markOneAsReadSuccess.type, getNotificationsAsync)]);
  yield all([
    takeLatest(ErroredDocumentsActions.markOneAsUnreadSuccess.type, getNotificationsAsync),
  ]);
  yield all([takeLatest(getNotifications.type, getNotificationsAsync)]);
}
