import { OneAction } from '@store/documents/documents.actions';
import { DocumentsState } from '@store/documents/documents.state';
import { WorklistIntegrationsState } from '@store/documents/integrations/worklist.state';
import { DraftDocumentsState } from '@store/documents/outgoing/draft.state';

export function delayedDocumentIdToRefresh<
  T extends DraftDocumentsState | WorklistIntegrationsState,
>(state: T, action: OneAction): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      delayedIdsToRefresh: state.dashboardView.delayedIdsToRefresh.concat(action.payload.id),
    },
  };
}

export function purgeDocumentIdsToRefetch<T extends DocumentsState>(state: T): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      delayedIdsToRefresh: [],
    },
  };
}
