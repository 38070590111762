import { delay, put, race, take, takeEvery } from 'redux-saga/effects';

import {
  ActionDeferredActions,
  AddDeferredAction,
  CancelDeferredAction,
  RemoveDeferredAction,
} from '../actionsDeferred.slice';

function* saga(action: AddDeferredAction) {
  const result: RaceResult = yield race({
    canceled: take(
      (raceAction: any) =>
        raceAction.type === ActionDeferredActions.cancel.type &&
        raceAction.payload.id === action.payload.id,
    ),
    removed: take(
      (raceAction: any) =>
        raceAction.type === ActionDeferredActions.remove.type &&
        raceAction.payload.id === action.payload.id,
    ),
    newAction: take(ActionDeferredActions.addOne.type), // TODO filter action type when other action than sending
    done: delay(action.payload.delay),
  });

  if (result.canceled) return;

  yield put(ActionDeferredActions.executeOne(action.payload));
}

export function* scheduleActionDeferred() {
  yield takeEvery(ActionDeferredActions.addOne.type, saga);
}

interface RaceResult {
  canceled: CancelDeferredAction;
  removed: RemoveDeferredAction;
  newAction: AddDeferredAction;
  done: boolean;
}
