import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import {
  DashboardSearchParams,
  FeatureFlags,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import { groupDocuments } from '@api';
import { DRAFT } from '@constants/documents.constants';
import {
  selectDashboardSearchParams,
  selectManyDocumentsFileStorageIds,
} from '@store/documents/documents.selector';
import { removeManyFromSelection } from '@store/selection/selection.action';
import { getFeatureFlags } from '@store/user/user.selector';
import { refreshDraftCacheAfterActionOnManyDocuments } from '@utils/caching.util';

import { BatchAction } from '../../documents.actions';
import { selectDraftCurrentIdentityReference } from '../draft.selector';
import { DraftDocumentsActions } from '../draft.slice';

function* groupManyDocumentsSaga({ payload: { ids } }: BatchAction) {
  const queryClient: QueryClient = yield getContext('queryClient');
  const featureFlags: FeatureFlags = yield select(getFeatureFlags);
  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, ids, DRAFT);
  const currentIdentity: string | undefined = yield select((state) =>
    selectDraftCurrentIdentityReference(state),
  );
  try {
    yield put(DraftDocumentsActions.updateLoadingDocumentIds({ ids }));
    yield call(groupDocuments, ids);
    yield put(removeManyFromSelection({ dashboard: SendingsDashboardType.Draft, ids }));
    yield put(DraftDocumentsActions.groupManySuccess({ ids, fileStorageIds }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to group batch of Document', error);
    yield put(
      DraftDocumentsActions.groupManyFailure({
        ids,
        error,
      }),
    );
  } finally {
    const searchParams: DashboardSearchParams = yield select((state) =>
      selectDashboardSearchParams(state, DRAFT),
    );
    refreshDraftCacheAfterActionOnManyDocuments({
      documentIds: ids,
      currentIdentity,
      search: { searchParams, featureFlags },
      queryClient,
    });
    // Fetch documents draft/readyToSend counters
    yield put(DraftDocumentsActions.fetchDraftCounters);
    yield put(DraftDocumentsActions.fetchTotalReadyToSend);
    yield put(DraftDocumentsActions.flushLoadingDocumentIds());
  }
}

export function* groupDraftDocumentsSagas() {
  yield takeLatest(DraftDocumentsActions.groupMany.type, groupManyDocumentsSaga);
}
