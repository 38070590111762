import {
  SendingRequest,
  SendingRequestDto,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import { SENT } from '@constants/documents.constants';
import { XhrStatus } from '@store/types';

import {
  BaseDashboardViewState,
  BaseDetailViewState,
  BaseEntitiesState,
  DownloadRequestState,
  INITIAL_DASHBOARD_VIEW_STATE,
  INITIAL_DETAIL_VIEW_STATE,
  INITIAL_DOWNLOAD_STATE,
  INITIAL_SETTINGS_OVERRIDES_VIEW_STATE,
  SettingsOverridesState,
  XHR_STATUS_IDLE,
} from '../documents.state';

export interface SentDetailViewState extends BaseDetailViewState {
  replaceDmpFileStorageId?: string;
  replaceDmpFileName?: string;
}
export interface SentDocumentsState {
  entities: BaseEntitiesState<SentDocumentDtoWithMeta>;
  dashboardView: BaseDashboardViewState & DownloadRequestState & SettingsOverridesState;
  detailView: SentDetailViewState;
}

export const INITIAL_SENT_DOCUMENTS_STATE: SentDocumentsState = {
  entities: {},
  dashboardView: {
    ...INITIAL_DASHBOARD_VIEW_STATE,
    searchParams: {
      ...INITIAL_DASHBOARD_VIEW_STATE.searchParams,
      defaultDateRange: true,
      statuses: ['completed'],
      sortBy: 'authored_on',
      includeEhrFallback: true,
      includeIntegration: true,
      includeConnectors: true,
      includeDeliveryStatuses: true,
    },
    downloadRequest: INITIAL_DOWNLOAD_STATE,
    settingsOverrides: INITIAL_SETTINGS_OVERRIDES_VIEW_STATE,
  },
  detailView: {
    ...INITIAL_DETAIL_VIEW_STATE,
    replaceDmpFileStorageId: '',
    replaceDmpFileName: '',
  },
};

export type SentDocumentDtoWithMeta = {
  resource: SendingRequestDto;
  meta: SentDocumentMetadata;
};

export type SentSendingRequestWithMeta = {
  resource: SendingRequest;
  meta: SentDocumentMetadata;
};

export enum SentDocumentMetadataKey {
  fetchAttachmentsRequest = 'fetchAttachmentsRequest',
  forwardRequest = 'forwardRequest',
  removeDmpRequest = 'removeDmpRequest',
  replaceDmpRequest = 'replaceDmpRequest',
  replaceMssRequest = 'replaceMssRequest',
  fileStorageIds = 'fileStorageIds',
  docDashboardType = 'docDashboardType',
}

export interface SentDocumentMetadata {
  [SentDocumentMetadataKey.fetchAttachmentsRequest]: XhrStatus;
  [SentDocumentMetadataKey.fileStorageIds]: string[] | undefined;
  [SentDocumentMetadataKey.forwardRequest]: XhrStatus;
  [SentDocumentMetadataKey.removeDmpRequest]: XhrStatus;
  [SentDocumentMetadataKey.replaceDmpRequest]: XhrStatus;
  [SentDocumentMetadataKey.replaceMssRequest]: XhrStatus;
  [SentDocumentMetadataKey.docDashboardType]: SendingsDashboardType.Sent;
}

export type SentDocumentMetadataType = keyof SentDocumentMetadata;

export const INITIAL_SENT_DOCUMENT_METADATA: SentDocumentMetadata = {
  [SentDocumentMetadataKey.fetchAttachmentsRequest]: XHR_STATUS_IDLE,
  [SentDocumentMetadataKey.fileStorageIds]: undefined,
  [SentDocumentMetadataKey.forwardRequest]: XHR_STATUS_IDLE,
  [SentDocumentMetadataKey.removeDmpRequest]: XHR_STATUS_IDLE,
  [SentDocumentMetadataKey.replaceDmpRequest]: XHR_STATUS_IDLE,
  [SentDocumentMetadataKey.replaceMssRequest]: XHR_STATUS_IDLE,
  [SentDocumentMetadataKey.docDashboardType]: SENT,
};
