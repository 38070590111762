import { useMemo } from 'react';

import { useCustomNavigate } from '@launcher/hooks/useCustomNavigate.hook';
import { isDesktopApp, openSettingsLink } from '@launcher/utils';
import { EnvManager } from '@services/environment/environment.service';
import { useTranslation } from 'react-i18next';

import { MainRouteUrls } from '@honestica/core-apps-common/types';
import { getUserInitials, isLifenInternalEmail } from '@honestica/core-apps-common/utils';
import { Avatar, Sidebar, Space, colors } from '@honestica/ui-kit/src';

import { useModal } from '@hooks/useModal.hook';
import { ModalType } from '@store/modal/modal.type';

import { AccountSettingsReduxProps, accountSettingsConnector } from './AccountSettings.connector';

import styles from './AccountSettings.module.less';

const AccountSettingsComponent = ({ logout, user }: AccountSettingsReduxProps) => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const { openModal } = useModal();

  const isQA = useMemo(
    (): boolean =>
      isLifenInternalEmail(user.email) &&
      ['staging', 'dev', 'review'].includes(EnvManager.getName() ?? ''),
    [user.email],
  );

  const userInitials = useMemo(() => getUserInitials(user), [user]);

  return (
    <Sidebar.Menu
      key="sub1"
      iconName="Cog1"
      data-testid="settings-menu"
      header={
        <Space className={styles.userEmail}>
          <Avatar background={colors.primary[5]} color={colors.white[0]}>
            {userInitials}
          </Avatar>
          <strong>{user.email}</strong>
        </Space>
      }
    >
      <Sidebar.Menu.Item
        key="profile"
        onClick={() => openSettingsLink({ url: EnvManager.getBaseUrlUm() || '' })}
      >
        {t('settings.parameters.profile')}
      </Sidebar.Menu.Item>
      {isDesktopApp() && (
        <Sidebar.Menu.Item
          key="desktopSettings"
          onClick={() => navigate(`/${MainRouteUrls.DesktopSettings}`)}
        >
          {t('settings.desktop')}
        </Sidebar.Menu.Item>
      )}
      {isQA ? (
        <Sidebar.Menu.Item key="qa" onClick={() => openModal({ modal: ModalType.settingsQA })}>
          {t('settings.parameters.qa.title')}
        </Sidebar.Menu.Item>
      ) : null}
      <Sidebar.Menu.Item key="logout" onClick={() => logout()}>
        {t('logout')}
      </Sidebar.Menu.Item>
      {isDesktopApp() && (
        <Sidebar.Menu.Item key="exit" onClick={() => window.electron.exitApp()}>
          {t('exit')}
        </Sidebar.Menu.Item>
      )}
    </Sidebar.Menu>
  );
};

export const AccountSettings = accountSettingsConnector(AccountSettingsComponent);
