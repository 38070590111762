import { all, put, takeLatest } from 'redux-saga/effects';

import { isDefined, isEmptyObj } from '@honestica/core-apps-common/utils';

import { DesktopActions } from '@store/desktop/desktop.action';
import {
  SetDesktopSettingsAction,
  UpdateDesktopSettingsAction,
} from '@store/desktop/desktop.reducer';

import { DashboardSettingsOverride } from '../../documents.state';
import { DraftDocumentsActions } from '../draft.slice';
import { SentDocumentsActions } from '../sent.slice';

function* setSettingsSaga(action: SetDesktopSettingsAction) {
  const overrides: DashboardSettingsOverride = {
    myRequestOnly: action.payload.value.myRequestsOnly,
  };

  yield all([
    put(DraftDocumentsActions.setOverrides(overrides)),
    put(SentDocumentsActions.setOverrides(overrides)),
  ]);
}

function* updateSettingsSaga(action: UpdateDesktopSettingsAction) {
  const overrides: Partial<DashboardSettingsOverride> = {};

  if (isDefined(action.payload.myRequestsOnly)) {
    overrides.myRequestOnly = action.payload.myRequestsOnly;
  }

  if (!isEmptyObj(overrides)) {
    yield all([
      put(DraftDocumentsActions.updateOverrides(overrides)),
      put(SentDocumentsActions.updateOverrides(overrides)),
    ]);
  }
}

export function* setDashboardSettingsSagas() {
  yield all([
    takeLatest(DesktopActions.setSettings.type, setSettingsSaga),
    takeLatest(DesktopActions.updateSettings.type, updateSettingsSaga),
  ]);
}
