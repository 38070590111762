import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { debounce } from '@launcher/utils/debounce.utils';

export enum Breakpoint {
  S = 500,
  M = 800,
  L = 1000,
  XL = 1200,
}

type ViewportContextType = {
  height: number;
  width: number;
};

export const ViewportContext = createContext<ViewportContextType>({
  height: window.innerHeight,
  width: window.innerWidth,
});

interface Props {
  children?: ReactNode;
}

export function ViewportProvider(props: Props) {
  const [viewport, setViewport] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const onResize = debounce(() => {
      setViewport({ width: window.innerWidth, height: window.innerHeight });
    }, 50);

    const debouncedResize = onResize();
    window.addEventListener('resize', onResize);

    return () => {
      clearTimeout(debouncedResize);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return <ViewportContext.Provider value={viewport}>{props.children}</ViewportContext.Provider>;
}

export function useViewportLargerThan(breakpoint: Breakpoint) {
  const ctx = useContext(ViewportContext);
  return ctx.width > breakpoint;
}

export function useViewportSmallerThan(breakpoint: Breakpoint) {
  const ctx = useContext(ViewportContext);
  return ctx.width < breakpoint;
}
