import {
  SendingRequest,
  SendingRequestDto,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import { INBOX } from '@constants/documents.constants';
import { XhrStatus } from '@store/types';

import {
  BaseDashboardViewState,
  BaseDetailViewState,
  BaseEntitiesState,
  DownloadRequestState,
  INITIAL_DASHBOARD_VIEW_STATE,
  INITIAL_DETAIL_VIEW_STATE,
  INITIAL_DOWNLOAD_STATE,
  XHR_STATUS_IDLE,
} from '../documents.state';

export type InboxDetailViewState = BaseDetailViewState;
export interface InboxDocumentsState {
  entities: BaseEntitiesState<InboxDocumentDtoWithMeta>;
  dashboardView: BaseDashboardViewState & DownloadRequestState;
  detailView: InboxDetailViewState;
}

export const INITIAL_INBOX_DOCUMENTS_STATE: InboxDocumentsState = {
  entities: {},
  dashboardView: {
    ...INITIAL_DASHBOARD_VIEW_STATE,
    searchParams: { ...INITIAL_DASHBOARD_VIEW_STATE.searchParams, defaultDateRange: true },
    downloadRequest: INITIAL_DOWNLOAD_STATE,
  },
  detailView: INITIAL_DETAIL_VIEW_STATE,
};

export type InboxDocumentDtoWithMeta = {
  resource: SendingRequestDto;
  meta: InboxDocumentMetadata;
};

export type InboxSendingRequestWithMeta = {
  resource: SendingRequest;
  meta: InboxDocumentMetadata;
};

export enum InboxDocumentMetadataKey {
  archiveRequest = 'archiveRequest',
  closeEnsConversation = 'closeEnsConversation',
  fetchAttachmentsRequest = 'fetchAttachmentsRequest',
  fileStorageIds = 'fileStorageIds',
  forwardRequest = 'forwardRequest',
  docDashboardType = 'docDashboardType',
}
export interface InboxDocumentMetadata {
  // detail
  [InboxDocumentMetadataKey.closeEnsConversation]: XhrStatus;
  [InboxDocumentMetadataKey.fetchAttachmentsRequest]: XhrStatus;
  [InboxDocumentMetadataKey.fileStorageIds]: string[] | undefined;
  [InboxDocumentMetadataKey.forwardRequest]: XhrStatus;
  // common
  [InboxDocumentMetadataKey.archiveRequest]: XhrStatus;
  [InboxDocumentMetadataKey.docDashboardType]: SendingsDashboardType.Inbox;
}

export type InboxDocumentMetadataType = keyof InboxDocumentMetadata;

export const INITIAL_INBOX_DOCUMENT_METADATA: InboxDocumentMetadata = {
  // detail
  [InboxDocumentMetadataKey.closeEnsConversation]: XHR_STATUS_IDLE,
  [InboxDocumentMetadataKey.fetchAttachmentsRequest]: XHR_STATUS_IDLE,
  [InboxDocumentMetadataKey.fileStorageIds]: undefined,
  [InboxDocumentMetadataKey.forwardRequest]: XHR_STATUS_IDLE,
  // common
  [InboxDocumentMetadataKey.archiveRequest]: XHR_STATUS_IDLE,
  [InboxDocumentMetadataKey.docDashboardType]: INBOX,
};
