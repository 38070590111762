import { createReducer } from '@reduxjs/toolkit';

import {
  findPractitioner,
  findPractitionerFailure as findPractitionerFailureAction,
  findPractitionerSuccess as findPractitionerSuccessAction,
} from './practitionerDetail.action';

export interface PractitionerDetailState {
  loading: boolean;
  error: boolean;
}

const INITIAL_STATE: PractitionerDetailState = {
  loading: false,
  error: false,
};

function loading(state: PractitionerDetailState, status: boolean) {
  return {
    ...state,
    loading: status,
  };
}

function findPractitionerSuccess(state: PractitionerDetailState) {
  return {
    ...state,
    loading: false,
    error: false,
  };
}

function findPractitionerFailure(state: PractitionerDetailState) {
  return {
    ...state,
    loading: false,
    error: true,
  };
}

export const practitionerDetailReducer = createReducer(INITIAL_STATE, {
  [findPractitioner.type]: (state) => loading(state, true),
  [findPractitionerSuccessAction.type]: findPractitionerSuccess,
  [findPractitionerFailureAction.type]: findPractitionerFailure,
});
