export function keyBy<T extends { [key: string]: any }, K extends keyof T>(
  resources: T[],
  key: K,
): { [key: string]: T } {
  return resources.reduce((accumulator: { [key: string]: T }, resource: T) => {
    if (!resource[key]) {
      return accumulator;
    }
    accumulator[resource[key]] = resource;
    return accumulator;
  }, {});
}
