import { InfoMessageType } from '../infoMessages.state';

export const infoMessageToCancelledMessage: Record<InfoMessageType, InfoMessageType | null> = {
  [InfoMessageType.documentSent]: null,
  [InfoMessageType.documentsSent]: null,
  [InfoMessageType.sendDocumentCancelled]: null,
  [InfoMessageType.sendDocumentPending]: InfoMessageType.sendDocumentCancelled,
  [InfoMessageType.sendDocumentsCancelled]: null,
  [InfoMessageType.sendDocumentsPending]: InfoMessageType.sendDocumentsCancelled,
  [InfoMessageType.downloadWithoutAttachment]: null,
  [InfoMessageType.updateDocumentSender]: null,
};
