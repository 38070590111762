import { addVirtualPrinterForMac, trayMenu } from '@launcher/helpers/desktop';
import { DesktopSettings, isDesktopApp } from '@launcher/utils';
import { isUsingNewBridgeAPI } from '@launcher/utils/desktop';
import { logger } from '@services/logger/logger.service';
import { notificationWindow } from '@services/notificationWindow/notificationWindow.service';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { FeatureFlags } from '@honestica/core-apps-common/types';

import { DesktopActions } from '@store/desktop/desktop.action';
import { selectDesktopSettings } from '@store/desktop/desktop.selector';
import { initFeatureFlags } from '@store/user/user.actions';
import { getFeatureFlags, getIsLifenSendingActivated } from '@store/user/user.selector';

function* initDesktop() {
  logger.info('DESKTOP', 'initDesktop');
  const isLifenSendingActivated: boolean = yield select(getIsLifenSendingActivated);
  const featureFlags: FeatureFlags = yield select(getFeatureFlags);

  if (isDesktopApp()) {
    logger.info('DESKTOP', 'initDesktop - Starting desktop init...');

    if (featureFlags['cafe-maintenance']) {
      logger.info('DESKTOP', 'initDesktop - Maintenance mode, closing watchers...');
      put(DesktopActions.closeWatchers());
      return;
    }

    if (!isLifenSendingActivated) {
      logger.info('DESKTOP', 'initDesktop - No Lifen app activated, closing watchers...');
      put(DesktopActions.closeWatchers());
      return;
    }

    try {
      if (!isUsingNewBridgeAPI(window)) {
        // only for < 100.14.18
        yield trayMenu.init();
      }

      const retrievedSettings: DesktopSettings = yield select(selectDesktopSettings);

      yield addVirtualPrinterForMac(retrievedSettings);

      // Make settings available to the store
      // and trigger watcher startup
      yield put(DesktopActions.initWatchers(retrievedSettings));

      yield notificationWindow.init();

      yield put(DesktopActions.setDesktopIsReady());
      logger.info('DESKTOP', 'initDesktop - Completed');
    } catch (error) {
      logger.error('DESKTOP', 'initDesktop - Failed', error);
    }
  }
}

export function* initDesktopSagas() {
  yield all([takeLatest(initFeatureFlags.type, initDesktop)]);
  yield all([takeLatest(DesktopActions.initDesktop.type, initDesktop)]);
}
