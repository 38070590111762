import { PayloadAction, createReducer } from '@reduxjs/toolkit';

import { ProfessionalBaseType, Telecom } from '@honestica/core-apps-common/types';

import {
  addContactPreference as addContactPreferenceAction,
  addContactPreferenceFailure as addContactPreferenceFailureAction,
  addContactPreferenceSuccess as addContactPreferenceSuccessAction,
  removeContactPreference as removeContactPreferenceAction,
  removeContactPreferenceFailure as removeContactPreferenceFailureAction,
  removeContactPreferenceSuccess as removeContactPreferenceSuccessAction,
} from './contactPreferences.action';

export interface ContactPreferencesState {
  isAdding: boolean;
  isRemoving: Record<string, boolean>;
  error: Record<string, boolean>;
}

const INITIAL_STATE: ContactPreferencesState = {
  isAdding: false,
  isRemoving: {},
  error: {},
};

function addContactPreference(
  state: ContactPreferencesState,
  action: PayloadAction<{
    data: Telecom | 'postal';
    resourceId: string;
    resourceType: ProfessionalBaseType;
  }>,
) {
  const { data, resourceId, resourceType } = action.payload;

  if (!data || !resourceId || !resourceType) {
    return state;
  }

  return { ...state, isAdding: true, error: {} };
}

function addContactPreferenceSuccess(
  state: ContactPreferencesState,
  action: PayloadAction<{
    data: Telecom | 'postal';
    resourceId: string;
    resourceType: ProfessionalBaseType;
  }>,
) {
  const { data, resourceId, resourceType } = action.payload;

  if (!data || !resourceId || !resourceType) {
    return state;
  }

  return { ...state, isAdding: false, error: {} };
}

function addContactPreferenceFailure(
  state: ContactPreferencesState,
  action: PayloadAction<{
    data: Telecom | 'postal';
    resourceId: string;
    resourceType: ProfessionalBaseType;
  }>,
) {
  const { data, resourceId, resourceType } = action.payload;

  if (!data || !resourceId || !resourceType) {
    return state;
  }

  return { ...state, isAdding: false, error: { adding: true } };
}

function removeContactPreference(
  state: ContactPreferencesState,
  action: PayloadAction<{
    data: Telecom | 'postal';
    resourceId: string;
    resourceType: ProfessionalBaseType;
  }>,
) {
  const { data, resourceId, resourceType } = action.payload;

  if (!data || !resourceId || !resourceType) {
    return state;
  }

  const key = data === 'postal' ? 'postal' : data.id ?? '';

  return { ...state, error: {}, isRemoving: { ...state.isRemoving, [key]: true } };
}

function removeContactPreferenceSuccess(
  state: ContactPreferencesState,
  action: PayloadAction<{
    data: Telecom | 'postal';
    resourceId: string;
    resourceType: ProfessionalBaseType;
  }>,
) {
  const { data, resourceId, resourceType } = action.payload;

  if (!data || !resourceId || !resourceType) {
    return state;
  }

  const key = data === 'postal' ? 'postal' : data.id ?? '';

  return { ...state, error: {}, isRemoving: { ...state.isRemoving, [key]: false } };
}

function removeContactPreferenceFailure(
  state: ContactPreferencesState,
  action: PayloadAction<{
    data: Telecom | 'postal';
    resourceId: string;
    resourceType: ProfessionalBaseType;
  }>,
) {
  const { data, resourceId, resourceType } = action.payload;

  if (!data || !resourceId || !resourceType) {
    return state;
  }

  const key = data === 'postal' ? 'postal' : data.id ?? '';

  return {
    ...state,
    isRemoving: { ...state.isRemoving, [key]: false },
    error: { ...state.error, [key]: true },
  };
}

export const contactPreferencesReducer = createReducer(INITIAL_STATE, {
  [addContactPreferenceAction.type]: addContactPreference,
  [addContactPreferenceSuccessAction.type]: addContactPreferenceSuccess,
  [addContactPreferenceFailureAction.type]: addContactPreferenceFailure,
  [removeContactPreferenceAction.type]: removeContactPreference,
  [removeContactPreferenceSuccessAction.type]: removeContactPreferenceSuccess,
  [removeContactPreferenceFailureAction.type]: removeContactPreferenceFailure,
});
