import { InfoMessagesReduxProps, infoMessagesConnector } from './InfoMessages.connector';
import { ActionAlert } from './components/ActionAlert/ActionAlert.component';

import styles from './InfoMessages.module.less';

function InfoMessagesComponent(props: InfoMessagesReduxProps) {
  if (props.hide || props.messages.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.root} data-testid="info-messages">
      {props.messages.map((message) => (
        <ActionAlert
          key={message.type}
          message={message}
          onCancel={() => props.cancel({ type: message.type, id: message.id })}
          onRemove={() => props.remove({ type: message.type, id: message.id })}
        />
      ))}
    </div>
  );
}

export const InfoMessages = infoMessagesConnector(InfoMessagesComponent);
