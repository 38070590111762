import { SENDER_IDENTITY_REFERENCES_LOCALSTORAGE_KEY } from '@launcher/constants';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { authClient } from '@services/authentication/authentication.service';
import { HttpError } from '@services/http/http.error.service';
import { logger } from '@services/logger/logger.service';
import { all, put, takeLatest } from 'redux-saga/effects';

import { ApiErrorsKey } from '@honestica/core-apps-common/constants';
import { ErrorPayload } from '@honestica/core-apps-common/types';
import { getProfessionalAsReference } from '@honestica/core-apps-common/utils';
import { isErrorPayload } from '@honestica/core-apps-common/validators';

import {
  getExistingSenderIdentitiesFromLS,
  getSelectedIdentities,
  getSenderIdentitiesFromLS,
} from '@utils/identities.util';

import {
  FetchIdentitiesSuccess,
  Logout,
  SwitchWorkspace,
  authenticateUserFailure,
  fetchIdentitiesSuccess,
  initSelectedIdentities,
  logout,
  setSelectedIdentity,
  switchWorkspace,
} from './user.actions';
import { AuthenticationError } from './user.state';

function* handleLogout({ payload: logoutOptions }: ReturnType<Logout>) {
  try {
    yield authClient.logout(logoutOptions);
  } catch (error) {
    logger.error('LOGIC', 'Failed to log user out', { error, logoutOptions });
  }
}

export function* switchWorkspaceSaga({ payload: workspaceId }: ReturnType<SwitchWorkspace>) {
  logger.info('LOGIC', `Switching to workspace: ${workspaceId}`);
  try {
    yield authClient.switchWorkspace(workspaceId);
  } catch (e) {
    logger.error('LOGIC', `Failed to switch to workspace ${workspaceId}`, e);
  }
}

function* initCurrentSender({ payload: identities }: ReturnType<FetchIdentitiesSuccess>) {
  const exisitingSenderIdentities = getExistingSenderIdentitiesFromLS(identities);
  const selectedIdentities = getSelectedIdentities(identities, exisitingSenderIdentities);

  yield put(
    initSelectedIdentities({
      selectedIdentities,
    }),
  );

  if (
    !exisitingSenderIdentities.existingSenderIdentityLifenSending ||
    !exisitingSenderIdentities.existingSenderIdentityLifenIntegration
  ) {
    const selectedIdentitiesForLS = JSON.stringify({
      lifenSending: selectedIdentities.lifenSending
        ? getProfessionalAsReference(selectedIdentities.lifenSending)
        : undefined,
      lifenIntegration: selectedIdentities.lifenIntegration
        ? getProfessionalAsReference(selectedIdentities.lifenIntegration)
        : undefined,
    });

    yield localStorage.setItem(
      SENDER_IDENTITY_REFERENCES_LOCALSTORAGE_KEY,
      selectedIdentitiesForLS,
    );
  }
}

function* handleSetCurrentSender({
  payload: { identity },
}: ReturnType<typeof setSelectedIdentity>) {
  /**
   * We persist the new currentSender in localSettings for Lifen Sending
   */
  const senderIdentities = getSenderIdentitiesFromLS();

  yield localStorage.setItem(
    SENDER_IDENTITY_REFERENCES_LOCALSTORAGE_KEY,
    JSON.stringify({
      lifenSending: getProfessionalAsReference(identity),
      lifenIntegration: senderIdentities?.lifenIntegration || undefined,
    }),
  );
}

// TODO: https://honestica.atlassian.net/browse/CORE-4222 refacto error action to always have error inside payload (extending ErrorPayload)
function* handleExpiredSession({ payload }: PayloadAction<ErrorPayload | undefined | unknown>) {
  let error: HttpError | undefined;

  if (payload instanceof HttpError) error = payload;
  if (isErrorPayload(payload) && payload?.error instanceof HttpError) error = payload.error;

  if (
    error?.statusCode === 401 ||
    error?.key === ApiErrorsKey.INVALID_MACHINE_TOKEN ||
    error?.key === ApiErrorsKey.IDENTITIES_FETCH_FAILED
  ) {
    yield put(authenticateUserFailure(AuthenticationError.ExpiredSession));
  }

  if (
    error?.key === ApiErrorsKey.USER_INVALID_IDENTITIES ||
    error?.key === ApiErrorsKey.USER_IDENTITY_NOT_FOUND ||
    error?.key === ApiErrorsKey.USER_INVALID_API_DOMAIN ||
    error?.key === ApiErrorsKey.WORKSPACE_ID_INVALID ||
    error?.key === ApiErrorsKey.USER_INVALID_ID ||
    error?.key === ApiErrorsKey.USER_INVALID_TOKEN
  ) {
    yield put(authenticateUserFailure(AuthenticationError.MisconfiguredAccount));
  }
}

export function* userSagas() {
  yield all([takeLatest((action: Action) => /Failure$/.test(action.type), handleExpiredSession)]);
  yield all([takeLatest(fetchIdentitiesSuccess.type, initCurrentSender)]);
  yield all([takeLatest(logout.type, handleLogout)]);
  yield all([takeLatest(setSelectedIdentity.type, handleSetCurrentSender)]);
  yield all([takeLatest(switchWorkspace.type, switchWorkspaceSaga)]);
}
