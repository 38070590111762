import { ComponentProps } from 'react';

import { Card as AntCard } from 'antd';
import cx from 'classnames';

import styles from './Card.module.less';

interface CardProps extends ComponentProps<typeof AntCard> {
  shadow?: boolean;
  hover?: boolean;
}

const Card = ({ children, shadow = false, hover = false, ...otherProps }: CardProps) => (
  <AntCard className={cx({ [styles.shadow]: shadow, [styles.hover]: hover })} {...otherProps}>
    {children}
  </AntCard>
);

Card.Meta = AntCard.Meta;
Card.Grid = AntCard.Grid;

export { Card };
