import { Centered } from './Centered.component';
import { Column } from './Column.component';
import { Empty } from './Empty.component';
import { Full } from './Full.component';

const Page = {
  Centered,
  Column,
  Empty,
  Full,
};

export default Page;
