import { http } from '@services/http/http.service';

import { ProfessionalBaseType, SearchParams } from '@honestica/core-apps-common/types';

const PROFESSIONALS_PATH = '/professionals';

export async function searchProfessionals(searchParams: SearchParams) {
  return await http.get({ path: PROFESSIONALS_PATH, searchParams });
}

export async function addContactPreference(params: {
  resourceId: string;
  category: 'telecom' | 'address';
  value: string;
  resourceType: ProfessionalBaseType;
}) {
  const path = params.resourceType === 'Practitioner' ? '/practitioners' : '/organizations';
  return await http.post({
    path: `${path}/${params.resourceId}/contact_preference`,
    body: { category: params.category, value: params.value },
  });
}

export async function removeContactPreference(params: {
  resourceId: string;
  category: 'telecom' | 'address';
  value: string;
  resourceType: ProfessionalBaseType;
}) {
  const path = params.resourceType === 'Practitioner' ? '/practitioners' : '/organizations';
  return await http.destroyWithBody({
    path: `${path}/${params.resourceId}/contact_preference`,
    body: { category: params.category, value: params.value },
  });
}
