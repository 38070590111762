import {
  AppHomeRouteUrls,
  AppKeys,
  CoreApp,
  MainRouteUrls,
} from '@honestica/core-apps-common/types';

export const APP_HOME_URLS: Record<AppKeys, AppHomeRouteUrls> = {
  [AppKeys.Documents]: AppHomeRouteUrls.Documents,
  [AppKeys.Integrations]: AppHomeRouteUrls.Integrations,
};

export const APP_URLS: Record<AppKeys, MainRouteUrls> = {
  [AppKeys.Documents]: MainRouteUrls.Documents,
  [AppKeys.Integrations]: MainRouteUrls.Integrations,
};

const coreAppBase = {
  isAppLauncherCompatible: true,
  displayInStore: false,
  isFirstParty: true,
  url: '',
};

export const CORE_APPS_INFO: Record<AppKeys, CoreApp> = {
  [AppKeys.Documents]: {
    ...coreAppBase,
    id: '4',
    name: AppKeys.Documents,
    iconUrl: 'MedicalFile',
    storeIcon: 'MessagingAppTitleIcon',
    displayName: 'documents.title',
    path: MainRouteUrls.Documents,
  },
  [AppKeys.Integrations]: {
    ...coreAppBase,
    id: '5',
    name: AppKeys.Integrations,
    iconUrl: 'MoveInbox',
    storeIcon: 'IntegrationsAppTitleIcon',
    displayName: 'integrations.title',
    path: MainRouteUrls.Integrations,
  },
};

export enum AppError {
  InvalidEnvVariables = 'INVALID_ENV_VARIABLES',
  LoadingDesktopSettingsFailure = 'LOADING_DESKTOP_SETTINGS_FAILURE',
  Maintenance = 'MAINTENANCE',
}

export const BASENAME = '/apps';
