import {
  DashboardSearchParams,
  IntegrationRequest,
  IntegrationRequestDto,
  IntegrationsDashboardType,
  SendingRequest,
  SendingRequestDto,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';

import { XhrStatus } from '@store/types';

import { ArchivedDocumentsActions } from './incoming/archived.slice';
import {
  ArchivedDetailViewState,
  ArchivedDocumentDtoWithMeta,
  ArchivedDocumentMetadata,
  ArchivedDocumentMetadataType,
  ArchivedDocumentsState,
  ArchivedSendingRequestWithMeta,
} from './incoming/archived.state';
import { InboxDocumentsActions } from './incoming/inbox.slice';
import {
  InboxDetailViewState,
  InboxDocumentDtoWithMeta,
  InboxDocumentMetadata,
  InboxDocumentMetadataType,
  InboxDocumentsState,
  InboxSendingRequestWithMeta,
} from './incoming/inbox.state';
import { IntegratedIntegrationsActions } from './integrations/integrated.slice';
import {
  IntegratedDetailViewState,
  IntegratedIntegrationRequestDtoWithMeta,
  IntegratedIntegrationRequestMetadata,
  IntegratedIntegrationRequestMetadataType,
  IntegratedIntegrationRequestWithMeta,
  IntegratedIntegrationsState,
} from './integrations/integrated.state';
import { WorklistIntegrationsActions } from './integrations/worklist.slice';
import {
  WorklistDetailViewState,
  WorklistIntegrationRequestDtoWithMeta,
  WorklistIntegrationRequestMetadata,
  WorklistIntegrationRequestMetadataType,
  WorklistIntegrationRequestWithMeta,
  WorklistIntegrationsState,
} from './integrations/worklist.state';
import { DraftDocumentsActions } from './outgoing/draft.slice';
import {
  DraftDetailViewState,
  DraftDocumentDtoWithMeta,
  DraftDocumentMetadata,
  DraftDocumentMetadataType,
  DraftDocumentsState,
  DraftSendingRequestWithMeta,
} from './outgoing/draft.state';
import { ErroredDocumentsActions } from './outgoing/errored.slice';
import {
  ErroredDocumentDashboardType,
  ErroredDocumentDtoWithMeta,
  ErroredDocumentMetadata,
  ErroredDocumentMetadataType,
  ErroredDocumentsState,
  ErroredSendingRequestWithMeta,
} from './outgoing/errored.state';
import { SentDocumentsActions } from './outgoing/sent.slice';
import {
  SentDetailViewState,
  SentDocumentDtoWithMeta,
  SentDocumentMetadata,
  SentDocumentMetadataType,
  SentDocumentsState,
  SentSendingRequestWithMeta,
} from './outgoing/sent.state';

/*
 * XHR
 */

export const XHR_STATUS_IDLE: XhrStatus = { isLoading: false, isInError: false };
export const XHR_STATUS_LOADING: XhrStatus = { isLoading: true, isInError: false };
export const XHR_STATUS_ERROR: XhrStatus = { isLoading: false, isInError: true };

/*
 * ENTITIES
 */

export type BaseEntitiesState<T> = Record<string, T>;

/*
 * DASHBOARD
 */

export interface BaseDashboardViewState {
  ids: string[];
  loadingDocumentIds: string[];
  searchParams: DashboardSearchParams;
  total: number;
  documentsRequest: XhrStatus;
}

export const INITIAL_SEARCH_PARAMS: DashboardSearchParams = {
  page: 1,
  perPage: 20,
};

export const INITIAL_DASHBOARD_VIEW_STATE: BaseDashboardViewState = {
  ids: [],
  loadingDocumentIds: [],
  searchParams: INITIAL_SEARCH_PARAMS,
  total: 0,
  documentsRequest: XHR_STATUS_IDLE,
};

/*
 * DETAIL
 */

export interface BaseDetailViewState {
  selectedId: string | undefined;
  documentRequest: XhrStatus;
  documentDto: SendingRequestDto | undefined;
}

export type DetailViewState =
  | InboxDetailViewState
  | ArchivedDetailViewState
  | DraftDetailViewState
  | SentDetailViewState
  | WorklistDetailViewState
  | IntegratedDetailViewState;

export const INITIAL_DETAIL_VIEW_STATE: BaseDetailViewState = {
  selectedId: undefined,
  documentRequest: XHR_STATUS_IDLE,
  documentDto: undefined,
};

/*
 * PROPS
 */

export interface DashboardSettingsOverride {
  myRequestOnly?: boolean;
}
export interface SettingsOverridesState {
  settingsOverrides: DashboardSettingsOverride;
}
export interface DelayedDocumentIdsToRefreshState {
  delayedIdsToRefresh: string[];
}

export interface LoadingDocumentIds {
  loadingDocumentIds: string[];
}

export const INITIAL_SETTINGS_OVERRIDES_VIEW_STATE: DashboardSettingsOverride = {
  myRequestOnly: false,
};
export interface DownloadRequestState {
  downloadRequest: XhrStatus;
}
export const INITIAL_DOWNLOAD_STATE: XhrStatus = XHR_STATUS_IDLE;

/*
 * TYPES
 */

export type DocumentsState =
  | InboxDocumentsState
  | ArchivedDocumentsState
  | DraftDocumentsState
  | SentDocumentsState
  | ErroredDocumentsState
  | WorklistIntegrationsState
  | IntegratedIntegrationsState;

export type DocumentsStateWithDetailView = Exclude<DocumentsState, ErroredDocumentsState>; // find a better way?

export type DocumentsStateFromType<T> = T extends SendingsDashboardType.Inbox
  ? InboxDocumentsState
  : T extends SendingsDashboardType.Archive
  ? ArchivedDocumentsState
  : T extends SendingsDashboardType.Draft
  ? DraftDocumentsState
  : T extends SendingsDashboardType.Sent
  ? SentDocumentsState
  : T extends IntegrationsDashboardType.Worklist
  ? WorklistIntegrationsState
  : T extends IntegrationsDashboardType.Integrated
  ? IntegratedIntegrationsState
  : ErroredDocumentsState;

export type SendingRequestID = SendingRequest['id'];
export type IntegrationRequestID = IntegrationRequest['id'];

export type SendingRequestWithMeta =
  | InboxSendingRequestWithMeta
  | ArchivedSendingRequestWithMeta
  | DraftSendingRequestWithMeta
  | SentSendingRequestWithMeta
  | ErroredSendingRequestWithMeta
  | WorklistIntegrationRequestWithMeta
  | IntegratedIntegrationRequestWithMeta;

export type DocumentDtoWithMeta =
  | InboxDocumentDtoWithMeta
  | ArchivedDocumentDtoWithMeta
  | DraftDocumentDtoWithMeta
  | SentDocumentDtoWithMeta
  | ErroredDocumentDtoWithMeta
  | WorklistIntegrationRequestDtoWithMeta
  | IntegratedIntegrationRequestDtoWithMeta;

export type IncomingSendingRequestWithMeta =
  | InboxSendingRequestWithMeta
  | ArchivedSendingRequestWithMeta;
export type OutgoingSendingRequestWithMeta =
  | DraftSendingRequestWithMeta
  | SentSendingRequestWithMeta;

export type DocumentDashboardType =
  | SendingsDashboardType
  | IntegrationsDashboardType
  | ErroredDocumentDashboardType;

export type DocumentMetadata =
  | InboxDocumentMetadata
  | ArchivedDocumentMetadata
  | DraftDocumentMetadata
  | SentDocumentMetadata
  | ErroredDocumentMetadata
  | WorklistIntegrationRequestMetadata
  | IntegratedIntegrationRequestMetadata;

export type SendingRequestsActions =
  | typeof InboxDocumentsActions
  | typeof ArchivedDocumentsActions
  | typeof DraftDocumentsActions
  | typeof SentDocumentsActions;

export type IntegrationsActions =
  | typeof WorklistIntegrationsActions
  | typeof IntegratedIntegrationsActions;

export type DocumentsWithErroredActions =
  | SendingRequestsActions
  | typeof ErroredDocumentsActions
  | IntegrationsActions;

export type DocumentDtoWithMetaFromType<T> = T extends SendingsDashboardType.Inbox
  ? InboxDocumentDtoWithMeta
  : T extends SendingsDashboardType.Archive
  ? ArchivedDocumentDtoWithMeta
  : T extends SendingsDashboardType.Draft
  ? DraftDocumentDtoWithMeta
  : T extends SendingsDashboardType.Sent
  ? SentDocumentDtoWithMeta
  : T extends IntegrationsDashboardType.Worklist
  ? WorklistIntegrationRequestDtoWithMeta
  : T extends IntegrationsDashboardType.Integrated
  ? IntegratedIntegrationRequestDtoWithMeta
  : unknown;

export type SendingRequestFromType<T> = T extends
  | IntegrationsDashboardType.Worklist
  | IntegrationsDashboardType.Integrated
  ? IntegrationRequest
  : SendingRequest;

export type SendingRequestDtoFromType<T> = T extends
  | IntegrationsDashboardType.Worklist
  | IntegrationsDashboardType.Integrated
  ? IntegrationRequestDto
  : SendingRequestDto;

export type DocumentDtoWithMetaFromState<T> = T extends InboxDocumentsState
  ? InboxDocumentDtoWithMeta
  : T extends ArchivedDocumentsState
  ? ArchivedDocumentDtoWithMeta
  : T extends DraftDocumentsState
  ? DraftDocumentDtoWithMeta
  : T extends SentDocumentsState
  ? SentDocumentDtoWithMeta
  : T extends WorklistIntegrationsState
  ? WorklistIntegrationRequestDtoWithMeta
  : T extends IntegratedIntegrationsState
  ? IntegratedIntegrationRequestDtoWithMeta
  : ErroredDocumentDtoWithMeta;

export type DocumentMetadataTypeFromState<T> = T extends InboxDocumentsState
  ? InboxDocumentMetadataType
  : T extends ArchivedDocumentsState
  ? ArchivedDocumentMetadataType
  : T extends DraftDocumentsState
  ? DraftDocumentMetadataType
  : T extends SentDocumentsState
  ? SentDocumentMetadataType
  : T extends WorklistIntegrationsState
  ? WorklistIntegrationRequestMetadataType
  : T extends IntegratedIntegrationsState
  ? IntegratedIntegrationRequestMetadataType
  : ErroredDocumentMetadataType;
