export function groupBy<T extends { [key: string]: any }>(
  resources: T[],
  propertyAccessor: (resource: T) => string,
): { [key: string]: T[] } {
  return groupByFunc(resources, propertyAccessor);
}

export function groupByFunc<T extends { [key: string]: any }>(
  resources: T[],
  by: (resource: T) => string,
): { [key: string]: T[] } {
  return resources.reduce((accumulator: { [key: string]: T[] }, resource: T) => {
    const key = by(resource);
    if (!key) {
      return accumulator;
    }

    if (accumulator[key] && Array.isArray(accumulator[key])) {
      accumulator[key] = [...accumulator[key], resource];
    } else {
      accumulator[key] = [resource];
    }
    return accumulator;
  }, {});
}
