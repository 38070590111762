import { MediumList, Telecom, TelecomType, Telecoms } from '@honestica/core-apps-common/types';

export function getSecuredTelecoms(telecoms: Telecoms) {
  return [...telecoms.apicrypt, ...telecoms.mssante, ...telecoms.lifen];
}

export function isEnsOrMss(telecom: Telecom) {
  return [TelecomType.MSSANTE, TelecomType.ENS].includes(telecom.type);
}

export function getENSTelecom(telecoms: MediumList<Telecom>) {
  return telecoms.find((telecom) => telecom.type === TelecomType.ENS);
}
