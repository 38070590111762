import { useEffect, useMemo } from 'react';

import { APP_URLS, BASENAME } from '@launcher/constants';
import { Breakpoint, useViewportLargerThan } from '@launcher/context/Viewport.context';
import { getCurrentAppFromPathname } from '@launcher/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { MainRouteUrls } from '@honestica/core-apps-common/types';
import { isNull } from '@honestica/core-apps-common/utils';

import {
  getFeatureFlags,
  getIsAppLoading,
  getIsLifenSendingActivated,
  selectActiveApps,
} from '@store/user/user.selector';

// This would probably better be done in a saga, keeping it here to not break current workflow
// Tech backlog card: https://honestica.atlassian.net/browse/CORE-3105
export function useDefaultAppRoute() {
  const coreApps = useSelector(selectActiveApps);
  const isLoading = useSelector(getIsAppLoading);
  const isDocumentsAppAvailable = useSelector(getIsLifenSendingActivated);
  const featureFlags = useSelector(getFeatureFlags);

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showOutgoing = useViewportLargerThan(Breakpoint.L);
  const currentAppName = useMemo(() => {
    if (isLoading) return null;
    return getCurrentAppFromPathname(pathname, coreApps);
  }, [isLoading, coreApps, pathname]);

  const isDesktopSettings = useMemo(
    () => pathname === `${BASENAME}/${MainRouteUrls.DesktopSettings}`,
    [pathname],
  );

  useEffect(() => {
    if (featureFlags['cafe-maintenance']) {
      navigate(MainRouteUrls.Maintenance);
      return;
    }

    if (!isLoading && isNull(currentAppName) && !isDesktopSettings) {
      if (isDocumentsAppAvailable) {
        navigate(MainRouteUrls.Documents);
        return;
      }

      if (coreApps.length > 0) {
        const defaultAppKey = coreApps[0].name;
        navigate(APP_URLS[defaultAppKey]);
      }
    }
  }, [
    dispatch,
    coreApps,
    isLoading,
    currentAppName,
    isDocumentsAppAvailable,
    isDesktopSettings,
    navigate,
    showOutgoing,
    featureFlags,
  ]);
}
