import { ApiErrorsKey } from '@honestica/core-apps-common/constants';

interface HttpErrorProps {
  message: string;
  statusCode: number;
  key?: ApiErrorsKey;
  headers?: Headers;
  response?: any;
}

export class HttpError extends Error {
  public readonly message: string;

  public readonly statusCode: number;

  public readonly key: ApiErrorsKey | undefined;

  public readonly headers: Headers | undefined;

  public readonly response: any | undefined;

  constructor({ message, statusCode, key, headers, response }: HttpErrorProps) {
    const msg = message || statusCode.toString() + (key ? ` - ${key}` : '');
    super(msg);
    this.message = msg;
    this.statusCode = statusCode;
    this.key = key;
    this.headers = headers;
    this.response = response;
  }

  public static getKey(error: unknown) {
    return error instanceof this ? error.key : undefined;
  }
}
