import { put } from 'redux-saga/effects';

import { ActionDeferredActions } from '@store/actionDeferred/actionsDeferred.slice';
import { DeferredActionsType, DeferredId } from '@store/actionDeferred/actionsDeferred.state';

import { InfoMessageType } from '../infoMessages.state';

export const cancelMessageToAction: Record<InfoMessageType, null | ((id: DeferredId) => void)> = {
  [InfoMessageType.documentSent]: null,
  [InfoMessageType.documentsSent]: null,
  [InfoMessageType.sendDocumentCancelled]: null,
  [InfoMessageType.sendDocumentPending]: (id: DeferredId) =>
    put(ActionDeferredActions.cancel({ type: DeferredActionsType.sendDocument, id })),
  [InfoMessageType.sendDocumentsCancelled]: null,
  [InfoMessageType.sendDocumentsPending]: (id: DeferredId) =>
    put(ActionDeferredActions.cancel({ type: DeferredActionsType.sendMany, id })),
  [InfoMessageType.downloadWithoutAttachment]: null,
  [InfoMessageType.updateDocumentSender]: null,
};
