export const MAX_LENGTH_ADDRESS = 38;

export const REFRESH_PATIENT_MATCH_TIMEOUT_MS = 30 * 1000;

/**
 * we use only one 'y' because the datefns library is not
 * 100% compatible with antd in particular with the antd time picker.
 *
 * This workaround is listed here : https://github.com/ant-design/ant-design/issues/30097#issuecomment-821226154
 *
 */
export const DAY_MONTH_YEAR_FORMAT_DATE_PICKER = 'dd/MM/y';
