import { logger } from '@services/logger/logger.service';

export function removeFile(filePath: string) {
  try {
    window.electron.removeFile(filePath);
  } catch (err) {
    logger.error('DESKTOP', `Failed to remove file "${filePath}".`, err, false);
  }
}

export function removeFiles(filePaths: string[]) {
  filePaths.forEach(removeFile);
}

export function moveFileOrDelete(from: string, to: string | string[]) {
  const fileName = window.electron.getBaseName(from);
  const destinationFolder = to instanceof Array ? to : [to];
  const destinationPath = window.electron.joinPaths(...destinationFolder, fileName);

  let movingErr;
  let deleteErr;

  try {
    logger.info('DESKTOP', `Moving file to "${destinationFolder}".`);
    window.electron.moveFile(from, destinationPath, { overwrite: true });
  } catch (err) {
    movingErr = err;
  } finally {
    if (movingErr) {
      try {
        // If moving failed, try to delete the file.
        window.electron.removeFile(from);
      } catch (err) {
        deleteErr = err;
      } finally {
        // If deleting failed, log both errors.
        if (deleteErr) {
          logger.error(
            'DESKTOP',
            `Failed to move file to "${destinationFolder}", and failed to remove file "${from}".`,
            deleteErr,
            false,
          );
        } else {
          logger.error(
            'DESKTOP',
            `Failed to move file to "${destinationFolder}". Deleting file "${from}".`,
            movingErr,
          );
        }
      }
    }
  }
}

export function getFilesPathInDirectories(directories: string[]) {
  return directories.reduce(
    (acc, next) => acc.concat(window.electron.getAllFilePathsFromDir(next).filter(isValidFilePath)),
    [] as string[],
  );
}

function isValidFilePath(path: string) {
  const fileName = window.electron.getBaseName(path);

  return !(fileName === '.DS_Store');
}
