import { every3Hours, getAllNestedWatchPaths, isFileOlderThanXDays } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';

import { getFilesPathInDirectories, removeFiles } from './desktopFile.helper';

let cleaningOutboxInterval: null | NodeJS.Timeout = null;

window.addEventListener('beforeunload', () => stopAutomaticCleaningOutbox());

/**
 * Check every 3 hours if files can be clean
 * @param watchPath where files are stored
 * @param keepDuring duration in days files are allow to stay in folder
 */
export function startAutomaticCleaningOutbox(watchPaths: string[], keepDuring: number) {
  if (cleaningOutboxInterval) clearInterval(cleaningOutboxInterval);
  cleanOutboxFolder(watchPaths, keepDuring);
  cleaningOutboxInterval = every3Hours(() => cleanOutboxFolder(watchPaths, keepDuring));
}

export function stopAutomaticCleaningOutbox() {
  if (cleaningOutboxInterval) clearInterval(cleaningOutboxInterval);
}

/**
 * Delete all files to be cleaned from the file system
 * @param watchPath watch path folder from settings
 * @param afterXDays number of days files can be kept, 0 to get all
 */
export function cleanOutboxFolder(watchPaths: string[], afterXDays: number) {
  logger.info('DESKTOP', `Starting purge on ${watchPaths.join(', ')}`);
  logger.info('DESKTOP', `All files older than ${afterXDays} days will be removed`);

  const filesToBePurged = getFilesToBeCleaned(watchPaths, afterXDays);

  logger.info('DESKTOP', `Will remove ${filesToBePurged.length} file(s)`);

  removeFiles(filesToBePurged);

  logger.info('DESKTOP', `Purge has completed`);
}

/**
 * Get the list of files to be deleted on the file system
 * @param watchPath watch path folder from settings
 * @param afterXDays number of days files can be kept, 0 to get all
 * @returns list of file paths to be cleaned
 */
export function getFilesToBeCleaned(watchPaths: string[], afterXDays: number) {
  try {
    const withNested = getAllNestedWatchPaths(watchPaths, false);
    const filePaths = getFilesPathInDirectories(withNested);

    const filtered = [] as string[];

    for (const path of filePaths) {
      const fileStats = window.electron.statFile(path);

      // Exclude directories
      if (!fileStats.isFile()) continue;

      // Filter files older than X days
      if (afterXDays > 0 && !isFileOlderThanXDays(fileStats, afterXDays)) continue;

      filtered.push(path);
    }

    return filtered;
  } catch (err) {
    logger.error('DESKTOP', 'Unable to retrieve files to be cleaned', err, false);
  }

  return [];
}
