import { DesktopSettings } from '@launcher/utils';
import { Selector } from 'react-redux';

import { State } from '@store/reducer';

import { DesktopState } from './desktop.state';

export const selectDesktopState: Selector<State, DesktopState> = (state: State) => state.desktop;

export const selectDesktopSettings: Selector<State, DesktopSettings> = (state: State) =>
  state.desktop.settings;

export const selectDesktopIsLoadingSettings: Selector<State, boolean> = (state: State) =>
  state.desktop.loadingSettings;

export const selectDesktopUsernameSettings: Selector<State, string> = (state: State) =>
  state.desktop.settings.username;

export const selectDesktopSsoConnectionSettings: Selector<State, string> = (state: State) =>
  state.desktop.settings.ssoConnection;

export const selectIsWatchingOutbox: Selector<State, boolean> = (state: State) =>
  state.desktop.watchingFolders;

export const selectHideSupport: Selector<State, boolean> = (state: State) =>
  state.desktop.settings.hideSupport;

export const selectDesktopIsReady: Selector<State, boolean> = (state: State) =>
  state.desktop.desktopFeaturesInitialized;
