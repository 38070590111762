import { useCallback, useMemo } from 'react';

import { ModalContent, ModalsComponent } from '@core-components/Modals';
import { CleanOutboxConfirmationModal } from '@launcher/components/DesktopSettings/CleanOutboxConfirmationModal/CleanOutboxConfirmationModal.component';
import { NotificationWindow } from '@launcher/components/NotificationWindow';
import { AboutModal, QAModal, SupportModal } from '@launcher/components/Sidebar';
import { NOTIFICATION_WINDOW_WIDTH } from '@launcher/helpers/desktop';
import { useTranslation } from 'react-i18next';

import { Button } from '@honestica/ui-kit/src';

import { useModal } from '@hooks/useModal.hook';
import { ModalType } from '@store/modal/modal.type';

import { useCleanOutbox } from '../../hooks/useCleanOutbox.hook';

import { ModalReduxProps, modalConnector } from './modal.connector';

const LauncherModalsComponent = ({ resetNotification }: ModalReduxProps) => {
  const cleanOutbox = useCleanOutbox();
  const { closeModal } = useModal();

  const withCloseModal = useCallback(
    (fn: () => void | Promise<void>) => () => Promise.resolve(fn()).then(() => closeModal()),
    [closeModal],
  );

  const { t } = useTranslation();

  const CancelButton = useMemo(
    () => (
      <Button key="cancel" onClick={() => closeModal()}>
        {t('cancel')}
      </Button>
    ),
    [t, closeModal],
  );

  const ConfirmButton = useCallback(
    (onConfirm: () => void | Promise<void>) => (
      <Button key="confirm" type="primary" onClick={withCloseModal(onConfirm)}>
        {t('confirm')}
      </Button>
    ),
    [t, withCloseModal],
  );
  const modalMap: { [modal: string]: ModalContent } = {
    [ModalType.cleanOutboxConfirmation]: {
      component: <CleanOutboxConfirmationModal />,
      footer: [CancelButton, ConfirmButton(cleanOutbox)],
      title: t('desktopSettings.cleanOutbox.modal.title'),
    },
    [ModalType.documentJobNotification]: {
      component: <NotificationWindow />,
      width: NOTIFICATION_WINDOW_WIDTH,
      styles: { body: { padding: 0 } },
      onCancel: () => {
        resetNotification();
      },
    },
    [ModalType.helpAbout]: {
      component: <AboutModal />,
      title: t('help.about'),
    },
    [ModalType.settingsQA]: {
      component: <QAModal />,
      title: t('settings.parameters.qa.title'),
    },
    [ModalType.helpSupport]: {
      component: <SupportModal />,
      title: t('help.support.title'),
    },
  };

  return <ModalsComponent modalMap={modalMap} />;
};

export const LauncherModals = modalConnector(LauncherModalsComponent);
