import { createSlice } from '@reduxjs/toolkit';

import {
  delayedDocumentIdToRefresh,
  purgeDocumentIdsToRefetch,
} from '../common/reducers/delayedDocumentsToRefresh.reducers';
import { generateDownloadActions } from '../common/reducers/downloadDocuments.reducers';
import { updateDocumentAttachments } from '../common/reducers/fetchAttachments.reducers';
import {
  fetchDocumentsSuccess,
  fetchMany,
  fetchManyFailure,
  fetchManySuccess,
  fetchOne,
  fetchOneFailure,
  fetchOneSuccess,
  setDetailViewDocument,
  setDetailViewDocumentDto,
} from '../common/reducers/fetchDocuments.reducers';
import {
  flushLoadingDocumentIds,
  updateLoadingDocumentIds,
} from '../common/reducers/updateLoadingDocumentIds.reducers';
import {
  updateDocumentAndMeta,
  updateDocumentAndMetaWithTimeout,
  updateFailure,
  updateMany,
  updateManySuccess,
  updateOne,
  updateOneFailure,
  updateOneSuccess,
  updateOneTimeout,
  updateOneTimeoutFailure,
  updateOneTimeoutStart,
  updateOneTimeoutSuccess,
} from '../common/reducers/updateMeta.reducers';
import { updateSearchParams } from '../common/reducers/updateSearchParams.reducers';
import {
  AddDematRecipientAction,
  AddPatientRecipientAction,
  AddPostalRecipientAction,
  BatchAction,
  BatchDeferredAction,
  BatchFailureAction,
  BatchSuccessAction,
  CreatePatientAction,
  DetailViewDocumentDtoAction,
  FailureAction,
  FetchDocumentAction,
  FetchDocumentSuccessAction,
  FetchEhrPatientsSuccessAction,
  FetchEncountersAction,
  FetchEncountersSuccessAction,
  GetDocumentAction,
  GetDocumentSuccessAction,
  OneAction,
  OneDeferredAction,
  OneFailureAction,
  OneSuccessAction,
  RemoveVerificationAction,
  RemoveVerificationFailureAction,
  RemoveVerificationSuccessAction,
  SearchPatientsAction,
  SearchPatientsSuccessAction,
  SelectDocumentIDAction,
  SetCustomEnsMessageAction,
  SetEnsConversationClosedAction,
  SuggestedRecipientsSuccessAction,
  SwitchEncounterAction,
  SwitchEncounterSuccessAction,
  ToggleConnectorAction,
  ToggleConnectorIntegrationModeAction,
  UpdateConnectorSettingsAction,
  UpdateDocumentBundleSuccessAction,
  UpdateDocumentDatePeriodAction,
  UpdateDocumentSenderAction,
  UpdateDocumentSenderSuccessAction,
  UpdateDocumentSuccessAction,
  UpdateDocumentTypeAction,
  UpdateEhrPatientAction,
  UpdateEhrPatientSuccessAction,
  UpdatePatientAction,
  UpdateRecipientAction,
  UpdateRecipientsAction,
  UpdatedPatientSuccessAction,
} from '../documents.actions';

import {
  DraftDocumentMetadataKey,
  INITIAL_DRAFT_DOCUMENTS_STATE,
  INITIAL_DRAFT_DOCUMENT_METADATA,
} from './draft.state';
import { setOverrides, updateOverrides } from './reducers/desktop.settingsOverrides.reducers';
import {
  decrementDraftCounter,
  decrementReadyToSendCounter,
  fetchDraftCounterFailure,
  fetchDraftCounterSuccess,
  fetchTotalReadyToSendFailure,
  fetchTotalReadyToSendSuccess,
  incrementDraftCounter,
  incrementReadyToSendCounter,
} from './reducers/draft.counters.reducers';
import {
  onFetchEhrPatients,
  onFetchEhrPatientsFailure,
  onFetchEhrPatientsSuccess,
  onFetchEncounters,
  onFetchEncountersFailure,
  onFetchEncountersSuccess,
  onRefreshPatientMatch,
  onSwitchEncounterSuccess,
  onUpdateEhrPatient,
  onUpdateEhrPatientFailure,
  onUpdateEhrPatientSuccess,
} from './reducers/draft.integrations.reducers';
import {
  onRemoveRecipient,
  onRemoveRecipientFailure,
  onRemoveRecipientSuccess,
  onUpdateRecipients,
  onUpdateRecipientsFailure,
  onUpdateRecipientsSuccess,
  setSuggestedRecipientsSuccess,
} from './reducers/draft.recipients.reducers';
import {
  onFlushPatientSearch,
  onSearchPatients,
  onSearchPatientsFailure,
  onSearchPatientsSuccess,
} from './reducers/draft.searchPatients.reducers';
import {
  resetCurrentIdentity,
  setCurrentIdentity,
} from './reducers/draft.selectedIdentity.reducers';
import { sendManySuccess, sendOneSuccess } from './reducers/draft.send.reducers';

const slice = createSlice({
  name: 'draftDocuments',
  initialState: INITIAL_DRAFT_DOCUMENTS_STATE,
  reducers: {
    selectOne: (state, action: SelectDocumentIDAction) => ({
      ...state,
      selectedId: action.payload,
    }), // never used?

    /*
     * DASHBOARD
     */

    /*
     * View
     */

    setOverrides: (state, action) => setOverrides(state, action),
    updateOverrides: (state, action) => updateOverrides(state, action),

    updateSearchParams,
    updateLoadingDocumentIds: (state, action) => updateLoadingDocumentIds(state, action),
    flushLoadingDocumentIds,

    fetchMany,
    fetchManySuccess: (state, action) =>
      fetchManySuccess(state, action, INITIAL_DRAFT_DOCUMENT_METADATA),
    fetchManyFailure,
    fetchDocumentsSuccess: (state, action) =>
      fetchDocumentsSuccess(state, action, INITIAL_DRAFT_DOCUMENT_METADATA),
    fetchTotalReadyToSend: (state) => state,
    fetchTotalReadyToSendSuccess,
    fetchTotalReadyToSendFailure,
    fetchDraftCounters: (state) => state,
    fetchDraftCounterSuccess,
    fetchDraftCounterFailure,
    incrementDraftCounter,
    decrementDraftCounter,
    incrementReadyToSendCounter,
    decrementReadyToSendCounter,
    setCurrentIdentity,
    resetCurrentIdentity,

    /*
     * Entities
     */

    cancelManyFailure: (state, action: BatchFailureAction) =>
      updateFailure(state, action, DraftDocumentMetadataKey.cancelDocument),

    groupMany: (state, action: BatchAction) =>
      updateMany(state, action, DraftDocumentMetadataKey.groupDocument),
    groupManySuccess: (state, action: BatchSuccessAction) =>
      updateManySuccess(state, action, DraftDocumentMetadataKey.groupDocument),
    groupManyFailure: (state, action: BatchFailureAction) =>
      updateFailure(state, action, DraftDocumentMetadataKey.groupDocument),

    sendMany: (state, action: BatchDeferredAction) =>
      updateMany(state, action, DraftDocumentMetadataKey.sendDocument),
    sendManyStart: (state, action: BatchAction) =>
      updateMany(state, action, DraftDocumentMetadataKey.sendDocument), // never used?
    sendManyDeferred: (state, action: BatchAction) =>
      updateMany(state, action, DraftDocumentMetadataKey.sendDocument),
    sendManyAbort: (state, action: BatchAction) =>
      updateManySuccess(state, action, DraftDocumentMetadataKey.sendDocument),
    sendManySuccess,
    sendManyFailure: (state, action: BatchFailureAction) =>
      updateFailure(state, action, DraftDocumentMetadataKey.sendDocument),

    splitMany: (state, action: BatchAction) =>
      updateMany(state, action, DraftDocumentMetadataKey.splitDocument),
    splitManySuccess: (state, action: BatchSuccessAction) =>
      updateManySuccess(state, action, DraftDocumentMetadataKey.splitDocument),
    splitManyFailure: (state, action: BatchFailureAction) =>
      updateFailure(state, action, DraftDocumentMetadataKey.splitDocument),

    duplicateMany: (state, action: BatchAction) =>
      updateMany(state, action, DraftDocumentMetadataKey.duplicateDocument),
    duplicateManySuccess: (state, action: BatchSuccessAction) =>
      updateManySuccess(state, action, DraftDocumentMetadataKey.duplicateDocument),
    duplicateManyFailure: (state, action: BatchFailureAction) =>
      updateFailure(state, action, DraftDocumentMetadataKey.duplicateDocument),

    delayedDocumentIdToRefresh,
    purgeDocumentIdsToRefetch,

    /*
     * DETAIL
     */

    /*
     * View
     */

    getOne: (state, _action: GetDocumentAction) => state,
    setDetailViewDocument: (state, action: GetDocumentSuccessAction) =>
      setDetailViewDocument(state, action, INITIAL_DRAFT_DOCUMENTS_STATE.detailView),
    setDetailViewDocumentDto: (state, action: DetailViewDocumentDtoAction) =>
      setDetailViewDocumentDto(
        state,
        action,
        INITIAL_DRAFT_DOCUMENTS_STATE.detailView,
        INITIAL_DRAFT_DOCUMENT_METADATA,
      ),

    fetchOne,
    fetchOneWithIntegrationData: (state, action: FetchDocumentAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.fetchAttachmentsRequest), // to update detail integration data + attachments of document that have integration data (use the same saga but a different reducer)
    fetchOneSuccess: (state, action: FetchDocumentSuccessAction) =>
      fetchOneSuccess(state, action, INITIAL_DRAFT_DOCUMENT_METADATA), // updates entities
    fetchOneFailure,

    fetchManyAttachments: (state, action: OneAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.fetchAttachmentsRequest),
    fetchManyAttachmentsSuccess: updateDocumentAttachments,
    fetchManyAttachmentsFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, DraftDocumentMetadataKey.fetchAttachmentsRequest),

    /*
     * Entities
     */

    cancelOneFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, DraftDocumentMetadataKey.cancelDocument),

    sendOneDeferred: (state, action: OneAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.sendDocument),
    sendOneAbort: (state, action: OneAction) =>
      updateOneSuccess(state, action, DraftDocumentMetadataKey.sendDocument),
    sendOne: (state, action: OneDeferredAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.sendDocument),
    sendOneSuccess,
    sendOneFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, DraftDocumentMetadataKey.sendDocument),

    splitOne: (state, action: OneAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.splitDocument),
    splitOneSuccess: (state, action: OneSuccessAction) =>
      updateOneSuccess(state, action, DraftDocumentMetadataKey.splitDocument),
    splitOneFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, DraftDocumentMetadataKey.splitDocument),

    duplicateOne: (state, action: OneAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.duplicateDocument),
    duplicateOneSuccess: (state, action: OneSuccessAction) =>
      updateOneSuccess(state, action, DraftDocumentMetadataKey.duplicateDocument),
    duplicateOneFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, DraftDocumentMetadataKey.duplicateDocument),

    updateDocumentType: (state, action: UpdateDocumentTypeAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.updateDocType),
    updateDocumentTypeSuccess: (state, action: UpdateDocumentSuccessAction) =>
      updateDocumentAndMeta(state, action.payload.document, DraftDocumentMetadataKey.updateDocType),
    updateDocumentTypeFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, DraftDocumentMetadataKey.updateDocType),

    updateDocumentDatePeriod: (state, action: UpdateDocumentDatePeriodAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.updatePeriodDate),
    updateDocumentDatePeriodSuccess: (state, action: UpdateDocumentSuccessAction) =>
      updateDocumentAndMeta(
        state,
        action.payload.document,
        DraftDocumentMetadataKey.updatePeriodDate,
      ),
    // eslint-disable-next-line sonarjs/no-identical-functions
    updateDocumentDatePeriodFailure: (state, action: UpdateDocumentSuccessAction) =>
      updateDocumentAndMeta(
        state,
        action.payload.document,
        DraftDocumentMetadataKey.updatePeriodDate,
      ),

    updateDocumentSender: (state, action: UpdateDocumentSenderAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.updateSender),
    updateDocumentSenderSuccess: (state, action: UpdateDocumentSenderSuccessAction) =>
      updateDocumentAndMeta(
        state,
        action.payload.bundle.document,
        DraftDocumentMetadataKey.updateSender,
      ),
    updateDocumentSenderFailure: (state, action: UpdateDocumentSuccessAction) =>
      updateDocumentAndMeta(state, action.payload.document, DraftDocumentMetadataKey.updateSender),

    addPatientRecipient: (state, action: AddPatientRecipientAction) =>
      onUpdateRecipients(state, action),
    addPatientRecipientSuccess: (state, action: UpdateDocumentSuccessAction) =>
      onUpdateRecipientsSuccess(state, action.payload.document),

    addPostalRecipient: (state, action: AddPostalRecipientAction) =>
      onUpdateRecipients(state, action),

    addDematRecipient: (state, action: AddDematRecipientAction) =>
      onUpdateRecipients(state, action),

    addRecipient: (state, action: UpdateRecipientAction) => onUpdateRecipients(state, action),
    addRecipientSuccess: (state, action: UpdateDocumentBundleSuccessAction) =>
      onUpdateRecipientsSuccess(state, action.payload.bundle.document), // updates entities
    addRecipientFailure: (state, action: OneFailureAction) =>
      onUpdateRecipientsFailure(state, action),

    addRecipients: (state, action: UpdateRecipientsAction) => onUpdateRecipients(state, action),
    addRecipientsSuccess: (state, action: UpdateDocumentBundleSuccessAction) =>
      onUpdateRecipientsSuccess(state, action.payload.bundle.document),

    removePatientRecipient: (state, action: OneAction) => onRemoveRecipient(state, action),

    removeRecipient: (state, action: UpdateRecipientAction) => onRemoveRecipient(state, action),
    removeRecipientSuccess: (state, action: UpdateDocumentSuccessAction) =>
      onRemoveRecipientSuccess(state, action.payload.document),
    removeRecipientFailure: (state, action: OneFailureAction) =>
      onRemoveRecipientFailure(state, action),

    updateRecipient: (state, action: UpdateRecipientAction) => onUpdateRecipients(state, action),
    updateRecipientSuccess: (state, action: UpdateDocumentSuccessAction) =>
      onUpdateRecipientsSuccess(state, action.payload.document),
    updateRecipientFailure: (state, action: OneFailureAction) =>
      onUpdateRecipientsFailure(state, action),

    addDocumentPatient: (state, action: UpdatePatientAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.updatePatient),
    createDocumentPatient: (state, action: CreatePatientAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.updatePatient),

    addDocumentPatientSuccess: (state, action: UpdateDocumentBundleSuccessAction) =>
      updateDocumentAndMeta(
        state,
        action.payload.bundle.document,
        DraftDocumentMetadataKey.updatePatient,
      ),
    addDocumentPatientFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, DraftDocumentMetadataKey.updatePatient), // updates entities

    updateDocumentPatient: (state, action: UpdatePatientAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.updatePatient),
    updateDocumentPatientFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, DraftDocumentMetadataKey.updatePatient),
    updateDocumentPatientSuccess: (state, action: UpdatedPatientSuccessAction) =>
      action.payload.document
        ? updateDocumentAndMeta(
            state,
            action.payload.document,
            DraftDocumentMetadataKey.updatePatient,
          )
        : updateOneSuccess(state, action, DraftDocumentMetadataKey.updatePatient), // updates entities

    removeDocumentPatient: (state, action: OneAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.removePatient),
    removeDocumentPatientSuccess: (state, action: UpdateDocumentBundleSuccessAction) =>
      updateDocumentAndMeta(
        state,
        action.payload.bundle.document,
        DraftDocumentMetadataKey.removePatient,
      ),
    removeDocumentPatientFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, DraftDocumentMetadataKey.removePatient),

    searchPatients: (state, _action: SearchPatientsAction) => onSearchPatients(state),
    searchPatientsFailure: (state, action: FailureAction) => onSearchPatientsFailure(state, action),
    searchPatientsSuccess: (state, action: SearchPatientsSuccessAction) =>
      onSearchPatientsSuccess(state, action),

    flushPatientSearch: (state) => onFlushPatientSearch(state),

    fetchSuggestedRecipients: (state) => state,
    fetchSuggestedRecipientsSuccess: (state, action: SuggestedRecipientsSuccessAction) =>
      setSuggestedRecipientsSuccess(state, action),

    fetchEncounters: (state, _action: FetchEncountersAction) => onFetchEncounters(state),
    fetchEncountersFailure: (state, action: FailureAction) =>
      onFetchEncountersFailure(state, action),
    fetchEncountersSuccess: (state, action: FetchEncountersSuccessAction) =>
      onFetchEncountersSuccess(state, action),

    fetchEhrPatients: (state) => onFetchEhrPatients(state),
    fetchEhrPatientsFailure: (state, action: FailureAction) =>
      onFetchEhrPatientsFailure(state, action),
    fetchEhrPatientsSuccess: (state, action: FetchEhrPatientsSuccessAction) =>
      onFetchEhrPatientsSuccess(state, action),

    updateEhrPatient: (state, _action: UpdateEhrPatientAction) =>
      onUpdateEhrPatient(state, _action),
    updateEhrPatientSuccess: (state, action: UpdateEhrPatientSuccessAction) =>
      onUpdateEhrPatientSuccess(state, action),
    updateEhrPatientFailure: (state, _action: OneFailureAction) =>
      onUpdateEhrPatientFailure(state, _action),

    removeVerification: (state, action: RemoveVerificationAction) =>
      onUpdateRecipients(state, action),
    removeVerificationSuccess: (state, action: RemoveVerificationSuccessAction) =>
      onUpdateRecipientsSuccess(state, action.payload.document),
    removeVerificationFailure: (state, action: RemoveVerificationFailureAction) =>
      onUpdateRecipientsFailure(state, action),

    /*
     * Integration entities
     */

    refreshPatientMatchSuccess: (state, action: OneAction) =>
      updateOneTimeoutSuccess(
        state,
        action as OneAction,
        DraftDocumentMetadataKey.refreshPatientMatch,
      ),
    refreshPatientMatchTimeout: (state, action: OneAction) =>
      updateOneTimeout(state, action, DraftDocumentMetadataKey.refreshPatientMatch),
    refreshPatientMatchFailure: (state, action: OneFailureAction) =>
      updateOneTimeoutFailure(state, action, DraftDocumentMetadataKey.refreshPatientMatch),

    refreshPatientMatch: (state, action: OneAction) => onRefreshPatientMatch(state, action),

    switchEncounter: (state, _action: SwitchEncounterAction) => state,
    switchEncounterSuccess: (state, action: SwitchEncounterSuccessAction) =>
      onSwitchEncounterSuccess(state, action),
    switchEncounterFailure: (state, _action: OneFailureAction) => state,

    toggleConnector: (state, action: ToggleConnectorAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.updateIntegration),
    toggleConnectorSuccess: (state, action: UpdateDocumentBundleSuccessAction) =>
      updateDocumentAndMeta(
        state,
        action.payload.bundle.document,
        DraftDocumentMetadataKey.updateIntegration,
      ), // = updateConnectorSettingsSuccess
    toggleConnectorFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, DraftDocumentMetadataKey.updateIntegration),

    updateConnectorSettings: (state, action: UpdateConnectorSettingsAction) =>
      updateOneTimeoutStart(state, action, DraftDocumentMetadataKey.updateIntegration),

    updateConnectorSettingsSuccess: (state, action: UpdateDocumentBundleSuccessAction) =>
      updateDocumentAndMetaWithTimeout(
        state,
        action.payload.bundle.document,
        DraftDocumentMetadataKey.updateIntegration,
      ),
    updateConnectorSettingsFailure: (state, action: OneFailureAction) =>
      updateOneTimeoutFailure(state, action, DraftDocumentMetadataKey.updateIntegration),

    toggleConnectorIntegrationMode: (state, action: ToggleConnectorIntegrationModeAction) =>
      updateOneTimeoutStart(state, action, DraftDocumentMetadataKey.updateIntegration),
    // eslint-disable-next-line sonarjs/no-identical-functions
    toggleConnectorIntegrationModeSuccess: (state, action: UpdateDocumentBundleSuccessAction) =>
      updateDocumentAndMetaWithTimeout(
        state,
        action.payload.bundle.document,
        DraftDocumentMetadataKey.updateIntegration,
      ), // = updateConnectorSettingsSuccess
    toggleConnectorIntegrationModeFailure: (state, action: OneFailureAction) =>
      updateOneTimeoutFailure(state, action, DraftDocumentMetadataKey.updateIntegration),

    /*
     * ENS entities
     */

    setCustomEnsMessage: (state, action: SetCustomEnsMessageAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.setEnsMessage),
    setCustomEnsMessageSuccess: (state, action: UpdateDocumentSuccessAction) =>
      onUpdateRecipientsSuccess(state, action.payload.document),
    setCustomEnsMessageFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, DraftDocumentMetadataKey.setEnsMessage),

    setEnsConversationClosed: (state, action: SetEnsConversationClosedAction) =>
      updateOne(state, action, DraftDocumentMetadataKey.setEnsConversationClosed),
    setEnsConversationClosedSuccess: (state, action: UpdateDocumentSuccessAction) =>
      onUpdateRecipientsSuccess(state, action.payload.document),
    setEnsConversationClosedFailure: (state, action: OneFailureAction) =>
      updateOneFailure(state, action, DraftDocumentMetadataKey.setEnsConversationClosed),
  },
});

export const documentDraftReducer = slice.reducer;

export const DraftDocumentsActions = { ...slice.actions, ...generateDownloadActions(slice.name) };
