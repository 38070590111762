import { JobState } from '@honestica/core-apps-common/types';

export type RealtimeResource = {
  id?: string;
  resourceType?: string;
};

export type DocumentFlowRealtimeEventPayload = {
  channel?: string;
  resource: { id: fhir.Resource['id'] };
};

export enum SendingFlowRealTimeEventType {
  CR_UPDATED = 'REALTIME_CR_UPDATED',
  COM_CREATED = 'REALTIME_COM_CREATED',
  COM_UPDATED = 'REALTIME_COM_EVENT_UPDATED',
}

export enum IntegrationFlowRealTimeEventType {
  INTEGRATION_CR_UPDATED = 'REALTIME_INTEGRATION_CR_UPDATED',
  INTEGRATION_COM_CREATED = 'REALTIME_INTEGRATION_COM_CREATED',
  INTEGRATION_COM_UPDATED = 'REALTIME_INTEGRATION_COM_UPDATED',
}

export type DocumentFlowRealTimeEventType =
  | SendingFlowRealTimeEventType
  | IntegrationFlowRealTimeEventType;

export enum JobRealTimeEventType {
  UPDATE_PATIENTS_IMPORT_JOB_STATE = 'REALTIME_UPDATE_PATIENTS_IMPORT_JOB_STATE',
}

export interface RealTimeEvent<T> {
  eventType:
    | DocumentFlowRealTimeEventType
    | JobRealTimeEventType
    | IntegrationFlowRealTimeEventType;
  payload: T;
}

export interface FhirRealTimeEvent extends RealTimeEvent<DocumentFlowRealtimeEventPayload> {
  eventType: DocumentFlowRealTimeEventType;
}

export interface JobRealTimeEvent<T> extends RealTimeEvent<JobState<T>> {
  eventType: JobRealTimeEventType;
}
