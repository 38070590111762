import { logger } from '@services/logger/logger.service';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { duplicateIntegrationRequests } from '@api';
import { WORKLIST } from '@constants/documents.constants';
import { selectManyDocumentsFileStorageIds } from '@store/documents/documents.selector';

import { BatchAction } from '../../documents.actions';
import { WorklistIntegrationsActions } from '../worklist.slice';

function* duplicateManyDocumentsSaga({ payload: { ids } }: BatchAction) {
  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, ids, WORKLIST);
  try {
    yield call(duplicateIntegrationRequests, ids);
    yield put(WorklistIntegrationsActions.duplicateManySuccess({ ids, fileStorageIds }));
  } catch (error) {
    logger.error('LOGIC', 'Failed to duplicate documents', error);
    yield put(
      WorklistIntegrationsActions.duplicateManyFailure({
        ids,
        error,
      }),
    );
  } finally {
    yield put(WorklistIntegrationsActions.fetchMany({ clearSearch: false }));
  }
}

export function* duplicateWorklistDocumentsSagas() {
  yield all([
    takeLatest(WorklistIntegrationsActions.duplicateMany.type, duplicateManyDocumentsSaga),
  ]);
}
