import { createAction } from '@reduxjs/toolkit';

import { DisplayableDrawer } from './drawer.type';

export type OpenDrawerFromStore = typeof openDrawerFromStore;
export type CloseDrawerFromStore = typeof closeDrawerFromStore;

export interface OpenDrawerProps {
  drawer: DisplayableDrawer;
  patientId?: string;
  practitionerId?: string;
  organizationId?: string;
  telecomId?: string;
  addPatientRecipientFlowParams?: {
    isAddressSelected: boolean;
    isTelecomSelected: boolean;
    isEnsSelected: boolean;
  };
  recipientReference?: string;
}

const REDUCER_NAME = 'drawer';

export const openDrawerFromStore = createAction<OpenDrawerProps>(`${REDUCER_NAME}/open`);

export const closeDrawerFromStore = createAction(`${REDUCER_NAME}/close`);
