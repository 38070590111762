import { ConnectedProps, connect } from 'react-redux';

import { selectDesktopSettings } from '@store/desktop/desktop.selector';
import { State } from '@store/reducer';

export const cleanOutboxConfirmationModalConnector = connect((state: State) => ({
  settings: selectDesktopSettings(state),
}));

export type CleanOutboxConfirmationModalReduxProps = ConnectedProps<
  typeof cleanOutboxConfirmationModalConnector
>;
