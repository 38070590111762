import { ReactNode } from 'react';

import { Alert, Col, Row } from 'antd';
import cx from 'classnames';

import colors from '../../theme/colors';
import Button from '../Button';
import { Dropdown, DropdownMenuItemProps } from '../Dropdown';
import Icon from '../Icon';
import { OneToneIconName } from '../Icon/OneToneIcons';

import styles from './BannerCard.module.less';

// Used for icon color
const colorMap: Record<CardType, string> = {
  warning: colors.orange[5],
  error: colors.red[6],
  info: colors.geekblue[5],
};

type CardType = 'warning' | 'error' | 'info';

interface BannerCardProps {
  className?: string;
  testid?: string;
  title?: string;
  iconName?: OneToneIconName;
  message: string;
  list?: string[];
  type?: CardType;
  closable?: boolean;
  onClose?: () => void;
  action?: React.ReactElement<BannerButtonsProps> | React.ReactElement<BannerDropdownProps>;
  link?: React.ReactElement<BannerLinkProps>;
}

interface DescriptionProps {
  message: string | ReactNode;
  list?: string[];
}

const Description = (props: DescriptionProps) => (
  <div className={styles.descriptionText}>
    {props.message}
    {props.list && (
      <ul>
        {props.list.map((text) => (
          <li key={text}>{text}</li>
        ))}
      </ul>
    )}
  </div>
);

interface BannerButtonsProps {
  primaryAction: () => void;
  primaryDisabled?: boolean;
  primaryText: string;
  primaryTestid?: string;
  secondaryAction?: () => void;
  secondaryDisabled?: boolean;
  secondaryText?: string;
  secondaryTestid?: string;
}

export const BannerButtons: React.FC<BannerButtonsProps> = (props: BannerButtonsProps) => (
  <Row>
    <Col>
      <Button
        size="middle"
        onClick={props.primaryAction}
        disabled={props.primaryDisabled}
        data-testid={props.primaryTestid}
        type="primary"
        className="margin-right-xs"
      >
        {props.primaryText}
      </Button>
    </Col>
    {props.secondaryAction && (
      <Col>
        <Button
          onClick={props.secondaryAction}
          disabled={props.secondaryDisabled}
          size="middle"
          data-testid={props.secondaryTestid}
        >
          {props.secondaryText}
        </Button>
      </Col>
    )}
  </Row>
);

interface BannerDropdownProps {
  dropDownOptions: DropdownMenuItemProps[];
  dropDownDisabled?: boolean;
  dropDownText: string;
  dropdownTestid?: string;
}

export const BannerDropdown: React.FC<BannerDropdownProps> = (props: BannerDropdownProps) => (
  <Dropdown options={props.dropDownOptions} disabled={props.dropDownDisabled}>
    <Button
      size="middle"
      className={styles.dropDownButton}
      data-testid={props.dropdownTestid}
      type="primary"
    >
      {props.dropDownText}
    </Button>
  </Dropdown>
);

interface BannerLinkProps {
  linkAction: () => void;
  linkText: string;
  linkTestid?: string;
}

export const BannerLink: React.FC<BannerLinkProps> = (props: BannerLinkProps) => (
  <Button
    type="link"
    onClick={props.linkAction}
    data-testid={props.linkTestid}
    className={styles.link}
  >
    {props.linkText}
  </Button>
);

export const BannerCard = ({
  className,
  testid,
  title,
  iconName,
  message,
  list,
  type,
  closable,
  onClose,
  action,
  link,
}: BannerCardProps) => (
  <Alert
    data-testid={testid}
    icon={
      iconName && (
        <Icon
          name={iconName}
          color={type && colorMap[type]}
          size={16}
          className={styles.icon}
          data-testid="bannericon"
        />
      )
    }
    showIcon={!!iconName}
    type={type}
    closable={closable}
    onClose={onClose}
    className={cx(styles.bannerCard, className, {
      [styles.warning]: type === 'warning',
      [styles.info]: type === 'info',
      [styles.error]: type === 'error',
    })}
    message={title ? <div className={styles.title}>{title}</div> : undefined}
    description={
      <div className={styles.description}>
        <Description message={message} list={list} />
        {link}
        {action && <div className={styles.action}>{action}</div>}
      </div>
    }
  />
);
type BannerProps = Omit<BannerCardProps, 'iconName' | 'type'>;

export const DangerBannerCard = (props: BannerProps) => (
  <BannerCard iconName="DiamondAlert" type="error" {...props} />
);

export const InfoBannerCard = (props: BannerProps) => (
  <BannerCard iconName="CircleInformation" type="info" {...props} />
);

export const WarningBannerCard = (props: BannerProps) => (
  <BannerCard iconName="RoadSignWarning" type="warning" {...props} />
);
