import { ChangeEvent, ForwardedRef, ReactText, forwardRef } from 'react';

import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';
import { Button, Col, Input, InputRef, Row } from 'antd';
import Form, { FormItemProps } from 'antd/lib/form';
import { InputProps } from 'antd/lib/input';
import { useDebounce } from 'use-debounce';

import {
  CountryCode,
  PhoneSupportedCountryCodes,
  validateCellPhone,
} from '@honestica/core-apps-common/utils';

import { Dropdown } from '../../Dropdown';

export interface PhoneInputProps {
  phoneNumber: string;
  countryCode: CountryCode;
  errorMessage: string;
  phoneSupportedCountryCodes: PhoneSupportedCountryCodes[];
  placeholder?: string;
  setPhoneNumber: (value: string) => void;
  setCountryCode: (value: CountryCode) => void;
  validator?: (cellPhone: string, countryCode: CountryCode) => boolean;
  formItemProps: FormItemProps;
}

export const PhoneInput = forwardRef(
  (
    {
      countryCode,
      errorMessage,
      formItemProps,
      phoneNumber,
      phoneSupportedCountryCodes,
      placeholder,
      setPhoneNumber,
      setCountryCode,
      validator = validateCellPhone,
      size,
      ...otherProps
    }: PhoneInputProps & InputProps,
    ref: ForwardedRef<InputRef>,
  ): JSX.Element => {
    const [debouncedPhone] = useDebounce<string>(phoneNumber, 1000);

    let validateStatus: 'error' | 'success' | undefined;
    let help;

    if (debouncedPhone.length > 0) {
      if (validator?.(debouncedPhone, countryCode)) {
        validateStatus = 'success';
      } else {
        validateStatus = 'error';
        help = errorMessage;
      }
    }
    const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
      setPhoneNumber(e.target.value);
    };

    const handleCountryCodeChange = (e: { key: ReactText }) => {
      const index = typeof e.key === 'number' ? e.key : parseInt(e.key, 10);
      setCountryCode(phoneSupportedCountryCodes[index].isoCode);
    };

    const menu = phoneSupportedCountryCodes.map(
      (cc: PhoneSupportedCountryCodes, index: number) => ({
        key: index.toString(),
        children: cc.name,
      }),
    );

    return (
      <Form.Item
        hasFeedback={!!(formItemProps.validateStatus ?? validateStatus)}
        validateStatus={formItemProps.validateStatus ?? validateStatus}
        help={formItemProps.help ?? help}
        htmlFor="cellPhone"
        name="cellPhone"
        labelCol={{ span: 24 }}
        {...formItemProps}
      >
        <Row gutter={8}>
          <Col span={4}>
            <Dropdown options={menu} menuProps={{ onClick: handleCountryCodeChange }}>
              <Button size={size ?? 'large'}>
                {countryCode} <CaretDownOutlined />
              </Button>
            </Dropdown>
          </Col>
          <Col span={20}>
            <Input
              size={size ?? 'large'}
              onChange={handlePhoneNumberChange}
              placeholder={placeholder}
              value={phoneNumber}
              id="cellPhone"
              ref={ref}
              {...otherProps}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  },
);
