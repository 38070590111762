import { createAction } from '@reduxjs/toolkit';

import {
  DocumentFlowRealtimeEventPayload,
  IntegrationFlowRealTimeEventType,
  SendingFlowRealTimeEventType,
} from '@honestica/lifen-realtime-service';

// LIFEN SENDING
export type RealtimeCommunicationCreated = typeof realtimeCommunicationCreated;
export const realtimeCommunicationCreated = createAction<DocumentFlowRealtimeEventPayload>(
  SendingFlowRealTimeEventType.COM_CREATED,
);

export type RealtimeCommunicationUpdated = typeof realtimeCommunicationUpdated;
export const realtimeCommunicationUpdated = createAction<DocumentFlowRealtimeEventPayload>(
  SendingFlowRealTimeEventType.COM_UPDATED,
);

export type RealtimeCommunicationRequestUpdated = typeof realtimeCommunicationRequestUpdated;
export const realtimeCommunicationRequestUpdated = createAction<DocumentFlowRealtimeEventPayload>(
  SendingFlowRealTimeEventType.CR_UPDATED,
);

// LIFEN INTEGRATIONS
export type IntegrationRealtimeCommunicationCreated =
  typeof integrationRealtimeCommunicationCreated;
export const integrationRealtimeCommunicationCreated =
  createAction<DocumentFlowRealtimeEventPayload>(
    IntegrationFlowRealTimeEventType.INTEGRATION_COM_CREATED,
  );

export type IntegrationRealtimeCommunicationUpdated =
  typeof integrationRealtimeCommunicationUpdated;
export const integrationRealtimeCommunicationUpdated =
  createAction<DocumentFlowRealtimeEventPayload>(
    IntegrationFlowRealTimeEventType.INTEGRATION_COM_UPDATED,
  );

export type IntegrationRealtimeCommunicationRequestUpdated =
  typeof integrationRealtimeCommunicationRequestUpdated;
export const integrationRealtimeCommunicationRequestUpdated =
  createAction<DocumentFlowRealtimeEventPayload>(
    IntegrationFlowRealTimeEventType.INTEGRATION_CR_UPDATED,
  );
