import { getENSTelecom } from '@lifen-sending/utils';
import { Tracking } from '@services/analytics';
import { all, getContext, takeLatest } from 'redux-saga/effects';

import { UpdateDocumentSuccessAction } from '@store/documents/documents.actions';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';

function* trackCustomEnsMessageSaga({ payload: { document } }: UpdateDocumentSuccessAction) {
  const trackingService: Tracking = yield getContext('trackingService');
  trackingService.documentCustomENSMessageAdded({
    documentId: document.id,
    isEmpty: !getENSTelecom(document.patient?.telecoms ?? [])?.customMessage?.length,
  });
}

function* trackSetEnsConversationClosedSaga({
  payload: { document },
}: UpdateDocumentSuccessAction) {
  const trackingService: Tracking = yield getContext('trackingService');
  trackingService.documentCloseENSConversation({
    documentId: document.id,
    closed: !!getENSTelecom(document.patient?.telecoms ?? [])?.conversationClosed,
  });
}

export function* trackingDocumentENSMessagesSagas() {
  yield all([
    takeLatest(DraftDocumentsActions.setCustomEnsMessageSuccess.type, trackCustomEnsMessageSaga),
    takeLatest(
      DraftDocumentsActions.setEnsConversationClosedSuccess.type,
      trackSetEnsConversationClosedSaga,
    ),
  ]);
}
