import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { all, getContext, put, takeLatest } from 'redux-saga/effects';

import { SendingRequestBundle } from '@honestica/core-apps-common/types';

import { removeVerification } from '@api';
import { RemoveVerificationAction } from '@store/documents/documents.actions';
import { updateDocumentInCache } from '@utils/caching.util';

import { DraftDocumentsActions } from '../draft.slice';

function* removeVerificationSaga(action: RemoveVerificationAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  try {
    const bundle: SendingRequestBundle = yield removeVerification(action.payload);

    yield put(DraftDocumentsActions.removeVerificationSuccess({ document: bundle.document }));
    yield updateDocumentInCache({
      updatedDocument: bundle.document,
      queryClient,
    });
  } catch (error) {
    logger.error(
      'LOGIC',
      `Failed to remove verification ${action.payload.verification} for document n°${action.payload.id}`,
      error,
    );
    yield put(
      DraftDocumentsActions.removeVerificationFailure({
        id: action.payload.id,
        error,
      }),
    );
  }
}

export function* documentVerificationsSagas() {
  yield all([takeLatest(DraftDocumentsActions.removeVerification.type, removeVerificationSaga)]);
}
