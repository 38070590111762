import { ContactType, TType, Tracking } from '@services/analytics';
import { all, getContext, takeLatest } from 'redux-saga/effects';

import { OpenDrawerFromStore, openDrawerFromStore } from '@store/drawer/drawer.action';
import {
  FindOrganizations,
  findOrganizations,
} from '@store/organizationDirectory/organizationDirectory.action';
import {
  FindPractitioners,
  findPractitioners,
} from '@store/practitionerDirectory/practitionerDirectory.action';
import { searchDirectories } from '@store/searchDirectories/searchDirectories.actions';

function* trackContactCardViewed({ payload }: ReturnType<OpenDrawerFromStore>) {
  const trackingService: Tracking = yield getContext('trackingService');
  if (payload.drawer === 'organization') {
    trackingService.contactCardViewed({
      contactType: TType.Organization,
      contactFhirReference: payload.organizationId ?? '',
    });
  }
  if (payload.drawer === 'practitioner') {
    trackingService.contactCardViewed({
      contactType: TType.Practitioner,
      contactFhirReference: payload.practitionerId ?? '',
    });
  }
}

function* trackPractitionerContactSearched({ payload }: ReturnType<FindPractitioners>) {
  const trackingService: Tracking = yield getContext('trackingService');
  if (payload.searchText)
    trackingService.contactSearched({ contactType: ContactType.Practitioner });
}

function* trackOrganizationContactSearched({ payload }: ReturnType<FindOrganizations>) {
  const trackingService: Tracking = yield getContext('trackingService');
  if (payload.searchText)
    trackingService.contactSearched({ contactType: ContactType.Organization });
}

function* trackContactSearched() {
  const trackingService: Tracking = yield getContext('trackingService');
  trackingService.contactSearched({ contactType: ContactType.All });
}

export function* trackingProfessionalDirectorySagas() {
  yield all([takeLatest(openDrawerFromStore.type, trackContactCardViewed)]);
  yield all([takeLatest(findPractitioners.type, trackPractitionerContactSearched)]);
  yield all([takeLatest(findOrganizations.type, trackOrganizationContactSearched)]);
  yield all([takeLatest(searchDirectories.type, trackContactSearched)]);
}
