import { getFileInMemory } from '@launcher/utils';
import { Selector } from '@reduxjs/toolkit';

import {
  ConnectorType,
  DmpDocumentConnector,
  SendingRequest,
  SendingRequestDto,
  SendingsDashboardType,
} from '@honestica/core-apps-common/types';
import { isPatientMinor } from '@honestica/core-apps-common/utils';
import { isIntegration } from '@honestica/core-apps-common/validators';

import {
  selectDetailDocument,
  selectDetailDocumentDto,
  selectDetailDocumentMeta,
  selectDetailViewState,
} from '@store/documents/documents.selector';
import {
  INITIAL_SENT_DOCUMENT_METADATA,
  SentDetailViewState,
  SentDocumentMetadata,
} from '@store/documents/outgoing/sent.state';
import { calculateSendingRequestDtoRecipientCount } from '@utils/document.util';

import { getPatientEntities } from '../../entities/entities.selector';
import { State } from '../../reducer';

/*
 * DOCUMENT DETAIL VIEW GENERICS
 */

export const selectSentDetailViewState = (state: State) =>
  selectDetailViewState(state, SendingsDashboardType.Sent) as SentDetailViewState;

export const selectDocument: Selector<State, SendingRequest | undefined> = (state) =>
  selectDetailDocument(state, SendingsDashboardType.Sent);

export const selectDocumentDto: Selector<State, SendingRequestDto | undefined> = (state) =>
  selectDetailDocumentDto(state, SendingsDashboardType.Sent);

export const selectSentDetailDocumentMeta: Selector<State, SentDocumentMetadata> = (state) =>
  (selectDetailDocumentMeta(state, SendingsDashboardType.Sent) as SentDocumentMetadata) ||
  INITIAL_SENT_DOCUMENT_METADATA;

/*
 * SENT DETAIL
 */

export const selectSentRecipientCount: Selector<State, number> = (state) => {
  const documentDto = selectDocumentDto(state);
  return calculateSendingRequestDtoRecipientCount(documentDto);
};

export const selectHasAtLeastOneIntegrationConnector: Selector<State, boolean> = (state) => {
  const integration = selectDocumentDto(state)?.integration;
  if (!isIntegration(integration)) {
    return false;
  }
  return Object.keys(integration.connectors).length > 0;
};

export const selectDocumentDetailDmpConnector: Selector<State, DmpDocumentConnector | undefined> = (
  state,
) => {
  const integration = selectDocumentDto(state)?.integration;

  if (isIntegration(integration)) {
    return integration.connectors[ConnectorType.Dmp];
  }

  return undefined;
};

export const selectIsCancelDmpDocumentLoading: Selector<State, boolean> = (state) =>
  selectSentDetailDocumentMeta(state).removeDmpRequest.isLoading;

export const selectIsPatientMinor: Selector<State, boolean> = (state) => {
  const ehrPatientId = selectDocumentDto(state)?.patient?.ehrPatientId ?? '';
  const patient = getPatientEntities(state)[ehrPatientId];

  if (!patient) {
    return false;
  }

  return isPatientMinor(patient);
};

export const selectDocumentIntegrationId: Selector<State, string> = (state) => {
  const integration = selectDocumentDto(state)?.integration;
  return isIntegration(integration) ? integration.id : '';
};

export const selectReplaceDmpFileStorageId: Selector<State, string | undefined> = (state) =>
  selectSentDetailViewState(state).replaceDmpFileStorageId;

export const selectIsFileIsFoundToReplaceDmp: Selector<State, boolean> = (state) => {
  const fileStorageId = selectReplaceDmpFileStorageId(state);
  return Boolean(getFileInMemory(fileStorageId));
};

export const selectFileNameToReplaceDmp: Selector<State, string | undefined> = (state) =>
  selectSentDetailViewState(state).replaceDmpFileName;

export const selectIsReplaceDmpDocumentLoading: Selector<State, boolean> = (state) =>
  selectSentDetailDocumentMeta(state).replaceDmpRequest.isLoading;

export const selectCanForwardSentDocument: Selector<State, boolean> = (state) => {
  const document = selectDocument(state);
  const isDocumentFetching = selectSentDetailViewState(state).documentRequest.isLoading;
  const isForwarding = selectSentDetailDocumentMeta(state).forwardRequest.isLoading;
  return !!document && !isDocumentFetching && !isForwarding;
};

export const selectSentDetailDocumentId: Selector<State, string | undefined> = (state) =>
  selectDocumentDto(state)?.id;
