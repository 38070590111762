import { logger } from '@services/logger/logger.service';
import { InitOptions } from 'i18next';

export const i18nSharedConfig: InitOptions = {
  load: 'currentOnly', // Why loading all translations ?
  returnNull: false, // Why enabling it ?
  returnEmptyString: false, // Why enabling it as we do not allow empty translation with eslint
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  saveMissing: true,
  fallbackLng: (code?: string) => {
    const fallbacks = ['fr'];
    if (!code) return fallbacks;

    if (code === 'en') {
      fallbacks.unshift('en-GB');
    }

    return fallbacks;
  },
  missingKeyHandler: (key) => {
    logger.warn('UI', `react-i18next key "${key}" not found.`);
  },
};
