import { ErrorPage } from '@launcher/components/ErrorPage';
import { AppError } from '@launcher/constants';
import { EnvManager } from '@services/environment/environment.service';

export const EnvProvider = ({ children }: { children: React.ReactNode }) => {
  if (!EnvManager.isReady()) {
    return <ErrorPage errorKey={AppError.InvalidEnvVariables} />;
  }

  return <>{children}</>;
};
