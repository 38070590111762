import { BatchAction } from '../../documents.actions';
import { DocumentsState } from '../../documents.state';

export function updateLoadingDocumentIds<T extends DocumentsState>(
  state: T,
  action: BatchAction | undefined,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      loadingDocumentIds: [...(action?.payload.ids || [])],
    },
  };
}

export function flushLoadingDocumentIds<T extends DocumentsState>(state: T): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      loadingDocumentIds: [],
    },
  };
}
