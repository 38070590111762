import { setUserEntitiesAvatarColor } from '@launcher/utils';
import { PayloadAction, createReducer } from '@reduxjs/toolkit';
import { logger } from '@services/logger/logger.service';

import { FeatureFlags } from '@honestica/core-apps-common/types';

import {
  AuthenticateFailure,
  AuthenticateUserSuccess,
  FetchIdentitiesSuccess,
  FetchIntegrationsSuccess,
  InitSelectedIdentities,
  SetSelectedIdentity,
  authenticateUserFailure,
  authenticateUserSuccess,
  fetchIdentitiesFailure,
  fetchIdentitiesSuccess,
  fetchIntegrationsFailure,
  fetchIntegrationsSuccess,
  initSelectedIdentities,
  setSelectedIdentity,
} from '@store/user/user.actions';

import { USER_INITIAL_STATE, UserData, UserDataTokenless } from './user.state';

function updateAuthData(
  state: UserDataTokenless,
  action: ReturnType<AuthenticateUserSuccess>,
): UserDataTokenless {
  const { payload } = action;

  logger.info('STATE', 'Auth data updated', {
    uuid: payload?.uuid,
  });

  return {
    ...state,
    ...{
      ...payload,
      settings: { ...state.settings, ...payload.settings },
      token: undefined,
    },
    isAuthenticated: true,
  };
}

function setAuthError(state: UserData, { payload }: ReturnType<AuthenticateFailure>): UserData {
  return {
    ...state,
    authError: payload,
  };
}

function updateIdentities(
  state: UserData,
  { payload }: ReturnType<FetchIdentitiesSuccess>,
): UserData {
  return {
    ...state,
    identityColors: setUserEntitiesAvatarColor(payload),
    identities: {
      ...state.identities,
      loading: false,
      identities: payload,
    },
  };
}

function updateIdentitiesFailure(state: UserData): UserData {
  return {
    ...state,
    identities: { ...state.identities, loading: false },
  };
}

function updateIntegrations(
  state: UserData,
  { payload }: ReturnType<FetchIntegrationsSuccess>,
): UserData {
  return {
    ...state,
    integrationOrganizations: {
      loading: false,
      organizations: payload,
    },
  };
}

function updateIntegrationsFailure(state: UserData): UserData {
  return {
    ...state,
    integrationOrganizations: {
      ...state.integrationOrganizations,
      loading: false,
    },
  };
}

function setFeatureFlags(state: UserData, { payload }: PayloadAction<FeatureFlags>): UserData {
  return {
    ...state,
    featureFlags: { loading: false, flags: { ...state.featureFlags.flags, ...payload } },
  };
}

function updateSelectedIdentity(
  state: UserData,
  action: ReturnType<SetSelectedIdentity>,
): UserData {
  return {
    ...state,
    identities: {
      ...state.identities,
      selectedIdentities: {
        ...state.identities.selectedIdentities,
        [action.payload.currentApp]: action.payload.identity,
      },
    },
  };
}

function setSelectedIdentities(
  state: UserData,
  action: ReturnType<InitSelectedIdentities>,
): UserData {
  return {
    ...state,
    identities: {
      ...state.identities,
      selectedIdentities: action.payload.selectedIdentities,
    },
  };
}

export const userReducer = createReducer(USER_INITIAL_STATE, (builder) => {
  builder
    .addCase(authenticateUserFailure, setAuthError)
    .addCase(fetchIdentitiesSuccess, updateIdentities)
    .addCase(fetchIdentitiesFailure, updateIdentitiesFailure)
    .addCase(fetchIntegrationsSuccess.type, updateIntegrations)
    .addCase(fetchIntegrationsFailure.type, updateIntegrationsFailure)
    .addCase(authenticateUserSuccess.type, updateAuthData)
    .addCase(setSelectedIdentity.type, updateSelectedIdentity)
    .addCase(initSelectedIdentities.type, setSelectedIdentities)
    .addMatcher((action) => action.type.endsWith('FeatureFlags'), setFeatureFlags);
});
