import { ConnectedProps, connect } from 'react-redux';

import { selectDesktopSettings } from '@store/desktop/desktop.selector';
import { State } from '@store/reducer';
import { logout } from '@store/user/user.actions';
import { getUser } from '@store/user/user.selector';

export type AccountSettingsReduxProps = ConnectedProps<typeof accountSettingsConnector>;

const mapStateToProps = (state: State) => ({
  hideSupport: selectDesktopSettings(state).hideSupport,
  user: getUser(state),
});

export const accountSettingsConnector = connect(mapStateToProps, { logout });
