import {
  IntegrationsDashboardType,
  SendingsDashboardType,
  SortByParams,
} from '@honestica/core-apps-common/types';

import {
  ARCHIVE_PATH,
  DRAFT_PATH,
  ERRORS_PATH,
  INBOX_PATH,
  INTEGRATED_PATH,
  SENT_PATH,
  WORKLIST_PATH,
} from '@constants/documents.constants';
import { ArchivedDocumentsActions } from '@store/documents/incoming/archived.slice';
import { InboxDocumentsActions } from '@store/documents/incoming/inbox.slice';
import { IntegratedIntegrationsActions } from '@store/documents/integrations/integrated.slice';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import { DraftDocumentsActions } from '@store/documents/outgoing/draft.slice';
import { ErroredDocumentsActions } from '@store/documents/outgoing/errored.slice';
import { SentDocumentsActions } from '@store/documents/outgoing/sent.slice';

export const ActionsByDashboard = {
  [SendingsDashboardType.Archive]: ArchivedDocumentsActions,
  [SendingsDashboardType.Draft]: DraftDocumentsActions,
  [SendingsDashboardType.Inbox]: InboxDocumentsActions,
  [SendingsDashboardType.Sent]: SentDocumentsActions,
  [IntegrationsDashboardType.Integrated]: IntegratedIntegrationsActions,
  [IntegrationsDashboardType.Worklist]: WorklistIntegrationsActions,
  errors: ErroredDocumentsActions,
};

export const PathsByDashboard = {
  [SendingsDashboardType.Archive]: ARCHIVE_PATH,
  [SendingsDashboardType.Draft]: DRAFT_PATH,
  [SendingsDashboardType.Inbox]: INBOX_PATH,
  [SendingsDashboardType.Sent]: SENT_PATH,
  [IntegrationsDashboardType.Integrated]: INTEGRATED_PATH,
  [IntegrationsDashboardType.Worklist]: WORKLIST_PATH,
};

export const DashboardsByPath: Record<
  string,
  SendingsDashboardType | IntegrationsDashboardType | 'errors'
> = {
  [ARCHIVE_PATH]: SendingsDashboardType.Archive,
  [DRAFT_PATH]: SendingsDashboardType.Draft,
  [INBOX_PATH]: SendingsDashboardType.Inbox,
  [SENT_PATH]: SendingsDashboardType.Sent,
  [ERRORS_PATH]: 'errors',
  [WORKLIST_PATH]: IntegrationsDashboardType.Worklist,
  [INTEGRATED_PATH]: IntegrationsDashboardType.Integrated,
};

export const ColumnSorter: Record<string, keyof SortByParams> = {
  authoredOn: 'sortByAuthoredOn',
} as const;
