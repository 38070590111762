import {
  CONNECTION,
  EMAIL,
  EMAIL_FROM_LOCALSTORAGE_DESKTOP,
  Environment,
  LOGIN_PARTITION,
  SIGNUP_VIEW_TO_DISPLAY,
  UTM_CAMPAIGN,
  UTM_CONTENT,
  UTM_MEDIUM,
  UTM_SOURCE,
  UTM_TERM,
  WORKSPACE_ID_LOCALSTORAGE_KEY,
} from '@launcher/constants';
import { ClearSessionStorageDataOptions } from '@launcher/helpers/desktop';
import { isDesktopApp } from '@launcher/utils';
import { EnvManager } from '@services/environment/environment.service';
import { LocalStorage, StorageKey } from '@services/localStorage.service';
import { logger } from '@services/logger/logger.service';

import { LifenConnectClient } from '@honestica/auth-sdk';
import {
  AuthUser,
  FullAuthUser,
  LoginOptions,
  LogoutOptions,
} from '@honestica/core-apps-common/types';

import { DOCUMENTS_ROOT_PATHS, INTEGRATION_ROOT_PATHS } from '@constants/documents.constants';
import { history } from '@store/history';

export const scopes = ['openid', 'profile'];

export class AuthenticationClient {
  private client: LifenConnectClient | null = null;

  get environment(): Environment {
    return EnvManager.getName() ?? 'dev';
  }

  get targetUrl(): string {
    const { pathname, search } = history.location;
    return `${pathname}${search}`;
  }

  get loginOptions(): LoginOptions {
    const params = new URL(window.location.href).searchParams;

    const connection = params.get(CONNECTION);
    const email = params.get(EMAIL);
    const emailFromLS = params.get(EMAIL_FROM_LOCALSTORAGE_DESKTOP);
    const workspaceIdFromParams = params.get(WORKSPACE_ID_LOCALSTORAGE_KEY);
    const view = params.get(SIGNUP_VIEW_TO_DISPLAY);
    const utmSource = params.get(UTM_SOURCE);
    const utmMedium = params.get(UTM_MEDIUM);
    const utmCampaign = params.get(UTM_CAMPAIGN);
    const utmTerm = params.get(UTM_TERM);
    const utmContent = params.get(UTM_CONTENT);

    const emailLocalStorageValue = LocalStorage.get('LIFEN_USER_EMAIL');
    const workspaceIdLocalStorageValue = LocalStorage.get('WORKSPACE_ID');

    const options: Partial<LoginOptions> = {
      allowChangeEmail: emailFromLS || emailLocalStorageValue ? true : undefined,
      email: email ?? emailFromLS ?? emailLocalStorageValue ?? undefined,
      force_username: email ? true : undefined,
      workspace_id: workspaceIdFromParams ?? workspaceIdLocalStorageValue ?? undefined,
      connection: connection ?? undefined,
      view: view ?? undefined,
      utm_source: utmSource ?? undefined,
      utm_medium: utmMedium ?? undefined,
      utm_campaign: utmCampaign ?? undefined,
      utm_term: utmTerm ?? undefined,
      utm_content: utmContent ?? undefined,
    };

    return {
      ...options,
      appState: { targetUrl: this.targetUrl },
      version: '2.0.0',
    };
  }

  private clearLocalStorage(): void {
    const keysToClear: StorageKey[] = ['LIFEN_USER_EMAIL', 'WORKSPACE_ID'];
    keysToClear.forEach((key) => LocalStorage.remove(key));
  }

  private async getUserSettings(): Promise<AuthUser | undefined> {
    if (!this.client) return undefined;
    return await this.client?.getUserSettings();
  }

  public async handleRedirectAfterLogin(): Promise<{
    user: AuthUser | undefined;
    targetUrl: string | undefined;
  }> {
    logger.info('LOGIC', `HANDLE_REDIRECT_AFTER_LOGIN`);

    if (!this.client) {
      await this.init();
    }
    const handleRedirectResult = await this.client?.handleRedirect();

    const fullUser = (await this.getUserSettings()) as FullAuthUser;

    logger.info('LOGIC', `HANDLE_REDIRECT_AFTER_LOGIN_INFO`, {
      workspaceId: fullUser.currentWorkspaceId,
      targetUrl: handleRedirectResult?.appState?.targetUrl,
    });

    const user = {
      ...fullUser,
      identitiesId: undefined,
      integrations: undefined,
      devices: undefined,
      baseSettingsUrl: undefined,
      viewerUrl: undefined,
    } as AuthUser;

    /**
     * Expose a function to allow the login webview to retrieve user info.
     */
    // eslint-disable-next-line no-underscore-dangle
    (window as any).__$$getLoginInfo = () => user; // only needed in desktop

    return { user, targetUrl: handleRedirectResult?.appState?.targetUrl };
  }

  public async init(): Promise<void> {
    if (this.client) return;
    logger.info('LOGIC', EnvManager.getName());
    logger.info('LOGIC', {
      audience: EnvManager.getAuth0Audience() || '',
      clientId: EnvManager.getAuth0ClientId() || '',
      domain: EnvManager.getAuth0Domain() || '',
      issuer: EnvManager.getAuthIssuer() || '',
      redirectUri: EnvManager.getUrlLoginCallback() ?? '/',
      scope: scopes.join(' '),
      cacheLocation: this.environment === 'octopus' ? 'localstorage' : 'memory',
    });
    this.client = await LifenConnectClient.init({
      audience: EnvManager.getAuth0Audience() || '',
      clientId: EnvManager.getAuth0ClientId() || '',
      domain: EnvManager.getAuth0Domain() || '',
      issuer: EnvManager.getAuthIssuer() || '',
      redirectUri: EnvManager.getUrlLoginCallback() ?? '/',
      scope: scopes.join(' '),
      cacheLocation: this.environment === 'octopus' ? 'localstorage' : 'memory',
    });
  }

  public async login(): Promise<void> {
    if (!this.client) {
      await this.init();
    }
    logger.info('LOGIC', 'LOGIN_URL', { url: window.location.href });

    this.saveUrlWorkspaceIdInLocalStorage(); // For desktop login

    logger.info('LOGIC', `LOGIN_REDIRECTION_OPTIONS: ${JSON.stringify(this.loginOptions)}`);
    logger.info('LOGIC', 'LOGIN_REDIRECTION_REQUEST');
    this.client?.loginWithRedirect(this.loginOptions);
  }

  private saveUrlWorkspaceIdInLocalStorage(): void {
    const params = new URL(window.location.href).searchParams;

    const workspaceIdFromParams = params.get(WORKSPACE_ID_LOCALSTORAGE_KEY);
    if (workspaceIdFromParams) {
      LocalStorage.set('WORKSPACE_ID', workspaceIdFromParams);
      logger.info('LOGIC', `LOGIN_SAVE_PARAMS_WORKSPACE_ID: ${workspaceIdFromParams}`);
    }
  }

  public switchWorkspace(workspaceId: string): void {
    logger.info('LOGIC', 'SWITCH_WORKSPACE', { workspaceId });
    LocalStorage.set('WORKSPACE_ID', workspaceId);

    let path = window.location.href;

    const rootPaths = [...DOCUMENTS_ROOT_PATHS, ...INTEGRATION_ROOT_PATHS];

    for (const rootPath of rootPaths) {
      const override = new RegExp(`.*${rootPath}`).exec(path);
      if (override !== null) {
        // eslint-disable-next-line prefer-destructuring
        path = override[0];
        break;
      }
    }

    if (isDesktopApp()) {
      window.electron.redirectTo(path);
    } else {
      window.location.assign(path);
    }
  }

  public async logout(options?: LogoutOptions): Promise<void> {
    this.clearLocalStorage();
    if (isDesktopApp()) {
      /* We access the session via the partition name
       * and clear localStorage data in order to manually
       * disconnect the user on next reload
       */

      const redirectUrl = `${window.location.origin}/apps/`;
      await window.electron?.clearCache();
      await window.electron?.clearSessionStorageData({ options: {} });

      const clearStorageOptions: ClearSessionStorageDataOptions = {
        origin: `https://${EnvManager.getAuth0Domain()}`,
      };
      await window.electron?.clearCache(LOGIN_PARTITION);
      await window.electron?.clearSessionStorageData({
        partition: LOGIN_PARTITION,
        options: clearStorageOptions,
      });
      window.electron?.redirectTo(redirectUrl);
    } else {
      await this.client?.logout({
        returnTo: EnvManager.getUrlLoginCallback(),
        ...options,
      });
    }
  }
}

export const authClient = new AuthenticationClient();
