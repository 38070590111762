import { logger } from '@services/logger/logger.service';
import { NavigateFunction } from 'react-router-dom';
import { all, call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import { DashboardSearchParams } from '@honestica/core-apps-common/types';

import { cancelIntegrationRequest, cancelIntegrationRequests } from '@api';
import { WORKLIST, WORKLIST_PATH } from '@constants/documents.constants';
import { BatchAction, OneAction } from '@store/documents/documents.actions';
import {
  selectDashboardSearchParams,
  selectManyDocumentsFileStorageIds,
} from '@store/documents/documents.selector';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';

function* cancelOneDocumentSaga({ payload: { id } }: OneAction) {
  const navigate: NavigateFunction = yield getContext('customNavigate');
  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, [id], WORKLIST);
  try {
    yield cancelIntegrationRequest(id);
    yield put(WorklistIntegrationsActions.cancelOneSuccess({ id, fileStorageIds }));
    navigate(WORKLIST_PATH);
  } catch (error) {
    logger.error('LOGIC', `Failed to cancel document n°${id}`, error);
    yield put(
      WorklistIntegrationsActions.cancelOneFailure({
        id,
        error,
      }),
    );
  }
}

function* cancelManyDocumentsSaga({ payload: { ids } }: BatchAction) {
  const fileStorageIds: string[] = yield select(selectManyDocumentsFileStorageIds, ids, WORKLIST);
  try {
    const searchParams: DashboardSearchParams = yield select((state) =>
      selectDashboardSearchParams(state, WORKLIST),
    );
    yield call(cancelIntegrationRequests, ids);
    yield put(WorklistIntegrationsActions.cancelManySuccess({ ids, fileStorageIds }));
    yield put(
      WorklistIntegrationsActions.fetchMany({
        search: searchParams,
        clearSearch: false,
      }),
    );
  } catch (error) {
    logger.error('LOGIC', 'Failed to cancel IntegrationRequests', error);
    yield put(
      WorklistIntegrationsActions.cancelManyFailure({
        ids,
        error,
      }),
    );
  }
}

export function* cancelIntegrationRequestsSagas() {
  yield all([
    takeLatest(WorklistIntegrationsActions.cancelOne.type, cancelOneDocumentSaga),
    takeLatest(WorklistIntegrationsActions.cancelMany.type, cancelManyDocumentsSaga),
  ]);
}
