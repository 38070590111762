import { logger } from '@services/logger/logger.service';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import {
  IntegrationRequestBundle,
  IntegrationRequestDto,
  IntegrationRequestUpdateOperation,
  ProfessionalSettings,
} from '@honestica/core-apps-common/types';

import { updateIntegrationRequest } from '@api';
import {
  UpdateDocumentTitleAction,
  UpdateIntegrationRequestDatePeriodAction,
  UpdateIntegrationRequestTypeAction,
  UpdateMedicalExamTypeAction,
  UpdateOriginalSenderAction,
} from '@store/documents/documents.actions';
import { selectIntegrationRequestDto } from '@store/documents/integrations/worklist.selector';
import { WorklistIntegrationsActions } from '@store/documents/integrations/worklist.slice';
import { selectSelectedIdentitySettings } from '@store/user/user.selector';

function* updateIntegrationRequestAsync({
  updateData,
  successAction,
  failureAction,
  updateType,
}: {
  updateData: IntegrationRequestUpdateOperation;
  successAction: any;
  failureAction: any;
  updateType: string;
}) {
  const docDto: IntegrationRequestDto = yield select(selectIntegrationRequestDto);
  try {
    const bundle: IntegrationRequestBundle = yield updateIntegrationRequest(docDto.id, updateData);

    // Ugly hack: underlying reducers expect attachments to be set in the entities instead of at the root of the bundle
    const bundleWithAttachments = {
      ...bundle,
      entities: {
        ...bundle.entities,
        attachments: bundle.attachments,
      },
    };

    yield put(successAction(bundleWithAttachments));
  } catch (error) {
    logger.error('LOGIC', `Failed to update integration request ${updateType}`, error);
    yield put(failureAction({ id: docDto.id, error }));
  }
}

function* updateDocumentDatePeriodAsync({
  payload: { newPeriod },
}: UpdateIntegrationRequestDatePeriodAction) {
  const { startPeriod, endPeriod } = newPeriod;

  yield* updateIntegrationRequestAsync({
    updateData: {
      startPeriod,
      endPeriod,
    },
    successAction: WorklistIntegrationsActions.updateIntegrationRequestDatePeriodSuccess,
    failureAction: WorklistIntegrationsActions.updateDocumentDatePeriodFailure,
    updateType: 'date period',
  });
}

function* updateDocumentTypeAsync({
  payload: { docType: newType },
}: UpdateIntegrationRequestTypeAction) {
  yield* updateIntegrationRequestAsync({
    updateData: { documentType: newType },
    successAction: WorklistIntegrationsActions.updateDocumentTypeSuccess,
    failureAction: WorklistIntegrationsActions.updateDocumentTypeFailure,
    updateType: 'document type',
  });
}

function* updateMedicalExamTypeAsync({
  payload: { medicalExamType: newType },
}: UpdateMedicalExamTypeAction) {
  const selectedIdentitySettings: ProfessionalSettings = yield select((state) =>
    selectSelectedIdentitySettings(state, 'lifenIntegration'),
  );
  yield* updateIntegrationRequestAsync({
    updateData: {
      medicalExamType: newType,
      updateVerificationStatus:
        !!selectedIdentitySettings?.lifenFeatureSetIntegrations?.enableRequiredFieldsOption,
    },
    successAction: WorklistIntegrationsActions.updateMedicalExamTypeSuccess,
    failureAction: WorklistIntegrationsActions.updateMedicalExamTypeFailure,
    updateType: 'medical exam type',
  });
}

function* updateOriginalSenderAsync({
  payload: { originalSender, originalSenderDisplayName },
}: UpdateOriginalSenderAction) {
  const selectedIdentitySettings: ProfessionalSettings = yield select((state) =>
    selectSelectedIdentitySettings(state, 'lifenIntegration'),
  );
  yield* updateIntegrationRequestAsync({
    updateData: {
      originalSender,
      originalSenderDisplayName,
      updateVerificationStatus:
        !!selectedIdentitySettings?.lifenFeatureSetIntegrations?.enableRequiredFieldsOption,
    },
    successAction: WorklistIntegrationsActions.updateOriginalSenderSuccess,
    failureAction: WorklistIntegrationsActions.updateOriginalSenderFailure,
    updateType: 'original sender',
  });
}

function* updateDocumentTitleAsync({ payload: { documentTitle } }: UpdateDocumentTitleAction) {
  const selectedIdentitySettings: ProfessionalSettings = yield select((state) =>
    selectSelectedIdentitySettings(state, 'lifenIntegration'),
  );

  yield* updateIntegrationRequestAsync({
    updateData: {
      documentTitle,
      updateVerificationStatus:
        !!selectedIdentitySettings?.lifenFeatureSetIntegrations?.enableRequiredFieldsOption,
    },
    successAction: WorklistIntegrationsActions.updateDocumentTitleSuccess,
    failureAction: WorklistIntegrationsActions.updateDocumentTitleFailure,
    updateType: 'document title',
  });
}

export function* updateIntegrationRequestSagas() {
  yield all([
    takeLatest(
      WorklistIntegrationsActions.updateDocumentDatePeriod.type,
      updateDocumentDatePeriodAsync,
    ),
    takeLatest(WorklistIntegrationsActions.updateDocumentType.type, updateDocumentTypeAsync),
    takeLatest(WorklistIntegrationsActions.updateMedicalExamType.type, updateMedicalExamTypeAsync),
    takeLatest(WorklistIntegrationsActions.updateOriginalSender.type, updateOriginalSenderAsync),
    takeLatest(WorklistIntegrationsActions.updateDocumentTitle.type, updateDocumentTitleAsync),
  ]);
}
