import { ComponentPropsWithRef } from 'react';

import Spin, { SpinSize } from 'antd/lib/spin';

import Space from '../Space';

import styles from './Spinner.module.less';

type SpinnerProps = ComponentPropsWithRef<'div'> & {
  size?: SpinSize;
  full?: boolean;
  visible?: boolean;
};

export const Spinner = ({ size, full, visible, ...otherProps }: SpinnerProps) => {
  let className = otherProps.className || '';
  // eslint-disable-next-line no-param-reassign
  delete otherProps.className;

  const show = visible ?? true;

  if (full) {
    className += ` ${styles.spinnerFull}`;
  }
  className += ` ${show ? styles.visible : styles.hidden}`;
  return (
    <Space
      direction="vertical"
      align="center"
      justify="center"
      fullWidth
      fullHeight
      className={className}
      {...otherProps}
    >
      <Spin size={size || 'large'} />
    </Space>
  );
};

export const SpinnerFull = ({ size, ...otherProps }: SpinnerProps) => (
  <Spinner {...otherProps} full size={size} />
);
