import { FC } from 'react';

import { Typography as AntTypography } from 'antd';
import { LinkProps } from 'antd/lib/typography/Link';
import { TextProps as AntTextProps } from 'antd/lib/typography/Text';
import cx from 'classnames';

import styles from './Typography.module.less';

interface TextProps extends AntTextProps {
  small?: boolean;
  capitalize?: boolean;
}

const Text: FC<TextProps> = ({
  children,
  small = false,
  capitalize = false,
  className,
  ...otherProps
}) => (
  <AntTypography.Text
    className={cx({ [styles.small]: small, [styles.capitalize]: capitalize }, className)}
    {...otherProps}
  >
    {children}
  </AntTypography.Text>
);

const Link: FC<LinkProps> = ({ children, className, ...otherProps }) => (
  <AntTypography.Link {...otherProps} className={cx(styles.link, className)}>
    {children}
  </AntTypography.Link>
);

export const Typography = {
  ...AntTypography,
  Link,
  Text,
};
