import { useEffect, useState } from 'react';

type UseReachBottomParams = {
  elt: Element | null | undefined;
};

const DEFAULT_OFFSET = 100;

export const useReachBottom = ({ elt }: UseReachBottomParams): boolean => {
  const [isBottomReached, setIsBottomReached] = useState(false);

  useEffect(() => {
    if (!elt) {
      return undefined;
    }
    const handleScroll = () => {
      if (elt.scrollHeight - elt.scrollTop - elt.clientHeight <= DEFAULT_OFFSET) {
        setIsBottomReached(true);
      } else {
        setIsBottomReached(false);
      }
    };

    elt.addEventListener('scroll', handleScroll);

    return () => {
      elt.removeEventListener('scroll', handleScroll);
    };
  }, [elt]);

  return isBottomReached;
};
