import { fileToBuffer } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';
import { QueryClient } from '@tanstack/react-query';
import { NavigateFunction } from 'react-router-dom';
import { all, getContext, put, takeLatest } from 'redux-saga/effects';

import { DocumentJobError } from '@honestica/core-apps-common/types';

import { replaceMssDocument } from '@api';
import { SENT_DETAIL_VIEW_PATH } from '@constants/documents.constants';
import { ReplaceMssAction, ReplaceMssFailureAction } from '@store/documents/documents.actions';
import { SentDocumentsActions } from '@store/documents/outgoing/sent.slice';
import { notificationAddError } from '@store/notificationWindow/notificationWindow.action';
import { delaySentDashboardCacheRefresh } from '@utils/caching.util';
import { checkFileForErrors } from '@utils/checkFile.util';

function* replaceMssSaga({ payload: { id, file, telecoms } }: ReplaceMssAction) {
  const queryClient: QueryClient = yield getContext('queryClient');

  const navigate: NavigateFunction = yield getContext('customNavigate');
  const fileName = file.name;

  try {
    const fileBuffer: Buffer = yield fileToBuffer(file);
    const errors: DocumentJobError[] = yield checkFileForErrors(fileBuffer, fileName);
    if (errors.length) {
      yield put(
        SentDocumentsActions.replaceMssFailure({
          id,
          errors,
        }),
      );
      return;
    }
    const { id: newDocumentId } = yield replaceMssDocument(id, file, telecoms);

    delaySentDashboardCacheRefresh({ queryClient });

    yield put(SentDocumentsActions.replaceMssSuccess({ id }));
    navigate(`${SENT_DETAIL_VIEW_PATH}/${newDocumentId}`);
  } catch (error) {
    logger.error(
      'LOGIC',
      `Failed to replace MSS document for document ${id} with file name ${fileName}`,
      error,
    );
    yield put(
      SentDocumentsActions.replaceMssFailure({
        id,
        errors: [DocumentJobError.UploadFailed],
      }),
    );
  }
}

function* replaceMssFailureSaga({ payload }: ReplaceMssFailureAction) {
  yield put(
    notificationAddError({
      id: '',
      errors: payload.errors,
      fileName: '',
      uploadSource: 'button',
      flowType: 'sending',
    }),
  );
}

export function* manageMssSentDocumentsSagas() {
  yield all([
    takeLatest(SentDocumentsActions.replaceMss.type, replaceMssSaga),
    takeLatest(SentDocumentsActions.replaceMssFailure.type, replaceMssFailureSaga),
  ]);
}
