import { NonParsedRegistryItem } from '@launcher/utils';
import { logger } from '@services/logger/logger.service';

const LIFEN_VALUE = 'Lifen';

export async function getPdfCreatorConfig() {
  try {
    const profiles = await window.electron.getDefaultPdfCreatorProfilesRegistryAccessor();

    logger.info('DESKTOP', `Analyzing ${profiles.length} profiles (PDFCreator)`);

    return await Promise.any(profiles.map(getLifenProfile));
  } catch (err) {
    if (err instanceof AggregateError) {
      logger.error('DESKTOP', 'No Lifen profile found (PDFCreator)', err);
    } else {
      logger.error('DESKTOP', 'Could not retrieve default Lifen Profile (PDFCreator)', err);
    }
    return null;
  }
}

export async function getLifenProfile(profile: NonParsedRegistryItem) {
  const name = await profile.get('Name');

  logger.info('DESKTOP', `Received default profile: ${name.value} (PDFCreator)`);

  if (LIFEN_VALUE !== name.value) throw new Error('PDFCreator profile is not Lifen');

  return {
    guid: await profile.get('Guid'),
    targetDirectory: await profile.get('TargetDirectory'),
  };
}
