import { isConnectorEnabledForIntegration } from '@lifen-integration/components/utils/integrations.util';
import { Selector } from '@reduxjs/toolkit';

import {
  IntegrationRequest,
  IntegrationRequestDto,
  IntegrationsDashboardType,
  Patient,
} from '@honestica/core-apps-common/types';

import {
  selectDetailDocument,
  selectDetailDocumentDto,
  selectDetailDocumentMeta,
  selectDetailViewState,
  selectDocumentsState,
} from '@store/documents/documents.selector';
import {
  INITIAL_WORKLIST_INTEGRATION_DOCUMENT_METADATA,
  WorklistDetailViewState,
  WorklistIntegrationRequestMetadata,
} from '@store/documents/integrations/worklist.state';
import { getPatientEntities } from '@store/entities/entities.selector';
import { isSuspended } from '@utils/document.util';
import { isIntegrationRequestCancelled } from '@utils/integrations.util';

import { State } from '../../reducer';

/*
 * DOCUMENT DETAIL VIEW GENERICS
 */

export const selectIntegrationRequestDetail = (state: State) =>
  selectDetailViewState(state, IntegrationsDashboardType.Worklist) as WorklistDetailViewState;

export const selectIntegrationRequest: Selector<State, IntegrationRequest | undefined> = (state) =>
  selectDetailDocument(state, IntegrationsDashboardType.Worklist);

export const selectIntegrationRequestDto: Selector<State, IntegrationRequestDto | undefined> = (
  state,
) => selectDetailDocumentDto(state, IntegrationsDashboardType.Worklist);

export const selectIntegrationRequestMeta: Selector<State, WorklistIntegrationRequestMetadata> = (
  state,
) =>
  (selectDetailDocumentMeta(
    state,
    IntegrationsDashboardType.Worklist,
  ) as WorklistIntegrationRequestMetadata) || INITIAL_WORKLIST_INTEGRATION_DOCUMENT_METADATA;

export function selectWorklistDelayedDocumentIds(documentsState: State) {
  const subState = selectDocumentsState(documentsState, IntegrationsDashboardType.Worklist);
  return subState.dashboardView.delayedIdsToRefresh;
}

/*
 * WORKLIST DETAIL
 */

export const selectWorklistCurrentPatient: Selector<State, Patient | undefined> = (state) =>
  selectIntegrationRequest(state)?.patient?.patient;

export const selectIntegrationRequestId: Selector<State, string | undefined> = (state) =>
  selectIntegrationRequestDto(state)?.id;

export const selectWorklistDetailDocumentId: Selector<State, string | undefined> = (state) =>
  selectDocumentsState(state, IntegrationsDashboardType.Worklist).detailView.selectedId;

export const selectPatient: Selector<State, Patient | undefined> = (state) => {
  const patientId = selectIntegrationRequestDto(state)?.patient?.id;
  return patientId ? getPatientEntities(state)[patientId] : undefined;
};

export const selectIsHospitalBasePatientSearchLoading: Selector<State, boolean> = (state) =>
  // patient matching will be triggered by any of those 2 actions:
  selectIntegrationRequestMeta(state).updatePatient.isLoading ||
  selectIntegrationRequestMeta(state).refreshPatientMatch.isLoading;

const selectIsUpdatingIntegrationRequest: Selector<State, boolean> = (state) =>
  selectIntegrationRequestMeta(state).updatePeriodDate.isLoading ||
  selectIntegrationRequestMeta(state).updateDocType.isLoading ||
  selectIntegrationRequestMeta(state).updateIntegration.isLoading ||
  selectIntegrationRequestMeta(state).updatePatient.isLoading ||
  selectIntegrationRequestMeta(state).removePatient.isLoading ||
  selectIntegrationRequestMeta(state).updateMedicalExamType.isLoading ||
  selectIntegrationRequestMeta(state).updateDocumentTitle.isLoading ||
  selectIntegrationRequestMeta(state).updateOriginalSender.isLoading;

export const selectIsIntegrating: Selector<State, boolean> = (state) =>
  selectIntegrationRequestMeta(state).integrateDocument.isLoading;

export const selectAreAllWorklistDetailActionsDisabled: Selector<State, boolean> = (state) => {
  const integrationRequest = selectIntegrationRequest(state);
  const isDocumentFetching = selectIntegrationRequestDetail(state).documentRequest.isLoading;
  const isDuplicating = selectIntegrationRequestMeta(state).duplicateDocument.isLoading;

  const isRemovingDocument = selectIntegrationRequestMeta(state).cancelDocument.isLoading;
  const isUpdating = selectIsUpdatingIntegrationRequest(state);
  const isSending = selectIsIntegrating(state);
  const isDocumentDeleted = Boolean(
    integrationRequest && isIntegrationRequestCancelled(integrationRequest),
  );
  return (
    !integrationRequest ||
    isDocumentFetching ||
    isDuplicating ||
    isRemovingDocument ||
    isSending ||
    isUpdating ||
    isDocumentDeleted
  );
};
export const selectPatientsList: Selector<State, Patient[]> = (state) => {
  const { patientIds } = selectIntegrationRequestDetail(state);
  const patients = getPatientEntities(state);
  return patientIds.map((id) => patients[id]);
};

export const selectIsSuspended: Selector<State, boolean> = (state) => {
  const document = selectIntegrationRequestDto(state);
  if (!document) {
    return false;
  }
  return isSuspended(document);
};

export const selectActionsDisabled: Selector<State, boolean> = (state) =>
  selectIsSuspended(state) && selectAreAllWorklistDetailActionsDisabled(state);

export const selectIsConnectorEnabledForIntegration: Selector<State, boolean> = (state) => {
  const document = selectIntegrationRequestDto(state);
  return isConnectorEnabledForIntegration(document);
};
