import { logger } from '@services/logger/logger.service';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { markAllAsRead, markDocumentAsRead, markDocumentAsUnread } from '@api';

import { OneAction } from '../../documents.actions';
import { ErroredDocumentsActions } from '../errored.slice';

function* markOneAsReadSaga(action: OneAction) {
  try {
    yield call(markDocumentAsRead, action.payload.id);
    yield put(ErroredDocumentsActions.markOneAsReadSuccess(action.payload));
  } catch (error) {
    logger.error('LOGIC', 'Failed to mark document as read', error);
    yield put(
      ErroredDocumentsActions.markOneAsReadFailure({
        id: action.payload.id,
        error,
      }),
    );
  } finally {
    yield put(ErroredDocumentsActions.fetchMany({}));
  }
}

function* markOneAsUnreadSaga(action: OneAction) {
  try {
    yield call(markDocumentAsUnread, action.payload.id);
    yield put(ErroredDocumentsActions.markOneAsUnreadSuccess(action.payload));
  } catch (error) {
    logger.error('LOGIC', 'Failed to mark document as unread', error);
    yield put(
      ErroredDocumentsActions.markOneAsReadFailure({
        id: action.payload.id,
        error,
      }),
    );
  } finally {
    yield put(ErroredDocumentsActions.fetchMany({}));
  }
}

function* markAllAsReadSaga() {
  try {
    yield call(markAllAsRead);
    yield put(ErroredDocumentsActions.markAllAsReadSuccess());
  } catch (error) {
    logger.error('LOGIC', 'Failed to mark all documents as read', error);
    yield put(
      ErroredDocumentsActions.markAllAsReadFailure({
        error,
      }),
    );
  } finally {
    yield put(ErroredDocumentsActions.fetchMany({}));
  }
}

export function* erroredDocumentsSagas() {
  yield all([
    takeLatest(ErroredDocumentsActions.markOneAsRead.type, markOneAsReadSaga),
    takeLatest(ErroredDocumentsActions.markOneAsUnread.type, markOneAsUnreadSaga),
    takeLatest(ErroredDocumentsActions.markAllAsRead.type, markAllAsReadSaga),
  ]);
}
