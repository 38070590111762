import {
  Auth0User,
  AuthData,
  AuthWorkspace,
  AuthenticatedUser,
  FullAuthUser,
  LegacyAuth0User,
  UserSettings,
} from '@honestica/core-apps-common/types';

import { USER_APPNAME_KEY, USER_INFO_KEY } from './constants';

const getWorkspaceName = (id: string, allWorkspaces: AuthWorkspace[]) =>
  allWorkspaces.find((workspace) => workspace.id === id)?.name ?? '';

export const auth0UserToUser = ({
  auth0User,
  token,
  isAuthenticated,
}: {
  auth0User: Auth0User;
  token: string | undefined;
  isAuthenticated: boolean;
}): FullAuthUser | undefined => {
  const {
    apiUrl,
    baseSettingsUrl,
    currentApiDomain,
    currentWorkspaceId,
    dematPatientSendingProhibited,
    directMail,
    ensSendingAuthorized,
    preferredLocale,
    identities,
    immediateModeActivated,
    insCoverAuthorized,
    integrations,
    devices,
    automaticEnsSendingDoctypes,
    internalSendingKeywords,
    internalSendingProhibited,
    mainRecipientOnlyActivated,
    patientAutomaticResending,
    patientSendingProhibited,
    patientSendingProhibitedValue,
    postalShippingType,
    postMailColorActivated,
    readyToSendAutomaticSending,
    sendingProhibited,
    sendingTemplate,
    mdnRequestAuthorized,
    uuid,
    viewerUrl,
    workspaces,
    integrationInsCoverAuthorized,
    sendingShortcutEnabled,
  } = auth0User[USER_INFO_KEY];
  if (!token) {
    return undefined;
  }
  return {
    uuid,
    isAuthenticated,
    firstName: auth0User.given_name ?? '',
    lastName: auth0User.family_name ?? '',
    email: auth0User.email ?? '',
    preferredLocale,
    identitiesId: identities,
    integrations, // fix desktop v4 users which rely on integrations in iframe
    devices,
    workspaces,
    currentWorkspaceId,
    currentApiDomain,
    settings: JSON.parse(
      JSON.stringify({
        automaticEnsSendingDoctypes,
        dematPatientSendingProhibited,
        directMail,
        ensSendingAuthorized,
        immediateModeActivated,
        insCoverAuthorized,
        internalSendingKeywords,
        internalSendingProhibited,
        mainRecipientOnlyActivated,
        mdnRequestAuthorized,
        patientAutomaticResending,
        patientSendingProhibited,
        patientSendingProhibitedValue,
        postalShippingType,
        postMailColorActivated,
        readyToSendAutomaticSending,
        sendingProhibited,
        sendingTemplate,
        integrationInsCoverAuthorized,
        sendingShortcutEnabled,
      }),
    ),
    baseSettingsUrl,
    apiUrl,
    viewerUrl,
    appName: auth0User[USER_APPNAME_KEY],
    token,
  };
};

export const userToAuthData = ({
  user,
  token,
  isAuthenticated,
}: {
  user: FullAuthUser;
  token: string;
  isAuthenticated: boolean;
}): AuthData => {
  const {
    firstName,
    lastName,
    email,
    uuid,
    appName,
    apiUrl,
    currentWorkspaceId,
    preferredLocale,
    workspaces,
    identitiesId,
    integrations,
    devices,
    baseSettingsUrl,
    viewerUrl,
    settings,
    currentApiDomain,
  } = user;
  const currentWorkspaceName = getWorkspaceName(currentWorkspaceId, workspaces);
  const userData: AuthenticatedUser = {
    firstname: firstName,
    lastname: lastName,
    email,
    uuid,
    [USER_INFO_KEY]: {
      uuid,
      apiUrl,
      currentWorkspaceId,
      currentWorkspaceName,
      preferredLocale,
      identities: identitiesId,
      integrations, // fix desktop v4 users which rely on integrations in iframe
      devices,
      baseSettingsUrl,
      viewerUrl,
      workspaces,
      currentApiDomain,
      ...settings,
    },
    [USER_APPNAME_KEY]: appName,
  };
  return {
    isAuth: isAuthenticated,
    token,
    userData,
  };
};

export const getDefaultUserSettings = (): UserSettings => ({
  automaticEnsSendingDoctypes: [],
  dematPatientSendingProhibited: false,
  directMail: false,
  ensSendingAuthorized: false,
  immediateModeActivated: true,
  insCoverAuthorized: false,
  internalSendingKeywords: '',
  internalSendingProhibited: 'no',
  mainRecipientOnlyActivated: false,
  mdnRequestAuthorized: true,
  patientAutomaticResending: 'no',
  patientSendingProhibited: false,
  patientSendingProhibitedValue: 'no',
  postalShippingType: 'normal',
  postMailColorActivated: false,
  readyToSendAutomaticSending: false,
  sendingProhibited: false,
  sendingTemplate: 'generic',
  integrationInsCoverAuthorized: false,
  sendingShortcutEnabled: true,
});

export const legacyToAuth0User = (legacyUser: LegacyAuth0User): Auth0User => {
  const { apiDomain, apiUrl, defaultIdentity, identities, integrations, devices, viewerUrl } =
    legacyUser['http://lifen.fr/workspaces'][0];
  return {
    'http://lifen.fr/applicationName': legacyUser['http://lifen.fr/appname'],
    email: '',
    email_verified: true,
    family_name: '',
    given_name: '',
    name: '',
    nickname: '',
    sub: '',
    updated_at: '',
    'http://lifen.fr/userInfo': {
      apiUrl,
      baseSettingsUrl: '',
      currentApiDomain: apiDomain,
      currentWorkspaceId: defaultIdentity,
      currentWorkspaceName: `Espace ${apiDomain}`,
      preferredLocale: '',
      identities,
      integrations, // fix desktop v4 users which rely on integrations in iframe
      devices,
      uuid: legacyUser['http://lifen.fr/user-uuid'],
      viewerUrl,
      workspaces: [],
      ...getDefaultUserSettings(),
    },
  };
};
