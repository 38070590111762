import { validate } from 'email-validator';

export function validateEmail(
  email: string,
  { allowBlank }: { allowBlank: boolean } = { allowBlank: false },
): boolean {
  return validate(email) || (email.length === 0 && allowBlank);
}

export function isApiCryptOrMssEmail(email: string): boolean {
  if (email.includes('@')) {
    return email.split('@')[1].includes('mssante') || email.split('@')[1].includes('apicrypt');
  }

  return false;
}

export function isLifenInternalEmail(email: string): boolean {
  return !!/@(test\.)?lifen\.fr$/i.exec(email);
}
