import { useEffect } from 'react';

import { registerAutoUpdaterListeners, setAppUpdate } from '@launcher/helpers/desktop';
import { isDesktopApp } from '@launcher/utils';
import { useSelector } from 'react-redux';

import { useOnMount } from '@honestica/core-apps-common/hooks';
import { DesktopReleaseChannel } from '@honestica/core-apps-common/types';

import { selectDesktopSettings } from '@store/desktop/desktop.selector';
import { State } from '@store/reducer';
import { getFeatureFlags } from '@store/user/user.selector';

export function useAutoUpdate() {
  const flags = useSelector<State, Partial<{ 'desktop-release-channel': DesktopReleaseChannel }>>(
    getFeatureFlags,
  );
  const settings = useSelector(selectDesktopSettings);

  const releaseChannel = flags['desktop-release-channel'] || null;

  useEffect(() => {
    if (releaseChannel && isDesktopApp()) {
      setAppUpdate(releaseChannel, settings.releaseServerURL);
    }
  }, [releaseChannel, settings.releaseServerURL]);

  useOnMount(() => {
    if (isDesktopApp()) {
      registerAutoUpdaterListeners();
    }
  });
}
