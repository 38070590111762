import { SendingRequest, SendingRequestDto } from '@honestica/core-apps-common/types';

import { ERRORS } from '@constants/documents.constants';
import { XhrStatus } from '@store/types';

import {
  BaseDashboardViewState,
  BaseDetailViewState,
  BaseEntitiesState,
  INITIAL_DASHBOARD_VIEW_STATE,
  INITIAL_DETAIL_VIEW_STATE,
  INITIAL_SEARCH_PARAMS,
  XHR_STATUS_IDLE,
} from '../documents.state';

export type ErroredDocumentDashboardType = typeof ERRORS;

export interface ErroredDocumentsState {
  entities: BaseEntitiesState<ErroredDocumentDtoWithMeta>;
  dashboardView: BaseDashboardViewState;
  detailView: BaseDetailViewState; // TODO : temporary DocumentsState fix
}

export const INITIAL_ERRORED_DOCUMENTS_STATE: ErroredDocumentsState = {
  entities: {},
  dashboardView: {
    ...INITIAL_DASHBOARD_VIEW_STATE,
    searchParams: { ...INITIAL_SEARCH_PARAMS, showRead: false, hideOtherRequesters: true },
  },
  detailView: INITIAL_DETAIL_VIEW_STATE,
};

export type ErroredDocumentDtoWithMeta = {
  resource: SendingRequestDto;
  meta: ErroredDocumentMetadata;
};

export type ErroredSendingRequestWithMeta = {
  resource: SendingRequest;
  meta: ErroredDocumentMetadata;
};

export interface ErroredDocumentMetadata {
  markAsRead: XhrStatus;
  markAsUnread: XhrStatus;
  docDashboardType: ErroredDocumentDashboardType;
}

export type ErroredDocumentMetadataType = keyof ErroredDocumentMetadata;

export const INITIAL_ERRORED_DOCUMENT_METADATA: ErroredDocumentMetadata = {
  markAsRead: XHR_STATUS_IDLE,
  markAsUnread: XHR_STATUS_IDLE,
  docDashboardType: ERRORS,
};
