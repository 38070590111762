import { useCallback, useEffect, useMemo, useRef } from 'react';

import { LOGIN_PARTITION } from '@launcher/constants';
import {
  getLoginWebviewSearchParams,
  resetAuthFlowWhenSessionIsExpiredOfWebview,
} from '@launcher/helpers/desktop';
import { useAuthenticateUser } from '@launcher/hooks/useAuthenticateUser.hook';
import { isDesktopApp } from '@launcher/utils/desktop/desktop.utils';
import { logger } from '@services/logger/logger.service';
import { useSelector } from 'react-redux';

import { Page, Spinner } from '@honestica/ui-kit/src';

import {
  selectDesktopIsLoadingSettings,
  selectDesktopSsoConnectionSettings,
  selectDesktopUsernameSettings,
} from '@store/desktop/desktop.selector';
import { getIsAuthenticated } from '@store/user/user.selector';

export const DesktopLoginProvider = ({ children }: { children: React.ReactNode }) => {
  const onAuthenticateUserSuccess = useAuthenticateUser();
  const desktopIsLoadingSettings = useSelector(selectDesktopIsLoadingSettings);
  const desktopUsernameSettings = useSelector(selectDesktopUsernameSettings);
  const desktopSssoConnectionSettings = useSelector(selectDesktopSsoConnectionSettings);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const webviewRef = useRef<HTMLWebViewElement | null>(null);
  const logCallback = (e: any) => {
    logger.info('LOGIC', `LOGIN_WEBVIEW: ${e.message}`);
  };

  /*
  Cleanup function
  */
  useEffect(
    () => () => {
      const ref = webviewRef.current;
      ref?.removeEventListener('console-message', logCallback);
    },
    [],
  );

  /*
  If webview is found, register listener to see webview logs in the main console
  */
  const setWebviewRef = useCallback((node) => {
    if (!webviewRef.current && !node) {
      return;
    }

    if (node && !webviewRef.current) {
      webviewRef.current = node;
    }

    const ref = webviewRef.current;

    ref?.addEventListener('console-message', logCallback);

    // temp workarround for auth0 bug https://honestica.atlassian.net/browse/BUGS-2590
    resetAuthFlowWhenSessionIsExpiredOfWebview(ref, isDesktopApp(), logger);
  }, []);

  /*
  Only on Desktop, wait for user data to be successfully loaded in the webview
  */
  useEffect(() => {
    if (isDesktopApp()) {
      const interval = setInterval(async () => {
        const loginWebview = document.getElementById('loginWebview');
        if (!isAuthenticated && loginWebview) {
          /*
           * Get the workspaceId from webView and trigger
           * $$getLoginInfo to send auth data back to the main view
           */

          const user = await (loginWebview as any).executeJavaScript(
            'window.__$$getLoginInfo && window.__$$getLoginInfo()',
          );
          if (user) {
            clearInterval(interval as NodeJS.Timeout);
            logger.info('LOGIC', `DESKTOP_LOGIN_USER_TO_MAIN_WEBVIEW`);

            await onAuthenticateUserSuccess(user);
          }
        }
      }, 2000); // we might trigger an AuthenticationError.Default if not done after a certain time, see authenticate in user.saga

      return function cleanup() {
        clearInterval(interval);
      };
    }

    return undefined;
  }, [isAuthenticated, onAuthenticateUserSuccess]);

  // We set secific search params on current url that indicate we are on the webview
  const searchParams = useMemo(
    () =>
      isDesktopApp()
        ? getLoginWebviewSearchParams(desktopUsernameSettings, desktopSssoConnectionSettings)
        : undefined,
    [desktopSssoConnectionSettings, desktopUsernameSettings],
  );

  // Wait for desktop settings to be fully loaded
  if (isDesktopApp() && desktopIsLoadingSettings) {
    logger.info('LOGIC', 'Loading desktop Settings');
    return (
      <Page.Empty>
        <Spinner />
      </Page.Empty>
    );
  }

  // We render login process in a webview until user has been authenticated (only on desktop)
  // desktop settings contains `username` and `ssoConnection` which can be passed as optional params to the login page
  // code reference:  https://github.com/honestica/lifen-core-apps/blob/93b0f0f912ed6ecb446a547387b38012d5c5b5be/packages/client/src/launcher/helpers/desktop/loginWebview.helper.ts#L32
  if (isDesktopApp() && !isAuthenticated) {
    logger.info('LOGIC', `LOGIN_WEBVIEW_SEARCHPARAMS: ${searchParams}`);
    const src = `${window.location.origin}/apps/?${searchParams}`;
    logger.info('LOGIC', `LOGIN_WEBVIEW_URL: ${src}`);

    return (
      /*
       * On desktop, we load the login page inside a
       * webview that takes the main app's place
       * so it does not have access to Node/Electron.
       */
      <>
        <webview
          ref={setWebviewRef}
          // eslint-disable-next-line react/no-unknown-property
          partition={LOGIN_PARTITION}
          data-testid="desktop-webview"
          id="loginWebview"
          src={src}
          style={{ position: 'absolute', width: '100%', height: '100vh' }}
          // eslint-disable-next-line react/no-unknown-property
          allowpopups={'true' as any} // there is a bug in some electron version we use and the setting need to be a string
        />
      </>
    );
  }

  return <>{children}</>;
};
