export * from './errors.constants';
export * from './domain.constants';
export * from './urls';

export enum MimeType {
  Pdf = 'application/pdf',
  Html = 'application/html',
  Jpeg = 'image/jpeg',
  Png = 'image/png',
  Hl7 = 'application/hl7',
  Text = 'application/text',
  Rfc822 = 'application/message/rfc822',
  MsWordLegacy = 'application/msword',
  MsWord = 'application/vnd.openxmlformats - officedocument.wordprocessingml.document',
}

export const DEFAULT_PER_PAGE = 20;
export const DEFAULT_FIRST_PAGE = 1;
export const DEFAULT_MAX_FILE_SIZE = 15; // 15Mb for file uplaod
export const INPUT_SEARCH_DEBOUNCE = 500;
export const BATCH_ACTIONS_DEBOUNCE = 400;
export const INPUT_SEARCH_MINIMUM_CHARACTERS = 3;

export const ROLLBACK_PATIENTS_IMPORT_QUEUE_NAME = 'rollback-patients-import-queue';
