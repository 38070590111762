import {
  DocumentPatient,
  DocumentRecipientBundle,
  IntegrationRequest,
  IntegrationRequestDto,
  Patient,
  SendingRequest,
  SendingRequestDto,
} from '../types';
import { isPatient, isProfessional } from '../validators';

import { formatDateShort } from './date.utils';
import { isNotNull } from './isNotNull.util';
import { parsePatientName } from './patient.utils';
import { snakelizeSkipDash } from './string.utils';

export function isDocumentPatient(val: any): val is DocumentPatient {
  return (
    !!val.patient &&
    Array.isArray(val.telecoms) &&
    Array.isArray(val.addresses) &&
    isPatient(val.patient)
  );
}

export const isDocumentRecipientBundle = (val: any): val is DocumentRecipientBundle =>
  val.entity &&
  Array.isArray(val.telecoms) &&
  Array.isArray(val.addresses) &&
  isProfessional(val.entity);

export function getPdfTitleFromDocument(
  document: SendingRequest | SendingRequestDto | IntegrationRequest | IntegrationRequestDto,
  documentTypeDisplayName: string,
  patient?: Patient,
  index?: number,
) {
  const p: Partial<Patient> =
    patient ?? ((document as SendingRequest | IntegrationRequest)?.patient?.patient || {});
  const name = parsePatientName(p);

  return composeFileName({
    lastname: name?.lastName,
    firstname: name?.firstName,
    birthdate: p.birthdate,
    documentTypeDisplayName,
    id: document.id,
    index,
    ext: 'pdf',
  });
}

export function composeFileName(prop: {
  lastname?: string;
  firstname?: string;
  birthdate?: string;
  documentTypeDisplayName?: string;
  id: string;
  index?: number;
  ext: string;
}) {
  const dateFormated = formatDateShort(prop.birthdate)?.replace(/\//g, '_') || null;

  const name = [
    prop.lastname ? snakelizeSkipDash(prop.lastname).toUpperCase() : null,
    prop.firstname ? snakelizeSkipDash(prop.firstname) : null,
    dateFormated,
    prop.documentTypeDisplayName || null,
    prop.id,
    typeof prop.index === 'number' ? prop.index : null,
  ]
    .filter((v) => isNotNull(v))
    .join('_');

  return `${name}.${prop.ext.toLowerCase()}`;
}

export function extractFilenameAndExtension(filename: string) {
  const nameArr = filename.toLowerCase().split('.');
  return {
    name:
      nameArr.length > 1 ? nameArr.slice(0, nameArr.length - 1).join('.') : filename.toLowerCase(),
    ext: nameArr.length > 1 ? nameArr[nameArr.length - 1] : '',
  };
}
