import { createAction } from '@reduxjs/toolkit';

import { List, Practitioner, SearchParams } from '@honestica/core-apps-common/types';

const REDUCER_NAME = 'practitionerDirectory';

const FIND_PRACTITIONERS = `${REDUCER_NAME}/find`;

export const findPractitioners = createAction<SearchParams>(FIND_PRACTITIONERS);
export const findPractitionersSuccess = createAction<List<Practitioner>>(
  `${FIND_PRACTITIONERS}Success`,
);
export const findPractitionersFailure = createAction<unknown>(`${FIND_PRACTITIONERS}Failure`);

export type FindPractitioners = typeof findPractitioners;
