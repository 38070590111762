import { Address } from '../types';

export const FRENCH_ZIP_CODE_REGEX = /^((0[1-9])|([1-8][0-9])|(9[0-8])|(2[ab]))[0-9]{3}$/;
export function getShortAddresses(addresses: Address[]) {
  const addressMap: { [p: string]: string } = addresses.reduce(
    (acc: { [p: string]: string }, address: Address) => {
      const short = `${address?.postalCode || ''} ${address?.city || ''}`;
      acc[short] = short;
      return acc;
    },
    {},
  );

  return Object.keys(addressMap);
}

export function isValidAddress(
  address: Address | undefined,
  options: { allowBlank?: boolean } = { allowBlank: false },
): boolean {
  if (!address) return false;

  const { lines, postalCode, city } = address;
  const line2 = lines[1];
  const valid =
    line2 !== '' && postalCode !== '' && !!FRENCH_ZIP_CODE_REGEX.exec(postalCode) && city !== '';

  if (!options.allowBlank) {
    return valid;
  }
  const blank =
    (!line2 || line2 === '') && (!postalCode || postalCode === '') && (!city || city === '');
  return valid || blank;
}

/**
 * returns address in compact format: "12 rue de la poste, 75009 Paris"
 */
export const formatAddressInline = (address: Address): string =>
  `${address.lines[1] ?? ''}, ${address.postalCode ?? ''} ${address.city ?? ''}`;

/**
 * should have a line with postalCode and city name: 75009 Paris / EC3N 4AB London
 */
export function validateCityAddressLine({
  lines,
  city,
  postalCode,
}: {
  lines: string[];
  city?: string;
  postalCode?: string;
}): boolean {
  if (postalCode && city) {
    return lines.some(
      (line) =>
        line.toLowerCase().includes(postalCode.toLowerCase()) &&
        line.toLowerCase().includes(city.toLowerCase()),
    );
  }
  return false;
}

export function isSameAddress(a1: Address, a2: Address) {
  if (a1.city !== a2.city) return false;
  if (a1.postalCode !== a2.postalCode) return false;
  return a1.lines.join('|') === a2.lines.join('|');
}
