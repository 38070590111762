import { CORE_APPS_INFO } from '@launcher/constants';
import { InErrorsDashboardSearchParams } from '@lifen-sending/features/in-errors/store/inErrors.slice';
import { AnalyticsBrowser, Context, Options } from '@segment/analytics-next';
import { EnvManager } from '@services/environment/environment.service';
import { logger } from '@services/logger/logger.service';

import {
  AppKeys,
  DateRangeValue,
  DesktopReleaseChannel,
  DocumentVerificationStatus,
  FeatureFlags,
  Integration,
  IntegrationRequestDto,
  IntegrationsDashboardType,
  MedicalExamType,
  PatientMatchStatus,
  ProfessionalBaseType,
  SelectedMediumFilter,
  SendingRequestDto,
  SendingsDashboardType,
  TelecomType,
  UploadDocumentJob,
} from '@honestica/core-apps-common/types';

import { DocumentDashboardType } from '@store/documents/documents.state';
import { isEmailDematPatient } from '@utils/document.util';

import { version } from '../../../package.json';

import * as Segments from './typewriter/segment';

interface TrackingUserData {
  userLastName: string | undefined;
  userFirstName: string | undefined;
  userEmail: string | undefined;
  userWorkspaceId: string | undefined;
  userId: string | undefined;
  currentApiDomain: string | undefined;
  locale: string | undefined;
}

export enum TrackingEventName {
  ActionErrorDisplayed = 'Action Error Displayed',
  AppUpdateCompleted = 'App Update Completed',
  AppUpdateFailed = 'App Update Failed',
  AppUpdateStarted = 'App Update Started',
  BatchActionClicked = 'Batch Action Clicked',
  BatchDocumentsModified = 'Batch Documents Updated',
  BatchFileUploadEnd = 'Batch File Upload End',
  BatchFileUploadStart = 'Batch File Upload Start',
  CancelInfoMessage = 'Cancel Info Message',
  CloseEnsConversation = 'Close Ens Conversation',
  ContactCardViewed = 'Contact Card Viewed',
  ContactSearched = 'Contact Searched',
  CustomEnsMessageAdded = 'Custom Ens Message Added',
  InErrorsDashboardFiltered = 'In Errors Dashboard Filtered',
  DashboardFiltered = 'Dashboard Filtered',
  DeferredDone = 'Deferred Done',
  DocumentActionClicked = 'Document Action Clicked',
  DocumentDatePeriodModified = 'Document Date Period Modified',
  DocumentDeleted = 'Document Deleted',
  DocumentIdCopied = 'Document Id Copied',
  DocumentIntegrated = 'Document Integrated',
  DocumentNavigatorClicked = 'Document Navigator Clicked',
  DocumentOriginalSenderModified = 'Document Original Sender Modified',
  DocumentPrinted = 'Document Printed',
  DocumentSenderModified = 'Document Sender Modified',
  DocumentTitleModified = 'Document Title Modified',
  DocumentTypeModified = 'Document Type Modified',
  DraftDashboardGlobalNavigationLinkClicked = 'Draft Dashboard Global Navigation Link Clicked',
  DraftDashboardIdentityNavigationLinkClicked = 'Draft Dashboard Identity Navigation Link Clicked',
  EncounterListClicked = 'Encounter List Clicked',
  EncounterUpdated = 'Encounter Updated',
  FileUploaded = 'File Uploaded',
  FileUploadFailed = 'File Upload Failed',
  GroupedDocumentReordered = 'Grouped Document Reordered',
  IntegrationMatchDone = 'Integration Match Done',
  MedicalExamTypeModified = 'Medical Exam Type Modified',
  MessageSent = 'Message Sent',
  NotificationClicked = 'Notification Clicked',
  NotificationMenuOpened = 'Notification Menu Opened',
  PageAccountCreationViewed = 'Page Account Creation Viewed',
  PageArchiveDashboardViewed = 'Page Archive Dashboard Viewed',
  PageDraftDashboardViewed = 'Page Draft Dashboard Viewed',
  PageErrorDashboardViewed = 'Page Error Dashboard Viewed',
  PageInboxDashboardViewed = 'Page Inbox Dashboard Viewed',
  PageInErrorsDashboardViewed = 'Page In Errors Dashboard Viewed',
  PageInboxDetailViewed = 'Page Inbox Detail Viewed',
  PageIntegratedDashboardViewed = 'Page Integrated Dashboard Viewed',
  PageIntegratedDetailViewed = 'Page Integrated Detail Viewed',
  PageSentDashboardViewed = 'Page Sent Dashboard Viewed',
  PageWorklistDashboardViewed = 'Page Worklist Dashboard Viewed',
  PageWorklistDetailViewed = 'Page Worklist Detail Viewed',
  PatientAdded = 'Patient Added',
  PatientCardViewed = 'Patient Card Viewed',
  PatientCreateClicked = 'Patient Create Clicked',
  PatientCreated = 'Patient Created',
  PatientDirectoryImportDone = 'Patient Directory Import Done',
  PatientDirectoryImportFailed = 'Patient Directory Import Failed',
  PatientDirectoryImportStarted = 'Patient Directory Import Started',
  PatientMatchRelaunched = 'Patient Match Relaunched',
  PatientModifyClicked = 'Patient Modify Clicked',
  PatientRemoved = 'Patient Removed',
  PatientSearched = 'Patient Searched',
  PatientUpdated = 'Patient Updated',
  PdfViewerLongPress = 'PDF Viewer Long Press',
  RecipientAdded = 'Recipient Added',
  RecipientRemoved = 'Recipient Removed',
  RecipientUpdated = 'Recipient Updated',
  RemoveInfoMessage = 'Remove Info Message',
  SelectedIdentityUpdated = 'Selected Identity Updated',
  UserForcedLogout = 'User Forced Logout',
  VerificationAction = 'Verification Action',
}

export class Tracking {
  private readonly version: string = version;

  private featureFlags: string[] | undefined;

  private desktopReleaseChanel: DesktopReleaseChannel | undefined;

  private userId: TrackingUserData['userId'];

  private userEmail: TrackingUserData['userEmail'];

  private userLastName: TrackingUserData['userLastName'];

  private userFirstName: TrackingUserData['userFirstName'];

  private userWorkspaceId: TrackingUserData['userWorkspaceId'];

  private currentApiDomain: TrackingUserData['currentApiDomain'];

  private locale: TrackingUserData['locale'];

  private readonly analytics: AnalyticsBrowser;

  public constructor(analyticsClient: AnalyticsBrowser) {
    this.analytics = analyticsClient;
  }

  private currentAppOpened: AppKeys;

  public initUserData({
    userId,
    userEmail,
    userWorkspaceId,
    userLastName,
    userFirstName,
    currentApiDomain,
    locale,
  }: TrackingUserData): void {
    this.userId = userId;
    this.userWorkspaceId = userWorkspaceId;
    this.userEmail = userEmail;
    this.userLastName = userLastName;
    this.userFirstName = userFirstName;
    this.currentApiDomain = currentApiDomain;
    this.locale = locale;
    this.initAnalytics();
  }

  public initFeatureFlags(featureFlags: FeatureFlags): void {
    logger.info('LOGIC', 'Tracking - initFeatureFlags');
    this.featureFlags = Object.entries(featureFlags).reduce(
      (list: string[], [key, value]) => (value === true ? [...list, key] : list),
      [],
    );
    this.desktopReleaseChanel =
      featureFlags['desktop-release-channel'] ?? DesktopReleaseChannel.Stable;
  }

  private initAnalytics() {
    this.analytics
      .load(
        {
          writeKey: EnvManager.getSegmentKey() ?? '',
          cdnURL: `https://${EnvManager.getBaseUrlProxy()}/private/proxy/cdnsegment`,
        },
        {
          integrations: {
            'Segment.io': {
              apiHost: `${EnvManager.getBaseUrlProxy()}/private/proxy/segment`,
            },
          },
        },
      )
      .catch((err) => logger.error('LOGIC', `Failed to load analytics`, err));
  }

  private getCurrentApp() {
    return Object.values(CORE_APPS_INFO).find((app) => window.location.pathname.includes(app.path))
      ?.name;
  }

  private get build(): string | undefined {
    return window.electron?.getElectronVersion?.();
  }

  private get options(): Options {
    return {
      context: {
        screen: {
          width: window.screen.width.toString(),
          height: window.screen.height.toString(),
        },
      },
      traits: {
        currentWorkspaceFhirReference: this.userWorkspaceId,
        currentApp: this.getCurrentApp(),
        launcherVersion: this.version,
        desktopVersion: this.build,
        osVersion: window.electron?.getOsVersion?.(),
        osType: window.electron?.getOsType?.(),
        osRelease: window.electron?.getOsRelease?.(),
        locale: this.locale,
      },
    };
  }

  public identify(): void {
    logger.info('LOGIC', 'Tracking - identify');
    try {
      this.analytics.identify(
        this.userId || '',
        {
          firstName: this.userFirstName, // required for zendesk integration
          lastName: this.userLastName, // required for zendesk integration
          email: this.userEmail,
          featureFlags: this.featureFlags,
          launcherVersion: this.version,
          desktopVersion: this.build,
          desktopReleaseChannel: this.desktopReleaseChanel,
          osVersion: window.electron?.getOsVersion?.(),
          osType: window.electron?.getOsType?.(),
          osRelease: window.electron?.getOsRelease?.(),
          currentWorkspaceFhirReference: this.userWorkspaceId,
          lifenDomain: this.currentApiDomain,
          ...this.options.context?.screen,
        },
        { Intercom: { hideDefaultLauncher: true } } as any,
      );
    } catch (error) {
      logger.error('LOGIC', 'Tracking - identify - Segment analytics client is not loaded', error);
    }
  }

  public trackBatchFileUploadStart({
    documentsCount,
    uploadSource,
  }: {
    documentsCount: number;
    uploadSource: UploadDocumentJob['uploadSource'];
  }): void {
    const sourceMap: Record<
      UploadDocumentJob['uploadSource'],
      Segments.BatchFileUploadStartSource
    > = {
      watcher: Segments.BatchFileUploadStartSource.Outbox,
      button: Segments.BatchFileUploadStartSource.Button,
      drop: Segments.BatchFileUploadStartSource.DragDrop,
      opening: Segments.BatchFileUploadStartSource.Opening,
    };
    this.analytics.track(
      TrackingEventName.BatchFileUploadStart,
      { documentsCount, source: sourceMap[uploadSource] },
      this.options,
    );
  }

  public trackBatchFileUploadEnd(documentsCount: number): void {
    this.analytics.track(TrackingEventName.BatchFileUploadEnd, { documentsCount }, this.options);
  }

  public trackFileUploaded({
    documentId,
    uploadSource,
  }: {
    documentId: string;
    uploadSource: UploadDocumentJob['uploadSource'];
  }): void {
    const sourceMap: Record<
      UploadDocumentJob['uploadSource'],
      Segments.BatchFileUploadStartSource
    > = {
      watcher: Segments.BatchFileUploadStartSource.Outbox,
      button: Segments.BatchFileUploadStartSource.Button,
      drop: Segments.BatchFileUploadStartSource.DragDrop,
      opening: Segments.BatchFileUploadStartSource.Opening,
    };
    this.analytics.track(
      TrackingEventName.FileUploaded,
      { fhirReference: documentId, source: sourceMap[uploadSource] },
      this.options,
    );
  }

  public trackFileUploadFailed({ errorCause }: { errorCause?: string }): void {
    const props: Segments.FileUploadFailed = {
      errorCause,
    };

    this.analytics.track(TrackingEventName.FileUploadFailed, props, this.options);
  }

  public trackDraftDashboardNavClick(identity?: string): void {
    if (identity) {
      const props: Segments.DraftDashboardIdentityNavigationLinkClicked = {
        identity,
      };
      this.analytics.track(
        TrackingEventName.DraftDashboardIdentityNavigationLinkClicked,
        props,
        this.options,
      );
    } else {
      this.analytics.track(
        TrackingEventName.DraftDashboardGlobalNavigationLinkClicked,
        {},
        this.options,
      );
    }
  }

  public trackPageViewed(
    // TODO: Create a type for this
    dashboardType:
      | SendingsDashboardType
      | IntegrationsDashboardType
      | 'Notification'
      | 'Error'
      | 'InErrors',
  ): void {
    switch (dashboardType) {
      case SendingsDashboardType.Archive:
        this.analytics.track(TrackingEventName.PageArchiveDashboardViewed, {}, this.options);
        break;
      case SendingsDashboardType.Draft:
        this.analytics.track(TrackingEventName.PageDraftDashboardViewed, {}, this.options);
        break;
      case SendingsDashboardType.Inbox:
        this.analytics.track(TrackingEventName.PageInboxDashboardViewed, {}, this.options);
        break;
      case SendingsDashboardType.Sent:
        this.analytics.track(TrackingEventName.PageSentDashboardViewed, {}, this.options);
        break;
      case IntegrationsDashboardType.Worklist:
        this.analytics.track(TrackingEventName.PageWorklistDashboardViewed, {}, this.options);
        break;
      case IntegrationsDashboardType.Integrated:
        this.analytics.track(TrackingEventName.PageIntegratedDashboardViewed, {}, this.options);
        break;
      case 'Notification':
        this.analytics.track(TrackingEventName.NotificationMenuOpened, {}, this.options);
        break;
      case 'Error':
        this.analytics.track(TrackingEventName.PageErrorDashboardViewed, {}, this.options);
        break;
      case 'InErrors':
        this.analytics.track(TrackingEventName.PageInErrorsDashboardViewed, {}, this.options);
        break;
      default:
        break;
    }
  }

  public trackDetailPageViewed(
    dashboardType: SendingsDashboardType | IntegrationsDashboardType,
    document: IntegrationRequestDto | SendingRequestDto,
    pageCount: number,
  ): void {
    const sourceType = (document as IntegrationRequestDto).source?.type ?? undefined;
    const props: Segments.PageIntegratedDetailViewed | Segments.PageWorklistDetailViewed = {
      status: document.verificationStatus,
      pageCount,
      source: sourceType,
    };
    if (document.id) {
      switch (dashboardType) {
        case IntegrationsDashboardType.Worklist:
          this.analytics.track(TrackingEventName.PageWorklistDetailViewed, props, this.options);
          break;
        case IntegrationsDashboardType.Integrated:
          this.analytics.track(TrackingEventName.PageIntegratedDetailViewed, props, this.options);
          break;
        case SendingsDashboardType.Inbox:
          this.analytics.track(TrackingEventName.PageInboxDetailViewed, props, this.options);
          break;
        default:
          break;
      }
    }
  }

  public messageDocumentSent(document: SendingRequestDto, isBatchAction?: boolean): void {
    const { patient, documentsMerged, integration, sender } = document;
    const props: Segments.MessageSent = {
      communicationRequest: document.id,
      documentLoincType: document.documentType,
      dematPatient: isEmailDematPatient(document),
      senderFhirReference: `${sender.type}/${sender.id}`,
      isBatchAction,
    };

    if (documentsMerged && documentsMerged.length > 1) {
      props.isGrouped = document.documentsMerged ? document.documentsMerged.join(' ,') : undefined;
    }

    if (patient?.telecoms && patient?.telecoms.length > 1) {
      props.isDirectMail = true;
    }

    if ((integration as Integration)?.connectors.DMP) {
      let status = null;

      status = (integration as Integration).connectors.DMP?.isSelected
        ? Segments.DmpIntegration.Selected
        : Segments.DmpIntegration.Unselected;

      props.dmpIntegration = status;
    }

    if ((integration as Integration)?.connectors.EHR) {
      let status = Segments.DPIIntegration.Unselected;
      if ((integration as Integration).connectors.EHR?.isSelected) {
        status =
          (integration as Integration).connectors.EHR?.integrationMode === 'auto'
            ? Segments.DPIIntegration.Auto
            : Segments.DPIIntegration.Manual;
      }

      props.dpiIntegration = status;
    }

    this.analytics.track(TrackingEventName.MessageSent, props, this.options);
  }

  public documentRecipientUpdated({
    communicationRequest,
    fhirReference,
    telecoms,
    type,
  }: {
    communicationRequest: string;
    fhirReference: string;
    telecoms: TelecomType[];
    type: ProfessionalBaseType | 'Patient';
  }) {
    this.analytics.track(
      TrackingEventName.RecipientUpdated,
      { communicationRequest, fhirReference, telecoms, type },
      this.options,
    );
  }

  public documentRecipientAdded({
    communicationRequest,
    fhirReference,
    telecoms,
    type,
  }: {
    communicationRequest: string;
    fhirReference: string;
    telecoms: TelecomType[];
    type: ProfessionalBaseType | 'Patient';
  }) {
    this.analytics.track(
      TrackingEventName.RecipientAdded,
      { communicationRequest, fhirReference, telecoms, type },
      this.options,
    );
  }

  public documentRecipientRemoved({
    communicationRequest,
    fhirReference,
  }: {
    communicationRequest: string;
    fhirReference: string;
  }) {
    this.analytics.track(
      TrackingEventName.RecipientRemoved,
      { communicationRequest, fhirReference },
      this.options,
    );
  }

  public trackAccountCreationQuickview({
    fhirReference,
  }: {
    fhirReference: string | undefined;
  }): Promise<Context> {
    return this.analytics.track(
      TrackingEventName.PageAccountCreationViewed,
      { fhirReference, source: 'quickview' },
      this.options,
    );
  }

  public trackRemoveInfoMessage({ payloadType, id }: { payloadType: string; id: string }) {
    this.analytics.track(TrackingEventName.RemoveInfoMessage, { payloadType, id }, this.options);
  }

  public trackCancelInfoMessage({ payloadType, id }: { payloadType: string; id: string }) {
    this.analytics.track(TrackingEventName.CancelInfoMessage, { payloadType, id }, this.options);
  }

  public trackDeferredDone({ payloadType, id }: { payloadType: string; id: string }) {
    this.analytics.track(TrackingEventName.DeferredDone, { payloadType, id }, this.options);
  }

  // Lifen Integration

  public messageDocumentIntegrated({
    document,
    isBatchAction,
    numberOfItemInBatch,
  }: {
    document: IntegrationRequestDto;
    isBatchAction: boolean;
    numberOfItemInBatch?: number | undefined;
  }): void {
    const props: Segments.DocumentIntegrated = {
      documentType: document.documentType,
      isBatchAction,
      numberOfItemInBatch,
      source: document.source?.type,
    };

    this.analytics.track(TrackingEventName.DocumentIntegrated, props, this.options);
  }

  public messageDocumentDeleted({
    isBatchAction,
    numberOfItemInBatch,
  }: {
    isBatchAction: boolean;
    numberOfItemInBatch?: number | undefined;
  }): void {
    const props: Segments.DocumentDeleted = {
      isBatchAction,
      numberOfItemInBatch,
    };

    this.analytics.track(TrackingEventName.DocumentDeleted, props, this.options);
  }

  public patientCardViewed({ patientFhirReference }: { patientFhirReference?: string }): void {
    const props: Segments.PatientCardViewed = {
      patientFhirReference,
    };

    this.analytics.track(TrackingEventName.PatientCardViewed, props, this.options);
  }

  public patientDirectoryImportStarted({
    workspaceFhirReference,
  }: {
    workspaceFhirReference: string;
  }): void {
    const props: Segments.PatientDirectoryImportStarted = {
      workspaceFhirReference,
    };

    this.analytics.track(TrackingEventName.PatientDirectoryImportStarted, props, this.options);
  }

  public patientDirectoryImportDone({
    patientsCreated,
    patientsFailed,
    patientsUpdated,
    workspaceFhirReference,
  }: {
    patientsCreated?: number;
    patientsFailed?: number;
    patientsUpdated?: number;
    workspaceFhirReference?: string;
  }): void {
    const props: Segments.PatientDirectoryImportDone = {
      patientsCreated,
      patientsFailed,
      patientsUpdated,
      workspaceFhirReference,
    };

    this.analytics.track(TrackingEventName.PatientDirectoryImportDone, props, this.options);
  }

  public patientDirectoryImportFailed({ errorCause }: { errorCause?: string }): void {
    const props: Segments.PatientDirectoryImportFailed = {
      errorCause,
    };

    this.analytics.track(TrackingEventName.PatientDirectoryImportFailed, props, this.options);
  }

  public contactCardViewed({
    contactFhirReference,
    contactType,
  }: {
    contactFhirReference?: string;
    contactType?: Segments.TType;
  }): void {
    const props: Segments.ContactCardViewed = {
      contactFhirReference,
      contactType,
    };

    this.analytics.track(TrackingEventName.ContactCardViewed, props, this.options);
  }

  public contactSearched({ contactType }: { contactType?: Segments.ContactType }): void {
    const props: Segments.ContactSearched = {
      contactType,
    };

    this.analytics.track(TrackingEventName.ContactSearched, props, this.options);
  }

  public dashboardFiltered({
    patientFiltered,
    patientAdvancedFiltered,
    servicesFiltered,
    sourceFiltered,
    statusFiltered,
    mediumFiltered,
    page,
    dashboardType,
    recipient,
    dateRange,
    defaultDateRange,
    hideOtherRequesters,
    signingPractitionerFiltered,
    documentTypeFiltered,
  }: {
    patientFiltered?: boolean;
    patientAdvancedFiltered?: string[];
    servicesFiltered?: string;
    sourceFiltered?: string;
    statusFiltered?: DocumentVerificationStatus;
    mediumFiltered?: SelectedMediumFilter;
    page?: number;
    dashboardType?: string;
    recipient?: string;
    dateRange?: DateRangeValue;
    defaultDateRange?: boolean;
    hideOtherRequesters?: boolean;
    signingPractitionerFiltered?: string;
    documentTypeFiltered?: string;
  }): void {
    const props: Segments.DashboardFiltered = {
      patientFiltered,
      patientAdvancedFiltered,
      servicesFiltered,
      sourceFiltered,
      statusFiltered,
      mediumFiltered,
      page,
      dashboardType,
      recipient,
      dateRange,
      defaultDateRange,
      hideOtherRequesters,
      signingPractitionerFiltered,
      documentTypeFiltered,
    };
    this.analytics.track(TrackingEventName.DashboardFiltered, props, this.options);
  }

  public inErrorsDashboardFiltered(searchParams: InErrorsDashboardSearchParams): void {
    const props: Segments.InErrorsDashboardFiltered = {
      id: searchParams.id,
      fromDate: searchParams.fromDate,
      untilDate: searchParams.untilDate,
      message_contains: searchParams.message_contains,
      filename: searchParams.filename,
      patientFiltered:
        !!searchParams.firstname ||
        !!searchParams.lastname ||
        !!searchParams.ipp ||
        !!searchParams.birthdate,
    };
    this.analytics.track(TrackingEventName.InErrorsDashboardFiltered, props, this.options);
  }

  public documentTypeModified({
    newType,
    previousType,
  }: {
    newType: string;
    previousType: string;
  }): void {
    const props: Segments.DocumentTypeModified = {
      newType,
      previousType,
    };
    this.analytics.track(TrackingEventName.DocumentTypeModified, props, this.options);
  }

  public documentDatePeriodModified({
    newStartDate,
    newEndDate,
    previousStartDate,
    previousEndDate,
  }: {
    newStartDate?: string;
    newEndDate?: string;
    previousStartDate?: string;
    previousEndDate?: string;
  }): void {
    const props: Segments.DocumentDatePeriodModified = {
      newStartDate,
      newEndDate,
      previousStartDate,
      previousEndDate,
    };
    this.analytics.track(TrackingEventName.DocumentDatePeriodModified, props, this.options);
  }

  public documentSenderModified({
    newSender,
    previousSender,
  }: {
    newSender: string;
    previousSender: string;
  }) {
    const props: Segments.DocumentSenderModified = {
      newSender,
      previousSender,
    };
    this.analytics.track(TrackingEventName.DocumentSenderModified, props, this.options);
  }

  public batchDocumentsModified(segment: Segments.BatchDocumentsModified) {
    this.analytics.track(TrackingEventName.BatchDocumentsModified, segment, this.options);
  }

  public documentTitleModified(segment: Segments.DocumentTitleModified) {
    this.analytics.track(TrackingEventName.DocumentTitleModified, segment, this.options);
  }

  public medicalExamTypeModified({
    newType,
    previousType,
  }: {
    newType: MedicalExamType | null;
    previousType: MedicalExamType | null;
  }): void {
    const props = {
      newType,
      previousType,
    };
    this.analytics.track(TrackingEventName.MedicalExamTypeModified, props, this.options);
  }

  public documentOriginalSenderModified({
    newValue,
    previousValue,
  }: {
    newValue: string | null;
    previousValue: string | null;
  }): void {
    const props = {
      newValue,
      previousValue,
    };
    this.analytics.track(TrackingEventName.DocumentOriginalSenderModified, props, this.options);
  }

  public patientUpdated({
    patientId,
    updatedBy,
    hasNir,
    matchStatus,
  }: {
    patientId: string;
    updatedBy?: string;
    hasNir?: boolean;
    matchStatus?: PatientMatchStatus;
  }): void {
    const props: Segments.PatientUpdated = {
      patientId,
      updatedBy,
      hasNir,
      matchStatus,
    };
    this.analytics.track(TrackingEventName.PatientUpdated, props, this.options);
  }

  public refreshPatientMatch({ matchStatus }: { matchStatus: PatientMatchStatus }): void {
    const props: Segments.PatientMatchRelaunched = { matchStatus };
    this.analytics.track(TrackingEventName.PatientMatchRelaunched, props, this.options);
  }

  public patientSearched(): void {
    this.analytics.track(TrackingEventName.PatientSearched, {}, this.options);
  }

  public documentPatientCreated({ patientId }: { patientId?: string }): void {
    const props: Segments.PatientCreated = { fhirReference: patientId };
    this.analytics.track(TrackingEventName.PatientCreated, props, this.options);
  }

  public patientCreateClicked(): void {
    this.analytics.track(TrackingEventName.PatientCreateClicked, {}, this.options);
  }

  public patientModifyClicked({ matchStatus }: { matchStatus: PatientMatchStatus }): void {
    const props: Segments.PatientModifyClicked = { matchStatus };
    this.analytics.track(TrackingEventName.PatientModifyClicked, props, this.options);
  }

  public documentPatientRemoved({
    patientId,
    matchStatus,
  }: {
    patientId: string;
    matchStatus: string;
  }): void {
    const props: Segments.PatientRemoved = { patientId, matchStatus };
    this.analytics.track(TrackingEventName.PatientRemoved, props, this.options);
  }

  public documentPatientAdded({ patientId }: { patientId: string }): void {
    const props: Segments.PatientAdded = { patientId };
    this.analytics.track(TrackingEventName.PatientAdded, props, this.options);
  }

  public batchActionClicked({
    actionType,
    numberOfSelectedRows,
    documentIds,
  }: {
    actionType: Segments.BatchActionClickedActionType;
    numberOfSelectedRows: number;
    documentIds: string[];
  }): void {
    const props: Segments.BatchActionClicked = { actionType, numberOfSelectedRows, documentIds };
    this.analytics.track(TrackingEventName.BatchActionClicked, props, this.options);
  }

  public documentActionClicked({
    actionType,
    communicationRequest,
  }: {
    actionType: Segments.DocumentActionClickedActionType;
    communicationRequest: string;
  }): void {
    const props: Segments.DocumentActionClicked = { actionType, communicationRequest };
    this.analytics.track(TrackingEventName.DocumentActionClicked, props, this.options);
  }

  public documentIDCopied({ fhirReference }: { fhirReference: string | undefined }) {
    const props: Segments.DocumentIDCopied = { fhirReference };
    this.analytics.track(TrackingEventName.DocumentIdCopied, props, this.options);
  }

  public documentIntegrationMatchDone({ fhirReference }: { fhirReference: string | undefined }) {
    const props: Segments.IntegrationMatchDone = { fhirReference };
    this.analytics.track(TrackingEventName.IntegrationMatchDone, props, this.options);
  }

  public groupedDocumentReordered({ fhirReference }: { fhirReference: string | undefined }) {
    const props: Segments.GroupedDocumentReordered = { fhirReference };
    this.analytics.track(TrackingEventName.GroupedDocumentReordered, props, this.options);
  }

  public documentPrinted({ fhirReference }: { fhirReference: string | undefined }) {
    const props: Segments.DocumentPrinted = { fhirReference };
    this.analytics.track(TrackingEventName.DocumentPrinted, props, this.options);
  }

  public pdfViewerLongPress() {
    this.analytics.track(TrackingEventName.PdfViewerLongPress, undefined, this.options);
  }

  public documentCustomENSMessageAdded({
    documentId,
    isEmpty,
  }: {
    documentId: string | undefined;
    isEmpty: boolean;
  }) {
    const props: Segments.CustomEnsMessageAdded = { documentId, isEmpty };
    this.analytics.track(TrackingEventName.CustomEnsMessageAdded, props, this.options);
  }

  public documentCloseENSConversation({
    documentId,
    closed,
  }: {
    documentId: string | undefined;
    closed: boolean;
  }) {
    const props: Segments.CloseEnsConversation = { documentId, closed };
    this.analytics.track(TrackingEventName.CloseEnsConversation, props, this.options);
  }

  public notificationSetAsRead({
    communication,
    communicationRequest,
  }: {
    communication?: string;
    communicationRequest?: string;
  }) {
    const props: Segments.NotificationClicked = { communication, communicationRequest };
    this.analytics.track(TrackingEventName.NotificationClicked, props, this.options);
  }

  public appIsOpened(currentApp: AppKeys) {
    if (this.currentAppOpened !== currentApp) {
      const defaultAtLaunch = this.currentAppOpened === undefined;
      this.currentAppOpened = currentApp;

      this.analytics.track('App Opened', {
        app: currentApp,
        openingMethod: defaultAtLaunch
          ? Segments.OpeningMethod.DefaultAtLaunch
          : Segments.OpeningMethod.UserSwitch,
      });
    }
  }

  public appUpdateFailed({ errorCause }: { errorCause?: string }): void {
    const props: Segments.AppUpdateFailed = {
      errorCause,
    };

    this.analytics.track(TrackingEventName.AppUpdateFailed, props, this.options);
  }

  public appUpdateStarted({
    desktopVersion,
    webVersion,
  }: {
    desktopVersion?: string;
    webVersion?: string;
  }): void {
    const props: Segments.AppUpdateStarted = {
      desktopVersion,
      webVersion,
    };

    this.analytics.track(TrackingEventName.AppUpdateStarted, props, this.options);
  }

  public appUpdateCompleted({
    desktopVersion,
    webVersion,
  }: {
    desktopVersion?: string;
    webVersion?: string;
  }): void {
    const props: Segments.AppUpdateCompleted = {
      desktopVersion,
      webVersion,
    };

    this.analytics.track(TrackingEventName.AppUpdateCompleted, props, this.options);
  }

  public selectedIdentityUpdated({ newSelectedIdentity }: { newSelectedIdentity?: string }): void {
    const props: Segments.SelectedIdentityUpdated = {
      newSelectedIdentity,
    };
    this.analytics.track(TrackingEventName.SelectedIdentityUpdated, props, this.options);
  }

  public actionError({ actionFailed }: { actionFailed?: string }): void {
    const props: Segments.ActionErrorDisplayed = { actionFailed };
    this.analytics.track(TrackingEventName.ActionErrorDisplayed, props, this.options);
  }

  public documentEncounterListClicked(): void {
    this.analytics.track(TrackingEventName.EncounterListClicked, {}, this.options);
  }

  public documentEncounterUpdated(): void {
    this.analytics.track(TrackingEventName.EncounterUpdated, {}, this.options);
  }

  public documentNavigatorClicked({
    navigation,
    dashboardType,
  }: {
    navigation: 'next' | 'previous';
    dashboardType: DocumentDashboardType;
  }): void {
    const props: Segments.DocumentNavigatorClicked = { navigation, dashboardType };
    this.analytics.track(TrackingEventName.DocumentNavigatorClicked, props, this.options);
  }

  public userForcedLogout(): void {
    this.analytics.track(TrackingEventName.UserForcedLogout, {}, this.options);
  }

  public trackVerificationAction(props: Segments.VerificationAction): void {
    this.analytics.track(TrackingEventName.VerificationAction, props, this.options);
  }
}

export const tracking = new Tracking(new AnalyticsBrowser());
