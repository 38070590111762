import {
  BatchAction,
  BatchFailureAction,
  BatchFailureOnlyErrorAction,
  BatchSuccessAction,
  OneAction,
  OneFailureAction,
} from '../../documents.actions';
import {
  BaseDashboardViewState,
  BaseDetailViewState,
  DocumentDtoWithMetaFromState,
  DocumentMetadataTypeFromState,
  DocumentsState,
  XHR_STATUS_ERROR,
  XHR_STATUS_IDLE,
  XHR_STATUS_LOADING,
} from '../../documents.state';

/*
 * UTILS
 */

function updateEntities<T extends DocumentsState>(
  state: T,
  ids: string[] | undefined,
  fn: (documentState: DocumentDtoWithMetaFromState<T>) => DocumentDtoWithMetaFromState<T>,
): T['entities'] {
  const updatedEntities = { ...state.entities };
  if (ids) {
    for (const id of ids) {
      if (Object.prototype.hasOwnProperty.call(updatedEntities, id)) {
        updatedEntities[id] = fn(updatedEntities[id] as DocumentDtoWithMetaFromState<T>);
      }
    }
  }
  return updatedEntities;
}

function start<T extends DocumentsState>(
  state: T,
  ids: string[] | undefined,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return {
    ...state,
    entities: updateEntities(state, ids, (entity) => ({
      ...entity,
      meta: {
        ...entity.meta,
        [metaType]: XHR_STATUS_LOADING,
      },
    })),
  };
}

function success<T extends DocumentsState>(
  state: T,
  ids: string[] | undefined,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return {
    ...state,
    dashboardView: {
      ...state.dashboardView,
      loadingDocumentIds: [],
    },
    entities: updateEntities(state, ids, (entity) => ({
      ...entity,
      meta: {
        ...entity.meta,
        [metaType]: XHR_STATUS_IDLE,
      },
    })),
  };
}

function failure<T extends DocumentsState>(
  state: T,
  ids: string[] | undefined,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return {
    ...state,
    entities: updateEntities(state, ids, (entity) => ({
      ...entity,
      meta: {
        ...entity.meta,
        [metaType]: XHR_STATUS_ERROR,
      },
    })),
  };
}

function timeout<T extends DocumentsState>(
  state: T,
  ids: string[] | undefined,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return {
    ...state,
    entities: updateEntities(state, ids, (entity) => ({
      ...entity,
      meta: {
        ...entity.meta,
        [metaType]: { ...XHR_STATUS_IDLE, timeout: true },
      },
    })),
  };
}

function startWithTimeout<T extends DocumentsState>(
  state: T,
  ids: string[] | undefined,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return {
    ...state,
    entities: updateEntities(state, ids, (entity) => ({
      ...entity,
      meta: {
        ...entity.meta,
        [metaType]: { ...XHR_STATUS_LOADING, timeout: false },
      },
    })),
  };
}

function successWithTimeout<T extends DocumentsState>(
  state: T,
  ids: string[] | undefined,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return {
    ...state,
    entities: updateEntities(state, ids, (entity) => ({
      ...entity,
      meta: {
        ...entity.meta,
        [metaType]: { ...XHR_STATUS_IDLE, timeout: false },
      },
    })),
  };
}

function failureWithTimeout<T extends DocumentsState>(
  state: T,
  ids: string[] | undefined,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return {
    ...state,
    entities: updateEntities(state, ids, (entity) => ({
      ...entity,
      meta: {
        ...entity.meta,
        [metaType]: { ...XHR_STATUS_ERROR, timeout: false },
      },
    })),
  };
}

/*
 * DASHBOARD REDUCERS
 */

export function updateMany<T extends DocumentsState>(
  state: T,
  action: BatchAction | undefined,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return start(state, action?.payload.ids, metaType);
}

export function updateManySuccess<T extends DocumentsState>(
  state: T,
  action: BatchSuccessAction | undefined,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return success(state, action?.payload.ids, metaType);
}

export function updateFailure<T extends DocumentsState>(
  state: T,
  action: BatchFailureAction | BatchFailureOnlyErrorAction | undefined,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  const ids = (action?.payload && 'ids' in action.payload && action.payload.ids) || undefined;
  return failure(state, ids, metaType);
}

/**
 * Drops the document from the entities store, after the action ran successfully
 */
export function updateAndDropManySuccess<T extends DocumentsState>(
  state: T,
  action: BatchSuccessAction,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  const dashboardView: BaseDashboardViewState | undefined =
    'dashboardView' in state
      ? {
          ...state.dashboardView,
          // Drop the document from the dashboard
          ids: state.dashboardView.ids.filter((id) => !action.payload.ids.includes(id)),
        }
      : undefined;

  const detailView: BaseDetailViewState | undefined =
    'detailView' in state
      ? {
          ...state.detailView,
          // Unselect the document if dropped
          selectedId:
            state.detailView.selectedId && action.payload.ids.includes(state.detailView.selectedId)
              ? undefined
              : state.detailView.selectedId,
        }
      : undefined; // useless: no need to clean detailview?

  // -> Should clean entities from sent documents (as Cancel)

  return {
    ...state,
    ...updateManySuccess(state, action, metaType),
    ...(dashboardView ? { dashboardView } : {}),
    ...(detailView ? { detailView } : {}),
  };
}

/*
 * DETAIL REDUCERS
 */

export function updateOne<T extends DocumentsState>(
  state: T,
  action: OneAction,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return start(state, [action.payload.id], metaType);
}

export function updateOneSuccess<T extends DocumentsState>(
  state: T,
  action: OneAction,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return success(state, [action.payload.id], metaType);
}

export function updateOneFailure<T extends DocumentsState>(
  state: T,
  action: OneFailureAction,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return failure(state, [action.payload.id], metaType);
}

export function updateOneTimeoutStart<T extends DocumentsState>(
  state: T,
  action: OneAction,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return startWithTimeout(state, [action.payload.id], metaType);
}

export function updateOneTimeoutSuccess<T extends DocumentsState>(
  state: T,
  action: OneAction,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return successWithTimeout(state, [action.payload.id], metaType);
}

export function updateOneTimeout<T extends DocumentsState>(
  state: T,
  action: OneAction,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return timeout(state, [action.payload.id], metaType);
}

export function updateOneTimeoutFailure<T extends DocumentsState>(
  state: T,
  action: OneFailureAction,
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return failureWithTimeout(state, [action.payload.id], metaType);
}

/*
 * Meta and resource updates
 */

export function updateDocumentAndMeta<T extends DocumentsState>(
  state: T,
  resource: DocumentDtoWithMetaFromState<T>['resource'],
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return {
    ...state,
    ...(state.detailView.documentDto && {
      detailView: { ...state.detailView, documentDto: resource },
    }),
    entities: {
      ...state.entities,
      [resource.id]: {
        ...(!state.detailView.documentDto && { resource }),
        meta: {
          ...state.entities[resource.id].meta,
          [metaType]: XHR_STATUS_IDLE,
        },
      },
    },
  };
}

export function updateDocumentAndMetaWithTimeout<T extends DocumentsState>(
  state: T,
  resource: DocumentDtoWithMetaFromState<T>['resource'],
  metaType: DocumentMetadataTypeFromState<T>,
): T {
  return {
    ...state,
    entities: {
      ...state.entities,
      [resource.id]: {
        resource,
        meta: {
          ...state.entities[resource.id].meta,
          [metaType]: { ...XHR_STATUS_IDLE, timeout: false },
        },
      },
    },
  };
}
