import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Collapse, Icon, Space } from '@honestica/ui-kit/src';

import {
  ServiceReachability,
  ServiceReachabilityService,
} from '../ServiceReachability/ServiceReachability.component';

export function WhitelistsDiagnostics() {
  const { t } = useTranslation();
  const [lastServicesRefreshAt, setLastServicesRefreshAt] = useState<Date>();

  return (
    <Collapse>
      <Collapse.Panel header={t('desktopSettings.showWhitelistsDiagnostics')} key="1">
        <Space direction="vertical">
          <ServiceReachability
            needsRefreshAt={lastServicesRefreshAt}
            service={ServiceReachabilityService.launchdarkly}
          />
          <ServiceReachability
            needsRefreshAt={lastServicesRefreshAt}
            service={ServiceReachabilityService.pusher}
          />
          <ServiceReachability
            needsRefreshAt={lastServicesRefreshAt}
            service={ServiceReachabilityService.segment}
          />
          <ServiceReachability
            needsRefreshAt={lastServicesRefreshAt}
            service={ServiceReachabilityService.intercom}
          />
          <Button onClick={() => setLastServicesRefreshAt(new Date())} className="margin-top-xl">
            <Space>
              <Icon name="Refresh" size={12} />
              {t('desktopSettings.refreshWhitelistsDiagnostics')}
            </Space>
          </Button>
        </Space>
      </Collapse.Panel>
    </Collapse>
  );
}
