import { BASENAME } from '@launcher/constants';
import { createSelector } from '@reduxjs/toolkit';

import { getDashboardTypeFromRoute } from '@utils/route.utils';

import { OpenDrawerProps } from '../drawer/drawer.action';

export const getPractitionerId = () => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);

  const id = searchParams.get('practitionerId') as string;

  return id ?? null;
};

export const getOrganizationIdFromRouter = () => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);

  const id = searchParams.get('organizationId') as string;

  return id ?? null;
};

export const getSearchTextParams = () => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  return (searchParams.get('search') as string) ?? '';
};

export const selectPathName = () => {
  const { pathname } = window.location;
  return pathname.replace(BASENAME, '');
};

export const selectCurrentDocumentTypeFromRoute = createSelector(
  selectPathName,
  getDashboardTypeFromRoute,
);

export const getAddPatientRecipientFlowParams = ():
  | OpenDrawerProps['addPatientRecipientFlowParams']
  | undefined => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);

  const isAddressSelected = searchParams.get('isAddressSelected') as string;
  const isTelecomSelected = searchParams.get('isTelecomSelected') as string;
  const isEnsSelected = searchParams.get('isEnsSelected') as string;

  if (!isAddressSelected && !isTelecomSelected && !isEnsSelected) {
    return undefined;
  }

  return {
    isAddressSelected: isAddressSelected === 'true',
    isTelecomSelected: isTelecomSelected === 'true',
    isEnsSelected: isEnsSelected === 'true',
  };
};
