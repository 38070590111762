/**
 * Generated by orval v6.13.1 🍺
 * Do not edit manually.
 * BECA API V2
 * OpenAPI spec version: v2
 */
import { useQuery } from '@tanstack/react-query';

import { customClient } from './mutator/custom-client';

import type { ErrorType } from './mutator/custom-client';
import type {
  GetProcessingNotifications401,
  GetProcessingNotifications422,
  GetProcessingNotificationsParams,
  ProcessingNotifications,
} from './types';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary list processing_notifications
 */
export const getProcessingNotifications = (params: GetProcessingNotificationsParams) =>
  customClient<ProcessingNotifications>({
    url: `/processing_notifications`,
    method: 'get',
    params,
  });

export const getGetProcessingNotificationsQueryKey = (params: GetProcessingNotificationsParams) =>
  [`/processing_notifications`, ...(params ? [params] : [])] as const;

export type GetProcessingNotificationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProcessingNotifications>>
>;
export type GetProcessingNotificationsQueryError = ErrorType<
  GetProcessingNotifications401 | GetProcessingNotifications422
>;

export const useGetProcessingNotifications = <
  TData = Awaited<ReturnType<typeof getProcessingNotifications>>,
  TError = ErrorType<GetProcessingNotifications401 | GetProcessingNotifications422>,
>(
  params: GetProcessingNotificationsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getProcessingNotifications>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProcessingNotificationsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProcessingNotifications>>> = () =>
    getProcessingNotifications(params);

  const query = useQuery<Awaited<ReturnType<typeof getProcessingNotifications>>, TError, TData>({
    queryKey,
    queryFn,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
