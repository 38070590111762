import { Tracking } from '@services/analytics';
import { all, getContext, takeLatest } from 'redux-saga/effects';

import { AddMutationErrorMessage, addMutationErrorMessage } from '@store/errors/errors.action';

function* trackActionError({ payload }: ReturnType<AddMutationErrorMessage>) {
  const trackingService: Tracking = yield getContext('trackingService');

  trackingService.actionError({ actionFailed: payload });
}

export function* trackingErrorSaga() {
  yield all([takeLatest(addMutationErrorMessage.type, trackActionError)]);
}
