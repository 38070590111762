import { useEffect } from 'react';

export function useOnMount(fn: () => void) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fn(), []);
}

export function useOnUnmount(fn: () => void) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fn, []);
}
