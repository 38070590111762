import { PayloadAction } from '@reduxjs/toolkit';
import { logger } from '@services/logger/logger.service';
import { all, put, takeEvery } from 'redux-saga/effects';

import {
  SearchDirectoriesResultWithContacts,
  SearchParams,
} from '@honestica/core-apps-common/types';

import { searchProfessionals } from '@api';

import {
  searchDirectories,
  searchDirectoriesFailure,
  searchDirectoriesSuccess,
} from './searchDirectories.actions';

export function* searchDirectoriesAsync({ payload: searchParams }: PayloadAction<SearchParams>) {
  try {
    const searchResults: SearchDirectoriesResultWithContacts = yield searchProfessionals(
      searchParams,
    );

    yield put(searchDirectoriesSuccess(searchResults));
  } catch (error) {
    logger.error('LOGIC', 'Failed to search directories', error);
    yield put(searchDirectoriesFailure(error));
  }
}

export function* searchDirectoriesSagas() {
  yield all([takeEvery(searchDirectories.type, searchDirectoriesAsync)]);
}
