import { Action } from '@reduxjs/toolkit';
import { all, getContext, takeLatest } from 'redux-saga/effects';

function* trackEncounterListClicked(): any {
  const trackingService = yield getContext('trackingService');
  trackingService.documentEncounterListClicked();
}

function* trackEncounterUpdated(): any {
  const trackingService = yield getContext('trackingService');
  trackingService.documentEncounterUpdated();
}

export function* trackingEncounterSagas() {
  yield all([
    takeLatest((action: Action) => /fetchEncounters$/.test(action.type), trackEncounterListClicked),
  ]);

  yield all([
    takeLatest((action: Action) => /switchEncounter$/.test(action.type), trackEncounterUpdated),
  ]);
}
